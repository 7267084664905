import React from "react";

import Card from "react-bootstrap/Card";
import classMap from "../..//utilities/classMap";
import TemplateProps from "./templates.props";

const Extended = (props: TemplateProps) => {
  const { title, titleClassName, slogan, icon, progress, action, description } = props;

  return (
    <>
      <div>
        <Card.Title
          as="h2"
          className={classMap("card-title", "visual-action-title", "m-0", titleClassName)}
          title={title}
        >
          {title}
        </Card.Title>
      </div>
      {slogan}
      {icon && <div className="visual-action-image mb-3">{icon}</div>}
      {progress && <div className="mb-3 w-100">{progress}</div>}
      {action && <div>{action}</div>}
      {description}
    </>
  );
};

export default Extended;
