enum configs {
  "paginationSize" = 50,
  "sessionTimeout" = 85,
  "studentSessionTimeout" = 27
}

export enum gradeLevels {
  PK = "PK",
  KG = "K",
  First = "1",
  Second = "2",
  Third = "3",
  Four = "4",
  Five = "5",
  Six = "6",
  Seven = "7",
  Eight = "8",
  Nine = "9",
  Ten = "10",
  Eleven = "11",
  Tweleve = "12",
  Other = "Other"
}

export const defaultGradeLevels: string[] = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
export const defaultGradeLevelIds: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const paginationControllers = [500, 250, 100, 50];

export const flagrKeys = [
  "DSDSReport-Use_JWT_V2_Token",
  "MPAReport-AllowCSVExportAllData",
  "MPAReport-StudentIDCSVColumn",
  "MPAReport-DomainReporting",
  "NetSuite",
  "S26-G4230-HideTheAdminButton",
  "S27-B376-ExpandSubjectLevelPlacement",
  "MyPathAssessmentReports",
  "S31b-B583-QuickPlacement",
  "MyPathAssessmentSchoolLevelReports",
  "MyPathAssessmentClassLevelReports",
  "MyPathAssessmentDistrictLevelReports",
  "S32a-B487-PlacementGradeFilter6-12",
  "B584-BulkActionAssignPlacement",
  "S44-0262-HideLSBReportsForTeachers",
  "S49_383_ShowDistrictLevelGrowthReportCard",
  "MyPathScienceProduct",
  "S54-3369-NewCreateStudentDesign",
  "S54-5370-EditStudentSettings",
  "S58-5299-ShowLogoutIcon",
  "MPAMathSpanishLanguage",
  "S59-B1146-ShowSisenseReport",
  "S62-5320-ClassRosterNewAddStudent",
  "SpanishLanguageScience",
  "S64-B1155-ShowMyAssignmentBadge",
  "ShowSisenseReportForWNEUsers",
  "ShowStudentProgressTab",
  "ShowStudentClassesTab",
  "ShowMyPathScienceColumnForDistricts",
  "S76-6256-RemoveSettingLicensingInALA",
  "S78-6522-UpdateLoginCode",
  "ReadinessFeature",
  "ReadinessFeature_Organizations",
  "enable-domain-placement",
  "enable-content-bridge",
  "ReadinessFeature_Educator_Email_Loginname",
  "ReadinessFeature_Users_Groups_Events",
  "DomainMappingFeature",
  "StandardsProficiencyReport-Access"
];

export const classLimit: number = 2000;

export default configs;
