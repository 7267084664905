import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import "./admin.scss";
import { UxAction } from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReduxProps from "../../Redux/Redux.props";
import { EducatorViewProps } from "./EducatorView.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getFlagrKeys: state.getFlagrKeys,
    getUserRoleId: state.getUserRoleId
  };
}
interface Props extends EducatorViewProps, ReduxProps {}

function EAdmin(props: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch, getDaDetails, getUserRoleId } = props;
  useEffect(() => {
    dispatch(updatePageTitle(`- Admin`));
    const fetchData = () => {
      const breadcrumb = { crumbs: [{ path: "/eadmin", name: "Admin" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    dispatch(updateActivityIndicator(false));
    fetchData();
  }, [dispatch]);

  function showBulkImports() {
    navigate(`/bulkimports/${getDaDetails.id}`);
  }

  return (
    <>
      {getUserRoleId !== 3 && getUserRoleId !== 4 && getUserRoleId !== 5 ? (
        <div className="class-nav-tabs ps-4 pt-4">
          <div className="EACardsFlex">
            <div className="EACards">
              <div className="EACardInnerHolder">
                <label className="assessmentHeader">Bulk Account Management</label>
                <UxAction
                  callback={() => {
                    showBulkImports();
                  }}
                  text={t("Bulk Uploads")}
                  className="NWEAButton"
                  size={"sm"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default connect(mapStateToProps)(EAdmin);
