import React from "react";

import { ThemeContext } from "../providers/themes";

import BackgroundProps from "./background.props";
import assetImport from "..//utilities/assetImport";
import classMap from "..//utilities/classMap";

const Background = (props: BackgroundProps) => (
  <ThemeContext.Consumer>
    {(theme: string) => {
      return (
        <div
          className={classMap("edge-ux-background position-contain background-cover", props.className)}
          style={{
            backgroundImage: `url(${assetImport(props.background, props.theme || theme)})`
          }}
        />
      );
    }}
  </ThemeContext.Consumer>
);

export default Background;
