import { useState, useEffect } from "react";
import { UpdatePlacementProps } from "../Homepage/StudentHomePage.props";

interface StudentLandingProps {
  showMyAssignmentBadge: boolean;
  gotStudentAssignments: boolean;
  allPlacementData: UpdatePlacementProps[];
  getMppApp: boolean;
  mppSubject: string;
  assessmentStatusData: any[];
}

export default function useStudentLandingDisplay({
  gotStudentAssignments,
  showMyAssignmentBadge,
  allPlacementData,
  getMppApp,
  mppSubject,
  assessmentStatusData
}: StudentLandingProps) {
  const invalidAssessmentStatuses = ["NoWindowAvailable", "Completed", "Waiting", "Restricted"];
  const [showNoWorkMessage, setShowNoWorkMessage] = useState(false);
  const [showTiles, setShowTiles] = useState(false);
  const [subjects, setSubjects] = useState<UpdatePlacementProps[]>([]);

  const isAnAssessmentPlaceholder = ({ subject, active, myPathSubjectStatus }) => {
    return !active && myPathSubjectStatus && subject !== "Science" && subject !== "Purpose";
  };

  useEffect(() => {
    function evaluateTileStatus() {
      let requestedSubjects = [...allPlacementData];
      if (getMppApp && mppSubject) {
        requestedSubjects = [...allPlacementData]
          .filter((tab) => tab.subject === mppSubject)
          .map((tab) => ({ subject: tab.subject, active: tab.active, myPathSubjectStatus: tab.myPathSubjectStatus, pathwayType: tab.pathwayType }));
      }

      requestedSubjects = requestedSubjects.filter((tab) => {
        if (isAnAssessmentPlaceholder(tab)) {
          if (assessmentStatusData?.length) {
            const catSubject = tab.subject === "Math" ? "math" : "ela";
            const subWindow = assessmentStatusData.filter((item) => item.subject === catSubject);
            return subWindow.length > 0 && !invalidAssessmentStatuses.includes(subWindow[0].status);
          }
          return false;
        }
        return true;
      });

      if (showMyAssignmentBadge || requestedSubjects.length > 0) {
        return { show: true, requestedSubjects: requestedSubjects };
      }

      return { show: false };
    }

    if (!gotStudentAssignments) {
      //inconclusive, no data yet
      setShowNoWorkMessage(false);
      setShowTiles(false);
      return;
    }

    const tileStatus = evaluateTileStatus();

    if (tileStatus?.show) {
      setShowNoWorkMessage(false);
      setShowTiles(true);
      if (tileStatus?.requestedSubjects) {
        setSubjects([...tileStatus.requestedSubjects]);
      }
    } else {
      setShowNoWorkMessage(true);
      setShowTiles(false);
    }
  }, [showMyAssignmentBadge, gotStudentAssignments, allPlacementData, getMppApp, mppSubject, assessmentStatusData]);

  return { showNoWorkMessage, showTiles, subjects };
}
