import React, { useEffect } from "react";
import "./Transition68.scss";
import { blueRobotImg } from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import { updatePageTitle } from "../../../../Redux/Action";
import ReduxProps from "../../../../Redux/Redux.props";
import { TransitionProps } from "../Transition.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreakpoint: state.getBreakpoint
  };
}
interface Props extends ReduxProps, TransitionProps {}

function Transition68(props: Props) {
  const { transitionGo, goBackToMyPathAssessment, isTestTimeAgain, getBreakpoint, dispatch } = props;
  useEffect(() => {
    dispatch(
      updatePageTitle(
        isTestTimeAgain ? "Test time again | Imagine MyPath" : "Time to finish your test | Imagine MyPath "
      )
    );
  }, []);

  useEffect(() => {
    document.getElementById("click68").focus();
    document.getElementById("back68").addEventListener("keydown", (event) => {
      if (event.key === "Tab" && !event.shiftKey) {
        event.preventDefault();
        const elem = document.getElementById("close68");
        elem.focus();
      }
    });

    document.getElementById("close68").addEventListener("keydown", (event) => {
      if (event.shiftKey && event.key === "tab") {
        event.preventDefault();
        const ele = document.getElementById("back68");
        ele.focus();
      }
    });
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        goBackToMyPathAssessment();
      }
    });
  }, []);

  const blueRobotransitionClassName = () => {
    if (getBreakpoint === "sm") {
      return "blueRobotransition blueRobotransitionsm";
    } else {
      return "blueRobotransition";
    }
  };
  const messageBoxClassName = () => {
    if (getBreakpoint === "sm") {
      return "messageBox68 messageBox68sm";
    } else {
      return "messageBox68";
    }
  };
  return (
    <>
      <div className="flexFor68  scrollbarhidden" style={{ backgroundColor: "rgba(6, 10, 139, 0.44)" }}>
        <div className="transition68layout">
          <div className="transition68screen">
            <div
              className={
                getBreakpoint === "md" ? "blueRobotransition blueRobotransitionmd" : blueRobotransitionClassName()
              }
            >
              <img src={blueRobotImg} alt="Joe says" />
            </div>
            <div
              className={getBreakpoint === "md" ? "messageBox68 messageBox68md" : messageBoxClassName()}
              role="dialog"
              aria-label="test time"
            >
              <div id="click68" tabIndex={-1} aria-hidden="true"></div>
              <div
                className="closeicon  "
                style={{ width: "30px" }}
                onClick={() => {
                  goBackToMyPathAssessment();
                }}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === "Enter") {
                    goBackToMyPathAssessment();
                  }
                }}
              >
                <i
                  className="fa fa-close tabfocustransition"
                  tabIndex={0}
                  aria-label="close"
                  role="button"
                  id="close68"
                ></i>
              </div>

              <div>
                {isTestTimeAgain ? (
                  <div className="modalmessage69">
                    <h2 className=" modalh2message tabfocustransition" tabIndex={0}>
                      {" "}
                      Test Time Again!{" "}
                    </h2>
                    Don't worry, your hard work will be saved.
                  </div>
                ) : (
                  <div className="modalmessage69">
                    <h2 className=" modalh2message  tabfocustransition" tabIndex={0}>
                      {" "}
                      Time to Finish Your Test.
                    </h2>
                  </div>
                )}
              </div>
              <div className="buttons">
                <div
                  className="gotransitionButton68  tabfocustransition"
                  onClick={() => {
                    transitionGo();
                  }}
                  tabIndex={0}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (event.key === "Enter") {
                      transitionGo();
                    }
                  }}
                  role="button"
                >
                  Go
                </div>
                <div
                  id="back68"
                  className="backtransitionButton68  tabfocustransition"
                  onClick={() => {
                    goBackToMyPathAssessment();
                  }}
                  tabIndex={0}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (event.key === "Enter") {
                      goBackToMyPathAssessment();
                    }
                  }}
                  role="button"
                >
                  Back
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Transition68);
