/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import BulkImports from "./BulkImports";
import { updateBreadCrumbs } from "../../Redux/Action";
import { BulkImportViewProps } from "./BulkImportsView.props";
import ReduxProps from "../../Redux/Redux.props";

interface Props extends ReduxProps, BulkImportViewProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getDaDetails: state.getDaDetails
  };
}

function BulkImportsView(props: Props): JSX.Element {
  const { dispatch } = props;

  React.useEffect(() => {
    dispatch(
      updateBreadCrumbs({
        crumbs: [
          { path: "/eadmin", name: "Admin" },
          { path: "/bulkimports", name: "Bulk Upload" }
        ]
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="class-nav-tabs pt-4">
        <BulkImports isfrom="admin" />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(BulkImportsView);
