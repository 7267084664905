import React, { useEffect } from "react";
import "./TransitionK2.scss";
import { goldEyedImg } from "@wne-spa/ux-component-library";
import { updatePageTitle } from "../../../../Redux/Action";
import { connect } from "react-redux";
import ReduxProps from "../../../../Redux/Redux.props";
import { TransitionProps } from "../Transition.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreakpoint: state.getBreakpoint
  };
}

function TransitionK2(props: TransitionProps) {
  const { goBackToMyPathAssessment, transitionGo, isTestTimeAgain, dispatch } = props;
  useEffect(() => {
    dispatch(
      updatePageTitle(
        isTestTimeAgain ? "Test time again | Imagine MyPath" : "Time to finish your test | Imagine MyPath "
      )
    );
  }, []);

  useEffect(() => {
    document.getElementById("clickk2").focus();
    document.getElementById("backk2").addEventListener("keydown", (event) => {
      if (event.key === "Tab" && !event.shiftKey) {
        event.preventDefault();
        const elem = document.getElementById("closek2");
        elem.focus();
      }
    });
    document.getElementById("closek2").addEventListener("keydown", (event) => {
      if (event.shiftKey && event.key === "tab") {
        event.preventDefault();
        const ele = document.getElementById("backk2");
        ele.focus();
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        goBackToMyPathAssessment();
      }
    });
  }, []);

  return (
    <>
      <div className="flex scrollbarhidden" style={{ backgroundColor: `rgba(6, 10, 139, 0.44)` }}>
        <div className="transitionlayout">
          <div className="transitionscreen">
            <div className="goldeyetransition" id="messageboxtransk2">
              <img src={goldEyedImg} alt="Joe says" />
            </div>
            <div className="messageboxtransition" role="dialog" aria-label="test time">
              <div id="clickk2" tabIndex={-1} aria-hidden="true"></div>
              <div
                className="closeicon  "
                style={{ width: "30px" }}
                onClick={() => {
                  goBackToMyPathAssessment();
                }}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key === "Enter") {
                    goBackToMyPathAssessment();
                  }
                }}
              >
                <i
                  className="fa fa-close tabfocustransition"
                  tabIndex={0}
                  aria-label="close"
                  role="button"
                  id="closek2"
                ></i>
              </div>
              {isTestTimeAgain ? (
                <div className="modalmessagek2">
                  <h2 className="modalh2messagek2 tabfocustransition" tabIndex={0}>
                    {" "}
                    Test Time Again!{" "}
                  </h2>
                  Don't worry, your hard work will be saved.
                </div>
              ) : (
                <div className="modalmessagek2">
                  <h2 className=" modalh2messagek2  tabfocustransition" tabIndex={0}>
                    {" "}
                    Time to Finish Your Test.
                  </h2>
                </div>
              )}

              <div className="transButtons">
                <div
                  className="goButtonTrans tabfocustransition"
                  onClick={() => {
                    transitionGo();
                  }}
                  tabIndex={0}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (event.key === "Enter") {
                      transitionGo();
                    }
                  }}
                  role="button"
                >
                  <div>Go</div>

                  <div aria-hidden="true">
                    <i className="fa fa-arrow-right goArrow"> </i>
                  </div>
                </div>
                <div
                  id="backk2"
                  className="backButtonTrans tabfocustransition"
                  onClick={() => {
                    goBackToMyPathAssessment();
                  }}
                  tabIndex={0}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (event.key === "Enter") {
                      goBackToMyPathAssessment();
                    }
                  }}
                  role="button"
                >
                  Back
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(TransitionK2);
