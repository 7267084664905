enum ColorsEnum {
  BLUE = "blue",
  INDIGO = "indigo",
  PURPLE = "purple",
  PINK = "pink",
  RED = "red",
  ORANGE = "orange",
  YELLOW = "yellow",
  GREEN = "green",
  TEAL = "teal",
  CYAN = "cyan"
}
export default ColorsEnum;
