import React, { useState } from "react";
import { UxAction, UxActivityIndicator } from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Helmet } from "react-helmet";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import configMessages from "../../helpers/configMessages";
import "./ResetPassword.scss";
import logo from "../../images/edgenuity-logo-large.png";
import Ilogo from "../../images/LOGO-IL.png";
import { validEmailAddress } from "../../helpers/regularExpressions";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    userEmail: ""
  });
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [checkPasswordReset, setCheckPasswordReset] = useState(false);

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>, inputType: string): void {
    if (event.target.value.charAt(0) !== " ") {
      setState({ ...state, [inputType]: event.target.value });
    }
  }
  const resetInputField = () => {
    setState({ userEmail: "" });
  };

  function savePassword() {
    setActivityIndicator(false);
    if (validEmailAddress.test(state.userEmail)) {
      validateUserEmailAddress();
    } else {
      if (!showToast.val) {
        if (state.userEmail === "") {
          setShowToast({
            val: true,
            msg: configMessages.resetPasswordUserEmailValidation
          });
        } else {
          setShowToast({
            val: true,
            msg: configMessages.resetPasswordEmptyUserEmailValidation
          });
          resetInputField();
        }
      }
    }
  }

  async function validateUserEmailAddress() {
    setActivityIndicator(true);
    let userRole = localStorage.getItem("ala:redirectedFrom");
    const jsonObj = {
      email: state.userEmail,
      roleName: userRole
    };
    try {
      const options = {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonObj)
      };
      const response = await fetch(`${window.APP_ENV.userApiUrl}/password/forgot`, options);

      let details = await response.text();
      let details1 = JSON.parse(details);
      if (details1.message === "Reset password not allowed.") {
        setCheckPasswordReset(true);
      }
      if (response != null) {
        setIsValid(false);
        setActivityIndicator(false);
      } else {
        setIsValid(true);
        setActivityIndicator(false);
      }
    } catch (err) {
      setIsValid(true);
      setActivityIndicator(false);
    }
  }

  function loginPage() {
    navigate("/");
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();
  const user = localStorage.getItem("ala:redirectedFrom") === "wneuser" ? "wneuser" : "educator";

  return (
    <>
      {activityIndicator ? <UxActivityIndicator type="nouser" /> : null}

      <Helmet>
        <meta charSet="utf-8" />
        {user === "wneuser" ? <title>Reset Password: Edgenuity</title> : <title>Reset Password: Imagine MyPath</title>}
      </Helmet>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={10000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            severity="warning"
            style={{ backgroundColor: "#f4473c", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div className="background"></div>
      <div className="resetpwdlayout">
        <div className="resetpwdcontent">
          <div className="resetpwdlogoimg">
            <img
              src={user === "wneuser" ? logo : Ilogo}
              alt=""
              style={user === "wneuser" ? { width: "60%", height: "auto" } : { width: "34%", height: "auto" }}
            />
          </div>
          <div className="resetpwdinput">
            {isValid ? (
              <>
                <div>
                  <p className="rHeading" tabIndex={0} aria-label="Reset Your Password">
                    {" "}
                    Reset Your Password{" "}
                  </p>
                </div>
                <div className="emailinput">
                  <div className="code-text" tabIndex={0} aria-label="Email Address">
                    {user === "wneuser" ? "Email Address" : "Email Address for Your Account"}
                  </div>
                  <input
                    className="loginTextInput tabfocus loginellipsis"
                    style={{ border: "1px solid #333333" }}
                    maxLength={48}
                    tabIndex={0}
                    placeholder="Enter Email Address for Your Account"
                    value={state.userEmail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "userEmail")}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        savePassword();
                      }
                    }}
                  />
                </div>
                <div className="loginbuttonbox">
                  <UxAction
                    callback={() => {
                      savePassword();
                    }}
                    text={t("Continue")}
                    className="continuebuttonresponsive"
                  />
                </div>
              </>
            ) : (
              <div className="forgotBoxHolder">
                <div className="resetheading"> Reset Your Password</div>

                <div className="resetMessage">
                  {!checkPasswordReset ? (
                    <>
                      <span style={{ fontWeight: "bold" }}>Check Your Email</span>

                      <span> If we find that you have an account, we will send an </span>
                      <span> email with instructions to reset your password. </span>
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}>Reset password not allowed</span>

                      <span> Your district manages user passwords in an external </span>
                      <span>source so Imagine MyPath won't be able to reset your </span>
                      <span>password.</span>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="resetpwdreturn">
            <p
              onClick={() => {
                loginPage();
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  loginPage();
                }
              }}
              className="returnToLogin"
              tabIndex={0}
              role="link"
            >
              <u> Return to Login </u>
              <p style={{ opacity: "0" }}> the user is directed to the Return to Log In</p>
            </p>
          </div>
        </div>
        <div className="resetpwdfooter">
          {user === "wneuser" ? (
            <div className="leftfooter">© {new Date().getFullYear()} Edgenuity Inc. All Rights Reserved.</div>
          ) : (
            <div className="leftfooter">© {new Date().getFullYear()} Imagine Learning LLC. All Rights Reserved.</div>
          )}
          <div className="rightfooter" style={{ fontWeight: 500 }} role="contentinfo" aria-label="Footer">
            <a
              className="tagRightFooter"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/en/us/products/mypath"
              id="about"
              tabIndex={0}
              aria-label="About link opens in a new tab"
              role="link"
              onKeyDown={(event) => {
                if (event.key === "Spacebar" || event.key === " ") {
                  window.open("https://www.imaginelearning.com/en/us/products/mypath");
                }
              }}
            >
              <u> About </u>
            </a>
            <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
            <a
              className="tagRightFooter  "
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/support/privacy/policy/"
              tabIndex={0}
              aria-label="Privacy Policy link opens in a new tab"
              id="policy"
              role="link"
            >
              <u> Privacy Policy</u>
            </a>
            <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
            <a
              className="tagRightFooter  "
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/media/pdf/End%20User%20TOS%202022%20IL%20LLC.pdf"
              tabIndex={0}
              aria-label="Terms of Use link opens in a new tab"
              id="terms"
              role="link"
            >
              <u> Terms of Use </u>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
