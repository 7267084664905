import React, { useEffect } from "react";
import {
  mathk2png,
  readingk2png,
  selk2png,
  math35png,
  reading35png,
  sel35png,
  ScienceK2png,
  Science35png
} from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import ReduxProps from "../../../Redux/Redux.props";
import { StudentSubjectsProps } from "./StudentSubjects.props";
import useGrade from "../hooks/useGrade";
import useBreakpoint from "../hooks/useBreakpoint";
import usePageTitle from "../hooks/usePageTitle";

function mapStateToProps(state: ReduxProps) {
  return {
    isSessionModal: state.isSessionModal,
    globalSubjectPurpose: state.globalSubjectPurpose
  };
}
interface Props extends StudentSubjectsProps {}

function StudentSubjectsFork5(props: Props) {
  const { subject, subjectClick, subjectClick68, subjectClick912, showIMPathway } = props;

  const globalSubjectPurpose = "Purpose";
  const { isKto2, is3to5, is6to8, is9to12 } = useGrade();
  const { breakpoint } = useBreakpoint();
  const { updateTitle } = usePageTitle();

  useEffect(() => {
    updateTitle("Student Dashboard | Imagine MyPath");
  }, []);

  function callback(subject: string) {
    if (isKto2 || is3to5) {
      subjectClick(subject);
    } else if (is6to8) {
      subjectClick68(subject);
    } else if (is9to12) {
      subjectClick912(subject);
    }
  }

  const breakpointClassName = () => {
    if (isKto2 && breakpoint === "md") {
      return "circle circlemd";
    } else if (isKto2) {
      return "circle";
    } else if (is3to5 && showIMPathway) {
      return "circle35 circle35-im-bg";
    } else if (is3to5 && breakpoint === "sm") {
      return "circle35 circle35sm";
    } else if (is3to5 && breakpoint === "md") {
      return "circle35 circle35md";
    } else {
      return "circle35";
    }
  };

  const subjectSrc = () => {
    if (is3to5 && subject === "Math") {
      return math35png;
    } else if (isKto2 && subject === "Reading") {
      return readingk2png;
    } else if (is3to5 && subject === "Reading") {
      return reading35png;
    } else if (isKto2 && subject === globalSubjectPurpose) {
      return selk2png;
    } else if (is3to5 && subject === globalSubjectPurpose) {
      return sel35png;
    } else if (isKto2 && subject === "Science") {
      return ScienceK2png;
    } else if (is3to5 && subject === "Science") {
      return Science35png;
    } else {
      return null;
    }
  };

  return (
    <div
      className={isKto2 && breakpoint === "sm" ? "circle circlesm" : breakpointClassName()}
      tabIndex={0}
      onFocus={() => {
        //do nothing
      }}
      onBlur={() => {
        //do nothing
      }}
      style={{ flexDirection: "column" }}
      onClick={() => {
        callback(subject);
      }}
    >
      <img
        className={subject.toLowerCase() === globalSubjectPurpose.toLowerCase() ? "selicon" : ""}
        style={{ width: "63px", height: "auto" }}
        src={isKto2 && subject === "Math" ? mathk2png : subjectSrc()}
        alt={""}
      />
      {showIMPathway && subject === "Math" ? (
        <div className="subjectk5text">
          <div className="subjecttexthomepage subjecttext-im-font" aria-hidden="true">
            BUILDER
          </div>
        </div>
      ) : (
        <div className="subjectk5text">
          {subject !== globalSubjectPurpose ? (
            <div className="subjecttexthomepage" aria-hidden="true">
              {subject}
            </div>
          ) : (
            <div>
              <div aria-hidden="true" className={"subjecttexthomepage"}>
                {" PURPOSE"}
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`${isKto2 ? "goButtonFork2" : "goButton35"} ${
          showIMPathway && isKto2 ? "practice-go-k2" : "practice-go-35"
        }`}
      >
        <div className="gotext" aria-hidden="true">
          GO
        </div>
        <div className="arrowmargin">
          <i className="fas fa-arrow-right arrowright"></i>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(StudentSubjectsFork5);
