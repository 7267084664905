import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReduxProps from "../../Redux/Redux.props";
import { openHelpCenter } from "../../helpers/helpCenter";

function mapStateToProps(state: ReduxProps) {
  return {
    getSiteCodeDetails: state.getSiteCodeDetails,
    getAssessmentDetails: state.getAssessmentDetails,
    getPendoEducatorDetails: state.getPendoEducatorDetails,
    getUserRole: state.getUserRole,
    getUserEmail: state.getUserEmail,
    getUserLastName: state.getUserLastName,
    getUserFirstName: state.getUserFirstName,
    getUserRoleId: state.getUserRoleId
  };
}

function PendoInitialize(props: ReduxProps) {
  const {
    getSiteCodeDetails,
    getAssessmentDetails,
    getPendoEducatorDetails,
    getUserRole,
    getUserEmail,
    getUserFirstName,
    getUserLastName,
    getUserRoleId
  } = props;
  let dataRetrived = false;
  useEffect(() => {
    if (
      ((getUserRoleId === 3 || getUserRoleId === 4 || getUserRoleId === 5) &&
        getSiteCodeDetails.siteCode !== "" &&
        getAssessmentDetails !== "") ||
      ((getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) && getPendoEducatorDetails.userGuid !== "")
    ) {
      dataRetrived = true;
    }

    if (dataRetrived) {
      //@ts-ignore
      if (window.pendo && window.pendo.initialize) {
        //@ts-ignore
        window.pendo.initialize({
          visitor: {
            id: getPendoEducatorDetails.userGuid, // Required if user is logged in
            email: getUserEmail, // Recommended if using Pendo Feedback, or NPS Email
            full_name: `${getUserFirstName} ${getUserLastName}`, // Recommended if using Pendo Feedback
            role: getUserRole // Optional
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account:
            getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7
              ? {
                  id: getPendoEducatorDetails.userID
                }
              : {
                  id: getPendoEducatorDetails.userID, // Highly recommended
                  siteCode: getSiteCodeDetails.siteCode,
                  siteInstitutionName: getSiteCodeDetails.siteInstitutionName,
                  siteInstitutionId: getSiteCodeDetails.siteInstitutionId,
                  siteInstitutionState: getSiteCodeDetails.siteInstitutionState,
                  siteInstitutionCategory: getSiteCodeDetails.siteInstitutionCategory,
                  siteInstitutionALAType: getSiteCodeDetails.siteInstitutionAlaType,
                  parentInstitutionName: getPendoEducatorDetails.parentInstitutionName,
                  parentInstitutionId: getPendoEducatorDetails.parentInstitutionId,
                  parentInstitutionState: getPendoEducatorDetails.parentInstitutionState,
                  parentInstitutionCategory: getPendoEducatorDetails.parentInstitutionCategory,
                  parentInstitutionALAType: getPendoEducatorDetails.parentInstitutionALAType,
                  userInstitutionCategory: getPendoEducatorDetails.institutionCategory,
                  userInstituionALAType: getPendoEducatorDetails.userInstituionALAType,
                  userInstitutionName: getPendoEducatorDetails.userinstitutionname,
                  userInstitutionID: getPendoEducatorDetails.userInstitutionid,
                  userInstitutionState: getPendoEducatorDetails.userInstitutionState,
                  assessmentProviders: getAssessmentDetails,
                  productsLicensed: getPendoEducatorDetails.productsLicensed,
                  Scheduled_SIS_Imports: getPendoEducatorDetails.ScheduledSISImports,
                  Single_Sign_On: getPendoEducatorDetails.SingleSignOnSettings,
                  Spanish_Lang_Math: getPendoEducatorDetails.SpanishLanguageMathAvailability,
                  Spanish_Lang_Science: getPendoEducatorDetails.SpanishLanguageScienceAvailability,
                  academicSessions: getPendoEducatorDetails.academicSessions
                },
          annotateUrl: function () {
            return { page: localStorage.getItem("page") };
          }
        });
      }
    }
  }, [getSiteCodeDetails, getPendoEducatorDetails.userGuid, dataRetrived, getAssessmentDetails]);
  async function handleHelpClick() {
    await openHelpCenter("https://help.imaginelearning.com/hc/en-us/sections/5082462459543");
  }

  return (
    <>
      <div
        style={{ visibility: "hidden" }}
        id="pendo-close-guide-24875efc"
        onClick={() => {
          handleHelpClick();
        }}
      ></div>
    </>
  );
}

export default connect(mapStateToProps)(PendoInitialize);
