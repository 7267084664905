import SessionChecker from "./SessionChecker";

export default async function SessionHandler() {
  let userdata: {
    username: string;
    token: string;
    session: boolean;
  };
  await SessionChecker().then((data) => {
    if (data === "NO DATA") {
      userdata = {
        username: "No User Found",
        token: "No Token Found",
        session: false
      };
    } else {
      userdata = {
        username: data.profile.username,
        token: data.access_token,
        session: true
      };
    }
  });
  return userdata;
}
