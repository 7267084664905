import React from "react";
import WNECreateModal from "../modals/UserCreateModal/WNECreateModal";
import { ManipILPModalProps } from "./ManipILPModal.props";

export default function ManipILPModal(props: ManipILPModalProps) {
  const { title, message, showModal, closeModal, save } = props;

  return (
    <>
      <WNECreateModal
        showModal={showModal}
        children={message}
        create={save}
        close={closeModal}
        title={title}
        yes="Yes"
        no="Cancel"
      />
    </>
  );
}
