import React from "react";
import { UxModal, UxAction, UxShadesEnum, UxPositionEnum } from "@wne-spa/ux-component-library";

interface ICreateProps {
  className?: string;
  create: () => void;
  close: () => void;
  children: JSX.Element;
  showModal: boolean;
  title: string;
  yes?: string;
  no?: string;
  enabledYes?: boolean;
  isRequired?: boolean;
  footer?: JSX.Element;
}

export default function WNECreateModal({
  className,
  close,
  create,
  children,
  showModal,
  title,
  no,
  yes,
  enabledYes,
  isRequired,
  footer
}: ICreateProps): JSX.Element {
  return (
    <UxModal
      className={className}
      showModal={showModal}
      handleClose={() => {
        close();
      }}
      title={title}
      size="lg"
      scrollable={true}
      body={<>{children}</>}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          {isRequired ? (
            <label className="labelForInput" style={{ fontWeight: 700 }}>
              *Required Field
            </label>
          ) : null}

          {footer ? (
            footer
          ) : (
            <div
              style={{
                justifyContent: "flex-end",
                width: !isRequired ? "100%" : "auto",
                display: "flex"
              }}
            >
              <UxAction
                key={0}
                size="sm"
                enabled={true}
                text={no ? no : "Cancel"}
                className="px-2 py-1 me-2 cursor secondaryLightButton"
                priority={UxShadesEnum.LIGHT}
                callback={() => {
                  close();
                }}
              />

              <UxAction
                key={1}
                size="sm"
                enabled={enabledYes !== undefined ? enabledYes : true}
                text={yes ? yes : "Create"}
                className="px-4 py-1 cursor turkishBlue"
                callback={() => {
                  create();
                }}
              />
            </div>
          )}
        </div>
      ]}
      vPosition={UxPositionEnum.CENTER}
      hPosition={UxPositionEnum.CENTER}
      backdrop="static"
    />
  );
}
