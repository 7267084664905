import React from "react";
import Settings from "../../helpers/constants";

interface ImpersonatorHiddenFormProps {
  ImpersonatedUserId: string;
  PostLogoutRedirectURL: string;
  tabInfo: string;
}

export default function ImpersonatorHiddenForm({
  ImpersonatedUserId,
  PostLogoutRedirectURL,
  tabInfo
}: ImpersonatorHiddenFormProps) {
  return (
    <div style={{ display: "none" }}>
      <form action={`${Settings().authority}/auth/impersonation`} method="post">
        <input type="text" id="impersonatedUserId" name="impersonatedUserId" value={ImpersonatedUserId} readOnly />
        <input
          type="text"
          id="ReturnURL"
          name="ReturnURL"
          value={window.APP_ENV.post_logout_redirect_uri + `/?impersonated=true`}
          readOnly
        />
        <input
          type="text"
          id="PostLogoutRedirectURL"
          name="PostLogoutRedirectURL"
          value={
            window.APP_ENV.WNE_post_logout_redirect_uri +
            `?impersonated=true&Ret=${PostLogoutRedirectURL}${tabInfo ? tabInfo : ""}`
          }
          readOnly
        />
        <input type="submit" id="submitImpersonate" value="login" />
      </form>
    </div>
  );
}

export function BeginImpersonation() {
  const getSubmit = document.getElementById("submitImpersonate");
  getSubmit?.click();
}
