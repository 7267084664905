export default function useGradeBand(grade: string) {
  const isKto2 = ["K", "1", "2"].includes(grade);
  const is3to5 = ["3", "4", "5"].includes(grade);
  const is6to8 = ["6", "7", "8"].includes(grade);
  const is9to12 = ["9", "11", "12", "10"].includes(grade);
  const isKto5 = isKto2 || is3to5;
  const is6to12 = is6to8 || is9to12;

  return {
    isKto2,
    is3to5,
    is6to8,
    is9to12,
    isKto5,
    is6to12
  };
}
