import React from "react";
import "./StudentTab.scss";
import Student from "./students";
import StudentPlacement from "../ClassView/StudentPlacement";
import StudentList from "../UserLists/StudentsList";
import { StudentTabProps } from "./StudentTab.props";

export default function StudentTab(props: StudentTabProps): JSX.Element {
  const { isfrom, orgLevel, isteacher, selectedView, setSelectedView, showStudentSearch } = props;

  if (selectedView === "home") {
    return (
      <div className="student-tab-home">
        <div className="rosterhover" onClick={() => setSelectedView("roster")}>
          Student Roster
        </div>
        <div className="placementhover" onClick={() => setSelectedView("placement")}>
          Student Placement
        </div>
      </div>
    );
  } else if (selectedView === "roster") {
    return showStudentSearch ? <StudentList showTitle={true} /> : <Student isfrom={isfrom} isteacher={isteacher} />;
  } else if (selectedView === "placement") {
    return <StudentPlacement orgLevel={orgLevel} />;
  }
}
