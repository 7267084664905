import {
  Groups,
  Badge,
  Home,
  Description,
  Assessment,
  AdminPanelSettings,
  AutoStories,
  Settings,
  FactCheck,
  CalendarMonth,
  School,
  HelpOutline,
  Map
} from "@mui/icons-material";
import groups_outlined from "@mui/icons-material/GroupsOutlined";
import home_outlined from "@mui/icons-material/HomeOutlined";
import badge_outlined from "@mui/icons-material/BadgeOutlined";
import admin_panel_settings from "@mui/icons-material/AdminPanelSettingsOutlined";
import help_outline from "@mui/icons-material/HelpOutlineOutlined";
import PersonPinCircle from "@mui/icons-material/PersonPinCircle";
import description from "@mui/icons-material/DescriptionOutlined";
import assessment from "@mui/icons-material/AssessmentOutlined";
import fact_check from "@mui/icons-material/FactCheckOutlined";
import calendar_month from "@mui/icons-material/CalendarMonthOutlined";
import settings from "@mui/icons-material/SettingsOutlined";
import auto_stories from "@mui/icons-material/AutoStoriesOutlined";
import school from "@mui/icons-material/SchoolOutlined";
import person_pin_circle from "@mui/icons-material/PersonPinCircleOutlined";
import map_outlined from "@mui/icons-material/MapOutlined";

enum UxMuiIconEnum {
  //@ts-ignore
  SELECTEDBADGE = Badge,
  //@ts-ignore
  BADGE = badge_outlined,

  //@ts-ignore
  ADMINPANELSETTINGS = admin_panel_settings,
  //@ts-ignore
  SELECTEDADMINPANELSETTINGS = AdminPanelSettings,

  //@ts-ignore
  SELECTEDGROUPS = Groups,
  //@ts-ignore
  GROUPS = groups_outlined,

  //@ts-ignore
  SELECTEDHOME = Home,
  //@ts-ignore
  HOME = home_outlined,

  //@ts-ignore
  HELPOUTLINE = help_outline,

  //@ts-ignore
  SELECTEDHELPOUTLINE = HelpOutline,

  //@ts-ignore
  SELECTEDPERSONPINCIRCLE = PersonPinCircle,
  //@ts-ignore
  PERSONPINCIRCLE = person_pin_circle,

  //@ts-ignore
  DESCRIPTION = description,
  //@ts-ignore
  SELECTEDDESCRIPTION = Description,

  //@ts-ignore
  ASSESSMENT = assessment,
  //@ts-ignore
  SELECTEDASSESSMENT = Assessment,

  //@ts-ignore
  FACTCHECK = fact_check,
  //@ts-ignore
  SELECTEDFACTCHECK = FactCheck,

  //@ts-ignore
  AUTOSTORIES = auto_stories,
  //@ts-ignore
  SELECTEDAUTOSTORIES = AutoStories,

  //@ts-ignore
  CALENDERMONTH = calendar_month,
  //@ts-ignore
  SELECTEDCALENDERMONTH = CalendarMonth,

  //@ts-ignore
  SETTINGS = settings,
  //@ts-ignore
  SELECTEDSETTINGS = Settings,

  //@ts-ignore
  SCHOOL = school,
  //@ts-ignore
  SELECTEDSCHOOL = School,

  //@ts-ignore
  DOMAINMAPPING = map_outlined,
  //@ts-ignore
  SELECTEDDOMAINMAPPING = Map
}
export default UxMuiIconEnum;
