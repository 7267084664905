import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library, SizeProp, IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import IconLibraryEnum from "../../shared/enums/icon-library.enum";
import IconProps from "./icon.props";
import UxIconEnum from "../../shared/enums/icon.enum";
import UxSizeEnum from "../../shared/enums/size.enum";
import assetImport from "../../shared/utilities/assetImport";
import classMap from "../../shared/utilities/classMap";
import { SvgIcon } from "@material-ui/core";

const Icon = ({ squared, ...props }: IconProps) => {
  let faUxIcontyle;
  let faIconLibrary;

  switch (props.library) {
    case IconLibraryEnum.REGULAR:
      faUxIcontyle = "far";
      faIconLibrary = far;
      break;
    case IconLibraryEnum.LIGHT:
      faUxIcontyle = "fal";
      faIconLibrary = fal;
      break;
    case IconLibraryEnum.DUOTONE:
      faUxIcontyle = "fad";
      faIconLibrary = fad;
      break;
    case IconLibraryEnum.SOLID:
      faUxIcontyle = "fas";
      faIconLibrary = fas;
      break;
    case IconLibraryEnum.BRAND:
      faUxIcontyle = "fab";
      faIconLibrary = fab;
      break;
    default:
      faUxIcontyle = "fas";
      faIconLibrary = fas;
      break;
  }

  const isFA = (icon: string): boolean => {
    const key: string = icon.toLocaleUpperCase().replace(/-/g, "_");
    return Boolean((UxIconEnum as object)[key]);
  };

  const isMuiIcon = (icon: string) => {
    if (typeof icon === "object") {
      return true;
    } else {
      return false;
    }
  };

  const getSize = (): string =>
    ((
      {
        sm: "12",
        md: "20",
        lg: "30",
        "1x": "40",
        "2x": "60",
        "3x": "90",
        "4x": "150",
        "5x": "200",
        abb: "45"
      } as object
    )[props.size || "1x"]);

  library.add(faIconLibrary);

  if (!props.icon) {
    return null;
  }

  if (isMuiIcon(props.icon)) {
    return (
      <SvgIcon {...props}>
        <props.icon />
      </SvgIcon>
    );
  }

  if (isFA(props.icon)) {
    return (
      <FontAwesomeIcon
        data-testid="fontAwesomeIcon-wrapper"
        icon={[faUxIcontyle as IconPrefix, props.icon as IconName]}
        className={classMap("edge-ux-icon", props.className)}
        size={(props.size !== UxSizeEnum.MEDIUM ? props.size : undefined) as SizeProp}
        style={
          squared
            ? {
                width: Number(getSize()),
                height: Number(getSize())
              }
            : undefined
        }
      />
    );
  }

  return (
    <img
      alt={props.title}
      src={assetImport(props.icon) || props.icon}
      width={getSize()}
      height={squared ? getSize() : undefined}
      className={classMap("edge-ux-icon d-inline-block", props.className)}
    />
  );
};

export default Icon;
