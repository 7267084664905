import React from "react";

import UxAction from "../action";
import EnhancedIcon from "../enhanced-icon";
import Progress from "../progress";
import VisualActionContentProps from "./visual-action-content.props";
import Templates from "./templates";
import classMap from "..//utilities/classMap";
import Background from "../background";
import TemplateProps from "./templates/templates.props";
const VisualActionContent = (props: VisualActionContentProps) => {
  const templateProps: TemplateProps = {
    title: props.title,
    titleClassName: props.titleClassName,
    slogan: props.slogan,
    icon: props.icon && <EnhancedIcon {...props.icon} />,
    action: props.action && <UxAction {...props.action} />,
    progress: props.progress && <Progress {...props.progress} />,
    description: props.description,
    descriptionClassName: props.descriptionClassName,
    background: props.background && <Background {...props.background} className="position-relative w-100" />
  };

  return (
    <div className={classMap("edge-ux-visual-action-content", props.className, "d-flex flex-column", "h-100 m-0")}>
      {(props.template && Templates[props.template](templateProps)) || Templates.Default(templateProps)}
    </div>
  );
};

export default VisualActionContent;
