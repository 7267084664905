import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { TimesIcon, UxAction, UxModal, UxPositionEnum, UxShadesEnum } from "@wne-spa/ux-component-library";
import ky from "ky";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { updateActivityIndicator, updateSchoolGUID } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { LMSService } from "../../services/helpers/lms.services";
import { UserService } from "../../services/helpers/user.services";
import BulkImportsModal from "../modals/bulk-imports";
import ResetPasswordModal from "./ResetPasswordModal";
import { CicsettingsRequestProps, StudentProfileProps } from "./StudentProfile.props";
import "./studentSettings.scss";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getFlagrKeys: state.getFlagrKeys,
    getActivityIndicator: state.getActivityIndicator,
    getCurrentSchoolGuid: state.getCurrentSchoolGuid,
    cicEnable: state.cicEnable,
    globalSubjectPurpose: state.globalSubjectPurpose,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}
interface Props extends ReduxProps, StudentProfileProps { }

function StudentSetting(props: Props): JSX.Element {
  const { t } = useTranslation();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const {
    dispatch,
    getUserRoleId,
    getUserGuId,
    getCurrentSchoolGuid,
    cicEnable,
    getFlagrKeys,
    globalSubjectPurpose,
    getIsOrgHasRegistrationOwned
  } = props;
  const borderColorToReset = "#DDDDDD";
  const borderColorError = "#F24E45";
  const [showStudentDetails, setShowStudentDetails] = useState(true);
  const [editStudentSettings, setEditStudentSettings] = useState(false);
  const [spanishMath, setSpanishMath] = useState(false);
  const [spanishMyPath, setSpanishMyPath] = useState(false);
  const [texttospeechMath, setTexttospeechMath] = useState(false);
  const [texttospeechReading, setTexttospeechReading] = useState(false);
  const [texttospeechSel, setTexttospeechSel] = useState(false);
  const [texttospeechScience, setTexttospeechScience] = useState(false);
  const [languageMath, setLanguageMath] = useState(false);
  const [languageReading, setLanguageReading] = useState(false);
  const [languageSel, setLanguageSel] = useState(false);
  const [languageScience, setLanguageScience] = useState(false);
  const [isScheduleSISImportEnabled, setIsScheduleSISImportEnabled] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isResetPasswordConfirmModalOpen, setIsResetPasswordConfirmModalOpen] = useState(false);
  const [spanishScience, setSpanishScience] = useState(false);

  const [studentSettingRequest, setStudentSettingRequest] = useState<IList[]>([
    { SettingsId: 0, SubjectId: 0, isOn: false }, //TTS Math
    { SettingsId: 0, SubjectId: 0, isOn: false }, //TTS Reading
    { SettingsId: 0, SubjectId: 0, isOn: false }, //TTS SEL
    { SettingsId: 0, SubjectId: 0, isOn: false }, //TTS Science
    { SettingsId: 0, SubjectId: 0, isOn: false }, //LTT Math
    { SettingsId: 0, SubjectId: 0, isOn: false }, //LTT Reading
    { SettingsId: 0, SubjectId: 0, isOn: false }, //LTT SEL
    { SettingsId: 0, SubjectId: 0, isOn: false }, //LTT Science
    { SettingsId: 0, SubjectId: 0, isOn: false }, //Spanish Math
    { SettingsId: 0, SubjectId: 0, isOn: false }, //Spanish Science
    { SettingsId: 0, SubjectId: 0, isOn: false }, //MPA Math
    { SettingsId: 0, SubjectId: 0, isOn: false }, //MPA Reading
    { SettingsId: 0, SubjectId: 0, isOn: false } //Spanish MPA Math
  ]);

  const [studentDetails, setStudentDetails] = useState({
    userId: 0,
    firstName: "",
    lastName: "",
    loginName: "",
    districtAssignedId: "",
    gradeLevelId: 0,
    gradeLevelName: "",
    isEnabled: false,
    schoolName: "",
    orgId: 0,
    orgGuid: "",
    nweaId: "",
    renaissanceId: "",
    iep: 0,
    ell: 0,
    userGuid: "",
    firstLanguageId: 0,
    isSynced: false
  });
  const [districtAssignedId, setDistrictAssignedId] = useState("");
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [showToastblue, setShowToastblue] = useState({ val: false, msg: "" });
  const [IEP, setIEP] = useState(0);
  const [ELL, setELL] = useState(0);
  const [grade, setGrade] = useState("");
  const [NWEAID, setNWEAID] = useState("");
  const [RENAISSANCEID, setRENAISSANCEID] = useState("");
  const [password, setPassword] = useState("");
  const [firstLanguage, setFirstLanguage] = useState("English");
  const [RENAISSANCEIDIssueText, setRENAISSANCEIDIssueText] = useState("");
  const [DistrictAssignedIDIssueText, setDistrictAssignedIDIssueText] = useState("");
  const [DistrictAssignedIDBorder, setDistrictAssignedIDBorder] = useState(borderColorToReset);
  const [NWEAIDBorder, setNWEAIDBorder] = useState(borderColorToReset);
  const [RENAISSANCEIDBorder, setRENAISSANCEIDBorder] = useState(borderColorToReset);
  const [allSchoolList, setAllSchoolList] = useState([]);
  const clientDateTime = moment().format();
  const [showSpanishMathSetting, setShowSpanishMathSetting] = useState(false);
  const [mathEnabled, setMathEnabled] = useState(false);
  const [readingEnabled, setReadingEnabled] = useState(false);
  const [selEnabled, setSelEnabled] = useState(false);
  const [scienceEnabled, setScienceEnabled] = useState(false);
  const [singleSignOnStatus, setSingleSignOnStatus] = useState(false);
  const [showManualPasswordUpdateModal, setShowManualPasswordUpdateModal] = useState(false);
  const [orgMyPathMathEnabled, setOrgMyPathMathEnabled] = useState(false);
  const [orgMyPathReadingEnabled, setOrgMyPathReadingEnabled] = useState(false);
  const [studentMyPathMathEnabled, setStudentMyPathMathEnabled] = useState(false);
  const [studentMyPathReadingEnabled, setStudentMyPathReadingEnabled] = useState(false);
  const [showSpanishScienceSetting, setShowSpanishScienceSetting] = useState(false);
  const [firstLanguageList, setFirstLanguageList] = useState([]);
  const [productAccess, setProductAccess] = useState([]);

  const productMapping = {
    'MyPathMath': 'MyPath Math',
    'MyPathReading': 'MyPath Reading',
    'Purpose': 'Imagine Purpose',
    'Science': 'Imagine Science Corner'
  };

  interface IList {
    SettingsId: number;
    SubjectId: number;
    isOn: boolean;
  }

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#454545",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: 10,
      maxWidth: "none"
    }
  }))(Tooltip);

  useEffect(() => {
    UserService.getStudentFirstLanguageSubjects().then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        (async () => {
          const response = JSON.parse(await data.text());
          setFirstLanguageList(response);
        })();
      } else {
        // Do Nothing
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFirstLanguage(
      firstLanguageList?.find((ele) => ele.languageID === studentDetails?.firstLanguageId)?.languageName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLanguageList, studentDetails?.firstLanguageId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.studentDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            newSetStudentDetailsData(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
        let guid = { orgGuid: id.split("_")[0] };
        dispatch(updateSchoolGUID(id.split("_")[0]));
        await AdminService.getSettingsSsoWithGuid(guid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotSettingsSsoResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        //do nothing
        dispatch(updateActivityIndicator(false));
      }
    };
    dispatch(updateActivityIndicator(true));
    getSchools();
    getAssessmentSettingsDetails();
    fetchData();
    localStorage.setItem("page", "settings");
    // fetchspanishids(getCurrentSchoolID)
    fetchspanishids(getCurrentSchoolGuid);
    setMathEnabled(false);
    setReadingEnabled(false);
    setSelEnabled(false);
    setScienceEnabled(false);
    getLicensedSubjects(getCurrentSchoolGuid);
    fetchMyPathAssessmentSettings(getCurrentSchoolGuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  async function gotSettingsSsoResponse(responsedata: Response) {
    let details = await responsedata.json();
    setSingleSignOnStatus(details.ssoEnabled);
  }

  const fetchspanishids = async (Ids: string) => {
    let apiObj = { guid: Ids };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
        // do nothing
      }
    });
  };
  const getStudenDetails = () => {
    const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
    UserService.studentDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        newSetStudentDetailsData(data);
      } else {
        dispatch(updateActivityIndicator(false));
      }
    });
  };

  const setSpanishScienceAndMyPathFunc = () => {
    return (
      <>
        {orgMyPathMathEnabled && studentMyPathMathEnabled && getFlagrKeys["MPAMathSpanishLanguage"] ? (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    setSpanishMyPath(!spanishMyPath);
                  }}
                  name="subjects"
                  size="small"
                  checked={spanishMyPath && studentMyPathMathEnabled ? true : false}
                />
              }
              //@ts-ignore
              label={"MyPath Math Assessment"}
            />
            <LightTooltip
              placement="right-start"
              style={{ backgroundColor: "white" }}
              title={renderSpanishMyPathToolTip()}
            >
              <i
                className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                style={{ marginTop: "10px", color: "#6E7075" }}
              ></i>
            </LightTooltip>
          </div>
        ) : null}
      </>
    );
  };

  function renderMyPathAssessmentBoxes() {
    return (
      <div className={orgMyPathMathEnabled || orgMyPathReadingEnabled ? "" : "hide"}>
        {orgMyPathMathEnabled ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    setStudentMyPathMathEnabled(!studentMyPathMathEnabled);
                    setSpanishMyPath(false);
                  }}
                  name="subjects"
                  size="small"
                />
              }
              //@ts-ignore
              label={"Math"}
              checked={studentMyPathMathEnabled ? true : false}
            />
          </FormGroup>
        ) : null}
        {orgMyPathReadingEnabled ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    setStudentMyPathReadingEnabled(!studentMyPathReadingEnabled);
                  }}
                  name="subjects"
                  size="small"
                />
              }
              //@ts-ignore
              label={"Reading"}
              checked={studentMyPathReadingEnabled ? true : false}
            />
          </FormGroup>
        ) : null}
      </div>
    );
  }

  function renderMyPathAssesmentSettings() {
    return (
      <div>
        {orgMyPathMathEnabled && (
          <div>
            <label className="newlabelForInput">Spanish Language</label>
            <div>{setSpanishScienceAndMyPathFunc()}</div>
          </div>
        )}
        <div className={orgMyPathMathEnabled || orgMyPathReadingEnabled ? "labelInputHolder pt-20" : "hide"}>
          <div className="settingsFirstRow1">
            <label className="newlabelForInput">MyPath Assessment</label>
            <LightTooltip
              placement="right-start"
              style={{ backgroundColor: "white" }}
              title={renderMyPathAssessmentToolTip()}
            >
              <i className="fa fa-info-circle newfainfoCircleicon infoImportsIconStudent1"></i>
            </LightTooltip>
          </div>
          {renderMyPathAssessmentBoxes()}
        </div>
      </div>
    );
  }

  function renderStudentSettings() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {(orgMyPathMathEnabled || orgMyPathReadingEnabled) && renderMyPathAssesmentSettings()}
      </div>
    );
  }

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: CicsettingsRequestProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.json();
    let i = 0;
    let levelId = { type: "Custom", guid: details.orgGUID.toString() };

    if (details.parents != null) {
      let parentid = details.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };

    fetchCicsettings(json);
  }

  const fetchCicsettings = async (json: CicsettingsRequestProps) => {
    try {
      await AdminService.getCicSettingsWithGuid(json).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotCicSettingsResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      // do nothing
    }
  };
  async function gotCicSettingsResponse(responsedata: Response) {
    let details = await responsedata.json();
    setIsScheduleSISImportEnabled(details.cicImportEnabled);
    setShowSpanishMathSetting(details.spanishLanguageEnabled);
    getFlagrKeys["SpanishLanguageScience"]
      ? setShowSpanishScienceSetting(details.spanishLanguageScienceEnabled)
      : setShowSpanishScienceSetting(false);
  }

  async function getAssessmentSettingsDetails() {
    let orgIds = [];
    orgIds.push(id.split("_")[0]);
    try {
      let OrgIds = { assessmentSettingsObj: orgIds };
      await AdminService.assessmentSettingsdetailWithGuid(OrgIds).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            AssessmentSetting(data);
          } else {
            //do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }
  async function AssessmentSetting(responsedata: Response) {
    let newData = await responsedata.text();
    newData = JSON.parse(newData);
    try {
      if (newData.length === 1) {
        //@ts-ignore
        setrenaissanceEnabled(newData[0].renaissanceEnabled);
      }
    } catch (error) {
      //do nothing
    }
  }

  async function getLicensedSubjects(schoolId: string) {
    try {
      await LMSService.getSubjectsWithGiud(schoolId).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotSubjects(data);
        } else {
          //do nothing
        }
      });
    } catch (error) {
      //do nothing
    }
  }

  async function gotSubjects(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    for (let x in details) {
      if (details[x].subjectName === "Math") {
        setMathEnabled(true);
      } else if (details[x].subjectName === "Reading") {
        setReadingEnabled(true);
      } else if (details[x].subjectName === globalSubjectPurpose) {
        setSelEnabled(true);
      } else if (details[x].subjectName === "Science") {
        setScienceEnabled(true);
      }
    }
  }

  async function fetchMyPathAssessmentSettings(schoolId: string) {
    try {
      let guid = { orgGuid: schoolId };
      await AdminService.mypathAssessmentSettingsWithGuid(guid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setMyPathAssessmentSettingsData(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setMyPathAssessmentSettingsData(responsedata: Response) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    //@ts-ignore
    setOrgMyPathMathEnabled(details.myPathMathEnabled);
    //@ts-ignore
    setOrgMyPathReadingEnabled(details.myPathELAEnabled);
  }

  async function newSetStudentDetailsData(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setGrade(details.gradeLevelName);
    //@ts-ignore

    setNWEAID(details.nweaId === null ? "" : details.nweaId);
    //@ts-ignore
    setRENAISSANCEID(details.renaissanceId === null ? "" : details.renaissanceId);
    //@ts-ignore
    setIEP(details.iep);
    setDistrictAssignedId(details.districtAssignedId === null ? "" : details.districtAssignedId);
    setStudentDetails({
      ...studentDetails,
      ell: details.ell,
      iep: details.iep
    });

    setStudentSettingRequest(details.studentSettingsRequest);

    //@ts-ignore
    setELL(details.ell);
    //@ts-ignore
    setStudentSettingRequest([
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 1
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 1
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 2
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 2
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 5
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 5
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 6
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 6
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 1
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 1
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 2
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 2
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 5
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 5
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 6
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 6
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 1
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 1
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 6
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 6
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 1
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 1
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 2
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 2
        )[0]?.isOn
      },
      {
        SettingsId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 5
        )[0]?.settingID,
        SubjectId: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 5 && studentSettingData.subjectID === 1
        )[0]?.subjectID,
        isOn: details?.studentSettingsRequest?.filter(
          (studentSettingData) => studentSettingData.settingID === 5 && studentSettingData.subjectID === 1
        )[0]?.isOn
      }
    ]);

    setTexttospeechMath(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 1
      )[0]?.isOn
    );
    setTexttospeechReading(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 2
      )[0]?.isOn
    );
    setTexttospeechSel(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 5
      )[0]?.isOn
    );
    setTexttospeechScience(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 1 && studentSettingData.subjectID === 6
      )[0]?.isOn
    );
    setLanguageMath(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 1
      )[0]?.isOn
    );
    setLanguageReading(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 2
      )[0]?.isOn
    );
    setLanguageSel(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 5
      )[0]?.isOn
    );
    setLanguageScience(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 2 && studentSettingData.subjectID === 6
      )[0]?.isOn
    );
    setSpanishMath(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 1
      )[0]?.isOn
    );
    setSpanishScience(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 3 && studentSettingData.subjectID === 6
      )[0]?.isOn
    );
    setStudentMyPathMathEnabled(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 1
      )[0]?.isOn
    );
    setStudentMyPathReadingEnabled(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 2
      )[0]?.isOn
    );
    setSpanishMyPath(
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 5 && studentSettingData.subjectID === 1
      )[0]?.isOn &&
      details?.studentSettingsRequest?.filter(
        (studentSettingData) => studentSettingData.settingID === 4 && studentSettingData.subjectID === 1
      )[0]?.isOn
    );

    setProductAccess(details?.products)

    setStudentDetails(details);


    dispatch(updateActivityIndicator(false));
  }

  async function getSchools() {
    try {
      const oid = id.split("_")[0];
      let apiObj = { uid: getUserGuId, guid: oid };
      AdminService.getSchoolsToMapWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotSchoolsToMapData(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function gotSchoolsToMapData(responsedata: Response) {
    let allData = await responsedata.json();
    let allSchools = allSchoolList;
    for (let x in allData) {
      let exist = allSchools.filter((item: string) => item === allData[x].name);
      if (exist.length === 0) {
        //@ts-ignore
        allSchools.push(allData[x].name);
      }
    }
    setAllSchoolList(allSchools);
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    },
    standardInfo: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));

  const alertclasses = alertStyles();

  function newCancelSaveDetails() {
    setNWEAID(studentDetails.nweaId);
    setDistrictAssignedId(studentDetails.districtAssignedId);
    setRENAISSANCEID(studentDetails.renaissanceId);
    setGrade(studentDetails.gradeLevelName);
    setIEP(studentDetails.iep);
    setELL(studentDetails.ell);
    setEditStudentSettings(false);
    setShowStudentDetails(true);
    setNWEAIDBorder(borderColorToReset);
    setRENAISSANCEIDBorder(borderColorToReset);
    setDistrictAssignedIDBorder(borderColorToReset);
    setRENAISSANCEIDIssueText("");
    setDistrictAssignedIDIssueText("");
    setStudentSettingRequest([
      {
        SettingsId: studentSettingRequest[0].SettingsId,
        SubjectId: studentSettingRequest[0].SubjectId,
        isOn: studentSettingRequest[0].isOn
      },
      {
        SettingsId: studentSettingRequest[1].SettingsId,
        SubjectId: studentSettingRequest[1].SubjectId,
        isOn: studentSettingRequest[1].isOn
      },
      {
        SettingsId: studentSettingRequest[2].SettingsId,
        SubjectId: studentSettingRequest[2].SubjectId,
        isOn: studentSettingRequest[2].isOn
      },
      {
        SettingsId: studentSettingRequest[3].SettingsId,
        SubjectId: studentSettingRequest[3].SubjectId,
        isOn: studentSettingRequest[3].isOn
      },
      {
        SettingsId: studentSettingRequest[4].SettingsId,
        SubjectId: studentSettingRequest[4].SubjectId,
        isOn: studentSettingRequest[4].isOn
      },
      {
        SettingsId: studentSettingRequest[5].SettingsId,
        SubjectId: studentSettingRequest[5].SubjectId,
        isOn: studentSettingRequest[5].isOn
      },
      {
        SettingsId: studentSettingRequest[6].SettingsId,
        SubjectId: studentSettingRequest[6].SubjectId,
        isOn: studentSettingRequest[6].isOn
      },
      {
        SettingsId: studentSettingRequest[7].SettingsId,
        SubjectId: studentSettingRequest[7].SubjectId,
        isOn: studentSettingRequest[7].isOn
      },
      {
        SettingsId: studentSettingRequest[8].SettingsId,
        SubjectId: studentSettingRequest[8].SubjectId,
        isOn: studentSettingRequest[8].isOn
      },
      {
        SettingsId: studentSettingRequest[9].SettingsId,
        SubjectId: studentSettingRequest[9].SubjectId,
        isOn: studentSettingRequest[9].isOn
      },
      {
        SettingsId: studentSettingRequest[10].SettingsId,
        SubjectId: studentSettingRequest[10].SubjectId,
        isOn: studentSettingRequest[10].isOn
      },
      {
        SettingsId: studentSettingRequest[11].SettingsId,
        SubjectId: studentSettingRequest[11].SubjectId,
        isOn: studentSettingRequest[11].isOn
      },
      {
        SettingsId: studentSettingRequest[12].SettingsId,
        SubjectId: studentSettingRequest[12].SubjectId,
        isOn: studentSettingRequest[10].isOn && studentSettingRequest[12].isOn
      }
    ]);
    setTexttospeechMath(studentSettingRequest[0].isOn);
    setTexttospeechReading(studentSettingRequest[1].isOn);
    setTexttospeechSel(studentSettingRequest[2].isOn);
    setTexttospeechScience(studentSettingRequest[3].isOn);
    setLanguageMath(studentSettingRequest[4].isOn);
    setLanguageReading(studentSettingRequest[5].isOn);
    setLanguageSel(studentSettingRequest[6].isOn);
    setLanguageScience(studentSettingRequest[7].isOn);
    setSpanishMath(studentSettingRequest[8].isOn);
    setSpanishScience(studentSettingRequest[9].isOn);
    setStudentMyPathMathEnabled(studentSettingRequest[10].isOn);
    setStudentMyPathReadingEnabled(studentSettingRequest[11].isOn);
    setSpanishMyPath(studentSettingRequest[10].isOn && studentSettingRequest[12].isOn);
    setFirstLanguage(firstLanguageList?.find((ele) => ele.languageID === studentDetails?.firstLanguageId).languageName);
    localStorage.setItem("page", "settings");
  }

  function cancelSaveDetails() {
    newCancelSaveDetails();
  }

  async function newSaveStudentDetails() {
    let StudentSettingsRequest = [
      { SettingID: 1, SubjectID: 1, IsOn: texttospeechMath },
      { SettingID: 1, SubjectID: 2, IsOn: texttospeechReading },
      { SettingID: 1, SubjectID: 5, IsOn: texttospeechSel },
      { SettingID: 1, SubjectID: 6, IsOn: texttospeechScience },
      { SettingID: 2, SubjectID: 1, IsOn: languageMath },
      { SettingID: 2, SubjectID: 2, IsOn: languageReading },
      { SettingID: 2, SubjectID: 5, IsOn: languageSel },
      { SettingID: 2, SubjectID: 6, IsOn: languageScience },
      { SettingID: 3, SubjectID: 1, IsOn: spanishMath },
      { SettingID: 3, SubjectID: 6, IsOn: spanishScience },
      { SettingID: 4, SubjectID: 1, IsOn: studentMyPathMathEnabled },
      { SettingID: 4, SubjectID: 2, IsOn: studentMyPathReadingEnabled },
      {
        SettingID: 5,
        SubjectID: 1,
        IsOn: studentMyPathMathEnabled && spanishMyPath
      }
    ];

    try {
      dispatch(updateActivityIndicator(true));

      const jsonObj = {
        UserId: studentDetails.userId,
        NWEAId: NWEAID,
        RENAISSANCEId: RENAISSANCEID,
        ExternalId: getIsOrgHasRegistrationOwned ? "" : districtAssignedId,
        DistrictAssignedId: districtAssignedId,
        StudentSettingsRequest: StudentSettingsRequest,
        IEP: IEP,
        ELL: ELL,
        ClientDateTime: clientDateTime,
        firstLanguageId: firstLanguageList?.find((ele) => ele.languageName === firstLanguage)?.languageID
      };
      UserService.studentUpdateInfo(jsonObj).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            getStudenDetails();
            setShowToast({ val: true, msg: configMessages.studentupdate });
            setNWEAIDBorder(borderColorToReset);
            setRENAISSANCEIDBorder(borderColorToReset);
            setDistrictAssignedIDBorder(borderColorToReset);
            setRENAISSANCEIDIssueText("");
            setDistrictAssignedIDIssueText("");
            setEditStudentSettings(false);
            localStorage.setItem("page", "settings");
            setStudentSettingRequest([
              { SettingsId: 1, SubjectId: 1, isOn: texttospeechMath },
              { SettingsId: 1, SubjectId: 2, isOn: texttospeechReading },
              { SettingsId: 1, SubjectId: 5, isOn: texttospeechSel },
              { SettingsId: 1, SubjectId: 6, isOn: texttospeechScience },
              { SettingsId: 2, SubjectId: 1, isOn: languageMath },
              { SettingsId: 2, SubjectId: 2, isOn: languageReading },
              { SettingsId: 2, SubjectId: 5, isOn: languageSel },
              { SettingsId: 2, SubjectId: 6, isOn: languageScience },
              { SettingsId: 3, SubjectId: 1, isOn: spanishMath },
              { SettingsId: 3, SubjectId: 6, isOn: spanishScience },
              { SettingsId: 4, SubjectId: 1, isOn: studentMyPathMathEnabled },
              {
                SettingsId: 4,
                SubjectId: 2,
                isOn: studentMyPathReadingEnabled
              },
              {
                SettingsId: 5,
                SubjectId: 1,
                isOn: studentMyPathMathEnabled && spanishMyPath
              }
            ]);
            setShowStudentDetails(true);
          } else {
            updateStudentInfoError(data as ky.HTTPError);
          }
        }
      );
    } catch (err) {
      alert("Error updating student details");
      cancelSaveDetails();
      dispatch(updateActivityIndicator(false));
    }
  }

  async function updateStudentInfoError(responsedata: ky.HTTPError) {
    let responseStudents = await responsedata.response.json();
    try {
      const errMsg = responseStudents.message;
      if (errMsg !== undefined) {
        dispatch(updateActivityIndicator(false));
        if (errMsg === "Unauthorized") {
          alert("Unauthorized to create users");
        } else if (errMsg === "RenaissanceId must be a unique value") {
          setRENAISSANCEIDBorder(borderColorError);
          setRENAISSANCEIDIssueText(configMessages.RENAISSANCEIDValidation);
        } else if (errMsg === "Please enter a unique External ID.") {
          setDistrictAssignedIDBorder(borderColorError);
          setDistrictAssignedIDIssueText(
            getIsOrgHasRegistrationOwned ? configMessages.UniqueStudentId : configMessages.UniqueExternalId
          );
        } else if (errMsg.includes("DuplicateKey")) {
          if (errMsg.includes("StudentNumber") || errMsg.includes("Tag")) {
            setDistrictAssignedIDBorder(borderColorError);
            setDistrictAssignedIDIssueText(
              getIsOrgHasRegistrationOwned ? configMessages.UniqueStudentId : configMessages.UniqueExternalId
            );
          }
        } else {
          // do nothing
        }
      } else {
        alert("Error updating student details");
        cancelSaveDetails();
        dispatch(updateActivityIndicator(false));
      }
    } catch (err) {
      alert("Error updating student details");
      cancelSaveDetails();
      dispatch(updateActivityIndicator(false));
    }
  }

  function renderManualAccountCreateModal() {
    return (
      <div className="manipContainer">
        <div className="bulkHeader">
          <text className="bulkImportText" style={{ marginLeft: "0px" }}>
            {cicEnable ? "Manual Account Updates Suspended" : "Student Update"}
          </text>
          <img
            className="confirmCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowManualPasswordUpdateModal(false);
            }}
          />
        </div>
        <hr></hr>
        <div>
          <text>
            {cicEnable
              ? "Your district manages student accounts via automatic import, so you won't"
              : "This student is managed by a data sync.The Student Profile cannot be updated here."}
            <br />
            <br />
            Contact your Administrator for more information.
          </text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: " flex-end",
            paddingBottom: "10px",
            marginRight: "-20px",
            marginTop: "20px"
          }}
        >
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Got It"}
            className="px-2 py-1 closeBut"
            callback={() => {
              setShowManualPasswordUpdateModal(false);
            }}
          />
        </div>
      </div>
    );
  }

  function renderEditSpanishToolTip() {
    return (
      <text>
        If enabled, this student will receive
        <br />
        Math lessons in Spanish.
      </text>
    );
  }

  function renderTexttospeechToolTip() {
    return (
      <text>
        When On, student can access a Text-to-Speech tool <br />
        that reads aloud instructional and assessment <br />
        content for the subject.
      </text>
    );
  }

  function renderSpanishMyPathToolTip() {
    return (
      <text>
        If enabled, this student will receive
        <br />
        MyPath Math Assessment in Spanish.
      </text>
    );
  }

  function renderLanguageToolTip() {
    return (
      <text>
        When On, student can access a Language Translation <br />
        tool to translate written instructional and assessment <br />
        content for the subject. Available languages may vary.
      </text>
    );
  }

  function renderIepToolTip() {
    return (
      <text>
        Set Individual Education Plan if <br />
        desired for reporting.
      </text>
    );
  }

  function renderEllToolTip() {
    return (
      <text>
        {" "}
        Set English Language Learner if <br />
        desired for reporting.
      </text>
    );
  }

  function renderMyPathAssessmentToolTip() {
    return (
      <text>
        Turn the MyPath Assessment off for students who are
        <br />
        exempt from testing. You will need to manually place
        <br />
        these students.
      </text>
    );
  }

  const openResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  const onResetPasswordModalCofirm = (updatedPassword: string) => {
    setIsResetPasswordModalOpen(false);
    setPassword(updatedPassword);
    setIsResetPasswordConfirmModalOpen(true);
  };

  const closeResetPasswordCofirmModal = () => {
    setIsResetPasswordConfirmModalOpen(false);
    setPassword("");
  };

  const onResetPassword = async () => {
    dispatch(updateActivityIndicator(true));
    try {
      const jsonObj = {
        UserId: studentDetails.userId,
        FirstName: studentDetails.firstName,
        LastName: studentDetails.lastName,
        loginName: studentDetails.loginName,
        NewPassword: password,
        SchoolId: studentDetails.orgId,
        ClientDateTime: clientDateTime,
        SchoolGuid: studentDetails.orgGuid
      };
      const response = await UserService.studentUpdateBasicInfo(jsonObj);

      if (response.status === 200) {
        setShowToast({ val: true, msg: configMessages.studentupdate });
      } else {
        alert("Error updating student details");
      }
    } catch (error) {
      alert("Error updating student details");
    } finally {
      setPassword("");
      dispatch(updateActivityIndicator(false));
    }
  };

  const onResetPasswordCofirmModalApprove = () => {
    setIsResetPasswordConfirmModalOpen(false);
    onResetPassword();
  };

  const iepStudentDetails = () => {
    if (studentDetails.iep === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };
  const ellStudentDetails = () => {
    if (studentDetails.ell === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };
  return (
    <div className="px-4 studentSettingScrollable">
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>

      <div className={alertclasses.standardInfo}>
        <Snackbar
          open={showToastblue.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToastblue({ val: false, msg: "" });
          }}
        >
          <Alert
            severity="info"
            style={{ backgroundColor: "#69a1cf", color: "#fff" }}
            onClose={() => {
              setShowToastblue({ val: false, msg: "" });
            }}
          >
            {showToastblue.msg}
          </Alert>
        </Snackbar>
      </div>

      {showStudentDetails ? (
        <div>
          <div className="classSettings">
            <div>
              <span className="headings"> Student Profile </span>
            </div>
            <div>
              <p className="tagname"> NAME </p>
              {studentDetails.firstName} {studentDetails.lastName}
            </div>
            <div>
              <p className="tagname"> USERNAME </p>
              {studentDetails.loginName}
            </div>
            <div>
              <p className="tagname"> SCHOOL NAME </p>
              {studentDetails.schoolName}
            </div>
            <div>
              <p className="tagname"> PASSWORD </p>
              ***********
            </div>
            {isScheduleSISImportEnabled
              ? !singleSignOnStatus && (
                <span className="student-reset-password-link" onClick={openResetPasswordModal}>
                  Reset Password
                </span>
              )
              : null}
            <div>
              <p className="tagname">GRADE LEVEL </p>
              {studentDetails.gradeLevelName}
            </div>
          </div>

          <div className="classSettings">
            <div>
              <span className="headings"> Student Details </span>
            </div>

            <div>
              <p className="tagname">{getIsOrgHasRegistrationOwned ? "STUDENT ID" : "EXTERNAL ID"} </p>
              {studentDetails.districtAssignedId === "" || studentDetails.districtAssignedId === null
                ? "-"
                : studentDetails.districtAssignedId}
            </div>
            <div>
              <p className="tagname"> NWEA ID </p>
              {studentDetails.nweaId === "" || studentDetails.nweaId === null ? "-" : studentDetails.nweaId}
            </div>
            <div>
              <p className="tagname"> RENAISSANCE ID </p>
              {studentDetails.renaissanceId === "" || studentDetails.renaissanceId === null
                ? "-"
                : studentDetails.renaissanceId}
            </div>
          </div>

          <div className="classSettings">
            <div>
              <span className="headings">Product Access</span>
            </div>

            <div>
              <p className="tagname"> </p>
              {productAccess && productAccess.sort((a, b) => {
                if (productMapping[a] <= productMapping[b]) return -1;
                else return 1;
              }).map((product, index) => (
                <div key={index}>{productMapping[product]}</div>
              ))}
            </div>
          </div>
          <div className="classSettings">
            <div>
              <span className="headings"> Student Settings </span>

              {(orgMyPathMathEnabled || orgMyPathReadingEnabled) && (
                <UxAction
                  callback={() => {
                    setEditStudentSettings(true);
                    setShowStudentDetails(false);
                    localStorage.setItem("page", "StudentSettingsEditForm");
                  }}
                  text={t("Edit")}
                  className="editButton"
                  size={"lg"}
                  enabled={
                    getIsOrgHasRegistrationOwned && getFlagrKeys["ReadinessFeature_Users_Groups_Events"]
                      ? studentDetails.isEnabled
                      : true
                  }
                />
              )}
            </div>

            <div className={mathEnabled || readingEnabled || selEnabled || scienceEnabled ? "" : "hide"}>
              <label className="tagname"> TEXT-TO-SPEECH</label>
              <LightTooltip placement="right-start" title={renderTexttospeechToolTip()}>
                <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
              </LightTooltip>
              <p
                style={{
                  display: mathEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Math:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[0].isOn === true ? "On" : "Off"
                }
              </p>
              <p
                style={{
                  display: readingEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Reading:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[1].isOn === true ? "On" : "Off"
                }
              </p>
              <p
                style={{
                  display: selEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                {"Purpose: "}
                {
                  //@ts-ignore
                  studentSettingRequest[2].isOn === true ? "On" : "Off"
                }
              </p>
              {scienceEnabled &&
                (grade === "K" || grade === "5" || grade === "1" || grade === "2" || grade === "3" || grade === "4") ? (
                <p
                  style={{
                    display: scienceEnabled ? "block" : "none",
                    marginBottom: "5px"
                  }}
                >
                  Science:{" "}
                  {
                    //@ts-ignore
                    studentSettingRequest[3].isOn === true ? "On" : "Off"
                  }
                </p>
              ) : null}
            </div>
            <div className={mathEnabled || readingEnabled || selEnabled || scienceEnabled ? "" : "hide"}>
              <label className="tagname">
                {" "}
                LANGUAGE TRANSLATION TOOLS
                <LightTooltip placement="right-start" title={renderLanguageToolTip()}>
                  <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
                </LightTooltip>
              </label>
              <p
                style={{
                  display: mathEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Math:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[4].isOn === true ? "On" : "Off"
                }
              </p>
              <p
                style={{
                  display: readingEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Reading:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[5].isOn === true ? "On" : "Off"
                }
              </p>
              <p
                style={{
                  display: selEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                {"Purpose: "}
                {
                  //@ts-ignore
                  studentSettingRequest[6].isOn === true ? "On" : "Off"
                }
              </p>
              {scienceEnabled &&
                (grade === "K" || grade === "5" || grade === "1" || grade === "2" || grade === "3" || grade === "4") ? (
                <p
                  style={{
                    display: scienceEnabled ? "block" : "none",
                    marginBottom: "5px"
                  }}
                >
                  Science:{" "}
                  {
                    //@ts-ignore
                    studentSettingRequest[7].isOn === true ? "On" : "Off"
                  }
                </p>
              ) : null}
            </div>

            {getUserRoleId === 5 ? null : (
              <div>
                <label className="tagname">
                  {" "}
                  INDIVIDUAL EDUCATION PLAN (IEP)
                  <LightTooltip placement="right-start" title={renderIepToolTip()}>
                    <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
                  </LightTooltip>
                </label>
                <p
                  style={{
                    marginBottom: "5px"
                  }}
                >
                  {iepStudentDetails()}
                </p>
              </div>
            )}

            <div>
              <label className="tagname">
                {" "}
                ENGLISH LANGUAGE LEARNER (ELL)
                <LightTooltip placement="right-start" title={renderEllToolTip()}>
                  <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
                </LightTooltip>
              </label>
              <p
                style={{
                  marginBottom: "5px"
                }}
              >
                {ellStudentDetails()}
              </p>
            </div>
            {((grade === "K" || grade === "5" || grade === "1" || grade === "2" || grade === "3" || grade === "4") &&
              (mathEnabled || scienceEnabled) &&
              (showSpanishMathSetting || showSpanishScienceSetting)) ||
              (getFlagrKeys["MPAMathSpanishLanguage"] && orgMyPathMathEnabled && studentMyPathMathEnabled) ? (
              <div>
                <label className="tagname">
                  {getFlagrKeys["MPAMathSpanishLanguage"] ? "SPANISH LANGUAGE" : "SPANISH LANGUAGE MATH"}
                </label>
                {!getFlagrKeys["MPAMathSpanishLanguage"] ? (
                  <LightTooltip placement="right-start" title={renderEditSpanishToolTip()}>
                    <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
                  </LightTooltip>
                ) : null}
                {(grade === "K" || grade === "5" || grade === "1" || grade === "2" || grade === "3" || grade === "4") &&
                  showSpanishMathSetting &&
                  mathEnabled ? (
                  <p
                    style={{
                      marginBottom: "5px"
                    }}
                  >
                    {getFlagrKeys["MPAMathSpanishLanguage"] ? `Math Lessons: ` : `Math: `}
                    {
                      //@ts-ignore
                      studentSettingRequest[8].isOn === true ? "On" : "Off"
                    }
                  </p>
                ) : null}
                {getFlagrKeys["MPAMathSpanishLanguage"] && orgMyPathMathEnabled && studentMyPathMathEnabled ? (
                  <p
                    style={{
                      marginBottom: "5px"
                    }}
                  >
                    MyPath Math Assessment:{" "}
                    {
                      //@ts-ignore
                      (studentSettingRequest[10].isOn && studentSettingRequest[12].isOn) === true ? "On" : "Off"
                    }
                  </p>
                ) : null}
                {(grade === "K" || grade === "5" || grade === "1" || grade === "2" || grade === "3" || grade === "4") &&
                  showSpanishScienceSetting &&
                  scienceEnabled &&
                  getFlagrKeys["SpanishLanguageScience"] ? (
                  <p
                    style={{
                      marginBottom: "5px"
                    }}
                  >
                    {`Science Lessons: `}
                    {
                      //@ts-ignore
                      studentSettingRequest[9].isOn === true ? "On" : "Off"
                    }
                  </p>
                ) : null}
              </div>
            ) : null}
            <div className={orgMyPathMathEnabled || orgMyPathReadingEnabled ? "" : "hide"}>
              <label className="tagname"> MYPATH ASSESSMENT </label>
              <LightTooltip placement="right-start" title={renderMyPathAssessmentToolTip()}>
                <i className="fa fa-info-circle infoImportsIconStudentForStudent"></i>
              </LightTooltip>
              <p
                style={{
                  display: orgMyPathMathEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Math:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[10].isOn === true ? "On" : "Off"
                }
              </p>
              <p
                style={{
                  display: orgMyPathReadingEnabled ? "block" : "none",
                  marginBottom: "5px"
                }}
              >
                Reading:{" "}
                {
                  //@ts-ignore
                  studentSettingRequest[11].isOn === true ? "On" : "Off"
                }
              </p>
            </div>
            {getFlagrKeys["ReadinessFeature_Users_Groups_Events"] && (
              <div className="firstLanguage">
                <label className="tagname">First Language</label>
                <p
                  style={{
                    marginBottom: "5px"
                  }}
                >
                  {firstLanguage}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : null}



      {editStudentSettings ? (
        <div className="responsiveSettings" id="editSettingsWrapper">
          <span className="headings" style={{ marginLeft: "15px" }}>
            {" "}
            Edit Student Settings{" "}
          </span>
          <div className="userProfileDetailsHolderForSettings">{renderStudentSettings()}</div>
          <div className="newdesignprofilefooter">
            <div style={{ marginTop: "-70px" }}>
              <hr></hr>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <UxAction callback={() => cancelSaveDetails()} text={t("Cancel")} className="cancelButton" size={"lg"} />

              <UxAction
                callback={() => newSaveStudentDetails()}
                text={t("Save")}
                className="saveButton"
                size={"lg"}
                enabled={
                  IEP !== studentDetails.iep ||
                    ELL !== studentDetails.ell ||
                    texttospeechMath !== studentSettingRequest[0].isOn ||
                    texttospeechReading !== studentSettingRequest[1].isOn ||
                    texttospeechSel !== studentSettingRequest[2].isOn ||
                    texttospeechScience !== studentSettingRequest[3].isOn ||
                    languageMath !== studentSettingRequest[4].isOn ||
                    languageReading !== studentSettingRequest[5].isOn ||
                    languageSel !== studentSettingRequest[6].isOn ||
                    languageScience !== studentSettingRequest[7].isOn ||
                    spanishMath !== studentSettingRequest[8].isOn ||
                    spanishScience !== studentSettingRequest[9].isOn ||
                    studentMyPathMathEnabled !== studentSettingRequest[10].isOn ||
                    studentMyPathReadingEnabled !== studentSettingRequest[11].isOn ||
                    spanishMyPath !== studentSettingRequest[12].isOn ||
                    firstLanguageList?.find((ele) => ele.languageName === firstLanguage)?.languageID !==
                    studentDetails.firstLanguageId
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>
      ) : null}

      {isResetPasswordConfirmModalOpen ? (
        <UxModal
          showModal={true}
          handleClose={() => {
            closeResetPasswordCofirmModal();
          }}
          title={"Confirm Reset?"}
          size="lg"
          scrollable={true}
          body={
            <div className="mb-5">
              <span
                className="name-text"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  lineHeight: 1.5
                }}
              >
                <span
                  style={{
                    margin: "10px 20px",
                    fontWeight: "bold",
                    display: "block"
                  }}
                >
                  {configMessages.scheduledSISImportConfirmationMessage}
                </span>
              </span>
            </div>
          }
          footer={[
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              className="px-2 py-1"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                closeResetPasswordCofirmModal();
              }}
            />,
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Yes"}
              className="px-4 py-1"
              priority={UxShadesEnum.DARK}
              callback={onResetPasswordCofirmModalApprove}
            />
          ]}
          vPosition={UxPositionEnum.CENTER}
          hPosition={UxPositionEnum.CENTER}
          backdrop="static"
        />
      ) : null}
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={closeResetPasswordModal}
        onConfirm={onResetPasswordModalCofirm}
      />
      <BulkImportsModal
        showBulkModal={showManualPasswordUpdateModal}
        children={renderManualAccountCreateModal()}
        width="580px"
        height={"auto"}
      />
    </div>
  );
}

export default connect(mapStateToProps)(StudentSetting);
