import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updateActivityIndicator, updateSchoolGuids, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import DAABuilderList from "./DAABuilderList";
import { AppDispatch } from "../../Redux/Store";
import useOrganizations from "../hooks/useOrganizations";

type AssignmentBuilderStaticProps = {
  dispatch: AppDispatch;
};

function AssignmentBuilderDistricts(props: Readonly<AssignmentBuilderStaticProps>) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch } = props;
  const allSchools = useOrganizations(id);

  useEffect(() => {
    dispatch(updatePageTitle(`- Assignment Builder`));
    const breadcrumb = {
      crumbs: [
        {
          path: `/assignmentbuilder/districts/${id}`,
          name: "Assignment Builder"
        }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
  }, [dispatch, updateBreadCrumbs]);

  useEffect(() => {
    if (allSchools !== null) {
      dispatch(updateSchoolGuids(allSchools));
    }
    dispatch(updateActivityIndicator(false));
  }, [dispatch, allSchools]);

  return (
    <div className="class-nav-tabs">
      <DAABuilderList />
    </div>
  );
}

export default connect()(AssignmentBuilderDistricts);
