import React from "react";
import "./page-not-found.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function PageNotFound() {
  return (
    <>
      <div className="showPageNotFound">
        <div className="fNf">Oops!</div>
        <div className="fNfDec">
          We can't seem to find the
          <br />
          page you're looking for.
        </div>
        <div className="fNfDec404">Error code: 404</div>
        <Link to="/" className="toLogin">
          <i className="fa fa-arrow-left"></i>return to Login
        </Link>
      </div>
    </>
  );
}

export default connect()(PageNotFound);
