import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { updateActivityIndicator, updateBreadCrumbs } from "../../Redux/Action";
import ClassView from "../EducatorView/ClassView";
import "./ClassCards.scss";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}
interface TCView {
  dispatch: AppDispatch;
}

interface Props extends TCView, ReduxProps {}

function TeacherClassView(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch } = props;
  const classTeacherList = [
    {
      class: "Class 01",
      teacher: "Teacher 01",
      id: 1
    },
    {
      class: "Class 02",
      teacher: "Teacher 02",
      id: 2
    },
    {
      class: "Class 03",
      teacher: "Teacher 03",
      id: 3
    },
    {
      class: "Class 04",
      teacher: "Teacher 04",
      id: 4
    },
    {
      class: "Class 05",
      teacher: "Teacher 05",
      id: 5
    }
  ];
  useEffect(() => {
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: "/teacherclasses", name: "Classes" },
        {
          path: `/classview/${id}`,
          name: classTeacherList[parseInt(id) - 1].class
        }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <>
      <ClassView />
    </>
  );
}

export default connect(mapStateToProps)(TeacherClassView);
