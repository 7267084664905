import ky from "ky";
import SessionHandler from "../../authService/SessionHandler";

const fetchToken = async () => {
  const token = await SessionHandler().then((data) => {
    //@ts-ignore
    return data.token;
  });
  return token;
};

const apiClient = ky.create({
  //@ts-ignore
  prefixUrl: window.APP_ENV.lmsApiUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await fetchToken();
        request.headers.set("Authorization", `Bearer ${token}`);
      }
    ],
    afterResponse: [
      (_request, _options, response) => {
        if (_request.url.indexOf("Heartbeat") > -1 && response.status === 404) {
          return new Response(response.body, { status: 203 });
        }
      }
    ]
  }
});

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
