import { assessmenticon35, assessmenticon68, assessmenticon912, assessmenticonk2 } from "@wne-spa/ux-component-library";
import React, { useState, useEffect } from "react";
import "../StudentSubjects/MyAssignmentCard.scss";
import useGrade from "../hooks/useGrade";

interface CardProps {
  handleClick: (args: string) => void;
}

function MyAssignmentCard(props: CardProps) {
  const { handleClick } = props;
  const { isKto2, is3to5, is6to8, is9to12 } = useGrade();
  const [assessmentIcon, setAssessmentIcon] = useState("");
  const [assignmentCardClassName, setAssignmentCardClassName] = useState("");
  const [assignmentTextClassName, setAssignmentTextClassName] = useState("");
  const cardText = "My Assignments";

  useEffect(() => {
    if (isKto2) {
      setAssessmentIcon(assessmenticonk2);
      setAssignmentCardClassName("assignmentcardk2");
      setAssignmentTextClassName("assignmenttext");
    } else if (is3to5) {
      setAssessmentIcon(assessmenticon35);
      setAssignmentCardClassName("assignmentcard35");
      setAssignmentTextClassName("assignmenttext");
    } else if (is6to8) {
      setAssessmentIcon(assessmenticon68);
      setAssignmentCardClassName("assignmentcard68");
      setAssignmentTextClassName("assignmenttext68 assignmenttext612");
    } else {
      setAssessmentIcon(assessmenticon912);
      setAssignmentCardClassName("assignmentcard912");
      setAssignmentTextClassName("assignmenttext912 assignmenttext612");
    }
  }, [isKto2, is3to5, is6to8, is9to12]);

  return (
    <div
      className="assignmentlayout"
      onClick={() => {
        handleClick("Assignment");
      }}
    >
      <div className={`assignmentcard  ${assignmentCardClassName}`} tabIndex={0}>
        <div className={is9to12 ? "iconborder912" : ""}>
          <img src={assessmentIcon} alt="assessmenticon" className={is9to12 ? "iconsize" : "assessmenticon"} />
        </div>
        <div className={assignmentTextClassName}>
          {isKto2 || is3to5 || is6to8 ? cardText.toLocaleUpperCase() : cardText}
        </div>
        {isKto2 || is3to5 ? (
          <div
            className={
              isKto2 ? "assessmentGoButton assessmentbackgroundk2" : "assessmentGoButton  assessmentbackground35"
            }
          >
            <div className="goassessment" aria-hidden="true">
              GO
            </div>
            <div className="arrowmargin">
              <i className="fas fa-arrow-right arrowright"></i>
            </div>
          </div>
        ) : (
          <div
            className={is6to8 ? "gomyassessment68 assessmentbackground68" : "gomyassessment68  assessmentbackground912"}
          >
            <div></div>
            <div className="letsgoassessment" aria-hidden="true">
              Let's Go!
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyAssignmentCard;
