import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ReportsTab.scss";
import {
  updateActivityIndicator,
  updateReportCard,
  updateTabName,
  updateAssessmentTab,
  updateStudentsSubtab,
  updateLevel,
  updatePreviousURLForClassProgress,
  updateSchoolGuids,
  updateClassViewTab,
  updateRoleLevel
} from "../../Redux/Action";
import { UxTabs } from "@wne-spa/ux-component-library";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { ReportsProps, ItemProps, SchoolGuidsResponseProps, GuidResponseProps } from "./Reports.props";
import { RootComponentProps } from "../../root-component.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getClassDetails: state.getClassDetails,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getAssessmentTab: state.getAssessmentTab,
    getUpdateLevel: state.getUpdateLevel,
    getPreviousBenchmarkGrowthReportURL: state.getPreviousBenchmarkGrowthReportURL,
    getLSBReport: state.getLSBReport,
    getUserRoleId: state.getUserRoleId,
    getSchoolGuids: state.getSchoolGuids,
    getFlagrKeys: state.getFlagrKeys,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getClassViewTab: state.getClassViewTab,
    getDaDetails: state.getDaDetails
  };
}
interface Props extends ReportsProps, ReduxProps, RootComponentProps {}

function BenchmarkGrowthReports(props: Props) {
  const {
    dispatch,
    BenchmarkGrowthReportBreadcrumbSubject,
    getClassDetails,
    getBreadCrumbsData,
    getUpdateLevel,
    getLSBReport,
    getUserRoleId,
    getSchoolGuids,
    getPreviousBenchmarkGrowthReportURL,
    getDistrictLevelFirstTab,
    getDaDetails
  } = props;

  const navigate = useNavigate();
  const [tabKey] = useState("reports");
  useEffect(() => {
    dispatch(updateActivityIndicator(false));
    if (!getLSBReport) {
      BenchmarkGrowthReportBreadcrumbSubject.next({
        name: getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      });
    }
  }, []);

  useEffect(() => {
    let level = checkLevel();
    let roleLevel = getRoleLevel(level);
    dispatch(updateRoleLevel(roleLevel));
    localStorage.setItem("roleLevel", roleLevel);
    dispatch(updateLevel(level));
  }, []);

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  function isReportsTabClicked(tab: string, _pagename: string = "") {
    if (tab === "students" || tab === "Assessments") {
      return;
    }
    dispatch(updateReportCard("home"));
    dispatch(updateAssessmentTab("home"));
    dispatch(updateStudentsSubtab("home"));

    if (tab === "sudo-students") {
      dispatch(updateTabName("students"));
      dispatch(updateStudentsSubtab(_pagename));
    } else if (tab === "sudo-Assessments") {
      dispatch(updateTabName("Assessments"));
      dispatch(updateAssessmentTab(_pagename));
    } else if (tab === "institutions" || tab === "school") {
      dispatch(updateTabName("schools"));
    } else if (tab !== "classprogress" && tab !== "classsummary") {
      dispatch(updateTabName(tab));
    }

    if (getUpdateLevel === "school") {
      if (getUserRoleId !== 4 && getUserRoleId !== 5) {
        fetchIds();
      } else {
        navigate(`${getPreviousBenchmarkGrowthReportURL}`);
      }
    } else if (getUpdateLevel === "district") {
      navigate(`${getPreviousBenchmarkGrowthReportURL}`);
    } else if (getUpdateLevel === "class" || getUpdateLevel === "student") {
      if (tab === "classprogress") {
        dispatch(updatePreviousURLForClassProgress(`/classview/${getClassDetails.classId}`));
        navigate("/classprogress");
      } else if (tab === "classsummary") {
        navigate(`/classsummary/${getClassDetails.classId}`);
      } else {
        dispatch(updateTabName(tab));
        dispatch(updateClassViewTab(tab));
        navigate(`/classview/${getClassDetails.classId}`);
      }
    }
  }

  const fetchIds = async () => {
    try {
      let ids = window.location.pathname.split("/");
      let guids: string[] = [];
      guids = [...guids, `${ids[ids.length - 1]}`];
      let data = { guids };
      AdminService.getIds(data).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          guidDetails(data, guids);
        }
      });
    } catch (err) {
      //do nothing
    }
  };
  async function guidDetails(repsonseData: Response, guids: string[]) {
    let details = JSON.parse(await repsonseData.text());
    let filteredData = details.filter((item: GuidResponseProps) => item.organizationGuid === guids[0]);
    let exist = getSchoolGuids.filter((item: SchoolGuidsResponseProps) => item.id === filteredData[0].organizationId);
    if (exist.length === 0) {
      let guids = [...getSchoolGuids, filteredData[0]];
      dispatch(updateSchoolGuids(guids));
    }

    if (getUserRoleId === 3) {
      navigate(`/daschool/${filteredData[0].organizationGuid}`);
    } else {
      navigate(`/school/${filteredData[0].organizationGuid}`);
    }
  }
  const setPage = (pageName: string) => {
    dispatch(updateAssessmentTab("home"));
    setSelectedStudentView(pageName);
    isReportsTabClicked("sudo-students", pageName);
  };
  function setAssessmentTab(tabName: string) {
    isReportsTabClicked("sudo-Assessments", tabName);
  }

  let schoolLevel = ["school", "daschool", "saschool", "saclasslist"];
  let districtLevel = ["dainstitutions", "institutions", "district"];
  let classLevel = ["class", "classview"];
  let studentLevel = ["student"];

  function checkLevel() {
    let urlParts = window.location.pathname.split("/");
    for (let str in urlParts) {
      if (urlParts[str] !== "") {
        if (schoolLevel.includes(urlParts[str])) {
          return "school";
        } else if (classLevel.includes(urlParts[str])) {
          return "class";
        } else if (districtLevel.includes(urlParts[str])) {
          return "district";
        } else if (studentLevel.includes(urlParts[str])) {
          return "student";
        }
      }
    }
  }

  function getRoleLevel(level: string) {
    let roleLevel = "";
    if (!getLSBReport) {
      if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
        roleLevel = level === "district" ? "institutions" : "school";
      } else if (getUserRoleId === 3) {
        roleLevel = level === "district" ? "dainstitutions" : "daschool";
      } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
        roleLevel = "saclasslist";
      } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
        roleLevel = "saclasslist";
      }
    } else {
      roleLevel = "reportcards";
    }
    return roleLevel;
  }

  let classTabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      title: "Student Roster"
    },

    {
      eventKey: "reports",
      title: "Reports"
    },

    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  let schoolTabs = [
    {
      eventKey: "classes",
      tabClassName: "",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      tabClassName: "",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      tabClassName: "",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      tabClassName: "",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      tabClassName: "",
      title: "Settings"
    }
  ];

  let districtLeveltabs = [
    {
      eventKey: "institutions",
      tabClassName: "",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  districtLeveltabs =
    getUserRoleId === 3
      ? districtLeveltabs.filter((item: ItemProps) => item.eventKey !== "licensing")
      : districtLeveltabs;

  const updateChildrenLevel = () => {
    if (getUpdateLevel === "district") {
      return districtLeveltabs;
    } else {
      return classTabs;
    }
  };
  return (
    <>
      {!getLSBReport && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isReportsTabClicked(tab);
            localStorage.setItem("page", tab);
          }}
          children={getUpdateLevel === "school" ? schoolTabs : updateChildrenLevel()}
          className="pt-2"
          currentKey={tabKey}
        />
      )}
      <div className={getLSBReport ? "bar" : "barmargin"} style={{ width: "calc(100vw - 90px)" }}>
        <div className="reportsBar">
          <label className="reportsBarTextSchedule">Benchmark Growth Report</label>
        </div>
        <div>
          <div id="growth-reports"></div>
        </div>
      </div>
    </>
  );
}
export default connect(mapStateToProps)(BenchmarkGrowthReports);
