import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import ProgressProps from "./progress.props";

const Progress = (props: ProgressProps) => {
  enum AllowedVariants {
    INFO = "info",
    SUCESS = "success",
    DANGER = "danger",
    WARNING = "warning"
  }

  const variant = props.variant ? (props.variant as unknown as AllowedVariants) : undefined;
  const label = props.label || props.value + "%";

  return (
    <ProgressBar
      data-testid="mainContainer"
      {...{
        ...props,
        className: "edge-ux-progress " + (props.className || ""),
        now: props.value,
        variant: variant,
        label: label
      }}
    />
  );
};

export default Progress;
