export const getInCorrectHeaderMsg = (data: string) => {
  return `Column headers are not correct for importing ${data}. Please check the file for the correct headers and upload again.`;
};

export const getMissingColumnHeadersMsg = (value: string) => {
  return `Column(s) are missing for importing ${value}. Please check the file for the correct columns and upload again.`;
};

enum configMessages {
  "loginCredentialsValidation" = "Username and password required",
  "resetPasswordUserEmailValidation" = "Please enter the email address associated to your account.",
  "resetPasswordEmptyUserEmailValidation" = "Please provide a properly formatted email address.",
  "forgotPasswordUsernameError" = "We could not find an email address for the entered username. Please enter the correct username.",
  "inValidCredentials" = "You entered an incorrect username, password, or both.",
  "accountDisabled" = "Account access has been disabled. Please work with your school or district administrator to restore access.",
  "WNEUserAccountDisabled" = "Account access has been disabled. Please work with the WNE Administrator to restore access.",
  "organizationDisabled" = "Access temporarily unavailable. Please work with your school or district administrator to restore access.",
  "activateCustomer" = "Please confirm that you want to activate this customer.",
  "customerActivated" = "Customer has been activated",
  "deactivateCustomer" = "Students and Educators associated with the customer you selected will not be able to log in. Please confirm that you want to deactivate this customer.",
  "customerDeactivated" = "Customer has been deactivated",
  "firstNameValidation" = "Please enter a First name.",
  "lastNameValidation" = "Please enter a Last name.",
  "uniqueNameValidation" = "Please enter a unique username.",
  "passwordValidation" = "Please enter a password of at least 8 characters, with 1 uppercase, 1 lowercase, 1 numeral, 1 special character.",
  "confirmPasswordValidation" = "Please input the same password.",
  "emailValidation" = "Please enter a valid Email address.",
  "userTypeValidation" = "Please select a WNE User Type",
  "userNameValidation" = "Please enter a username.",
  "uniqueEmailValidation" = "Email address is already in use. Please enter another valid email address.",
  "educatorTypeValidation" = "Please select an Educator Type.",
  "WNEUserTypeValidation" = "Please select a WNE User Type.",
  "schoolSelectValidation" = "Please select a school.",
  "teacherschoolSelectValidation" = "Please select a school.",
  "GradeLevel" = "Please select a grade level.",
  "studentPasswordValidation" = "Please enter a password of at least 6 characters, any combination.",
  "ClassNameValidation" = "Please enter a class name.",
  "AddTeacherValidation" = "Please select a teacher.",
  "AddStudentsValidation" = "Please select a student.",
  "getCustomerError" = "Unable to retrieve customers.",
  "BIDFormatError" = "District BID value has not been saved. Please check the characters and try again.",
  "BIDError" = "Please enter a BID value.",
  "RPIDFormatError" = "District RPID value has not been saved. Please check the characters and try again.",
  "RPIDError" = "Please enter a RPID value.",
  "classUpdate" = "Class update was successful",
  "bulkRemoveStudents" = "The Students you selected will be removed from the class. Please confirm you want to remove these students.",
  "bulkRemoveStudentsMsg" = "Students have been removed",
  "studentupdate" = "Student update was successful",
  "bulkActivateEducators" = "The Educators you selected will be able to log in. Please confirm that you want to activate these educators.",
  "bulkActivateEducatorsMsg" = "Educators have been activated",
  "bulkDeactivateEducators" = "The Educators you selected will no longer be able to log in. Please confirm that you want to deactivate these educators.",
  "bulkDeactivateEducatorsMsg" = "Educators have been deactivated",
  "bulkActivateStudents" = "The Students you selected will be able to log in and can be added to class rosters. Please confirm that you want to activate these students.",
  "bulkActivateStudentsMsg" = "Students have been activated",
  "bulkDeactivateStudents" = "The Students you selected will no longer be able to log in and will be shown as Inactive in classes. Please confirm you want to deactivate these students.",
  "bulkDeactivateStudentsMsg" = "Students have been deactivated",
  "bulkImportAlert" = "No file uploaded. Please upload file to continue.",
  "bulkStudentUpdateAlert" = "No Student records found. Please check to see if Student records exist.",
  "bulkEducatorUpdateAlert" = "No Educator records found. Please check to see if Educator records exist.",
  "bulkClassUpdateAlert" = "No Class records found. Please check to see if Class records exist.",
  "NWEAIDValidationMsg" = "Please enter a unique value.",
  "RENAISSANCEIDValidation" = "The ID you have entered is not unique.",
  "assignPlacement" = "Placement assigned",
  "editPlacement" = "Placement updated",
  "placeGradeMessage" = "Unable to assign placement grade",
  "deactivateILP" = "ILP deactivated. To undo, select Restore ILP from the student placement.",
  "restoreILP" = "ILP restored",
  "NWEAIdValidation" = "Please enter a unique value.",
  "bulkActivateClasses" = "Activated classes will display as Active on class lists and are included in reports. Please confirm you want to activate these classes.",
  "bulkActivateClassesMsg" = "Classes have been activated",
  "bulkDeactivateClasses" = "Deactivated classes will display as Inactive on class lists and are not included in reports. Students will continue to access their classwork. Please confirm you want to deactivate these classes.",
  "bulkDeactivateClassesMsg" = "Classes have been deactivated",
  "bulkActivateWNEUsers" = "The WNE Users you selected will be able to log in. Please confirm you want to activate these WNE Users.",
  "bulkActivateWNEUsersMsg" = "WNE Users have been activated",
  "bulkDeactivateWNEUsers" = "The WNE Users you selected will no longer be able to log in. Please confirm you want to deactivate these WNE Users.",
  "bulkDeactivateWNEUsersMsg" = "WNE Users have been deactivated",
  "singleSchoolValidation" = "Please select a school.",
  "schoolToDistrictUpdate" = "You are changing a School Administrator to a District Administrator. Any schools associated to the School Administrator are removed from the user’s profile, but the School Administrator is not removed from any associated classes at those schools. Please confirm that you want to change a School Administrator to a District Administrator.",
  "schoolToTeacherUpdate" = "You are changing a School Administrator to a Teacher. Any schools associated to the School Administrator will remain for the Teacher and the School Administrator is not removed from any associated classes at those schools. Please confirm that you want to change a School Administrator to a Teacher.",
  "teacherToDistrictUpdate" = "You are changing a Teacher to a District Administrator. Any schools associated to the Teacher are removed from the user’s profile, but the Teacher is not removed from any associated classes at those schools. Please confirm that you want to change a Teacher to a District Administrator.",
  "teacherToSchoolUpdate" = "You are changing a Teacher to a School Administrator. Any schools associated to the Teacher will remain and the Teacher is not removed from any associated classes at those schools. Please confirm that you want to change a Teacher to a School Administrator.",
  "districtToSchoolUpdate" = "You are changing a District Administrator to a School Administrator. If the District Administrator has assigned classes at any schools not selected, the educator is removed from those classes. Please confirm that you want to change a District Administrator to a School Administrator.",
  "districtToTeacherUpdate" = "You are changing a District Administrator to a Teacher. If the District Administrator has assigned classes at any schools not selected, the educator is removed from those classes. Please confirm that you want to change a District Administrator to a Teacher.",
  "educatorResetPassword" = "Please confirm that you want to reset this user's password. The user will receive an email with instructions.",
  "educatorPasswordReset" = "Password reset link has been sent",
  "studentSchoolChange" = "The student will be removed from any classes in the school they are leaving and will need to be added to classes at the school they are joining in order for teachers to manage them. Please confirm that you want to change the student's associated school.",
  "subjectValidation" = "Please select one or more subjects.",
  "removeStudentFromClass" = "The Student you selected will be removed from the class. Please confirm you want to remove this student.",
  "removeStudentFromClassMsg" = "Student has been removed",
  "updatedNWEAID" = "Assessment ID successfully updated",
  "noAssessmentNWEAID" = "No assessment results found for this ID",
  "deactivateIndividualClass" = "The class you selected will display as Inactive on class lists and is not included in reports. Please confirm you want to deactivate this class.",
  "deactivateIndividualClassMsg" = "Class has been deactivated",
  "activateIndividualClass" = "The class you selected will appear as Active on class lists and is included in reports. Please confirm that you want to activate this class.",
  "activateIndividualClassMsg" = "Class has been activated",
  "deactivateIndividualWNEUser" = "The WNE User you selected will no longer be able to log in. Please confirm you want to deactivate this WNE User.",
  "deactivateIndividualWNEUserMsg" = "WNE User has been deactivated",
  "activateIndividualWNEUser" = "The WNE User you selected will be able to log in. Please confirm that you want to activate this WNE User.",
  "activateIndividualWNEUserMsg" = "WNE User has been activated",
  "deactivateIndividualStudent" = "The student you selected will no longer be able to log in and will be shown as Inactive in classes. Please confirm you want to deactivate this student.",
  "deactivateIndividualStudentMsg" = "Student has been deactivated",
  "activateIndividualStudent" = "The student you selected will be able to log in and can be added to class rosters. Please confirm that you want to activate this student.",
  "activateIndividualStudentMsg" = "Student has been activated",
  "deactivateIndividualEducator" = "The educator you selected will no longer be able to log in. Please confirm you want to deactivate this educator.",
  "deactivateIndividualEducatorMsg" = "Educator has been deactivated",
  "activateIndividualEducator" = "The educator you selected will be able to log in. Please confirm that you want to activate this educator.",
  "activateIndividualEducatorMsg" = "Educator has been activated",
  "profileUpdateMsg" = "Profile update was successful",
  "educatorUpdateMsg" = "Educator update was successful",
  "educatorCreationMsg" = "Educator creation was successful",
  "classCreationMsg" = "Class creation was successful",
  "BIDSaveMsg" = "District BID saved",
  "RPIDSaveMsg" = "District RPID saved",
  "studentCreationMsg" = "Student creation was successful",
  "districtLevelActivate" = "By activating this customer, all levels of the customer’s organization will automatically be activated. Please confirm that you want to activate this customer.",
  "institutionLevelActivate" = "By activating this institution, all institutions and schools below it in the customer’s organization will also be activated. Please confirm that you want to activate this institution.",
  "schoolLevelActivate" = "Please confirm that you want to activate this school.",
  "districtLevelDeactivate" = "By deactivating this customer, all levels of the customer’s organization will automatically be deactivated and Students and Educators cannot log in. Please confirm that you want to deactivate this customer.",
  "institutionLevelDeactivate" = "By deactivating this institution, all institutions below it in the organization will automatically be deactivated and Students and Educators associated to those institutions cannot log in. Please confirm that you want to deactivate this institution.",
  "schoolLevelDeactivate" = "Students and Educators associated with this individual school will not be able to log in. Educators with multiple school associations can log in, but will not see this individual school’s data. Please confirm that you want to deactivate this school.",
  "districtLevelActivateMsg" = "Customer has been activated",
  "institutionLevelActivateMsg" = "Institution has been activated",
  "schoolLevelActivateMsg" = "School has been activated",
  "districtLevelDeactivateMsg" = "Customer has been deactivated",
  "institutionLevelDeactivateMsg" = "Institution has been deactivated",
  "schoolLevelDeactivateMsg" = "School has been deactivated",
  "districtAdminNoChange" = "Because this Educator is associated to a top-level District, the Type cannot be changed.",
  "successfullFileValidation" = "Congratulations! Our quick data check found no errors and this file is ready to upload for final validation.",
  "fileformatErr" = "Please save your file as a .csv file type and upload again",
  "moreRecordCount" = "Please break up your file to upload 5,000 or fewer records",
  "noRecordFound" = "No record in File",
  "successfullFileUpload" = "File successfully uploaded",
  "noDistrictAdministrator" = "You currently do not have any district administrators. You can add them by clicking the 'Create District Admin' button.",
  "noEducators" = "You currently do not have any educators. You can add them by clicking the 'Create Educator' button.",
  "noStudents" = "You currently do not have any students. You can add them by clicking the 'Create Student' button.",
  "noClasses" = "You currently do not have any classes. You can add them by clicking the 'Create Class' button.",
  "noEducatorsNoEdit" = "You currently do not have any educators.",
  "noStudentsNoEdit" = "You currently do not have any students.",
  "noClassesNoEdit" = "You currently do not have any classes.",
  "wneAdmintowneSupport" = "You are changing a WNE Admin user to a WNE Support user.  Please confirm that you want to change a WNE Admin user to a WNE Support user.",
  "wneSupporttowneAdmin" = "You are changing a WNE Support user to a WNE Admin user.  Please confirm that you want to change a WNE Support user to a WNE Admin user.",
  "wneAdmintowneHelpDesk" = "You are changing a WNE Admin user to a WNE HelpDesk user.  Please confirm that you want to change a WNE Admin user to a WNE HelpDesk user.",
  "wneHelpDesktowneAdmin" = "You are changing a WNE HelpDesk user to a WNE Admin user.  Please confirm that you want to change a WNE HelpDesk user to a WNE Admin user.",
  "wneHelpDesktowneSupport" = "You are changing a WNE HelpDesk user to a WNE Support user.  Please confirm that you want to change a WNE HelpDesk user to a WNE Support user.",
  "wneSupporttowneHelpDesk" = "You are changing a WNE Support user to a WNE HelpDesk user.  Please confirm that you want to change a WNE Support user to a WNE HelpDesk user.",
  "wneuserupdatemsg" = "WNE User update was successful",
  "extraColumnError" = "File Contains Duplicate Columns",
  "WNEfirstNameValidation" = "Please enter a First name.",
  "WNElastNameValidation" = "Please enter a Last name.",
  "WNEemailValidation" = "Please enter a valid Email address.",
  "UniqueExternalId" = "Please enter a unique External ID",
  "ExternalIdValidation" = "External Institution ID should be unique.",
  "ParentUniqueExternalId" = "External Id Should be unique than ParentID",
  "bulkUpdateIncorrectHeader" = "Column headers are not correct. Please check the file for the correct headers and upload again.",
  "bulkUpdateMissingHeader" = "Column(s) are missing. Please check the file for the correct columns and upload again.",
  "studentCreationValidation" = "Student could not be created. Please check the highlighted fields and try again.",
  "enableReading" = "Enabling Text-to-Speech may invalidate the results of Reading assessments.",
  "enableReadingLanguage" = "Enabling Language Translation may invalidate the results of Reading assessments.",
  "specialCharacterColumnError" = "Invalid data inserted in a column. Please check and upload again.",
  "loginCodeRequired" = "Please enter a Login Code.",
  "loginCodeMassage" = "If a previous Login Code was issued, users will need to be notified of the change to log in.Please confirm you want to change the Login Code.",
  "loginCodeSuccess" = "Organization setting update was successful",
  "loginCodeUnique" = "Please enter a unique value.",
  "loginCodeModalTitle" = "You are changing the Login Code.",
  "licenseUpdatedMsg" = "Licenses have been updated.",
  "invalidCreds" = "You entered an incorrect username, password, or login code.",
  "invalidSitecode" = "You entered an incorrect username, password, or login code.",
  "invalidCredentials" = "Sorry, but we could not find an account that matches the entered Username, Password or Login Code. You can close this message.",
  "clientIdRequired" = "Please enter a Client ID.",
  "clientIdUnique" = "Please enter a unique Client ID.",
  "clientSecretRequired" = "Please enter a Client Secret.",
  "SingleSignOnSuccess" = "Organization setting update was successful.",
  "AuthenticatorErrorMessage" = "If you are trying to log in simultaneously to a different user account, this is not supported. Try a different browser or open a new incognito window.",
  "scheduledSISImportConfirmationMessage" = "Important: This district manages student accounts via automatic import. The new password will be reset to the password in the external source with the automatic import.",
  "quickPlacementCompletedMsg" = "Quick Placement is Complete.",
  "quickPlacementInProgressMsg" = "Quick Placement in progress.",
  "quickPlacementInfo" = "Quick Placement in progress. Click the Refresh button to check for updated placements.",
  "DeactivateClassesErrorMessage" = "Error in Deactivating Classes",
  "ActivateClassesErrorMessage" = "Error in Activating Classes",
  "DeactivateClassErrorMessage" = "Error in Deactivating Class",
  "ActivateClassErrorMessage" = "Error in Activating Class",
  "ClassExternalIdErrorMessage" = "Class External ID already exists.",
  "SAConfirmMessage" = "If you removed any schools, the School Administrator will be removed from any assigned classes at those schools. Please confirm you want to change the schools.",
  "TeacherConfirmMessage" = "If you removed any schools, the Teacher will be removed from any assigned classes at those schools. Please confirm you want to change the schools.",
  "BulkPlacementMax" = "The maximum number of placements in a Bulk Action is limited to 500. You are approaching the maximum, please initiating your Bulk Action before exceeding the maximum.",
  "BulkPlacementExceeds" = "You have exceeded the maximum number of placement records for a Bulk Action. Please reduce the number of placements to 500 to be able to initiate a Bulk Action.",
  "AllowSpacialChar" = "Please enter a valid username with allowed special characters.",
  "DeactivateStudentsErrorMessage" = "Error in Deactivating Students",
  "ActivateStudentsErrorMessage" = "Error in Activating Students",
  "DeactivateStudentErrorMessage" = "Error in Deactivating Student",
  "ActivateStudentErrorMessage" = "Error in Activating Student",
  "DeactivateEducatorsErrorMessage" = "Error in Deactivating Educators",
  "ActivateEducatorsErrorMessage" = "Error in Activating Educators",
  "DeactivateEducatorErrorMessage" = "Error in Deactivating Educator",
  "ActivateEducatorErrorMessage" = "Error in Activating Educator",
  "academicSessionNote" = "The Reset Date can be edited at the District level, but not at the School level. Editing the Reset Date is possible up to the Reset Date, but not on or after the date.",
  "academicClockBarNote" = "School reset automatically occurs at 12:00 AM Pacific Daylight Time (PDT) on the reset date.",
  "passwordValdiationMessage" = "Please enter a password of less than 32 characters",
  "studentRosterExitScreenMessage" = "You’ve selected student(s) to add to your class. If you exit this screen, your selections won’t be saved.",
  "createStudentExitScreenMessage" = "You’ve started creating a student. If you exit this screen, the student won’t be created.",
  "UsernameMinMaxCharacterCheckMessage" = "Username should be atleast 4 characters.",
  "ExternalIdMaxChar" = "External institution id should be less than 64 characters",
  "ExternalIdMinChar" = "External institution id should be mininum of 1 character.",
  "LoginCodeUpdateSuccess" = 'Login Code update will take sometime. You will not be able to Edit the login code again until this update is completed. Click on the "Refresh" button to know the update status.',
  "InternalServerError" = "Internal Server Error.",
  "EducatorWithMultipleSchoolLoginCodeUpdateMessage" = "Login code cannot be updated as an Educator of this org belongs to another org with same Login code",
  "CreateStudentpasswordValdiationMax" = "Please enter a password of less than 256 characters",
  "CreateStudentPasswordValidationMin" = "Please enter a password of at least 4 characters, any combination.",
  "CreateStudentPasswordValidationControlCharacter" = "Password cannot contain a control character",
  "CreateEducatorpasswordValdiationMax" = "Please enter a password of less than 256 characters",
  "CreateEducatorPasswordValidationMin" = "Please enter a password of at least 8 characters, any combination.",
  "CreateEducatorPasswordValidationControlCharacter" = "Password cannot contain a control character",
  "NewInvalidCredentials" = "Sorry, but we could not find an account that matches the entered Username, Password or Login Code.\nUsernames will now match the account email address and may have been updated. If this is the first time you are logging in this school year, please reset your password before attempting to log in.\nIf you are having issues logging in, please contact Imagine Learning support.You can close this message.",
  "UsernameEmailMinLengthValidation" = "Please enter a username/email of at least 4 characters.",
  "UsernameEmailMaxLengthValidation" = "Please enter a username/email of less than 360 characters",
  "UserNameEmailValidation" = "Please enter a username/email.",
  "UserEmailValidation" = "Please enter a valid username/email address.",
  "UniqueUserNameEmail" = "Please enter a unique username/email.",
  "AcademicSessionReadOnlyMessage" = "The Reset Date will be set at the District level. Please reach out to your Customer Success Manager with any questions or to request a different reset date.",
  "DeactivateClassFromRegistration" = "Inactivating this class will also remove it from any associated platforms.",
  "DeactivateEducatorFromRegistration" = "Inactivating this user will also remove the account from any associated platforms",
  "DeactivateStudentFromRegistration" = "Inactivating this student will also remove the account from any associated platforms.",
  "ActivateEducatorFromRegistration" = "The educator you selected will need to reset their password to log in. Please confirm that you want to activate this educator.",
  "UniqueStudentId" = "Please enter a unique Student ID",
  "StudentIDValidation" = "Please enter a Student ID."
}

export default configMessages;
