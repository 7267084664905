import React from "react";
import "./student_activity_indicator.scss";
import LoaderIcon from "../../assets/icons/icon_loader.svg";

type Props = {
  type: string;
  opacity: string;
  position;
};

export default function Loader(props: Props) {
  return (
    <div
      className={props.type === "nouser" ? "flex1" : "flex"}
      style={{
        position: props.position ? props.position : "fixed !important",
        backgroundColor: props.opacity ? `rgba(5, 4, 33, ${props.opacity})` : "rgba(5, 4, 33, 0.49)"
      }}
    >
      <div className="loader">
        <label className="loaderText">Building your learning path</label>
        <img src={LoaderIcon} alt="loader" width="46px" height="46px" className="dot" />
      </div>
    </div>
  );
}
