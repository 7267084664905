/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  UxAction,
  UxMUITable,
  UxShadesEnum,
  UxPaginate,
  UxPaginationController,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxIconLibraryEnum,
  TimesIcon,
  ImportIcon,
  UploadIcon,
  RefreshIcon,
  DownloadArrow
} from "@wne-spa/ux-component-library";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme, withStyles } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { updateActivityIndicator, updateBulkListSort } from "../../Redux/Action";
import "./BulkImports.scss";
import BulkImportModal from "../modals/bulk-imports";
import Tooltip from "@material-ui/core/Tooltip";
import { paginationControllers } from "../../helpers/configs";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import { LMSService } from "../../services/helpers/lms.services";
import configMessages, { getInCorrectHeaderMsg, getMissingColumnHeadersMsg } from "../../helpers/configMessages";
import {
  IBulkImports,
  BulkImportProps,
  BulkImportFileDetailsDataProps,
  BulkImportFetchCicsettingsProps,
  BulkImportTableRowDataValuesProps,
  BulkImportRowCustomErrorOptionCallbackProps
} from "./BulkImport.props";
import ReduxProps from "../../Redux/Redux.props";
import ky from "ky";

interface Props extends ReduxProps, BulkImportProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getBulkListSort: state.getBulkListSort,
    getUserGuId: state.getUserGuId,
    globalSubjectPurpose: state.globalSubjectPurpose
  };
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#454545",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: 10,
    maxWidth: "none",
    marginRight: 30
  }
}))(Tooltip);

function BulkImports(props: Props): JSX.Element {
  const { t } = useTranslation();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, isfrom, getBulkListSort, getUserGuId, globalSubjectPurpose } = props;
  const [showModalIntro, setShowModalIntro] = useState(false);
  const [showModalStudent, setShowModalStudent] = useState(false);
  const [showModalSummary, setShowModalSummary] = useState(false);
  const [showModalEducators, setShowModalEducators] = useState(false);
  const [showModalEducatorsSummary, setShowModalEducatorsSummary] = useState(false);
  const [showModalClasses, setShowModalClasses] = useState(false);
  const [showModalClassSummary, setShowModalClassSummary] = useState(false);

  const [showUploadModalStudent, setShowUploadModalStudent] = useState(false);
  const [showUploadModalSummary, setShowUploadModalSummary] = useState(false);

  const [showUploadModalEducator, setShowUploadModalEducator] = useState(false);
  const [showUploadModalEducatorsSummary, setShowUploadModalEducatorsSummary] = useState(false);

  const [showUploadModalClass, setShowUploadModalClass] = useState(false);
  const [showUploadModalClassSummary, setShowUploadModalClassSummary] = useState(false);

  const [showModalFixError, setShowModalFixError] = useState(false);
  const [errorRecordType, setErrorRecordType] = useState<string>("");
  const [showModalFixSummary, setShowModalFixSummary] = useState(false);

  const [showModalFileDetails, setShowModalFileDetails] = useState(false);

  const fileDetailsData = {
    fileName: "",
    recordType: "",
    name: "",
    stime: "",
    ctime: "",
    errors: 0,
    status: "",
    successCount: 0
  };

  const [userFileDetails, setUserFileDetails] = useState<BulkImportFileDetailsDataProps>(fileDetailsData);

  const [fileName, setFileName] = useState("");
  const [emptyTableText, setEmptyTableText] = useState("");
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const columnNames = ["FILE NAME", "RECORD TYPE", "UPLOAD TYPE", "USER", "SUBMITTED DATE/TIME", "ERRORS", "STATUS"];
  const rowMapper = [["fileName"], "recordType", "uploadType", "name", "stime", "error", "status"];
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [fileData, setFileData] = useState<File>();
  const [tooltipData, setToolTipData] = useState();
  const [fileNameBool, setFileNameBool] = useState<boolean | null>(null);
  const [typeBool, setTypeBool] = useState<boolean | null>(null);
  const [userBool, setUserBool] = useState<boolean | null>(null);
  const [submitBool, setSubmitBool] = useState<boolean | null>(null);
  const [updateTypeBool, setUpdateTypeBool] = useState<boolean | null>(null);
  const [pageNo, setPageNo] = useState(1);
  const [pController, setPController] = useState(50);
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });

  const [enableStudentsUpload, setEnableStudentsUpload] = useState(false);
  const [enableEducatorsUpload, setEnableEducatorsUpload] = useState(false);
  const [enableClassesUpload, setEnableClassesUpload] = useState(false);
  const classesCSV = "School ID,Class External ID,ClassName,TeacherUsername,Subject,StudentUsername";
  const educatorsCSV = "School ID,Last Name,First Name,Username,Password,External ID,Email,Educator Type";
  const studentsCSV = `School ID,Last Name,First Name,Username,Password,Grade Level,External ID,NWEA ID,iep,ell,Math Placement,Reading Placement,Renaissance ID,Math Text to Speech,Reading Text to Speech,${globalSubjectPurpose} Text to Speech,Science Text to Speech,Math Text Translation,Reading Text Translation,${globalSubjectPurpose} Text Translation,Science Text Translation,Spanish Language Math,Spanish Language Science,MyPath Math Assessment,MyPath Reading Assessment,Spanish Language MyPath Math Assessment`;

  const sortBy = useRef(getBulkListSort.name);
  const sortOrder = useRef(getBulkListSort.val);
  const downloadFile = useRef({ id: 0, type: 0, utype: 0, fname: "" });
  const [isCICEnabled, setIsCICEnabled] = useState<boolean>(false);
  const [showManualAccountCreateModal, setShowManualAccountCreateModal] = useState(false);

  function sortChange(type: string) {
    switch (type) {
      case "SUBMITTED DATE/TIME":
        sortOrder.current = !submitBool ? "DESC" : "ASC";
        sortBy.current = "submitteddate";
        break;
      case "FILE NAME":
        sortOrder.current = !fileNameBool ? "DESC" : "ASC";
        sortBy.current = "filename";
        break;
      case "RECORD TYPE":
        sortOrder.current = !typeBool ? "DESC" : "ASC";
        sortBy.current = "recordtype";
        break;
      case "UPLOAD TYPE":
        sortOrder.current = !updateTypeBool ? "DESC" : "ASC";
        sortBy.current = "uploadtype";
        break;
      default:
        sortOrder.current = !userBool ? "DESC" : "ASC";
        sortBy.current = "lastname";
    }
    dispatch(updateActivityIndicator(true));
    getBulkImportList(1);
  }

  async function getBulkImportList(pageNum: number, pageSize: number = pController) {
    let bulkJson = {
      Page: pageNum,
      PageSize: pageSize,
      SortBy: sortBy.current,
      SortOrder: sortOrder.current
    };
    dispatch(updateBulkListSort({ name: sortBy.current, val: sortOrder.current }));
    if (isfrom === "school") {
      //@ts-ignore
      bulkJson.orgGuid = id;
    }

    AdminService.bulkImportListWithGuid(bulkJson).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotResponse(data);
      } else {
        setEmptyTableText("No records found");
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
        dispatch(updateActivityIndicator(false));
      }
    });
  }

  const bulkListSortFunc = () => {
    if (getBulkListSort.val === "DESC" || getBulkListSort.val === "desc") {
      switch (getBulkListSort.name) {
        case "submitteddate":
          setSubmitBool(true);
          break;
        case "filename":
          setFileNameBool(true);
          break;
        case "recordtype":
          setTypeBool(true);
          break;
        case "uploadtype":
          setUpdateTypeBool(true);
          break;
        default:
          setUserBool(true);
      }
    } else if (getBulkListSort.val === "ASC" || getBulkListSort.val === "asc") {
      switch (getBulkListSort.name) {
        case "submitteddate":
          setSubmitBool(false);
          break;
        case "filename":
          setFileNameBool(false);
          break;
        case "recordtype":
          setTypeBool(false);
          break;
        case "uploadtype":
          setUpdateTypeBool(false);
          break;
        default:
          setUserBool(false);
      }
    }
  };

  useEffect(() => {
    if (window.innerHeight > 650) {
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    bulkListSortFunc();
    dispatch(updateActivityIndicator(true));
    getBulkImportList(1);
    fetchspanishids(id.split("_")[0]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const fetchspanishids = async (Ids: string) => {
    let apiObj = { guid: Ids };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
        // do nothing
      }
    });
  };

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: BulkImportFetchCicsettingsProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.text();
    let i = 0;
    let details1 = JSON.parse(details);
    let levelId = { type: "Custom", guid: details1.orgGUID.toString() };

    if (details1.parents != null) {
      let parentid = details1.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };

    fetchCicsettings(json);
  }

  const fetchCicsettings = async (json: BulkImportFetchCicsettingsProps) => {
    try {
      await AdminService.getCicSettingsWithGuid(json).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotCicSettingsResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      // do nothing
    }
  };
  async function gotCicSettingsResponse(responsedata: Response) {
    let details = await responsedata.json();
    setIsCICEnabled(details.cicImportEnabled);
  }
  const sortByFunc = () => {
    switch (sortBy.current) {
      case "submitteddate":
        setFileNameBool(null);
        setTypeBool(null);
        setUserBool(null);
        setUpdateTypeBool(null);
        break;
      case "filename":
        setSubmitBool(null);
        setTypeBool(null);
        setUserBool(null);
        setUpdateTypeBool(null);
        break;
      case "recordtype":
        setSubmitBool(null);
        setFileNameBool(null);
        setUserBool(null);
        setUpdateTypeBool(null);
        break;
      case "uploadtype":
        setSubmitBool(null);
        setTypeBool(null);
        setUserBool(null);
        setFileNameBool(null);
        break;
      default:
        setFileNameBool(null);
        setTypeBool(null);
        setSubmitBool(null);
        setUpdateTypeBool(null);
    }
  };
  const calculatingCreatedTime = (allData, x: string) => {
    if (allData.values[x].completedDate === null) {
      //do nothing
    } else {
      let etime = "";
      let edate = new Date(allData.values[x].completedDate);
      etime = edate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
      let emonth = edate.getMonth() + 1;
      let eday = edate.getDate();
      let eyear = edate.getFullYear();
      let eResponseMonth = emonth < 10 ? `0${emonth}` : emonth;
      let eResponseDay = eday < 10 ? `0${eday}` : eday;
      allData.values[x].ctime = `${eResponseMonth}/${eResponseDay}/${eyear} ${etime}`;
    }
  };
  async function gotResponse(detail: Response) {
    const allData = JSON.parse(await detail.text());
    sortByFunc();
    if (allData.size === 0) {
      setEmptyTableText("No File Uploaded");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      for (let x in allData.values) {
        allData.values[x].name = `${allData.values[x].lastName}, ${allData.values[x].firstName}`;
        let ntime = "";
        let date = new Date(allData.values[x].submittedDate);
        ntime = date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        });
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let year = date.getFullYear();
        let responseMonth = month < 10 ? `0${month}` : month;
        let responseDay = day < 10 ? `0${day}` : day;
        allData.values[x].stime = `${responseMonth}/${responseDay}/${year} ${ntime}`;

        calculatingCreatedTime(allData, x);
        if (allData.values[x].statusId === 4 || allData.values[x].statusId === 3) {
          allData.values[x].error = null;
        } else {
          allData.values[x].error = allData.values[x].errors;
        }
      }
      setData(allData);
    }
    dispatch(updateActivityIndicator(false));
  }

  function renderManualAccountCreateModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Bulk Uploads Suspended
            </text>
            <img
              className="confirmCancelImage"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowManualAccountCreateModal(false);
              }}
            />
          </div>
          <hr></hr>
          <div>
            <text>
              Bulk Upload Account Suspended
              <br />
              <br />
              Your district manages user accounts via automatic import, so you won’t be
              <br />
              able to import or update users.
              <br />
              <br />
              Contact your Administrator for more information.
            </text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px",
              marginTop: "20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Got It"}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowManualAccountCreateModal(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  //----------------------------------------------------------------------------------------------

  //---------------------Render Input-------------------------------------------------------------
  function onFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e.target.files[0] &&
      (e.target.files[0].type === "text/csv" || e.target.files[0].type === "application/vnd.ms-excel")
    ) {
      setFileName(e.target.files[0].name);
      setFileData(e.target.files[0]);
    } else {
      setShowToast({
        val: true,
        msg: "Please save your file as a .csv file type and upload again.",
        color: "#f4473c",
        type: "warning"
      });
    }
  }

  function onInputClick(event: React.MouseEvent<HTMLInputElement>) {
    (event.target as HTMLInputElement).value = "";
  }

  function renderFileInput() {
    const fileImporter = document.getElementById("fileInputs");
    fileImporter?.click();
  }

  //----------------------------------------------------------------------------------------------

  //--------------------Themes--------------------------------------------------------------------

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      zIndex: 3000,
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing()
        }
      }
    })
  );

  let classes = Styles();

  //----------------------------------------------------------------------------------------------

  //------------------Callbacks-------------------------------------------------------------------

  async function callback(args: number) {
    dispatch(updateActivityIndicator(true));
    setPController(args);
    setPageNo(1);
    getBulkImportList(1, args);
  }

  function errorsCallback(args: BulkImportTableRowDataValuesProps) {
    downloadFile.current = {
      id: args.bulkImportId,
      fname: args.fileName,
      type: args.recordTypeId,
      utype: args.uploadTypeId
    };
    setShowModalFixError(!showModalFixError);
    setErrorRecordType(args.recordType);
  }

  function customErrorOptionCallback(args: BulkImportRowCustomErrorOptionCallbackProps) {
    if (args.type === "Download Completed File") {
      downloadFile.current = {
        id: args.data.bulkImportId,
        fname: args.data.fileName,
        type: args.data.recordTypeId,
        utype: args.data.uploadTypeId
      };
      downloadAWSFile(2);
    } else if (args.type === "File Details") {
      setUserFileDetails(args.data);
      downloadFile.current = {
        id: args.data.bulkImportId,
        fname: args.data.fileName,
        type: args.data.recordTypeId,
        utype: args.data.uploadTypeId
      };
      setShowModalFileDetails(!showModalFileDetails);
    } else if (args.type === "Download & Fix Errors") {
      downloadFile.current = {
        id: args.data.bulkImportId,
        fname: args.data.fileName,
        type: args.data.recordTypeId,
        utype: args.data.uploadTypeId
      };
      setShowModalFixError(!showModalFixError);
    }
  }

  //-----------------------------------------------------------------------------------------------

  //------------------ToolTips---------------------------------------------------------------------

  const studentsIntroToolTip = (
    <text style={{ width: "294px", height: "130px" }}>
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Import</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Import button to create
        <br />
        student accounts via a bulk import using a<br />
        templated CSV file.
      </text>
      <br /> <br />
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Update</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Update button to update
        <br />
        student accounts from an exported file of your
        <br />
        current records and uploading the changes.
      </text>
    </text>
  );

  const classesIntroToolTip = (
    <text style={{ width: "294px", height: "130px" }}>
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Import</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Import button to create
        <br />
        classes and associate existing students and teachers
        <br />
        via a bulk import using a templated CSV file.{" "}
      </text>
      <br /> <br />
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Update</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Update button to update
        <br />
        classes from an exported file of your current records
        <br />
        and uploading the changes.
      </text>
    </text>
  );

  const educatorsIntroToolTip = (
    <text style={{ width: "294px", height: "130px" }}>
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Import</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Import button to create
        <br />
        teachers and school administrators via a bulk
        <br />
        import using a templated CSV file.
      </text>
      <br /> <br />
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "bold" }}>Bulk Update</text> -{" "}
      <text style={{ fontSize: "12px", color: "#454545", fontWeight: "normal" }}>
        Select the Update button to update
        <br />
        educator accounts from an exported file of your
        <br />
        current records and uploading the changes.
      </text>
    </text>
  );

  const studentsToolTip = (
    <text>
      Your file must include data in these required fields:
      <br />
      <br />
      - School ID, First Name, Last Name, Username, Password, and Grade Level.
      <br />
      - Username must be unique.
      <br />
      - Password min of 6 chars, any.
      <br />
      - Grade Level options: K, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12.
      <br />
    </text>
  );

  const educatorsToolTip = (
    <text>
      Your file must include data in these required fields:
      <br />
      <br />
      - School ID, First Name, Last Name, Username, Password, Email and Educator Type.
      <br />
      - Username must be unique.
      <br />
      - Password min of 8 chars, 1 uppercase, 1 lowercase, 1 number, 1 special char.
      <br />
      - Email properly formatted.
      <br />
      - Educator Type of Teacher or School Administrator.
      <br />
    </text>
  );

  const classesToolTip = (
    <text>
      Your file must include data in these required fields:
      <br />
      <br />
      - School ID, Class Name, Teacher Username, Subject, and Student Username.
      <br />
      - Teacher Username can contain multiple usernames separated by semicolons for the same class.
      <br />
      - Subject is Math or Reading or Math;Reading.
      <br />
      - Student Username must be on each row for the same class.
      <br />
    </text>
  );

  const studentsUploadToolTip = (
    <ul style={{ marginLeft: "-20px", fontWeight: 400 }}>
      <li>
        Required fields of School ID, Student ID, Last Name, First
        <br />
        Name, Grade Level and Status cannot be left blank.
      </li>
      <li>Grade level options: K, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12.</li>
      <li>Status must be Active or Inactive.</li>
      <li>Student ID cannot be changed.</li>
    </ul>
  );

  const educatorsUploadToolTip = (
    <ul style={{ marginLeft: "-20px", fontWeight: 400 }}>
      <li>
        Required fields of School ID, Educator ID, Last Name, First
        <br />
        Name, Email, Educator Type, Status cannot be left blank.
      </li>
      <li>Email value must be properly formatted and unique across platform. </li>
      <li>Educator Type must be School Administrator or Teacher. </li>
      <li>Status must be Active or Inactive.</li>
      <li>Educator ID cannot be changed.</li>
    </ul>
  );

  const classesUploadToolTip = (
    <ul style={{ marginLeft: "-20px", fontWeight: 400 }}>
      <li>
        Required fields of School ID, Class ID, Class
        <br />
        Name, Teacher Username, Subject, Student Username, Status cannot be left blank.
      </li>
      <li>Teacher Username(s) must match existing teachers at school.</li>
      <li>Student Username(s) must match existing students at school.</li>
      <li>Subject must contain Math, Reading, or both.</li>
      <li>Status must be Active or Inactive.</li>
      <li>Class ID cannot be changed.</li>
    </ul>
  );

  function renderToolTip() {
    return <text style={{ width: "auto" }}>{tooltipData}</text>;
  }

  function renderStudentsToolTip() {
    return <text>{studentsIntroToolTip}</text>;
  }

  function renderEducatorsToolTip() {
    return <text>{educatorsIntroToolTip}</text>;
  }

  function renderClassesToolTip() {
    return <text style={{ width: "auto" }}>{classesIntroToolTip}</text>;
  }

  function renderStudentsUploaddToolTip() {
    return <text>{studentsUploadToolTip}</text>;
  }

  function renderEducatorUploadToolTip() {
    return <text>{educatorsUploadToolTip}</text>;
  }

  function renderClassesUploadToolTip() {
    return <text>{classesUploadToolTip}</text>;
  }

  function renderErrorToolTip() {
    if (errorRecordType === "Students") {
      return <text>{studentsUploadToolTip}</text>;
    } else if (errorRecordType === "Educators") {
      return <text>{educatorsUploadToolTip}</text>;
    } else {
      return <text>{classesUploadToolTip}</text>;
    }
  }

  //------------------------------------------------------------------------------------------------------------------

  //-----------------------------File Upload ans Download--------------------------------------------------------------------------

  async function fileUpload(type: number) {
    try {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      formData.append("ImportFile", fileData, fileData.name);
      let bulkImport = { type, orgGuid: id, bulkImportUploadjsonObj: formData };
      AdminService.bulkImportUploadWithGuid(bulkImport).then(
        async ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setShowToast({
              val: true,
              msg: configMessages.successfullFileUpload,
              color: "#36BBA4",
              type: "success"
            });
            getBulkImportList(pageNo);
            setFileName("");
          } else {
            dispatch(updateActivityIndicator(false));
            alert("Error Uploading File");
            setFileName("");
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function bulkUpdateFileUpload(type: number) {
    try {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      formData.append("UpdateFile", fileData, fileData.name);
      let bulkObject = { type, orgGuid: id, bulkUpdateUploadjsonObj: formData };
      AdminService.bulkUpdateUploadWithGuid(bulkObject).then(
        async ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setShowToast({
              val: true,
              msg: configMessages.successfullFileUpload,
              color: "#36BBA4",
              type: "success"
            });
            getBulkImportList(pageNo);
            setFileName("");
          } else {
            dispatch(updateActivityIndicator(false));
            alert("Error Uploading File");
            setFileName("");
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function upLoadFixedError() {
    if (downloadFile.current.utype === 1) {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      formData.append("ImportFile", fileData, fileData.name);
      let bulkFixedError = {
        type: downloadFile.current.type,
        orgGuid: id,
        bulkImportUploadFixedErrordjsonObj: formData,
        bid: downloadFile.current.id
      };
      AdminService.bulkImportUploadFixedErrordWithGuid(bulkFixedError).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getBulkImportList(pageNo);
            setFileName("");
          } else {
            dispatch(updateActivityIndicator(false));
            alert("Error Uploading File");
            setFileName("");
          }
        }
      );
    } else {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      formData.append("UpdateFile", fileData, fileData.name);
      let bulkObj = {
        type: downloadFile.current.type,
        guid: id,
        bulkUpdateUploadFixedErrordjsonObj: formData,
        bid: downloadFile.current.id
      };
      AdminService.bulkUpdateUploadFixedErrordWithGuid(bulkObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getBulkImportList(pageNo);
            setFileName("");
          } else {
            dispatch(updateActivityIndicator(false));
            alert("Error Uploading File");
            setFileName("");
          }
        }
      );
    }
  }

  async function downloadAWSFile(type: number) {
    dispatch(updateActivityIndicator(true));
    AdminService.downloadFiles({
      id: downloadFile.current.id,
      type: type
    }).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotFileData(data, 0);
      } else {
        dispatch(updateActivityIndicator(false));
        alert("Error Downloading File");
      }
    });
  }

  async function gotFileData(detail: Response, type: number) {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const seconds = date.getSeconds();
    const minutes = date.getMinutes();
    const hour = date.getHours();
    let timestampMonth = month < 10 ? `0${month}` : month;
    let timestampDay = day < 10 ? `0${day}` : day;
    const timestamp = `${timestampMonth}${timestampDay}${year}${hour}${minutes}${seconds}.csv`;
    let filename = "";
    if (type === 0) {
      filename = downloadFile.current.fname;
    } else if (type === 1) {
      filename = `Students_${timestamp}`;
    } else if (type === 2) {
      filename = `Educators_${timestamp}`;
    } else if (type === 3) {
      filename = `Classes_${timestamp}`;
    }
    let details = await detail.text();
    let filedownload = document.createElement("a");
    const csvContent = details;
    dispatch(updateActivityIndicator(false));
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    filedownload.href = url;
    filedownload.setAttribute("download", filename);
    filedownload.click();
  }

  const bulkExportClassesWithGuidFunc = (flag: boolean, type: number) => {
    LMSService.bulkExportClassesWithGuid(getUserGuId, id, flag).then(
      ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
        if (status === 200) {
          gotFileData(data as Response, type);
        } else {
          handleError(data as ky.HTTPError, type);
        }
      }
    );
  };

  function exportUpdateData(type: number) {
    dispatch(updateActivityIndicator(true));
    let flag = false;
    if (isfrom === "school") {
      flag = true;
    }
    if (type === 1) {
      UserService.bulkExportStudentsWithGuid(getUserGuId, id, flag).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            gotFileData(data as Response, type);
          } else {
            handleError(data as ky.HTTPError, type);
          }
        }
      );
    } else if (type === 2) {
      UserService.bulkExportEducatorsWithGuid(getUserGuId, id, flag).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            gotFileData(data as Response, type);
          } else {
            handleError(data as ky.HTTPError, type);
          }
        }
      );
    } else if (type === 3) {
      bulkExportClassesWithGuidFunc(flag, type);
    }
  }

  async function handleError(error: ky.HTTPError, type: number) {
    let errorMsg = JSON.parse(await error.response.text());
    const errMsg = errorMsg.message;
    dispatch(updateActivityIndicator(false));
    if (errMsg === "No Records Found") {
      if (type === 1) {
        setShowToast({
          val: true,
          msg: configMessages.bulkStudentUpdateAlert,
          color: "#f4473c",
          type: "warning"
        });
      } else if (type === 2) {
        setShowToast({
          val: true,
          msg: configMessages.bulkEducatorUpdateAlert,
          color: "#f4473c",
          type: "warning"
        });
      } else {
        setShowToast({
          val: true,
          msg: configMessages.bulkClassUpdateAlert,
          color: "#f4473c",
          type: "warning"
        });
      }
    } else {
      alert("Error downloading file");
    }
  }

  //----------------------------------------------------------------------------------------------------------------------

  //---------------------------------File Validation----------------------------------------------------------------------

  const validateClassesFunc = (type: string) => {
    const formData = new FormData();
    formData.append("classImportFile", fileData, fileData.name);
    AdminService.validateClasses(formData).then(
      ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
        if (status === 200) {
          handleUploadSuccess(data as Response, type);
        } else {
          handleUploadError(data as ky.HTTPError, "classes");
        }
      }
    );
  };
  async function fileValidation(type: string) {
    try {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      if (type === "students" || type === "1") {
        formData.append("studentImportFile", fileData, fileData.name);
        AdminService.validateStudents(formData).then(
          ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
            if (status === 200) {
              handleUploadSuccess(data as Response, type);
            } else {
              handleUploadError(data as ky.HTTPError, "students");
            }
          }
        );
      } else if (type === "educators" || type === "2") {
        formData.append("educatorImportFile", fileData, fileData.name);
        AdminService.validateEducators(formData).then(
          ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
            if (status === 200) {
              handleUploadSuccess(data as Response, type);
            } else {
              handleUploadError(data as ky.HTTPError, "educators");
            }
          }
        );
      } else if (type === "classes" || type === "3") {
        validateClassesFunc(type);
      }
    } catch (err) {
      //do nothing
    }
  }

  const bulkUpdateValidateClassesFunc = (type: string) => {
    const formData = new FormData();
    formData.append("classesUpdateFile", fileData, fileData.name);
    AdminService.bulkUpdateValidateClasses(formData).then(
      ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
        if (status === 200) {
          handleUpdateSuccess(data as Response, type);
        } else {
          handleUpdateError(data as ky.HTTPError, "classes");
        }
      }
    );
  };

  async function bulkUpdateValidation(type: string) {
    try {
      dispatch(updateActivityIndicator(true));
      const formData = new FormData();
      if (type === "students" || type === "1") {
        formData.append("studentUpdateFile", fileData, fileData.name);
        AdminService.bulkUpdateValidateStudents(formData).then(
          ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
            if (status === 200) {
              handleUpdateSuccess(data as Response, type);
            } else {
              handleUpdateError(data as ky.HTTPError, "students");
            }
          }
        );
      } else if (type === "educators" || type === "2") {
        formData.append("educatorUpdateFile", fileData, fileData.name);
        AdminService.bulkUpdateValidateEducators(formData).then(
          ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
            if (status === 200) {
              handleUpdateSuccess(data as Response, type);
            } else {
              handleUpdateError(data as ky.HTTPError, "educators");
            }
          }
        );
      } else if (type === "classes" || type === "3") {
        bulkUpdateValidateClassesFunc(type);
      }
    } catch (err) {
      //do nothing
    }
  }

  //-------------------------------------------------------------------------------------------------------

  //--------------------------Handle Callbacks-------------------------------------------------------------

  async function handleUploadSuccess(detail: Response, type: string) {
    dispatch(updateActivityIndicator(false));
    if (type === "students") {
      setShowModalStudent(false);
      setShowModalSummary(true);
    } else if (type === "educators") {
      setShowModalEducators(false);
      setShowModalEducatorsSummary(true);
    } else if (type === "classes") {
      setShowModalClasses(false);
      setShowModalClassSummary(true);
    } else if (type === "1" || type === "2" || type === "3") {
      setShowModalFixError(false);
      setShowModalFixSummary(true);
    }
    setShowToast({
      val: true,
      msg: configMessages.successfullFileValidation,
      color: "#36BBA4",
      type: "success"
    });
  }

  async function handleUpdateSuccess(detail: Response, type: string) {
    dispatch(updateActivityIndicator(false));
    if (type === "students") {
      setShowUploadModalStudent(false);
      setShowUploadModalSummary(true);
    } else if (type === "educators") {
      setShowUploadModalEducator(false);
      setShowUploadModalEducatorsSummary(true);
    } else if (type === "classes") {
      setShowUploadModalClass(false);
      setShowUploadModalClassSummary(true);
    } else if (type === "1" || type === "2" || type === "3") {
      setShowModalFixError(false);
      setShowModalFixSummary(true);
    }
    setShowToast({
      val: true,
      msg: configMessages.successfullFileValidation,
      color: "#36BBA4",
      type: "success"
    });
  }

  async function handleUploadError(detail: ky.HTTPError, type: string) {
    let details = JSON.parse(await detail.response.text());
    let errMsg = "";
    if (details === 1) {
      errMsg = configMessages.fileformatErr;
    } else if (details === 3) {
      errMsg = getInCorrectHeaderMsg(type);
    } else if (details === 2) {
      errMsg = getMissingColumnHeadersMsg(type);
    } else if (details === 4) {
      errMsg = configMessages.moreRecordCount;
    } else if (details === 5) {
      errMsg = configMessages.noRecordFound;
    } else if (details === 6) {
      errMsg = configMessages.extraColumnError;
    } else if (details === 7) {
      errMsg = configMessages.specialCharacterColumnError;
    } else {
      errMsg = "something went wrong";
    }
    setFileName("");
    setFileData(undefined);
    dispatch(updateActivityIndicator(false));
    setShowToast({ val: true, msg: errMsg, color: "#f4473c", type: "warning" });
  }

  async function handleUpdateError(detail: ky.HTTPError, type: string) {
    let details = JSON.parse(await detail.response.text());
    let errMsg = "";
    if (details === 1) {
      errMsg = configMessages.fileformatErr;
    } else if (details === 3) {
      errMsg = configMessages.bulkUpdateIncorrectHeader;
    } else if (details === 2) {
      errMsg = configMessages.bulkUpdateMissingHeader;
    } else if (details === 4) {
      errMsg = configMessages.moreRecordCount;
    } else if (details === 5) {
      errMsg = configMessages.noRecordFound;
    } else if (details === 6) {
      errMsg = configMessages.extraColumnError;
    } else if (details === 7) {
      errMsg = configMessages.specialCharacterColumnError;
    } else {
      errMsg = "something went wrong";
    }
    setFileName("");
    dispatch(updateActivityIndicator(false));
    setShowToast({ val: true, msg: errMsg, color: "#f4473c", type: "warning" });
  }

  //---------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Intro Modal------------------------------------------------------------------------------------------------
  function studentImportButton(data: string) {
    if (!isCICEnabled) {
      if (data === "studentImport") {
        setShowModalStudent(!showModalStudent);
        setShowModalIntro(!showModalIntro);
        //@ts-ignore
        setToolTipData(studentsToolTip);
      } else if (data === "studentUpdate") {
        setShowUploadModalStudent(!showUploadModalStudent);
        setShowModalIntro(!showModalIntro);
      } else if (data === "educatorImport") {
        setShowModalEducators(!showModalEducators);
        setShowModalIntro(!showModalIntro);
        //@ts-ignore
        setToolTipData(educatorsToolTip);
      } else if (data === "educatorUpdate") {
        setShowUploadModalEducator(!showUploadModalEducator);
        setShowModalIntro(!showModalIntro);
      }
    } else {
      setShowManualAccountCreateModal(true);
    }
  }

  function renderImportIntro() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalIntro(!showModalIntro);
            }}
          />
        </div>
        <div className="bulkDesc">
          <text className="bulkDescStarted">Let's Get Started</text>
          <br />
          <text className="bulkDescSelect">Select what you would like to import or update:</text>
        </div>
        <div className="bulkCardsHolderThree">
          <div className="bulkCardFlexHolder">
            <div className="bulkTurkishBox">
              <UxIcon
                icon={UxIconEnum.USER}
                size={UxSizeEnum.LARGE}
                library={UxIconLibraryEnum.LIGHT}
                className="iconIntro importIconForIntro"
              />
              <br />
              <text className="bulkCardFlexHolderHeader">Students</text>
              <LightTooltip placement="bottom-start" title={renderStudentsToolTip()}>
                <i className="fa fa-info-circle bulkimporticon infoImportsIcon"></i>
              </LightTooltip>
            </div>
            <div className="importButHolder">
              <div
                className="importBut"
                onClick={() => {
                  studentImportButton("studentImport");
                }}
              >
                <img src={ImportIcon} alt="" width="19px" />
                <text className="importButText">Import</text>
              </div>
              <div
                className="importBut"
                onClick={() => {
                  studentImportButton("studentUpdate");
                }}
              >
                <img src={UploadIcon} alt="" width="19px" />
                <text className="importButText">Update</text>
              </div>
            </div>
          </div>
          <div className="bulkCardFlexHolder">
            <div className="bulkTurkishBox">
              <UxIcon
                icon={UxIconEnum.GRADUATION_CAP}
                size={UxSizeEnum.LARGE}
                library={UxIconLibraryEnum.LIGHT}
                className="iconIntro importIconForIntroEducator"
              />
              <br />
              <text className="bulkCardFlexHolderHeader">Educators</text>
              <LightTooltip placement="bottom-start" title={renderEducatorsToolTip()}>
                <i className="fa fa-info-circle bulkimporticon infoImportsEducatorIcon"></i>
              </LightTooltip>
            </div>
            <div className="importButHolder">
              <div
                className="importBut"
                onClick={() => {
                  studentImportButton("educatorImport");
                }}
              >
                <img src={ImportIcon} alt="" width="19px" />
                <text className="importButText">Import</text>
              </div>
              <div
                className="importBut"
                onClick={() => {
                  studentImportButton("educatorUpdate");
                }}
              >
                <img src={UploadIcon} alt="" width="19px" />
                <text className="importButText">Update</text>
              </div>
            </div>
          </div>
          <div className="bulkCardFlexHolder">
            <div className="bulkTurkishBox">
              <UxIcon
                icon={UxIconEnum.BOOKS}
                size={UxSizeEnum.LARGE}
                library={UxIconLibraryEnum.LIGHT}
                className="iconIntro importIconForIntro"
              />
              <br />
              <text className="bulkCardFlexHolderHeader">Classes</text>
              <LightTooltip placement="bottom-start" title={renderClassesToolTip()}>
                <i className="fa fa-info-circle bulkimporticon infoImportsIcon"></i>
              </LightTooltip>
            </div>
            <div className="importButHolder">
              <div
                className="importBut"
                onClick={() => {
                  setShowModalClasses(!showModalClasses);
                  setShowModalIntro(!showModalIntro);
                  //@ts-ignore
                  setToolTipData(classesToolTip);
                }}
              >
                <img src={ImportIcon} alt="" width="19px" />
                <text className="importButText">Import</text>
              </div>
              <div
                className="importBut"
                onClick={() => {
                  setShowUploadModalClass(!showUploadModalClass);
                  setShowModalIntro(!showModalIntro);
                }}
              >
                <img src={UploadIcon} alt="" width="19px" />
                <text className="importButText">Update</text>
              </div>
            </div>
          </div>
        </div>
        <div className="processHolder">
          <div className="checkHolders">
            <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
          </div>
          <div className="processBar" />
          <div className="checkHolderEmpty" />
          <div className="processBar" />
          <div className="checkHolderEmpty" />
        </div>
      </>
    );
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Imports Modals------------------------------------------------------------------------------------------------

  function renderImportStudent() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Student Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalStudent(!showModalStudent);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Choose File</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">The Bulk Import Wizard helps you create student accounts.</div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={ImportIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Download Template
            </div>
            <div className="downloadTextDesc">
              Download the Student Import Template provided below to populate an import file with your student data.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                download("StudentsImportTemplate.csv", studentsCSV);
              }}
              text={t("Download Student Import Template")}
              size={"lg"}
              className="downloadButton"
            />
          </div>
          <UxIcon icon={UxIconEnum.ARROW_RIGHT} ize={UxSizeEnum.SMALL} className="bulkimporticon" />
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={UploadIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Upload Template
            </div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowModalStudent(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  fileValidation("students");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderImportEducators() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Educator Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalEducators(!showModalEducators);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Choose File</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">The Bulk Import Wizard helps you create educator accounts.</div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={ImportIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Download Template
            </div>
            <div className="downloadTextDesc">
              Download the Educator Import Template provided below to populate an import file with your educator data.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                download("EducatorsImportTemplate.csv", educatorsCSV);
              }}
              text={t("Download Educator Import Template")}
              size={"lg"}
              className="downloadButtonForEducator ebdButton"
            />
          </div>
          <UxIcon icon={UxIconEnum.ARROW_RIGHT} ize={UxSizeEnum.SMALL} className="bulkimporticon" />
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={UploadIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Upload Template
            </div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowModalEducators(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  fileValidation("educators");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderImportClasses() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Class Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalClasses(!showModalClasses);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Choose File</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">
          The Bulk Import Wizard helps you create classes.
          <br />
          Check that you have already uploaded any teachers and students you want to associate to the classes.
        </div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={ImportIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Download Template
            </div>
            <div className="downloadTextDesc">
              Download the Classes Import Template provided below to populate an import file with your classes data.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                download("ClassesImportTemplate.csv", classesCSV);
              }}
              text={t("Download Classes Import Template")}
              size={"lg"}
              className="downloadButton"
            />
          </div>
          <UxIcon icon={UxIconEnum.ARROW_RIGHT} size={UxSizeEnum.SMALL} className="bulkimporticon" />
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={UploadIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Upload Template
            </div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowModalClasses(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  fileValidation("classes");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Imports Summary Modals------------------------------------------------------------------------------------------------

  function renderImportSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Student Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalSummary(!showModalSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Import Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and import when you click Finish.</text>
          <br></br>
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed import results.
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              fileUpload(1);
              setShowModalSummary(false);
            }}
          />
        </div>
      </>
    );
  }

  function renderImportEducatorsSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Educator Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalEducatorsSummary(!showModalEducatorsSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Import Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and import when you click Finish.</text>
          <br></br>
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed import results.
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowModalEducatorsSummary(false);
              fileUpload(2);
            }}
          />
        </div>
      </>
    );
  }

  function renderImportClassSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Class Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalClassSummary(!showModalClassSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Import Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and import when you click Finish.</text>
          <br></br>
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed import results.
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowModalClassSummary(false);
              fileUpload(3);
            }}
          />
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Uploads Modals--------------------------------------------------------------------------------------------------------

  function renderUploadStudent() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Student Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalStudent(!showUploadModalStudent);
              setEnableStudentsUpload(false);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Records</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">
          The Bulk Updates Wizard helps you to update data in and change the status of multiple student accounts.
        </div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">Export Current Student Records</div>
            <div className="downloadTextDesc">
              You export your current student records to a .csv file and make changes as needed to the data in the
              columns. Then, upload the file with the changes.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderStudentsUploaddToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                setEnableStudentsUpload(true);
                exportUpdateData(1);
              }}
              text={t("Export Student Records")}
              size={"lg"}
              className="downloadUploadButton"
            />
          </div>
          <div style={{ width: "30px" }}>
            {" "}
            <UxIcon
              icon={UxIconEnum.ARROW_RIGHT}
              size={UxSizeEnum.SMALL}
              className="bulkimporticon"
              style={{
                marginLeft: enableStudentsUpload ? "0px" : "-50px",
                opacity: enableStudentsUpload ? 1 : 0
              }}
            />
          </div>
          <div
            className="bulkCardsHolderTwo"
            style={{
              opacity: enableStudentsUpload ? 1 : 0.5,
              pointerEvents: enableStudentsUpload ? "auto" : "none"
            }}
          >
            <div className="downloadText">Upload File With Changes</div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton2"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowUploadModalStudent(false);
                setEnableStudentsUpload(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  bulkUpdateValidation("students");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderUploadEducator() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Educator Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalEducator(!showUploadModalEducator);
              setEnableEducatorsUpload(false);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Records</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">
          The Bulk Updates Wizard helps you to update data in and change the status of multiple educator accounts.
        </div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">Export Current Educator Records</div>
            <div className="downloadTextDesc">
              You export your current educator records to a .csv file and make changes as needed to the data in the
              columns. Then, upload the file with the changes.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderEducatorUploadToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                setEnableEducatorsUpload(true);
                exportUpdateData(2);
              }}
              text={t("Export Educator Records")}
              size={"lg"}
              className="downloadUploadButton"
            />
          </div>
          <div style={{ width: "30px" }}>
            {" "}
            <UxIcon
              icon={UxIconEnum.ARROW_RIGHT}
              size={UxSizeEnum.SMALL}
              className="bulkimporticon"
              style={{
                marginLeft: enableEducatorsUpload ? "0px" : "-50px",
                opacity: enableEducatorsUpload ? 1 : 0
              }}
            />
          </div>
          <div
            className="bulkCardsHolderTwo"
            style={{
              opacity: enableEducatorsUpload ? 1 : 0.5,
              pointerEvents: enableEducatorsUpload ? "auto" : "none"
            }}
          >
            <div className="downloadText">Upload File With Changes</div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton2"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowUploadModalEducator(false);
                setEnableEducatorsUpload(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  bulkUpdateValidation("educators");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderUploadClass() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Class Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalClass(!showUploadModalClass);
              setEnableClassesUpload(false);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Records</div>
        <div className="chooseFileSeperator" />
        <div className="wizard">
          The Bulk Updates Wizard helps you to update data in and change the status of multiple class accounts.
        </div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">Export Current Class Records</div>
            <div className="downloadTextDesc">
              You export your current class records to a .csv file and make changes as needed to the data in the
              columns. Then, upload the file with the changes.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderClassesUploadToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                setEnableClassesUpload(true);
                exportUpdateData(3);
              }}
              text={t("Export Class Records")}
              size={"lg"}
              className="downloadUploadButton"
            />
          </div>
          <div style={{ width: "30px" }}>
            {" "}
            <UxIcon
              icon={UxIconEnum.ARROW_RIGHT}
              size={UxSizeEnum.SMALL}
              className="bulkimporticon"
              style={{
                marginLeft: enableClassesUpload ? "0px" : "-50px",
                opacity: enableClassesUpload ? 1 : 0
              }}
            />
          </div>
          <div
            className="bulkCardsHolderTwo"
            style={{
              opacity: enableClassesUpload ? 1 : 0.5,
              pointerEvents: enableClassesUpload ? "auto" : "none"
            }}
          >
            <div className="downloadText">Upload File With Changes</div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton2"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px"
          }}
        >
          <div className="processHolder">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBar" />
            <div className="checkHolderEmpty" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Previous"}
              className="px-2 py-1 previousBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                setShowUploadModalClass(false);
                setEnableClassesUpload(false);
                setShowModalIntro(true);
                setFileName("");
              }}
            />
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  bulkUpdateValidation("classes");
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Uploads Summary Modals------------------------------------------------------------------------------------------------

  function renderUploadSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Student Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalSummary(!showUploadModalSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and update when you click Finish.</text>
          <br />
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed update results.
          </text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowUploadModalSummary(false);
              bulkUpdateFileUpload(1);
            }}
          />
        </div>
      </>
    );
  }

  function renderUploadEducatorsSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Educator Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalEducatorsSummary(!showUploadModalEducatorsSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and update when you click Finish.</text>
          <br />
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed update results.
          </text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowUploadModalEducatorsSummary(false);
              bulkUpdateFileUpload(2);
            }}
          />
        </div>
      </>
    );
  }

  function renderUploadClassSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Class Bulk Update</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowUploadModalClassSummary(!showUploadModalClassSummary);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Update Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation and update when you click Finish.</text>
          <br />
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed update results.
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary">
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
            <div className="processBarBlue" />
            <div className="checkHolders">
              <UxIcon icon={UxIconEnum.CHECK} size={UxSizeEnum.SMALL} className="checkers" />
            </div>
          </div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowUploadModalClassSummary(false);
              bulkUpdateFileUpload(3);
            }}
          />
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk Error Fix Modal--------------------------------------------------------------------------------------------------------

  function renderImportFixError() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalFixError(!showModalFixError);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Choose File</div>
        <div className="chooseFileSeperator" />
        <div className="emptywizard"></div>
        <div className="bulkCardsHolderTemplateHolder">
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={ImportIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Download Errors
            </div>
            <div className="downloadTextDesc">
              Click Download Errors to see errors.
              <br />
              Correct your file and then upload your corrected file.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <div className="downloadRequireText">File Requirements</div>
              <LightTooltip placement="bottom-start" title={renderErrorToolTip()}>
                <i className="fa fa-info-circle bulkimporticon"></i>
              </LightTooltip>
            </div>
            <UxAction
              callback={() => {
                downloadAWSFile(3);
              }}
              text={t("Download Errors")}
              size={"lg"}
              className="downloadButton"
            />
          </div>
          <UxIcon icon={UxIconEnum.ARROW_RIGHT} size={UxSizeEnum.SMALL} className="bulkimporticon" />
          <div className="bulkCardsHolderTwo">
            <div className="downloadText">
              <img src={UploadIcon} alt="" width="19px" style={{ marginRight: "8px" }} />
              Upload Corrected File
            </div>
            <div className="downloadRequireTextMiddle">Browse for import file</div>
            <input className="fileName" value={fileName} placeholder="FileName.csv" disabled />
            <input type="file" onChange={onFileSelect} onClick={onInputClick} id="fileInputs" accept=".csv" />
            <UxAction
              callback={() => {
                renderFileInput();
              }}
              text={t("Upload File")}
              size={"lg"}
              className="uploadButton"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <UxAction
              key={1}
              size="sm"
              enabled={true}
              text={"Next"}
              className="px-4 py-1 nextBut"
              priority={UxShadesEnum.DARK}
              callback={() => {
                if (fileName !== "") {
                  if (downloadFile.current.utype === 1) {
                    fileValidation(downloadFile.current.type.toString());
                  } else {
                    bulkUpdateValidation(downloadFile.current.type.toString());
                  }
                } else {
                  setShowToast({
                    val: true,
                    msg: configMessages.bulkImportAlert,
                    color: "#f4473c",
                    type: "warning"
                  });
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function renderErrorFixSummary() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">Bulk Upload</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalFixSummary(false);
              setFileName("");
            }}
          />
        </div>
        <div className="chooseFileText">Summary</div>
        <div className="chooseFileSeperator" />
        <div className="summaryInfoHolder">
          <text className="summaryInfo">Your file will start final validation when you click Finish.</text>
          <br />
          <text className="summaryInfo">
            Please be sure to check the Status column of the Bulk Upload page for your completed results.
          </text>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-35px"
          }}
        >
          <div className="processHolderSummary"></div>
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Finish"}
            className="px-4 py-1 nextButSum"
            priority={UxShadesEnum.DARK}
            callback={() => {
              setShowModalFixSummary(false);
              upLoadFixedError();
            }}
          />
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //-----------------------------------------------Bulk File Details Modal--------------------------------------------------------------------------------------------------------

  function renderFileDetails() {
    return (
      <>
        <div className="bulkHeader">
          <text className="bulkImportText">File Details</text>
          <img
            className="bulkImportCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowModalFileDetails(!showModalFileDetails);
              setUserFileDetails(fileDetailsData);
            }}
          />
        </div>
        <div className="chooseFileText">{userFileDetails.fileName}</div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Record Type</text>
          <text className="textValue">{userFileDetails.recordType}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Upload Type</text>
          <text className="textValue">{downloadFile.current.utype === 1 ? "Import" : "Update"}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">User</text>
          <text className="textValue">{userFileDetails.name}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Submitted Date/Time</text>
          <text className="textValue">{userFileDetails.stime}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Completed Date/Time</text>
          <text className="textValue">{userFileDetails.ctime}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Errors</text>
          <text className="textValue">{userFileDetails.errors}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">
            Number of Records {downloadFile.current.utype === 1 ? "Created" : "Changed"}{" "}
          </text>
          <text className="textValue">{userFileDetails.successCount}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="spaceBetweenTexts">
          <text className="textType">Status</text>
          <text className="textValue">{userFileDetails.status}</text>
        </div>
        <div className="fileDetailsSeperator" />
        <div className="fileDetailAction">
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Close"}
            className="px-4 py-1 closeButton"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              setShowModalFileDetails(!showModalFileDetails);
              setUserFileDetails(fileDetailsData);
            }}
          />
          {userFileDetails.errors !== 0 || userFileDetails.successCount !== 0 ? (
            <DropdownButton
              key={"up"}
              id={`dropdown-button-drop-up`}
              drop={"up"}
              variant="primary fileDetailsButton"
              title={
                <div className="pull-left">
                  <img className="downloadArrow" src={DownloadArrow} alt="" />
                  Download
                </div>
              }
              size={"sm"}
              align={"start"}
            >
              {userFileDetails.errors === 0 ? null : (
                <Dropdown.Item
                  onClick={() => downloadAWSFile(3)}
                  eventKey="1"
                  style={{ fontSize: "1.2rem !important" }}
                >
                  Download & Fix Errors
                </Dropdown.Item>
              )}
              {userFileDetails.successCount === 0 ? null : (
                <Dropdown.Item
                  onClick={() => downloadAWSFile(2)}
                  eventKey="2"
                  style={{ fontSize: "1.2rem !important" }}
                >
                  Download Completed File
                </Dropdown.Item>
              )}
            </DropdownButton>
          ) : null}
        </div>
      </>
    );
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(updateActivityIndicator(true));
    getBulkImportList(value);
    setPageNo(value);
  };

  function download(filename: string, text: string) {
    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (
    <>
      {/*-----------------------------------------------Bulk Intro Modal---------------------------------------------------------*/}
      <BulkImportModal showBulkModal={showModalIntro} width="856px" height="400px">
        {renderImportIntro()}
      </BulkImportModal>
      {/*---------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk Imports Modals------------------------------------------------------*/}
      <BulkImportModal showBulkModal={showModalStudent}>{renderImportStudent()}</BulkImportModal>
      <BulkImportModal showBulkModal={showModalEducators}>{renderImportEducators()}</BulkImportModal>
      <BulkImportModal showBulkModal={showModalClasses}>{renderImportClasses()}</BulkImportModal>
      {/*----------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk Imports Summary Modals----------------------------------------------*/}
      <BulkImportModal showBulkModal={showModalSummary}>{renderImportSummary()}</BulkImportModal>
      <BulkImportModal showBulkModal={showModalEducatorsSummary}>{renderImportEducatorsSummary()}</BulkImportModal>
      <BulkImportModal showBulkModal={showModalClassSummary}>{renderImportClassSummary()}</BulkImportModal>
      {/*--------------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk Uploads Modals------------------------------------------------------*/}
      <BulkImportModal showBulkModal={showUploadModalStudent}>{renderUploadStudent()}</BulkImportModal>
      <BulkImportModal showBulkModal={showUploadModalEducator}>{renderUploadEducator()}</BulkImportModal>
      <BulkImportModal showBulkModal={showUploadModalClass}>{renderUploadClass()}</BulkImportModal>
      {/*----------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk Uploads Summary Modals----------------------------------------------*/}
      <BulkImportModal showBulkModal={showUploadModalSummary}>{renderUploadSummary()}</BulkImportModal>
      <BulkImportModal showBulkModal={showUploadModalEducatorsSummary}>
        {renderUploadEducatorsSummary()}
      </BulkImportModal>
      <BulkImportModal showBulkModal={showUploadModalClassSummary}>{renderUploadClassSummary()}</BulkImportModal>
      {/*--------------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk Error Fix Modal-----------------------------------------------------*/}
      <BulkImportModal showBulkModal={showModalFixError}>{renderImportFixError()}</BulkImportModal>
      <BulkImportModal showBulkModal={showModalFixSummary}>{renderErrorFixSummary()}</BulkImportModal>
      {/*--------------------------------------------------------------------------------------------------------------------------*/}

      {/*-----------------------------------------------Bulk File Details Modal-----------------------------------------------------*/}
      <BulkImportModal showBulkModal={showModalFileDetails} width="500px" height="auto">
        {renderFileDetails()}
      </BulkImportModal>
      {/*-----------------------------------------------------------------------------------------------------------------------------*/}

      <div style={{ zIndex: 3000 }}>
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={6000}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "success" });
            }}
          >
            {showToast.type === "success" ? (
              <Alert
                severity="success"
                style={{ backgroundColor: showToast.color, color: "#fff" }}
                onClose={() => {
                  setShowToast({ val: false, msg: "", color: "", type: "" });
                }}
              >
                {showToast.msg}
              </Alert>
            ) : (
              <Alert
                severity="warning"
                style={{ backgroundColor: showToast.color, color: "#fff" }}
                onClose={() => {
                  setShowToast({ val: false, msg: "", color: "", type: "" });
                }}
              >
                {showToast.msg}
              </Alert>
            )}
          </Snackbar>
        </div>
      </div>
      <div>
        <div className="bulkImportHolder">
          <div
            style={{
              marginTop: "0px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row"
            }}
          >
            <div
              className="bulkImportRefresh"
              onClick={() => {
                dispatch(updateActivityIndicator(true));
                getBulkImportList(pageNo);
              }}
            >
              <img src={RefreshIcon} alt="" className="refreshIcon" />
              <label style={{ cursor: "pointer" }}>Refresh</label>
            </div>
          </div>
          <div>
            <UxAction
              callback={() => {
                setShowModalIntro(true);
              }}
              text={t("Bulk Upload")}
              className="bulkImportAction"
              size={"lg"}
            />
          </div>
        </div>
        <div ref={elementRef} className="bulkImportTable">
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableBulkImportTable"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IBulkImports).values}
            showColumnCheckbox={false}
            showKebabMenu={false}
            showNameKebabMenu={false}
            emptyTableText={emptyTableText}
            checkboxRef="loginName"
            selected={[]}
            setSelected={[]}
            showToolTip={false}
            mtableHeight={tableHgt}
            showStatusText={false}
            showCustomStatusText={true}
            customStatusMapper="error"
            errorsCallback={errorsCallback}
            customErrorOptionCallback={customErrorOptionCallback}
            showSortList={[true, true, true, true, true, false, false]}
            sortListMapper={{
              "FILE NAME": [
                fileNameBool,
                setFileNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "RECORD TYPE": [
                typeBool,
                setTypeBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "UPLOAD TYPE": [
                updateTypeBool,
                setUpdateTypeBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              USER: [
                userBool,
                setUserBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "SUBMITTED DATE/TIME": [
                submitBool,
                setSubmitBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        </div>
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={pController}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController rowsList={paginationControllers} setRowsCount={pController} callback={callback} />
          )}
        </div>
        <BulkImportModal
          showBulkModal={showManualAccountCreateModal}
          children={renderManualAccountCreateModal()}
          width="580px"
          height="310px"
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(BulkImports);
