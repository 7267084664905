import React, { useState, useEffect } from "react";
import {
  UxActivityIndicator,
  UxAction,
  UxIconEnum,
  UxStyleEnum,
  UxPriorityEnum,
  UxActionTypeEnum,
  UxTabs
} from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./login.scss";
import { login, getUser } from "../../authService/AuthService";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import configMessages from "../../helpers/configMessages";
import { UserService } from "../../services/helpers/user.services";
import Settings from "../../helpers/constants";
import moment from "moment";
import { updateISMPPAPP, updateMPPSubject, updatePageTitle } from "../../Redux/Action";
import { connect } from "react-redux";
import SHOWPASS from "../../images/show_pass.png";
import HIDEPASS from "../../images/hide_pass.png";
import PEN from "../../images/edit_pen.png";
import logo from "../../images/edgenuity-logo-large.png";
import Ilogo from "../../images/LOGO-IL.png";
import Tooltip from "@material-ui/core/Tooltip";
import ReduxProps from "../../Redux/Redux.props";
import { FlagrApiResponse, LoginProps } from "./Login.props";
import { User } from "oidc-client";
import ky from "ky";

interface Props extends ReduxProps, LoginProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator
  };
}
function Login(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    password: "",
    username: "",
    loginCode: localStorage.getItem("ala:loginCode") || "",
    process: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const { dispatch, getActivityIndicator } = props;
  const [showPassStud, setShowPassStud] = useState(false);
  const [showLoginCode, setShowLoginCode] = useState(true);
  const [mppApp, setMppApp] = useState(false);
  const [isSSO, setSSO] = useState(localStorage.getItem("clever:sso") === "true" ? true : false);
  const params = new URL(window.location.href).searchParams;
  const [disableRedirect, setDisableRedirect] = useState(params.get("redirect") === "off"); // If url parameters “redirect” === “off”, then display ALA login

  const [isImpersonationState, setIsimpersonationState] = useState(false);
  useEffect(() => {
    dispatch(updatePageTitle(""));
    if (localStorage.getItem("ala:loginCode")) {
      setShowLoginCode(false);
    }
    setTimeout(() => {
      window.location.assign(window.location.origin);
    }, 1000 * 60 * 60 * 4 - 10000);
  }, []);

  const [redirectToULP, setRedirectToULP] = useState(false);
  const [displayRedirectToUlpMessage, setDisplayRedirectToUlpMessage] = useState(false);
  const [renderLoginForm, setrenderLoginForm] = useState(false);

  useEffect(() => {
    const url = window.APP_ENV.lmsFlagrUrl + "/api/v1/evaluation/batch";
    // const url = 'http://localhost:18000/api/v1/evaluation/batch'; // local debugging
    ky.post(url, {
      json: { entities: [{ sitecode: "anythingForNow" }], flagKeys: ["RedirectToUlp", "DisplayRedirectToUlpMessage"] }
    }) //login_redirect
      .json()
      .then((response: FlagrApiResponse) => {
        const redirectResponse = response.evaluationResults.find((entry) => entry.flagKey === "RedirectToUlp");
        if (redirectResponse.variantKey === "on" && !disableRedirect) {
          if (params.get("redirect") !== "off") {
            // add one more check just before redirecting
            setRedirectToULP(true);
          }
        }
        const redirectMessageResponse = response.evaluationResults.find(
          (entry) => entry.flagKey === "DisplayRedirectToUlpMessage"
        );

        if (redirectMessageResponse.variantKey === "on") {
          setDisplayRedirectToUlpMessage(true);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching flagr data:", error);
      })
      .finally(() => setrenderLoginForm(true));
  }, []);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }
  useEffect(() => {
    window.addEventListener("resize", getZoomSize);
  }, [window.location.pathname]);

  const getZoomSize = () => {
    let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

    if (zoom > 100) {
      document.getElementById("loginpage").style.removeProperty("overflow");
    } else {
      document.getElementById(`loginpage`).style.overflowY = "none";
    }
  };

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const studentPasswordTitle = (
    <div aria-hidden="true">
      <span className="stuPasswordTitle">Student password requirements:</span>
      <span className="eduPasswordTitle"> Student password must be any 6 characters with no spaces.</span>
    </div>
  );
  const educatorPasswordTitle = (
    <div aria-hidden="true">
      <span className="stuPasswordTitle">Educator password requirements:</span>
      <span className="eduPasswordTitle">
        {" "}
        Educator password must be 8 characters of 1 uppercase, 1 lowercase, 1 number, 1 special character and no spaces.
        Period cannot be the only special character.
      </span>
    </div>
  );
  const educatorEmailTitle = (
    <div aria-hidden="true">
      <span className="stuPasswordTitle">Educator Email:</span>
      <span className="eduPasswordTitle">
        {" "}
        Starting June 3, 2023 you will be asked to log into your account with your email address as your username.
        Please review your profile settings and ensure your current email is up to date.
      </span>
    </div>
  );

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#454545",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: 15,
      maxWidth: 300,
      marginRight: 30
    }
  }))(Tooltip);

  const [currentTab, setCurrentTab] = useState(localStorage.getItem("ala:redirectedFrom") || "student");

  const tabs = [
    {
      eventKey: "student",
      title: <div aria-label="Login form: Student Login">Student Login</div>,
      content: <></>
    },
    {
      eventKey: "educator",
      title: <div aria-label="Login form: Educator Login">Educator Login</div>,
      content: <></>
    }
  ];

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("oidc.")) {
        let data = JSON.parse(localStorage.getItem(localStorage.key(i)));
        localStorage.setItem("oidc:state", data.id);
        localStorage.removeItem("oidc:persistState");
      }
    }
    dispatch(updateISMPPAPP(false));
    dispatch(updateMPPSubject(""));
    const url = new URL(`${window.location}`);
    if (url.searchParams.get("sso") || localStorage.getItem("clever:sso") === "true") {
      localStorage.setItem("clever:sso", "true");
      setSSO(true);
      login();
    } else if (url.searchParams.get("impersonated")) {
      localStorage.setItem("isImpersonation", "true");
      localStorage.setItem("impersonationCallUrl", url.searchParams.get("Ret") || "");
      localStorage.setItem("activeTab", url.searchParams.get("activeTab") || "");
      localStorage.setItem("activeSubTab", url.searchParams.get("activeSubTab") || "");
      setIsimpersonationState(true);
      login();
    } else if (localStorage.getItem("ala:MppLaunch") === "true" && !isWneUserApp()) {
      setMppApp(true);
      dispatch(updateISMPPAPP(true));
      dispatch(updateMPPSubject(localStorage.getItem("ala:MppLaunchSubject")));
      login();
    } else {
      if (localStorage.getItem("ala:loginCode")) {
        //@ts-ignore
        setState({
          ...state,
          loginCode: localStorage.getItem("ala:loginCode")
        });
      }
      if (
        window.location.host === window.APP_ENV.oldAdminVanityURL ||
        window.location.host === window.APP_ENV.oldStudentVanityURL ||
        window.location.host === window.APP_ENV.AdminWNACloudURL ||
        window.location.host === window.APP_ENV.StudentWNACloudURL
      ) {
        if (
          window.location.host === window.APP_ENV.oldAdminVanityURL ||
          window.location.host === window.APP_ENV.AdminWNACloudURL
        ) {
          if (new URL(`${window.location}`).searchParams.get("code")) {
            window.location.assign(
              `${window.APP_ENV.post_logout_redirect_uri}/?redirected_from=educator&code=${new URL(
                `${window.location}`
              ).searchParams.get("code")}`
            );
          } else {
            window.location.assign(`${window.APP_ENV.post_logout_redirect_uri}/?redirected_from=educator`);
          }
        } else if (
          window.location.host === window.APP_ENV.oldStudentVanityURL ||
          window.location.host === window.APP_ENV.StudentWNACloudURL
        ) {
          if (new URL(`${window.location}`).searchParams.get("code")) {
            window.location.assign(
              `${window.APP_ENV.post_logout_redirect_uri}/?redirected_from=student&code=${new URL(
                `${window.location}`
              ).searchParams.get("code")}`
            );
          } else {
            window.location.assign(`${window.APP_ENV.post_logout_redirect_uri}/?redirected_from=student`);
          }
        }
      } else {
        if (new URL(`${window.location}`).searchParams.get("redirected_from")) {
          if (!localStorage.getItem("ala:redirectedFrom")) {
            if (new URL(`${window.location}`).searchParams.get("code")) {
              localStorage.setItem(
                "ala:redirectedFrom",
                //@ts-ignore
                new URL(`${window.location}`).searchParams.get("redirected_from")
              );
              window.location.assign(
                `${window.APP_ENV.post_logout_redirect_uri}?code=${new URL(`${window.location}`).searchParams.get(
                  "code"
                )}`
              );
            } else {
              localStorage.setItem(
                "ala:redirectedFrom",
                //@ts-ignore
                new URL(`${window.location}`).searchParams.get("redirected_from")
              );
              window.location.assign(`${window.APP_ENV.post_logout_redirect_uri}`);
            }
          } else {
            if (localStorage.getItem("ala:redirectedFrom") === "educator") {
              if (new URL(`${window.location}`).searchParams.get("code")) {
                //@ts-ignore
                localStorage.setItem("ala:redirectedFrom", "educator");
                window.location.assign(
                  `${window.APP_ENV.post_logout_redirect_uri}?code=${new URL(`${window.location}`).searchParams.get(
                    "code"
                  )}`
                );
              } else {
                //@ts-ignore
                localStorage.setItem(
                  "ala:redirectedFrom",
                  //@ts-ignore
                  new URL(`${window.location}`).searchParams.get("redirected_from")
                );
                window.location.assign(`${window.APP_ENV.post_logout_redirect_uri}`);
              }
            } else if (localStorage.getItem("ala:redirectedFrom") === "student") {
              if (new URL(`${window.location}`).searchParams.get("code")) {
                //@ts-ignore
                localStorage.setItem("ala:redirectedFrom", "student");
                window.location.assign(
                  `${window.APP_ENV.post_logout_redirect_uri}?code=${new URL(`${window.location}`).searchParams.get(
                    "code"
                  )}`
                );
              } else {
                window.location.assign(`${window.APP_ENV.post_logout_redirect_uri}`);
              }
            }
          }
        } else {
          if (isWneUserApp()) {
            localStorage.removeItem("ala:MppLaunch");
            if (localStorage.getItem("ala:redirectedFrom") !== "wneuser") {
              localStorage.setItem("ala:redirectedFrom", "wneuser");
              window.location.assign("/");
            }
          } else {
            if (localStorage.getItem("ala:redirectedFrom")) {
              if (localStorage.getItem("ala:redirectedFrom") === "wneuser") {
                //@ts-ignore
                localStorage.setItem("ala:redirectedFrom", "student");
              }
            } else {
              //@ts-ignore
              localStorage.setItem("ala:redirectedFrom", "student");
              window.location.assign("/");
            }
          }
        }
        localStorage.setItem("lms:isRenew", "false");
        getUser()
          .then(async (user: User) => {
            if (!user) {
              if (new URL(`${window.location}`).searchParams.get("error")) {
                if (
                  new URL(`${window.location}`).searchParams.get("error") === "Invalid username or password" ||
                  new URL(`${window.location}`).searchParams.get("error") === "Invalid login code"
                ) {
                  localStorage.removeItem("oidc:session");
                  localStorage.removeItem("page");
                  localStorage.removeItem("oidc:state");
                  for (let i = 0; i < localStorage.length; i++) {
                    if (localStorage.key(i).includes("oidc.")) {
                      localStorage.removeItem(localStorage.key(i));
                    }
                  }
                  if (localStorage.getItem("ala:hasCode")) {
                    setState({
                      ...state,
                      loginCode: localStorage.getItem("ala:loginCode") || "",
                      process: true
                    });
                  } else {
                    localStorage.removeItem("ala:loginCode");
                    // setState({
                    //   ...state,
                    //   process: true,
                    // });
                  }
                  if (currentTab === "student") {
                    setShowToast({
                      val: true,
                      msg: configMessages.invalidCredentials
                    });
                    focusOnUsername();
                  } else if (currentTab === "educator") {
                    setShowToast({
                      val: true,
                      msg: configMessages.NewInvalidCredentials
                    });
                    focusOnUsername();
                  } else {
                    setShowToast({
                      val: true,
                      msg: "You entered an incorrect username, password, or both."
                    });
                    focusOnUsername();
                  }
                  const clientDateTime = moment().format();
                  const useragent = navigator.userAgent;
                  let eventURL = window.location.origin;
                  const json = {
                    ClientDateTime: clientDateTime,
                    UserAgent: useragent,
                    EventSourceURL: eventURL
                  };
                  UserService.loginfailed(json);
                  setTimeout(() => {
                    login();
                  }, 5000);
                }
              } else if (!localStorage.getItem("oidc:state") || Boolean(localStorage.getItem("oidc:session"))) {
                login();
                setState({
                  ...state,
                  process: true
                });
              }
            } else if (!Boolean(localStorage.getItem("oidc:session"))) {
              login();
              setState({
                ...state,
                process: true
              });
            } else {
              login();
              setState({
                ...state,
                process: true
              });
            }
          })
          .catch((error) => {
            if (error.message === "No matching state found in storage") {
              localStorage.removeItem("oidc:state");
              localStorage.removeItem("oidc:session");
              localStorage.removeItem("page");
              login();
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActivityIndicator]);

  // needed in future

  // async function getFlagrKeyValues() {
  //   try {
  //     const raw = JSON.stringify({
  //       entities: [
  //         {
  //           entityID: "a1234",
  //           entityType: "report",
  //           entityContext: {},
  //         },
  //       ],
  //       enableDebug: true,
  //       flagKeys: flagrKeys,
  //     });
  //     const options = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: raw,
  //     };

  // let flagrVal = {};
  //   let details = await response.text();
  //   details = JSON.parse(details);
  //   //@ts-ignore
  //   for (let x in details.evaluationResults) {
  //     //@ts-ignore
  //     if (details.evaluationResults[x].variantKey === undefined) {
  //       //@ts-ignore
  //       flagrVal[details.evaluationResults[x].flagKey] = false;
  //       //@ts-ignore
  //     } else if (
  //       //@ts-ignore
  //       details.evaluationResults[x].variantKey === "off" ||
  //       //@ts-ignore
  //       details.evaluationResults[x].variantKey === "OFF"
  //     ) {
  //       //@ts-ignore
  //       flagrVal[details.evaluationResults[x].flagKey] = false;
  //     } else {
  //       //@ts-ignore
  //       flagrVal[details.evaluationResults[x].flagKey] = true;
  //     }
  //   }
  //   dispatch(updateFlagrKeys(flagrVal));
  // } else {
  //   let flagrvalues: GetFlagrKeysOptionalProps = {};
  //   for (let keys in flagrKeys) {
  //     if (
  //       flagrKeys[keys] === "S44-O262-HideLSBReportsForTeachers" ||
  //       flagrKeys[keys] === "S57-B1240-NewClassSummaryTab" ||
  //       flagrKeys[keys] === "ShowSisenseReportForWNEUsers" ||
  //       flagrKeys[keys] === "SpanishLanguageScience"
  //     ) {
  //       flagrvalues[flagrKeys[keys]] = false;
  //     } else {
  //       flagrvalues[flagrKeys[keys]] = true;
  //     }
  //   }
  //   dispatch(updateFlagrKeys(flagrvalues));
  // }

  function checker() {
    handleSubmit();
  }

  const focusOnUsername = () => {
    setTimeout(() => {
      document.getElementById("username").focus();
    }, 12000);
  };

  async function handleSubmit() {
    if (currentTab === "educator" || currentTab === "student") {
      if (
        state.password.includes(" ") ||
        state.password === "" ||
        state.loginCode.includes(" ") ||
        state.loginCode === ""
      ) {
        setShowToast({
          val: true,
          msg: currentTab === "educator" ? configMessages.NewInvalidCredentials : configMessages.invalidCredentials
        });
        focusOnUsername();
        return;
      }
    }

    const clientDateTime = moment().format();
    const useragent = navigator.userAgent;
    localStorage.setItem("ala:loginCode", state.loginCode);
    try {
      setIsSubmitting(true);
      const jsonObj = {
        userName: state.username,
        password: state.password,
        IsWneForm: currentTab === "wneuser" ? true : false,
        ClientDateTime: clientDateTime,
        UserAgent: useragent,
        loginCode: localStorage.getItem("ala:loginCode")
      };
      UserService.isMigrated(jsonObj).then(async (res: Response | ky.HTTPError) => {
        if ((res as Response).status === 200) {
          //@ts-ignore
          document.getElementById("ustate").value =
            localStorage.getItem("oidc:persistState") !== null
              ? localStorage.getItem("oidc:persistState")
              : localStorage.getItem("oidc:state");
          localStorage.setItem("oidc:session", "true");
          const getSubmit = document.getElementById("usubmit");
          getSubmit?.click();
          setIsSubmitting(false);
        } else {
          let details =
            typeof (res as ky.HTTPError).response !== "undefined" ? await (res as ky.HTTPError).response.json() : 0;
          if (details === 1) {
            if (currentTab === "student") {
              setShowToast({
                val: true,
                msg: configMessages.invalidCredentials
              });
              focusOnUsername();
            } else if (currentTab === "educator") {
              setShowToast({
                val: true,
                msg: configMessages.NewInvalidCredentials
              });
              focusOnUsername();
            } else {
              setShowToast({
                val: true,
                msg: "You entered an incorrect username, password, or both."
              });
              focusOnUsername();
            }
          } else if (details === 2) {
            if (currentTab === "student") {
              setShowToast({
                val: true,
                msg: configMessages.invalidCredentials
              });
              focusOnUsername();
            } else if (currentTab === "educator") {
              setShowToast({
                val: true,
                msg: configMessages.NewInvalidCredentials
              });
              focusOnUsername();
            } else {
              setShowToast({
                val: true,
                msg: "Username/Password is not correct"
              });
              focusOnUsername();
            }
          } else if (details === 3) {
            setShowToast({
              val: true,
              msg: configMessages.AuthenticatorErrorMessage
            });
            focusOnUsername();
          } else {
            setShowToast({
              val: true,
              msg: "If you are trying to log in simultaneously to a different user account, this is not supported. Try a different browser or open a new incognito window."
            });
            focusOnUsername();
          }
          if (localStorage.getItem("ala:hasCode")) {
            setState({
              ...state,
              password: "",
              username: "",
              loginCode: localStorage.getItem("ala:loginCode") || "",
              process: false
            });
          } else {
            localStorage.removeItem("ala:loginCode");
            setState({
              ...state,
              password: "",
              username: "",
              loginCode: "",
              process: false
            });
          }
          setIsSubmitting(false);
        }
      });
    } catch (error) {
      setShowToast({
        val: true,
        msg: configMessages.AuthenticatorErrorMessage
      });
      setIsSubmitting(false);
      setState({
        ...state,
        process: true
      });
    }
  }
  function forgotPassword() {
    navigate("/forgotpassword");
  }

  function isWneUserApp() {
    return window.location.host === window.APP_ENV.WneUserVanityURL || window.location.host === "localhost:3000"; //When running locally, to enable student/educator screen, change to 30000
  }

  function redirectToUlpFromLink() {
    window.location.replace(window.APP_ENV.ulpRedirectUrl);
    // window.location.replace('https://tesstapi.imaginelearning.com/signin')
  }

  function responsiveLayout(loginUser: string) {
    if (loginUser !== "wneuser" && redirectToULP && params.get("redirect") !== "off") {
      if (state.loginCode) {
        window.location.replace(`${window.APP_ENV.ulpRedirectUrl}/signin?sitecode=${state.loginCode}`);
      } else {
        window.location.replace(window.APP_ENV.ulpRedirectUrl);
      }
      return (
        <>
          <div className="loginbackground"> </div>
        </>
      ); // display this instead of blank screen
    }

    return (
      <>
        <div className="loginbackground"> </div>
        {state.process ? <UxActivityIndicator type="nouser" /> : null}
        <div id="loginpage" className="loginlayout">
          <div className={loginUser === "wneuser" ? "loginholderadmin" : "loginholder"}>
            <div className={loginUser === "wneuser" ? "loginfieldswne" : "loginfields"}>
              <div className={"imgname"} style={loginUser === "wneuser" ? { padding: "16px" } : { padding: "20px" }}>
                {" "}
                <img
                  src={loginUser === "wneuser" ? logo : Ilogo}
                  alt="Imagine MyPath logo"
                  style={loginUser === "wneuser" ? { width: "60%" } : { width: "36.6%" }}
                />
              </div>
              {loginUser === "wneuser" ? responsiveWNETab() : responsiveStudentEducatorTab()}
            </div>
            {loginUser !== "wneuser" && (
              <div
                className="EducatorMesssage"
                style={{
                  visibility: loginUser === "educator" ? "visible" : "hidden"
                }}
              >
                <b>Educators</b>: Please log in using your email address. <br></br> If you are having issues logging in,
                please contact Imagine Learning support.
              </div>
            )}

            <div className="loginreset">
              <div className="forgotPasswordHolder">
                <p
                  onClick={() => {
                    forgotPassword();
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      forgotPassword();
                    }
                  }}
                  className="forgotPasswordTag"
                  tabIndex={0}
                  role="link"
                  style={
                    loginUser !== "student"
                      ? { opacity: 1, visibility: "visible" }
                      : { opacity: 0, visibility: "hidden" }
                  }
                >
                  <u> {"Reset Your Password"} </u>
                  <br />
                </p>
              </div>
            </div>
            <div>
              <div>
                <p
                  onClick={() => {
                    redirectToUlpFromLink();
                  }}
                  className="forgotPasswordTag"
                  tabIndex={0}
                  role="link"
                  style={
                    loginUser !== "wneuser" && displayRedirectToUlpMessage
                      ? { opacity: 1, visibility: "visible" }
                      : { opacity: 0, visibility: "hidden" }
                  }
                >
                  <u> {"Click Here to log into MyPath through the Universal Login Page & Product Selection Screen"} </u>
                </p>
              </div>
            </div>
          </div>
          <div className="loginfooterfields">
            {loginUser === "wneuser" ? (
              <div className="leftfooter">© {new Date().getFullYear()} Edgenuity Inc. All Rights Reserved.</div>
            ) : (
              <div className="leftfooter">© {new Date().getFullYear()} Imagine Learning LLC. All Rights Reserved.</div>
            )}
            <div className="rightfooter" style={{ fontWeight: 500 }} role="contentinfo" aria-label="Footer">
              <a
                className="tagRightFooter"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/en/us/products/mypath"
                id="about"
                tabIndex={0}
                aria-label="About link opens in a new tab"
                role="link"
                onKeyDown={(event) => {
                  if (event.key === "Spacebar" || event.key === " ") {
                    window.open("https://www.imaginelearning.com/en/us/products/mypath");
                  }
                }}
              >
                <u> About </u>
              </a>
              <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
              <a
                className="tagRightFooter  "
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/support/privacy/policy/"
                tabIndex={0}
                aria-label="Privacy Policy link opens in a new tab"
                id="policy"
                role="link"
              >
                <u> Privacy Policy</u>
              </a>
              <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
              <a
                className="tagRightFooter  "
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.imaginelearning.com/media/pdf/End%20User%20TOS%202022%20IL%20LLC.pdf"
                tabIndex={0}
                aria-label="Terms of Use link opens in a new tab"
                id="terms"
                role="link"
              >
                <u> Terms of Use </u>
              </a>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <form action={`${Settings().authority}/auth/login`} method="post">
            <input type="text" id="uname" name="username" value={state.username} readOnly />
            <input type="text" id="upass" name="password" value={state.password} readOnly />
            <input type="text" id="ustate" name="state" value={localStorage.getItem("oidc:state")} readOnly />
            <input
              type="text"
              id="uclientId"
              name="clientId"
              value={
                localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
              }
              readOnly
            />
            {loginUser !== "wneuser" && <input type="text" id="usid" name="siteId" value={state.loginCode} readOnly />}
            <input
              type="text"
              id="utenant"
              name="tenant"
              value={window.APP_ENV ? window.APP_ENV.tenant : "Tenant.ALA"}
              readOnly
            />
            <input type="submit" id="usubmit" value="login" />
          </form>
        </div>
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={12000}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            <Alert
              severity="warning"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "" });
              }}
            >
              {showToast?.msg.includes("\n")
                ? showToast?.msg.split("\n").map((message: string, index: number, arr: Array<string>) => (
                    <p
                      style={{
                        marginBottom: arr.length - 1 === index && 0
                      }}
                    >
                      {message}
                    </p>
                  ))
                : showToast?.msg}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }

  function responsiveWNETab() {
    return (
      <div className="loginHolderWNE" style={{ padding: "28px 0px" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login Page: Edgenuity</title>
        </Helmet>
        <div className="loginHeadingWNE extra-margin-top"> Weld North Education</div>
        <div className="loginHeadingWNE extra-margin-bottom"> Employee Login </div>
        <div className="textinputholderwne">
          <input
            style={{ border: "1px solid #cccccc" }}
            id="username"
            data-testid='username'
            className="logintextinputresponsive urname tabfocus loginellipsis"
            maxLength={50}
            placeholder="Username"
            value={state.username}
            autoComplete="username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value !== " ") {
                e.target.value = e.target.value.replace(" ", "").trim();
                setState({ ...state, username: e.target.value });
              }
            }}
            tabIndex={0}
          />
        </div>
        <div className="textinputholderwne">
          <div>
            <input
              style={{ border: "1px solid #cccccc" }}
              className={"logintextinputresponsive urpass tabfocus loginellipsis"}
              maxLength={50}
              data-testid="password"
              type="password"
              placeholder="Password"
              value={state.password}
              autoComplete="current-password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value !== " ") {
                  e.target.value = e.target.value.replace(" ", "").trim();
                  setState({ ...state, password: e.target.value });
                }
              }}
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  !state.username.includes(" ") &&
                  !state.password.includes(" ") &&
                  state.username !== "" &&
                  state.password !== ""
                ) {
                  checker();
                }
              }}
              tabIndex={0}
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                if (state.username.includes(" ") || state.username === "") {
                  event.stopPropagation();
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div
          className="loginbuttonresponsive"
          style={
            !state.username.includes(" ") &&
            !state.password.includes(" ") &&
            state.username !== "" &&
            state.password !== ""
              ? { opacity: "1", marginTop: "31px" }
              : { opacity: "0.65", marginTop: "31px" }
          }
        >
          <UxAction
            callback={() => {
              checker();
            }}
            className="loginbuttonresponsive"
            size={"lg"}
            type={UxActionTypeEnum.SUBMIT}
            enabled={
              !state.username.includes(" ") &&
              !state.password.includes(" ") &&
              state.username !== "" &&
              state.password !== ""
                ? true
                : false
            }
            text={!isSubmitting ? t("Login") : undefined}
            priority={UxPriorityEnum.INFO}
            style={UxStyleEnum.ROUNDED}
            icon={
              (isSubmitting && {
                icon: UxIconEnum.CIRCLE_NOTCH,
                className: "animate spinner"
              }) ||
              undefined
            }
          />
        </div>
        <div style={{ height: "31px" }}></div>
      </div>
    );
  }

  function responsiveStudentEducatorTab() {
    return (
      <div className="loginHolderWNE" style={{ padding: "15px 10px" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login Page: Imagine MyPath</title>
        </Helmet>
        <div>
          <div className="full-width display-center stuedutabs">
            <UxTabs
              tabsId="class-tabs"
              activeKey={currentTab}
              onTabSelect={(tab: string) => {
                setCurrentTab(tab);
                localStorage.setItem("ala:redirectedFrom", tab);
                const resetState = {
                  password: "",
                  username: "",
                  loginCode: localStorage.getItem("ala:loginCode") || "",
                  process: false
                };
                setState(resetState);
                setShowPassStud(false);
                if (localStorage.getItem("ala:loginCode")) {
                  setShowLoginCode(false);
                } else {
                  setShowLoginCode(true);
                }
              }}
              children={tabs}
              className="pt-2 display-center"
              isLogin={true}
            />
          </div>
        </div>
        <div className="textinputholder">
          <div style={{ marginBottom: "5px" }}>
            <span className="inputlabel">{currentTab === "educator" ? "Username/Email" : "Username"}</span>
            {currentTab === "educator" && (
              <LightTooltip title={educatorEmailTitle} placement="right">
                <i
                  className="fa fa-question-circle-o tabfocus"
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                  tabIndex={0}
                  aria-label={`Starting June 3, 2023 you will be asked to log into your account with
                  your email address as your username. Please review your profile settings
                  and ensure your current email is up to date.`}
                />
              </LightTooltip>
            )}
          </div>
          <input
            id="username"
            className="logintextinputresponsive urname tabfocus loginellipsis"
            maxLength={128}
            placeholder="Enter Username"
            value={state.username}
            autoComplete="username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value !== " ") {
                setState({ ...state, username: e.target.value });
              }
            }}
            tabIndex={0}
          />
        </div>
        <div className="textinputholder">
          <div style={{ marginBottom: "5px" }}>
            <span className="inputlabel">Password</span>
            <LightTooltip
              title={currentTab === "educator" ? educatorPasswordTitle : studentPasswordTitle}
              placement="right"
            >
              <i
                className="fa fa-question-circle-o tabfocus"
                style={{ marginLeft: "5px", cursor: "pointer" }}
                tabIndex={0}
                aria-label={
                  currentTab === "educator"
                    ? ` Educator password requirements: Educator password must be 8 characters of 1 uppercase, 1 lowercase, 1
                number, 1 special character and no spaces. Period cannot be the only
                special character`
                    : ` Student password requirements:  Student password must be any 6 characters with no spaces.`
                }
              />
            </LightTooltip>
          </div>

          <div className="showHideInputView  ">
            <input
              className={"logintextinputresponsivepwd urpass tabfocus loginellipsis"}
              maxLength={256}
              type={showPassStud ? "text" : "password"}
              placeholder="Enter Password"
              value={state.password}
              autoComplete="current-password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value !== " ") {
                  e.target.value = e.target.value.replace(" ", "").trim();
                  setState({ ...state, password: e.target.value });
                }
              }}
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  !state.username.includes(" ") &&
                  !state.password.includes(" ") &&
                  state.username !== "" &&
                  state.password !== ""
                ) {
                  checker();
                }
              }}
              tabIndex={0}
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                if (state.username.includes(" ") || state.username === "") {
                  event.stopPropagation();
                  event.preventDefault();
                }
              }}
            />

            {state.password !== "" && (
              <img
                src={!showPassStud ? SHOWPASS : HIDEPASS}
                alt=""
                width={!showPassStud ? "24px" : "28px"}
                style={{
                  marginRight: `${!showPassStud ? "10px" : "8px"}`,
                  marginTop: `${!showPassStud ? "0" : "1px"}`,
                  cursor: "pointer"
                }}
                onClick={() => {
                  setShowPassStud(!showPassStud);
                }}
              />
            )}
          </div>
        </div>
        <div className="textinputholder">
          <span className="inputlabel">Login Code</span>
          {showLoginCode ? (
            <input
              className="loginTextInputCode  tabfocus  loginellipsis"
              maxLength={64}
              type="text"
              value={state.loginCode}
              placeholder="Enter Login Code"
              autoComplete="logincode"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value !== " ") {
                  e.target.value = e.target.value.replace(" ", "").trim();
                  setState({ ...state, loginCode: e.target.value });
                }
              }}
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  !state.username.includes(" ") &&
                  !state.password.includes(" ") &&
                  !state.loginCode.includes(" ") &&
                  state.username !== "" &&
                  state.password !== "" &&
                  state.loginCode !== ""
                ) {
                  checker();
                }
              }}
              tabIndex={0}
            />
          ) : (
            <div className="logincodepen">
              {localStorage?.getItem("ala:loginCode")?.length < 50 ? (
                <div style={{ marginRight: "8px" }}>{localStorage.getItem("ala:loginCode")}</div>
              ) : (
                <div style={{ marginRight: "8px" }}>{localStorage?.getItem("ala:loginCode")?.slice(0, 20)}...</div>
              )}

              <div>
                <img
                  src={PEN}
                  alt=""
                  style={{ cursor: "pointer" }}
                  width="20px"
                  onClick={() => {
                    setShowLoginCode(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="loginbuttonresponsive">
          <UxAction
            callback={() => {
              checker();
            }}
            className="loginbuttonresponsive"
            size={"lg"}
            type={UxActionTypeEnum.SUBMIT}
            enabled={true}
            text={!isSubmitting ? t("Login") : undefined}
            priority={UxPriorityEnum.INFO}
            style={UxStyleEnum.ROUNDED}
            icon={
              (isSubmitting && {
                icon: UxIconEnum.CIRCLE_NOTCH,
                className: "animate spinner"
              }) ||
              undefined
            }
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {!isSSO && renderLoginForm && !isImpersonationState && !mppApp && responsiveLayout(currentTab)}

      {!renderLoginForm && (
        <div className="signinDialog">
          <div className="signinHolder">
            <div className="spinner-border text-light loadingSpinner" role="status" />
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Roboto",
                color: "#FFFFFF"
              }}
            ></span>
          </div>
        </div>
      )}

      {(isSSO || isImpersonationState || mppApp) && (
        <div className="signinDialog">
          <div className="signinHolder">
            <div
              className="spinner-border text-light loadingSpinner"
              role="status"
              aria-label="Authenticating------. After successful authentication, you will be directed to your home page."
            />
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Roboto",
                color: "#FFFFFF"
              }}
            >
              Authenticating
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(Login);
