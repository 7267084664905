import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { useParams } from "react-router-dom";
import ViewEducatorProfile from "./ViewEducatorProfile";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import { EducatorProfileProps } from "./EducatorProfile.props";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails
  };
}
interface Props extends EducatorProfileProps, ReduxProps {}

function SchoolEducatorProfile(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey, setTabKey] = useState("settings");
  const { dispatch, getDaDetails } = props;

  useEffect(() => {
    localStorage.setItem("page", "settings");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.getEducatorDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setEducatorBreadcrumb(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  async function setEducatorBreadcrumb(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setBreadCrumb(details.firstName, details.lastName, details.userID, details.organizations[0].orgGUID);
  }

  async function setBreadCrumb(fname: string, lname: string, uid: number, oid: number) {
    try {
      let apiObj = { rid: oid, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            handleSuccessResponse(data, fname, lname, uid, oid);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function handleSuccessResponse(
    responsedata: Response,
    fname: string,
    lname: string,
    _uid: number,
    oid: number
  ) {
    let details = await responsedata.json();
    //@ts-ignore
    dispatch(updatePageTitle(`- Schools - ${fname} ${lname}`));
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
        //@ts-ignore
        { path: `/saclasslist/${oid}`, name: `${details.name}` },
        { path: `/svieweducator/${oid}`, name: `${fname} ${lname}` }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
  }
  const tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <ViewEducatorProfile />
    }
  ];
  return (
    <>
      <div>
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(SchoolEducatorProfile);
