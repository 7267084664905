import { useDispatch, useSelector } from "react-redux";
import { updateGrade as updateReduxGrade } from "../../../Redux/Action";
import useGradeBand from "./useGradeBand";

export default function useGrade() {
  const grade = useSelector((state: any) => state.getChronologicalGrade);
  const dispatch = useDispatch();

  const gradeBands = useGradeBand(grade);

  const updateGrade = (grade: string) => {
    dispatch(updateReduxGrade(grade));
  };

  return { grade, updateGrade, ...gradeBands };
}
