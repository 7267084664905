import {
  PaginationControllerState,
  Sorting,
  ClassDetails,
  Calender,
  SchoolGuidsResponseProps,
  ClassSummaryReport,
  SelectedStudentProps,
  PendoEducatorDetails,
  SiteCodeDetails,
  UserDetails,
  OrganizationDetails
} from "../Redux.props";

export const HEADERDATA = "HEADERDATA";
export const BREADCRUMBS = "BREADCRUMBS";
export const USERROLE = "USERROLE";
export const USERROLEID = "USERROLEID";
export const USERID = "USERID";
export const USERGUID = "USERGUID";
export const USEREMAIL = "USEREMAIL";
export const USERLASTNAME = "USERLASTNAME";
export const USERFIRSTNAME = "USERFIRSTNAME";
export const CURRENTUSERDETAILS = "CURRENTUSERDETAILS";
export const CURRENTORGANIZATIONDETAILS = "CURRENTORGANIZATIONDETAILS";
export const DADETAILS = "DADETAILS";
export const ACTIVITYINDICATOR = "ACTIVITYINDICATOR";
export const CLASSVIEWTAB = "CLASSVIEWTAB";
export const SCHOOLID = "SCHOOLID";
export const SCHOOLGUID = "SCHOOLGUID";
export const REFRESHSP = "REFRESHSP";
export const USERNAME = "USERNAME";
export const SIDEBARACTIVECLASS = "SIDEBARACTIVECLASS";
export const PAGETITLE = "PAGETITLE";
export const ROSTERFILTER = "ROSTERFILTER";
export const STUDENTSFILTER = "STUDENTSFILTER";
export const EDUCATORSFILTER = "EDUCATORSFILTER";
export const WNEUSERFILTER = "WNEUSERFILTER";
export const CLASSFILTER = "CLASSFILTER";
export const STUDENTLISTSORT = "STUDENTLISTSORT";
export const SEARCHSTUDENTLISTSORT = "SEARCHSTUDENTLISTSORT";
export const WNEUSERLISTSORT = "WNEUSERLISTSORT";
export const CLASSLISTSORT = "CLASSLISTSORT";
export const ROSTERLISTSORT = "ROSTERLISTSORT";
export const CUSTOMERLISTSORT = "CUSTOMERLISTSORT";
export const TABCHANGE = "TABCHANGE";
export const EDUCATORLISTSORT = "EDUCATORLISTSORT";
export const LICENSINGLISTSORT = "LICENSINGLISTSORT";
export const SEARCHEDUCATORLISTSORT = "SEARCHEDUCATORLISTSORT";
export const STUDENTPLACEMENTLISTSORT = "STUDENTPLACEMENTLISTSORT";
export const STUDENTPLACEMENTFILTER = "STUDENTPLACEMENTFILTER";
export const PAGINATIONCONTROLLERSTATE = "PAGINATIONCONTROLLERSTATE";
export const FLAGRKEYS = "FLAGRKEYS";
export const BULKLISTSORT = "BULKLISTSORT";
export const USERROLEDETAILS = "USERROLEDETAILS";
export const ASSESSMENTTAB = "ASSESSMENTTAB";
export const EXTERNALID = "EXTERNALID";
export const EDUCATORDETAILS = "EDUCATORDETAILS";
export const SITECODEDETAILS = "SITECODEDETAILS";
export const ASSESSMENTDETAILS = "ASSESSMENTDETAILS";
export const USEID = "USEID";
export const CATEGORY = "CATEGORY";
export const ANNOTATEURL = "ANNOTATEURL";
export const ONPROCESS = "ONPROCESS";
export const PREVIOUS_URL = "PREVIOUS_URL";
export const UPDATE_REPORTS_LEVEL = "UPDATE_REPORTS_LEVEL";
export const DOLITTLETOKEN = "DOLITTLETOKEN";
export const ACADEMICGRADE = "ACADEMICGRADE";
export const SHOWSCREEN = "SHOWSCREEN";
export const ACTIVEURL = "ACTIVEURL";
export const TABNAME = "TABNAME";
export const CLASSGUID = "CLASSGUID";
export const LSBREPORT = "LSBREPORT";
export const DISTRICTLEVELFIRSTTAB = "DISTRICTLEVELFIRSTTAB";
export const ORGLOGINCODEAVAILABLE = "ORGLOGINCODEAVAILABLE";
export const TRANSITIONSCREEN = "TRANSITIONSCREEN";
export const TESTTIMEAGAIN = "TESTTIMEAGAIN";
export const LICENSINGORGIDS = "LICENSINGORGIDS";
export const CURRENTSCHOOLID = "CURRENTSCHOOLID";
export const CURRENTSCHOOLGUID = "CURRENTSCHOOLGUID";
export const STUDENTSUBTAB = "STUDENTSUBTAB";
export const TOPLEVELDA = "TOPLEVELDA";
export const CICENABLE = "CICENABLE";
export const FIRSTTAB = "FIRSTTAB";
export const PREVIOUSURL = "PREVIOUSURL";
export const FROMSIDEBARASSESSMENT = "FROMSIDEBARASSESSMENT";
export const PREVIOUSURLCLASSPROGRESS = "PREVIOUSURLCLASSPROGRESS";
export const LOGLISTSORT = "LOGLISTSORT";
export const SHOWLOGTABLE = "SHOWLOGTABLE";
export const REPORTCARD = "REPORTCARD";
export const CLASSDETAILS = "CLASSDETAILS";
export const IMPORTLOGFILTER = "IMPORTLOGFILTER";
export const IMPORTLOGCALENDAR = "IMPORTLOGCALENDAR";
export const NETSUITECALENDAR = "NETSUITECALENDAR";
export const SUBJECTNAME = "SUBJECTNAME";
export const ADMINNAME = "ADMINNAME";

export const LEVEL = "LEVEL";
export const PREVIOUSMYPATHURL = "PREVIOUSMYPATHURL";
export const SCHOOLGUIDS = "SCHOOLGUIDS";

export const BREAKPOINT = "BREAKPOINT";
export const DLLSBReportsData = "DLLSBReportsData";
export const ISWNEUSER = "ISWNEUSER";

export const CLASSEXTERNALID = "CLASSEXTERNALID";
export const STUDENTIDS = "STUDENTIDS";
export const SPANISHIDS = "SPANISHIDS";
export const CREATESTUDENT = "CREATESTUDENT";
export const PREVIOUSBENCHMARKGROWTHREPORTURL = "PREVIOUSBENCHMARKGROWTHREPORTURL";

export const ISSESSIONMODAL = "ISSESSIONMODAL";

export const GETSTUDENTANDCLASSFILTERURL = "GETSTUDENTANDCLASSFILTERURL";

export const ASSIGNMENTBUILDERORGID = "ASSIGNMENTBUILDERORGID";

export const STUDENTSEARCHINPUTVALUE = "STUDENTSEARCHINPUTVALUE";

export const ACADEMICCALENDAR = "ACADEMICCALENDAR";
export const CLASSSUMMARYREPORT = "CLASSSUMMARYREPORT";
export const SELECTEDSTUDENTIDS = "SELECTEDSTUDENTIDS";
export const UPDATESHOWCOMPONENT = "UPDATESHOWCOMPONENT";
export const ADDSTUDENTROSTER = "ADDSTUDENTROSTER";
export const UPDATEROLELEVEL = "updateRoleLevel";
export const GETORGFLAGRKEYS = "GETORGFLAGRKEYS";
export const GETDSDSREPORTTOKEN = "GETDSDSREPORTTOKEN";
export const GETCHILDDEPTH = "GETCHILDDEPTH";
export const ISORGANIZATIONACTIVE = "isOrganizationActive";
export const ISMPPAPP = "ISMPPAPP";
export const GETMPPSUBJECT = "GETMPPSUBJECT";
export const ISINHERITED = "ISINHERITED";
export const ISORGHASREGISTRATIONOWNED = "ISORGHASREGISTRATIONOWNED";

export function updateFlagrKeys(data: {}) {
  return {
    type: FLAGRKEYS,
    payload: data
  };
}
export function updateStudentPlacementFilter(data: Array<string>) {
  return {
    type: STUDENTPLACEMENTFILTER,
    payload: data
  };
}

export function updateStudentPlacementListSort(data: Sorting) {
  return {
    type: STUDENTPLACEMENTLISTSORT,
    payload: data
  };
}

export function updateEducatorListSort(data: Sorting) {
  return {
    type: EDUCATORLISTSORT,
    payload: data
  };
}

export function updateSearchEducatorListSort(data: Sorting) {
  return {
    type: SEARCHEDUCATORLISTSORT,
    payload: data
  };
}

export function updateTabChange(data: boolean) {
  return {
    type: TABCHANGE,
    payload: data
  };
}

export function updateActivityIndicator(data: boolean) {
  return {
    type: ACTIVITYINDICATOR,
    payload: data
  };
}

export function updateCustomerListSort(data: Sorting) {
  return {
    type: CUSTOMERLISTSORT,
    payload: data
  };
}

export function updateClassesListSort(data: Sorting) {
  return {
    type: CLASSLISTSORT,
    payload: data
  };
}

export function updateRosterListSort(data: Sorting) {
  return {
    type: ROSTERLISTSORT,
    payload: data
  };
}

export function updateWNEUserListSort(data: Sorting) {
  return {
    type: WNEUSERLISTSORT,
    payload: data
  };
}

export function updateSearchStudentListSort(data: Sorting) {
  return {
    type: SEARCHSTUDENTLISTSORT,
    payload: data
  };
}

export function updateStudentListSort(data: Sorting) {
  return {
    type: STUDENTLISTSORT,
    payload: data
  };
}

export function updateRosterFilter(data: Array<string>) {
  return {
    type: ROSTERFILTER,
    payload: data
  };
}

export function updateWNEUserFilter(data: Array<string>) {
  return {
    type: WNEUSERFILTER,
    payload: data
  };
}

export function updateClassFilter(data: Array<string>) {
  return {
    type: CLASSFILTER,
    payload: data
  };
}

export function updatePageTitle(data: string) {
  return {
    type: PAGETITLE,
    payload: data
  };
}

export function updateHeaderData(data: string) {
  return {
    type: HEADERDATA,
    payload: data
  };
}

export function updateUserName(data: string) {
  return {
    type: USERNAME,
    payload: data
  };
}

export function updateBreadCrumbs(data: { crumbs: { name: string; path?: string }[] } | undefined[]) {
  return {
    type: BREADCRUMBS,
    payload: data
  };
}

export function updateUserRole(data: string) {
  return {
    type: USERROLE,
    payload: data
  };
}

export function updateUserRoleId(data: number) {
  return {
    type: USERROLEID,
    payload: data
  };
}

export function updateUserId(data: string) {
  return {
    type: USERID,
    payload: data
  };
}

export function updateUserGuId(data: string) {
  return {
    type: USERGUID,
    payload: data
  };
}

export function updateUserEmail(data: string) {
  return {
    type: USEREMAIL,
    payload: data
  };
}

export function updateUserLastName(data: string) {
  return {
    type: USERLASTNAME,
    payload: data
  };
}

export function updateUserFirstName(data: string) {
  return {
    type: USERFIRSTNAME,
    payload: data
  };
}

export function updateCurrentUserDetails(data: UserDetails) {
  return {
    type: CURRENTUSERDETAILS,
    payload: data
  };
}

export function updateCurrentOrganizationDetails(data: OrganizationDetails) {
  return {
    type: CURRENTORGANIZATIONDETAILS,
    payload: data
  };
}

export function updateDaDetails(data: { name: string; id: string[] | string; type: number }) {
  return {
    type: DADETAILS,
    payload: data
  };
}

export function updateClassViewTab(data: string) {
  return {
    type: CLASSVIEWTAB,
    payload: data
  };
}

export function updateSchoolID(data: number | string) {
  return {
    type: SCHOOLID,
    payload: data
  };
}

export function updateSchoolGUID(data: number | string) {
  return {
    type: SCHOOLGUID,
    payload: data
  };
}

export function updateRefreshSP(data: boolean) {
  return {
    type: REFRESHSP,
    payload: data
  };
}

export function updateSidebarActiveClass(data: string) {
  return {
    type: SIDEBARACTIVECLASS,
    payload: data
  };
}

export function updateStudentsFilter(data: Array<string>) {
  return {
    type: STUDENTSFILTER,
    payload: data
  };
}

export function updateEducatorsFilter(data: Array<string>) {
  return {
    type: EDUCATORSFILTER,
    payload: data
  };
}

export function updatePaginationControllerState(data: PaginationControllerState[]) {
  return {
    type: PAGINATIONCONTROLLERSTATE,
    payload: data
  };
}

export function updateBulkListSort(data: Sorting) {
  return {
    type: BULKLISTSORT,
    payload: data
  };
}

export function updateUserRoleDetails(data: string) {
  return {
    type: USERROLEDETAILS,
    payload: data
  };
}
export function updateExternalId(data: string) {
  return {
    type: EXTERNALID,
    payload: data
  };
}
export function updateClassExternalId(data: string) {
  return {
    type: CLASSEXTERNALID,
    payload: data
  };
}
export function updateOnProcess(data: boolean) {
  return {
    type: ONPROCESS,
    payload: data
  };
}
export function updateActiveURL(data: string) {
  return {
    type: ACTIVEURL,
    payload: data
  };
}

export function updatePendoEducatorDetails(data: PendoEducatorDetails) {
  return {
    type: EDUCATORDETAILS,
    payload: data
  };
}

export function updateSiteCodeDetails(data: SiteCodeDetails) {
  return {
    type: SITECODEDETAILS,
    payload: data
  };
}

export function updateUseId(data: string) {
  return {
    type: USEID,
    payload: data
  };
}

export function updateAssessmentDetails(data: string) {
  return {
    type: ASSESSMENTDETAILS,
    payload: data
  };
}

export function updatePendoCategoryDetails(data: string) {
  return {
    type: CATEGORY,
    payload: data
  };
}
export function updatePendoUrls(data: string) {
  return {
    type: ANNOTATEURL,
    payload: data
  };
}

export function updateTabName(data: string) {
  return {
    type: TABNAME,
    payload: data
  };
}

export function updateClassGUID(data: string) {
  return {
    type: CLASSGUID,
    payload: data
  };
}

export function updateLSBReport(data: boolean) {
  return {
    type: LSBREPORT,
    payload: data
  };
}

export function updateDistrictLevelFirstTab(data: string) {
  return {
    type: DISTRICTLEVELFIRSTTAB,
    payload: data
  };
}

export function updateOrgLoginCodeAvailable(data: boolean) {
  return {
    type: ORGLOGINCODEAVAILABLE,
    payload: data
  };
}

export function updateGrade(data: string) {
  return {
    type: ACADEMICGRADE,
    payload: data
  };
}

export function updateShowScreen(data: boolean) {
  return {
    type: SHOWSCREEN,
    payload: data
  };
}

export function updatePreviousUrl(data: string) {
  return {
    type: PREVIOUS_URL,
    payload: data
  };
}
export function updateDoLittleToken(data: string) {
  return {
    type: DOLITTLETOKEN,
    payload: data
  };
}

export function updateReportsLevel(data) {
  return {
    type: UPDATE_REPORTS_LEVEL,
    payload: data
  };
}

export function updateLicensingListSort(data: Sorting) {
  return {
    type: LICENSINGLISTSORT,
    payload: data
  };
}

export function updateLicensingOrgIds(data: Array<string>) {
  return {
    type: LICENSINGORGIDS,
    payload: data
  };
}

export function updateCurrentSchoolID(data: number) {
  return {
    type: CURRENTSCHOOLID,
    payload: data
  };
}

export function updateCurrentSchoolGuid(data: string) {
  return {
    type: CURRENTSCHOOLGUID,
    payload: data
  };
}

export function updateStudentsSubtab(studentSubtab: string) {
  return {
    type: STUDENTSUBTAB,
    payload: studentSubtab
  };
}

export function updateTopLevelDA(data: boolean) {
  return {
    type: TOPLEVELDA,
    payload: data
  };
}

export function updateCicEnable(data: boolean) {
  return {
    type: CICENABLE,
    payload: data
  };
}

export function updateAssessmentTab(data: string) {
  return {
    type: ASSESSMENTTAB,
    payload: data
  };
}

export function updateFirstTab(data: string) {
  return {
    type: FIRSTTAB,
    payload: data
  };
}

export function updatePreviousURL(data: string) {
  return {
    type: PREVIOUSURL,
    payload: data
  };
}

export function updateFromSideBarAssessment(data: boolean) {
  return {
    type: FROMSIDEBARASSESSMENT,
    payload: data
  };
}

export function updatePreviousURLForClassProgress(data: string) {
  return {
    type: PREVIOUSURLCLASSPROGRESS,
    payload: data
  };
}

export function updateLogListSort(data: Sorting) {
  return {
    type: LOGLISTSORT,
    payload: data
  };
}

export function updateShowLogTable(data: boolean) {
  return {
    type: SHOWLOGTABLE,
    payload: data
  };
}

export function updateReportCard(data: string) {
  return {
    type: REPORTCARD,
    payload: data
  };
}

export function updateClassDetails(data: ClassDetails) {
  return {
    type: CLASSDETAILS,
    payload: data
  };
}

export function updateImportLogFilter(data: Array<string>) {
  return {
    type: IMPORTLOGFILTER,
    payload: data
  };
}
export function updateImportLogCalender(data: Calender) {
  return {
    type: IMPORTLOGCALENDAR,
    payload: data
  };
}
export function updateNetSuiteCalender(data: Calender) {
  return {
    type: NETSUITECALENDAR,
    payload: data
  };
}

export function updateSubjectName(data: string) {
  return {
    type: SUBJECTNAME,
    payload: data
  };
}

export function updateAdminName(data: string) {
  return {
    type: ADMINNAME,
    payload: data
  };
}

export function updateLevel(data: string) {
  return {
    type: LEVEL,
    payload: data
  };
}

export function updateBreakpoint(data: string) {
  return {
    type: BREAKPOINT,
    payload: data
  };
}

export function updatePreviousMyPathURL(data: string) {
  return {
    type: PREVIOUSMYPATHURL,
    payload: data
  };
}

export function updateSchoolGuids(data: SchoolGuidsResponseProps[]) {
  return {
    type: SCHOOLGUIDS,
    payload: data
  };
}

export function updateDistrictLevelLSBReportsData(data: object) {
  return {
    type: DLLSBReportsData,
    payload: data
  };
}

export function updateIsWneUser(data: boolean) {
  return {
    type: ISWNEUSER,
    payload: data
  };
}

export function updateStudentIds(data: Array<string>) {
  return {
    type: STUDENTIDS,
    payload: data
  };
}

export function updateSpanishIds(data: string) {
  return {
    type: SPANISHIDS,
    payload: data
  };
}

export function updatePreviousBenchmarkGrowthReportURL(data: string) {
  return {
    type: PREVIOUSBENCHMARKGROWTHREPORTURL,
    payload: data
  };
}

export function updateSessionModal(data: boolean) {
  return {
    type: ISSESSIONMODAL,
    payload: data
  };
}

export function updateStudentAndClassFilterURL(data: string) {
  return {
    type: GETSTUDENTANDCLASSFILTERURL,
    payload: data
  };
}
export function updateCreateStudent(data: boolean) {
  return {
    type: CREATESTUDENT,
    payload: data
  };
}
export function updateAssignmentBuilderOrgId(data: object) {
  return {
    type: ASSIGNMENTBUILDERORGID,
    payload: data
  };
}

export function updateAcademicCalendar(data: Calender) {
  return {
    type: ACADEMICCALENDAR,
    payload: data
  };
}

export function updateClassSummaryReport(data: ClassSummaryReport) {
  return {
    type: CLASSSUMMARYREPORT,
    payload: data
  };
}

export function updateSelectedStudentIds(data: SelectedStudentProps[]) {
  return {
    type: SELECTEDSTUDENTIDS,
    payload: data
  };
}

export function updateShowComponent(data: boolean) {
  return {
    type: UPDATESHOWCOMPONENT,
    payload: data
  };
}

export function updateAddStudentRoaster(data: {}) {
  return {
    type: ADDSTUDENTROSTER,
    payload: data
  };
}

export function updateRoleLevel(data: string) {
  return {
    type: UPDATEROLELEVEL,
    payload: data
  };
}

export function updateDSDSReportToken(data: string) {
  return {
    type: GETDSDSREPORTTOKEN,
    payload: data
  };
}

export function updateOrgFlagrKeys(data: {}) {
  return {
    type: GETORGFLAGRKEYS,
    payload: data
  };
}
export function updateChildDepth(data: number) {
  return {
    type: GETCHILDDEPTH,
    payload: data
  };
}

export function updateIsOrganizationActive(data: boolean) {
  return {
    type: ISORGANIZATIONACTIVE,
    payload: data
  };
}

export function updateISMPPAPP(data: boolean) {
  return {
    type: ISMPPAPP,
    payload: data
  };
}

export function updateMPPSubject(data: string) {
  return {
    type: GETMPPSUBJECT,
    payload: data
  };
}

export function updateIsInherited(data: boolean) {
  return {
    type: ISINHERITED,
    payload: data
  };
}

export function updateIsOrgRegistrationOwned(data: boolean) {
  return {
    type: ISORGHASREGISTRATIONOWNED,
    payload: data
  };
}
