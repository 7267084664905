import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BulkImportsModal from "../modals/bulk-imports";
import "./EditLicenseType.scss";
import { updateActivityIndicator } from "../../Redux/Action";
import { UxAction, UxShadesEnum, TimesIcon } from "@wne-spa/ux-component-library";
import NavDropdown from "react-bootstrap/NavDropdown";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import EditLicenseProps, { LicenseDataProps, ProductData } from "./EditLicense.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getLicensingOrgIds: state.getLicensingOrgIds,
    getFlagrKeys: state.getFlagrKeys
  };
}
interface Props extends EditLicenseProps, ReduxProps {}
function EditLicenseType(props: Props) {
  const { getLicensingOrgIds, closeModal, ShowLicensingModal, dispatch, handleSuccess, data, getFlagrKeys } = props;
  const [licenseSaveButton, setlicenseSaveButton] = useState(false);
  const [licensedata, setLicensedata] = useState<LicenseDataProps | Array<string>>([]);
  let productLicenseData = data;
  const [productData, setproductData] = useState([
    {
      ProductId: 0,
      LicenseID: 0
    }
  ]);
  let productLicense = [...productData];
  const [columnNames] = useState(["Products", "Set All Type"]);

  const schools = getLicensingOrgIds
    .toString()
    .split(",")
    .filter((item: string) => item);

  const closeLicensingModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (JSON.stringify(licensedata) !== "[]") {
      setlicenseSaveButton(true);
    }
  }, [licensedata]);

  async function saveLicenseData() {
    dispatch(updateActivityIndicator(true));
    try {
      const jsonObj = {
        organizationIds: schools.map(Number),
        OrganizationGuids: schools.map(Number),
        ProductData: productData.filter((item) => item.ProductId !== 0)
      };

      await AdminService.saveProductLicensing(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          handleSuccess();
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (error) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function renderTableHeader() {
    return columnNames.map((key, index) => {
      if (key === "Products")
        return (
          <th style={{ width: "900px" }} className="productHeader" key={index}>
            {key}
          </th>
        );
      else
        return (
          <th className="productHeader" key={index}>
            <NavDropdown
              id="dropdownHolderForHeaderLicenseType"
              title={
                <div>
                  Set All Type
                  <i className="fa fa-chevron-down dropdownheadericonleft"></i>
                </div>
              }
            >
              {(productLicenseData as ProductData).licenses.map((item) => (
                <NavDropdown.Item
                  id="dropdownitem"
                  className="licenseDropdown"
                  onClick={() => {
                    {
                      (productLicenseData as ProductData).products.forEach((row) => {
                        setLicensedata((state: { [key: number]: string }) => ({
                          ...state,
                          [row.productID]: item.licenseType
                        }));
                        productLicense = productLicense.filter((p) => p.ProductId !== row.productID);
                        productLicense.push({
                          ProductId: row.productID,
                          LicenseID: item.licenseTypeID
                        });
                      });
                      setproductData(productLicense);
                    }
                  }}
                >
                  {item.licenseType}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </th>
        );
    });
  }

  function renderTableData() {
    return (
      <>
        {(productLicenseData as ProductData).products.map((row) => (
          <tr>
            <td className="product">{row.productName}</td>
            <td className="product">
              <NavDropdown
                id="dropdownHolderForLicenseType"
                title={
                  <div>
                    {licensedata[row.productID] ? licensedata[row.productID] : "Set Type"}
                    <i className="fa fa-chevron-down dropdowniconleft"></i>
                  </div>
                }
              >
                {(productLicenseData as ProductData).licenses.map((item) => (
                  <NavDropdown.Item
                    id="dropdownitem"
                    className="licenseDropdown"
                    onClick={() => {
                      setLicensedata((state: { [key: number]: string }) => ({
                        ...state,
                        [row.productID]: item.licenseType
                      }));
                      productLicense = productLicense.filter((p) => p.ProductId !== row.productID);
                      productLicense.push({
                        ProductId: row.productID,
                        LicenseID: item.licenseTypeID
                      });
                      setproductData(productLicense);
                    }}
                  >
                    {item.licenseType}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </td>
          </tr>
        ))}
      </>
    );
  }

  function renderProductLicenseData() {
    return (
      <div className="manipContainer">
        <div className="bulkHeader">
          <text className="bulkImportText" style={{ marginLeft: "0px" }}>
            Set License Type
          </text>
          <img
            src={TimesIcon}
            alt=""
            className="closeIcon"
            onClick={() => {
              closeLicensingModal();
            }}
          />
        </div>
        <hr></hr>
        <label className="labelText">
          Set the license type for the {schools.length} {schools.length > 1 ? "schools" : "school"} you selected.
        </label>
        <div className="licensetablehgt">
          <table>
            <thead className="licenseheader">{renderTableHeader()}</thead>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: " flex-end",
            paddingBottom: "10px",
            marginRight: "-43px",
            marginTop: "20px"
          }}
        >
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Cancel"}
            className="px-2 py-1 previousBut licButton"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              closeLicensingModal();
            }}
          />
          <UxAction
            key={1}
            size="sm"
            text="Save"
            className="px-4 py-1 nextBut saveBtn licButton"
            priority={UxShadesEnum.DARK}
            callback={() => {
              saveLicenseData();
            }}
            enabled={licenseSaveButton ? true : false}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <BulkImportsModal
        showBulkModal={ShowLicensingModal}
        children={renderProductLicenseData()}
        width="532px"
        height={getFlagrKeys["MyPathScienceProduct"] ? "505px" : "450px"}
      />
    </>
  );
}

export default connect(mapStateToProps)(EditLicenseType);
