import React from "react";
import RBNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Logo from "../logo";
import NavbarProps from "./navbar.props";
import NavbarItemProps from "./navbar-item.props";
import DirectionEnum from "../enums/direction.enum";
import OrientationEnum from "../enums/orientation.enum";
import SizeEnum from "../enums/size.enum";
import ShadesEnum from "../enums/shades.enum";
import PositionEnum from "../enums/position.enum";
import classMap from "..//utilities/classMap";

import "./studentnavbar.scss";

const Navbar = (props: NavbarProps) => {
  let {
    brand,
    slogan,
    children,
    color,
    expand,
    orientation,
    variant,
    fixed,
    addColumnClass,
    sloganClassName,
    bgClassName
  } = props;

  const sidebar: boolean = orientation === OrientationEnum.VERTICAL;
  const footer: boolean = orientation === OrientationEnum.HORIZONTAL && fixed === PositionEnum.BOTTOM;
  const collapsible: boolean = Boolean(!footer && children.length > 1);

  interface ChildrenByDirectionProps {
    beginning: NavbarItemProps[];
    center: NavbarItemProps[];
    end: NavbarItemProps[];
  }
  const childrenByDirection: ChildrenByDirectionProps = {
    beginning: [],
    center: [],
    end: []
  };
  children.forEach((childProps: NavbarItemProps) =>
    childrenByDirection[childProps.direction || DirectionEnum.END].push(childProps)
  );
  const content =
    children.length &&
    Object.values(DirectionEnum).map((direction: string, directionIndex: number) => {
      return (
        <Nav
          key={directionIndex}
          className={classMap(
            "align-items-center",
            addColumnClass ? "col" : "",
            "justify-content-" + direction,
            sidebar ? "flex-column" : "",
            !collapsible ? "flex-row" : ""
          )}
        >
          {childrenByDirection[direction].map((childProps: NavbarItemProps, index: number) => (
            <div key={index}>{childProps.item}</div>
          ))}
        </Nav>
      );
    });

  const footerClass = () => {
    if (footer) {
      return "footer";
    } else {
      return "header";
    }
  };

  return (
    <RBNavbar
      {...{
        expand: sidebar ? undefined : expand ?? SizeEnum.MEDIUM,
        variant: variant || ShadesEnum.LIGHT,
        className: classMap(
          "edge-ux-navbar",
          "flex-nowrap",
          sidebar ? "sidebar flex-column" : footerClass(),
          props.className
        ),
        fixed: props.fixed,
        sticky: props.sticky,
        role: props.role ?? "banner",
        as: props.asDiv ? "div" : undefined
      }}
    >
      <div
        className={classMap("navbar-bg position-absolute w-100 h-100 left-0 z-index--1", "bg-" + color, bgClassName)}
      />
      {brand && (
        <RBNavbar.Brand
          href={brand.href || undefined}
          className={classMap(sidebar ? "m-0" : "text-truncate stulogo", props.brandCallback && "cursor-pointer")}
          onClick={props.brandCallback}
        >
          {
            <>
              <Logo {...{ ...brand, iconOnly: brand.iconOnly || sidebar }} />
              {slogan && (
                <>
                  <br />
                  <span className={classMap("edge-ux-header", sloganClassName ?? "text-primary")}>{slogan}</span>
                </>
              )}
            </>
          }
        </RBNavbar.Brand>
      )}
      {(collapsible && (
        <>
          <RBNavbar.Collapse
            id="basic-navbar-nav-toggle"
            className={classMap("justify-content-between", sidebar ? "flex-column" : "")}
          >
            {content}
          </RBNavbar.Collapse>
          <RBNavbar.Toggle aria-controls="basic-navbar-nav-toggle" />
        </>
      )) || <div className="d-flex justify-content-between flex-grow-1 studropdown">{content}</div>}
    </RBNavbar>
  );
};

export default Navbar;
