import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { updateBreadCrumbs } from "../../Redux/Action";
import ViewEducatorProfile from "./ViewEducatorProfile";
import { EducatorProfileProps } from "./EducatorProfile.props";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}

function EducatorProfile(props: EducatorProfileProps): JSX.Element {
  const { dispatch } = props;
  const [tabKey, setTabKey] = useState("settings");
  useEffect(() => {
    const fetchData = () => {
      const breadcrumb = { crumbs: [{ path: "/users", name: "Users" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("page", "settings");
  }, []);

  const tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <ViewEducatorProfile />
    }
  ];
  return (
    <>
      <div>
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(EducatorProfile);
