import React from "react";
import Student from "../Students/students";

interface Props {
  isfrom: string;
  isteacher: boolean;
}
export default function Students(props: Props) {
  const { isfrom, isteacher } = props;
  return (
    <>
      <Student isfrom={isfrom} isteacher={isteacher} />
    </>
  );
}
