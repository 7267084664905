import React from "react";

import AvatarProps from "./avatar.props";
import AvatarEnum from "../enums/avatar.enum";
import AvatarStyleEnum from "../enums/avatar-style.enum";
import "./avatar.scss";

import classMap from "..//utilities/classMap";
import getEnumValue from "..//utilities/getEnumValue";
import config from "../../config";

import AvatarPriorityEnum from "../enums/avatar-priority.enum";
import EnhancedIcon from "../enhanced-icon";
import IconSizeEnum from "../enums/icon-size.enum";
import PositionEnum from "../enums/position.enum";

const Avatar = (props: AvatarProps) => {
  const base: string = config.defaults.basePaths.avatars;
  const avatarStyle: AvatarStyleEnum = props.avatarStyle ?? AvatarStyleEnum.BODY;
  const headOnly: boolean = avatarStyle === AvatarStyleEnum.HEAD;
  const avatarType: string = props.avatarType ?? AvatarPriorityEnum.DEFAULT;
  const avatarSplit: string[] = props.avatar.split(":");
  const fileType: string = avatarSplit[0];
  const avatar: string = avatarSplit[1].toLowerCase();

  return (
    <div
      className={classMap("edge-ux-avatar", "overflow-hidden d-inline-block", props.className)}
      style={{
        transform: `rotate(${props.rotation}deg)`,
        height: headOnly ? getEnumValue(IconSizeEnum, props.size) / 2 : undefined
      }}
    >
      <EnhancedIcon
        {...{
          ...props,
          icon: base + avatar + "/" + avatarType + "." + fileType,
          title: props.showTitle ? AvatarEnum[props.avatar.toUpperCase()] : undefined,
          textPosition: PositionEnum.BOTTOM,
          squared: true
        }}
      />
    </div>
  );
};

export default Avatar;
