import React, { ReactNode } from "react";
import SideBar from "./components/SideBar/SideBar";
import Breadcrumbs from "./components/BreadCrumbs/BreadCrumbs";
import HeaderNavbar from "./components/HeaderBar/HeaderBar";
import SessionManager from "./components/SessionManager/sessionManager";
import { BehaviorSubject } from "rxjs";

interface Props {
  tokenSubject: BehaviorSubject<string>;
  children: ReactNode;
}

function BoundRoute(props: Readonly<Props>) {
  return (
    <>
      <SessionManager tokenSubject={props.tokenSubject} />
      <HeaderNavbar />
      <SideBar />

      <div className="body-content mt-5 pt-3 h-100" role="main">
        <Breadcrumbs />
        {props.children}
      </div>
    </>
  );
}

export default BoundRoute;
