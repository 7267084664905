import React, { useEffect } from "react";
import { StudentId } from "./StudentHomePage.props";
import { redirectToPss } from "../../../helpers/sessionHelper";
import { goldEyedImg } from "@wne-spa/ux-component-library";
import usePageTitle from "../hooks/usePageTitle";
import useBreakpoint from "../hooks/useBreakpoint";

const eMessageBoxClass = (breakpoint: string) => {
  if (breakpoint === "md") {
    return "emessagebox emessageboxmd";
  } else if (breakpoint === "sm") {
    return "emessagebox emessageboxsm";
  } else {
    return "emessagebox";
  }
};

const goldeyeExceptionRoboClass = (breakpoint: string) => {
  if (breakpoint === "md") {
    return "goldeyeexceptionrobo goldeyeexceptionrobomd";
  } else if (breakpoint === "sm") {
    return "goldeyeexceptionrobo goldeyeexceptionrobosm";
  } else {
    return "goldeyeexceptionrobo";
  }
};

interface YouHaveNoWorkMessageProps {
  waitingForNoContentMessage: boolean;
  studentId: StudentId;
}

export function YouHaveNoWorkMessage(props: YouHaveNoWorkMessageProps) {
  const { waitingForNoContentMessage, studentId } = props;

  const { updateTitle } = usePageTitle();
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (!waitingForNoContentMessage) {
      updateTitle("You don’t have any work yet. Please ask your teacher | Imagine MyPath ");
    }
  }, [waitingForNoContentMessage]);

  if (waitingForNoContentMessage) {
    return null;
  }

  return (
    <div className="estudentmessageoutside">
      <div className="estudentmessageinside">
        <div className={eMessageBoxClass(breakpoint)} aria-live="assertive" aria-label="abvcd">
          You do not have any <br></br>
          work yet. Please ask <br></br>
          &nbsp;&nbsp; &nbsp;&nbsp;your teacher.
          <div
            id="backk2"
            className="backButtonTrans tabfocustransition"
            style={{
              fontSize: "22px",
              fontWeight: 500
            }}
            onClick={() => redirectToPss(studentId.id, studentId.role)}
            tabIndex={0}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                redirectToPss(studentId.id, studentId.role);
              }
            }}
            role="button"
          >
            <div aria-hidden="true">
              <i className="fa fa-arrow-left goArrow backArrow"> </i>
            </div>
            Back
          </div>
        </div>
        <div className={goldeyeExceptionRoboClass(breakpoint)}>
          <img src={goldEyedImg} alt="Joe says" />
        </div>
      </div>
    </div>
  );
}

export default YouHaveNoWorkMessage;
