import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import StudentList from "../UserLists/StudentsList";
import EducatorList from "../UserLists/EducatorList";
import WNEUserList from "../UserLists/WNECreateUser";
import { UxTabs } from "@wne-spa/ux-component-library";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getTabName: state.getTabName
  };
}
type UserProps = {
  dispatch: AppDispatch;
};

interface Props extends UserProps, ReduxProps {}

function Users(props: Props) {
  const [tabKey, setTabKey] = useState("students");
  const { dispatch, getUserRoleId, getTabName } = props;

  useEffect(() => {
    setTabKey(getTabName ? getTabName : tabKey);
    localStorage.setItem("page", "students");
  }, []);

  const WNEUser = [
    {
      eventKey: "students",
      title: "Students",
      content: tabKey === "students" ? <StudentList /> : null
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: tabKey === "educators" ? <EducatorList /> : null
    }
  ];
  const WNEAdmin = [
    {
      eventKey: "students",
      title: "Students",
      content: tabKey === "students" ? <StudentList /> : null
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: tabKey === "educators" ? <EducatorList /> : null
    },
    {
      eventKey: "wneusers",
      title: "WNE Users",
      content: tabKey === "wneusers" ? <WNEUserList /> : null
    }
  ];

  const WNEHelpDesk = [
    {
      eventKey: "students",
      title: "Students",
      content: tabKey === "students" ? <StudentList /> : null
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: tabKey === "educators" ? <EducatorList /> : null
    },
    {
      eventKey: "wneusers",
      title: "WNE Users",
      content: tabKey === "wneusers" ? <WNEUserList /> : null
    }
  ];

  useEffect(() => {
    dispatch(updatePageTitle("- Users"));
    const fetchData = () => {
      const breadcrumb = { crumbs: [{ path: "/users", name: "Users" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    dispatch(updateActivityIndicator(false));
    fetchData();
  }, [dispatch]);

  function renderTabs() {
    if (getUserRoleId === 1) {
      return (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={WNEAdmin}
          className="pt-2"
          currentKey={tabKey}
        />
      );
    } else if (getUserRoleId === 7) {
      return (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={WNEHelpDesk}
          className="pt-2"
          currentKey={tabKey}
        />
      );
    } else {
      return (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={WNEUser}
          className="pt-2"
          currentKey={tabKey}
        />
      );
    }
  }
  return (
    <>
      <div className="class-nav-tabs">{renderTabs()}</div>
    </>
  );
}

export default connect(mapStateToProps)(Users);
