import { BreadcrumbsData, GenericBreadCrumbsData } from "../components/AssignmentBuilder/AssignmentBuilder.props";

/**
 * Updates breadcrumbs based on provided data.
 * @param data An object containing a `crumbs` array of objects representing breadcrumbs.
 * @returns An object with an array of updated breadcrumbs (`crumbs`), where each breadcrumb
 * has a `path` and `name` property.
 * The functions transform the input object into an object with an array of breadcrumbs with a `path` and `name` property.
 */

export default function getStructuredBreadCrumbsData(data: GenericBreadCrumbsData): BreadcrumbsData {
  if (data.crumbs.length > 0) {
    const pathValue = "cumulativestandardsproficiency";
    const crumbsData = data.crumbs.map((crumb) => ({
      path: `/${pathValue}/` + crumb.level + `/${crumb.id}`,
      name: crumb.name,
    }));

    return {
      crumbs: crumbsData,
    }
  }

  return {
    crumbs: []
  }
}