import React, { useState, useEffect } from "react";
import moment from "moment";

import "./calender.scss";
interface CalenderProps {
  calender: {
    from: {
      date: number | string;
      month: number | string;
      year: number | string;
    };
    to: {
      date: number | string;
      month: number | string;
      year: number | string;
    };
  };
  callback: (args: CallBackProps) => void;
  dateTime?: Date;
  disableCurrentDate?: boolean;
  type?: string;
}

interface CallBackProps {
  target: {
    value: {
      apply: boolean;
      from: null | string;
      to: null | string;
    };
  };
}

function Calender(props: CalenderProps) {
  const { callback, calender, disableCurrentDate, type, dateTime } = props;
  const days = moment.weekdaysShort();
  const [startFrom, setStartFrom] = useState([]);
  const [totalDays, setTotalDays] = useState([]);
  const [sundays, setSundays] = useState([]);
  const [saturdays, setSaturdays] = useState([]);
  const [isFrom, setIsFrom] = useState(true);
  const [selectDateFrom, setSelectDateFrom] = useState(Number(calender.from.date));
  const [selectMonthFrom, setSelectMonthFrom] = useState(Number(calender.from.month));
  const [selectYearFrom, setSelectYearFrom] = useState(Number(calender.from.year));
  const [selectDateTo, setSelectDateTo] = useState(Number(calender.to.date));
  const [selectMonthTo, setSelectMonthTo] = useState(Number(calender.to.month));
  const [selectYearTo, setSelectYearTo] = useState(Number(calender.to.year));

  const getCurrentDate = (data) => {
    if (disableCurrentDate) {
      return data + 2;
    } else {
      return data + 1;
    }
  };

  function getSundays() {
    const days = new Date(
      isFrom ? selectYearFrom : selectYearTo,
      isFrom ? selectMonthFrom : selectMonthTo,
      0
    ).getDate();
    const sundays = [
      8 -
        new Date(`${isFrom ? selectMonthFrom : selectMonthTo}/01/${isFrom ? selectYearFrom : selectYearTo}
      `).getDay()
    ];
    for (let i = sundays[0] + 7; i < days; i += 7) {
      sundays.push(i);
    }
    setSundays(sundays);
  }

  function getSaturdays() {
    const days = new Date(
      isFrom ? selectYearFrom : selectYearTo,
      isFrom ? selectMonthFrom : selectMonthTo,
      0
    ).getDate();
    const saturdays = [
      7 - new Date(`${isFrom ? selectMonthFrom : selectMonthTo}/01/${isFrom ? selectYearFrom : selectYearTo}`).getDay()
    ];
    for (let i = saturdays[0] + 7; i < days; i += 7) {
      saturdays.push(i);
    }
    setSaturdays(saturdays);
  }

  useEffect(() => {
    //@ts-ignore
    setStartFrom(
      //@ts-ignore
      moment(`${isFrom ? selectYearFrom : selectYearTo}${isFrom ? selectMonthFrom : selectMonthTo}`, "YYYYM")
        .startOf("month")
        .format("d")
    );
    //@ts-ignore
    setTotalDays(
      //@ts-ignore
      new Date(isFrom ? selectYearFrom : selectYearTo, isFrom ? selectMonthFrom : selectMonthTo, 0).getDate()
    );
    getSundays();
    getSaturdays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFrom, selectMonthFrom, selectMonthTo, selectYearFrom, selectYearTo, calender]);

  const updateFromDate = () => {
    setSelectMonthFrom(12);
    setSelectYearFrom(selectYearFrom - 1);
  };

  const updateToDate = () => {
    setSelectMonthTo(12);
    setSelectYearTo(selectYearTo - 1);
  };

  const updateFromToDate = () => {
    setSelectMonthFrom(1);
    setSelectYearFrom(selectYearFrom + 1);
  };

  const updateToToDate = () => {
    setSelectMonthTo(1);
    setSelectYearTo(selectYearTo + 1);
  };

  const chevronLeftCalender = () => {
    if (selectMonthTo === 1 && selectMonthFrom !== selectMonthTo) {
      updateToDate();
    } else if (selectYearTo === selectYearFrom && selectMonthTo === selectMonthFrom) {
      return null;
    } else if (selectYearTo === new Date().getFullYear() && selectMonthTo > selectMonthFrom) {
      setSelectMonthTo(selectMonthTo - 1);
    } else if ((selectYearTo === new Date().getFullYear() && selectMonthTo <= selectMonthFrom) || disableCurrentDate) {
      setSelectMonthTo(selectMonthTo - 1);
    } else if (selectYearTo === new Date().getFullYear() - 1 && selectMonthTo >= selectMonthFrom) {
      setSelectMonthTo(selectMonthTo - 1);
    }
  };

  const onChevronLeft = () => {
    if (isFrom) {
      if (selectMonthFrom === 1) {
        updateFromDate();
      } else if (selectYearFrom === new Date().getFullYear() && selectMonthFrom <= new Date().getMonth() + 1) {
        setSelectMonthFrom(selectMonthFrom - 1);
      } else if (
        (selectYearFrom === new Date().getFullYear() - 1 &&
          selectMonthFrom >= new Date().getMonth() + 1 &&
          selectMonthFrom !== new Date().getMonth() + 1) ||
        disableCurrentDate
      ) {
        setSelectMonthFrom(selectMonthFrom - 1);
      } else {
        return null;
      }
    } else {
      chevronLeftCalender();
    }
  };

  const chevronRightCalender = () => {
    if (selectMonthTo === 12) {
      updateToToDate();
    } else if (selectMonthTo < new Date().getMonth() + 1) {
      setSelectMonthTo(selectMonthTo + 1);
    } else if (selectYearTo < new Date().getFullYear()) {
      setSelectMonthTo(selectMonthTo + 1);
    } else if (disableCurrentDate) {
      setSelectMonthTo(selectMonthTo + 1);
    } else {
      return null;
    }
  };

  const onChevronRight = () => {
    if (isFrom) {
      if (selectMonthFrom === 12) {
        updateFromToDate();
      } else if (selectYearFrom === selectYearTo && selectMonthFrom < selectMonthTo) {
        setSelectMonthFrom(selectMonthFrom + 1);
      } else if (selectYearFrom < selectYearTo && selectMonthFrom > selectMonthTo) {
        setSelectMonthFrom(selectMonthFrom + 1);
      } else if (selectYearFrom < selectYearTo && selectMonthFrom >= selectMonthTo) {
        setSelectMonthFrom(selectMonthFrom + 1);
      } else if (disableCurrentDate) {
        setSelectMonthFrom(selectMonthFrom + 1);
      } else {
        return null;
      }
    } else {
      chevronRightCalender();
    }
  };

  const _SubOptions = [
    { value: "select", label: "Select" },
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "7Days", label: "Last 7 Days" },
    { value: "28Days", label: "Last 28 Days" },
    { value: "90Days", label: "Last 90 Days" },
    { value: "custom", label: "Custom" }
  ];

  const selectMonthFromCalender = () => {
    switch (selectMonthFrom) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "";
    }
  };
  const selectMonthFromCalenderForAcademic = () => {
    if (selectMonthFrom === new Date(dateTime).getMonth() + 1) {
      return "display-blur";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="calender-holder">
        <div className="from-to-holder">
          {type != "acadimic" && (
            <div className="flex-center">
              <div
                className={`from-date-picker ${isFrom ? "picker-current" : "picker"}`}
                onClick={() => {
                  setIsFrom(!isFrom);
                }}
                data-testid="from-date-picker"
              >
                {`${new Date(`${selectMonthFrom}/${selectDateFrom}/${selectYearFrom}`).toLocaleString("default", {
                  month: "short"
                })}
                ${selectDateFrom},${" "}
                ${selectYearFrom}
              `}
              </div>
              <div className="picker-divider">-</div>
              <div
                className={`from-date-picker ${!isFrom ? "picker-current" : "picker"}`}
                onClick={() => {
                  setIsFrom(!isFrom);
                }}
                data-testid="to-date-picker"
              >
                {`${new Date(`${selectMonthTo}/${selectDateTo}/${selectYearTo}`).toLocaleString("default", {
                  month: "short"
                })}
                ${selectDateTo},${" "}
                ${selectYearTo}
              `}
              </div>
            </div>
          )}
          <div className="flex-center chevron-holder">
            <i
              className={`fa fa-chevron-left c-iconleft
                ${
                  type === "acadimic"
                    ? selectMonthFromCalenderForAcademic()
                    : isFrom
                    ? selectYearFrom === new Date().getFullYear() - 1 &&
                      selectMonthFrom === new Date().getMonth() + 1 &&
                      !disableCurrentDate &&
                      "display-blur"
                    : (selectYearTo === new Date().getFullYear() - 1 &&
                        selectMonthTo === new Date().getMonth() + 1 &&
                        !disableCurrentDate) ||
                      (selectMonthFrom >= selectMonthTo && selectYearFrom >= selectYearTo && !disableCurrentDate)
                    ? "display-blur"
                    : ""
                }
                `}
              data-testid="calender-icon"
              onClick={() => {
                if (type === "acadimic") {
                  setSelectDateFrom(new Date(dateTime).getDate() + 1);
                } else {
                  if (isFrom === true && !disableCurrentDate) {
                    if (
                      selectYearFrom === new Date().getFullYear() - 1 &&
                      selectMonthFrom !== new Date().getMonth() + 1
                    ) {
                      if (selectMonthFrom === new Date().getMonth() + 2) {
                        setSelectDateFrom(new Date().getDate());
                      } else {
                        setSelectDateFrom(1);
                      }
                    } else if (
                      selectYearFrom === new Date().getFullYear() &&
                      selectMonthFrom !== new Date().getMonth() + 1
                    ) {
                      setSelectDateFrom(1);
                    } else {
                      if (
                        selectYearFrom === new Date().getFullYear() - 1 &&
                        selectMonthFrom !== new Date().getMonth() + 1
                      ) {
                        setSelectDateFrom(1);
                      }
                      setSelectDateFrom(1);
                    }
                  } else {
                    if (selectYearTo !== new Date().getFullYear() - 1 && selectMonthTo !== new Date().getMonth() + 1) {
                      setSelectDateTo(1);
                    } else {
                      if (disableCurrentDate) {
                        setSelectDateTo(1);
                      }
                    }
                  }
                }
                onChevronLeft();
              }}
            />
            {type === "acadimic" && (
              <div style={{ width: "230px", textAlign: "center" }} data-testid="calender-acadimic">
                {selectMonthFromCalender()}
              </div>
            )}
            <i
              className={`fa fa-chevron-right c-iconright
                  ${
                    type === "acadimic"
                      ? ""
                      : isFrom
                      ? selectYearTo === selectYearFrom && selectMonthFrom >= selectMonthTo
                        ? "display-blur"
                        : selectMonthFrom === new Date().getMonth() + 1 && selectYearFrom === new Date().getFullYear()
                        ? "display-blur"
                        : ""
                      : selectMonthTo === new Date().getMonth() + 1 && selectYearTo === new Date().getFullYear()
                      ? "display-blur"
                      : ""
                  }
                `}
              data-testid="calender-chevron-right"
              onClick={() => {
                if (isFrom === true) {
                  if (selectMonthFrom !== new Date().getMonth() + 1 || selectMonthFrom === new Date().getMonth() + 1) {
                    setSelectDateFrom(1);
                  }
                } else {
                  if (selectMonthTo !== new Date().getMonth() + 1) setSelectDateTo(1);
                }
                onChevronRight();
              }}
            />
          </div>
        </div>
        {type != "acadimic" && (
          <div className="flex-center flex-header padding-10">
            {days.map((day) => {
              return (
                <div className="day" key={day}>
                  {day}
                </div>
              );
            })}
          </div>
        )}
        <div className="flex-header padding-10">
          {
            //@ts-ignore
            [...Array(Number(startFrom)).keys()].map((data) => {
              return <div className="date" key={data} />;
            })
          }
          {
            //@ts-ignore
            [...Array(Number(totalDays)).keys()].map((data, index) => {
              return (
                <div className="date" key={data}>
                  <div
                    className={`date-inner
                        ${sundays.includes(data + 1) || saturdays.includes(data + 1) ? "date-corner" : ""} 
                        ${
                          isFrom && selectDateFrom === data + 1
                            ? "date-current"
                            : !isFrom && selectDateTo === data + 1
                            ? "date-current"
                            : ""
                        }
                        ${
                          type === "acadimic"
                            ? getCurrentDate(data) <= new Date(dateTime).getDate() + 1 &&
                              selectMonthFrom === new Date(dateTime).getMonth() + 1 &&
                              selectYearFrom === new Date(dateTime).getFullYear()
                              ? "display-blur"
                              : ""
                            : isFrom
                            ? getCurrentDate(data) > new Date().getDate() &&
                              selectMonthFrom === new Date().getMonth() + 1 &&
                              selectYearFrom === new Date().getFullYear()
                              ? "display-blur"
                              : getCurrentDate(data) < new Date().getDate() &&
                                selectMonthFrom === new Date().getMonth() + 1 &&
                                selectYearFrom === new Date().getFullYear() - 1 &&
                                !disableCurrentDate
                              ? "display-blur"
                              : ""
                            : getCurrentDate(data) < new Date().getDate() &&
                              selectMonthTo === new Date().getMonth() + 1 &&
                              selectYearTo !== new Date().getFullYear() &&
                              !disableCurrentDate
                            ? "display-blur"
                            : getCurrentDate(data) > new Date().getDate() &&
                              selectMonthTo === new Date().getMonth() + 1 &&
                              selectYearTo === new Date().getFullYear()
                            ? "display-blur"
                            : ""
                        } `}
                    data-testid={`calender-date-numbers-${index}`}
                    onClick={() => {
                      if (isFrom) {
                        if (
                          selectMonthFrom === selectMonthTo &&
                          selectYearFrom === selectYearTo &&
                          data + 1 <= new Date().getDate()
                        ) {
                          setSelectDateFrom(data + 1);
                          setSelectDateTo(data + 1);
                        } else if (data + 1 > new Date().getDate() && selectMonthFrom === new Date().getMonth() + 1) {
                          null;
                        } else {
                          selectMonthFrom === new Date().getMonth() + 1 &&
                            selectYearFrom !== new Date().getFullYear() &&
                            data + 1 >= new Date().getDate() &&
                            setSelectDateFrom(data + 1);
                        }
                      } else {
                        null;
                      }
                      if (!isFrom && data + 1 <= new Date().getDate()) {
                        if (selectMonthFrom === selectMonthTo && selectYearFrom === selectYearTo) {
                          data + 1 >= selectDateFrom && setSelectDateTo(data + 1);
                        } else {
                          setSelectDateTo(data + 1);
                        }
                      } else {
                        selectMonthTo === new Date().getMonth() + 1 &&
                          selectYearTo !== new Date().getFullYear() &&
                          data + 1 >= selectDateFrom &&
                          setSelectDateTo(data + 1);
                      }
                      if (!isFrom && selectMonthTo !== new Date().getMonth() + 1 && data + 1 >= selectDateFrom) {
                        setSelectDateTo(data + 1);
                      }
                      if (
                        isFrom &&
                        selectMonthFrom === new Date().getMonth() + 1 &&
                        selectYearFrom !== new Date().getFullYear() &&
                        data + 1 >= new Date().getDate()
                      ) {
                        setSelectDateFrom(data + 1);
                      } else {
                        isFrom && setSelectDateFrom(data + 1);
                      }
                    }}
                  >
                    {data + 1}
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="border-bottom-calender" />
        <div className="controller">
          <div
            className="controller-cancel"
            data-testid="calender-control-cancel"
            onClick={() => {
              callback({
                target: {
                  value: {
                    apply: false,
                    from: null,
                    to: null
                  }
                }
              });
            }}
          >
            {type === "acadimic" ? "CANCEL" : "Cancel"}
          </div>
          <div
            className="controller-apply"
            data-testid="calender-control-apply"
            onClick={() => {
              callback({
                target: {
                  value: {
                    apply: true,
                    from: `${
                      selectMonthFrom < 10 ? `0${selectMonthFrom}` : `${selectMonthFrom}`
                    }-${selectDateFrom}-${selectYearFrom}`,
                    to: `${
                      selectMonthTo < 10 ? `0${selectMonthTo}` : `${selectMonthTo}`
                    }-${selectDateTo}-${selectYearTo}`
                  }
                }
              });
            }}
          >
            {type === "acadimic" ? "OK" : "Apply"}
          </div>
        </div>
      </div>
    </>
  );
}

export default Calender;
