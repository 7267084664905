/* eslint-disable array-callback-return */
import React, { useState } from "react";
import "./log-filter-slider.scss";
import TimesIcon from "../../assets/icons/icon_times.svg";
import ResetIcon from "../../assets/icons/icon_reset.svg";
import NavDropdown from "react-bootstrap/NavDropdown";
import Calender from "../calender";
import { UxIcon, UxIconEnum, UxSizeEnum } from "../../wne-spa-ux-component-library";

interface ILogFilterSlider {
  opacity: number;
  backgroundVisibility: boolean;
  left: string;
  sliderHeader: string;
  activeFilters: string[];
  setActiveFilters: { current: string[] };
  resetText: string;
  onResetClick: () => void;
  activeFilterText: string;
  dropHeader: string[];
  dropTypesList: object;
  dropTypes: object;
  filterStates: FilterStateProps;
  setFilterState: { current: object };
  setSliderLeft: (args: string) => void;
  setBackgroundVisibility: (args: boolean) => void;
  setOpacity: (args: number) => void;
  onChecked: () => void;
  textMessage: {
    current: string;
  };
  setMultiFilterDropDown: (args: boolean) => void;
  screen: string;
  setStateName: (args) => void;
  stateName: {
    "Created Date": string[];
    "Import Type": string[];
    Status: string[];
  };
  stateCallback?: (args) => void;
  calender: {
    from: {
      date: number | string;
      month: number | string;
      year: number | string;
    };
    to: {
      date: number | string;
      month: number | string;
      year: number | string;
    };
  };
}
type FilterStateProps = {
  "": boolean;
  Classes: boolean;
  Completed: boolean;
  "Completed w/ Errors": boolean;
  Custom: boolean;
  Educators: boolean;
  "Failed-Retrying": boolean;
  "Import Disabled": boolean;
  Last7Days: boolean;
  Last28Days: boolean;
  Last90Days: boolean;
  "Last 7 Days": boolean;
  "Last 28 Days": boolean;
  "Last 90 Days": boolean;
  New: boolean;
  Processing: boolean;
  Students: boolean;
  "System Error": boolean;
  Terminated: boolean;
  Today: boolean;
  "Waiting-User Ingestion": boolean;
  Yesterday: boolean;
};

type OnItemSelectDataProps = {
  from: string;
  to: string;
  isFromCustom: boolean;
};

export default function UxLogFilterSlider(props: ILogFilterSlider) {
  const {
    opacity,
    backgroundVisibility,
    left,
    sliderHeader,
    activeFilters,
    setActiveFilters,
    resetText,
    onResetClick,
    activeFilterText,
    filterStates,
    setFilterState,
    setSliderLeft,
    setBackgroundVisibility,
    setOpacity,
    onChecked,
    textMessage,
    setMultiFilterDropDown,
    screen,
    setStateName,
    stateName,
    stateCallback,
    dropHeader,
    dropTypesList,
    dropTypes,
    calender
  } = props;

  const [backgroundOpaque, setBackgroundOpaque] = useState(false);
  const [custom, setCustom] = useState(false);

  function sliderleft() {
    if (left !== "-330px") {
      setSliderLeft("-330px");
      setTimeout(() => {
        setBackgroundVisibility(false);
      }, 800);
      setBackgroundOpaque(false);
      setOpacity(0);
      textMessage.current = "UxLogFilterSlider";
      setMultiFilterDropDown(false);
      setCustom(false);
    } else {
      setBackgroundVisibility(true);
      setBackgroundOpaque(true);
      setTimeout(() => {
        setOpacity(1);
      }, 10);
      setSliderLeft("90px");
    }
  }

  function sliceFilter(data: string) {
    const ftrs = activeFilters.filter(function (item: string) {
      return item !== data;
    });

    //@ts-ignore
    const Dropnames = stateName[dropTypes[data]].filter(function (item: string) {
      return item !== data;
    });

    //@ts-ignore
    setActiveFilters.current = ftrs;
    //@ts-ignore
    setStateName({ ...stateName, [dropTypes[data]]: Dropnames });
  }

  function onItemSelect(row: string, data: string | OnItemSelectDataProps) {
    if (row === "Students" || row === "Educators" || row === "Classes") {
      if (activeFilters.includes(row)) {
        setActiveFilters.current = [...activeFilters];
        setStateName({ ...stateName });
      } else {
        if (row === "Students") {
          if (filterStates.Students === false) {
            setFilterState.current = {
              ...filterStates,
              Students: true,
              Classes: false,
              Educators: false
            };
            // @ts-ignore
            if (activeFilters.includes("Educators")) {
              activeFilters[activeFilters.indexOf("Educators")] = "Students";
            } else if (activeFilters.includes("Classes")) {
              activeFilters[activeFilters.indexOf("Classes")] = "Students";
            } else {
              setActiveFilters.current = [...activeFilters, "Students"];
            }
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Students"] });
          } else {
            setFilterState.current = {
              ...filterStates
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Classes") {
          if (filterStates.Classes === false) {
            setFilterState.current = {
              ...filterStates,
              Students: false,
              Educators: false,
              Classes: true
            };
            // @ts-ignore
            if (activeFilters.includes("Educators")) {
              activeFilters[activeFilters.indexOf("Educators")] = "Classes";
            } else if (activeFilters.includes("Students")) {
              activeFilters[activeFilters.indexOf("Students")] = "Classes";
            } else {
              setActiveFilters.current = [...activeFilters, "Classes"];
            }
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Students"] });
          } else {
            setFilterState.current = {
              ...filterStates
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else {
          if (filterStates.Educators === false) {
            setFilterState.current = {
              ...filterStates,
              Students: false,
              Classes: false,
              Educators: true
            };
            // @ts-ignore
            if (activeFilters.includes("Students")) {
              activeFilters[activeFilters.indexOf("Students")] = "Educators";
            } else if (activeFilters.includes("Classes")) {
              activeFilters[activeFilters.indexOf("Classes")] = "Educators";
            } else {
              setActiveFilters.current = [...activeFilters, "Educators"];
            }
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Educators"] });
          } else {
            setFilterState.current = {
              ...filterStates
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        }
        onChecked();
        stateCallback && stateCallback([row, !filterStates[row]]);
        setCustom(false);
      }
    }

    if (
      row === "Today" ||
      row === "Yesterday" ||
      row === "Last 7 Days" ||
      row === "Last 28 Days" ||
      row === "Last 90 Days" ||
      row === "Custom" ||
      row === "Calendar"
    ) {
      let dateRows = ["Today", "Yesterday", "Last 7 Days", "Last 28 Days", "Last 90 Days", "Custom"];

      if (row === "Custom") {
        setCustom(true);
      }

      if (activeFilters.includes(row)) {
        setActiveFilters.current = [...activeFilters];
        setStateName({ ...stateName });
      } else {
        dateRows.forEach((dateRow) => {
          if (activeFilters.includes(dateRow)) {
            activeFilters.splice(activeFilters.indexOf(dateRow), 1);
          }
        });
        if (row === "Today") {
          if (filterStates.Today === false) {
            setFilterState.current = {
              ...filterStates,
              Today: true,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            setActiveFilters.current = [...activeFilters, "Today"];

            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Today"] });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Yesterday") {
          if (filterStates.Yesterday === false) {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: true,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };

            setActiveFilters.current = [...activeFilters, "Yesterday"];

            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Yesterday"] });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Last 7 Days") {
          if (filterStates.Last7Days === false) {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": true,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            setActiveFilters.current = [...activeFilters, "Last 7 Days"];
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({
                  ...stateName,
                  [dropTypes[row]]: ["Last 7 Days"]
                });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Last 28 Days") {
          if (filterStates.Last28Days === false) {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": true,
              "Last 90 Days": false,
              Custom: false
            };

            setActiveFilters.current = [...activeFilters, "Last 28 Days"];

            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({
                  ...stateName,
                  [dropTypes[row]]: ["Last 28 Days"]
                });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Last 90 Days") {
          if (filterStates.Last90Days === false) {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": true,
              Custom: false
            };

            setActiveFilters.current = [...activeFilters, "Last 90 Days"];

            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({
                  ...stateName,
                  [dropTypes[row]]: ["Last 90 Days"]
                });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        } else if (row === "Calendar") {
          if (filterStates.Custom === false || (data as OnItemSelectDataProps).isFromCustom === true) {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: true
            };
            setActiveFilters.current = [...activeFilters, "Custom"];

            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: ["Custom"] });
          } else {
            setFilterState.current = {
              ...filterStates,
              Today: false,
              Yesterday: false,
              "Last 7 Days": false,
              "Last 28 Days": false,
              "Last 90 Days": false,
              Custom: false
            };
            //@ts-ignore
            stateName[dropTypes[row]] === undefined
              ? //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [row] })
              : //@ts-ignore
                setStateName({ ...stateName, [dropTypes[row]]: [] });
          }
        }
        if (row !== "Custom") {
          onChecked();
          stateCallback && stateCallback([row, !filterStates[row], data]);
          setCustom(false);
        }
      }
    }
  }

  function onUncheck(row: string, data: string) {
    if (filterStates[row] === false) {
      setActiveFilters.current = [...activeFilters, row];
      //@ts-ignore
      stateName[dropTypes[row]] === undefined
        ? //@ts-ignore
          setStateName({ ...stateName, [dropTypes[row]]: [row] })
        : //@ts-ignore
          setStateName({
            ...stateName,
            [dropTypes[row]]: [...stateName[dropTypes[row]], row]
          });
      setFilterState.current = { ...filterStates, [row]: true };
    } else {
      sliceFilter(row);
      setFilterState.current = { ...filterStates, [row]: false };
    }
    onChecked();
    stateCallback && stateCallback([row, !filterStates[row]]);
    if (row !== "Custom") {
      setCustom(false);
    }
  }

  function displayTypes(getData: string) {
    if (document.getElementsByClassName(getData)[0].getAttribute("style")?.includes("block")) {
      document.getElementsByClassName(getData)[0].setAttribute("style", "display: none");
      document.getElementsByClassName(`${getData}caret`)[0].setAttribute("class", `fa fa-caret-up ${getData}caret`);
    } else {
      document.getElementsByClassName(getData)[0].setAttribute("style", "display: block");
      document.getElementsByClassName(`${getData}caret`)[0].setAttribute("class", `fa fa-caret-down ${getData}caret`);
    }
  }

  function clearTypes(obj: string) {
    setStateName({ ...stateName, [obj]: [] });
    let newState = {};
    for (const object in filterStates) {
      //@ts-ignore
      if (dropTypes[object] !== obj) newState = { ...newState, [object]: filterStates[object] };
      else newState = { ...newState, [object]: false };
    }
    const ftrs = activeFilters.filter(function (item: string) {
      //@ts-ignore
      return dropTypes[item] !== obj;
    });
    setActiveFilters.current = ftrs;
    setFilterState.current = newState;
    onChecked();
    stateCallback && stateCallback([obj, false]);
    setCustom(false);
  }

  function renderActiveFilters() {
    const elements = [];
    for (const object in stateName) {
      if (stateName[object].length !== 0) {
        elements.push(
          <div className="activeFilters">
            {stateName[object].length > 1 ? (
              <label className="allActiveFilterText">
                {`${object}: `}
                <label className="Count">{`${stateName[object].length}`}</label>
              </label>
            ) : (
              <label className="allActiveFilterText">{`${object}: ${
                stateName[object][0].includes("-conf-") ? stateName[object][0].split("-")[2] : stateName[object][0]
              }`}</label>
            )}
            <div
              onClick={() => {
                clearTypes(object);
              }}
              data-testid="clear-types-image"
            >
              <img src={TimesIcon} alt="timesicon" width="16px" height="16px" className="filterRemove cursorImg" />
            </div>
          </div>
        );
      }
    }
    return elements;
  }
  interface CallBackProps {
    target: {
      value: {
        apply: boolean;
        from: null | string;
        to: null | string;
      };
    };
  }

  function callback(event: CallBackProps) {
    setCustom(false);
    if (event.target.value.apply === true) {
      onItemSelect("Calendar", {
        from: event.target.value.from,
        to: event.target.value.to,
        isFromCustom: true
      });
    }
  }

  return (
    <>
      <div className="filterSliderOpaque" style={{ display: backgroundOpaque ? "block" : "none" }} />
      <div
        className="filterSliderBackground"
        style={{
          display: backgroundVisibility ? "flex" : "none",
          opacity: opacity,
          cursor: "pointer"
        }}
        onClick={() => {
          sliderleft();
        }}
        data-testid="filter-slider-background"
      />
      <div className="filterSliderLog" style={{ left: left }} data-testid="logfilterslider-wrapper">
        <div className="fliterTextCloseFLex">
          <label className="filterSliderText">{sliderHeader}</label>
          <div
            onClick={() => {
              sliderleft();
            }}
            data-testid="filter-slider-image"
          >
            <img src={TimesIcon} alt="timesicon" width="25px" height="25px" className="cursorImg" />
          </div>
        </div>
        {activeFilters.length !== 0 ? (
          <div
            onClick={() => {
              onResetClick();
            }}
            className="resetHolder"
            data-testid="reset-holder"
          >
            <img src={ResetIcon} alt="reseticon" width="11px" height="11px" />
            <label style={{ cursor: "pointer" }} className="resetText">
              {resetText}
            </label>
          </div>
        ) : null}
        <hr />
        <div className="activeFilterDiv">
          <div
            className="activeFilterDivText"
            onClick={() => {
              displayTypes(`${activeFilterText}${screen}`);
            }}
            data-testid="active-filter-text"
          >
            <label style={{ cursor: "pointer" }}>{activeFilterText}</label>
            <i style={{ cursor: "pointer" }} className={`fa fa-caret-down ${activeFilterText}${screen}caret`}></i>
          </div>
          <div className={`${activeFilterText}${screen}`} style={{ display: "block" }}>
            {activeFilters.length === 0 ? (
              <div className="activeFilters">
                <label className="allActiveFilterText">No Active Filters</label>
              </div>
            ) : (
              renderActiveFilters()
            )}
          </div>
        </div>
        <hr />
        <div>
          {dropHeader.map((data, index) => {
            return (
              <>
                <div>
                  <div
                    onClick={() => {
                      displayTypes(`${data}${screen}`);
                    }}
                    className="activeFilterDivText"
                    data-testid={`dropHeaderData-${index}`}
                  >
                    <label style={{ cursor: "pointer" }}>{data}</label>
                    <i style={{ cursor: "pointer" }} className={`fa fa-caret-down ${data}${screen}caret`}></i>
                  </div>

                  {dropTypesList[data] !== dropTypesList["Status"] ? (
                    <div className={`anime ${data}${screen}`} style={{ display: "block" }} id="eselectFilterType">
                      {
                        <div>
                          <NavDropdown
                            id="eselectFilter"
                            title={
                              <div style={{ display: "flex" }}>
                                <span className="dropHeading">Select </span>
                                <UxIcon
                                  icon={UxIconEnum.CHEVRON_DOWN}
                                  size={UxSizeEnum.SMALL}
                                  className="dimFilterIconLog"
                                />
                              </div>
                            }
                            data-testid={`nav-dropdown-select-${index}`}
                          >
                            <div className="dropItems">
                              {dropTypesList[data].map((row: string, i: number) => {
                                return (
                                  <NavDropdown.Item
                                    className="dropDownItem"
                                    eventKey="active"
                                    onClick={() => {
                                      onItemSelect(row, data);
                                    }}
                                    key={row}
                                    data-testid={`nav-items-select-${i}`}
                                  >
                                    {row}
                                  </NavDropdown.Item>
                                );
                              })}
                            </div>
                          </NavDropdown>
                        </div>
                      }
                    </div>
                  ) : (
                    <div className={`anime ${data}${screen}`} style={{ display: "block" }}>
                      {
                        //@ts-ignore
                        dropTypesList[data].map((row: string, i: number) => {
                          return (
                            <div
                              className="inputTypeFlex"
                              onClick={() => {
                                onUncheck(row, data);
                              }}
                              data-testid={`droptypelist-labelicon-${i}`}
                            >
                              <div className="checkBoxUxIcon">
                                {filterStates[row] ? (
                                  <i className="fa fa-check-square"></i>
                                ) : (
                                  <i className="fa fa-square"></i>
                                )}
                              </div>
                              <label className="typeLabel">{row}</label>
                            </div>
                          );
                        })
                      }
                    </div>
                  )}

                  <hr />
                </div>
                <div>{custom && data === "Created Date" && <Calender callback={callback} calender={calender} />}</div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
