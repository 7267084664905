import IconSizeEnum from "../enums/icon-size.enum";

/**
 * @function getEnumValue
 * @param {IconSizeEnum} enumObject enum to get the value from
 * @param {string} key index of enum
 * @description Returns the enum value
 * @returns {string} any
 */
const getEnumValue = (enumObject: typeof IconSizeEnum, key: string): number => enumObject[key];

export default getEnumValue;
