import React from "react";
import { UxModal, UxAction, UxShadesEnum, UxPositionEnum } from "@wne-spa/ux-component-library";

interface IUserListProps {
  show: boolean;
  save: () => void;
  close: () => void;
  message: string;
}

export default function ViewEducatorUpdateModal({ show, close, save, message }: IUserListProps): JSX.Element {
  return (
    <UxModal
      showModal={show}
      handleClose={() => {
        close();
      }}
      title={"Confirm"}
      size="lg"
      scrollable={true}
      body={
        <>
          <div className="mb-5">
            <span className="name-text">{message}</span>
          </div>
        </>
      }
      footer={[
        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Cancel"}
          className="px-2 py-1"
          priority={UxShadesEnum.LIGHT}
          callback={() => {
            close();
          }}
        />,
        <UxAction
          key={1}
          size="sm"
          enabled={true}
          text={"Yes"}
          className="px-4 py-1"
          priority={UxShadesEnum.DARK}
          callback={() => {
            save();
          }}
        />
      ]}
      vPosition={UxPositionEnum.CENTER}
      hPosition={UxPositionEnum.CENTER}
      backdrop="static"
    />
  );
}
