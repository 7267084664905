import React from "react";

import Card from "react-bootstrap/Card";
import "../visual-action-content.scss";
import TemplateProps from "./templates.props";

const Default = (props: TemplateProps) => {
  const { icon, title, titleClassName, slogan, progress, action, background } = props;

  return (
    <>
      {(icon || background) && (
        <div className="visual-action-image pb-3">
          {background}
          {icon}
        </div>
      )}
      <div className={titleClassName}>
        <Card.Title
          as="h2"
          className={
            titleClassName.includes("text912")
              ? "card-title912 visual-action-title m-0 truncate-lines"
              : `card-title visual-action-title m-0 truncate-lines`
          }
          title={title}
        >
          {title}
        </Card.Title>
      </div>
      {slogan}
      {progress && <div className="mb-3 w-100">{progress}</div>}
      {action}
    </>
  );
};

export default Default;
