import React from "react";
import Routing from "./Routing";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/Redux/Store";
import "./index.scss";
import "./font.scss";
import { RootComponentProps } from "./root-component.props";
const RootComponent = ({
  breadcrumbObservable,
  tokenExpiryMessageSubjectObservable,
  tokenSubject,
  schedulerAssessmentSubject,
  schedulerAssessmentObservable,
  ilpsubject,
  ilpObservable,
  MyPathLevelSubject,
  MyPathBreadcrumbsSubjectObservable,
  MyPathReassignSubject,
  MyPathReassignButtonObservable,
  assignmentBuilderBreadcrumbSubject,
  assignmentBuilderBreadcrumbObservable,
  assignmentBuilderCustomPropsSubject,
  assignmentBuilderCustomPropsSubjectObservable,
  BenchmarkGrowthReportBreadcrumbSubject,
  BenchmarkGrowthReportBreadcrumbSubjectObservable,
  BenchmarkGrowthReportBackSubject,
  BenchmarkGrowthReportBackObservable,
  standardsProficiencyReportBreadcrumbsObservable,
  ActivityIndicatorSubject,
  ActivityIndicatorObservable,
  mpngClassSummarySubject,
  mpngClassSummaryObservable,
  mpngStudentIlpSubject,
  mpngStudentIlpObservable,
  dsdsReportSubject,
  studentProgressSubject,
  subjectMFE,
  timeZoneSubject,
  MyPathLoaderSubject,
  MyPathLoaderSubjectObservable,
  mypathReportActiveFlagsSubject
}: RootComponentProps) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routing
          subject={breadcrumbObservable}
          tokenExpiryMessageSubjectObservable={tokenExpiryMessageSubjectObservable}
          tokenSubject={tokenSubject}
          schedulerAssessmentSubject={schedulerAssessmentSubject}
          schedulerAssessmentObservable={schedulerAssessmentObservable}
          ilpsubject={ilpsubject}
          ilpObservable={ilpObservable}
          MyPathLevelSubject={MyPathLevelSubject}
          MyPathBreadcrumbsSubjectObservable={MyPathBreadcrumbsSubjectObservable}
          MyPathReassignSubject={MyPathReassignSubject}
          MyPathReassignButtonObservable={MyPathReassignButtonObservable}
          assignmentBuilderBreadcrumbSubject={assignmentBuilderBreadcrumbSubject}
          assignmentBuilderBreadcrumbObservable={assignmentBuilderBreadcrumbObservable}
          assignmentBuilderCustomPropsSubject={assignmentBuilderCustomPropsSubject}
          assignmentBuilderCustomPropsSubjectObservable={assignmentBuilderCustomPropsSubjectObservable}
          BenchmarkGrowthReportBreadcrumbSubjectObservable={BenchmarkGrowthReportBreadcrumbSubjectObservable}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          BenchmarkGrowthReportBackSubject={BenchmarkGrowthReportBackSubject}
          BenchmarkGrowthReportBackObservable={BenchmarkGrowthReportBackObservable}
          standardsProficiencyReportBreadcrumbsObservable={standardsProficiencyReportBreadcrumbsObservable}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
          ActivityIndicatorObservable={ActivityIndicatorObservable}
          mpngClassSummarySubject={mpngClassSummarySubject}
          mpngClassSummaryObservable={mpngClassSummaryObservable}
          mpngStudentIlpSubject={mpngStudentIlpSubject}
          mpngStudentIlpObservable={mpngStudentIlpObservable}
          dsdsReportSubject={dsdsReportSubject}
          studentProgressSubject={studentProgressSubject}
          subjectMFE={subjectMFE}
          timeZoneSubject={timeZoneSubject}
          MyPathLoaderSubject={MyPathLoaderSubject}
          MyPathLoaderSubjectObservable={MyPathLoaderSubjectObservable}
          mypathReportActiveFlagsSubject={mypathReportActiveFlagsSubject}
        />
      </PersistGate>
    </Provider>
  );
};

export default RootComponent;
