import React, { useState, useEffect, useRef } from "react";
import {
  UxActivityIndicator,
  defaultk2Img,
  default35Img,
  default68Img,
  default912Img,
  ImagineMPLogo,
  ImagineMPMonogram
} from "@wne-spa/ux-component-library";
import "../Homepage/StudentHomePage.scss";
import "../../../../src/index.scss";
import { updateActivityIndicator, updateShowScreen, updateISMPPAPP } from "../../../Redux/Action";
import { connect } from "react-redux";
import HeaderDropdown from "../HeaderDropdown/index";
import "../StudentSubjects/StudentSubjects.scss";
import ReduxProps from "../../../Redux/Redux.props";
import { StudentHomePageProps } from "./StudentHomePage.props";
import { redirectToPss } from "../../../helpers/sessionHelper";
import StudentTransitionScreen from "./StudentTransitionScreen";
import YouHaveNoWorkMessage from "./YouHaveNoWorkMessage";
import useGrade from "../hooks/useGrade";
import usePageTitle from "../hooks/usePageTitle";
import AssignmentsAndSubjects from "./AssignmentsAndSubjects";
import useMPPSubject from "../hooks/useMPPSubject";
import useStudentLoginDetails from "../hooks/useStudentLoginDetails";
import useCatTestStatus from "../hooks/useCatTestStatus";
import useStudentLandingDisplay from "../hooks/useStudentLandingDisplay";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getShowScreen: state.getShowScreen,
    isSessionModal: state.isSessionModal,
    getMPPAPP: state.getMPPAPP
  };
}
interface Props extends ReduxProps, StudentHomePageProps {}

function StudentHomePage(props: Props) {
  const { dispatch, getActivityIndicator, getShowScreen, isSessionModal, getMPPAPP } = props;
  const { isKto2, is3to5, is6to8, is9to12, grade, updateGrade } = useGrade();
  const [showTransitionScreen, setShowTransitionScreen] = useState(false);
  const layout = useRef();
  const [srcImg, setSrcImg] = useState(ImagineMPLogo);
  const [waitingForNoContentMessage, setWaitingForNoContentMessage] = useState(true);
  const { updateTitle } = usePageTitle();
  const { mppSubject, updateMppSubject } = useMPPSubject();
  const { subStatusAndOrder } = useCatTestStatus();

  const {
    isLoading,
    studentDetails,
    showIMPathway,
    chronologicalGrade,
    firstName,
    lastName,
    studentMypathAssessment,
    TTSST,
    allPlacementData,
    studentId,
    showMyAssignmentBadge,
    gotStudentAssignments
  } = useStudentLoginDetails();

  const { showNoWorkMessage, showTiles, subjects } = useStudentLandingDisplay({
    gotStudentAssignments,
    showMyAssignmentBadge,
    allPlacementData,
    getMppApp: getMPPAPP,
    mppSubject,
    assessmentStatusData: subStatusAndOrder
  });

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    updateTitle("- Student");
  }, []);

  useEffect(() => {
    if (gotStudentAssignments || !isLoading) {
      dispatch(updateActivityIndicator(false));
    }
  }, [gotStudentAssignments, isLoading]);

  useEffect(() => {
    updateGrade(chronologicalGrade);
    dispatch(updateShowScreen(true));
  }, [chronologicalGrade]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (document.getElementById("layoutId")) {
        document.body.style.overflow = "auto";
        clearInterval(timeInterval);
      }
    }, 1);
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  useEffect(() => {
    if (!getMPPAPP) {
      // if false, double check
      const mppLaunch = localStorage.getItem("ala:MppLaunch");
      if (mppLaunch) {
        dispatch(updateISMPPAPP(true));
        updateMppSubject(localStorage.getItem("ala:MppLaunchSubject"));
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getSizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, layout]);

  function getSizes() {
    let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
    if (zoom > 95 || zoom < 90) {
      document.getElementById("layoutId").style.removeProperty("overflow");
    } else {
      document.getElementById("layoutId").style.overflow = "auto";
    }

    if (window.innerWidth < 240) {
      setSrcImg(ImagineMPMonogram);
    } else {
      setSrcImg(ImagineMPLogo);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setWaitingForNoContentMessage(false);
    }, 2000); // 2 second delay in showing the "you don't have content" this is because science and purpose sometime show up in data later
  }, []);

  const studentBackgroundImage = () => {
    if (isKto2) {
      return `url(${defaultk2Img})`;
    } else if (is3to5) {
      return `url(${default35Img})`;
    } else if (is6to8) {
      return `url(${default68Img})`;
    } else {
      return `url(${default912Img})`;
    }
  };

  const navbarHeaderBackgroundClass = () => {
    if (isKto2 || is6to8) {
      return " navbarheader backgroundk2";
    } else if (is3to5) {
      return " navbarheader background35";
    } else {
      return "navbarheader background912";
    }
  };

  const showWelcomeMessage = () => {
    return showTiles || (showNoWorkMessage && waitingForNoContentMessage) || !showNoWorkMessage;
  };

  return (
    <>
      <div>{getActivityIndicator ? <UxActivityIndicator /> : null}</div>
      {getShowScreen && (
        <>
          <div
            className="backgroundimg"
            style={{
              backgroundImage: studentBackgroundImage()
            }}
          ></div>
          <div id="layoutId" className="layout" ref={layout}>
            <div className="holder">
              <div
                className={navbarHeaderBackgroundClass()}
                style={is6to8 || is9to12 ? { opacity: "0.95" } : { opacity: "1" }}
              ></div>
              <div
                className={
                  showTransitionScreen || isSessionModal ? " headercontent disableheadercontent" : "headercontent"
                }
              >
                <div className="imgtext">
                  <img src={srcImg} alt="Imagine MyPath Logo" className={"logoimgresponsive"} />
                  {mppSubject && (
                    <>
                      <text className="headercontentpipe">|</text>
                      <text className="headercontenttext">{mppSubject}</text>
                    </>
                  )}
                </div>

                <div>
                  <HeaderDropdown firstName={firstName} showTransitionScreen={showTransitionScreen} />
                </div>
              </div>
            </div>
            {studentDetails.length && !showTransitionScreen && window.location.pathname.includes("home") ? (
              <div className="homepagecnt" id="homepageId">
                {getMPPAPP && (
                  <div className="MPPBackButtonBase">
                    <div className="MPPBackButton" onClick={() => redirectToPss(studentId.id, studentId.role)}>
                      <i className="fas fa-arrow-left arrowleft"></i>
                    </div>
                  </div>
                )}
                {showWelcomeMessage() && (
                  <div className="welcomemessage">
                    <div className="welcome textfocushide">Welcome,&nbsp;</div>
                    <div className="usernamecolor textfocushide">{firstName + " " + lastName}</div>
                  </div>
                )}
                <div className="homepagesubjects">
                  {showNoWorkMessage && (
                    <YouHaveNoWorkMessage
                      waitingForNoContentMessage={waitingForNoContentMessage}
                      studentId={studentId}
                    />
                  )}
                  {showTiles && (
                    <AssignmentsAndSubjects
                      allPlacementData={allPlacementData}
                      showMyAssignmentBadge={showMyAssignmentBadge}
                      getMPPAPP={getMPPAPP}
                      showIMPathway={showIMPathway}
                      grade={grade}
                      studentMypathAssessment={studentMypathAssessment}
                      subStatusAndOrder={subStatusAndOrder}
                      mppSubjects={subjects}
                    />
                  )}
                </div>
              </div>
            ) : (
              <StudentTransitionScreen
                showTransitionScreen={showTransitionScreen}
                studentDetails={studentDetails}
                setShowTransitionScreen={setShowTransitionScreen}
                getTTSST={TTSST}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default connect(mapStateToProps)(StudentHomePage);
