import React, { useState, useEffect } from "react";
import { UxTabs } from "@wne-spa/ux-component-library";
import "./ClassCards.scss";

function ClassView(): JSX.Element {
  const [tabKey, setTabKey] = useState("summary");

  useEffect(() => {
    localStorage.setItem("page", "summary");
  }, []);

  const tabs = [
    {
      eventKey: "summary",
      title: "Summary",
      content: <span className="pl-4"> Class Summary Page by MPNG </span>
    }
  ];
  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default ClassView;
