import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs, UxTabDropDown } from "@wne-spa/ux-component-library";
import StudentsTab from "../Students/StudentTab";
import Educators from "../EducatorsList/EducatorsList";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateTabChange,
  updateOrgLoginCodeAvailable,
  updateStudentsSubtab
} from "../../Redux/Action";
import "./SAClassList.scss";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";

interface SAUsersProps {
  dispatch: AppDispatch;
}

interface Props extends SAUsersProps, ReduxProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getTabChange: state.getTabChange,
    getStudentSubtab: state.getStudentSubtab
  };
}

function SAUsers(props: Props) {
  const { dispatch, getDaDetails, getTabChange, getStudentSubtab } = props;
  const [tabKey, setTabKey] = useState("students");
  const [schoolName, setSchoolName] = useState("");

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    dispatch(updateTabChange(!getTabChange));
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    dispatch(updatePageTitle(`- Users`));
    const breadcrumb = {
      crumbs: [{ path: `/sausers/${getDaDetails.id}`, name: "Users" }]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
    setSchoolName(getDaDetails.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDaDetails]);

  useEffect(() => {
    localStorage.setItem("page", "studentsRoster");

    let orgids = { guid: id };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
      }
    });
    setSelectedStudentView(getStudentSubtab);
    dispatch(updateStudentsSubtab("home"));
  }, []);

  const setPage = (pageName: string) => {
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  const tabs = [
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentsTab isfrom="school" orgLevel="school" selectedView={selectedStudentView} setSelectedView={setSelectedStudentView} />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: <Educators isfrom="school" schoolname={schoolName} />
    }
  ];

  async function gotLogincodeResponse(responsedata: Response) {
    try {
      let details = await responsedata.json();
      if (details.length > 0) {
        if (details[0].loginCode === "") {
          dispatch(updateOrgLoginCodeAvailable(false));
        } else {
          dispatch(updateOrgLoginCodeAvailable(true));
        }
      } else {
        //do nothing
      }
    } catch {}
  }

  const onTabClick = (tab: string) => {
    if (tab === "students") {
      return;
    }
    setSelectedStudentView("home");
    setTabKey(tab);
  };

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            onTabClick(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(SAUsers);
