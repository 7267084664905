import moment from "moment";
// @ts-ignore
import { WindowAPPENVprops } from "../root-component.props";
import { persistor } from "../Redux/Store";
import { AdminService } from "../services/helpers/api.services";

declare global {
  interface Window {
    APP_ENV: WindowAPPENVprops;
  }
}

export function clearLocalStorage() {
  localStorage.removeItem("lms:isRenew");
  localStorage.removeItem("isLogoutClicked");
  localStorage.removeItem("clever:sso");
  localStorage.removeItem("persist:growth-reports");
  localStorage.removeItem("persist:mypath-reports");
  localStorage.removeItem("oidc:session");
  localStorage.removeItem("page");
  localStorage.removeItem("oidc:state");
  localStorage.removeItem("ala:MppLaunch");
  localStorage.removeItem("ala:MppLaunchSubject");
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).includes("oidc.")) {
      localStorage.removeItem(localStorage.key(i));
    }
  }
}

async function sendAlaLogoutEvent(userId: string | number, userRole: string) {
  try {
    const clientDateTime = moment().format();
    const userAgent = navigator.userAgent;
    const jsonObj = {
      userId,
      clientDateTime: clientDateTime,
      userAgent: userAgent,
      userRole
    };

    await AdminService.saveLogoutevent(jsonObj);
  } catch (err) {
    //do nothing
  }
}

export async function redirectToPss(userId: string | number, userRole: string) {
  await persistor.purge();
  clearLocalStorage();
  sessionStorage.clear();
  await sendAlaLogoutEvent(userId, userRole);

  window.open(window.APP_ENV.pssBaseUrl, "_self");
}
