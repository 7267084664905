/* eslint-disable react-hooks/exhaustive-deps */
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  newMathIcon,
  newReadingIcon,
  newScienceIcon,
  newSELIcon,
  UXCSVExport,
  UxIcon,
  UxIconEnum,
  UxSizeEnum
} from "@wne-spa/ux-component-library";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { updateActivityIndicator, updateSubjectName } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { LMSService } from "../../services/helpers/lms.services";
import Classes from "../ViewCustomer/Classes";
import "./ClassCards.scss";
import { EducatorViewProps } from "./EducatorView.props";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getShowComponent: state.updateShowComponent,
    globalSubjectPurpose: state.globalSubjectPurpose
  };
}
interface Props extends EducatorViewProps, ReduxProps {}

function ClassCards(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const {
    handleClick,
    dispatch,
    getDaDetails,
    isfrom,
    getUserRoleId,
    globalSubjectPurpose,
    getBreadCrumbsData
  } = props;
  const [csvData, setCSVData] = useState([]);
  const [CSVFilename, setCSVFilename] = useState("");
  const [toggleView, setToggleView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const headersForCsv = [
    {
      label: "School Id",
      key: "schoolID"
    },
    {
      label: "Class ID",
      key: "classID"
    },
    {
      label: "Class Name",
      key: "className"
    },
    {
      label: "Class External ID",
      key: "districtAssignedId"
    },
    {
      label: "Teacher Name",
      key: "teacherName"
    },
    {
      label: "Subject",
      key: "subject"
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  interface IClassList {
    classID: number;
    classGUID: string;
    classTitle: string;
    organizationID: number;
    educators: string;
    subjects: string[];
    isActive: boolean;
    isSynced?: boolean;
  }

  useEffect(() => {
    if (getUserRoleId === 5 && getDaDetails.type === 1) {
      setCSVFilename(getDaDetails.name);
    } else if (window.location.pathname.includes("teacherclasses")) {
      setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
    } else {
      setCSVFilename(getDaDetails.name);
    }
  });
  useEffect(() => {
    const getClass = async () => {
      try {
        let jsonObj = {};
        jsonObj = {
          OrgGuid: 0,
          Page: 1,
          PageSize: 0,
          FilterStatus: "true",
          organizationGuid: id
        };
        let urltype = `single`;

        if (getUserRoleId === 5 && getDaDetails.type === 2 && isfrom !== "school") {
          urltype = `multiple`;
          jsonObj = { Page: 1, PageSize: 0, FilterStatus: "true" };
        }
        if (window.location.pathname.includes("teacherclasses")) {
          LMSService.getClassCSVData(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          });
        } else {
          LMSService.getClassCSVDataforTeacher(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          });
        }
        if (urltype === "single") {
          await LMSService.getClassListWithGuid(jsonObj).then(
            ({ status, data }: { status: number; data: Response }) => {
              if (status === 200) {
                gotClasses(data);
              } else {
                dispatch(updateActivityIndicator(false));
                setData({
                  page: 0,
                  size: 0,
                  pageSize: 0,
                  values: []
                });
              }
            }
          );
        } else {
          getClassListForTeacherWithGuidFunc(jsonObj);
        }
      } catch (err) {
        dispatch(updateActivityIndicator(false));
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    getClass();
    // eslint-disable-next-line
  }, [isfrom]);

  const getClassListForTeacherWithGuidFunc = (jsonObj) => {
    LMSService.getClassCSVDataforTeacher(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
      gotCSVData(data);
    });
    LMSService.getClassListForTeacherWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotClasses(data);
      } else {
        dispatch(updateActivityIndicator(false));
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
      }
    });
  };
  async function gotCSVData(responsedata: Response) {
    const csvData = JSON.parse(await responsedata.text());
    setCSVData(csvData);
  }
  async function gotClasses(responsedata: Response) {
    const DataClasses = JSON.parse(await responsedata.text());
    if (DataClasses.size === 0) {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setLoading(false);
      dispatch(updateActivityIndicator(false));
    } else {
      let classList = {
        page: DataClasses.page,
        size: DataClasses.size,
        pageSize: DataClasses.pageSize,
        values: []
      };
      let cvalues = [];
      for (let x in DataClasses.values) {
        pushingClassValues(DataClasses, cvalues, x);
      }
      //@ts-ignore
      classList.values = cvalues;

      setData(classList);
      setLoading(false);
      dispatch(updateActivityIndicator(false));
    }
  }
  const pushingClassValues = (DataClasses, cvalues, x: string) => {
    if (DataClasses.values[x].isActive) {
      let subjects = [];
      for (let y in DataClasses.values[x].subjects) {
        subjects.push(DataClasses.values[x].subjects[y].subject);
      }
      let educators = "";
      let elist = DataClasses.values[x].educators;
      //@ts-ignore
      elist.sort(function (a, b) {
        return a.sequence > b.sequence ? 1 : -1;
      });
      for (let z in elist) {
        if (z === "0") {
          educators = `${elist[z].lastName}, ${elist[z].firstName}`;
        } else {
          educators = `${educators}; ${elist[z].lastName}, ${elist[z].firstName}`;
        }
      }
      const classvalue = {
        classID: DataClasses.values[x].classID,
        classGUID: DataClasses.values[x].classGUID,
        classTitle: DataClasses.values[x].classTitle,
        organizationID: DataClasses.values[x].organizationID,
        isActive: DataClasses.values[x].isActive,
        educators: educators,
        subjects: subjects,
        isSynced: DataClasses?.values[x]?.isSynced
      };
      cvalues.push(classvalue);
    }
  };

  const getClasses = async () => {
    dispatch(updateActivityIndicator(true));
    try {
      let jsonObj = {};
      jsonObj = {
        OrgGuid: 0,
        Page: 1,
        PageSize: 0,
        FilterStatus: "true",
        organizationGuid: id
      };
      let urltype = `single`;

      if (getUserRoleId === 5 && getDaDetails.type === 2 && isfrom !== "school") {
        urltype = `multiple`;
        jsonObj = { Page: 1, PageSize: 0, FilterStatus: "true" };
      }

      LMSService.getClassCSVDataforTeacher(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        gotCSVData(data);
      });
      if (urltype === "single") {
        await LMSService.getClassListWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotClasses(data);
          } else {
            dispatch(updateActivityIndicator(false));
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
          }
        });
      } else {
        await LMSService.getClassListForTeacherWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotClasses(data);
            } else {
              dispatch(updateActivityIndicator(false));
              setData({
                page: 0,
                size: 0,
                pageSize: 0,
                values: []
              });
            }
          }
        );
      }
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      //do nothing
    }
  };

  const titleWithMenuFunc = (row: IClassList) => {
    return (
      <div className="titleWithMenu">
        {row.classTitle.length >= 40 ? (
          <LightTooltip title={`${row.classTitle}`}>
            <div className="newTeacherclassText">{row.classTitle}</div>
          </LightTooltip>
        ) : (
          <div className="newTeacherclassText"> {row.classTitle} </div>
        )}
      </div>
    );
  };

  function renderNewCards() {
    return data.values.map((row: IClassList) => {
      return (
        <div
          className="newCards"
          onClick={() => {
            handleClick(row.classGUID);
            dispatch(updateSubjectName(row.subjects[0]));
          }}
          key={row.classGUID}
        >
          <div className="cardInnerHolder">
            {titleWithMenuFunc(row)}
            <div className="subjectLayout">
              <div
                className="subjects"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: row.subjects.includes("Math") ? "flex" : "none"
                }}
              >
                {row.subjects.includes("Math") && (
                  <div
                    onClick={() => {
                      handleClick(row.classGUID);
                      dispatch(updateSubjectName("Math"));
                    }}
                    style={{ display: "flex" }}
                  >
                    <div className="subjectIconHolerForMS" style={{ width: "26.14px", height: "26.14px" }}>
                      <img src={newMathIcon} alt="" width="15.6px" height="15.68px" style={{ marginTop: "6px" }} />
                    </div>
                    <label className="subjectName">Math</label>
                  </div>
                )}
              </div>
              <div
                className="subjects"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: row.subjects.includes("Reading") ? "flex" : "none"
                }}
              >
                {row.subjects.includes("Reading") && (
                  <div
                    onClick={() => {
                      handleClick(row.classGUID);
                      dispatch(updateSubjectName("Reading"));
                    }}
                    style={{ display: "flex" }}
                  >
                    <div className="subjectIconHoler">
                      <img src={newReadingIcon} alt="" width="16.64px" height="16.64px" style={{ marginTop: "5px" }} />
                    </div>
                    <label className="subjectName">Reading</label>
                  </div>
                )}
              </div>
              <div
                className="subjects"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: row.subjects.includes(globalSubjectPurpose) ? "flex" : "none"
                }}
              >
                {row.subjects.includes(globalSubjectPurpose) && (
                  <div
                    onClick={() => {
                      handleClick(row.classGUID);
                      dispatch(updateSubjectName(globalSubjectPurpose));
                    }}
                    style={{ display: "flex" }}
                  >
                    <div className="subjectIconHoler">
                      <img src={newSELIcon} alt="" width="14px" height="14px" style={{ marginTop: "6px" }} />
                    </div>
                    <label className="subjectName">{globalSubjectPurpose}</label>
                  </div>
                )}
              </div>
              <div
                className="subjects"
                onClick={(e) => e.stopPropagation()}
                style={{
                  display: row.subjects.includes("Science") ? "flex" : "none"
                }}
              >
                {row.subjects.includes("Science") && (
                  <div
                    onClick={() => {
                      handleClick(row.classGUID);
                      dispatch(updateSubjectName("Science"));
                    }}
                    style={{ display: "flex" }}
                  >
                    <div className="subjectIconHolerForMS">
                      <img src={newScienceIcon} alt="" width="16.64px" height="16.64px" style={{ marginTop: "4px" }} />
                    </div>
                    <label className="subjectName">Science</label>
                  </div>
                )}
              </div>
            </div>
            <div className="newteacherIconHolder">
              {row.educators.length > 50 ? (
                <LightTooltip title={`Teacher: ${row.educators}`}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <label className="newteachersText">Teacher:</label>
                    <label className="teacherName">{row.educators}</label>
                  </div>
                </LightTooltip>
              ) : (
                <>
                  <label className="newteachersText">Teacher:</label>
                  <label className="teacherName">{row.educators}</label>
                </>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  return (
    <>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div className="newclasslayoutwithbutton">
        {data.values.length === 0 ? null : (
            <div className="th-listHolder">
              <div
                onClick={() => {
                  setToggleView(false);
                  getClasses();
                }}
                className="thHolder"
                style={{
                  cursor: "pointer",
                  backgroundColor: toggleView ? "#F2F4F9" : "#ffffff",
                  color: !toggleView ? "rgb(77 101 152)" : "rgba(77, 101, 152, 0.6)"
                }}
              >
                <UxIcon icon={UxIconEnum.TH} size={UxSizeEnum.MEDIUM} className="thIcon" />
              </div>
              <div
                onClick={() => {
                  setToggleView(true);
                }}
                className="thHolder"
                style={{
                  cursor: "pointer",
                  backgroundColor: !toggleView ? "#F2F4F9" : "#ffffff",
                  color: toggleView ? "rgb(77 101 152)" : "rgba(77, 101, 152, 0.6)"
                }}
              >
                <UxIcon icon={UxIconEnum.TH_LIST} size={UxSizeEnum.MEDIUM} className="thIcon" />
              </div>
            </div>
          )}

          {data.values.length > 0 &&
          !toggleView &&
          !(getUserRoleId === 5 && getDaDetails.type === 2 && isfrom !== "school" && isfrom !== undefined) ? (
            <div
              style={{
                width: "95%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "30px auto"
              }}
            >
              <div style={{ marginLeft: "20px" }}>
                <UXCSVExport
                  data={csvData}
                  headers={headersForCsv}
                  filename={
                    "Classes_" +
                    CSVFilename.replace(/[.]/g, " ") +
                    "_" +
                    (new Date(`${new Date()}`).getUTCMonth() + 1) +
                    "_" +
                    new Date(`${new Date()}`).getUTCDate() +
                    "_" +
                    new Date(`${new Date()}`).getUTCFullYear() +
                    "_" +
                    Date.now()
                  }
                />
              </div>
            </div>
          ) : null}
          {!toggleView ? (
            <div className={data.values.length !== 0 ? "newccardOuterHolder" : "ccardOuterHolder"}>
              {renderNewCards()}
              {!loading && data.values.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "70%"
                  }}
                >
                  <UxIcon icon={UxIconEnum.NO_CLASSES} className="noClassesIcon" />
                  <div className="noClassesSubMsg">No Classes Created</div>
                </div>
              ) : null}
            </div>
          ) : (
            <Classes isfrom="teacher" ismulti={isfrom} showcreate={isfrom} />
          )}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ClassCards);
