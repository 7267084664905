import React from "react";

import BrandedTextProps from "./branded-text.props";

const BrandedText = (props: BrandedTextProps) => {
  return (
    <span className={`edge-ux-branded-text ${props.className}`} data-testid="branded-text">
      <span style={{ color: "white" }}>Ed</span>
      <span style={{ color: "white" }}>
        genuity
        {props.registered && <span className="text-xxs text-super">&reg;</span>}
      </span>
    </span>
  );
};

export default BrandedText;
