import React from "react";
import Card from "react-bootstrap/Card";

import { ThemeContext } from "../providers/themes";

import VisualActionContent from "../visual-action-content";
import VisualActionProps from "./visual-action.props";
import VisualActionContentProps from "../visual-action-content/visual-action-content.props";

import UxPriorityEnum from "../enums/priority.enum";
import UxShadesEnum from "../enums/shades.enum";
import StateEnum from "../enums/state.enum";
import StatusEnum from "../enums/status.enum";
import UxStyleEnum from "../enums/style.enum";
import classMap from "..//utilities/classMap";
import VisualActionDefaults from "./visual-action.defaults";

import "./visual-action.scss";

const VisualAction = (props: VisualActionProps) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        props = { ...VisualActionDefaults[theme], ...props };
        const complete: boolean = props.state === StateEnum.COMPLETE;
        const loading: boolean = props.status === StatusEnum.LOADING;
        const error: boolean = props.status === StatusEnum.ERROR;
        const locked: boolean = props.state === StateEnum.LOCKED;
        const background: UxPriorityEnum | UxShadesEnum | undefined = UxPriorityEnum.PRIMARY;
        const style: UxStyleEnum = props.style || UxStyleEnum.ROUNDED;
        const visualActionClasses: string = classMap(
          "animation",
          `shape`,
          //${style}`,
          // props.size &&  (props.size === "1:1" ? "aspect-1-1" : `size ${props.size}`),
          loading ? "pulse loading bg-transparent" : "",
          error ? "error" : "",
          // error || locked ? "" : "border-0",
          locked || loading || error || complete ? "disabled" : "shadow-hover cursor-pointer",
          props.className
        );

        const visualBorder68 = () => {
          if (visualActionClasses.includes("squareGradeReading68")) {
            return "border68";
          } else {
            return "";
          }
        };
        const visualBorder912 = () => {
          if (visualActionClasses.includes("squareGradeMath912")) {
            return "border912";
          } else {
            return "";
          }
        };

        const circle: boolean = style === UxStyleEnum.CIRCLE;
        const bodyClasses = classMap(
          "card-body",
          "visual-action-body",
          "container",
          "h-100",
          "overflow-hidden",
          (circle && "rounded-circle") || "",
          visualActionClasses.includes("squareGradeMath68") ? "border68" : visualBorder68(),
          visualActionClasses.includes("squareGradeMath912") ? "border912" : visualBorder912()
        );

        const content: VisualActionContentProps = {
          ...props.content,
          action: props.content?.action && {
            ...props.content.action,
            enabled: !Boolean(locked || loading || error || complete),
            className: classMap(
              props.content.action?.className || "",
              (!props.callback && "stretched-link") || "",
              (circle && "after-border-radius-circle") || ""
            )
          },
          titleClassName:
            visualActionClasses.includes("squareGradeMath912") || visualActionClasses.includes("squareGradeReading912")
              ? "text912"
              : ""
        };

        return (
          <Card
            {...{
              //bg: background,
              // title: content?.title,
              className: classMap(
                "edge-ux-visual-action",
                visualActionClasses,
                "background-cover",
                "background-center",
                background
                //&& "bg-" + background
              ),
              onClick: props.callback ?? undefined,
              style: props.bgImage
                ? {
                    backgroundImage: `url(${props.bgImage})`
                  }
                : undefined,
              tabIndex: props.callback ? 0 : undefined
            }}
          >
            <Card.Body className={bodyClasses}>
              <VisualActionContent {...content} />
            </Card.Body>
          </Card>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default VisualAction;
