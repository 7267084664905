import React, { useEffect, useRef } from "react";
import "./index.scss";
import { UxIcon, UxIconEnum, UxSizeEnum } from "../../wne-spa-ux-component-library";

interface TabDropDownProps {
  title: string | JSX.Element;
  onNavClick: (args) => void;
  onDropDownClick: () => void;
  isOpen: boolean;
  options: [{ text: string; value: string }];
  activeOption: string;
}

function handleOutsideClickHook(
  ref: React.MutableRefObject<HTMLDivElement>,
  onClickOutside: () => void,
  dropdownState: boolean
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && dropdownState) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dropdownState]);
}

export default function TabDropDown(props: TabDropDownProps): JSX.Element {
  const { title, onNavClick, onDropDownClick, isOpen, options, activeOption } = props;

  const drowDownRef = useRef(null);

  handleOutsideClickHook(drowDownRef, onDropDownClick, isOpen);
  return (
    <div ref={drowDownRef} className="tab-title">
      <span
        onClick={() => {
          onNavClick("home");
        }}
        style={{ textAlign: "center" }}
        data-testid="onNavClickHome"
      >
        {title}
      </span>
      <div onClick={onDropDownClick} data-testid="onDropDownClick">
        <UxIcon
          icon={isOpen ? UxIconEnum.CHEVRON_UP : UxIconEnum.CHEVRON_DOWN}
          size={UxSizeEnum.SMALL}
          className="rfilterIcon"
        />
      </div>
      <div className={`tab-drop-down ${isOpen ? "open-tab" : ""}`}>
        {options.map((option) => (
          <div
            onClick={() => {
              onNavClick(option.value);
            }}
            className={`drop-down-item ${activeOption === option.value ? "tab-drop-down_selected" : ""}`}
            data-testid="onNavClick"
            key={`tab-option-${option.value}`}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
}
