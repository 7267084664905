import React, { useEffect, useState } from "react";
import { UxAction, TimesIcon, UxShadesEnum } from "@wne-spa/ux-component-library";
import "./index.scss";
import BulkImportsModal from "../../modals/bulk-imports";
import configMessages from "../../../helpers/configMessages";
import { ResetPasswordModalProps } from "../StudentProfile.props";

const borderColorToReset = "#DDDDDD";
const borderColorFocus = "#4DA7C9";
const borderColorSuccess = "#3CBCA6";
const borderColorError = "#F24E45";

export default function ResetPasswordModal(props: ResetPasswordModalProps) {
  const { isOpen, onClose, onConfirm } = props;

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordBorder, setNewPasswordBorder] = useState(borderColorToReset);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPasswordBorder, setConfirmPasswordBorder] = useState(borderColorToReset);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  const reset = () => {
    setNewPassword("");
    setNewPasswordError("");
    setNewPasswordBorder(borderColorToReset);

    setConfirmPassword("");
    setConfirmPasswordError("");
    setConfirmPasswordBorder(borderColorToReset);
  };

  const getTextFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    return event.target.value;
  };

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordBorder(borderColorFocus);
    setNewPasswordError("");
    const value = getTextFieldValue(event);
    if (!value.includes(" ")) {
      setNewPassword(value);
    }
  };

  const onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordBorder(borderColorFocus);
    setNewPasswordError("");
    const value = getTextFieldValue(event);
    if (!value.includes(" ")) {
      setConfirmPassword(value);
    }
  };

  function validatePassword() {
    let isValid = true;
    if (newPassword !== "" && newPassword.length > 5 && newPassword === confirmPassword && newPassword.length <= 32) {
      setNewPasswordBorder(borderColorSuccess);
      setNewPasswordError("");
      setConfirmPasswordBorder(borderColorSuccess);
      setConfirmPasswordError("");
    } else {
      if (newPassword !== "" && newPassword.length < 6) {
        setNewPasswordBorder(borderColorError);
        setNewPasswordError(configMessages.studentPasswordValidation);
        isValid = false;
      }
      if (newPassword !== "" && newPassword.length > 5) {
        setNewPasswordBorder(borderColorSuccess);
        setNewPasswordError("");
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordBorder(borderColorError);
        setConfirmPasswordError(configMessages.confirmPasswordValidation);
        isValid = false;
      }
      if (newPassword.length > 32) {
        setNewPassword(borderColorError);
        setNewPasswordError(configMessages.passwordValdiationMessage);
        isValid = false;
      }
    }
    return isValid;
  }
  const submitPassword = () => {
    if (validatePassword()) {
      onConfirm(newPassword);
    }
  };

  return (
    <BulkImportsModal
      showBulkModal={isOpen}
      children={
        <div className="reset-password-modal">
          <div className="reset-password-modal_header">
            <text>Reset Student Password</text>
            <img src={TimesIcon} alt="close icon" onClick={onClose} />
          </div>
          <div className="reset-password-modal_fields">
            <div className="resetPasswordInputWrapper">
              <label>NEW PASSWORD</label>
              <div
                style={{
                  border: `1.3px solid ${newPasswordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="eTextInput"
                  maxLength={56}
                  value={newPassword}
                  onChange={onNewPasswordChange}
                  onBlur={() => {
                    setNewPasswordBorder(borderColorToReset);
                  }}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                />
              </div>
              <span className="labelForIssue">{newPasswordError}</span>
            </div>
            <div className="resetPasswordInputWrapper">
              <label>CONFIRM PASSWORD</label>
              <div
                style={{
                  border: `1.3px solid ${confirmPasswordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="eTextInput"
                  maxLength={56}
                  value={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  onBlur={() => {
                    setConfirmPasswordBorder(borderColorToReset);
                  }}
                  type="password"
                  autoComplete="new-password"
                  placeholder="Password"
                />
              </div>
              <span className="labelForIssue">{confirmPasswordError}</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "-10px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              className="px-2 py-1 closeBut"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                onClose();
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={newPassword && confirmPassword}
              text={"Confirm"}
              className="px-2 py-1 closeBut"
              callback={submitPassword}
            />
          </div>
        </div>
      }
      width="394px"
      height="max-content"
    />
  );
}
