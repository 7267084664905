import React from "react";
import "./errorMsg.scss";

interface ErrorMsgProps {
  error: string;
}

function ErrorMsg(props: ErrorMsgProps): JSX.Element {
  const { error } = props;
  return (
    <>
      <div>
        <p className="errorTag"> Error </p>
        <div className="errorBox">
          <p className="errorMsg"> {error} </p>
        </div>
      </div>
    </>
  );
}

export default ErrorMsg;
