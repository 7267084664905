import { UxIcon, UxIconEnum, UxSizeEnum } from "@wne-spa/ux-component-library";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { updateActivityIndicator, updateSchoolGUID, updateSchoolID } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { LMSService } from "../../services/helpers/lms.services";
import { ClassDetailsProps, ClassSettingsProps } from "./ClassSettings.props";
import "./classSettings.scss";

interface Props extends ReduxProps, ClassSettingsProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getSchoolID: state.getSchoolID,
    getFlagrKeys: state.getFlagrKeys,
    getWneUser: state.getWneUser
  };
}

function ClassSettings(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getBreadCrumbsData, getUserRoleId, getDaDetails, getFlagrKeys, getWneUser } = props;
  const [classDetails, setClassDetails] = useState<ClassDetailsProps>({
    classID: 0,
    classGUID: "",
    classTitle: "",
    classExternalID: "",
    districtAssignedId: "",
    organizationID: 0,
    organizationGuid: "",
    educators: [],
    subjects: [],
    isActive: true
  });
  const [classTitle, setClassTitle] = useState("");
  const [classExternalID, setClassExternalID] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [educatorNames, setEducatorNames] = useState([]);
  const selectedSubjects = useRef([]);
  const [selectedTeacherList, setSelectedTeacherList] = useState([]);
  const [teacherOrigList, setTeacherOrigList] = useState([]);
  interface ITeachers {
    id: number;
    name: string;
    isActive: boolean;
  }

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    const fetchData = async () => {
      try {
        await LMSService.getClassDetailsWithGuid(id).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotClassDetails(data);
          }
        });
      } catch (err) {
        // do nothing
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function gotClassDetails(detail: Response) {
    //@ts-ignore
    const details = JSON.parse(await detail.text());
    //@ts-ignore
    dispatch(updateSchoolID(details.organizationID));
    dispatch(updateSchoolGUID(details.organizationGuid));
    //@ts-ignore
    setClassDetails(details);
    //@ts-ignore
    setClassTitle(details.classTitle);
    //@ts-ignore
    setSchoolYear(details.academicSessionTitle);

    if (details.districtAssignedId !== null || details.districtAssignedId !== "") {
      setClassExternalID(details.districtAssignedId);
    } else {
      setClassDetails({ ...details, districtAssignedId: "" });
    }

    //@ts-ignore
    if (details.subjects != null) {
      const subj = details.subjects.slice();
      selectedSubjects.current = subj;
    } else {
      //@ts-ignore
      selectedSubjects.current = [];
    }

    let snames = selectedTeacherList;
    let tnames = [];
    //@ts-ignore
    let elist = details.educators;
    //@ts-ignore
    elist.sort(function (a, b) {
      return a.sequence > b.sequence ? 1 : -1;
    });
    //@ts-ignore
    for (let x in details.educators) {
      //@ts-ignore
      snames.push({
        //@ts-ignore
        name: `${details.educators[x].lastName} ${details.educators[x].firstName}`,
        //@ts-ignore
        id: details.educators[x].educatorId,
        //@ts-ignore
        isActive: details.educators[x].isActive
      });
      //@ts-ignore
      tnames.push({
        //@ts-ignore
        name: `${details.educators[x].lastName} ${details.educators[x].firstName}`,
        //@ts-ignore
        id: details.educators[x].educatorId,
        //@ts-ignore
        isActive: details.educators[x].isActive
      });
    }
    //@ts-ignore
    setTeacherOrigList(tnames);
    //@ts-ignore
    setSelectedTeacherList(snames);
    dispatch(updateActivityIndicator(false));
  }

  useEffect(() => {
    //@ts-ignore
    let enames = [];
    for (let x in teacherOrigList) {
      //@ts-ignore
      enames.push(teacherOrigList[x]);
    }
    //@ts-ignore
    setEducatorNames(enames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherOrigList]);


  function renderEducatorNames() {
    return educatorNames.map((row: ITeachers) => {
      return (
        <>
          {row.isActive ? (
            <p className="activeTeacher"> {row.name} </p>
          ) : (
            <p className="inactiveTeacher"> {row.name} - Inactive</p>
          )}
        </>
      );
    });
  }

  const filterClassSubject = () => {
    if (classDetails.subjects === null) {
      return <span>-</span>;
    } else if (getFlagrKeys.MyPathScienceProduct) {
      return classDetails.subjects.map((e) => e.subject).join(", ");
    } else {
      return classDetails.subjects
        .filter((e) => e.subject !== "Science")
        .map((e) => e.subject)
        .join(", ");
    }
  };

  return (
    <div className="class-nav-tabs px-4 classSettingScrollable">
      <div>
        <div className="classSettings">
          <div>
            <span className="headings"> Class Details </span>
          </div>
          <div>
            <p className="tagname"> Class Name </p>
            {classTitle}
          </div>
          {getWneUser ? (
            <div>
              <p className="tagname"> External Id </p>
              {classExternalID === null || classExternalID === "" ? "--" : classExternalID}
            </div>
          ) : null}
          <div>
            <p className="tagname"> Teacher(s) </p>
            {educatorNames != null && educatorNames.length === 0 ? <span>-</span> : renderEducatorNames()}
          </div>
          <div>
            <p className="tagname"> Subject(s) </p>
            {filterClassSubject()}
          </div>
          {getUserRoleId === 5 && getDaDetails.type === 2 && getBreadCrumbsData.crumbs.length > 1 ? (
            <div>
              <p className="tagname"> School </p>
              {getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 2].name}
            </div>
          ) : null}
          <div>
            <p className="tagname"> Academic Session </p>
            {schoolYear === null || schoolYear === "" ? "--" : schoolYear}
            <UxIcon
              icon={schoolYear === null || schoolYear === "" ? "" : UxIconEnum.LOCK_ALT}
              size={UxSizeEnum.MEDIUM}
              className="lockIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ClassSettings);
