import React, { useState, useEffect } from "react";
import { UserService } from "../../../services/helpers/user.services";
import { StudentId, TTSSTProps, UpdatePlacementProps } from "../Homepage/StudentHomePage.props";
import { buildPlacementData } from "../Homepage/buildPlacementData";
import GetToken from "../../../authService/GetToken";
import PendoMetadata from "../PendoIntegration/PendoIntegration";

export default function useStudentLoginDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [studentDetails, setStudentDetails] = useState([]);
  const [showIMPathway, setShowIMPathway] = useState(false);
  const [chronologicalGrade, setChronologicalGrade] = useState("");
  const [firstName, setFirstName] = useState<null | string>("");
  const [lastName, setLastName] = useState<null | string>("");
  const [studentMypathAssessment, setStudentMypathAssessment] = useState({
    math: "",
    reading: "",
    purpose: ""
  });
  const [TTSST, setTTSST] = useState<TTSSTProps>({
    tts: { math: "off", reading: "off" },
    st: { math: "off", reading: "off" }
  });
  const [allPlacementData, setAllPlacementData] = useState<UpdatePlacementProps[] | null>([]);
  const [studentId, setStudentId] = useState<StudentId>({ id: "", iep: false, role: "" });
  const [gotStudentAssignments, setGotStudentAssignments] = useState(false);
  const [showMyAssignmentBadge, setShowMyAssignmentBadge] = useState(false);

  const getStudentAssignments = async (studentguid: string, organizations: any) => {
    try {
      let schoolYear = "";
      if (organizations?.length > 0) {
        schoolYear = organizations[0].school_year;
      } else {
        console.error("No academic session found for student", studentguid);
      }
      const rawResponse = await fetch(
        `${window.APP_ENV.studentAssignmentStatusURL + studentguid}?schoolYear=${schoolYear}`,
        //`https://admin.mp.qa.edgenuityapp.com/api/mpng-api/Alp/Count/${studentguid}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${GetToken()}`
          }
        }
      );
      const result = await rawResponse.json();
      (result.newAssignmentCount > 0 || result.inProgressAssignmentCount > 0) &&
      result.completedAssignmentCount !== result.totalAssignmentCount
        ? setShowMyAssignmentBadge(true)
        : setShowMyAssignmentBadge(false);

      setGotStudentAssignments(true);
    } catch (err) {
      console.error(err, "getStudentAssignments() - Exception");
      setGotStudentAssignments(true);
    }
  };

  const getStudentProducts = async (studentGuid: string) => {
    try {
      const response = await fetch(
        `${window.APP_ENV.mpng_api_base_url}user/${studentGuid}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${GetToken()}`
          }
        }
      );
      const result = await response.json();
      return result.products ?? [];
    } catch (err) {
      console.error(err, "getStudentProducts - Exception");
      return [];
    }
  }

  const getStudentPlacements = async (studentData: any) => {
    const studentProducts = await getStudentProducts(studentData.studentDetail[0].user_id);
    const { status, data } = await UserService.getStudentPlacements(studentData.studentDetail[0].user_id);
    if (status !== 200) {
      setAllPlacementData(buildPlacementData(studentData, studentProducts, []));
    } else {
      const studentPlacements = await data.json();
      setAllPlacementData(buildPlacementData(studentData, studentProducts, studentPlacements));
    }
  }

  useEffect(() => {
    UserService.getStudentLoginDetails().then(({ status, data }) => {
      if (status !== 200) {
        setIsLoading(false);
        return;
      }

      data.json().then(async (data) => {
        setStudentDetails(data.studentDetail);
        setShowIMPathway(data.productLicense.licenseTypeMath === "Standard+");
        setChronologicalGrade(data.studentDetail[0].chronological_grade);
        setFirstName(data.studentDetail[0].first_name);
        setLastName(data.studentDetail[0].last_name);
        setStudentMypathAssessment(data.studentDetail[0].mypath_Assessment);
        setTTSST({
          tts: data.studentDetail[0].text_to_Speech,
          st: data.studentDetail[0].language_Translation,
          lang: data.studentDetail[0].spanish_MyPathAssessment
        });
        setStudentId({
          id: data.studentDetail[0].user_id,
          iep: data.studentDetail[0].iep !== "No",
          role: data.studentDetail[0].role
        });
        PendoMetadata(data.studentDetail[0].user_id);

        await getStudentAssignments(data.studentDetail[0].user_id, data.organizations);
        await getStudentPlacements(data);

        setIsLoading(false);
      });
    });
  }, []);
  return {
    isLoading,
    studentDetails,
    showIMPathway,
    chronologicalGrade,
    firstName,
    lastName,
    studentMypathAssessment,
    TTSST,
    allPlacementData,
    studentId,
    showMyAssignmentBadge,
    gotStudentAssignments
  };
}
