import React from "react";

import Row from "react-bootstrap/Row";

import VisualAction from "../visual-action";
import UxAction from "../action";
import VisualActionContainerProps from "./visual-action-container.props";
import VisualActionProps from "../visual-action/visual-action.props";
import UxOrientationEnum from "../enums/orientation.enum";
import UxIconEnum from "../enums/icon.enum";
import UxPositionEnum from "../enums/position.enum";
import classMap from "..//utilities/classMap";
import config from "../../config";

import "./visual-action-container.scss";

const VisualActionContainer = (props: VisualActionContainerProps) => {
  const colCount: number = props.colCount || 3;
  const vertical: boolean = props.orientation === UxOrientationEnum.VERTICAL;
  const staggered: boolean = props.staggered || false;
  const breakpoints = config.defaults.breakpoints;
  const aproxSizes: { [key: string]: number } = {
    sm: 270,
    md: 310,
    lg: 350,
    xl: 360
  };
  const sizeValue = (props?.children && props.children.length && props.children[0].size) || undefined;
  const isSizeExplicit: boolean = Boolean(sizeValue && sizeValue !== "1:1");
  const getResponsiveClasses = () => {
    return Object.keys(breakpoints)
      .map((size: string) => {
        return "col-" + size + "-" + Math.floor(12 / counts[size]);
      })
      .join(" ");
  };

  let counts: { [key: string]: number } = {
    sm: colCount - 2,
    md: colCount - 1,
    lg: colCount,
    xl: colCount
  };
  sizeValue &&
    isSizeExplicit &&
    Object.keys(breakpoints).map((key: string) => {
      counts[key] = Math.floor(breakpoints[key] / aproxSizes[sizeValue]);
      return true;
    });

  return (
    <>
      <Row
        className={classMap(
          props.className,
          "edge-ux-visual-action-container",
          "displaycenter",
          //props.className,
          vertical ? "flex-column" : "",
          staggered ? "column-stagger" : ""
        )}
      >
        {props.children &&
          props.children.map((visualActionProps: VisualActionProps, index: number) => {
            const classes: string = classMap(staggered ? undefined : "mb-4", "col-10", getResponsiveClasses());
            const divRef = visualActionProps.focused ? props.focusRef : null;
            return (
              <div key={index} ref={divRef} className={classes}>
                <VisualAction
                  {...{
                    ...visualActionProps,
                    className: classMap(visualActionProps.className, "mx-auto")
                  }}
                />
              </div>
            );
          })}
      </Row>
      {staggered && props.showBackToCurrent && (
        <div className="back-to-current-div">
          <UxAction
            className="text-dark"
            callback={props.callback}
            priority="link"
            text="Back to Current"
            icon={{
              textPosition: UxPositionEnum.BOTTOM,
              icon: props.chevronUp ? UxIconEnum.CHEVRON_UP : UxIconEnum.CHEVRON_DOWN,
              className: "bg-dark rounded-circle p-2 text-white",
              squared: true
            }}
          />
        </div>
      )}
    </>
  );
};

export default VisualActionContainer;
