import { UserService } from "../../../services/helpers/user.services";
import { OrganizationDetailsProps, StudentMetaData } from "../Homepage/StudentHomePage.props";

export default async function PendoMetadata(userID: number) {
  try {
    await UserService.pendoStudentDetails(userID).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        metadataSetting(data);
      } else {
        //do nothing
      }
    });
  } catch (error) {
    //do nothing
  }
}

async function metadataSetting(dataRes: Response) {
  let Studentdata = await dataRes.json();

  const institutionName: string[] = [];
  const institutionId: number[] = [];
  const institutionState: string[] = [];
  const institutionOrgType: string[] = [];
  const institutionAlaType: string[] = [];
  const institutionAlaGuid: string[] = [];

  Studentdata.organizationDetails.forEach((row: OrganizationDetailsProps) => {
    institutionName.push(row.institutionName);
    institutionId.push(row.institutionId);
    institutionState.push(row.institutionState);
    institutionOrgType.push(row.institutionOrgType);
    institutionAlaType.push(row.institutionAlaType);
    institutionAlaGuid.push(row.institutionGuid);
    
  });

  const studentMetadata = {
    ...Studentdata,
    organizationDetails: {
      institutionName: institutionName.join(""),
      institutionId: institutionId.join(""),
      institutionState: institutionState.join(""),
      institutionCategory: institutionOrgType.join(""),
      institutionAlaType: institutionAlaType.join(""),
      institutionAlaGUID: institutionAlaGuid[0]
    }
  };

  PendoInitialize(studentMetadata);
}

function PendoInitialize(pendoMetadata: StudentMetaData) {
  //@ts-ignore
  const { organizationDetails, siteOrganizationDetails, userGuid } = pendoMetadata;

  //@ts-ignore
  if (window.pendo?.initialize) {
    //@ts-ignore
    window.pendo.initialize({
      visitor: {
        id: userGuid,
        role: pendoMetadata.roleName,
        grade: pendoMetadata.gradeLevel
      },
      account: {
        id: organizationDetails.institutionId,
        institutionName: organizationDetails.institutionName,
        institutionALAID: organizationDetails.institutionId,
        institutionState: organizationDetails.institutionState,
        institutionCategory: organizationDetails.institutionCategory,
        institutionALAType: organizationDetails.institutionAlaType,
        siteInstitutionname: siteOrganizationDetails.siteInstitutionName,
        siteInstitutionALAID: siteOrganizationDetails.siteInstitutionId,
        siteInstitutionLoginCode: siteOrganizationDetails.siteCode,
        siteInstitutionState: siteOrganizationDetails.siteInstitutionState,
        siteInstitutionCategory: siteOrganizationDetails.siteInstitutionOrgType,
        siteInstitutionALAType: siteOrganizationDetails.siteInstitutionAlaType
      }
    });
  }
}
