import React, { useState, useEffect, useRef } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { IWNEUsers, WneuserProps, RowProps } from "./IWNEUser";
import { useTranslation } from "react-i18next";
import {
  UxAction,
  UxActivityIndicator,
  UxMUITable,
  UxFilterDropDown,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxSearchInput,
  TimesIcon,
  UxSliderAction,
  UxFilterSlider,
  UxPaginationController,
  UxPaginate
} from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./WNECreateUser.scss";
import {
  updateActivityIndicator,
  updateWNEUserFilter,
  updateWNEUserListSort,
  updatePaginationControllerState,
  updateBreadCrumbs
} from "../../Redux/Action";
import WNECreateModal from "../modals/UserCreateModal/WNECreateModal";
import ConfirmActions from "../ClassView/ConfirmActions";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import { UserService } from "../../services/helpers/user.services";
import SessionHandler from "../../authService/SessionHandler";
import ReduxProps from "../../Redux/Redux.props";
import ky from "ky";
import { validEmailAddress } from "../../helpers/regularExpressions";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRole: state.getUserRole,
    getWNEUserFilter: state.getWNEUserFilter,
    getWNEUserListSort: state.getWNEUserListSort,
    getUserId: state.getUserId,
    getPaginationControllerState: state.getPaginationControllerState,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getFlagrKeys: state.getFlagrKeys
  };
}

interface Props extends ReduxProps, WneuserProps {}

function WNEUserList(props: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getUserRole,
    dispatch,
    getWNEUserFilter,
    getWNEUserListSort,
    getUserId,
    getPaginationControllerState,
    getBreadCrumbsData
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModalIndicator, setShowModalIndicator] = useState(false);
  const [emptyTableText, setEmptyTableText] = useState("");
  const columnNames = ["WNE Name", "Username", "Type", "Status"];
  const rowMapper = [["lastName", "firstName"], "loginName", "userType", "isEnabled"];
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [bulkActionType, setbulkActionType] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [selectedAll, setSelectedAll] = React.useState<string[]>([]);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [UID, setUID] = useState("");
  const [actionType, setActionType] = useState("");
  const [showSingleConfirmationModal, setShowSingleConfirmationModal] = useState(false);
  const sortBy = useRef(getWNEUserListSort.name);
  const sortOrder = useRef(getWNEUserListSort.val);
  const [WNEnameBool, setWNEnameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [typeBool, setTypeBool] = useState<boolean | null>(null);
  const [searchText, setSearchText] = useState("");
  const searchResultRef = useRef(false);
  const textMessage = React.useRef("none");
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [stateName, setStateName] = useState({});

  const typesHeader = ["Type", "Status"];
  const types = {
    "WNE Admin": "Type",
    "WNE Support": "Type",
    "WNE HelpDesk": "Type",
    Active: "Status",
    Inactive: "Status"
  };
  const typesList = {
    Type: ["WNE Admin", "WNE Support", "WNE HelpDesk"],
    Status: ["Active", "Inactive"]
  };

  const filterStates = useRef<object>({
    "WNE Admin": false,
    "WNE Support": false,
    "WNE HelpDesk": false,
    Active: false,
    Inactive: false
  });

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[2] = { paginationPageName: "WNEUsers", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 3, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getWNEUsers(1, searchText);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    if (getWNEUserListSort.val === "desc" || getWNEUserListSort.val === "DESC") {
      if (getWNEUserListSort.name === "lastname") {
        setWNEnameBool(true);
      } else if (getWNEUserListSort.name === "loginname") {
        setUsernameBool(true);
      } else {
        setTypeBool(true);
      }
    } else if (getWNEUserListSort.val === "asc" || getWNEUserListSort.val === "ASC") {
      if (getWNEUserListSort.name === "lastname") {
        setWNEnameBool(false);
      } else if (getWNEUserListSort.name === "loginname") {
        setUsernameBool(false);
      } else {
        setTypeBool(false);
      }
    }

    activeFilters.current = getWNEUserFilter;
    let wneUsers = [];
    let wneStatus = [];
    for (let x in getWNEUserFilter) {
      if (getWNEUserFilter[x] === "Active") {
        filterStates.current = { ...filterStates.current, Active: true };
        wneStatus.push(["Active"]);
      } else if (getWNEUserFilter[x] === "Inactive") {
        filterStates.current = { ...filterStates.current, Inactive: true };
        wneStatus.push(["Inactive"]);
      } else if (getWNEUserFilter[x] === "WNE Admin") {
        filterStates.current = { ...filterStates.current, "WNE Admin": true };
        wneUsers.push(getWNEUserFilter[x]);
      } else if (getWNEUserFilter[x] === "WNE Support") {
        filterStates.current = { ...filterStates.current, "WNE Support": true };
        wneUsers.push(getWNEUserFilter[x]);
      } else if (getWNEUserFilter[x] === "WNE HelpDesk") {
        filterStates.current = {
          ...filterStates.current,
          "WNE HelpDesk": true
        };
        wneUsers.push(getWNEUserFilter[x]);
      }
    }
    setStateName({ ...stateName, Type: wneUsers, Status: wneStatus });
    const fetchData = async () => {
      dispatch(updateActivityIndicator(true));
      try {
        const uname = await SessionHandler().then((dataDetails) => {
          //@ts-ignore
          return dataDetails.username;
        });
        let FilterTypes = [];
        let filterStatus = [];
        for (let i in activeFilters.current) {
          if (activeFilters.current[i] === "Active") {
            filterStatus.push(true);
          } else if (activeFilters.current[i] === "Inactive") {
            filterStatus.push(false);
          } else if (activeFilters.current[i] === "WNE Admin") {
            FilterTypes.push(1);
          } else if (activeFilters.current[i] === "WNE Support") {
            FilterTypes.push(2);
          } else if (activeFilters.current[i] === "WNE HelpDesk") {
            FilterTypes.push(7);
          }
        }
        const jsonObj = {
          Page: 0,
          PageSize: getPaginationControllerState[2].paginationCount,
          Loginname: uname,
          SearchText: "",
          SortBy: sortBy.current,
          SortOrder: sortOrder.current,
          FilterRoleTypes: FilterTypes.join(),
          FilterStatus: filterStatus.join()
        };

        UserService.getSupportUsers(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data);
          } else {
            // do nothing
          }
        });
      } catch (err) {
        //do nothing
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function gotResponse(responsedata: Response) {
    setSelected([]);
    if (sortBy.current === "lastname") {
      setTypeBool(null);
      setUsernameBool(null);
    } else if (sortBy.current === "loginname") {
      setTypeBool(null);
      setWNEnameBool(null);
    } else {
      setUsernameBool(null);
      setWNEnameBool(null);
    }
    let allData = JSON.parse(await responsedata.text());
    setLoading(false);
    dispatch(updateActivityIndicator(false));
    if (allData.size === 0) {
      if (activeFilters.current.length === 0 && !searchResultRef.current) {
        setEmptyTableText("No records were found");
      } else if (activeFilters.current.length > 0 && searchResultRef.current) {
        setEmptyTableText("No results matching for your applied filters and search parameter");
      } else if (activeFilters.current.length > 0) {
        setEmptyTableText("No results matching your applied filters");
      } else if (searchResultRef.current) {
        setEmptyTableText("No results containing your search were found");
      }
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setSelectedAll([]);
    } else {
      let allUserid = [];
      for (let x in allData.values) {
        //@ts-ignore
        allUserid.push(allData.values[x].userGuid);
      }
      setSelectedAll(allUserid);
      setData(allData);
    }
  }
  const stateToReset = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    userType: "Select"
  };

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    userType: "Select"
  });

  const isIssueText = {
    firstName: configMessages.firstNameValidation,
    lastName: configMessages.lastNameValidation,
    userName: configMessages.userNameValidation,
    password: configMessages.passwordValidation,
    confirmPassword: configMessages.confirmPasswordValidation,
    email: configMessages.emailValidation,
    userType: configMessages.userTypeValidation,
    uniqueName: configMessages.uniqueNameValidation,
    uniqueEmail: configMessages.uniqueEmailValidation,
    AllowSpacialChar: configMessages.AllowSpacialChar
  };

  const issueTextToReset = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    userType: ""
  };

  const [firstNameIssueText, setFirstNameIssueText] = useState("");
  const [lastNameIssueText, setLastNameIssueText] = useState("");
  const [userNameIssueText, setUserNameIssueText] = useState("");
  const [passwordIssueText, setPasswordIssueText] = useState("");
  const [confirmPasswordIssueText, setConfirmPasswordIssueText] = useState("");
  const [emailIssueText, setEmailIssueText] = useState("");
  const [userTypeIssueText, setUserTypeIssueText] = useState("");
  const borderColorToReset = "#DDDDDD";
  const borderColorFocus = "#4DA7C9";
  const borderColorSuccess = "#3CBCA6";
  const borderColorError = "#F24E45";
  const [firstNameBorder, setFirstNameBorder] = useState(borderColorToReset);
  const [lastNameBorder, setLastNameBorder] = useState(borderColorToReset);
  const [userNameBorder, setUserNameBorder] = useState(borderColorToReset);
  const [passwordBorder, setPasswordBorder] = useState(borderColorToReset);
  const [userTypeBorder, setUserTypeBorder] = useState(borderColorToReset);
  const [confirmPasswordBorder, setConfirmPasswordBorder] = useState(borderColorToReset);
  const [emailBorder, setEmailBorder] = useState(borderColorToReset);

  const closeModal = () => {
    localStorage.setItem("page", "wneusers");
    setShowModal(false);
    setState(stateToReset);
    setFirstNameIssueText(issueTextToReset.firstName);
    setLastNameIssueText(issueTextToReset.lastName);
    setUserNameIssueText(issueTextToReset.userName);
    setPasswordIssueText(issueTextToReset.password);
    setConfirmPasswordIssueText(issueTextToReset.confirmPassword);
    setEmailIssueText(issueTextToReset.email);
    setFirstNameBorder(borderColorToReset);
    setLastNameBorder(borderColorToReset);
    setUserNameBorder(borderColorToReset);
    setPasswordBorder(borderColorToReset);
    setConfirmPasswordBorder(borderColorToReset);
    setEmailBorder(borderColorToReset);
    setUserTypeIssueText("");
    setUserTypeBorder(borderColorToReset);
  };

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>, inputType: string): void {
    if (inputType === "userType") {
      setState({ ...state, userType: event.target.value });
    } else if (
      (event.target.value.includes("#") || event.target.value.includes("%") || event.target.value.includes("&")) &&
      (inputType === "confirmPassword" || inputType === "password")
    ) {
      //do nothing
    } else if (
      (inputType === "userName" ||
        inputType === "password" ||
        inputType === "confirmPassword" ||
        inputType === "email") &&
      event.target.value.includes(" ")
    ) {
      // do nothing
    } else if (!event.target.value.includes(" ")) {
      setState({ ...state, [inputType]: event.target.value });
    } else if ((inputType === "firstName" || inputType === "lastName") && event.target.value.charAt(0) === " ") {
      // do nothing
    } else {
      setState({ ...state, [inputType]: event.target.value });
    }
  }

  function handleDropdownTextChange(event: string, inputType: string): void {
    if (inputType === "userType") {
      setState({ ...state, userType: event });
    } else {
      setState({ ...state, [inputType]: event });
    }
  }

  function validateFirstName() {
    if (state.firstName === "") {
      setFirstNameIssueText(isIssueText.firstName);
      setFirstNameBorder(borderColorError);
    } else {
      setFirstNameIssueText(issueTextToReset.firstName);
      setFirstNameBorder(borderColorSuccess);
    }
  }

  function validateLastName() {
    if (state.lastName === "") {
      setLastNameIssueText(isIssueText.lastName);
      setLastNameBorder(borderColorError);
    } else {
      setLastNameIssueText(issueTextToReset.lastName);
      setLastNameBorder(borderColorSuccess);
    }
  }

  function validateUserName() {
    if (state.userName === "") {
      setUserNameIssueText(isIssueText.userName);
      setUserNameBorder(borderColorError);
    } else {
      setUserNameIssueText(issueTextToReset.userName);
      setUserNameBorder(borderColorSuccess);
    }
  }

  function validatePasswordName() {
    const validateRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
    if (validateRegExp.test(state.password) && state.password.length <= 32) {
      setPasswordIssueText(issueTextToReset.password);
      setPasswordBorder(borderColorSuccess);
    } else {
      if (state.password.length > 32) {
        setPasswordIssueText(configMessages.passwordValdiationMessage);
        setPasswordBorder(borderColorError);
      } else {
        setPasswordIssueText(isIssueText.password);
        setPasswordBorder(borderColorError);
      }
    }
  }

  function validateConfirmPasswordName() {
    if (state.password !== state.confirmPassword || state.confirmPassword === "") {
      setConfirmPasswordIssueText(isIssueText.confirmPassword);
      setConfirmPasswordBorder(borderColorError);
    } else {
      setConfirmPasswordIssueText(issueTextToReset.confirmPassword);
      setConfirmPasswordBorder(borderColorSuccess);
    }
  }

  function validateEmail() {
    if (
      validEmailAddress.test(state.email) &&
      (state.email.toLowerCase().includes("@edgenuity.com") ||
        state.email.toLowerCase().includes("@weldnorth.com") ||
        state.email.toLowerCase().includes("@weldnorthed.com") ||
        state.email.toLowerCase().includes("@imaginelearning.com"))
    ) {
      setEmailIssueText(issueTextToReset.email);
      setEmailBorder(borderColorSuccess);
    } else {
      setEmailIssueText(isIssueText.email);
      setEmailBorder(borderColorError);
    }
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  async function createUser() {
    const validateRegExpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
    if (
      state.firstName !== "" &&
      state.lastName !== "" &&
      state.userName !== "" &&
      state.password.length >= 8 &&
      state.password.length <= 32 &&
      state.password === state.confirmPassword &&
      state.confirmPassword !== "" &&
      validateRegExpPassword.test(state.password) &&
      validEmailAddress.test(state.email) &&
      (state.email.toLowerCase().includes("@edgenuity.com") ||
        state.email.toLowerCase().includes("@weldnorth.com") ||
        state.email.toLowerCase().includes("@weldnorthed.com") ||
        state.email.toLowerCase().includes("@imaginelearning.com")) &&
      state.userType !== "Select"
    ) {
      setShowModalIndicator(true);
      try {
        addWNEUserApiCall();
      } catch (err) {
        //do nothing
      }
    }
  }

  const addWNEUserApiCall = () => {
    const jsonObj = {
      LoginName: state.userName,
      Password: state.password,
      FirstName: state.firstName.trim(),
      LastName: state.lastName.trim(),
      Email: state.email,
      UserType: ""
    };
    if (state.userType === "WNE Admin") {
      jsonObj.UserType = "WneAdmin";
    }
    if (state.userType === "WNE Support") {
      jsonObj.UserType = "WneSupport";
    }
    if (state.userType === "WNE HelpDesk") {
      jsonObj.UserType = "WneHelpDesk";
    }
    UserService.addWNEUser(jsonObj).then(
      ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
        if (status === 200) {
          setShowModalIndicator(false);
          getWNEUsers(1, searchText);
          closeModal();
        } else {
          handleErrorResponse(data as ky.HTTPError);
        }
      }
    );
  };

  async function handleErrorResponse(data: ky.HTTPError) {
    try {
      let errorMsg = JSON.parse(await data.response.text());
      const errMsg = errorMsg.message;
      setShowModalIndicator(false);
      if (errMsg === undefined) {
        if (errorMsg.errors) {
          const emailErrMsg = errorMsg.errors.Email[0];
          if (emailErrMsg === "Please enter a valid email address.") {
            setEmailIssueText(isIssueText.email);
            setEmailBorder(borderColorError);
          } else {
            setPasswordIssueText(isIssueText.password);
          }
        }
      } else if (errMsg.includes("Loginname already exists.")) {
        setUserNameIssueText(isIssueText.uniqueName);
        setUserNameBorder(borderColorError);
      } else if (errMsg.includes("Email address already exists.")) {
        setEmailIssueText(isIssueText.uniqueEmail);
        setEmailBorder(borderColorError);
      } else if (errMsg === "Unauthorized") {
        alert("Unauthorized to create users");
      } else if (errMsg.includes("Please enter a valid username with allowed special characters.")) {
        setUserNameIssueText(isIssueText.AllowSpacialChar);
        setUserNameBorder(borderColorError);
      } else {
        // do nothing
      }
    } catch (err) {
      // other error
    }
  }

  function validateUserType() {
    if (state.userType !== "Select") {
      setUserTypeIssueText("");
      setUserTypeBorder(borderColorSuccess);
    } else {
      setUserTypeIssueText(configMessages.WNEUserTypeValidation);
      setUserTypeBorder(borderColorError);
    }
  }

  function validateUser() {
    validateFirstName();
    validateUserType();
    validateLastName();
    validateUserName();
    validatePasswordName();
    validateConfirmPasswordName();
    validateEmail();
    validateUserType();
    createUser();
  }

  function viewWNEUser(row: RowProps) {
    let breadcrumbs = getBreadCrumbsData.crumbs;
    dispatch(updateActivityIndicator(true));
    breadcrumbs.push({
      path: `/wneuser/${row.userGuid}`,
      name: `${row.firstName} ${row.lastName}`
    });
    dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
    navigate(`/wneuser/${row.userGuid}`);
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    getWNEUsers(value, searchText);
  };
  async function getWNEUsers(pageno: number, stext: string) {
    dispatch(updateActivityIndicator(true));
    dispatch(updateWNEUserListSort({ name: sortBy.current, val: sortOrder.current }));
    try {
      const uname = await SessionHandler().then((dataDetail) => {
        //@ts-ignore
        return dataDetail.username;
      });
      if (activeFilters.current.length === 0) {
        dispatch(updateWNEUserFilter([]));
      } else {
        dispatch(updateWNEUserFilter(activeFilters.current));
      }
      dispatch(updateWNEUserListSort({ name: sortBy.current, val: sortOrder.current }));
      let FilterTypes = [];
      let filterStatus = [];
      let wneStatus = [];
      let wneUsers = [];
      for (let i in activeFilters.current) {
        if (activeFilters.current[i] === "Active") {
          filterStatus.push(true);
          wneStatus.push("Active");
        } else if (activeFilters.current[i] === "Inactive") {
          filterStatus.push(false);
          wneStatus.push("Inactive");
        } else if (activeFilters.current[i] === "WNE Admin") {
          FilterTypes.push(1);
          wneUsers.push("WNE Admin");
        } else if (activeFilters.current[i] === "WNE Support") {
          FilterTypes.push(2);
          wneUsers.push("WNE Support");
        } else if (activeFilters.current[i] === "WNE HelpDesk") {
          FilterTypes.push(7);
          wneUsers.push("WNE HelpDesk");
        }
      }

      setStateName({ ...stateName, Type: wneUsers, Status: wneStatus });
      const jsonObj = {
        Page: pageno,
        PageSize: getPaginationControllerState[2].paginationCount,
        Loginname: uname,
        SearchText: stext,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        FilterRoleTypes: FilterTypes.join(),
        FilterStatus: filterStatus.join()
      };

      UserService.getSupportUsers(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  const closeConfirmationModal = () => {
    setbulkActionType("");
    setSelected([]);
    setShowConfirmationModal(false);
  };

  function showActivateUsers() {
    setbulkActionType("activate");
    setShowConfirmationModal(true);
  }

  function showDeactivateUsers() {
    setbulkActionType("deactivate");
    setShowConfirmationModal(true);
  }

  async function activateUsers() {
    try {
      setShowConfirmationModal(false);
      dispatch(updateActivityIndicator(true));

      UserService.activateWNEUsersWithGuid(selected).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setShowToast({
            val: true,
            msg: configMessages.bulkActivateWNEUsersMsg
          });
          setSelected([]);
          getWNEUsers(pageNo, searchText);
        } else {
          alert("Error activating users");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error activating users");
      dispatch(updateActivityIndicator(false));
    }
  }

  async function deactivateUsers() {
    try {
      setShowConfirmationModal(false);
      dispatch(updateActivityIndicator(true));

      UserService.deactivateWNEUsersWithGuid(selected).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setShowToast({
            val: true,
            msg: configMessages.bulkDeactivateWNEUsersMsg
          });
          setSelected([]);
          getWNEUsers(pageNo, searchText);
        } else {
          alert("Error deactivating users");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error deactivating users");
      dispatch(updateActivityIndicator(false));
    }
  }

  async function activateUser() {
    try {
      setShowSingleConfirmationModal(false);
      dispatch(updateActivityIndicator(true));
      let userGuid = [UID];

      UserService.activateWNEUsersWithGuid(userGuid).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setShowToast({
            val: true,
            msg: configMessages.activateIndividualWNEUserMsg
          });
          setSelected([]);
          getWNEUsers(pageNo, searchText);
        } else {
          alert("Error activating users");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error activating users");
      dispatch(updateActivityIndicator(false));
    }
  }

  async function deactivateUser() {
    try {
      setShowSingleConfirmationModal(false);
      dispatch(updateActivityIndicator(true));
      let userGuid = [UID];

      UserService.deactivateWNEUsersWithGuid(userGuid).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setShowToast({
            val: true,
            msg: configMessages.deactivateIndividualWNEUserMsg
          });
          setSelected([]);
          getWNEUsers(pageNo, searchText);
        } else {
          alert("Error deactivating user");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error deactivating user");
      dispatch(updateActivityIndicator(false));
    }
  }

  function selectAllUsers() {
    setSelected(selectedAll);
  }
  function clearAllUsers() {
    setSelected([]);
  }

  const closeSingleConfirmationModal = () => {
    setShowSingleConfirmationModal(false);
  };

  function sortChange(type: string) {
    if (type === "WNE Name") {
      if (!WNEnameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "lastname";
    } else if (type === "Username") {
      if (!usernameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "loginname";
    } else {
      if (!typeBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "usertype";
    }

    getWNEUsers(1, searchText);
    setPageNo(1);
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getWNEUsers(1, searchText);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="wneusersearchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getWNEUsers(1, searchText);
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="wneusersearchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      setPageNo(1);
      getWNEUsers(1, "");
    }
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  function kebabClick(row: RowProps, index: number) {
    if (row.isEnabled) {
      setActionType("deactivate");
    } else {
      setActionType("activate");
    }
    setShowSingleConfirmationModal(true);
    setUID(row.userGuid);
  }

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = {
      "WNE Admin": false,
      "WNE Support": false,
      "WNE HelpDesk": false,
      Active: false,
      Inactive: false
    };
    setFilterCountClick(false);
    setStateName({});
    setPageNo(1);
    getWNEUsers(1, "");
  }

  function renderCreateUserInputs() {
    return (
      <>
        {showModalIndicator ? (
          <div className="fullScreenIndicator">
            <UxActivityIndicator position={"fixed !important"} opacity={0.5} />
          </div>
        ) : null}
        <div className="userProfileDetailsHolder">
          <div className="userProfileHolder">
            <label>WNE User Profile</label>
            <div className="elabelInputHolder firstOption">
              <label className="labelForInput">First Name *</label>
              <div
                style={{
                  border: `1.3px solid ${firstNameBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="textInput"
                  maxLength={255}
                  onClick={() => {
                    setFirstNameIssueText(issueTextToReset.firstName);
                    setFirstNameBorder(borderColorFocus);
                  }}
                  value={state.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "firstName")}
                  onBlur={() => {
                    setFirstNameBorder(borderColorToReset);
                  }}
                />
              </div>
              <label className="labelForIssue">{firstNameIssueText}</label>
            </div>
            <div className="elabelInputHolder">
              <label className="labelForInput">Last Name *</label>
              <div
                style={{
                  border: `1.3px solid ${lastNameBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="textInput"
                  maxLength={255}
                  onClick={() => {
                    setLastNameIssueText(issueTextToReset.lastName);
                    setLastNameBorder(borderColorFocus);
                  }}
                  value={state.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "lastName")}
                  onBlur={() => {
                    setLastNameBorder(borderColorToReset);
                  }}
                />
              </div>
              <label className="labelForIssue">{lastNameIssueText}</label>
            </div>
            <div className="elabelInputHolder">
              <label className="labelForInput">Username *</label>
              <div
                style={{
                  border: `1.3px solid ${userNameBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="textInput"
                  maxLength={50}
                  onClick={() => {
                    setUserNameIssueText(issueTextToReset.userName);
                    setUserNameBorder(borderColorFocus);
                  }}
                  value={state.userName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "userName")}
                  autoComplete="new-password"
                  onBlur={() => {
                    setUserNameBorder(borderColorToReset);
                  }}
                />
              </div>
              <label className="labelForIssue">{userNameIssueText}</label>
            </div>
            <div className="elabelInputHolder">
              <label className="labelForInput">Password *</label>
              <div
                style={{
                  border: `1.3px solid ${passwordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  type="password"
                  onClick={() => {
                    setPasswordIssueText(issueTextToReset.password);
                    setPasswordBorder(borderColorFocus);
                  }}
                  value={state.password}
                  className="textInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "password")}
                  autoComplete="new-password"
                  onBlur={() => {
                    setPasswordBorder(borderColorToReset);
                  }}
                />
              </div>
              <label className="labelForPasswordIssue">{passwordIssueText}</label>
            </div>
            <div className="elabelInputHolder lastOption">
              <label className="labelForInput">Confirm Password *</label>
              <div
                style={{
                  border: `1.3px solid ${confirmPasswordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  type="password"
                  onClick={() => {
                    setConfirmPasswordIssueText(issueTextToReset.confirmPassword);
                    setConfirmPasswordBorder(borderColorFocus);
                  }}
                  value={state.confirmPassword}
                  className="textInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "confirmPassword")}
                  onBlur={() => {
                    setConfirmPasswordBorder(borderColorToReset);
                  }}
                />
              </div>
              <label className="labelForIssue">{confirmPasswordIssueText}</label>
            </div>
          </div>
          <div className="userDetailsHolder">
            <label>WNE User Details</label>
            <div className="elabelInputHolder firstOption">
              <label className="labelForInput">Email *</label>
              <div
                style={{
                  border: `1.3px solid ${emailBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  maxLength={100}
                  onClick={() => {
                    setEmailIssueText(issueTextToReset.email);
                    setEmailBorder(borderColorFocus);
                  }}
                  value={state.email}
                  className="textInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "email")}
                  onBlur={() => {
                    setEmailBorder(borderColorToReset);
                  }}
                />
              </div>
            </div>
            <label className="labelForIssue">{emailIssueText}</label>
            <div className="elabelInputHolder">
              <label className="labelForInput">WNE User Type *</label>
              <div
                style={{
                  border: `1.3px solid ${userTypeBorder}`,
                  borderRadius: "3px",
                  paddingTop: "7px"
                }}
                onClick={() => {
                  setUserTypeBorder(borderColorFocus);
                }}
                onBlur={() => {
                  setUserTypeBorder(borderColorToReset);
                }}
              >
                <NavDropdown id="wselectUserType" title={state.userType}>
                  {getUserRole === "WNE Admin" ? (
                    <NavDropdown.Item
                      className="supportText labelForInput"
                      eventKey="active"
                      onClick={() => {
                        handleDropdownTextChange("WNE Admin", "userType");
                        setUserTypeIssueText("");
                        setUserTypeBorder(borderColorSuccess);
                      }}
                    >
                      WNE Admin
                    </NavDropdown.Item>
                  ) : null}

                  <NavDropdown.Item
                    className="supportText labelForInput"
                    eventKey="active"
                    onClick={() => {
                      handleDropdownTextChange("WNE HelpDesk", "userType");
                      setUserTypeIssueText("");
                      setUserTypeBorder(borderColorSuccess);
                    }}
                  >
                    WNE HelpDesk
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className="supportText labelForInput"
                    eventKey="active"
                    onClick={() => {
                      handleDropdownTextChange("WNE Support", "userType");
                      setUserTypeIssueText("");
                      setUserTypeBorder(borderColorSuccess);
                    }}
                  >
                    WNE Support
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              <label className="labelForIssue">{userTypeIssueText}</label>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <UxFilterSlider
          opacity={opacity}
          backgroundVisibility={contentVisibility}
          left={sliderLeft}
          sliderHeader="Filters"
          activeFilters={activeFilters.current}
          setActiveFilters={activeFilters}
          resetText="Reset Filters"
          onResetClick={() => {
            restoreData();
          }}
          activeFilterText="Active Filters"
          types={types}
          typesHeader={typesHeader}
          typesList={typesList}
          filterStates={filterStates.current}
          setFilterState={filterStates}
          setSliderLeft={setSliderLeft}
          setBackgroundVisibility={setContentVisibility}
          setOpacity={setOpacity}
          onChecked={() => {
            setPageNo(1);
            getWNEUsers(1, searchText);
          }}
          textMessage={textMessage}
          setMultiFilterDropDown={setFilterCountClick}
          screen="wneusers"
          stateName={stateName}
          setStateName={setStateName}
        />
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={4000}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            <Alert
              style={{ backgroundColor: "#36BBA4", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          </Snackbar>
        </div>
        <div className="userActionHolder">
          <div
            style={{
              marginTop: "25px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row"
            }}
          >
            <UxSliderAction
              filterText="Filters"
              activeFilters={activeFilters.current}
              minCount={1}
              multiFilterDropDown={filterCountClick}
              filterStates={filterStates.current}
              setFilterState={filterStates}
              setActiveFilters={activeFilters}
              setMultiFilterDropDown={setFilterCountClick}
              setSliderLeft={setSliderLeft}
              left={sliderLeft}
              setBackgroundVisibility={setContentVisibility}
              setOpacity={setOpacity}
              types={types}
              onChecked={() => {
                setPageNo(1);
                getWNEUsers(1, searchText);
              }}
              textMessage={textMessage}
              stateName={stateName}
              setStateName={setStateName}
            />
            {selected.length === 0 ? (
              <div className="wdimdropdownHolder">
                <div className="wdimtitleHolder">
                  <text className="wdimfilterText">Bulk Actions</text>
                  <UxIcon icon={UxIconEnum.CHEVRON_DOWN} size={UxSizeEnum.SMALL} className="wfilterIcon" />
                </div>
              </div>
            ) : (
              <div className="wdropdownHolder">
                <UxFilterDropDown
                  id="bulkactiondropdown"
                  title={
                    <div className="wtitleHolder">
                      <text className="wfilterText">Bulk Actions</text>
                      <UxIcon icon={UxIconEnum.CHEVRON_DOWN} size={UxSizeEnum.SMALL} className="wfilterIcon" />
                    </div>
                  }
                  content={
                    <>
                      <NavDropdown.Item onClick={() => showActivateUsers()}>
                        <text className="dropdownText">Activate</text>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => showDeactivateUsers()}>
                        <text className="dropdownText">Deactivate</text>
                      </NavDropdown.Item>
                    </>
                  }
                />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
            <UxSearchInput
              flexClassName="wnesFlexHolder"
              inputClassName="wnesInputText"
              placeHolder="Search Name, Username"
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="wneuserSearchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="wneuserclearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {
                restoreDefaultCheckState();
              }}
            />
            {getUserRole === "WNE Admin" ? (
              <UxAction
                callback={() => {
                  setShowModal(true);
                  localStorage.setItem("page", "CreateWNEuserForm");
                }}
                text={t("Create WNE User")}
                className="createWNEuserButton"
                size={"lg"}
              />
            ) : null}

            {getUserRole === "WNE HelpDesk" ? (
              <UxAction
                callback={() => {
                  setShowModal(true);
                  localStorage.setItem("page", "CreateWNEuserForm");
                }}
                text={t("Create WNE User")}
                className="createWNEuserButton"
                size={"lg"}
              />
            ) : null}
          </div>
        </div>
      </div>
      {selected.length > 0 ? (
        <div className="bulkOptions">
          <p className="selectcount">
            {" "}
            {selected.length} {selected.length > 1 ? "WNE Users have been selected." : "WNE User has been selected."}{" "}
          </p>
          {selected.length !== data.values.length ? (
            <p className="selectall" onClick={() => selectAllUsers()}>
              {" "}
              Select All {data.values.length} WNE Users on Page
            </p>
          ) : null}

          <p className="userclearall" onClick={() => clearAllUsers()}>
            {" "}
            Clear Selections{" "}
          </p>
        </div>
      ) : (
        <div className="nobulkOptions"></div>
      )}
      <WNECreateModal
        showModal={showModal}
        className="create-wne-user"
        children={renderCreateUserInputs()}
        create={() => {
          validateUser();
        }}
        close={() => {
          closeModal();
        }}
        title="Create WNE User"
      />
      <div ref={elementRef}>
        <UxMUITable
          MUITableClassName={data.values.length === 0 ? "emptyTable1" : "WNEUsersscrollableTable"}
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(data as IWNEUsers).values}
          showColumnCheckbox={true}
          showKebabMenu={true}
          KebabMenuOption={["Deactivate", "Activate"]}
          showNameKebabMenu={false}
          kebabAction={kebabClick}
          viewChild={viewWNEUser}
          emptyTableText={emptyTableText}
          checkboxRef={"userGuid"}
          selected={selected}
          setSelected={setSelected}
          showSortList={[true, true, true, false]}
          mtableHeight={tableHgt}
          sortListMapper={{
            "WNE Name": [
              WNEnameBool,
              setWNEnameBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Username: [
              usernameBool,
              setUsernameBool,
              (args: string) => {
                sortChange(args);
              }
            ],
            Type: [
              typeBool,
              setTypeBool,
              (args: string) => {
                sortChange(args);
              }
            ]
          }}
        />
      </div>
      {!loading ? (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[2].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[2].paginationCount}
              callback={callback}
            />
          )}
        </div>
      ) : null}
      {showConfirmationModal ? (
        <ConfirmActions
          title="Confirm"
          message={
            bulkActionType === "activate" ? configMessages.bulkActivateWNEUsers : configMessages.bulkDeactivateWNEUsers
          }
          close={closeConfirmationModal}
          callback={bulkActionType === "activate" ? activateUsers : deactivateUsers}
        />
      ) : null}
      {showSingleConfirmationModal ? (
        <ConfirmActions
          title="Confirm"
          message={
            actionType === "deactivate"
              ? configMessages.deactivateIndividualWNEUser
              : configMessages.activateIndividualWNEUser
          }
          close={closeSingleConfirmationModal}
          callback={actionType === "activate" ? activateUser : deactivateUser}
        />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps)(WNEUserList);
