import React from "react";
import { CSVLink } from "react-csv";
import "./index.scss";
import CSVProps from "./index.props";

export default function CSVPDFExport(props: CSVProps) {
  const { data, headers, filename } = props;

  return (
    <>
      <div className="csv-export" data-testid="csv-export-wrapper">
        <CSVLink
          data={data}
          headers={headers}
          filename={filename}
          style={{
            color: "#00007c"
          }}
        >
          <i className="fa fa-cloud-download csv-cloud"></i>
        </CSVLink>
      </div>
    </>
  );
}
