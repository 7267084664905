import React, { useState, useEffect, useRef } from "react";
import "./HeaderDropdown.scss";
import { logout } from "../../../authService/AuthService";
import moment from "moment";
import GetToken from "../../../authService/GetToken";
import { connect } from "react-redux";
import ReduxProps from "../../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserId: state.getUserId,
    isSessionModal: state.isSessionModal
  };
}

type HeaderDropdownProps = {
  firstName: string;
  showTransitionScreen: boolean;
};
interface Props extends ReduxProps, HeaderDropdownProps {}

function HeaderDropdown(props: Props) {
  const [show, setShow] = useState(false);
  const wrappedRef = useRef(null);
  useOutsideAlerter(wrappedRef);
  useEffect(() => {
    return () => {
      //do nothing
    };
  }, []);
  const { firstName, showTransitionScreen, isSessionModal, getUserId } = props;

  function timeOut() {
    setTimeout(() => {
      setShow(false);
    }, 10000);
  }

  function logoutStudent() {
    sendLogoutEvent();
    localStorage.removeItem("clever:sso");
    localStorage.removeItem("oidc:session");
    localStorage.removeItem("page");
    localStorage.removeItem("oidc:state");
    localStorage.removeItem("persist:mypath-reports");
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).includes("oidc.")) {
        localStorage.removeItem(localStorage.key(i));
      }
    }
    logout();
  }

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const userId = Number(getUserId);
      const jsonObj = {
        userId: userId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: "Student"
      };

      await fetch(`${window.APP_ENV.userApiUrl}/api/studentlogoutEvent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GetToken()}`
        },
        body: JSON.stringify(jsonObj)
      });
    } catch (err) {
      //do nothing
    }
  }

  function useOutsideAlerter(ref: React.MutableRefObject<HTMLDivElement>) {
    useEffect(() => {
      document.addEventListener("click", outsideClick);
      function outsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      return () => {
        document.removeEventListener("click", outsideClick);
      };
    }, [ref]);
  }

  const handlekeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 40) {
      document.getElementById("logbtn").focus();
    } else if (e.key === "Escape") {
      document.getElementById("logbtnholder").focus();
      setShow(!show);
      timeOut();
    }
  };
  const handlekeyPress = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      setShow(!show);
    }
  };
  return (
    <>
      <div
        id="logbtnholder"
        ref={wrappedRef}
        className="dropdownButtonHolder"
        tabIndex={showTransitionScreen || isSessionModal ? -1 : 0}
        role="button"
        aria-label={firstName}
        onKeyPress={(e) => {
          handlekeyPress(e);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          handlekeydown(event);
        }}
        onClick={() => {
          setShow(!show);
          timeOut();
        }}
      >
        {show ? (
          <div
            id="logbtn"
            className="logoutbox"
            tabIndex={0}
            role="button"
            aria-label="logout"
            aria-haspopup="true"
            aria-expanded={show ? true : false}
            onClick={() => {
              logoutStudent();
            }}
            onKeyPress={(event: React.KeyboardEvent<Element>) => {
              if (event.key === "Enter") {
                logoutStudent();
              }
            }}
          >
            Logout
          </div>
        ) : null}

        <div className="firstName" aria-hidden="true">
          {firstName}
        </div>
        <div className="dropdownArrowMark">
          <i className="fa fa-chevron-down"></i>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(HeaderDropdown);
