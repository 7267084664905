import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import {
  updateBreadCrumbs,
  updatePaginationControllerState,
  updateStudentsSubtab,
  updateTabName,
  updateActivityIndicator,
  updatePageTitle,
  updateCustomerListSort,
  updateSchoolGuids,
  updateAssessmentTab
} from "../../Redux/Action";
import "./customersearch.scss";
import {
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  TimesIcon
} from "@wne-spa/ux-component-library";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import CustomerModal from "./OrgStatus";
import { IUsers } from "../DataList/IUser";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import ErrorMsg from "../ErrorMsg/ErrorMsg";
import errorMessages from "../../helpers/errorMessages";
import { ICustomers, ICustomer } from "./customer.interface";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getCustomerListSort: state.getCustomerListSort,
    getUserId: state.getUserId,
    getPaginationControllerState: state.getPaginationControllerState,
    getActivityIndicator: state.getActivityIndicator,
    getSchoolGuids: state.getSchoolGuids,
    getFlagrKeys: state.getFlagrKeys
  };
}
interface CustomerProps {
  dispatch: AppDispatch;
}

interface Props extends CustomerProps, ReduxProps {}

function Customers(props: Props) {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const { dispatch, getCustomerListSort, getUserId, getPaginationControllerState, getSchoolGuids, getFlagrKeys } =
    props;
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState("");
  const columnNames = ["Institution Name", "State", "Category", "Institution ID", "Status"];
  const rowMapper = [["name", "rootPath"], "state", "orgType", "id", "isActive"];
  const [Guid, setGuid] = useState("");
  const [status, setStatus] = useState(false);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [custBool, setCustBool] = useState<boolean | null>(null);
  const [categoryBool, setCategoryBool] = useState<boolean | null>(null);
  const [institutionIdBool, setInstitutionIdBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const [stateBool, setStateBool] = useState<boolean | null>(null);
  const sortBy = useRef(getCustomerListSort.name);
  const sortOrder = useRef(getCustomerListSort.val);
  const [pageNo, setPageNo] = useState(1);
  const [customerData, setCustomerData] = useState<ICustomer>();
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [error, setError] = useState({
    state: false,
    msg: ""
  });

  useEffect(() => {
    dispatch(updateTabName(""));
    dispatch(updateStudentsSubtab("home"));
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    customerListSortFunc();
    dispatch(updatePageTitle("- Customers"));
    const fetchData = () => {
      const breadcrumb = {
        crumbs: [{ path: "/customers", name: "Customers" }]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    dispatch(updateActivityIndicator(false));
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  const customerListSortFunc = () => {
    if (getCustomerListSort.val === "DESC" || getCustomerListSort.val === "desc") {
      switch (getCustomerListSort.name) {
        case "organizationtitle":
          setCustBool(true);
          break;
        case "Category":
          setCategoryBool(true);
          break;
        case "state":
          setStateBool(true);
          break;
        case "institutionId":
          setInstitutionIdBool(true);
          break;
        default:
          setStatusBool(true);
      }
    } else if (getCustomerListSort.val === "ASC" || getCustomerListSort.val === "asc") {
      switch (getCustomerListSort.name) {
        case "organizationtitle":
          setCustBool(false);
          break;
        case "Category":
          setCategoryBool(false);
          break;
        case "state":
          setStateBool(false);
          break;
        case "institutionId":
          setInstitutionIdBool(false);
          break;
        default:
          setStatusBool(false);
      }
    }
  };

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleSearchButtonClick() {
    if (searchText !== "") {
      searchSchools(1, getPaginationControllerState[6].paginationCount);
      setPageNo(1);
      setSearchResult(true);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<Element>) {
    if (event.key === "Enter" && searchText !== "") {
      searchSchools(1, getPaginationControllerState[6].paginationCount);
      setPageNo(1);
      setSearchResult(true);
    }
  }

  const viewCustomer = async (row: ICustomer) => {
    let exist = getSchoolGuids.filter((item) => item.id === row.id);
    if (exist.length === 0) {
      let updatedSchoolGuids = [];
      updatedSchoolGuids = [...updatedSchoolGuids, row];
      dispatch(updateSchoolGuids(updatedSchoolGuids));
    }
    dispatch(updateAssessmentTab(""));
    if (row.childDepth === 0) {
      navigate(`/school/${row.organizationGuid}`);
    } else {
      navigate(`/institutions/${row.organizationGuid}`);
    }
  };

  function sortChange(type: string) {
    switch (type) {
      case "Institution Name":
        sortOrder.current = !custBool ? "DESC" : "ASC";
        sortBy.current = "organizationtitle";
        break;
      case "Category":
        sortOrder.current = !categoryBool ? "DESC" : "ASC";
        sortBy.current = "OrgType";
        break;
      case "Institution ID":
        sortOrder.current = !institutionIdBool ? "DESC" : "ASC";
        sortBy.current = "Id";
        break;
      case "State":
        sortOrder.current = !stateBool ? "DESC" : "ASC";
        sortBy.current = "state";
        break;
      default:
        sortOrder.current = !statusBool ? "DESC" : "ASC";
        sortBy.current = "isactive";
    }
    searchSchools(1, getPaginationControllerState[6].paginationCount);
    setPageNo(1);
  }

  async function searchSchools(pagenumber: number, pageSize: number) {
    try {
      setShowTable(true);
      dispatch(updateActivityIndicator(true));
      dispatch(updateCustomerListSort({ name: sortBy.current, val: sortOrder.current }));
      setSearchResult(true);
      const serachObj = {
        Page: pagenumber,
        PageSize: pageSize,
        SearchText: searchText,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        FilterBy: 1
      };
      AdminService.searchCustomers(serachObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setError({
            state: false,
            msg: ""
          });
          //@ts-ignore
          gotResponse(data);
        } else {
          setError({
            state: true,
            msg: errorMessages.customerError
          });
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      setError({
        state: true,
        msg: errorMessages.customerError
      });
      dispatch(updateActivityIndicator(false));
    }
  }
  async function gotResponse(responsedata: Response) {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    let allData = await responsedata.json();

    if (sortBy.current === "organizationtitle") {
      setStateBool(null);
      setStatusBool(null);
      setCategoryBool(null);
      setInstitutionIdBool(null);
    } else if (sortBy.current === "Category") {
      setStateBool(null);
      setCustBool(null);
      setStatusBool(null);
      setInstitutionIdBool(null);
    } else if (sortBy.current === "state") {
      setCustBool(null);
      setCategoryBool(null);
      setStatusBool(null);
      setInstitutionIdBool(null);
    } else if (sortBy.current === "Id") {
      setCategoryBool(null);
      setCustBool(null);
      setStatusBool(null);
      setStateBool(null);
    } else {
      setStateBool(null);
      setCustBool(null);
      setCategoryBool(null);
      setInstitutionIdBool(null);
    }
    if (allData.size === 0) {
      setEmptyTableText("No results containing your search were found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setData(allData);
    }
    dispatch(updateActivityIndicator(false));
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResult) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResult) {
      return (
        <div className="searchIconText">
          <span>Clear Results</span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResult) {
      setSearchText("");
    } else if (searchText !== "" && searchResult) {
      setSearchText("");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setPageNo(1);
      setSearchResult(false);
      setShowTable(false);
      sortOrder.current = "ASC";
      sortBy.current = "organizationtitle";
      setCustBool(false);
      setCategoryBool(false);
      setStateBool(false);
      setError({
        state: false,
        msg: ""
      });
    }
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchSchools(value, getPaginationControllerState[6].paginationCount);
    setPageNo(value);
  };

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  const handleClick = (row: ICustomer, rowindex: number) => {
    setGuid(row.organizationGuid);
    setStatus(row.isActive);
    setCustomerData(row);
    setIndex(rowindex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateCustomerStatus = () => {
    setShowModal(false);
    updateCustomer();
  };

  const customerDeactivateMsg = () => {
    if (customerData.parent === 0 && customerData.childDepth >= 1) {
      return configMessages.districtLevelDeactivateMsg;
    } else if (customerData.parent !== 0 && customerData.childDepth >= 1) {
      return configMessages.institutionLevelDeactivateMsg;
    } else if (customerData.childDepth === 0) {
      return configMessages.schoolLevelDeactivateMsg;
    } else {
      return "";
    }
  };

  const customerActivateMsg = () => {
    if (customerData.parent === 0 && customerData.childDepth >= 1) {
      return configMessages.districtLevelActivateMsg;
    } else if (customerData.parent !== 0 && customerData.childDepth >= 1) {
      return configMessages.institutionLevelActivateMsg;
    } else if (customerData.childDepth === 0) {
      return configMessages.schoolLevelActivateMsg;
    } else {
      return "";
    }
  };
  const activateCustomerWithGuidFunc = async () => {
    let apiObj = { guid: Guid };
    AdminService.activateCustomerWithGuid(apiObj).then((response) => {
      if (response.status === 200) {
        searchSchools(1, getPaginationControllerState[6].paginationCount);
        let msg: string;
        if ((data as IUsers).values[index].isActive) {
          msg = customerDeactivateMsg();
        } else {
          msg = customerActivateMsg();
        }
        (data as IUsers).values[index].isActive = !(data as IUsers).values[index].isActive;
        setData({
          page: 0,
          size: 0,
          pageSize: 0,
          values: []
        });
        setShowToast({ val: true, msg });
      } else {
        dispatch(updateActivityIndicator(false));
      }
    });
  };

  async function updateCustomer() {
    dispatch(updateActivityIndicator(true));
    try {
      if (status) {
        let apiObj = { guid: Guid };
        await AdminService.deactivateCustomerWithGuid(apiObj).then((response) => {
          if (response.status === 200) {
            searchSchools(1, getPaginationControllerState[6].paginationCount);
            let msg: string;
            if ((data as IUsers).values[index].isActive) {
              msg = customerDeactivateMsg();
            } else {
              msg = customerActivateMsg();
            }
            (data as IUsers).values[index].isActive = !(data as IUsers).values[index].isActive;
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
            setShowToast({ val: true, msg });
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } else {
        activateCustomerWithGuidFunc();
      }
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[6] = {
      paginationPageName: "Institutions",
      paginationCount: args
    };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 7, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            searchSchools(1, args);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  const searchResultInputClassName = () => {
    if (showTable === false) {
      if (!searchResult) {
        return "schoolFullInputTextMiddle";
      } else {
        return "schoolInputTextMiddle";
      }
    } else if (searchText === "") {
      return "schoolFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };

  return (
    <>
      <div className="schools">
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={4000}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            <Alert
              style={{ backgroundColor: "#36BBA4", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          </Snackbar>
        </div>
        <div className={!showTable ? "searchCustomersMiddle" : "searchCustomerTop"}>
          {!showTable ? <span className="searchHeading">Search For Customers</span> : null}
          <UxSearchInput
            inputClassName={searchResultInputClassName()}
            flexClassName={showTable === false ? "schoolFlexHolderMiddle" : "schoolFlexHolderTop"}
            placeHolder="Search school, district, or customer name or ID"
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="searchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="clearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
          />
        </div>
        {error.state ? (
          <div ref={elementRef}>
            {" "}
            <ErrorMsg error={error.msg} />{" "}
          </div>
        ) : (
          <div>
            <div ref={elementRef}>
              {showTable ? (
                <UxMUITable
                  MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableCustomersTable"}
                  columnNames={columnNames}
                  rowMapper={rowMapper}
                  rowData={(data as ICustomers).values}
                  showColumnCheckbox={false}
                  showKebabMenu={getFlagrKeys["ReadinessFeature_Organizations"] ? false : true}
                  showNameKebabMenu={false}
                  KebabMenuOption={["Deactivate", "Activate"]}
                  viewChild={viewCustomer}
                  emptyTableText={emptyTableText}
                  checkboxRef="loginName"
                  selected={[]}
                  setSelected={[]}
                  kebabAction={handleClick}
                  showToolTip={true}
                  mtableHeight={tableHgt}
                  showSortList={[true, true, true, true, true]}
                  sortListMapper={{
                    "Institution Name": [
                      custBool,
                      setCustBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    State: [
                      stateBool,
                      setStateBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Category: [
                      categoryBool,
                      setCategoryBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    "Institution ID": [
                      institutionIdBool,
                      setInstitutionIdBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Status: [
                      statusBool,
                      setStatusBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ]
                  }}
                />
              ) : null}
            </div>
            {showTable ? (
              <div className="fixedpagination">
                <UxPaginate
                  data={data}
                  page={pageNo}
                  paginationSize={getPaginationControllerState[6].paginationCount}
                  className={classes.root}
                  handleChange={handleChange}
                />
                {data.size > 50 && (
                  <UxPaginationController
                    rowsList={paginationControllers}
                    setRowsCount={getPaginationControllerState[6].paginationCount}
                    callback={callback}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
        {showModal ? (
          <CustomerModal
            customerdata={customerData}
            custstate={status}
            close={closeModal}
            status={updateCustomerStatus}
          />
        ) : null}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Customers);
