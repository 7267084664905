import { useDispatch, useSelector } from "react-redux";
import { updatePageTitle } from "../../../Redux/Action";

export default function usePageTitle() {
  const pageTitle = useSelector((state: any) => state.getPageTitle);
  const dispatch = useDispatch();
  const updateTitle = (newTitle) => {
    dispatch(updatePageTitle(newTitle));
  };

  return {
    pageTitle,
    updateTitle
  };
}
