import React from "react";
import StudentHomePage from "../StudentApp/Homepage/StudentHomePage";
import SessionManager from "../SessionManager/sessionManager";
import { BehaviorSubject } from "rxjs";

interface Props {
  tokenSubject: BehaviorSubject<string>;
}

function StudentBoundRoute({ tokenSubject }: Props) {
  return (
    <>
      <SessionManager tokenSubject={tokenSubject} />
      <StudentHomePage />
    </>
  );
}

export default StudentBoundRoute;
