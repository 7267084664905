import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import "./Schools.scss";
import { AdminService } from "../../services/helpers/api.services";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { updateOrgLoginCodeAvailable } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {};
}
type SettingsProps = {
  dispatch: AppDispatch;
  isfrom?: string;
};

interface Props extends SettingsProps, ReduxProps {}

function Settings(props: Props) {
  const [ExternalInstitutionId, setExternalInstitutionId] = useState("-");
  const [loginCode, setLoginCode] = useState("");
  const { dispatch } = props;
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });

  const [timezoneObject, setTimeZoneObject] = useState({});
  const [orgId, setOrgId] = useState("");

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  useEffect(() => {
    fetchExternalInstitutionId();
    let orgids = { guid: id };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
      }
    });
    // AdminService.getOrgTimezone(id)
    let apiObj1 = { orgGuid: id };
    AdminService.getOrgTimezoneWithGuid(apiObj1).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotTimezoneResponse(data);
      }
    });
  }, []);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.json();

    if (details.length > 0) {
      setLoginCode(details[0].loginCode);
      setOrgId(details[0].organizationID);
      if (details[0].loginCode === "") {
        dispatch(updateOrgLoginCodeAvailable(false));
      } else {
        dispatch(updateOrgLoginCodeAvailable(true));
      }
    } else {
      //do nothing
    }
  }

  async function gotTimezoneResponse(responseData: Response) {
    const timezoneObject = await responseData.json();
    setTimeZoneObject(timezoneObject);
  }

  async function fetchExternalInstitutionId() {
    try {
      let eids = { guid: id.split("_").toString() };
      await AdminService.getExternalInstitutionIdByGuid(eids).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setInstitutionId(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }
  async function setInstitutionId(data: Response) {
    let details = await data.json();
    let ExternalId = details[0].districtAssignedId;
    if (ExternalId === "") {
      setExternalInstitutionId("-");
    } else {
      setExternalInstitutionId(ExternalId);
    }
  }

  const loginCodeValue = loginCode !== "" ? loginCode : "- -";
  return (
    <>
      <div className="dasettings">
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={4000}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "" });
            }}
          >
            <Alert
              style={{ backgroundColor: showToast.color, color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", color: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          </Snackbar>
        </div>
        <div className="ps-4">
          <div className="nweasettings">
            <p className="settingsheadings"> Institution ID </p>

            <p className="settingstagvalue"> {orgId} </p>
          </div>
          <div className="nweasettings">
            <p className="settingsheadings"> External Institution ID </p>

            <p className="settingstagvalue">{ExternalInstitutionId === " " ? "-" : ExternalInstitutionId}</p>
          </div>
        </div>

        <div className="ps-4 ">
          <span className="settingsheadings"> Site Code </span>
          <p className="settingstagname "> SITE CODE </p>

          <p className="settingstagvalue">
            {loginCodeValue}
            <CopyToClipboard text={loginCodeValue}>
              <button
                style={{ border: "none" }}
                className="copyButton"
                onClick={() => {
                  setShowToast({
                    val: true,
                    msg: "Site Code copied successfully",
                    color: "#36BBA4",
                    type: "success"
                  });
                }}
              >
                <i className="fal fa fa-clone"></i>
              </button>
            </CopyToClipboard>
          </p>
        </div>
        <div className="loginCodesettings ps-4">
          <span className="settingsheadings"> Time Zone </span>
          <div>
            <p className="settingstagname"> TIME ZONE </p>
            <p className="settingstagvalue">
              {/* @ts-ignore */}
              {timezoneObject.timeZoneName ? `(UTC ${timezoneObject.utcTime}) ${timezoneObject.timeZoneName}` : "- -"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Settings);
