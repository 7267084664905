import React, { useRef } from "react";
import InpuTextProps from "./search-input.props";

export default function InputText(props: InpuTextProps) {
  const {
    inputClassName,
    inputID,
    searchButtonClassName,
    clearButtonClassName,
    buttonID,
    name,
    placeHolder,
    minLength,
    maxLength,
    required,
    readOnly,
    searchButtonContent,
    onChange,
    onSearchClick,
    onClearClick,
    flexClassName,
    onKeyPress,
    clearButtonContent,
    restoreCheckBoxState
  } = props;

  const searchref = useRef(null);

  function renderClear(event) {
    if (restoreCheckBoxState) {
      onClearClick(event);
      restoreCheckBoxState();
      searchref.current.value = "";
    } else {
      onClearClick(event);
      searchref.current.value = "";
    }
  }

  return (
    <div id="searchSubmit" data-testid="searchSubmit" className={`inlineFlex ${flexClassName}`}>
      <input
        data-testid="search-word-input"
        className={inputClassName}
        id={inputID}
        name={name}
        placeholder={placeHolder}
        minLength={minLength}
        maxLength={maxLength}
        required={required}
        readOnly={readOnly}
        onChange={(event) => {
          onChange(event);
        }}
        onKeyPress={(event) => {
          onKeyPress(event);
        }}
        ref={searchref}
      />
      <div style={{ display: "flex", float: "right" }}>
        <button
          data-testid="clear-text-btn"
          className={clearButtonClassName}
          id={buttonID}
          onClick={(event) => {
            renderClear(event);
          }}
        >
          {clearButtonContent}
        </button>
        <button
          data-testid="search-text-btn"
          className={searchButtonClassName}
          id={buttonID}
          onClick={(event) => {
            onSearchClick(event);
          }}
        >
          {searchButtonContent}
        </button>
      </div>
    </div>
  );
}
