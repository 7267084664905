import { IStudentPlacementDetails } from "components/ClassView/StudentPlacement.props";
import { UpdatePlacementProps } from "./StudentHomePage.props";

export function buildPlacementData(data: any, products: string[], placements: IStudentPlacementDetails[]) {
  let updatedPlacements: UpdatePlacementProps[] = [];

  placements.forEach((placement) => {
    if (placement.isEnabled) {
      updatedPlacements.push({
        active: placement.isEnabled,
        myPathSubjectStatus: getMyPathSubjectStatus(placement, data),
        subject: placement.subject,
        pathwayType: placement.pathwayType
      });
    }
  });

  if (hasMathAssessmentTurnedOn(data, products)) {
    addPlaceholderForAssessment(updatedPlacements, "Math");
  }

  if (hasReadingAssessmentTurnedOn(data, products)) {
    addPlaceholderForAssessment(updatedPlacements, "Reading");
  }

  return updatedPlacements.sort(bySubjectName);
}

// Helper functions

function addPlaceholderForAssessment(updatedPlacements: UpdatePlacementProps[], subject: string) {
  if (updatedPlacements.filter((item: UpdatePlacementProps) => item.subject === subject).length === 0) {
    updatedPlacements.push({
      active: false,  //NOTE: setting active to false so that restricted assessment times will show the "no work" message
      myPathSubjectStatus: true,
      subject: subject,
      pathwayType: null
    });
  }
}

function hasMathAssessmentTurnedOn(data: any, products: string[]) {
  return (
    data.mpngData.myPathMathStatus &&
    data.studentDetail[0].mypath_Assessment.math === "On" &&
    products.includes("MyPathMath")
  );
}
function hasReadingAssessmentTurnedOn(data: any, products: string[]) {
  return (
    data.mpngData.myPathReadingStatus &&
    data.studentDetail[0].mypath_Assessment.reading === "On" &&
    products.includes("MyPathReading")
  );
}

function getMyPathSubjectStatus(placement: IStudentPlacementDetails, data: any) {
  if (placement.subject === "Math") {
    return data.mpngData.myPathMathStatus;
  } else if (placement.subject === "Reading") {
    return data.mpngData.myPathReadingStatus;
  } else {
    return false;
  }
}

const bySubjectName = (a: UpdatePlacementProps, b: UpdatePlacementProps) => {
  let fa = a.subject.toLowerCase(),
    fb = b.subject.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};
