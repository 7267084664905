import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs, UxTabDropDown } from "@wne-spa/ux-component-library";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import EducatorList from "../UserLists/EducatorList";
import StudentTab from "../Students/StudentTab";
import ReduxProps from "../../Redux/Redux.props";
import { EducatorViewProps } from "./EducatorView.props";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
  };
}

function EUsers(props: EducatorViewProps): JSX.Element {
  const { dispatch } = props;
  const [tabKey, setTabKey] = useState("students");

  const [selectedStudentView, setSelectedStudentView] = useState("home");

  useEffect(() => {
    dispatch(updatePageTitle("- Users"));
    const fetchData = () => {
      const breadcrumb = { crumbs: [{ path: "/eusers", name: "Users" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    dispatch(updateActivityIndicator(false));
    fetchData();
  }, [dispatch]);

  const setPage = (pageName: string) => {
    setSelectedStudentView(pageName);
    setTabKey("students");
  };

  const onTabClick = (tab: string) => {
    if (tab === "students") {
      return;
    }
    setSelectedStudentView("home");
    setTabKey(tab);
  };

  const children = [
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={selectedStudentView} />,
      content: (
        <StudentTab
          isfrom="school"
          orgLevel="multi-school"
          showStudentSearch={true}
          selectedView={selectedStudentView}
          setSelectedView={setSelectedStudentView}
        />
      )
    },
    {
      eventKey: "educators",
      title: "Educators",
      content: tabKey === "educators" ? <EducatorList /> : null
    }
  ];

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            onTabClick(tab);
            localStorage.setItem("page", tab);
          }}
          children={children}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(EUsers);
