import React from "react";
import { UxModal, UxAction, UxShadesEnum, UxPositionEnum } from "@wne-spa/ux-component-library";

interface IUserListProps {
  callback: () => void;
  close: () => void;
  message: string;
  title: string;
  body?: JSX.Element;
}

export default function ConfirmActions({ close, callback, message, title, body }: IUserListProps): JSX.Element {
  return (
    <UxModal
      showModal={true}
      handleClose={() => {
        close();
      }}
      title={title}
      size="lg"
      scrollable={true}
      body={
        body ? (
          body
        ) : (
          <>
            <div className="mb-5">
              <span className="name-text" style={{ fontSize: "1.2rem", fontWeight: 400, lineHeight: 1.5 }}>
                {" "}
                {message}{" "}
              </span>
            </div>
          </>
        )
      }
      footer={[
        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Cancel"}
          className="px-2 py-1"
          priority={UxShadesEnum.LIGHT}
          callback={() => {
            close();
          }}
        />,
        <UxAction
          key={1}
          size="sm"
          enabled={true}
          text={"Yes"}
          className="px-4 py-1"
          priority={UxShadesEnum.DARK}
          callback={() => {
            callback();
          }}
        />
      ]}
      vPosition={UxPositionEnum.CENTER}
      hPosition={UxPositionEnum.CENTER}
      backdrop="static"
    />
  );
}
