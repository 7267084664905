import useGrade from "../hooks/useGrade";
import useTitle from "../hooks/usePageTitle";
import React, { useEffect, useState } from "react";
import { showTransitionScreensForGrade, transitionToCatPlayer } from "./assessmentUtils";
import { TTSSTProps } from "./StudentHomePage.props";
import MyPathAssessmentK5 from "../MyPathAssessmentTile/MyPathAssesssmentK5";
import MyPathAssessment612 from "../MyPathAssessmentTile/MyPathAssessment612";

interface StudentTransitionScreenProps {
  showTransitionScreen: boolean;
  setShowTransitionScreen: (boolean) => void;
  studentDetails: any[];
  getTTSST: TTSSTProps;
}

function returnToHome() {
  window.location.assign("/home");
}

export default function StudentTransitionScreen({
  showTransitionScreen,
  setShowTransitionScreen,
  studentDetails,
  getTTSST
}: Readonly<StudentTransitionScreenProps>) {
  const { isKto2, is3to5, is6to8, grade } = useGrade();
  const { updateTitle } = useTitle();
  const [isTestTimeAgain, setIsTestTimeAgain] = useState(true);

  function launchCAT(subjectName: string) {
    let screen = localStorage.getItem("stud:TransitionScreen") === "true";
    let testTime = localStorage.getItem("stud:testTimeAgain") === "true";
    if (screen) {
      setIsTestTimeAgain(testTime);
      setShowTransitionScreen(screen);
    } else {
      setShowTransitionScreen(screen);
      transitionToCatPlayer(grade, getTTSST, subjectName);
    }
  }

  useEffect(() => {
    if (!showTransitionScreen && studentDetails.length) {
      updateTitle(
        localStorage.getItem("stud:subName") === "Math"
          ? "MyPath Math Assessment | Imagine MyPath"
          : "MyPath Reading Assessment | Imagine MyPath"
      );
    }
  }, [showTransitionScreen, studentDetails]);

  //going back to  mypathassessment  screen from  transition screen.
  function goBackToMyPathAssessment() {
    setShowTransitionScreen(false);
  }

  if (!showTransitionScreen && studentDetails.length) {
    return (
      <div className="homepagecnt" id="homepageId">
        <div
          className={"buttonb68"}
          onClick={() => {
            returnToHome();
          }}
          tabIndex={0}
          role="button"
          aria-label="Previous Page"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              returnToHome();
            } else {
              //do nothing
            }
          }}
        >
          <i
            className="fa fa-arrow-left insidebb"
            style={{
              color: "#0C0477",
              paddingLeft: "14px",
              paddingTop: "12px",
              fontSize: "28px"
            }}
          ></i>
        </div>

        <div className="mypath">
          {isKto2 || is3to5 ? (
            <MyPathAssessmentK5 launchCAT={launchCAT} />
          ) : (
            <MyPathAssessment612 launchCAT={launchCAT} />
          )}
        </div>
      </div>
    );
  } else if (showTransitionScreen) {
    return showTransitionScreensForGrade(
      isKto2,
      is3to5,
      is6to8,
      isTestTimeAgain,
      grade,
      getTTSST,
      goBackToMyPathAssessment
    );
  } else {
    return null;
  }
}


