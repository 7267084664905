/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./manipulateStudentPlacement.scss";
import "../BulkImports/BulkImports.scss";
import WNECreateModal from "../modals/UserCreateModal/WNECreateModal";
import { ManipulateStudentPlacementProps } from "./ManipulateStudentPlacement.props";
import { PathwayTypeBadge } from "@wne/mpng-components";

export default function ManipulateStudentPlacement({
  showModal,
  setGrade,
  data,
  closeModal,
  updatePlacement,
  gradesData
}: ManipulateStudentPlacementProps) {
  const [selectedGrade, setSelectedGrade] = useState("Select Grade");
  const [grades, setGrades] = useState([...gradesData]);

  useEffect(() => {
    setGrades([...gradesData]);
  }, [data]);

  if (showModal) {
    localStorage.setItem("page", "studentsPlacementEditPlacementForm");
  }

  function onUpdate() {
    setSelectedGrade("Select Grade");
    updatePlacement();
  }

  const gradesLengthData = () => {
    if (grades.length > 0) {
      return grades;
    } else {
      return gradesData;
    }
  };

  function renderChildForSubLevelData() {
    return (
      <div className="manipContainerForSubDomain">
        <label className="manipName">{`${data.firstName} ${data.lastName}`}</label>
        <div className="manipHeaderHolder">
          <label className="manipHeaderForSub">Subject</label>
          <label className="manipHeaderForSub">Placement Grade</label>
        </div>
        <div className="manipData">
          <label className="manipValue firstHeader">
            {data.subject}
            {data.pathwayType && <PathwayTypeBadge pathwayType={data.pathwayType} />}
          </label>
          <NavDropdown
            id="manipdropdownHolderForSubLevel"
            title={selectedGrade === "Select Grade" ? selectedGrade : `Grade ${selectedGrade}`}
          >
            {gradesData.length > 0 ? (
              gradesLengthData().map((row: string, _index: number) => {
                return (
                  <NavDropdown.Item
                    className="manipDropdown"
                    onClick={() => {
                      setSelectedGrade(row);
                      setGrade(row);
                    }}
                  >
                    {`Grade ${row}`}
                  </NavDropdown.Item>
                );
              })
            ) : (
              <NavDropdown.Item className="manipDropdownSpin">
                <i className="fa fa-spinner spin"></i>
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </div>
      </div>
    );
  }

  return (
    <WNECreateModal
      className="manipulateStudentPlacementModal"
      showModal={showModal}
      children={renderChildForSubLevelData()}
      create={() => {
        onUpdate();
      }}
      close={() => {
        closeModal();
        setSelectedGrade("Select Grade");
      }}
      title={"Assign Placement"}
      yes={"Assign"}
      no="Cancel"
      enabledYes={selectedGrade !== "Select Grade" ? true : false}
    />
  );
}
