/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateSchoolGuids,
  updateStudentAndClassFilterURL,
  updateClassViewTab,
  updateTabName,
  updateIsOrgRegistrationOwned
} from "../../Redux/Action";
import "./ClassCards.scss";
import ClassCards from "../EducatorView/ClassCards";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getDaDetails: state.getDaDetails,
  };
}

interface TCProps {
  dispatch: AppDispatch;
  isfrom?: string;
}

interface Props extends TCProps, ReduxProps {}

function TeacherClasses(props: Props) {
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getDaDetails, isfrom } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AdminService.getParentsWithRootWithGuid({
          rid: id,
          cid: id
        });
        if (response.status === 200) {
          handleSuccessResponse(response.data);
        } else {
          updateDetails();
        }
      } catch (err) {
        //do nothing
      }
    };
    if (!id.includes("_")) {
      fetchData();
    } else {
      updateDetails();
    }
  }, [dispatch, id, getDaDetails]);

  useEffect(() => {
    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
  }, []);

  function updateDetails() {
    dispatch(updatePageTitle(`- Classes`));
    const breadcrumb = {
      crumbs: [{ path: `/teacherclasses/${getDaDetails.id}`, name: "Classes" }]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
  }

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    if (getDaDetails.type === 1) {
      dispatch(updatePageTitle("- Classes"));
      const breadcrumb = {
        crumbs: [{ path: `/teacherclasses/${getDaDetails.id}`, name: "Classes" }]
      };
      let schoolGUID = [];
      schoolGUID = [...schoolGUID, details];
      dispatch(updateBreadCrumbs(breadcrumb));
      dispatch(updateSchoolGuids(schoolGUID));
    } else {
      //@ts-ignore
      dispatch(updatePageTitle(`- Schools - ${details.name}`));
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/teacherschools/${getDaDetails.id}`, name: "Schools" },
          { path: "/daclasscards", name: details.name }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    dispatch(updateActivityIndicator(false));
  }

  useEffect(() => {
    let apiObj = { guid: id.includes("_") ? id.split("_")[0] : id };
    (async () => {
      const response = await AdminService.getGUIDSWithGuid(apiObj);
      if (response.status === 200) {
        await responseGUIDS(response.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  function handleClick(cid: number) {
    dispatch(updateClassViewTab("classsummary"));
    dispatch(updateTabName("classsummary"));
    navigate(`/classview/${cid}`);
  }

  return (
    <>
      <ClassCards handleClick={handleClick} isfrom={isfrom} />
    </>
  );
}

export default connect(mapStateToProps)(TeacherClasses);
