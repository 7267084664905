import React from "react";
import { UxShadesEnum, UxOrientationEnum, UxDirectionEnum, UxNavbar } from "@wne-spa/ux-component-library";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import "../HeaderMenu/HeaderMenu.scss";

const HeaderNavbar = () => {
  const headerChildren = [
    {
      item: <HeaderMenu />,
      direction: UxDirectionEnum.END
    }
  ];

  return (
    <UxNavbar
      color={UxShadesEnum.LIGHT}
      orientation={UxOrientationEnum.HORIZONTAL}
      children={headerChildren}
      className="bg-white zeeIndex"
      asDiv={true}
    />
  );
};

export default HeaderNavbar;
