/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import "./Schools.scss";
import {
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  TimesIcon
} from "@wne-spa/ux-component-library";
import { ISchools } from "./IShoolsList";
import {
  updateActivityIndicator,
  updatePageTitle,
  updatePaginationControllerState,
  updateBreadCrumbs
} from "../../Redux/Action";
import { paginationControllers } from "../../helpers/configs";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserId: state.getUserId,
    getPaginationControllerState: state.getPaginationControllerState
  };
}
type schoolsProps = {
  dispatch: AppDispatch;
};

interface Props extends schoolsProps, ReduxProps {}

function Schools(props: Props) {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const { dispatch, getUserId, getPaginationControllerState } = props;
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState("");
  const columnNames = ["School Name", "Parent Name", "Customer Name", "NetSuite ID", "Status"];
  const rowMapper = [["name"], "parentName", "customerName", "netsuiteId", "isActive"];
  const [pageNo, setPageNo] = useState(1);

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[6] = {
      paginationPageName: "Institutions",
      paginationCount: args
    };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 7, args).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          searchSchools(1);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }
  useEffect(() => {
    localStorage.setItem("page", "schools");
  }, []);
  useEffect(() => {
    dispatch(updatePageTitle("- Schools"));
    const fetchData = () => {
      const breadcrumb = { crumbs: [{ path: "/schools", name: "Schools" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
    };
    dispatch(updateActivityIndicator(false));
    fetchData();
  }, [dispatch]);

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleSearchButtonClick() {
    if (searchText !== "") {
      searchSchools(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchSchools(1);
      setSearchResult(true);
      setShowTable(true);
      setPageNo(1);
    }
  }

  type viewChildprops = {
    id: number;
    name: string;
    parentId: number;
    parentName: string;
    customerName: string;
    netsuiteId: string;
    isActive: boolean;
    childDepth: number;
    organizationGuid: string;
    orgGUID: string;
    type: string;
    parentGuid: string;
    state: string;
  };

  function viewSchool(row: viewChildprops) {
    if (row.childDepth === 0) {
      const breadcrumb = { crumbs: [{ path: "/", name: "" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
      navigate(`/school/${row.orgGUID}`);
    } else {
      const breadcrumb = { crumbs: [{ path: "/", name: "" }] };
      dispatch(updateBreadCrumbs(breadcrumb));
      navigate(`/institutions/${row.orgGUID}`);
    }
  }

  async function searchSchools(pagenumber: number) {
    try {
      dispatch(updateActivityIndicator(true));
      setSearchResult(true);
      const jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[6].paginationCount,
        SearchText: searchText,
        SortBy: "OrganizationTitle",
        SortOrder: "ASC"
      };
      AdminService.getSchools(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function gotResponse(responsedata: Response) {
    let allData = await responsedata.json();
    if (allData.size === 0) {
      setEmptyTableText("No results containing your search were found");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setData(allData);
    }
    dispatch(updateActivityIndicator(false));
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResult) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResult) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResult) {
      setSearchText("");
    } else if (searchText !== "" && searchResult) {
      setSearchText("");
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      setSearchResult(false);
      setShowTable(false);
      setPageNo(1);
    }
  }

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    searchSchools(value);
    setPageNo(value);
  };

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }
  const searchResultInputClassName = () => {
    if (showTable === false) {
      if (!searchResult) {
        return "schoolFullInputTextMiddle";
      } else {
        return "schoolInputTextMiddle";
      }
    } else if (searchText === "") {
      return "schoolFullInputTextTop";
    } else {
      return "schoolInputTextTop";
    }
  };

  return (
    <>
      <div className="schools">
        <div className={!showTable ? "searchSchoolMiddle" : "searchSchoolTop"}>
          {!showTable ? <span className="searchHeading">Search For Schools</span> : null}
          <UxSearchInput
            inputClassName={searchResultInputClassName()}
            flexClassName={showTable === false ? "schoolFlexHolderMiddle" : "schoolFlexHolderTop"}
            placeHolder="Search School Name, Customer Name or NetSuite ID"
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="searchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="clearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
          />
        </div>
        {showTable ? (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "scrollableSchoolTable"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as ISchools).values}
            showColumnCheckbox={false}
            showKebabMenu={false}
            showNameKebabMenu={false}
            viewChild={viewSchool}
            emptyTableText={emptyTableText}
            checkboxRef="loginName"
            selected={[]}
            setSelected={[]}
          />
        ) : null}
        {!showTable ? null : (
          <div className="fixedpagination">
            <UxPaginate
              data={data}
              page={pageNo}
              paginationSize={getPaginationControllerState[6].paginationCount}
              className={classes.root}
              handleChange={handleChange}
            />
            {data.size > 50 && (
              <UxPaginationController
                rowsList={paginationControllers}
                setRowsCount={getPaginationControllerState[6].paginationCount}
                callback={callback}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Schools);
