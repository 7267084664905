import React, { useEffect } from "react";
import { logout } from "../../../authService/AuthService";
import { persistor } from "../../../Redux/Store";

function Logout() {
  useEffect(() => {
    clearStorage();

    setTimeout(() => {
      logout();
    }, 1000);

    return () => {
      clearStorage();
    };
  }, []);

  const clearStorage = async () => {
    persistor.pause();
    await persistor.purge();
    localStorage.clear();
  };

  return <div></div>;
}

export default Logout;
