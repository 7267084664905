import React from "react";
import "./index.scss";

interface Props {
  showBulkModal: boolean;
  children?: JSX.Element;
  width?: string;
  height?: string;
}

export default function BulkImportsModal({ showBulkModal, children, width, height }: Props) {
  return showBulkModal ? (
    <div className="bulkOverlay">
      <div
        className="bulkMiddleLayer"
        style={{
          width: width ? width : "750px",
          height: height ? height : "500px"
        }}
      >
        {children}
      </div>
    </div>
  ) : null;
}
