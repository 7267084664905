import { UxTabDropDown } from "@wne-spa/ux-component-library";
import ReduxProps from "Redux/Redux.props";
import React, { useState } from "react";
import { connect } from "react-redux";

type DropdownTabProps = {
  onNavClick: (pageName: string) => void;
};

interface Props extends ReduxProps, DropdownTabProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getAssessmentTab: state.getAssessmentTab
  };
}

function AssessmentDropdownTab(props: Readonly<Props>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onNavClick = (pageName: string) => {
    setIsDropdownOpen(false);
    props.onNavClick(pageName);
  };

  return (
    <UxTabDropDown
      title="Assessments"
      onNavClick={onNavClick}
      onDropDownClick={() => {
        setIsDropdownOpen((val) => !val);
      }}
      isOpen={isDropdownOpen}
      options={[
        { text: "Assessment Settings", value: "settings" },
        { text: "Assessment Scheduling", value: "schedule" }
      ]}
      activeOption={props.getAssessmentTab}
    />
  );
}

export default connect(mapStateToProps)(AssessmentDropdownTab);
