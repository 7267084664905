import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./log-slider-action.scss";
import FilterIcon from "../../assets/icons/icon_filter.svg";
import TimesIcon from "../../assets/icons/icon_times.svg";

interface ILogSliderAction {
  filterText: string;
  activeFilters: string[];
  filterStates: FilterStateProps;
  setFilterState: { current: FilterStateProps };
  setActiveFilters: { current: string[] };
  minCount: number;
  multiFilterDropDown: boolean;
  setMultiFilterDropDown: (args: boolean) => void;
  setSliderLeft: (args: string) => void;
  left: string;
  setBackgroundVisibility: (args: boolean) => void;
  setOpacity: (args: number) => void;
  onChecked: () => void;
  textMessage: { current: string };
  setStateName: (args: object) => void;
  stateName: {
    "Created Date": string[];
    "Import Type": string[];
    Status: string[];
  };
  stateCallback?: (args) => void;
  dropTypes: {
    Students?: string;
    Educators?: string;
    Classes?: string;
    Today?: string;
    Yesterday?: string;
    "Last 7 Days"?: string;
    "Last 28 Days"?: string;
    "Last 90 Days"?: string;
    Custom?: string;
    New?: string;
    Processing?: string;
    "Failed-Retrying"?: string;
    Completed?: string;
    "Completed w/ Errors"?: string;
    Terminated?: string;
    "Import Disabled"?: string;
    "Waiting-User Ingestion"?: string;
    "System Error"?: string;
  };
}
type FilterStateProps = {
  ""?: boolean | string;
  Classes: boolean | string;
  Completed: boolean | string;
  "Completed w/ Errors": boolean | string;
  Custom: boolean | string;
  Educators: boolean | string;
  "Failed-Retrying": boolean | string;
  "Import Disabled": boolean | string;
  Last7Days: boolean | string;
  Last28Days: boolean | string;
  Last90Days: boolean | string;
  New: boolean | string;
  Processing: boolean;
  Students: boolean | string;
  "System Error": boolean | string;
  Terminated: boolean | string;
  Today: boolean | string;
  "Waiting-User Ingestion": boolean | string;
  Yesterday: boolean | string;
};
export default function UxLogSliderAction(props: ILogSliderAction) {
  const {
    filterText,
    activeFilters,
    setActiveFilters,
    filterStates,
    setFilterState,
    minCount,
    multiFilterDropDown,
    setMultiFilterDropDown,
    setSliderLeft,
    left,
    setBackgroundVisibility,
    setOpacity,
    onChecked,
    textMessage,
    setStateName,
    stateName,
    stateCallback,
    dropTypes
  } = props;

  function sliderleft() {
    setMultiFilterDropDown(false);
    if (left !== "-330px") {
      setSliderLeft("-330px");
      setTimeout(() => {
        setBackgroundVisibility(false);
      }, 800);
      setOpacity(0);
    } else {
      setBackgroundVisibility(true);
      setTimeout(() => {
        setOpacity(1);
      }, 10);
      setSliderLeft("90px");
    }
  }

  function sliceFilter(data: string) {
    const ftrs = activeFilters.filter(function (item) {
      return item !== data;
    });

    //@ts-ignore
    const dropnames = stateName[dropTypes[data]].filter(function (item: string) {
      return item !== data;
    });
    setActiveFilters.current = ftrs;
    //@ts-ignore
    setStateName({ ...stateName, [dropTypes[data]]: dropnames });
  }

  function onUncheck(row: string) {
    if (filterStates[row] === false) {
      setActiveFilters.current = [...activeFilters, row];
      setFilterState.current = { ...filterStates, [row]: true };
      //@ts-ignore
      stateName[dropTypes[row]] === undefined
        ? //@ts-ignore
          setStateName({ ...stateName, [dropTypes[row]]: [row] })
        : //@ts-ignore
          setStateName({
            ...stateName,
            [dropTypes[row]]: [...stateName[dropTypes[row]], row]
          });
      setFilterState.current = { ...filterStates, [row]: true };
      onChecked();
    } else {
      sliceFilter(row);
      setFilterState.current = { ...filterStates, [row]: false };
      onChecked();
    }
    stateCallback && stateCallback([row, !filterStates[row]]);
  }

  return (
    <div data-testid="log-slider-action-wrapper">
      <div className={activeFilters.length >= minCount ? "multiFilterActive" : "multiFilter"}>
        <div
          className={activeFilters.length >= minCount ? "iconTextActive" : "iconText"}
          onClick={() => {
            sliderleft();
          }}
          data-testid="filter-text-wrapper"
        >
          <img src={FilterIcon} alt="filtericon" width="16px" height="17px" />
          <label className={activeFilters.length >= minCount ? "multiFilterTextActive" : "multiFilterText"}>
            {filterText}
          </label>
        </div>
        {activeFilters.length >= minCount ? (
          <div className={activeFilters.length >= minCount ? "countHolderActive" : "counterHolderActive"}>
            <label
              className="filterCount"
              onMouseOver={() => {
                setMultiFilterDropDown(true);
                setTimeout(() => {
                  setMultiFilterDropDown(false);
                }, 3000);
              }}
              data-testid="filter-count"
            >
              {activeFilters.length}
            </label>
          </div>
        ) : null}
      </div>
      {multiFilterDropDown ? (
        <div className="listAbsolute">
          {activeFilters.map((row, i) => {
            return (
              <NavDropdown.Item className="multifilterdropdownText">
                {`${dropTypes[row]}: ${row}`}{" "}
                <div
                  onClick={() => {
                    onUncheck(row);
                    setMultiFilterDropDown(!multiFilterDropDown);
                    textMessage.current = "UxLogFilterSlider";
                  }}
                  data-testid={`multifilter-dropdown-${i}`}
                >
                  <img
                    src={TimesIcon}
                    alt="timesicon"
                    width="16px"
                    height="16px"
                    className="cursorImg"
                    style={{ marginTop: "4px" }}
                  />
                </div>
              </NavDropdown.Item>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
