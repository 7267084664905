import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateAssessmentTab,
  updateFromSideBarAssessment,
  updateLSBReport,
  updateLevel,
  updateSchoolGuids,
  updateStudentIds,
  updateStudentsSubtab,
  updateIsOrgRegistrationOwned,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import "./DataList.scss";
import SchoolList from "../EducatorView/SchoolList";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { Subject } from "rxjs";
import useOrganizations from "../hooks/useOrganizations";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getBreadcrumbs: state.getBreadCrumbsData,
    updatePreviousURL: state.updatePreviousURL,
    updateFromSideBarAssessment: state.updateFromSideBarAssessment,
    getClassFilter: state.getClassFilter,
    getAssessmentTab: state.getAssessmentTab,
    getCurrentUserDetails: state.getCurrentUserDetails
  };
}
interface SASchoolsProps {
  dispatch: AppDispatch;
  isFrom?: string;
  MyPathLevelSubject?: Subject<object>;
  BenchmarkGrowthReportBreadcrumbSubject?: Subject<object>;
}

interface Props extends SASchoolsProps, ReduxProps {}
interface RowItem {
  id: number;
  name: string;
  parentId: number;
  parentName: string;
  customerName: string;
  netsuiteId: string;
  isActive: boolean;
  childDepth: number;
  organizationGuid: string;
  orgGUID: string;
  type: string;
  parentGuid: string;
  state: string;
}

function SASchools(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, isFrom, getBreadCrumbsData, getCurrentUserDetails } = props;
  const [data, setData] = useState([]);
  const allSchools = useOrganizations(id);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFrom === "Assessment") {
      dispatch(updateAssessmentTab("home"));
      dispatch(updateFromSideBarAssessment(true));
    }
    if (allSchools !== null) {
      dispatch(updateSchoolGuids(allSchools));
      setData(allSchools);
    }
    dispatch(updateActivityIndicator(false));
  }, [dispatch, allSchools, isFrom]);

  useEffect(() => {
    dispatch(updateStudentIds([]));
    dispatch(updateStudentsSubtab("home"));
  }, []);

  useEffect(() => {
    if (isFrom === "reports") {
      dispatch(updatePageTitle(`- Reports`));
    } else if (isFrom === "Assessment") {
      dispatch(updatePageTitle(`- Assessments`));
    } else {
      dispatch(updatePageTitle(`- Schools`));
    }
    const breadcrumb =
      isFrom === "reports"
        ? {
            crumbs: [{ path: `/saschools/reports/${id}`, name: "Reports" }]
          }
        : isFrom === "Assessment"
        ? {
            crumbs: [{ path: `saassessmentschools/:id`, name: "Assessments" }]
          }
        : {
            crumbs: [{ path: `/saschools/${id}`, name: "Schools" }]
          };
    if (!getBreadCrumbsData?.crumbs?.some((e) => e?.path?.split("/")[1] === "benchmark-growth-reports")) {
      dispatch(updateBreadCrumbs(breadcrumb));
    }
  }, [isFrom]);

  useEffect(() => {
    let apiObj = { guid: id.includes("_") ? id.split("_")[0] : id };
    (async () => {
      await AdminService.getGUIDSWithGuid(apiObj).then(async ({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          await responseGUIDS(data);
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  function handleClick(row: RowItem) {
    const currentOrg = getCurrentUserDetails.organizations.find(org => org.orgGuid === row.orgGUID);
    dispatch(updateCurrentOrganizationDetails(currentOrg));
    if (isFrom === "reports") {
      dispatch(updateLSBReport(true));
      dispatch(updateLevel("school"));

      navigate(`/reportcards/${row.orgGUID}/${row.name}`);
    } else if (isFrom === "Assessment") {
      navigate(`/assessments/${row.orgGUID}`);
    } else {
      navigate(`/saclasslist/${row.orgGUID}`);
    }
  }

  return (
    <div className="class-nav-tabs">
      <SchoolList handleClick={handleClick} data={data} />
    </div>
  );
}

export default connect(mapStateToProps)(SASchools);
