import React from "react";
import { mathk2png, myPathAssessmentImg, readingk2png } from "@wne-spa/ux-component-library";
import useGrade from "../hooks/useGrade";

export default function MyPathAssessmentK5({ launchCAT }: Readonly<MPAProps>) {
  const subjectName = localStorage.getItem("stud:subName");
  const { isKto2 } = useGrade();
  return (
    <div
      className={isKto2 ? "circle" : "circle35"}
      style={{ flexDirection: "column" }}
      onClick={() => {
        launchCAT(subjectName);
      }}
      tabIndex={0}
      role="button"
      aria-label={""}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          launchCAT(subjectName);
        } else {
          //do nothing
        }
      }}
    >
      <div className="assessmentcircle">
        <div>
          <img
            className="imgsize"
            src={subjectName === "Math" ? mathk2png : readingk2png}
            alt={subjectName === "Math" ? "Math Image" : "Reading Image"}
          />
        </div>

        <div className="assessmentsmallcircle">
          <img src={`${myPathAssessmentImg}`} alt="" style={{ width: "36px", height: "32px" }}></img>
        </div>
      </div>
      <div className="assesmenttext">{`MyPath  ${subjectName} Assessment`}</div>
      <div className={isKto2 ? "goButtonFork2" : "goButton35"}>
        <div
          className="gotext"
          onClick={() => {
            launchCAT(subjectName);
          }}
        >
          GO
        </div>
        <div className="arrowmargin">
          <i className="fas fa-arrow-right arrowright"></i>
        </div>
      </div>
    </div>
  );
}
