/* eslint-disable array-callback-return */
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  TimesIcon,
  UxAction,
  UxActivityIndicator,
  UXCSVExport,
  UxFilterSlider,
  UxIcon,
  UxIconEnum,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  UxSearchInput,
  UxShadesEnum,
  UxSizeEnum,
  UxSliderAction,
  UxTitleBar
} from "@wne-spa/ux-component-library";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { paginationControllers } from "../../helpers/configs";
import HelpIcon from "../../images/help_search_icon.png";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updateCicEnable,
  updateCreateStudent,
  updateCurrentSchoolGuid,
  updateCurrentSchoolID,
  updatePaginationControllerState,
  updateSchoolGUID,
  updateStudentListSort,
  updateStudentsFilter
} from "../../Redux/Action";
import ReduxProps, { SelectedStudentProps } from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { StudentListWithGuidProps } from "../../services/helpers/user.service.props";
import { UserService } from "../../services/helpers/user.services";
import HiddenForm, { BeginImpersonation } from "../Auth/ImpersonatorHiddenForm";
import BulkImportsModal from "../modals/bulk-imports";
import {
  CustomStudentOptionCallbackProps,
  FilterStatesProps,
  StudentFetchCicSettingsProps,
  StudentGradeLevelDataProps,
  StudentProps
} from "./Students.props";
import "./students.scss";

interface Props extends ReduxProps, StudentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getStudentsFilter: state.getStudentsFilter,
    getStudentListSort: state.getStudentListSort,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getUserRole: state.getUserRole,
    getCreateStudent: state.getCreateStudent,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}

function Student(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  const navigate = useNavigate();
  const [stateName, setStateName] = useState({});
  const {
    isfrom,
    dispatch,
    isteacher,
    getBreadCrumbsData,
    getDaDetails,
    getUserRoleId,
    getStudentsFilter,
    getStudentListSort,
    getUserId,
    getTabChange,
    getPaginationControllerState,
    getUserRole,
    getCreateStudent,
    getIsOrgHasRegistrationOwned
  } = props;
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const [emptyTableText, setEmptyTableText] = useState<JSX.Element | string>();
  const [searchText, setSearchText] = useState("");
  const searchResultRef = useRef(false);
  const [icon, setIcon] = useState(false);
  const sortBy = useRef(getStudentListSort.name);
  const sortOrder = useRef(getStudentListSort.val);
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [loading, setLoading] = useState(true);
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const [kebabMenuOption, setKebabMenuOption] = React.useState<string[]>([]);
  const [showImpersonationModal, setShowImpersonationModal] = useState(false);
  const [showHelpIcon, setShowHelpIcon] = useState(true);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }
  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();
  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();
  let columnNames = ["Student Name", "Username", "External ID", "Student ID", "Grade Level", "Status"];
  columnNames = columnNames.filter((column) =>
    getIsOrgHasRegistrationOwned ? "External ID" !== column : "Student ID" !== column
  );

  const rowMapper = [["lastName", "firstName"], "loginName", "districtAssignedId", "gradeLevelName", "isEnabled"];

  let columnNamesForMultiple = [
    "Student Name",
    "Username",
    "School",
    "External ID",
    "Student ID",
    "Grade Level",
    "Status"
  ];
  columnNamesForMultiple = columnNamesForMultiple.filter((column) =>
    getIsOrgHasRegistrationOwned ? "External ID" !== column : "Student ID" !== column
  );

  const rowMapperForMultiple = [
    ["lastName", "firstName"],
    "loginName",
    "schoolName",
    "districtAssignedId",
    "gradeLevelName",
    "isEnabled"
  ];
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [opacity, setOpacity] = useState(0);
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const textMessage = React.useRef("none");
  const [studentNameBool, setStudentNameBool] = useState<boolean | null>(null);
  const [usernameBool, setUsernameBool] = useState<boolean | null>(null);
  const [gradeLevelBool, setGradeLevelBool] = useState<boolean | null>(null);
  const [statusBool, setStatusBool] = useState<boolean | null>(null);
  const [schoolBool, setSchoolBool] = useState<boolean | null>(null);
  const [studentName, setStudentName] = useState("");
  const [isMigratedID, setIsMigratedID] = useState("");
  const [showErrorModal, setShowErrorModal] = useState<{
    show: boolean;
    errorType: "Inactive" | "NoLogIn" | "IsSynced" | "IsSyncedBulk";
  } | null>(null);
  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );
  const [csvData, setCSVData] = useState([]);
  const headersForCsv = [
    {
      label: "School Id",
      key: "schoolId"
    },
    {
      label: "Student Id",
      key: "studentId"
    },
    {
      label: "Student Last Name",
      key: "lastName"
    },
    {
      label: "Student First Name",
      key: "firstName"
    },
    {
      label: "Username",
      key: "userName"
    },
    {
      label: "External ID",
      key: "externalId"
    },
    {
      label: "Grade Level",
      key: "gradeLevel"
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  const typesHeader = ["Grade Level"];
  const [types, setTypes] = useState({
    Active: "Status",
    Inactive: "Status"
  });
  const [typesList, setTypesList] = useState({ "Grade Level": [] });
  const filterStates = useRef<FilterStatesProps>({
    Active: false,
    Inactive: false
  });
  const filterStatesToReset = useRef<FilterStatesProps>({
    Active: false,
    Inactive: false
  });
  const idMapper = useRef<Object>({});
  const [CSVFilename, setCSVFilename] = useState("");
  if (activeFilters.current.includes("Active")) {
    const index = activeFilters.current.indexOf("Active");
    if (index !== -1) {
      activeFilters.current.splice(index, 1);
    }
  }
  interface IStudentsList {
    firstName: string;
    lastName: string;
    loginName: string;
    eid: number;
    glevel: number;
    isEnabled: boolean;
  }

  interface IPage {
    size: number;
    page: number;
    pageSize: number;
    values: IStudentsList[];
  }

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = filterStatesToReset.current;
    setFilterCountClick(false);
    setStateName({});
    setPageNo(1);
    getStudents(1, "");
  }

  useEffect(() => {
    if (getUserRoleId === 4) {
      if (getDaDetails.type === 1) {
        setCSVFilename(getDaDetails.name);
      } else {
        setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
      }
    } else if (getUserRoleId === 5) {
      setCSVFilename(getDaDetails.name);
    } else {
      setCSVFilename(getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
    }
  });

  useEffect(() => {
    if (getCreateStudent) {
      studentCreated();
      dispatch(updateCreateStudent(false));
    }
  }, []);

  useEffect(() => {
    dispatch(updateActivityIndicator(false));
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }

    fetchspanishids();
  }, [getTabChange]);

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    getStudentListSortFunc();

    setActivityIndicator(true);

    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      setKebabMenuOption(["Impersonate"]);
    } else {
      setKebabMenuOption(["Deactivate", "Activate"]);
    }
    getGradeLevelData();
    localStorage.setItem("page", "studentsRoster");
    // eslint-disable-next-line
  }, []);

  const getStudentListSortFunc = () => {
    if (getStudentListSort.val === "desc" || getStudentListSort.val === "DESC") {
      switch (getStudentListSort.name) {
        case "lastname":
          setStudentNameBool(true);
          break;
        case "loginname":
          setUsernameBool(true);
          break;
        case "gradelevelname":
          setGradeLevelBool(true);
          break;
        case "Status":
          setStatusBool(true);
          break;
        default:
          setSchoolBool(true);
      }
    } else if (getStudentListSort.val === "asc" || getStudentListSort.val === "ASC") {
      switch (getStudentListSort.name) {
        case "lastname":
          setStudentNameBool(false);
          break;
        case "loginname":
          setUsernameBool(false);
          break;
        case "gradelevelname":
          setGradeLevelBool(false);
          break;
        case "Status":
          setStatusBool(false);
          break;
        default:
          setSchoolBool(false);
      }
    }
  };

  const fetchspanishids = async () => {
    let apiObj = { guid: id.split("_")[0] };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        // do nothing
      }
    });
  };

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: StudentFetchCicSettingsProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.text();
    let i = 0;
    let details1 = JSON.parse(details);
    let levelId = { type: "Custom", guid: details1.orgGUID.toString() };

    if (details1.parents != null) {
      let parentid = details1.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };

    fetchCicsettings(json);
  }

  const fetchCicsettings = async (json: StudentFetchCicSettingsProps) => {
    try {
      await AdminService.getCicSettingsWithGuid(json).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotCicSettingsResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      // do nothing
    }
  };
  async function gotCicSettingsResponse(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    dispatch(updateCicEnable(details.cicImportEnabled));
    localStorage.setItem("SpanishMathEnable", details.spanishLanguageEnabled);
    localStorage.setItem("SpanishScienceEnable", details.spanishLanguageScienceEnabled);
  }

  const fetchData = async () => {
    try {
      let urltype = `single`;
      let FilterGrades = [];
      activeFilters.current = getStudentsFilter;
      for (let i in activeFilters.current) {
        if (activeFilters.current[i] !== "Active" && activeFilters.current[i] !== "Inactive") {
          //@ts-ignore
          FilterGrades.push(idMapper.current[activeFilters.current[i]]);
        }
      }
      let jsonObj: StudentListWithGuidProps = {
        Page: 1,
        PageSize: getPaginationControllerState[0].paginationCount,
        OrgId: 0,
        FilterGradeLevels: FilterGrades.join(),
        FilterStatus: "true",
        SearchText: "",
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        organizationGuid: id
      };
      dispatch(updateSchoolGUID(id));
      if (getUserRoleId === 5 && getDaDetails.type === 2) {
        urltype = `mulitple`;
        jsonObj = {
          Page: 1,
          PageSize: getPaginationControllerState[0].paginationCount,
          FilterGradeLevels: FilterGrades.join(),
          FilterStatus: "true",
          SearchText: "",
          SchoolGuids: id.split("_").toString(),
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      }

      getStudentListApiCall(jsonObj, urltype);
    } catch (err) {
      //do nothing
      setActivityIndicator(false);
    }
  };

  const getStudentListApiCall = async (jsonObj: StudentListWithGuidProps, urltype: string) => {
    if (urltype === "single") {
      const getStudentList = async () => {
        await UserService.getStudentRosterSchoollevelCSVData(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          }
        );
        await UserService.getStudentListWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data);
            } else {
              setActivityIndicator(false);
            }
          }
        );
      };
      setTimeout(getStudentList, 1000);
    } else {
      const getStudentList = async () => {
        await UserService.getStudentRosterSchoollevelFORMSCSVData(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          }
        );
        await UserService.getStudentListforTeacherWithGuid(jsonObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotResponse(data);
            } else {
              setActivityIndicator(false);
            }
          }
        );
      };
      setTimeout(getStudentList, 1000);
    }
  };

  async function gotCSVData(responsedata: Response) {
    const csvData = JSON.parse(await responsedata.text());
    setCSVData(csvData);
  }

  const getGradeLevelData = async () => {
    try {
      await UserService.getGradeLevels().then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setGradeLevelData(data);
        } else {
          setActivityIndicator(false);
        }
      });
    } catch (err) {
      //do nothing
      setActivityIndicator(false);
    }
    if (isfrom === "school") {
      let gLevel: string[] = [];
      let statesShow = {};
      activeFilters.current = getStudentsFilter;
      getStudentsFilter.forEach((row: string) => {
        if (row === "Active") {
          statesShow = { ...statesShow, Status: ["Active"] };
        } else if (row === "Inactive") {
          statesShow = { ...statesShow, Status: ["Inactive"] };
        } else {
          filterStates.current = { ...filterStates.current, [row]: true };
          gLevel.push(row);
        }
      });
      if (gLevel.length > 1) {
        statesShow = { ...statesShow, "Grade Level": gLevel };
      } else if (gLevel.length === 1) {
        statesShow = { ...statesShow, "Grade Level": [gLevel[0]] };
      }
      setStateName(statesShow);
    }
  };

  async function setGradeLevelData(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    details.map((row: StudentGradeLevelDataProps) => {
      //@ts-ignore
      if (
        row.gradeLevelName === "1" ||
        row.gradeLevelName === "2" ||
        row.gradeLevelName === "3" ||
        row.gradeLevelName === "4" ||
        row.gradeLevelName === "5" ||
        row.gradeLevelName === "6" ||
        row.gradeLevelName === "7" ||
        row.gradeLevelName === "8" ||
        row.gradeLevelName === "9"
      ) {
        //@ts-ignore
        setTypes(Object.assign(types, { [`0${row.gradeLevelName}`]: row.type }));
        //@ts-ignore
        setTypesList(
          Object.assign(
            typesList,
            //@ts-ignore
            typesList["Grade Level"].push(`0${row.gradeLevelName}`)
          )
        );
        //@ts-ignore
        if (getStudentsFilter.indexOf(`0${row.gradeLevelName}`) !== -1) {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [`0${row.gradeLevelName}`]: true
          });
        } else {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [`0${row.gradeLevelName}`]: false
          });
        }

        filterStatesToReset.current = Object.assign(filterStatesToReset.current, {
          [`0${row.gradeLevelName}`]: false
        });
        //@ts-ignore
        idMapper.current = Object.assign(idMapper.current, {
          [`0${row.gradeLevelName}`]: [row.gradeLevelId]
        });
      } else {
        //@ts-ignore
        setTypes(Object.assign(types, { [row.gradeLevelName]: row.type }));
        //@ts-ignore
        setTypesList(
          Object.assign(
            typesList,
            //@ts-ignore
            typesList["Grade Level"].push(row.gradeLevelName)
          )
        );
        //@ts-ignore
        if (getStudentsFilter.indexOf(`${row.gradeLevelName}`) !== -1) {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [row.gradeLevelName]: true
          });
        } else {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [row.gradeLevelName]: false
          });
        }

        filterStatesToReset.current = Object.assign(filterStatesToReset.current, { [row.gradeLevelName]: false });
        //@ts-ignore
        idMapper.current = Object.assign(idMapper.current, {
          [row.gradeLevelName]: [row.gradeLevelId]
        });
      }
    });
    fetchspanishids();
    fetchData();
  }

  function studentCreated() {
    activeFilters.current = getStudentsFilter;
    setPageNo(1);
    getStudents(1, "");
    setShowToast({
      val: true,
      msg: configMessages.studentCreationMsg,
      type: "success"
    });
  }

  function renderImpersonationModal() {
    return (
      <>
        <HiddenForm
          ImpersonatedUserId={isMigratedID}
          PostLogoutRedirectURL={window.location.pathname}
          tabInfo={"&activeTab=students&activeSubTab=roster"}
        />
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Entering Impersonation Session
            </text>
            <img
              className="confirmCancelImage"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowImpersonationModal(false);
              }}
            />
          </div>
          <hr></hr>
          <div>
            <div className="studentNameHolder">Student: {studentName}</div>
            <text>
              What does it mean to impersonate a user?
              <ul>
                <li>You will be able to take actions in the student’s name.</li>
                <li>Any actions you take can be tracked.</li>
              </ul>
              Please confirm you want to impersonate this user.
            </text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px",
              marginTop: "20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowImpersonationModal(false);
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Yes"}
              className="px-2 py-1 closeBut"
              callback={() => {
                BeginImpersonation();
                sendLogoutEvent();
                setShowImpersonationModal(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  async function getStudents(pageno: number, stext: string) {
    setActivityIndicator(true);
    try {
      dispatch(updateStudentsFilter(activeFilters.current));
      if (activeFilters.current.length === 0) {
        dispatch(updateStudentsFilter([]));
      } else {
        dispatch(updateStudentsFilter(activeFilters.current));
      }

      dispatch(updateStudentListSort({ name: sortBy.current, val: sortOrder.current }));
      let FilterGrades = [];
      for (let i in activeFilters.current) {
        if (activeFilters.current[i] !== "Active" && activeFilters.current[i] !== "Inactive") {
          //@ts-ignore
          FilterGrades.push(idMapper.current[activeFilters.current[i]]);
        }
      }
      let jsonObj: StudentListWithGuidProps = {
        Page: pageno,
        PageSize: getPaginationControllerState[0].paginationCount,
        OrgId: 0,
        FilterGradeLevels: FilterGrades.join(),
        FilterStatus: "true",
        SearchText: stext,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        organizationGuid: id
      };
      let urltype = `single`;
      if (getUserRoleId === 5 && getDaDetails.type === 2) {
        urltype = `multiple`;
        jsonObj = {
          Page: pageno,
          PageSize: getPaginationControllerState[0].paginationCount,
          FilterGradeLevels: FilterGrades.join(),
          FilterStatus: "true",
          SearchText: stext,
          SchoolGuids: id.split("_").toString(),
          SortBy: sortBy.current,
          SortOrder: sortOrder.current
        };
      }

      getStudentListApiCall(jsonObj, urltype);
    } catch (err) {
      //do nothing
    }
  }

  const sortByFunc = () => {
    switch (sortBy.current) {
      case "lastname":
        setUsernameBool(null);
        setGradeLevelBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      case "loginname":
        setStudentNameBool(null);
        setGradeLevelBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      case "gradelevelname":
        setStudentNameBool(null);
        setUsernameBool(null);
        setStatusBool(null);
        setSchoolBool(null);
        break;
      case "isenabled":
        setStudentNameBool(null);
        setUsernameBool(null);
        setGradeLevelBool(null);
        setSchoolBool(null);
        break;
      default:
        setStudentNameBool(null);
        setUsernameBool(null);
        setGradeLevelBool(null);
        setStatusBool(null);
    }
  };

  async function gotResponse(responsedata: Response) {
    restoreDefaultCheckState();
    let allData = JSON.parse(await responsedata.text());
    setLoading(false);
    sortByFunc();
    if (allData.size === 0) {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
      if (
        (activeFilters.current.includes("Active") || activeFilters.current.length === 0) &&
        !searchResultRef.current
      ) {
        setShowHelpIcon(false);
        setEmptyTableText(configMessages.noStudentsNoEdit);
      } else if (
        (activeFilters.current.length > 0 && searchResultRef.current) ||
        activeFilters.current.length > 0 ||
        searchResultRef.current
      ) {
        setIcon(true);
        setEmptyTableText(helpSearch);
      } else {
        //do nothing
      }
    } else {
      let allUserid = [];
      const filteredDataOverIsSyncedValue = allData.values.filter((item) => item.isSynced !== true);
      for (let x in filteredDataOverIsSyncedValue) {
        //@ts-ignore
        allUserid.push(filteredDataOverIsSyncedValue[x].userGuid);
      }
      setData(allData);
    }

    if (allData.page != 1 && allData.values.length === 0) {
      // do nothing
    }

    setActivityIndicator(false);
  }

  function sortChange(type: string) {
    switch (type) {
      case "Student Name":
        sortOrder.current = !studentNameBool ? "desc" : "asc";
        sortBy.current = "lastname";
        break;
      case "Username":
        sortOrder.current = !usernameBool ? "desc" : "asc";
        sortBy.current = "loginname";
        break;
      case "Grade Level":
        sortOrder.current = !gradeLevelBool ? "desc" : "asc";
        sortBy.current = "gradelevelname";
        break;
      case "Status":
        sortOrder.current = !statusBool ? "desc" : "asc";
        sortBy.current = "isenabled";
        break;
      default:
        sortOrder.current = !schoolBool ? "desc" : "asc";
        sortBy.current = "schoolName";
    }
    getStudents(1, searchText);
    setPageNo(1);
  }

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    getStudents(value, searchText);
  };

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getStudents(1, searchText);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getStudents(1, searchText);
    }
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      setPageNo(1);
      getStudents(1, "");
    }
  }

  function viewStudent(row: SelectedStudentProps) {
    dispatch(updateCurrentSchoolID(row.orgId));
    dispatch(updateCurrentSchoolGuid(row.orgGuid));
    let breadcrumbs = getBreadCrumbsData.crumbs;
    breadcrumbs.push({
      path: `/viewstudent/${row.orgGuid}_${row.userGuid}`,
      name: `${row.firstName} ${row.lastName}`
    });
    dispatch(updateBreadCrumbs({ crumbs: breadcrumbs }));
    setActivityIndicator(true);
    navigate(`/viewstudent/${row.orgGuid}_${row.userGuid}`);
  }

  function restoreDefaultCheckState() {}

  function customStudentOptionCallback(args: CustomStudentOptionCallbackProps) {
    if (args.data.isSynced && (args.type === "Deactivate" || args.type === "Activate")) {
      setShowErrorModal({ show: true, errorType: "IsSynced" });
      return;
    }
    if (args.type !== "Deactivate" && args.type !== "Activate") {
      try {
        setActivityIndicator(true);
        UserService.getImpersonationUserDetailsWithGuid(args.data.userGuid).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              gotMigratedID(data);
            } else {
              //do nothing
            }
          }
        );
      } catch (err) {
        alert("error");
      }

      let name = args.data.firstName + ", " + args.data.lastName;
      setStudentName(name);
      setActivityIndicator(false);
    }
  }

  function renderErrorModal() {
    let errorText;
    switch (showErrorModal?.errorType) {
      case "Inactive":
        errorText = `The student's access has been deactivated. Logging in as the student cannot proceed.`;
        break;
      case "NoLogIn":
        errorText = "This student has not yet logged in to MyPath and cannot be impersonated.";
        break;
      case "IsSynced":
        errorText = "This student is managed from a migration and cannot be updated";
        break;
      case "IsSyncedBulk":
        errorText = "One or more selected students are managed from a migration and cannot be updated";
        break;
      default:
        errorText = "";
    }
    return (
      <div className="manipContainer">
        <div className="bulkHeader">
          <img
            className="confirmCancelImage"
            src={TimesIcon}
            alt=""
            onClick={() => {
              setShowErrorModal(null);
            }}
          />
        </div>
        <hr></hr>
        <div style={{ margin: "85px 50px", fontWeight: 600 }}>
          <text>{errorText}</text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: " flex-end",
            paddingBottom: "10px",
            marginRight: "-20px",
            marginTop: "20px"
          }}
        >
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Ok"}
            className="px-2 py-1 closeBut"
            callback={() => {
              setShowErrorModal(null);
            }}
          />
        </div>
      </div>
    );
  }

  async function gotMigratedID(data: Response) {
    let details = JSON.parse(await data.text());
    if (details.isOrganizationActive) {
      if (details.isEnabled) {
        if (details.isMigrated) {
          setShowImpersonationModal(true);
          setIsMigratedID(details.migratedUserGuid);
        } else {
          setShowErrorModal({ show: true, errorType: "NoLogIn" });
        }
      } else {
        setShowErrorModal({ show: true, errorType: "Inactive" });
      }
    } else {
      setShowErrorModal({ show: true, errorType: "Inactive" });
    }
  }

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const jsonObj = {
        userId: getUserId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: getUserRole
      };
      AdminService.saveLogoutevent(jsonObj).then((_response) => {
        //do nothing
      });
    } catch (err) {
      //do nothing
    }
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[0] = { paginationPageName: "Students", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));

    try {
      setActivityIndicator(true);
      UserService.updatePaginationCount(getUserId, 1, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getStudents(1, searchText);
          } else {
            setActivityIndicator(false);
          }
        }
      );
    } catch (err) {
      setActivityIndicator(false);
    }
  }

  const muiTableForHelpIcon = () => {
    return (
      <UxMUITable
        MUITableClassName={isteacher ? "contentForTStudent" : "contentForStudent"}
        columnNames={getUserRoleId === 5 && getDaDetails.type === 2 ? columnNamesForMultiple : columnNames}
        rowMapper={getUserRoleId === 5 && getDaDetails.type === 2 ? rowMapperForMultiple : rowMapper}
        rowData={(data as IPage).values}
        showColumnCheckbox={false}
        showKebabMenu={kebabMenuOption.includes("Impersonate")}
        showImpersonationKebabMenu={true}
        customImpersonationKebabOption={customStudentOptionCallback}
        KebabMenuOption={kebabMenuOption}
        showNameKebabMenu={false}
        viewChild={viewStudent}
        emptyTableText={emptyTableText}
        checkboxRef={"userGuid"}
        selected={[]}
        setSelected={() => {}}
        mtableHeight={tableHgt}
        showHelpIconFlag={showHelpIcon}
        removeEditabilityFlag={true}
      />
    );
  };

  const muiTableContent = () => {
    if (!icon && data.values.length === 0) {
      return (
        <UxMUITable
          MUITableClassName={isteacher ? "contentForTStudent" : "contentForStudent"}
          columnNames={getUserRoleId === 5 && getDaDetails.type === 2 ? columnNamesForMultiple : columnNames}
          rowMapper={getUserRoleId === 5 && getDaDetails.type === 2 ? rowMapperForMultiple : rowMapper}
          rowData={(data as IPage).values}
          showColumnCheckbox={false}
          showKebabMenu={kebabMenuOption.includes("Impersonate")}
          showImpersonationKebabMenu={true}
          customImpersonationKebabOption={customStudentOptionCallback}
          KebabMenuOption={kebabMenuOption}
          showNameKebabMenu={false}
          viewChild={viewStudent}
          emptyTableText={emptyTableText}
          checkboxRef={"userGuid"}
          selected={[]}
          setSelected={() => {}}
          mtableHeight={tableHgt}
          removeEditabilityFlag={true}
        />
      );
    } else {
      return muiTableForHelpIcon();
    }
  };

  return (
    <>
      {activityIndicator ? <UxActivityIndicator type="nouser" /> : null}
      <div>
        <UxTitleBar title="Student Roster" />
        <UxFilterSlider
          opacity={opacity}
          backgroundVisibility={contentVisibility}
          left={sliderLeft}
          sliderHeader="Filters"
          activeFilters={activeFilters.current}
          setActiveFilters={activeFilters}
          resetText="Reset Filters"
          onResetClick={() => {
            restoreData();
          }}
          activeFilterText="Active Filters"
          types={types}
          typesHeader={typesHeader}
          typesList={typesList}
          filterStates={filterStates.current}
          setFilterState={filterStates}
          setSliderLeft={setSliderLeft}
          setBackgroundVisibility={setContentVisibility}
          setOpacity={setOpacity}
          onChecked={() => {
            setPageNo(1);
            getStudents(1, searchText);
          }}
          textMessage={textMessage}
          setMultiFilterDropDown={setFilterCountClick}
          screen="students"
          stateName={stateName}
          setStateName={setStateName}
        />
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={4000}
            onClose={() => {
              setShowToast({ val: false, msg: "", type: "" });
            }}
          >
            {showToast.type === "success" ? (
              <Alert
                severity="success"
                style={{ backgroundColor: "#36BBA4", color: "#fff" }}
                onClose={() => {
                  setShowToast({ val: false, msg: "", type: "" });
                }}
              >
                {showToast.msg}
              </Alert>
            ) : (
              <Alert
                severity="error"
                style={{ backgroundColor: "#f4473c", color: "#fff" }}
                onClose={() => {
                  setShowToast({ val: false, msg: "", type: "" });
                }}
              >
                {showToast.msg}
              </Alert>
            )}
          </Snackbar>
        </div>
        {isfrom === "school" ? (
          <div className="searchCreateButtonHolder">
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row"
              }}
            >
              <UxSliderAction
                filterText="Filters"
                activeFilters={activeFilters.current}
                minCount={1}
                multiFilterDropDown={filterCountClick}
                filterStates={filterStates.current}
                setFilterState={filterStates}
                setActiveFilters={activeFilters}
                setMultiFilterDropDown={setFilterCountClick}
                setSliderLeft={setSliderLeft}
                left={sliderLeft}
                setBackgroundVisibility={setContentVisibility}
                setOpacity={setOpacity}
                types={types}
                onChecked={() => {
                  setPageNo(1);
                  getStudents(1, searchText);
                }}
                textMessage={textMessage}
                stateName={stateName}
                setStateName={setStateName}
              />
            </div>
            <div
              className="studentSearchCreateButtonHolders"
              style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}
            >
              <UxSearchInput
                flexClassName="sFlexHolder"
                inputClassName="sInputText"
                placeHolder={`Search Student Name, Username, ${
                  getIsOrgHasRegistrationOwned ? "Student ID" : "External ID"
                }`}
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  handleKeyPress(e);
                }}
                searchButtonClassName="studentSearchButton"
                searchButtonContent={renderSearchButton()}
                onSearchClick={() => {
                  handleSearchButtonClick();
                }}
                clearButtonClassName="studentclearButton"
                clearButtonContent={renderCancelButton()}
                onClearClick={() => {
                  clearButtonClick();
                }}
                restoreCheckBoxState={() => {}}
              />
              <UXCSVExport
                data={csvData}
                headers={headersForCsv}
                filename={
                  "Student Roster_" +
                  CSVFilename.replace(/[.]/g, " ") +
                  "_" +
                  (new Date(`${new Date()}`).getUTCMonth() + 1) +
                  "_" +
                  new Date(`${new Date()}`).getUTCDate() +
                  "_" +
                  new Date(`${new Date()}`).getUTCFullYear() +
                  "_" +
                  Date.now()
                }
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="nobulkOptions"></div>
      <div ref={elementRef}>
        {data.values.length > 0 ? (
          <UxMUITable
            MUITableClassName={isteacher ? "contentForTStudent" : "contentForStudent"}
            columnNames={getUserRoleId === 5 && getDaDetails.type === 2 ? columnNamesForMultiple : columnNames}
            rowMapper={getUserRoleId === 5 && getDaDetails.type === 2 ? rowMapperForMultiple : rowMapper}
            rowData={(data as IPage).values}
            showColumnCheckbox={false}
            showKebabMenu={kebabMenuOption.includes("Impersonate")}
            showImpersonationKebabMenu={true}
            customImpersonationKebabOption={customStudentOptionCallback}
            KebabMenuOption={kebabMenuOption}
            showNameKebabMenu={false}
            viewChild={viewStudent}
            emptyTableText={emptyTableText}
            checkboxRef={"userGuid"}
            selected={[]}
            setSelected={() => {}}
            mtableHeight={tableHgt}
            showHelpIconFlag={showHelpIcon}
            hideCheckboxOverValue="isSynced"
            disableKebabMenuOverValue="isSynced"
            removeEditabilityFlag={true}
            showSortList={
              getUserRoleId === 5 && getDaDetails.type === 2
                ? [true, true, true, false, true, true]
                : [true, true, false, true, true]
            }
            sortListMapper={
              getUserRoleId === 5 && getDaDetails.type === 2
                ? {
                    "Student Name": [
                      studentNameBool,
                      setStudentNameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Username: [
                      usernameBool,
                      setUsernameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    School: [
                      schoolBool,
                      setSchoolBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    "Grade Level": [
                      gradeLevelBool,
                      setGradeLevelBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Status: [
                      statusBool,
                      setStatusBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ]
                  }
                : {
                    "Student Name": [
                      studentNameBool,
                      setStudentNameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Username: [
                      usernameBool,
                      setUsernameBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    "Grade Level": [
                      gradeLevelBool,
                      setGradeLevelBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ],
                    Status: [
                      statusBool,
                      setStatusBool,
                      (args: string) => {
                        sortChange(args);
                      }
                    ]
                  }
            }
          />
        ) : (
          muiTableContent()
        )}
      </div>
      {loading ? null : (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[0].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[0].paginationCount}
              callback={callback}
            />
          )}
        </div>
      )}

      <BulkImportsModal
        showBulkModal={showImpersonationModal}
        children={renderImpersonationModal()}
        width="610px"
        height={"340px"}
      />
      <BulkImportsModal
        showBulkModal={showErrorModal?.show}
        children={renderErrorModal()}
        width={"580px"}
        height={"auto"}
      />
    </>
  );
}

export default connect(mapStateToProps)(Student);
