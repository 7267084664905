import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  UxSearchInput,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxAction,
  UxMUITable,
  UxPaginate,
  UxPaginationController,
  TimesIcon
} from "@wne-spa/ux-component-library";
import {
  updateActivityIndicator,
  updateLicensingListSort,
  updatePaginationControllerState,
  updateLicensingOrgIds
} from "../../Redux/Action";
import NavDropdown from "react-bootstrap/NavDropdown";
import configMessages from "../../helpers/configMessages";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import "./Licensing.scss";
import { ILicensingData } from "../Licensing/ILicensingData";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import LicenseType from "./EditLicenseType";
import { paginationControllers, flagrKeys } from "../../helpers/configs";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { ProductData } from "./EditLicense.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getLicensingListSort: state.getLicensingListSort,
    getActivityIndicator: state.getActivityIndicator,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getUserId: state.getUserId,
    getLicensingOrgIds: state.getLicensingOrgIds,
    getUserRoleId: state.getUserRoleId,
    getPendoEducatorDetails: state.getPendoEducatorDetails,
    getUserName: state.getUserName,
    globalSubjectPurpose: state.globalSubjectPurpose,
    getFlagrKeys: state.getFlagrKeys
  };
}
type LicenseProps = {
  isfrom: string;
  dispatch: AppDispatch;
};
interface Props extends LicenseProps, ReduxProps {}
function Licensing(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const {
    getLicensingListSort,
    dispatch,
    getTabChange,
    getPaginationControllerState,
    getUserId,
    getLicensingOrgIds,
    getUserRoleId,
    getPendoEducatorDetails,
    getUserName,
    globalSubjectPurpose,
    getFlagrKeys
  } = props;
  const [flagrValue, setflagvalue] = useState(false);
  const [ShowLicensingModal, setShowLicensingModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const searchResultRef = useRef(false);
  const sortBy = useRef(getLicensingListSort.name);
  const sortOrder = useRef(getLicensingListSort.val);
  const [schoolBool, setSchoolBool] = useState<boolean | null>(null);
  const [categoryBool, setcategoryBool] = useState<boolean | null>(null);
  const textMessage = React.useRef("none");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedAll, setSelectedAll] = React.useState<string[]>([]);
  const elementRef = useRef(null);
  const [pageNo, setPageNo] = useState(1);
  const [tableHgt, setTableHgt] = useState(0);
  const [loading, setLoading] = useState(true);
  const [emptyTableText, setEmptyTableText] = useState("");
  const [licenseButton, setlicenseButton] = useState(false);
  const categoryfilterclickedRef = useRef(false);
  const licenseUpdatedRef = useRef(false);
  let [productLicenseData, setproductLicenseData] = useState<ProductData | Array<string>>([]);
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [columnNames, setColumnNames] = useState(() =>
    globalSubjectPurpose === "SEL"
      ? ["Schools", "Category", "MyPath Math", "MyPath Reading", "Imagine Science Corner", "Purpose Prep Sel", " "]
      : ["Schools", "Category", "MyPath Math", "MyPath Reading", "Imagine Science Corner", "Imagine Purpose", " "]
  );
  const [rowMapper, setrowMapper] = useState([
    ["organizationDetails"],
    "orgType",
    "licenseTypeMath",
    "licenseTypeReading",
    "licenseTypeScience",
    "licenseTypePurposePrepSel",
    ""
  ]);

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    localStorage.setItem("ala:pageChange", "true");
    dispatch(updateActivityIndicator(false));
    setPageNo(value);
    getLicensingData(value, searchText);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        //@ts-ignore
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    let valuePushed = [...getLicensingOrgIds];
    let selectedOrgIds = selected
      .toString()
      .split(",")
      .filter((item: string) => item);

    valuePushed = valuePushed.filter((item: string) => item !== localStorage.getItem("ala:unchecked"));
    valuePushed = valuePushed.filter((x) => !localStorage.getItem("ala:unCheckAll")?.includes(x));
    localStorage.setItem("ala:unCheckAll", `${""}`);
    for (let index in valuePushed) {
      if (selectedOrgIds.indexOf(valuePushed[index]) === -1) {
        selectedOrgIds.push(valuePushed[index]);
      }
    }
    dispatch(updateLicensingOrgIds([]));
    dispatch(updateLicensingOrgIds(selectedOrgIds));
    selectedOrgIds.length > 0 ? setlicenseButton(true) : setlicenseButton(false);
    return () => {
      dispatch(updateLicensingOrgIds([]));
    };
  }, [selected]);

  useEffect(() => {
    if (categoryFilter.length > 0) {
      dispatch(updateLicensingOrgIds([]));
      setSelected([]);
      categoryfilterclickedRef.current = true;
      getLicensingData(1, searchText);
    } else {
      categoryfilterclickedRef.current = false;
    }

    // eslint-disable-next-line
  }, [categoryFilter]);

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    setSelected([]);
    dispatch(updateLicensingOrgIds([]));
  }, [getTabChange]);

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    setColumnNames(() =>
      globalSubjectPurpose === "SEL"
        ? ["Schools", "Category", "MyPath Math", "MyPath Reading", "Imagine Science Corner", "Purpose Prep Sel"]
        : ["Schools", "Category", "MyPath Math", "MyPath Reading", "Imagine Science Corner", "Imagine Purpose"]
    );
    setrowMapper([
      ["organizationDetails"],
      "orgType",
      "licenseTypeMath",
      "licenseTypeReading",
      "licenseTypeScience",
      "licenseTypePurposePrepSel"
    ]);
    if (getLicensingListSort.val === "desc" || getLicensingListSort.val === "DESC") {
      if (getLicensingListSort.name === "organizationtitle") {
        setSchoolBool(true);
      } else if (getLicensingListSort.name === "category") {
        setcategoryBool(true);
      }
    } else if (getLicensingListSort.val === "asc" || getLicensingListSort.val === "ASC") {
      if (getLicensingListSort.name === "organizationtitle") {
        setSchoolBool(false);
      } else if (getLicensingListSort.name === "category") {
        setcategoryBool(false);
      }
    }

    licensingDataApiCall();
    // eslint-disable-next-line
    setSelected([]);
    dispatch(updateLicensingOrgIds([]));
  }, [id]);
  useEffect(() => {
    getflagerkey();
  }, []);

  const licensingDataApiCall = () => {
    const jsonObj = {
      Page: 1,
      PageSize: getPaginationControllerState[7].paginationCount,
      orgID: id,
      SortBy: sortBy.current,
      SortOrder: sortOrder.current,
      SearchText: searchText,
      FilterBy: categoryFilter === "All" ? "" : categoryFilter
    };

    AdminService.getLicensingDataWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
      }
    });
    fetchProductLicenseData(id);
  };

  async function getflagerkey() {
    let multiOrgEntities = [];
    let entities = {
      entityContext: {
        orgGuid: id,
        userGuid: getPendoEducatorDetails,
        siteCode: localStorage.getItem("ala:loginCode"),
        userRole: getPendoEducatorDetails.roleName,
        userName: getUserName
      }
    };
    multiOrgEntities = [...multiOrgEntities, entities];
    const raw = {
      entities: multiOrgEntities,
      enableDebug: true,
      flagKeys: flagrKeys
    };
    await AdminService.getFlagerStatus(raw).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getflagerResponse(data);
      }
    });
  }

  async function getflagerResponse(response: Response) {
    let details = await response.json();
    for (let x in details.evaluationResults) {
      if (details.evaluationResults[x].flagKey === "ShowMyPathScienceColumnForDistricts") {
        if (details.evaluationResults[x].variantKey === "on" || details.evaluationResults[x].variantKey === "ON") {
          setflagvalue(true);
        } else {
          setflagvalue(false);
        }
      }
    }
  }
  async function fetchProductLicenseData(orgId: string | number) {
    try {
      let apiObj = { orgGuid: orgId };
      await AdminService.getProductLicenseDataWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotProductLicenseData(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      // do nothing
    }
  }

  async function gotProductLicenseData(responsedata: Response) {
    try {
      let details = await responsedata.json();
      setproductLicenseData(details);
    } catch (err) {
      // do nothing
    }
  }

  function licenseUpdated() {
    licenseUpdatedRef.current = true;
    dispatch(updateLicensingOrgIds([]));
    setSelected([]);
    closelicensingModal();
    getLicensingData(1, searchText);
    setShowToast({
      val: true,
      msg: configMessages.licenseUpdatedMsg,
      type: "info"
    });
  }
  async function getLicensingData(pagenumber: number, stext: string) {
    try {
      dispatch(updateActivityIndicator(true));
      dispatch(
        updateLicensingListSort({
          name: sortBy.current,
          val: sortOrder.current
        })
      );

      const jsonObj = {
        Page: pagenumber,
        PageSize: getPaginationControllerState[7].paginationCount,
        orgID: id,
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        SearchText: stext,
        FilterBy: categoryFilter === "All" ? "" : categoryFilter
      };

      await AdminService.getLicensingDataWithGuid(jsonObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data);
          } else {
            if (status === 204) {
              setData({ page: 0, size: 0, pageSize: 0, values: [] });
              setSelectedAll([]);
              if (categoryFilter.length > 0 && searchResultRef.current) {
                setEmptyTableText("No results matching for your applied filters and search parameter");
              } else {
                setEmptyTableText("No results containing your search were found");
              }
            }
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  async function gotResponse(responsedata: Response) {
    try {
      setLoading(false);
      let allData = JSON.parse(await responsedata.text());
      if (sortBy.current === "organizationtitle") {
        setcategoryBool(null);
      } else if (sortBy.current === "category") {
        setSchoolBool(null);
      }
      if (allData.size === 0) {
        setData({ page: 0, size: 0, pageSize: 0, values: [] });
        setSelectedAll([]);
        setSelected([]);
      } else {
        gotResponseSubFunc(allData);
      }
      dispatch(updateActivityIndicator(false));
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }
  const gotResponseSubFunc = (allData) => {
    let allOrganizationId = [];
    let checkBoxIds: string[] = [];
    let previousReduxIds: string[] = [];
    setSelected([]);
    for (let x in allData.values) {
      if (
        !searchResultRef.current &&
        !categoryfilterclickedRef.current &&
        !licenseUpdatedRef.current &&
        getLicensingOrgIds.length > 0 &&
        getLicensingOrgIds.indexOf(allData.values[x].organizationId.toString()) !== -1
      ) {
        checkBoxIds.push(allData.values[x].organizationId);
      }
      //@ts-ignore
      allOrganizationId.push(allData.values[x].organizationId);
      allData.values[x].organizationDetails = allData.values[x].organizationDetails.toString();
    }

    if (localStorage.getItem("ala:pageChange") === "true") {
      for (let i in getLicensingOrgIds) {
        if (previousReduxIds.indexOf(getLicensingOrgIds[i]) === -1) {
          previousReduxIds.push(getLicensingOrgIds[i]);
        }
      }
    }
    if (checkBoxIds.length > 0) {
      setSelected(checkBoxIds);
    }
    setSelectedAll(allOrganizationId);
    setData(allData);
  };

  function sortChange(type: string) {
    if (type === "Schools") {
      if (!schoolBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "organizationtitle";
    } else if (type === "Category") {
      if (!categoryBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "OrgType";
    }
    getLicensingData(1, searchText);
    setPageNo(1);
  }

  function handleSearchTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setSearchText(event.target.value);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<Element>): void {
    if (event.key === "Enter" && searchText !== "") {
      dispatch(updateLicensingOrgIds([]));
      setSelected([]);
      searchResultRef.current = true;
      textMessage.current = "Search";
      getLicensingData(1, searchText);
      setPageNo(1);
    }
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[7] = {
      paginationPageName: "ProductLicense",
      paginationCount: args
    };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 8, args).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getLicensingData(1, searchText);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="searchIcon" />;
  }

  function handleSearchButtonClick() {
    if (searchText !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      dispatch(updateLicensingOrgIds([]));
      setSelected([]);
      getLicensingData(1, searchText);
      setPageNo(1);
    }
  }

  function closelicensingModal() {
    setShowLicensingModal(false);
    localStorage.setItem("page", "licensing");
  }

  function renderCancelButton() {
    if (searchText !== "" && !searchResultRef.current) {
      return <img src={TimesIcon} alt="" width="20px" height="20px" />;
    } else if (searchText !== "" && searchResultRef.current) {
      return (
        <div className="searchIconText">
          <span> Clear Results </span>
        </div>
      );
    }
  }

  function clearButtonClick() {
    if (searchText !== "" && !searchResultRef.current) {
      setSearchText("");
    } else if (searchText !== "" && searchResultRef.current) {
      setSearchText("");
      searchResultRef.current = false;
      setSearchText("");
      getLicensingData(1, "");
      setPageNo(1);
    }
  }

  function selectAllSchools() {
    setSelected(selectedAll);
  }

  function clearAllSchools() {
    setSelected([]);
    dispatch(updateLicensingOrgIds([]));
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  return (
    <>
      <div className={alertclasses.root + " licensing-snackbar"}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={6000}
          classes={{
            root: "licensing-snackbar_root"
          }}
          onClose={() => {
            setShowToast({ val: false, msg: "", type: "info" });
          }}
        >
          {showToast.type === "info" ? (
            <Alert
              style={{ backgroundColor: "#36BBA4", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "info" });
              }}
            >
              {showToast.msg}
            </Alert>
          ) : (
            <Alert
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "error" });
              }}
            >
              {showToast.msg}
            </Alert>
          )}
        </Snackbar>
      </div>

      <div className="elInputTextHolder">
        <div className="categorydropdownHolder">
          <NavDropdown
            id="categorydropdown"
            title={
              <div className="categorytitleHolder">
                <text className="categorytext">{categoryFilter ? categoryFilter : "Category"}</text>
                <i className="fa fa-chevron-down categorydropdownIcon"></i>
              </div>
            }
          >
            {(productLicenseData as ProductData).orgTypes
              ? (productLicenseData as ProductData).orgTypes.map((item: string) => (
                  <NavDropdown.Item
                    onClick={() => {
                      setCategoryFilter(item);
                    }}
                  >
                    <text className="categorydropdownText">{item}</text>
                  </NavDropdown.Item>
                ))
              : null}
          </NavDropdown>
        </div>
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row"
          }}
        ></div>
        <div
          className={getUserRoleId === 1 ? "edsearchLicenseHolder" : ""}
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            marginTop: "25px",
            justifyContent: "end",
            alignItems: "center",
            marginRight: getUserRoleId === 1 ? "17px" : "12px"
          }}
        >
          <UxSearchInput
            flexClassName={"eLicenseFlexHolder"}
            inputClassName="licensingInputText"
            placeHolder="Search school name or ID"
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchTextChange(e)}
            onKeyPress={(e: React.KeyboardEvent) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="searchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="clearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
            restoreCheckBoxState={() => {
              restoreDefaultCheckState();
            }}
          />
          {getUserRoleId === 1 && !getFlagrKeys["S76-6256-RemoveSettingLicensingInALA"] ? (
            <UxAction
              callback={() => {
                setShowLicensingModal(true);
                localStorage.setItem("page", "licenseTypeEditForm");
              }}
              text={"Set License Type"}
              className="setLicensingButton"
              size={"lg"}
              enabled={licenseButton ? true : false}
            />
          ) : null}
        </div>
      </div>
      {ShowLicensingModal ? (
        <LicenseType
          closeModal={closelicensingModal}
          ShowLicensingModal={ShowLicensingModal}
          handleSuccess={licenseUpdated}
          data={productLicenseData}
        />
      ) : null}

      {selected.length > 0 ? (
        <div className="licenseTabBulkOptions">
          <p className="selectcount">
            {" "}
            {selected.length} {selected.length > 1 ? "schools have been selected." : "school has been selected."}{" "}
          </p>
          {selected.length !== data.values.length ? (
            <p className="selectall" onClick={() => selectAllSchools()}>
              {" "}
              Select All {data.values.length} Schools on Page
            </p>
          ) : null}

          <p className="clearall" onClick={() => clearAllSchools()}>
            {" "}
            Clear Selections{" "}
          </p>
        </div>
      ) : (
        <div className="licenseTabNoBulkOptions"></div>
      )}

      <div ref={elementRef}>
        {data.values.length > 0 ? (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "bg-white LicensingTable"}
            columnNames={flagrValue ? columnNames : columnNames.filter((cnname) => cnname !== "MyPath Science")}
            rowMapper={flagrValue ? rowMapper : rowMapper.filter((cnname) => cnname !== "licenseTypeScience")}
            rowData={(data as ILicensingData).values}
            showColumnCheckbox={
              getUserRoleId === 1 && !getFlagrKeys["S76-6256-RemoveSettingLicensingInALA"] ? true : false
            }
            emptyTableText={emptyTableText}
            checkboxRef={"organizationId"}
            selected={selected}
            mtableHeight={tableHgt}
            setSelected={setSelected}
            showStatusText={false}
            LicenseTableFlag={true}
            showSortList={[true, true, false, false, false, false]}
            sortListMapper={{
              Schools: [
                schoolBool,
                setSchoolBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              Category: [
                categoryBool,
                setcategoryBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        ) : (
          <UxMUITable
            MUITableClassName={data.values.length === 0 ? "emptyTable1" : "bg-white LicensingTable"}
            columnNames={flagrValue ? columnNames : columnNames.filter((cnname) => cnname !== "MyPath Science")}
            rowMapper={flagrValue ? rowMapper : rowMapper.filter((cnname) => cnname !== "licenseTypeScience")}
            rowData={(data as ILicensingData).values}
            showColumnCheckbox={true}
            emptyTableText={emptyTableText}
            checkboxRef={"organizationId"}
            selected={selected}
            mtableHeight={tableHgt}
            setSelected={setSelected}
            LicenseTableFlag={true}
          />
        )}
      </div>
      {loading ? null : (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[7].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[7].paginationCount}
              callback={callback}
            />
          )}
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(Licensing);
