import React, { useState, useEffect, useRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { UxAction, UxShadesEnum, TimesIcon } from "@wne-spa/ux-component-library";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { blue, grey } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import "./Settings.scss";
import { updateActivityIndicator } from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import configMessages from "../../helpers/configMessages";
import BulkImportsModal from "../modals/bulk-imports";
import dateFnsFormat from "date-fns/format";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SessionHandler from "../../authService/SessionHandler";
import ReduxProps from "Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getUserId: state.getUserId,
    getChildDepth: state.getChildDepth
  };
}
type myPathSettingsProps = {
  dispatch: AppDispatch;
  orgId: string;
};

interface Props extends myPathSettingsProps, ReduxProps {}
function MyPathSettings(props: Props) {
  const { t } = useTranslation();
  const [editMyPathAssessmentSettings, setEditMyPathAssessmentSettings] = useState<boolean>(false);
  const [mathSettings, setMathSettings] = useState<boolean>(false);
  const [readingSettings, setReadingSettings] = useState<boolean>(false);
  const [math, setMath] = useState<boolean>(false);
  const [reading, setReading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });
  const { getUserRoleId, orgId, dispatch, getUserId, getChildDepth } = props;
  const { id } = useParams<{ id: string | undefined }>();
  const [editNWEASettings, setEditNWEASettings] = useState(false);
  const [NWEASettings, setNWEASettings] = useState(false);
  const borderColorToReset = "#DDDDDD";
  const borderColorFocus = "#4DA7C9";
  const borderColorSuccess = "#3CBCA6";
  const borderColorError = "#F24E45";
  const [BID, setBID] = useState("-");
  const [BIDVal, setBIDVal] = useState("");
  const [BIDIssueText, setBIDIssueText] = useState("");
  const [BIDBorder, setBIDBorder] = useState(borderColorToReset);
  const [BIDstate, setBIDState] = React.useState({
    checkedA: false
  });
  const [selectedStartDate, handleStartDateChange] = useState(
    formatDate(new Date().setMonth(new Date().getMonth() - 3))
  );
  const [selectedEndDate, handleEndDateChange] = useState(formatDate(new Date().setDate(new Date().getDate() - 1)));

  const [selectedRenStartDate, handleRenStartDateChange] = useState(
    formatDate(new Date().setMonth(new Date().getMonth() - 3))
  );
  const [selectedRenEndDate, handleRenEndDateChange] = useState(
    formatDate(new Date().setDate(new Date().getDate() - 1))
  );
  const interval = useRef<ReturnType<typeof setTimeout>>();
  const [nweaLastSyncStatus, setnweaLastStatus] = useState(0);
  const [nweaLastSyncDate, setnweaLastSyncDate] = useState("");
  const [nweaSyncbutton, setnweaSyncbutton] = useState(true);
  const [renaissanceLastSyncStatus, setrenaissanceLastStatus] = useState(0);
  const [renaissanceLastSyncDate, setrenaissanceLastSyncDate] = useState("");
  const [renaissanceSyncbutton, setrenaissanceSyncbutton] = useState(true);
  const [showNweaSyncMsg, setShowNweaSyncMsg] = useState(false);
  const [showRenaissanceSyncMsg, setShowRenaissanceSyncMsg] = useState(false);
  const [editRenaissanceSettings, setEditRenaissanceSettings] = useState(false);
  const [RenaissanceSettings, setRenaissanceSettings] = useState(false);
  const [RenaissanceRPID, setRenaissanceRPID] = useState("-");
  const [RenaissanceRPIDVal, setRenaissanceRPIDVal] = useState("");
  const [RenaissanceRPIDIssueText, setRenaissanceRPIDIssueText] = useState("");
  const [RenaissanceRPIDBorder, setRenaissanceRPIDBorder] = useState(borderColorToReset);
  const renaissanceIssueTextToReset = {
    BID: ""
  };
  const [RenaissanceRPIDstate, setRenaissanceRPIDState] = React.useState({
    checkedA: false
  });
  const issueTextToReset = {
    BID: "",
    LoginCode: ""
  };

  useEffect(() => {
    fetchMyPathAssessmentSettings();
    fetchNWEASettings();
    fetchRenaissanceSettings();
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      checkNweaSyncStatus();
    }, 60000);
    return () => clearInterval(interval.current);
  }, [nweaLastSyncStatus]);

  useEffect(() => {
    interval.current = setInterval(() => {
      checkRenaissanceSyncStatus();
    }, 60000);
    return () => clearInterval(interval.current);
  }, [renaissanceLastSyncStatus]);

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const BlueSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: blue[500]
      },
      "&$checked + $track": {
        backgroundColor: blue[500]
      }
    },
    checked: {},
    track: {}
  })(Switch);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  function format(input: string, formats: [number, number, number, number], sep: string) {
    let output = "";
    let idx = 0;
    for (let i = 0; i < formats.length && idx < input.length; i++) {
      output += input.substr(idx, formats[i]);
      if (idx + formats[i] < input.length) output += sep;
      idx += formats[i];
    }

    output += input.substr(idx);

    return output;
  }

  function formatDate(date: number) {
    return dateFnsFormat(date, "MM/dd/yyyy");
  }

  function validateDate(testdate: string) {
    let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return date_regex.test(testdate);
  }

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      if (event.target.value.length > 0) {
        event.target.value = event.target.value.replace(/-/g, "");
        event.target.value = format(event.target.value, [8, 4, 4, 4], "-");
      }
      setBIDVal(event.target.value);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBIDState({ ...BIDstate, [event.target.name]: event.target.checked });
  };

  function handleRenaissanceTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setRenaissanceRPIDVal(event.target.value);
    }
  }

  const renaissanceHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRenaissanceRPIDState({
      ...RenaissanceRPIDstate,
      [event.target.name]: event.target.checked
    });
  };

  function getFormattedDate(date: string) {
    let formatteddate = new Date(date),
      mnth = ("0" + (formatteddate.getMonth() + 1)).slice(-2),
      day = ("0" + formatteddate.getDate()).slice(-2);
    return [mnth, day, formatteddate.getFullYear()].join("/");
  }

  async function fetchMyPathAssessmentSettings() {
    try {
      const firstOrgId = orgId.split("_")[0]; //passing first organization ID for my path settings
      let guid = { orgGuid: firstOrgId };
      await AdminService.mypathAssessmentSettingsWithGuid(guid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setMyPathAssessmentSettingsData(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setMyPathAssessmentSettingsData(responsedata: Response) {
    let details = await responsedata.json();

    //@ts-ignore
    setMathSettings(details.myPathMathEnabled);
    //@ts-ignore
    setReadingSettings(details.myPathELAEnabled);
  }

  async function saveMyPathAssessmentSettings() {
    dispatch(updateActivityIndicator(true));
    const jsonObj = {
      organizationGUID: orgId,
      myPathMathEnabled: math,
      myPathELAEnabled: reading
    };

    try {
      await AdminService.saveMyPathAssessmentSettingsWithGuid(jsonObj).then(
        ({ status }: { status: number; data: Response }) => {
          if (status === 200) {
            setMathSettings(jsonObj.myPathMathEnabled);
            setReadingSettings(jsonObj.myPathELAEnabled);
            setShowToast({
              val: true,
              msg: "Successfully Updated",
              color: "#36BBA4",
              type: "success"
            });
            setEditMyPathAssessmentSettings(false);
            dispatch(updateActivityIndicator(false));
          } else {
            setShowToast({
              val: true,
              msg: "Not able to Update...",
              color: "#f4473c",
              type: "warning"
            });
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (error) {
      dispatch(updateActivityIndicator(false));
    }
  }

  function cancelMyPathAssessmentSettings() {
    localStorage.setItem("page", "AssessmentSettings");
    setEditMyPathAssessmentSettings(false);

    //do nothing
  }

  async function fetchNWEASettings() {
    try {
      let guid = { guid: id };
      await AdminService.nweaSettingsWithGuid(guid).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setNWEASettingsData(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function setNWEASettingsData(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setNWEASettings(details.nweaEnabled);
    //@ts-ignore
    setBIDState({ ...BIDstate, checkedA: details.nweaEnabled });
    //@ts-ignore
    if (details.bid !== null && details.bid !== "") {
      //@ts-ignore
      setBID(details.bid);
      //@ts-ignore
      setBIDVal(details.bid);
    }

    //@ts-ignore
    if (details.syncStatus === 2) {
      setnweaLastSyncDate(details.lastSyncDateTime);
      setnweaLastStatus(2);
      setnweaSyncbutton(true);
    } else if (details.syncStatus === 1) {
      setnweaLastStatus(1);
      setnweaSyncbutton(false);
    }
  }

  function renderChildForSyncNweaModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              NWEA Sync
            </text>
            <img
              src={TimesIcon}
              alt=""
              className="nweacloseIcon"
              onClick={() => {
                closeNweaSyncMSgModal();
              }}
            />
          </div>
          <hr></hr>
          <div>
            <text>
              Sync is in progress. This may take up to 15
              <br />
              minutes to complete.
              <br />
              <br />
            </text>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-40px"
            }}
          >
            <UxAction
              key={1}
              size="sm"
              text="Close"
              className="px-4 py-1 nextBut saveBtn nweacloseButton"
              priority={UxShadesEnum.DARK}
              callback={() => {
                closeNweaSyncMSgModal();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  const closeNweaSyncMSgModal = () => {
    setShowNweaSyncMsg(false);
  };

  function cancelEdit() {
    localStorage.setItem("page", "AssessmentSettings");
    if (NWEASettings) {
      setBIDVal(BID);
    } else {
      if (BID === "-") {
        setBIDVal("");
      } else {
        setBIDVal(BID);
      }
    }

    setBIDState({ ...BIDstate, checkedA: NWEASettings });
    setEditNWEASettings(false);
    setBIDBorder(borderColorToReset);
    setBIDIssueText("");
  }

  function saveNWEASettings() {
    validateBID();
  }

  function validateBID() {
    if (BIDstate.checkedA) {
      const validateRegExp = /^[0-9a-f-]+$/;
      if (BIDVal !== "" && BIDVal.length === 36 && validateRegExp.test(BIDVal)) {
        setBIDBorder(borderColorSuccess);
        setBIDIssueText(issueTextToReset.BID);
        updateNWEASettings();
      } else {
        if (BIDVal === "" || BIDVal.length === 0) {
          setBIDIssueText(configMessages.BIDError);
        } else {
          setBIDIssueText(configMessages.BIDFormatError);
        }
        setBIDBorder(borderColorError);
      }
    } else {
      updateNWEASettings();
    }
  }

  async function updateNWEASettings() {
    try {
      dispatch(updateActivityIndicator(true));
      let NBID = "";
      if (BIDstate.checkedA) {
        NBID = BIDVal;
      } else {
        NBID = BID;
      }
      const jsonObj = {
        OrganizationGuid: id,
        BID: NBID,
        NWEAEnabled: BIDstate.checkedA
      };
      AdminService.nweaSaveSettingsWithGuid(jsonObj).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          setBIDBorder(borderColorToReset);
          dispatch(updateActivityIndicator(false));
          setEditNWEASettings(false);
          localStorage.setItem("page", "AssessmentSettings");
          setBIDIssueText("");
          setNWEASettings(BIDstate.checkedA);
          if (BIDstate.checkedA) {
            setBID(BIDVal);
          } else {
            setBIDVal(BID);
          }
          setShowToast({
            val: true,
            msg: configMessages.BIDSaveMsg,
            color: "#36BBA4",
            type: "success"
          });
        } else {
          alert("Error while updating BID");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error while updating BID");
      dispatch(updateActivityIndicator(false));
    }
  }

  async function checkNweaSyncStatus() {
    if (getUserRoleId === 1 && nweaLastSyncStatus === 1) {
      fetchNWEASettings();
    }
  }

  function callNweaSyncService() {
    let formattedEndDate = getFormattedDate(selectedEndDate);
    let formattedStartDate = getFormattedDate(selectedStartDate);

    let formatteddate = new Date();
    let currentStartDate = new Date(selectedStartDate);
    let currentEndDate = new Date(selectedEndDate);

    let formatted_Startdate =
      currentStartDate.getFullYear() +
      "-" +
      ("0" + (currentStartDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + currentStartDate.getDate()).slice(-2);
    let formatted_Enddate =
      currentEndDate.getFullYear() +
      "-" +
      ("0" + (currentEndDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + currentEndDate.getDate()).slice(-2);
    let formatted_date =
      formatteddate.getFullYear() +
      "-" +
      ("0" + (formatteddate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + formatteddate.getDate()).slice(-2);

    if (formattedEndDate === "01/01/1970" || formattedStartDate === "01/01/1970") {
      setShowToast({
        val: true,
        msg: "Invalid Date: Start Date and/or End Date should not be empty.",
        color: "#f4473c",
        type: "warning"
      });
      return;
    }
    if (validateDate(formattedStartDate) && validateDate(formattedEndDate)) {
      if (formatted_Enddate === formatted_date || formatted_Enddate > formatted_date) {
        setShowToast({
          val: true,
          msg: "Invalid Date: End Date must be a date in the past. No data available for current or future date.",
          color: "#f4473c",
          type: "warning"
        });
        return;
      } else if (formatted_Startdate >= formatted_Enddate) {
        setShowToast({
          val: true,
          msg: "Invalid Date: Start Date must precede End Date.",
          color: "#f4473c",
          type: "warning"
        });
        return;
      } else {
        let fromSchool = false;
        setShowNweaSyncMsg(true);

        nweaSyncServiceApiCall(fromSchool, formattedStartDate, formattedEndDate);
      }
    } else {
      setShowToast({
        val: true,
        msg: "Invalid Date",
        color: "#f4473c",
        type: "warning"
      });
    }
  }

  const nweaSyncServiceApiCall = (fromSchool: boolean, formattedStartDate: string, formattedEndDate: string) => {
    try {
      if (window.location.href.indexOf("school") > -1) {
        fromSchool = true;
      }
      const jsonObj = {
        nweaBidId: BID,
        isSchool: fromSchool,
        organizationGuid: id,
        userId: getUserId,
        syncStartDate: formattedStartDate,
        syncEndDate: formattedEndDate
      };
      AdminService.nweaSyncService(jsonObj).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          setnweaLastStatus(1);
          setnweaSyncbutton(false);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  };

  async function fetchRenaissanceSettings() {
    try {
      let guid = { guid: id };
      await AdminService.renaissanceSettingsWithGuid(guid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setRenaissanceSettingsData(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setRenaissanceSettingsData(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setRenaissanceSettings(details.renaissanceEnabled);
    //@ts-ignore
    setRenaissanceRPIDState({
      ...RenaissanceRPIDstate,
      checkedA: details.renaissanceEnabled
    });
    //@ts-ignore
    if (details.rpid !== null && details.rpid !== "") {
      //@ts-ignore
      setRenaissanceRPID(details.rpid);
      //@ts-ignore
      setRenaissanceRPIDVal(details.rpid);
    }

    if (details.syncStatus === 2) {
      setrenaissanceLastSyncDate(details.lastSyncDateTime);
      setrenaissanceLastStatus(2);
      setrenaissanceSyncbutton(true);
    } else if (details.syncStatus === 1) {
      setrenaissanceLastStatus(1);
      setrenaissanceSyncbutton(false);
    }
  }

  function renderChildForSyncRenaissanceModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              RENAISSANCE Sync
            </text>
            <img
              src={TimesIcon}
              alt=""
              className="renaissancecloseIcon"
              onClick={() => {
                closeRenaissanceSyncMSgModal();
              }}
            />
          </div>
          <hr></hr>
          <div>
            <text>
              Sync is in progress. This may take up to 15
              <br />
              minutes to complete.
              <br />
              <br />
            </text>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-40px"
            }}
          >
            <UxAction
              key={1}
              size="sm"
              text="Close"
              className="px-4 py-1 nextBut saveBtn nweacloseButton"
              priority={UxShadesEnum.DARK}
              callback={() => {
                closeRenaissanceSyncMSgModal();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  const closeRenaissanceSyncMSgModal = () => {
    setShowRenaissanceSyncMsg(false);
  };

  function cancelRenaissanceEdit() {
    localStorage.setItem("page", "AssessmentSettings");
    if (RenaissanceSettings) {
      setRenaissanceRPIDVal(RenaissanceRPID);
    } else {
      if (RenaissanceRPID === "-") {
        setRenaissanceRPIDVal("");
      } else {
        setRenaissanceRPIDVal(RenaissanceRPID);
      }
    }
    setRenaissanceRPIDState({
      ...RenaissanceRPIDstate,
      checkedA: RenaissanceSettings
    });
    setEditRenaissanceSettings(false);
    setRenaissanceRPIDBorder(borderColorToReset);
    setRenaissanceRPIDIssueText("");
  }

  function saveRenaissanceSettings() {
    localStorage.setItem("page", "RenaissanceSettingsEditForm");
    validateRenaissanceBID();
  }

  function validateRenaissanceBID() {
    if (RenaissanceRPIDstate.checkedA) {
      const validateRegExp = /^[a-zA-Z0-9-]+$/;
      if (RenaissanceRPIDVal !== "" && validateRegExp.test(RenaissanceRPIDVal)) {
        setRenaissanceRPIDBorder(borderColorSuccess);
        setRenaissanceRPIDIssueText(renaissanceIssueTextToReset.BID);
        updateRenaissanceSettings();
      } else {
        if (RenaissanceRPIDVal === "" || RenaissanceRPIDVal.length === 0) {
          setRenaissanceRPIDIssueText(configMessages.RPIDError);
        } else {
          setRenaissanceRPIDIssueText(configMessages.RPIDFormatError);
        }
        setRenaissanceRPIDBorder(borderColorError);
      }
    } else {
      updateRenaissanceSettings();
    }
  }

  async function updateRenaissanceSettings() {
    try {
      dispatch(updateActivityIndicator(true));
      let RPID = "";
      if (RenaissanceRPIDstate.checkedA) {
        RPID = RenaissanceRPIDVal;
      } else {
        RPID = RenaissanceRPID;
      }

      const _token = await SessionHandler().then((data) => {
        //@ts-ignore
        return data.token;
      });

      const jsonObj = {
        OrganizationGuid: id,
        RPID: RPID,
        RenaissanceEnabled: RenaissanceRPIDstate.checkedA,
        ExternalSystemID: 0
      };
      AdminService.renaissanceSaveSettingsWithGuid(jsonObj).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          setRenaissanceRPIDBorder(borderColorToReset);
          dispatch(updateActivityIndicator(false));
          setEditRenaissanceSettings(false);
          localStorage.setItem("page", "AssessmentSettings");
          setRenaissanceRPIDIssueText("");
          setRenaissanceSettings(RenaissanceRPIDstate.checkedA);
          if (RenaissanceRPIDstate.checkedA) {
            setRenaissanceRPID(RenaissanceRPIDVal);
          } else {
            setRenaissanceRPIDVal(RenaissanceRPID);
          }
          setShowToast({
            val: true,
            msg: configMessages.RPIDSaveMsg,
            color: "#36BBA4",
            type: "success"
          });
        } else {
          alert("Error while updating RPID");
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error while updating RPID");
      dispatch(updateActivityIndicator(false));
    }
  }

  async function checkRenaissanceSyncStatus() {
    if (getUserRoleId === 1 && renaissanceLastSyncStatus === 1) {
      fetchRenaissanceSettings();
    }
  }

  function callRenaissanceSyncService() {
    let formattedEndDate = getFormattedDate(selectedRenEndDate);
    let formattedStartDate = getFormattedDate(selectedRenStartDate);

    let formatteddate = new Date();
    let currentStartDate = new Date(selectedRenStartDate);
    let currentEndDate = new Date(selectedRenEndDate);

    let formatted_Startdate =
      currentStartDate.getFullYear() +
      "-" +
      ("0" + (currentStartDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + currentStartDate.getDate()).slice(-2);
    let formatted_Enddate =
      currentEndDate.getFullYear() +
      "-" +
      ("0" + (currentEndDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + currentEndDate.getDate()).slice(-2);
    let formatted_date =
      formatteddate.getFullYear() +
      "-" +
      ("0" + (formatteddate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + formatteddate.getDate()).slice(-2);

    if (formattedEndDate === "01/01/1970" || formattedStartDate === "01/01/1970") {
      setShowToast({
        val: true,
        msg: "Invalid Date: Start Date and/or End Date should not be empty.",
        color: "#f4473c",
        type: "warning"
      });
      return;
    }
    if (validateDate(formattedStartDate) && validateDate(formattedEndDate)) {
      if (formatted_Enddate === formatted_date || formatted_Enddate > formatted_date) {
        setShowToast({
          val: true,
          msg: "Invalid Date: End Date must be a date in the past. No data available for current or future date.",
          color: "#f4473c",
          type: "warning"
        });
        return;
      } else if (formatted_Startdate >= formatted_Enddate) {
        setShowToast({
          val: true,
          msg: "Invalid Date: Start Date must precede End Date.",
          color: "#f4473c",
          type: "warning"
        });
        return;
      } else {
        setShowRenaissanceSyncMsg(true);

        renaissanceSyncServiceApiCall(formattedStartDate, formattedEndDate);
      }
    } else {
      setShowToast({
        val: true,
        msg: "Invalid Date",
        color: "#f4473c",
        type: "warning"
      });
    }
  }

  const renaissanceSyncServiceApiCall = (formattedStartDate: string, formattedEndDate: string) => {
    try {
      if (window.location.href.indexOf("school") > -1) {
        // do nothing
      }
      const jsonObj = {
        organizationGuid: id,
        syncStartDate: formattedStartDate,
        syncEndDate: formattedEndDate
      };
      AdminService.renaissanceSyncService(jsonObj).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          setrenaissanceLastStatus(1);
          setrenaissanceSyncbutton(false);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  };

  const nweaLastSyncStatusDisplay = () => {
    if (nweaLastSyncStatus === 1 && NWEASettings && BID !== "-") {
      return (
        <div style={{ marginBottom: "30px" }}>
          Last Sync:
          <span
            style={{
              color: "#36bba4",
              fontWeight: 500,
              fontSize: "13px"
            }}
          >
            {" "}
            <i className="far fa fa-adjust"></i> IN PROGRESS
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  const renaissanceLastSyncStatusDisplay = () => {
    if (renaissanceLastSyncStatus === 1 && RenaissanceSettings && RenaissanceRPID !== "-") {
      return (
        <div style={{ marginBottom: "30px" }}>
          Last Sync:
          <span
            style={{
              color: "#36bba4",
              fontWeight: 500,
              fontSize: "13px"
            }}
          >
            {" "}
            <i className="far fa fa-adjust"></i> IN PROGRESS
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: showToast.color, color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>

      {!editMyPathAssessmentSettings && !editNWEASettings && !editRenaissanceSettings ? (
        <div className="myPathSettingsscrollable px-4 ms-2">
          <div className="nweasettings">
            <span className="mypathSettingsHeader"> MyPath Assessment Settings </span>

            {getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7 ? (
              <UxAction
                callback={() => {
                  setMath(mathSettings);
                  setReading(readingSettings);
                  setEditMyPathAssessmentSettings(true);
                  localStorage.setItem("page", "myPathSettingsEditForm");
                }}
                text={"Edit"}
                className="editButton"
                size={"lg"}
              />
            ) : null}
            <div>
              <p className="mypathSettingTagName"> ENABLE MATH ASSESSMENT </p>

              <p className="settingstagvalue"> {mathSettings ? "On" : "Off"} </p>
            </div>
            <div>
              <p className="mypathSettingTagName"> ENABLE READING ASSESSMENT </p>

              <p className="settingstagvalue"> {readingSettings ? "On" : "Off"} </p>
            </div>
          </div>
          <div className="nweasettings">
            <span className="mypathSettingsHeader"> NWEA Settings </span>
            {getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7 ? (
              <UxAction
                callback={() => {
                  setEditNWEASettings(true);
                  localStorage.setItem("page", "NWEASettingsEditForm");
                }}
                text={t("Edit")}
                className="editButton"
                size={"lg"}
              />
            ) : null}
            <div>
              <p className="mypathSettingTagName">{getChildDepth > 0 ? "District" : "school"} Settings </p>

              <p className="settingstagvalue"> {NWEASettings ? "On" : "Off"} </p>
            </div>
            <div>
              <p className="mypathSettingTagName"> BID </p>

              <p className="settingstagvalue"> {BID} </p>
            </div>
            {getUserRoleId === 1 ? (
              <div style={{ width: "710px" }}>
                <p className="mypathSettingTagName"> SYNC NWEA </p>

                <div>
                  <p className="syncnweaStart"> Start Date</p>
                  <p className="syncnweaEnd"> End Date</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row"
                  }}
                >
                  <div style={{ marginLeft: "72px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardDatePicker
                          clearable
                          value={selectedStartDate}
                          placeholder="mm/dd/yyyy"
                          variant="inline"
                          disableToolbar
                          onChange={
                            //@ts-ignore
                            (date: Date) => handleStartDateChange(date)
                          }
                          format="MM/dd/yyyy"
                          maxDate={selectedEndDate}
                          keyboardIcon={
                            <i
                              className="fa fa-calendar calendarIcon "
                              style={{
                                marginLeft: "-2px",
                                marginBottom: "10px",
                                marginRight: "-8px"
                              }}
                            ></i>
                          }
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardDatePicker
                          clearable
                          value={selectedEndDate}
                          placeholder="mm/dd/yyyy"
                          variant="inline"
                          disableToolbar
                          onChange={
                            //@ts-ignore
                            (date: Date) => handleEndDateChange(date)
                          }
                          format="MM/dd/yyyy"
                          maxDate={formatDate(new Date().setDate(new Date().getDate() - 1))}
                          minDate={selectedStartDate}
                          keyboardIcon={
                            <i
                              className="fa fa-calendar calendarIcon "
                              style={{
                                marginLeft: "-2px",
                                marginBottom: "10px",
                                marginRight: "-8px"
                              }}
                            ></i>
                          }
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className={
                      NWEASettings && BID !== "-" && nweaSyncbutton && nweaLastSyncStatus !== 1
                        ? "syncButton"
                        : "syncButtonDisabled"
                    }
                    onClick={
                      NWEASettings && BID !== "-" && nweaSyncbutton && nweaLastSyncStatus !== 1
                        ? () => {
                            callNweaSyncService();
                          }
                        : () => {
                            //do nothing
                          }
                    }
                  >
                    <label style={{ cursor: "pointer" }}>Sync</label>
                  </div>
                </div>
                <div>
                  <br />
                </div>
                {nweaLastSyncStatus === 2 && NWEASettings && BID !== "-" ? (
                  <div>
                    <p>Last Sync: {nweaLastSyncDate}</p>
                  </div>
                ) : (
                  nweaLastSyncStatusDisplay()
                )}
              </div>
            ) : null}
            <BulkImportsModal
              showBulkModal={showNweaSyncMsg}
              children={renderChildForSyncNweaModal()}
              width="406px"
              height="253px"
            />
          </div>
          <div
            className={
              getUserRoleId === 3 || getUserRoleId === 4 || getUserRoleId === 5
                ? "nweasettings renaissanceSettingsDiv"
                : "nweasettings"
            }
          >
            <span className="mypathSettingsHeader"> Renaissance Settings </span>
            {getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7 ? (
              <UxAction
                callback={() => {
                  setEditRenaissanceSettings(true);
                  localStorage.setItem("page", "RenaissanceSettingsEditForm");
                }}
                text={t("Edit")}
                className="editButton"
                size={"lg"}
              />
            ) : null}
            <div>
              <p className="mypathSettingTagName"> {getChildDepth > 0 ? "District" : "school"} Settings </p>

              <p className="settingstagvalue"> {RenaissanceSettings ? "On" : "Off"} </p>
            </div>
            <div>
              <p className="mypathSettingTagName"> RPID </p>

              <p className="settingstagvalue"> {RenaissanceRPID} </p>
            </div>
            {getUserRoleId === 1 ? (
              <div style={{ width: "710px" }}>
                <p className="mypathSettingTagName"> SYNC RENAISSANCE</p>

                <div>
                  <p className="syncnweaStart"> Start Date</p>
                  <p className="syncnweaEnd"> End Date</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row"
                  }}
                >
                  <div style={{ marginLeft: "72px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardDatePicker
                          clearable
                          value={selectedRenStartDate}
                          placeholder="mm/dd/yyyy"
                          variant="inline"
                          disableToolbar
                          onChange={
                            //@ts-ignore
                            (date: Date) => handleRenStartDateChange(date)
                          }
                          format="MM/dd/yyyy"
                          maxDate={selectedRenEndDate}
                          keyboardIcon={
                            <i
                              className="fa fa-calendar calendarIcon "
                              style={{
                                marginLeft: "-2px",
                                marginBottom: "10px",
                                marginRight: "-8px"
                              }}
                            ></i>
                          }
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <KeyboardDatePicker
                          clearable
                          value={selectedRenEndDate}
                          placeholder="mm/dd/yyyy"
                          variant="inline"
                          disableToolbar
                          onChange={
                            //@ts-ignore
                            (date: Date) => handleRenEndDateChange(date)
                          }
                          format="MM/dd/yyyy"
                          maxDate={formatDate(new Date().setDate(new Date().getDate() - 1))}
                          minDate={selectedRenStartDate}
                          keyboardIcon={
                            <i
                              className="fa fa-calendar calendarIcon "
                              style={{
                                marginLeft: "-2px",
                                marginBottom: "10px",
                                marginRight: "-8px"
                              }}
                            ></i>
                          }
                        />
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    className={
                      RenaissanceSettings &&
                      RenaissanceRPID !== "-" &&
                      renaissanceSyncbutton &&
                      renaissanceLastSyncStatus !== 1
                        ? "syncButton"
                        : "syncButtonDisabled"
                    }
                    onClick={
                      RenaissanceSettings &&
                      RenaissanceRPID !== "-" &&
                      renaissanceSyncbutton &&
                      renaissanceLastSyncStatus !== 1
                        ? () => {
                            callRenaissanceSyncService();
                          }
                        : () => {
                            //do nothing
                          }
                    }
                  >
                    <label style={{ cursor: "pointer" }}>Sync</label>
                  </div>
                </div>
                <div>
                  <br />
                </div>
                {renaissanceLastSyncStatus === 2 && RenaissanceSettings && RenaissanceRPID !== "-" ? (
                  <div>
                    <p>Last Sync: {renaissanceLastSyncDate}</p>
                  </div>
                ) : (
                  renaissanceLastSyncStatusDisplay()
                )}
              </div>
            ) : null}
            <BulkImportsModal
              showBulkModal={showRenaissanceSyncMsg}
              children={renderChildForSyncRenaissanceModal()}
              width="406px"
              height="253px"
            />
          </div>
        </div>
      ) : null}

      {editMyPathAssessmentSettings ? (
        <div className="myPathSettingsscrollable px-4 ms-2">
          <div style={{ marginTop: "18px" }}>
            <span className="mypathSettingsHeader"> MyPath Assessment Settings </span>

            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <label className="mypathSettingTagName">ENABLE MATH ASSESSMENT</label>
            </div>
            <div className="customSwitchButtonHolderForMyPathSettings">
              <div
                className="customSwitchButtonLeftForSettings"
                style={{
                  background: math ? "#4c9c8d" : "#ffffff",
                  color: math ? "#ffffff" : "#6E7075"
                }}
                onClick={() => {
                  setMath(true);
                }}
              >
                On
              </div>
              <div
                className="customSwitchButtonRightForSettings"
                style={{
                  background: !math ? "#e0e0e0" : "#ffffff",
                  color: !math ? "#5B5B5B" : "#6E7075"
                }}
                onClick={() => {
                  setMath(false);
                }}
              >
                Off
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="mypathSettingTagName">ENABLE READING ASSESSMENT</label>
            </div>

            <div className="customSwitchButtonHolderForMyPathSettings">
              <div
                className="customSwitchButtonLeftForSettings"
                style={{
                  background: reading ? "#4c9c8d" : "#ffffff",
                  color: reading ? "#ffffff" : "#6E7075"
                }}
                onClick={() => {
                  setReading(true);
                }}
              >
                On
              </div>
              <div
                className="customSwitchButtonRightForSettings"
                style={{
                  background: !reading ? "#e0e0e0" : "#ffffff",
                  color: !reading ? "#5B5B5B" : "#6E7075"
                }}
                onClick={() => {
                  setReading(false);
                }}
              >
                Off
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <UxAction
                callback={() => cancelMyPathAssessmentSettings()}
                text={"Cancel"}
                className="cancelButton"
                size={"lg"}
              />
              <UxAction
                callback={() => saveMyPathAssessmentSettings()}
                text={"Save"}
                className="saveButton"
                size={"lg"}
                enabled={math !== mathSettings || reading !== readingSettings ? true : false}
              />
            </div>
          </div>
        </div>
      ) : null}

      {editNWEASettings ? (
        <div className="myPathSettingsscrollable  px-4 ms-2">
          <div style={{ marginTop: "18px" }}>
            <span className="settingsheading"> NWEA Settings </span>
            <div style={{ marginTop: "20px" }}>
              <span>
                <BlueSwitch checked={BIDstate.checkedA} onChange={handleChange} name="checkedA" />
              </span>
              <span className="settingstagvalue">
                {" "}
                {BIDstate.checkedA ? "Disable NWEA integration" : "Enable NWEA integration"}{" "}
              </span>
            </div>
            {BIDstate.checkedA ? (
              <div className="labelInputHolder" style={{ marginTop: "20px" }}>
                <label className="labelForInput">{getChildDepth > 0 ? "District" : "school"} Bid *</label>
                <div
                  style={{
                    border: `1.3px solid ${BIDBorder}`,
                    borderRadius: "3px"
                  }}
                >
                  <input
                    className="eTextInput"
                    maxLength={36}
                    onClick={() => {
                      setBIDIssueText(issueTextToReset.BID);
                      setBIDBorder(borderColorFocus);
                    }}
                    value={BIDVal}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e)}
                    onBlur={() => {
                      setBIDBorder(borderColorToReset);
                    }}
                  />
                </div>
                <label className="labelForIssue">{BIDIssueText}</label>
              </div>
            ) : null}

            <div style={{ marginTop: "30px" }}>
              <UxAction callback={() => cancelEdit()} text={t("Cancel")} className="cancelButton" size={"lg"} />
              {NWEASettings !== BIDstate.checkedA || (BID !== BIDVal && BID !== "-") ? (
                <UxAction callback={() => saveNWEASettings()} text={t("Save")} className="saveButton" size={"lg"} />
              ) : (
                <UxAction
                  callback={() => {
                    //do nothing
                  }}
                  text={t("Save")}
                  className="saveButtonDisabled"
                  size={"lg"}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}

      {editRenaissanceSettings ? (
        <div className="myPathSettingsscrollable px-4 ms-2">
          <div style={{ marginTop: "18px" }}>
            <span className="settingsheading"> Renaissance Settings </span>
            <div style={{ marginTop: "20px" }}>
              <BlueSwitch checked={RenaissanceRPIDstate.checkedA} onChange={renaissanceHandleChange} name="checkedA" />
              <span className="settingstagvalue">
                {" "}
                {RenaissanceRPIDstate.checkedA
                  ? "Disable Renaissance integration"
                  : "Enable Renaissance integration"}{" "}
              </span>
            </div>
            {RenaissanceRPIDstate.checkedA ? (
              <div className="labelInputHolder" style={{ marginTop: "20px" }}>
                <label className="labelForInput">Enter RPID *</label>
                <div
                  style={{
                    border: `1.3px solid ${RenaissanceRPIDBorder}`,
                    borderRadius: "3px"
                  }}
                >
                  <input
                    className="eTextInput"
                    onClick={() => {
                      setRenaissanceRPIDIssueText(renaissanceIssueTextToReset.BID);
                      setRenaissanceRPIDBorder(borderColorFocus);
                    }}
                    value={RenaissanceRPIDVal}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRenaissanceTextChange(e)}
                    onBlur={() => {
                      setRenaissanceRPIDBorder(borderColorToReset);
                    }}
                  />
                </div>
                <label className="labelForIssue">{RenaissanceRPIDIssueText}</label>
              </div>
            ) : null}

            <div style={{ marginTop: "30px" }}>
              <UxAction
                callback={() => cancelRenaissanceEdit()}
                text={t("Cancel")}
                className="cancelButton"
                size={"lg"}
              />
              {RenaissanceSettings !== RenaissanceRPIDstate.checkedA ||
              (RenaissanceRPID !== RenaissanceRPIDVal && RenaissanceRPID !== "-") ? (
                <UxAction
                  callback={() => saveRenaissanceSettings()}
                  text={t("Save")}
                  className="saveButton"
                  size={"lg"}
                />
              ) : (
                <UxAction
                  callback={() => {
                    //do nothing
                  }}
                  text={t("Save")}
                  className="saveButtonDisabled"
                  size={"lg"}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(MyPathSettings);
