import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateLSBReport,
  updateLevel,
  updateSchoolGuids,
  updateCurrentOrganizationDetails
} from "../../Redux/Action";
import "./DataList.scss";
import SchoolList from "../EducatorView/SchoolList";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import useOrganizations from "../hooks/useOrganizations";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getClassFilter: state.getClassFilter,
    getCurrentUserDetails: state.getCurrentUserDetails
  };
}
interface TESProps {
  dispatch: AppDispatch;
  isFrom?: string;
}

interface RowData {
  id: number;
  name: string;
  parentId: number;
  parentName: string;
  customerName: string;
  netsuiteId: string;
  isActive: boolean;
  childDepth: number;
  organizationGuid: string;
  orgGUID: string;
  type: string;
  parentGuid: string;
  state: string;
}

interface Props extends TESProps, ReduxProps {}

function TeacherSchools(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getUserRoleId, isFrom, getCurrentUserDetails } = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const allSchools = useOrganizations(id);

  useEffect(() => {
    if (isFrom === "reports") {
      dispatch(updatePageTitle(`- Reports`));
    } else if (isFrom === "Assessment") {
      dispatch(updatePageTitle(`- Assessments`));
    } else {
      dispatch(updatePageTitle(`- Schools`));
    }
    const breadcrumb =
      isFrom === "reports"
        ? {
            crumbs: [{ path: `/schools/reports/${id}`, name: "Reports" }]
          }
        : isFrom === "Assessment"
        ? {
            crumbs: [{ path: `/teacherassessmentschools${id}`, name: "Assessments" }]
          }
        : {
            crumbs: [{ path: "/teacherschools", name: "Schools" }]
          };

    dispatch(updateBreadCrumbs(breadcrumb));
  }, [dispatch, id, isFrom]);

  useEffect(() => {
    if (allSchools !== null) {
      dispatch(updateSchoolGuids(allSchools));
      setData(allSchools);
    }
    dispatch(updateActivityIndicator(false));
  }, [dispatch, allSchools]);

  function handleClick(row: RowData) {
    const currentOrg = getCurrentUserDetails.organizations.find(org => org.orgGuid === row.orgGUID);
    dispatch(updateCurrentOrganizationDetails(currentOrg));
    if (isFrom === "reports") {
      dispatch(updateLSBReport(true));
      dispatch(updateLevel("school"));
      navigate(`/reportcards/${row.orgGUID}/${row.name}`);
    } else if (isFrom === "Assessment") {
      navigate(`/assessments/${row.orgGUID}`);
    } else {
      if (getUserRoleId === 5) {
        navigate(`/teacherclasses/${row.orgGUID}`);
      }
    }
  }

  return (
    <div className="class-nav-tabs">
      <SchoolList handleClick={handleClick} data={data} />
    </div>
  );
}

export default connect(mapStateToProps)(TeacherSchools);
