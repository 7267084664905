/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./addStudents.scss";
import {
  UxAction,
  UxPaginate,
  UxMUITable,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxSearchInput,
  TimesIcon,
  UxSliderAction,
  UxFilterSlider,
  UxPaginationController,
  UxBanner,
  WarningRosterIcon,
  CancelIcon,
  UXCSVExport
} from "@wne-spa/ux-component-library";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateRefreshSP,
  updateRosterFilter,
  updateRosterListSort,
  updatePaginationControllerState,
  updateCurrentSchoolID,
  updateCurrentSchoolGuid,
  updateAddStudentRoaster,
  updateSelectedStudentIds
} from "../../Redux/Action";
import { paginationControllers, classLimit } from "../../helpers/configs";
import { UserService } from "../../services/helpers/user.services";
import { LMSService } from "../../services/helpers/lms.services";
import BulkImportsModal from "../modals/bulk-imports";
import HelpIcon from "../../images/help_search_icon.png";
import ReduxProps from "../../Redux/Redux.props";
import {
  RosterProps,
  FilterTypeProps,
  RosterTypeRef,
  GradeLevelDataProps,
  AllEnrolledStudentsValuesProps
} from "./Roster.props";

interface Props extends ReduxProps, RosterProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getRefreshSP: state.getRefreshSP,
    getRosterFilter: state.getRosterFilter,
    getRosterListSort: state.getRosterListSort,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getSchoolID: state.getSchoolID,
    getSchoolGUID: state.getSchoolGUID,
    getClassExternalId: state.getClassExternalId,
    getBreadCrumbsData: state.getBreadCrumbsData,
    addStudentToRosterSuccess: state.addStudentToRosterSuccess,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails
  };
}

function EnrolledStudentsList(props: Props) {
  const {
    dispatch,
    getRefreshSP,
    getRosterFilter,
    getRosterListSort,
    getUserId,
    getTabChange,
    getPaginationControllerState,
    getSchoolID,
    getSchoolGUID,
    getClassExternalId,
    orgId,
    getBreadCrumbsData,
    addStudentToRosterSuccess,
    getUserRoleId,
    getDaDetails
  } = props;
  const navigate = useNavigate();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [spinner, setSpinner] = useState(true);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [selectedAll, setSelectedAll] = React.useState<number[]>([]);
  const [icon, setIcon] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  const columnNames = ["Student Name", "UserName", "Grade Level", "Account Status"];
  const rowMapper = [["lastName", "firstName"], "userName", "gradeLevelId", "isActive"];
  const grades = ["PK", "K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "Other"];
  const sortBy = useRef(getRosterListSort.name);
  const sortOrder = useRef(getRosterListSort.val);
  const [studentNameBool, setStudentNameBool] = useState<boolean | null>(null);
  const [userNameBool, setuserNameBool] = useState<boolean | null>(null);
  const [gradeLevelBool, setGradeLevelBool] = useState<boolean | null>(null);
  const searchResultRef = useRef(false);
  const textMessage = React.useRef("none");
  const [emptyTableText, setEmptyTableText] = useState<string | JSX.Element>();
  const [opacity, setOpacity] = useState(0);
  const [contentVisibility, setContentVisibility] = useState(false);
  const activeFilters = React.useRef<Array<string>>([]);
  const [filterCountClick, setFilterCountClick] = useState(false);
  const [sliderLeft, setSliderLeft] = useState("-330px");
  const [stateName, setStateName] = useState({});
  const [showBanner, setShowBanner] = useState({ banner: false, msg: "" });
  const [showModalPopup, setShowModalPopup] = useState(false);
  const typesHeader = ["Grade Level"];
  const [types, setTypes] = useState({
    Active: "Status",
    Inactive: "Status"
  });
  const [typesList, setTypesList] = useState({
    "Grade Level": []
  });
  const filterStates = useRef<FilterTypeProps>({
    Active: false,
    Inactive: false
  });
  const filterStatesToReset = useRef<FilterTypeProps>({
    Active: false,
    Inactive: false
  });
  const idMapper = useRef<Object>({});
  const [showNoRosteredStudentsModal, setShowNoRosteredStudentsModal] = useState(false);
  const [showNoActiveStudentModal, setShowNoActiveStudentModal] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const headersForCsv = [
    {
      label: "School Id",
      key: "schoolId"
    },
    {
      label: "Student Id",
      key: "studentId"
    },
    {
      label: "Student Last Name",
      key: "lastName"
    },
    {
      label: "Student First Name",
      key: "firstName"
    },
    {
      label: "Username",
      key: "userName"
    },
    {
      label: "External ID",
      key: "externalId"
    },
    {
      label: "Grade Level",
      key: "gradeLevel"
    },
    {
      label: "Status",
      key: "status"
    }
  ];
  const [CSVFilename, setCSVFilename] = useState("");
  interface IEnrolledStudentsList {
    firstName: string;
    lastName: string;
    loginName: string;
    eid: number;
    glevel: number;
    isEnabled: boolean;
  }

  interface IPage {
    size: number;
    page: number;
    pageSize: number;
    values: IEnrolledStudentsList[];
  }

  const helpSearch = (
    <div className="emptyContentWrapper">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  const rosterSearchRef = useRef<RosterTypeRef>({ value: "" });

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  const [studentList, setStudentList] = useState([]);

  if (activeFilters.current.includes("Active")) {
    const index = activeFilters.current.indexOf("Active");
    if (index !== -1) {
      activeFilters.current.splice(index, 1);
    }
  }

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[4] = { paginationPageName: "Roaster", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 5, args).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          getEnrolledStudents(1, rosterSearchRef.current.value);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }
  useEffect(() => {
    if (getUserRoleId === 4 || getUserRoleId === 5) {
      if (getDaDetails.type === 1) {
        setCSVFilename(getDaDetails.name + "_" + getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name);
      } else {
        setCSVFilename(
          getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 2].name +
            "_" +
            getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
        );
      }
    } else {
      setCSVFilename(
        getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 2].name +
          "_" +
          getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name
      );
    }
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 650) {
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);

  const rosterListSortFunc = () => {
    if (getRosterListSort.val === "desc" || getRosterListSort.val === "DESC") {
      if (getRosterListSort.name === "lastname") {
        setStudentNameBool(true);
      } else if (sortBy.current === "userName") {
        setuserNameBool(true);
      } else {
        setGradeLevelBool(true);
      }
    } else if (getRosterListSort.val === "asc" || getRosterListSort.val === "ASC") {
      if (getRosterListSort.name === "lastname") {
        setStudentNameBool(false);
      } else if (sortBy.current === "userName") {
        setuserNameBool(false);
      } else {
        setGradeLevelBool(false);
      }
    }
  };

  useEffect(() => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 90))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
    rosterListSortFunc();
    activeFilters.current = getRosterFilter;
    let gLevel = [];
    let gstatus = [];
    for (let x in getRosterFilter) {
      if (getRosterFilter[x] === "Active") {
        filterStates.current = { ...filterStates.current, Active: true };
        gstatus.push("Active");
      } else if (getRosterFilter[x] === "Inactive") {
        filterStates.current = { ...filterStates.current, Inactive: true };
        gstatus.push("Inactive");
      } else {
        gLevel.push(getRosterFilter[x]);
      }
    }
    setStateName({ ...stateName, Status: gstatus, "Grade Level": gLevel });
    dispatch(updateActivityIndicator(true));
    setSpinner(false);
    getStudentListForClass();
    getGradeLevelData();
    dispatch(updateSelectedStudentIds([]));
  }, []);

  useEffect(() => {
    if (studentList.length === 0) {
      getStudentList();
    }
  }, [orgId]);
  useEffect(() => {
    if (totalRecords != null && totalRecords != undefined && totalRecords > 0) {
      if (totalRecords >= classLimit) {
        setShowBanner({
          banner: true,
          msg: `A single class roster is limited to ${classLimit} students. Please consider creating two classes.`
        });
      } else if (totalRecords >= classLimit - 50 && totalRecords < classLimit) {
        setShowBanner({
          banner: true,
          msg: `A single class roster is limited to ${classLimit} students. You currently have ${
            classLimit - 50
          } or more students in the class. Please consider creating two classes.`
        });
      } else {
        setShowBanner({ banner: false, msg: "" });
      }
    }
    if (addStudentToRosterSuccess) {
      setShowToast({
        val: true,
        msg: "Student(s) successfully added to class"
      });
      dispatch(updateAddStudentRoaster(false));
    }
  }, [getTabChange]);

  async function studentsEnrolledWithGuid(studentsObj) {
    let statusCode = 0;
    let count = 0;
    const Interval = setInterval(async () => {
      if (count < 10 && statusCode != 200) {
        await LMSService.getStudentRosterclasslevelCSVData(studentsObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            gotCSVData(data);
          }
        );
        LMSService.studentsEnrolledWithGuid(studentsObj).then(
          ({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              statusCode = 200;
              gotResponse(data);
              clearInterval(Interval);
            }
          }
        );
      } else {
        clearInterval(Interval);
        dispatch(updateActivityIndicator(false));
        setSpinner(false);
      }
      count++;
    }, 2000);
  }
  async function fetchData() {
    // try {
    // dispatch(updateActivityIndicator(true));
    let FilterGrades = [];
    for (let i in activeFilters.current) {
      if (activeFilters.current[i] !== "Active" && activeFilters.current[i] !== "Inactive") {
        //@ts-ignore
        FilterGrades.push(idMapper.current[activeFilters.current[i]]);
      }
    }

    const studentsObj = {
      ClassGuid: id,
      Page: 1,
      PageSize: getPaginationControllerState[4].paginationCount,
      SortName: sortBy.current,
      SortOrder: sortOrder.current,
      SearchText: "",
      FilterGradeLevels: FilterGrades.join(),
      FilterStatus: "true"
    };
    studentsEnrolledWithGuid(studentsObj);
  }
  async function gotCSVData(responsedata: Response) {
    const csvData = JSON.parse(await responsedata.text());
    setCSVData(csvData);
  }

  async function gotGradeLevel(detail: Response) {
    dispatch(updateActivityIndicator(true));
    const details = JSON.parse(await detail.text());
    // eslint-disable-next-line
    details.map((row: GradeLevelDataProps) => {
      //@ts-ignore
      if (
        row.gradeLevelName === "1" ||
        row.gradeLevelName === "2" ||
        row.gradeLevelName === "3" ||
        row.gradeLevelName === "4" ||
        row.gradeLevelName === "5" ||
        row.gradeLevelName === "6" ||
        row.gradeLevelName === "7" ||
        row.gradeLevelName === "8" ||
        row.gradeLevelName === "9"
      ) {
        //@ts-ignore
        setTypes(Object.assign(types, { [`0${row.gradeLevelName}`]: row.type }));
        //@ts-ignore
        setTypesList(
          Object.assign(
            typesList,
            //@ts-ignore
            typesList["Grade Level"].push(`0${row.gradeLevelName}`)
          )
        );
        //@ts-ignore
        if (getRosterFilter.indexOf(`0${row.gradeLevelName}`) === -1) {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [`0${row.gradeLevelName}`]: false
          });
        } else {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [`0${row.gradeLevelName}`]: true
          });
        }

        filterStatesToReset.current = Object.assign(filterStatesToReset.current, {
          [`0${row.gradeLevelName}`]: false
        });
        //@ts-ignore
        idMapper.current = Object.assign(idMapper.current, {
          [`0${row.gradeLevelName}`]: [row.gradeLevelId]
        });
      } else {
        //@ts-ignore
        setTypes(Object.assign(types, { [row.gradeLevelName]: row.type }));
        //@ts-ignore
        setTypesList(
          Object.assign(
            typesList,
            //@ts-ignore
            typesList["Grade Level"].push(row.gradeLevelName)
          )
        );
        //@ts-ignore
        if (getRosterFilter.indexOf(`${row.gradeLevelName}`) === -1) {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [row.gradeLevelName]: false
          });
        } else {
          //@ts-ignore
          filterStates.current = Object.assign(filterStates.current, {
            [row.gradeLevelName]: true
          });
        }
        filterStatesToReset.current = Object.assign(filterStatesToReset.current, { [row.gradeLevelName]: false });
        //@ts-ignore
        idMapper.current = Object.assign(idMapper.current, {
          [row.gradeLevelName]: [row.gradeLevelId]
        });
      }
    });
    fetchData();
  }

  const getGradeLevelData = async () => {
    try {
      dispatch(updateActivityIndicator(true));
      await UserService.getGradeLevels().then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotGradeLevel(data);
        } else {
          dispatch(updateActivityIndicator(false));
          setSpinner(false);
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(updateActivityIndicator(false));
    setPageNo(value);
    getEnrolledStudents(value, rosterSearchRef.current.value);
  };

  function sortChange(type: string) {
    if (type === "Student Name") {
      if (!studentNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "lastname";
    } else if (type === "UserName") {
      if (!userNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "userName";
    } else {
      if (!gradeLevelBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "gradelevel";
    }
    getEnrolledStudents(1, rosterSearchRef.current.value);
    setPageNo(1);
  }

  const getEnrolledStudents = async (page: number, sText: string) => {
    try {
      dispatch(updateActivityIndicator(true));
      dispatch(updateRosterFilter(activeFilters.current));

      dispatch(updateRosterListSort({ name: sortBy.current, val: sortOrder.current }));
      let FilterGrades = [];
      for (let i in activeFilters.current) {
        if (activeFilters.current[i] !== "Active" && activeFilters.current[i] !== "Inactive") {
          //@ts-ignore
          FilterGrades.push(idMapper.current[activeFilters.current[i]]);
        }
      }
      const studentsObj = {
        ClassGuid: id,
        Page: page,
        PageSize: getPaginationControllerState[4].paginationCount,
        SortName: sortBy.current,
        SortOrder: sortOrder.current,
        SearchText: sText,
        FilterGradeLevels: FilterGrades.join(),
        FilterStatus: "true"
      };
      await LMSService.getStudentRosterclasslevelCSVData(studentsObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          gotCSVData(data);
        }
      );
      LMSService.studentsEnrolledWithGuid(studentsObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotResponse(data);
          dispatch(updateRefreshSP(!getRefreshSP));
        } else {
          dispatch(updateActivityIndicator(false));
          setSpinner(false);
        }
      });
    } catch (err) {
      //do nothing
      dispatch(updateActivityIndicator(false));
      setSpinner(false);
    }
  };

  const sortByFunc = () => {
    if (sortBy.current === "lastname") {
      setGradeLevelBool(null);
      setuserNameBool(null);
    } else if (sortBy.current === "userName") {
      setGradeLevelBool(null);
      setStudentNameBool(null);
    } else {
      setuserNameBool(null);
      setStudentNameBool(null);
    }
  };
  const setEmptyTableTextFunc = () => {
    if ((activeFilters.current.length === 0 || activeFilters.current.includes("Active")) && !searchResultRef.current) {
      setEmptyTableText("You don't have any students in this class.");
    } else if (
      (activeFilters.current.length > 0 && searchResultRef.current) ||
      activeFilters.current.length > 0 ||
      searchResultRef.current
    ) {
      setIcon(true);
      setEmptyTableText(helpSearch);
    }
  };

  async function gotResponse(responsedata: Response) {
    dispatch(updateActivityIndicator(true));
    let allData = JSON.parse(await responsedata.text());
    sortByFunc();
    setSpinner(false);
    setSelected([]);

    if (allData.size === 0) {
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });

      setEmptyTableTextFunc();
      setSelectedAll([]);
      setShowBanner({ banner: false, msg: "" });
      setTotalRecords(allData.totalStudentsInClass);
    } else {
      let students = {
        page: allData.page,
        size: allData.size,
        pageSize: allData.pageSize,
        values: []
      };
      let allUserid = [];
      for (let x in allData.values) {
        let val = allData.values[x];
        //Grade PK comes in as -1 from the API data.
        //Grade K comes in as 1, grade 1 comes in as 2, and so on respectively.
        if (val.gradeLevelId === -1) {
          val.gradeLevelId = grades[allData.values[x].gradeLevelId + 1];
        } else {
          val.gradeLevelId = grades[allData.values[x].gradeLevelId];
        }
        //@ts-ignore
        students.values.push(val);
        allUserid.push(allData.values[x].userId);
      }
      setSelectedAll(allUserid);

      if (allData.totalStudentsInClass >= classLimit) {
        setShowBanner({
          banner: true,
          msg: `A single class roster is limited to ${classLimit} students. Please consider creating two classes.`
        });
      } else if (allData.totalStudentsInClass >= classLimit - 50 && allData.totalStudentsInClass < classLimit) {
        setShowBanner({
          banner: true,
          msg: `A single class roster is limited to ${classLimit} students. You currently have ${
            classLimit - 50
          } or more students in the class. Please consider creating two classes.`
        });
      } else {
        setShowBanner({ banner: false, msg: "" });
      }
      setData(allData);
      setTotalRecords(allData.totalStudentsInClass);
    }
    dispatch(updateActivityIndicator(false));
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  function selectAllStudents() {
    setSelected(selectedAll);
  }
  function clearAllStudents() {
    setSelected([]);
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && rosterSearchRef.current.value !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getEnrolledStudents(1, rosterSearchRef.current.value);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="rostersearchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (rosterSearchRef.current.value !== "" && rosterSearchRef.current.value !== undefined) {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getEnrolledStudents(1, rosterSearchRef.current.value);
    }
  }

  function renderCancelButton() {
    setTimeout(() => {
      if (
        rosterSearchRef.current.value !== "" &&
        rosterSearchRef.current.value !== undefined &&
        !searchResultRef.current
      ) {
        document.getElementById("rostercloseIcon").style.display = "block";
        document.getElementById("rosterclearIcon").style.display = "none";
      } else if (
        rosterSearchRef.current.value !== "" &&
        rosterSearchRef.current.value !== undefined &&
        searchResultRef.current
      ) {
        document.getElementById("rostercloseIcon").style.display = "none";
        document.getElementById("rosterclearIcon").style.display = "block";
      } else {
        document.getElementById("rostercloseIcon").style.display = "none";
        document.getElementById("rosterclearIcon").style.display = "none";
      }
    });
    return (
      <>
        <div id="rostercloseIcon">
          <img src={TimesIcon} alt="" width="20px" height="20px" />
        </div>
        <div id="rosterclearIcon" className="rostersearchIconText">
          <span> Clear Results </span>
        </div>
      </>
    );
  }

  function clearButtonClick() {
    if (rosterSearchRef.current.value !== "" && !searchResultRef.current) {
      rosterSearchRef.current.value = "";
    } else if (rosterSearchRef.current.value !== "" && searchResultRef.current) {
      rosterSearchRef.current.value = "";
      searchResultRef.current = false;
      setPageNo(1);
      getEnrolledStudents(1, "");
    }
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  async function getStudentList() {
    if (orgId !== "") {
      dispatch(updateActivityIndicator(true));
      const studentListResponse = await UserService.studentListForClassEnrollmentWithGuid(orgId);
      if (studentListResponse.status === 200) {
        const studentListResponseData = await studentListResponse.data.json();
        //@ts-ignore
        setStudentList(studentListResponseData);
      }
    }
  }

  async function getStudentListForClass() {
    await getStudentList();
  }

  function restoreData() {
    activeFilters.current = [];
    filterStates.current = filterStatesToReset.current;
    setFilterCountClick(false);
    setStateName({});
    setPageNo(1);
    getEnrolledStudents(1, "");
  }

  function closePopup() {
    setShowModalPopup(false);
  }

  function viewStudent(row: AllEnrolledStudentsValuesProps) {
    dispatch(updateCurrentSchoolID(getSchoolID));
    dispatch(updateCurrentSchoolGuid(getSchoolGUID));
    dispatch(updateActivityIndicator(true));
    navigate(`/viewstudent/${getSchoolGUID}_${row.userGuid}`);
  }

  function renderModalPopup() {
    return (
      <div className="manipContainer">
        <div className="headerRosterPopup">
          <text className="bulkImportText" style={{ marginLeft: "0px" }}>
            Class Roster Limit Reached
          </text>
          <img
            src={TimesIcon}
            alt=""
            className="closeIcon"
            onClick={() => {
              closePopup();
            }}
          />
        </div>
        <hr></hr>
        <label className="labelTextRoster">
          A single class roster is limited to {classLimit} students. Please consider creating two classes.
        </label>
        <div className="outerClose">
          <div
            className="closeButtonRoster"
            onClick={() => {
              closePopup();
            }}
          >
            Close
          </div>
        </div>
      </div>
    );
  }

  function renderNoRosteredStudentsModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              No rostered students found
            </text>
            <img
              className="confirmCancelImageAddStudent"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowNoRosteredStudentsModal(false);
              }}
            />
          </div>
          <hr></hr>
          <div>
            <text>Contact your administrator to find out how students are rostered at your school.</text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-9px",
              marginTop: "20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Got It"}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowNoRosteredStudentsModal(false);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  function renderNoActiveStudentModal() {
    return (
      <div className="manipContainer">
        <div className="headerRosterPopup">
          <text className="bulkImportText" style={{ marginLeft: "0px" }}>
            No Active Students Found
          </text>
          <img
            src={TimesIcon}
            alt=""
            className="closeIcon"
            onClick={() => {
              setShowNoActiveStudentModal(false);
            }}
          />
        </div>
        <hr></hr>
        <label className="noActivelabelText">
          Students are rostered at your school, but are in Inactive status and cannot be added to a class. Contact your
          administrator to find out why the status is Inactive.
        </label>
        <div className="outerClose">
          <div
            className="closeButtonRoster"
            onClick={() => {
              setShowNoActiveStudentModal(false);
            }}
          >
            Close
          </div>
        </div>
      </div>
    );
  }

  function renderStudentModal() {
    if (showNoRosteredStudentsModal) {
      return renderNoRosteredStudentsModal();
    } else if (showNoActiveStudentModal) {
      return renderNoActiveStudentModal();
    } else {
      return renderModalPopup();
    }
  }

  return (
    <div>
      <BulkImportsModal
        showBulkModal={showModalPopup || showNoRosteredStudentsModal || showNoActiveStudentModal}
        children={renderStudentModal()}
        width="621px"
        height={showNoActiveStudentModal ? "220px" : "204px"}
      />

      <UxFilterSlider
        opacity={opacity}
        backgroundVisibility={contentVisibility}
        left={sliderLeft}
        sliderHeader="Filters"
        activeFilters={activeFilters.current}
        setActiveFilters={activeFilters}
        resetText="Reset Filters"
        onResetClick={() => {
          restoreData();
        }}
        activeFilterText="Active Filters"
        types={types}
        typesHeader={typesHeader}
        typesList={typesList}
        filterStates={filterStates.current}
        setFilterState={filterStates}
        setSliderLeft={setSliderLeft}
        setBackgroundVisibility={setContentVisibility}
        setOpacity={setOpacity}
        onChecked={() => {
          setPageNo(1);
          getEnrolledStudents(1, rosterSearchRef.current.value);
        }}
        textMessage={textMessage}
        setMultiFilterDropDown={setFilterCountClick}
        screen="rosters"
        stateName={stateName}
        setStateName={setStateName}
      />
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div className="addStudentFieldsHolder">
        <div
          style={{
            marginTop: "25px",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row"
          }}
        >
          <UxSliderAction
            filterText="Filters"
            activeFilters={activeFilters.current}
            minCount={1}
            multiFilterDropDown={filterCountClick}
            filterStates={filterStates.current}
            setFilterState={filterStates}
            setActiveFilters={activeFilters}
            setMultiFilterDropDown={setFilterCountClick}
            setSliderLeft={setSliderLeft}
            left={sliderLeft}
            setBackgroundVisibility={setContentVisibility}
            setOpacity={setOpacity}
            types={types}
            onChecked={() => {
              setPageNo(1);
              getEnrolledStudents(1, rosterSearchRef.current.value);
            }}
            textMessage={textMessage}
            stateName={stateName}
            setStateName={setStateName}
          />
        </div>
        <div
          className="srstudentSearchCreateButtonHolder"
          style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}
        >
          <UxSearchInput
            flexClassName={getClassExternalId ? "srFlexHolder srFlexHolderRoster" : "srFlexHolder"}
            inputClassName="srInputText"
            placeHolder="Search Student Name, Username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              rosterSearchRef.current.value = e.target.value;

              if (
                rosterSearchRef.current.value !== "" &&
                rosterSearchRef.current.value !== undefined &&
                !searchResultRef.current
              ) {
                document.getElementById("rostercloseIcon").style.display = "block";
                document.getElementById("rosterclearIcon").style.display = "none";
              } else if (
                rosterSearchRef.current.value !== "" &&
                rosterSearchRef.current.value !== undefined &&
                searchResultRef.current
              ) {
                document.getElementById("rostercloseIcon").style.display = "none";
                document.getElementById("rosterclearIcon").style.display = "block";
              } else {
                document.getElementById("rostercloseIcon").style.display = "none";
                document.getElementById("rosterclearIcon").style.display = "none";
              }
            }}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyPress(e);
            }}
            searchButtonClassName="rosterSearchButton"
            searchButtonContent={renderSearchButton()}
            onSearchClick={() => {
              handleSearchButtonClick();
            }}
            clearButtonClassName="rosterclearButton"
            clearButtonContent={renderCancelButton()}
            onClearClick={() => {
              clearButtonClick();
            }}
            restoreCheckBoxState={() => {
              restoreDefaultCheckState();
            }}
          />
          <div style={{ marginRight: "30px" }}>
            <UXCSVExport
              data={csvData}
              headers={headersForCsv}
              filename={
                "Student Roster_" +
                CSVFilename.replace(/[.]/g, " ") +
                "_" +
                (new Date(`${new Date()}`).getUTCMonth() + 1) +
                "_" +
                new Date(`${new Date()}`).getUTCDate() +
                "_" +
                new Date(`${new Date()}`).getUTCFullYear() +
                "_" +
                Date.now()
              }
            />
          </div>
        </div>
      </div>

      {showBanner.banner && (
        <div style={{ marginTop: "10px" }}>
          <UxBanner
            leftIcon={<img src={WarningRosterIcon} alt="" />}
            text={<div style={{ marginLeft: "10px", marginRight: "10px" }}> {showBanner.msg}</div>}
            rightIcon={
              <img
                width="25px"
                height="25px"
                src={CancelIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowBanner({ banner: false, msg: "" });
                }}
              />
            }
            color="#454545"
            backgroundColor="#FDF8ED"
            borderColor="#ebb549"
          />
        </div>
      )}
      {selected.length > 0 ? (
        <div className="bulkOptions">
          <p className="selectcount">
            {" "}
            {selected.length} {selected.length > 1 ? "Students have " : "Student has "}
            been selected.{" "}
          </p>
          {selected.length !== data.values.length ? (
            <p className="selectall" onClick={() => selectAllStudents()}>
              {" "}
              Select All {data.values.length} Students{" "}
            </p>
          ) : null}

          <p className="clearall" onClick={() => clearAllStudents()}>
            {" "}
            Clear Selections{" "}
          </p>
        </div>
      ) : (
        <div className="nobulkOptions"></div>
      )}
      <div ref={elementRef}>
        {data.values.length > 0 ? (
          <UxMUITable
            MUITableClassName={"contentForEnrolledStudent"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IPage).values}
            showColumnCheckbox={false}
            showKebabMenu={false}
            showNameKebabMenu={false}
            viewChild={viewStudent}
            emptyTableText={emptyTableText}
            checkboxRef={"userId"}
            selected={selected}
            setSelected={setSelected}
            mtableHeight={tableHgt}
            showSortList={[true, true, true, false]}
            sortListMapper={{
              "Student Name": [
                studentNameBool,
                setStudentNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              UserName: [
                userNameBool,
                setuserNameBool,
                (args: string) => {
                  sortChange(args);
                }
              ],
              "Grade Level": [
                gradeLevelBool,
                setGradeLevelBool,
                (args: string) => {
                  sortChange(args);
                }
              ]
            }}
          />
        ) : (
          <UxMUITable
            MUITableClassName={"contentForEnrolledStudent"}
            columnNames={columnNames}
            rowMapper={rowMapper}
            rowData={(data as IPage).values}
            showColumnCheckbox={false}
            showKebabMenu={false}
            showNameKebabMenu={false}
            emptyTableText={emptyTableText}
            checkboxRef={"userId"}
            selected={selected}
            setSelected={setSelected}
            mtableHeight={tableHgt}
            showHelpIconFlag={icon || data.values.length > 0}
          />
        )}
      </div>
      {spinner ? null : (
        <div className="fixedpagination">
          <UxPaginate
            data={data}
            page={pageNo}
            paginationSize={getPaginationControllerState[4].paginationCount}
            className={classes.root}
            handleChange={handleChange}
          />
          {data.size > 50 && (
            <UxPaginationController
              rowsList={paginationControllers}
              setRowsCount={getPaginationControllerState[4].paginationCount}
              callback={callback}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(EnrolledStudentsList);
