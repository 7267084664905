import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { useParams } from "react-router-dom";
import ViewEducatorProfile from "./ViewEducatorProfile";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import { UserService } from "../../services/helpers/user.services";
import { EducatorProfileProps } from "./EducatorProfile.props";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails
  };
}
interface Props extends EducatorProfileProps, ReduxProps {}

function USchoolEducatorProfile(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey, setTabKey] = useState("settings");
  const { dispatch, getDaDetails } = props;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.getEducatorDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            handleSuccessResponse(data, uid);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  async function handleSuccessResponse(responsedata: Response, uid: string) {
    let details = await responsedata.json();
    //@ts-ignore
    dispatch(updatePageTitle(`- Users - ${details.firstName} ${details.lastName}`));
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/sausers/${getDaDetails.id}`, name: "Users" },
        //@ts-ignore
        {
          path: `/suvieweducator/${uid}`,
          name: `${details.firstName} ${details.lastName}`
        }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
  }

  const tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <ViewEducatorProfile />
    }
  ];
  return (
    <>
      <div>
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(USchoolEducatorProfile);
