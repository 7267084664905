import React, { useState } from "react";
import {
  UxBreadcrumbs,
  UxElementTypeEnum,
  UxIconEnum,
  UxAction,
  UxShadesEnum,
  TimesIcon
} from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import { IList, BreadCrumsProps } from "./IList";
import { useNavigate } from "react-router-dom";
import { updateBreadCrumbs, updateTabName, updateReportCard, updateSelectedStudentIds } from "../../Redux/Action";
import BulkImportsModal from "../modals/bulk-imports";
import ReduxProps from "../../Redux/Redux.props";

interface Props extends ReduxProps, BreadCrumsProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getHeaderData: state.getHeaderData,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getActivityIndicator: state.getActivityIndicator,
    getTabName: state.getTabName
  };
}

const Breadcrumbs = (props: Props) => {
  const { getBreadCrumbsData, getActivityIndicator, dispatch, getTabName } = props;
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [addstudentroster, setAddRosterStudent] = useState(false);
  const [newmenu, setnewMenu] = useState("");
  const handleClick = (path: string) => {
    if (
      localStorage.getItem("ala:createStudent") === "true" ||
      localStorage.getItem("ala:addRosterStudent") === "true"
    ) {
      if (localStorage.getItem("ala:addRosterStudent") === "true") {
        setAddRosterStudent(true);
      }
      setnewMenu(path);
      setShowCancelModal(true);
    } else {
      if (getTabName === "ReportsTab" || getTabName === "reports") {
        dispatch(updateTabName(null));
      }
      if (path.includes("institutions")) {
        dispatch(updateTabName("schools"));
        localStorage.setItem("page", "schools");
      }
      const index = getBreadCrumbsData.crumbs.findIndex((data: IList) => data.path === path);
      //@ts-ignore
      const crumbs = getBreadCrumbsData;
      const newArray = crumbs.crumbs.filter((_item: IList, i: number) => {
        return i <= index;
      });
      localStorage.setItem("micro:crumbs", JSON.stringify(newArray));
      dispatch(updateBreadCrumbs({ crumbs: newArray }));
      navigate(path);
    }
  };

  function reportsClick(name: string) {
    if (name.toLowerCase() === "reports") {
      dispatch(updateReportCard("home"));
    }
  }

  function exitStudentCreation() {
    dispatch(updateSelectedStudentIds([]));
    setShowCancelModal(false);
    setAddRosterStudent(false);
    localStorage.setItem("ala:addRosterStudent", "false");
    localStorage.setItem("ala:createStudent", "false");
    handleClick(newmenu);
  }
  function renderCancelModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Exit Screen
            </text>
            <img
              className="confirmCancelImageAddStudent"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowCancelModal(false);
              }}
            />
          </div>
          <div>
            {addstudentroster ? (
              <div className="popuptext">
                <text>
                  You’ve selected student(s) to add to your class. If you exit this screen, your selections won’t be
                  saved.
                </text>
              </div>
            ) : (
              <div className="popuptext">
                <text>You’ve started creating a student. If you exit this screen, the student won’t be created.</text>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowCancelModal(false);
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Exit"}
              className="px-2 py-1 closeBut"
              callback={() => {
                exitStudentCreation();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <UxBreadcrumbs
        separator={{ icon: UxIconEnum.CHEVRON_RIGHT }}
        elementType={UxElementTypeEnum.H1}
        children={getBreadCrumbsData.crumbs.map((path: IList) => ({
          title: path.name,
          callback: () => {
            handleClick(path.path);
            reportsClick(path.name);
          },
          loading: getActivityIndicator
        }))}
      />
      <BulkImportsModal
        showBulkModal={showCancelModal}
        children={renderCancelModal()}
        width={addstudentroster ? "780px" : "670px"}
        height={"225px"}
      />
    </>
  );
};

export default connect(mapStateToProps)(Breadcrumbs);
