const config = {
  defaults: {
    theme: "educator",
    maxLevelRequiredPoints: 1000,
    studentTheme: "student_3_5",
    breakpoints: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    },
    basePaths: {
      avatars: "assets:avatars/"
    }
  }
};

export default config;
