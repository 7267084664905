import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  TimesIcon,
  UxAction,
  UxActivityIndicator,
  UxRadioButton,
  UxShadesEnum,
  UxTabs
} from "@wne-spa/ux-component-library";
import ky from "ky";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updateCreateStudent,
  updateStudentsSubtab,
  updateTabName
} from "../../../src/Redux/Action";
import configMessages from "../../helpers/configMessages";
import { gradeLevels } from "../../helpers/configs";
import ReduxProps from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { LMSService } from "../../services/helpers/lms.services";
import { StudentSettingsRequest } from "../../services/helpers/user.service.props";
import { UserService } from "../../services/helpers/user.services";
import BulkImportsModal from "../modals/bulk-imports";
import WNECreateModal from "../modals/UserCreateModal/WNECreateModal";
import {
  CreateStudentFetchSpanishSettingsProps,
  CreateStudentProps,
  IepProps,
  SchoolDataProps
} from "./CreateStudent.props";
import "./createStudent.scss";
import useOrganizations from "../hooks/useOrganizations";
import { getUser } from "authService/AuthService";

interface Props extends ReduxProps, CreateStudentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserId: state.getUserId,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getFlagrKeys: state.getFlagrKeys,
    globalSubjectPurpose: state.globalSubjectPurpose,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}

const CreateStudent = (props: Props) => {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const {
    handleSuccess,
    showModal,
    closeModal,
    getUserRoleId,
    getDaDetails,
    getFlagrKeys,
    dispatch,
    getBreadCrumbsData,
    globalSubjectPurpose,
    getIsOrgHasRegistrationOwned
  } = props;
  const [showModalIndicator, setShowModalIndicator] = useState(false);
  const stateToReset = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    nweid: "",
    renaissanceId: "",
    grade: "Select",
    iep: "Unknown",
    ell: "Unknown",
    school: "Select",
    districtAssignedId: "",
    firstLanguage: "English"
  };
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    nweid: "",
    renaissanceId: "",
    grade: "Select",
    iep: "Unknown",
    ell: "Unknown",
    school: "Select",
    districtAssignedId: "",
    firstLanguage: "English"
  });
  const isIssueText = {
    firstName: configMessages.firstNameValidation,
    lastName: configMessages.lastNameValidation,
    userName: configMessages.userNameValidation,
    password: configMessages.CreateStudentPasswordValidationMin,
    confirmPassword: configMessages.confirmPasswordValidation,
    uniqueName: configMessages.uniqueNameValidation,
    uniqueEmail: configMessages.uniqueEmailValidation,
    gradeSelection: configMessages.GradeLevel,
    districtAssignedId: getIsOrgHasRegistrationOwned ? configMessages.UniqueStudentId : configMessages.UniqueExternalId,
    AllowSpacialChar: configMessages.AllowSpacialChar
  };
  const issueTextToReset = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    gradeSelection: "",
    uniqueID: "",
    districtAssignedId: ""
  };
  const borderColorToReset = "#DDDDDD";
  const borderColorFocus = "#4DA7C9";
  const borderColorSuccess = "#3CBCA6";
  const borderColorError = "#F24E45";
  const [firstNameBorder, setFirstNameBorder] = useState(borderColorToReset);
  const [lastNameBorder, setLastNameBorder] = useState(borderColorToReset);
  const [userNameBorder, setUserNameBorder] = useState(borderColorToReset);
  const [passwordBorder, setPasswordBorder] = useState(borderColorToReset);
  const [confirmPasswordBorder, setConfirmPasswordBorder] = useState(borderColorToReset);
  const [gradeTypeBorder, setGradeTypeBorder] = useState(borderColorToReset);
  const [NWEAIDBorder, setNWEAIDBorder] = useState(borderColorToReset);
  const [RNEIDBorder, setRNEIDBorder] = useState(borderColorToReset);
  const [districtAssignedIDBorder, setDistrictAssignedIDBorder] = useState(borderColorToReset);
  const [districtAssignedIDIssueText, setDistrictAssignedIDIssueText] = useState("");
  const [firstNameIssueText, setFirstNameIssueText] = useState("");
  const [lastNameIssueText, setLastNameIssueText] = useState("");
  const [userNameIssueText, setUserNameIssueText] = useState("");
  const [passwordIssueText, setPasswordIssueText] = useState("");
  const [confirmPasswordIssueText, setConfirmPasswordIssueText] = useState("");
  const [gradeSelectionIssueText, setGradeSelectionIssueText] = useState("");
  const [NWEAIDIssueText, setNWEAIDIssueText] = useState("");
  const [, setRNEIDStatus] = useState(false);
  const [, setNWEAStatus] = useState(false);
  const [RNEIDIssueText, setRNEIDIssueText] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState<IList>();
  const [schoolSelectionBorder, setSchoolSelectionBorder] = useState(borderColorToReset);
  const [schoolSelectionIssueText, setSchoolSelectionIssueText] = useState("");
  const [tabKey, setTabKey] = useState("profile");
  const [spanish, setSpanish] = useState("off");
  const [spanishRef, setSpanishRef] = useState("off");
  const [iep, setIep] = useState("no");
  const [ell, setEll] = useState("no");
  const [textToSpeechMath, setTextToSpeechMath] = useState("off");
  const [textToSpeechReading, setTextToSpeechReading] = useState("off");
  const [textToSpeechSel, setTextToSpeechSel] = useState("off");
  const [translationToSpeechMath, setTranslationToSpeechMath] = useState("off");
  const [translationToSpeechReading, setTranslationToSpeechReading] = useState("off");
  const [translationToSpeechSel, setTranslationToSpeechSel] = useState("off");
  const [enable, setEnable] = useState(false);
  const [spanishMathEnable, setSpanishMathEnable] = useState(false);
  const [spanishScienceEnable, setSpanishScienceEnable] = useState(false);
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const [schoolData, setSchoolData] = useState([]);
  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      color: "#555454e6",
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: 10,
      maxWidth: "none"
    }
  }))(Tooltip);
  const [showToastLang, setShowToastLang] = useState({
    val: false,
    msg: "",
    type: ""
  });
  const [loginCode, setLoginCode] = useState("");
  const [mathEnabled, setMathEnabled] = useState(false);
  const [readingEnabled, setReadingEnabled] = useState(false);
  const [selEnabled, setSelEnabled] = useState(false);
  const [orgMyPathMathEnabled, setOrgMyPathMathEnabled] = useState(false);
  const [orgMyPathReadingEnabled, setOrgMyPathReadingEnabled] = useState(false);
  const [studentMyPathMathEnabled, setStudentMyPathMathEnabled] = useState("off");
  const [studentMyPathReadingEnabled, setStudentMyPathReadingEnabled] = useState("off");
  const [mypathMath, setMypathMath] = useState(false);
  const [mypathReading, setMypathReading] = useState(false);
  const [textSelectedSubjects, setTextSelectedSubjects] = useState([]);
  const [MypathSelectedSubjects, setMypathSelectedSubjects] = useState([]);
  const [languageSelectedSubjects, setLanguageSelectedSubjects] = useState([]);
  const [spanishSelectedSubjects, setSpanishSelectedSubjects] = useState([]);
  const [iepSelected, setIepSelected] = useState("No");
  const [ellSelected, setEllSelected] = useState("No");
  const [niep, setNIep] = useState([]);
  const [ELL, setELL] = useState([]);
  const [scienceEnabled, setScienceEnabled] = useState(false);
  const [gradeCheck, setGradeCheck] = useState(false);
  const [spanishMypath, setSpanishMypath] = useState(false);
  const [spanishScience, setSpanishScience] = useState(false);
  const [showNoSubject, setShowNosubject] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [firstLanguageList, setFirstLanguageList] = useState([]);
  const allSchools = useOrganizations(id);

  interface IList {
    id: number;
    name: string;
    isActive: boolean;
    netsuiteId: string;
    parentId: number;
    parentName: string | null;
    customerName: string;
    childDepth: number;
    orgGUID: string;
  }

  useEffect(() => {
    if (
      state.firstName === "" &&
      state.lastName === "" &&
      state.userName === "" &&
      state.confirmPassword === "" &&
      state.password === "" &&
      state.districtAssignedId === "" &&
      state.renaissanceId === "" &&
      state.nweid === "" &&
      state.grade === "Select" &&
      state.school === "Select"
    ) {
      localStorage.setItem("ala:createStudent", "false");
    }
  }, [state]);

  useEffect(() => {
    UserService.getStudentFirstLanguageSubjects().then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        (async () => {
          const response = JSON.parse(await data.text());
          setFirstLanguageList(response);
        })();
      } else {
        // Do Nothing
      }
    });
    let iep = [
      { id: 0, value: "No" },
      { id: 1, value: "Yes" }
    ];
    setNIep(iep);
    let ell = [
      { id: 0, value: "No" },
      { id: 1, value: "Yes" }
    ];
    setELL(ell);
    localStorage.setItem("ala:createStudent", "false");
  }, []);

  useEffect(() => {
    let schoolId = id.split("_");
    if (allSchools !== null && getUserRoleId === 5 && getDaDetails.type === 2) {
      setSchoolList(allSchools);
    }
    dispatch(updateActivityIndicator(false));
    getLicensedSubjects(schoolId[0]);
  }, [allSchools, getUserRoleId, getDaDetails, id]);

  useEffect(() => {
    let schoolId = id.split("_");
    {
      localStorage.getItem("SpanishMathEnable") === "true" ? setSpanishMathEnable(true) : setSpanishMathEnable(false);
      localStorage.getItem("SpanishScienceEnable") === "true"
        ? setSpanishScienceEnable(true)
        : setSpanishScienceEnable(false);
    }
    let OrgIds = { assessmentSettingsObj: schoolId };
    AdminService.assessmentSettingsdetailWithGuid(OrgIds).then(
      ({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          AssessmentSetting(data);
        } else {
          //do nothing
        }
      }
    );

    getLoginCode();
    fetchspanishids(schoolId[0]);
    fetchMyPathAssessmentSettings(schoolId[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, showModal]);

  const fetchspanishids = async (Ids: string) => {
    let apiObj = { guid: Ids };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
        // do nothing
      }
    });
  };

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: CreateStudentFetchSpanishSettingsProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.text();
    let i = 0;
    let details1 = JSON.parse(details);
    let levelId = { type: "Custom", guid: details1.orgGUID.toString() };

    if (details1.parents != null) {
      let parentid = details1.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };

    fetchSpanishsettings(json);
  }
  const fetchSpanishsettings = async (json: CreateStudentFetchSpanishSettingsProps) => {
    try {
      await AdminService.getCicSettingsWithGuid(json).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotSpanishSettingsResponse(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      // do nothing
    }
  };
  async function gotSpanishSettingsResponse(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    setSpanishMathEnable(details.spanishLanguageEnabled);
    setSpanishScienceEnable(details.spanishLanguageScienceEnabled);
  }

  function getLoginCode() {
    dispatch(updateActivityIndicator(true));
    let orgids = { guid: id };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
        dispatch(updateActivityIndicator(false));
      }
    });
  }

  async function gotLogincodeResponse(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());

    if (details.length > 0) {
      setLoginCode(details[0].loginCode);
    } else {
      //do nothing
    }
    dispatch(updateActivityIndicator(false));
  }
  async function getLicensedSubjects(schoolId: string) {
    try {
      await LMSService.getSubjectsWithGiud(schoolId).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotSubjects(data);
        } else {
          setShowNosubject(false);
          setMathEnabled(false);
          setReadingEnabled(false);
          setSelEnabled(false);
          setScienceEnabled(false);
          dispatch(updateActivityIndicator(false));
          //do nothing
        }
      });
    } catch (error) {
      //do nothing
    }
  }

  const resDataFunc = (details) => {
    let array = [
      { subjectID: 1, subjectName: "Math" },
      { subjectID: 2, subjectName: "Reading" },
      { subjectID: 5, subjectName: globalSubjectPurpose },
      { subjectID: 6, subjectName: "Science" }
    ];
    let res = array.filter(
      (item1) =>
        !details.some((item2) => item2.subjectID === item1.subjectID && item2.subjectName === item1.subjectName)
    );
    if (res.length !== 0) {
      for (let y in res) {
        if (res[y].subjectName === "Math") {
          setMathEnabled(false);
        } else if (res[y].subjectName === "Reading") {
          setReadingEnabled(false);
        } else if (res[y].subjectName === globalSubjectPurpose) {
          setSelEnabled(false);
        } else if (res[y].subjectName === "Science") {
          setScienceEnabled(false);
        }
      }
    }
  };

  async function gotSubjects(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //@ts-ignore
    for (let x in details) {
      if (details[x].subjectName === "Math") {
        setShowNosubject(true);
        setMathEnabled(true);
      } else if (details[x].subjectName === "Reading") {
        setShowNosubject(true);
        setReadingEnabled(true);
      } else if (details[x].subjectName === globalSubjectPurpose) {
        setShowNosubject(true);
        setSelEnabled(true);
      } else if (details[x].subjectName === "Science") {
        setShowNosubject(true);
        setScienceEnabled(true);
      }
    }
    resDataFunc(details);
    dispatch(updateActivityIndicator(false));
  }

  async function fetchMyPathAssessmentSettings(schoolId: string) {
    try {
      let guid = { orgGuid: schoolId };
      await AdminService.mypathAssessmentSettingsWithGuid(guid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setMyPathAssessmentSettingsData(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setMyPathAssessmentSettingsData(responsedata: Response) {
    let details = JSON.parse(await responsedata.text());
    //@ts-ignore
    setOrgMyPathMathEnabled(details.myPathMathEnabled);
    //@ts-ignore
    setOrgMyPathReadingEnabled(details.myPathELAEnabled);
    let arry = [];
    if (details.myPathMathEnabled) {
      setMypathMath(true);
      setStudentMyPathMathEnabled("on");
      arry.push("Math");
      setMypathSelectedSubjects(arry);
    }
    if (details.myPathELAEnabled) {
      setMypathReading(true);
      setStudentMyPathReadingEnabled("on");
      arry.push("Reading");
      setMypathSelectedSubjects(arry);
    }
  }

  async function AssessmentSetting(responsedata: Response) {
    let newData = JSON.parse(await responsedata.text());
    try {
      if (newData.length === 1) {
        setRNEIDStatus(newData[0].renaissanceEnabled);
        setNWEAStatus(newData[0].nweaEnabled);
      } else {
        setSchoolData(newData);
      }
    } catch (error) {}
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const closeStudentModal = () => {
    closeModal();
    setState(stateToReset);
    setFirstNameIssueText(issueTextToReset.firstName);
    setLastNameIssueText(issueTextToReset.lastName);
    setUserNameIssueText(issueTextToReset.userName);
    setPasswordIssueText(issueTextToReset.password);
    setConfirmPasswordIssueText(issueTextToReset.confirmPassword);
    setGradeSelectionIssueText(issueTextToReset.gradeSelection);
    setFirstNameBorder(borderColorToReset);
    setLastNameBorder(borderColorToReset);
    setUserNameBorder(borderColorToReset);
    setPasswordBorder(borderColorToReset);
    setConfirmPasswordBorder(borderColorToReset);
    setGradeTypeBorder(borderColorToReset);
    setDistrictAssignedIDIssueText("");
    setDistrictAssignedIDBorder(borderColorToReset);
    setNWEAIDIssueText("");
    setNWEAIDBorder(borderColorToReset);
    setRNEIDIssueText("");
    setRNEIDBorder(borderColorToReset);
    setSchoolSelectionIssueText("");
    setSchoolSelectionBorder(borderColorToReset);
    setEnable(false);
    setEll("no");
    setIep("no");
    setSpanish("off");
    setSpanishRef("off");
    setTextToSpeechMath("off");
    setTextToSpeechReading("off");
    setTextToSpeechSel("off");
    setTranslationToSpeechMath("off");
    setTranslationToSpeechReading("off");
    setTranslationToSpeechSel("off");
    setTabKey("profile");
  };

  const setIssueTextAndBorder = (inputType: string) => {
    if (inputType === "firstName") {
      setFirstNameIssueText(issueTextToReset.firstName);
      setFirstNameBorder(borderColorToReset);
    } else if (inputType === "lastName") {
      setLastNameIssueText(issueTextToReset.lastName);
      setLastNameBorder(borderColorToReset);
    } else if (inputType === "userName") {
      setUserNameIssueText(issueTextToReset.userName);
      setUserNameBorder(borderColorToReset);
    } else if (inputType === "nweid") {
      setNWEAIDBorder(borderColorToReset);
      setNWEAIDIssueText("");
    } else if (inputType === "password") {
      setPasswordBorder(borderColorToReset);
      setPasswordIssueText("");
    } else if (inputType === "confirmPassword") {
      setConfirmPasswordBorder(borderColorToReset);
      setConfirmPasswordIssueText("");
    } else if (inputType === "renaissanceId") {
      setRNEIDBorder(borderColorToReset);
      setRNEIDIssueText("");
    } else if (inputType === "gradeType") {
      setGradeSelectionIssueText("");
      setGradeTypeBorder(borderColorToReset);
    } else if (inputType === "districtAssignedId") {
      setDistrictAssignedIDBorder(borderColorToReset);
      setDistrictAssignedIDIssueText("");
    } else {
      //do nothing
    }
  };
  function handleTextChange(event: React.ChangeEvent<HTMLInputElement> | string, inputType: string): void {
    let inputTypeArray = ["userName", "confirmPassword"];
    setIssueTextAndBorder(inputType);
    if (inputType === "gradeType") {
      setState({ ...state, grade: event as string });
    } else if (inputType === "firstLanguage") {
      setState({ ...state, firstLanguage: event as string });
    } else if (
      inputType === "userName" &&
      (event as React.ChangeEvent<HTMLInputElement>).target.value.includes(" ") &&
      (event as React.ChangeEvent<HTMLInputElement>).target.value.charAt(0) !== " "
    ) {
      setState({
        ...state,
        [inputType]: (event as React.ChangeEvent<HTMLInputElement>).target.value
      });
    } else if (
      !inputTypeArray.includes(inputType) &&
      !(event as React.ChangeEvent<HTMLInputElement>).target.value.includes(" ")
    ) {
      setState({
        ...state,
        [inputType]: (event as React.ChangeEvent<HTMLInputElement>).target.value
      });
    } else if (
      (inputType === "firstName" || inputType === "lastName") &&
      (event as React.ChangeEvent<HTMLInputElement>).target.value.charAt(0) !== " "
    ) {
      setState({
        ...state,
        [inputType]: (event as React.ChangeEvent<HTMLInputElement>).target.value
      });
    } else {
      if (!(event as React.ChangeEvent<HTMLInputElement>).target.value.includes(" ")) {
        setState({
          ...state,
          [inputType]: (event as React.ChangeEvent<HTMLInputElement>).target.value
        });
      }
    }
    if (getFlagrKeys["S54-3369-NewCreateStudentDesign"]) {
      localStorage.setItem("ala:createStudent", "true");
    }
  }

  function validateFirstName() {
    if (state.firstName === "") {
      setFirstNameIssueText(isIssueText.firstName);
      setFirstNameBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      setFirstNameIssueText(issueTextToReset.firstName);
      setFirstNameBorder(borderColorSuccess);
    }
  }
  function validateLastName() {
    if (state.lastName === "") {
      setLastNameIssueText(isIssueText.lastName);
      setLastNameBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      setLastNameIssueText(issueTextToReset.lastName);
      setLastNameBorder(borderColorSuccess);
    }
  }

  function validateUserName() {
    if (state.userName === "") {
      setUserNameIssueText(isIssueText.userName);
      setUserNameBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else if (state.userName.trim().length < 4) {
      setUserNameIssueText(configMessages.UsernameMinMaxCharacterCheckMessage);
      setUserNameBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      if (userNameIssueText !== "Please enter a unique username.") {
        setUserNameIssueText(issueTextToReset.userName);
        setUserNameBorder(borderColorSuccess);
      } else {
        // do nothing
      }
    }
  }

  function validatePasswordName() {
    if (state.password.length >= 4 && !/\\/g.test(state.password)) {
      setPasswordIssueText(issueTextToReset.password);
      setPasswordBorder(borderColorSuccess);
    } else {
      if (state.password.length > 256) {
        setPasswordIssueText(configMessages.CreateEducatorpasswordValdiationMax);
        setPasswordBorder(borderColorError);
      } else {
        setPasswordIssueText(
          /\\/g.test(state.password)
            ? configMessages.CreateStudentPasswordValidationControlCharacter
            : isIssueText.password
        );
        setPasswordBorder(borderColorError);
        setShowToast({
          val: true,
          msg: configMessages.studentCreationValidation,
          type: "warning"
        });
      }
    }
  }

  function validateConfirmPasswordName() {
    if (state.password !== state.confirmPassword || state.confirmPassword === "") {
      setConfirmPasswordIssueText(isIssueText.confirmPassword);
      setConfirmPasswordBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      setConfirmPasswordIssueText(issueTextToReset.confirmPassword);
      setConfirmPasswordBorder(borderColorSuccess);
    }
  }

  function validateGradeName() {
    if (state.grade === "Select") {
      setGradeSelectionIssueText(isIssueText.gradeSelection);
      setGradeTypeBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      setGradeSelectionIssueText(issueTextToReset.gradeSelection);
      setGradeTypeBorder(borderColorSuccess);
    }
  }

  function validateSchoolSelection(clickedValue: string) {
    if (state.school === "Select") {
      setSchoolSelectionIssueText(configMessages.teacherschoolSelectValidation);
      setSchoolSelectionBorder(borderColorError);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
    } else {
      setSchoolSelectionIssueText("");
      setSchoolSelectionBorder(borderColorSuccess);
      if (clickedValue === "save") {
        CreateStudentUser();
      } else {
        //do nothing
      }
    }
  }

  function validateExternalId(fuctName: string) {
    if (getIsOrgHasRegistrationOwned) {
      if (state.districtAssignedId === "") {
        setDistrictAssignedIDIssueText(configMessages.StudentIDValidation);
        setDistrictAssignedIDBorder(borderColorError);
        setShowToast({
          val: true,
          msg: configMessages.studentCreationValidation,
          type: "warning"
        });
      } else {
        setDistrictAssignedIDIssueText(issueTextToReset.districtAssignedId);
        setDistrictAssignedIDBorder(borderColorSuccess);
      }
    } else {
      if (fuctName === "Admin") {
        setDistrictAssignedIDIssueText(issueTextToReset.districtAssignedId);
        setDistrictAssignedIDBorder(borderColorSuccess);
      }
    }
  }

  function updateUsernameError() {
    setUserNameIssueText(isIssueText.uniqueName);
    setUserNameBorder(borderColorError);
    setShowToast({
      val: true,
      msg: configMessages.studentCreationValidation,
      type: "warning"
    });
    setTabKey("profile");
    localStorage.setItem("page", "Profile");
  }
  function validateUserNameBackend(getError: string) {
    if (getError.includes("Loginname already exists.")) {
      updateUsernameError();
    } else if (getError.includes("DuplicateKey")) {
      if (getError.includes("Username")) {
        updateUsernameError();
      }
    } else if (getError.includes("Please enter a valid username with allowed special characters.")) {
      setUserNameIssueText(isIssueText.AllowSpacialChar);
      setUserNameBorder(borderColorError);
    } else {
      //do nothing
    }
  }

  function validateRenaissanceID(getError: string) {
    if (getError.includes("RenaissanceId must be a unique value")) {
      setRNEIDBorder(borderColorError);
      setRNEIDIssueText(configMessages.NWEAIdValidation);
      setShowToast({
        val: true,
        msg: configMessages.studentCreationValidation,
        type: "warning"
      });
      if (userNameIssueText === "") {
        setTabKey(getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "profile" : "details");
      }
    } else {
      setRNEIDIssueText(issueTextToReset.uniqueID);
    }
  }
  function updatedExtrenalIDError() {
    setDistrictAssignedIDIssueText(isIssueText.districtAssignedId);
    setDistrictAssignedIDBorder(borderColorError);
    setShowToast({
      val: true,
      msg: configMessages.studentCreationValidation,
      type: "warning"
    });
    setTabKey("profile");
    localStorage.setItem("page", "Profile");
  }

  function validateExternalIDBackend(getError: string) {
    if (getError.includes("Please enter a unique External ID.")) {
      updatedExtrenalIDError();
    } else if (getError.includes("DuplicateKey")) {
      if (getError.includes("StudentNumber") || getError.includes("Tag")) {
        updatedExtrenalIDError();
      }
    } else {
      //do nothing
    }
  }

  async function CreateStudentUser() {
    let iepsubject: number;
    //@ts-ignore
    if (iepSelected === "Yes") {
      iepsubject = 1;
    } else {
      iepsubject = 0;
    }

    let ellsubject: number;
    //@ts-ignore
    if (ellSelected === "Yes") {
      ellsubject = 1;
    } else {
      ellsubject = 0;
    }

    let textMath = false;
    let textReading = false;
    let textSel = false;
    let textScience = false;

    for (let x in textSelectedSubjects) {
      if (textSelectedSubjects[x] === "Math") {
        textMath = true;
      } else if (textSelectedSubjects[x] === "Reading") {
        textReading = true;
      } else if (textSelectedSubjects[x] === "Science") {
        textScience = true;
      } else if (textSelectedSubjects[x] === "Purpose Prep SEL") {
        textSel = true;
      }
    }

    let languageMath = false;
    let languageReading = false;
    let languageSel = false;
    let languageScience = false;

    for (let x in languageSelectedSubjects) {
      if (languageSelectedSubjects[x] === "Math") {
        languageMath = true;
      } else if (languageSelectedSubjects[x] === "Reading") {
        languageReading = true;
      } else if (languageSelectedSubjects[x] === "Science") {
        languageScience = true;
      } else if (languageSelectedSubjects[x] === "Purpose Prep SEL") {
        languageSel = true;
      }
    }

    let myPathMath = false;
    let myPathReading = false;
    for (let x in MypathSelectedSubjects) {
      if (MypathSelectedSubjects[x] === "Math") {
        myPathMath = true;
      } else if (MypathSelectedSubjects[x] === "Reading") {
        myPathReading = true;
      }
    }

    let spanishMath = false;
    let spanishMyPath = false;
    for (let x in spanishSelectedSubjects) {
      if (spanishSelectedSubjects[x] === "Math") {
        spanishMath = true;
      } else if (spanishSelectedSubjects[x] === "MyPath" && orgMyPathMathEnabled && myPathMath) {
        spanishMyPath = true;
      }
    }

    let newStudentSettingsRequest: StudentSettingsRequest[] = [
      {
        SettingID: 1,
        SubjectID: 1,
        IsOn: textMath
      },
      {
        SettingID: 1,
        SubjectID: 2,
        IsOn: textReading
      },
      {
        SettingID: 1,
        SubjectID: 5,
        IsOn: textSel
      },
      {
        SettingID: 1,
        SubjectID: 6,
        IsOn: textScience
      },
      {
        SettingID: 2,
        SubjectID: 1,
        IsOn: languageMath
      },
      {
        SettingID: 2,
        SubjectID: 2,
        IsOn: languageReading
      },
      {
        SettingID: 2,
        SubjectID: 5,
        IsOn: languageSel
      },
      {
        SettingID: 2,
        SubjectID: 6,
        IsOn: languageScience
      },
      {
        SettingID: 3,
        SubjectID: 1,
        IsOn: spanishMath
      },
      {
        SettingID: 3,
        SubjectID: 6,
        IsOn: spanishScience
      },
      {
        SettingID: 4,
        SubjectID: 1,
        IsOn: myPathMath
      },
      {
        SettingID: 4,
        SubjectID: 2,
        IsOn: myPathReading
      },
      {
        SettingID: 5,
        SubjectID: 1,
        IsOn: spanishMyPath
      }
    ];
    setShowModalIndicator(true);
    try {
      let getIEP;
      let getELL;
      let getGrade;

      if (iep === "unknown") {
        getIEP = 1;
      } else if (iep === "yes") {
        getIEP = 2;
      } else {
        getIEP = 3;
      }
      if (ell === "unknown") {
        getELL = 1;
      } else if (ell === "yes") {
        getELL = 2;
      } else {
        getELL = 3;
      }
      if (state.grade === "PK") {
        getGrade = -1;
      } else if (state.grade === "K") {
        getGrade = 1;
      } else if (state.grade === "1") {
        getGrade = 2;
      } else if (state.grade === "2") {
        getGrade = 3;
      } else if (state.grade === "3") {
        getGrade = 4;
      } else if (state.grade === "4") {
        getGrade = 5;
      } else if (state.grade === "5") {
        getGrade = 6;
      } else if (state.grade === "6") {
        getGrade = 7;
      } else if (state.grade === "7") {
        getGrade = 8;
      } else if (state.grade === "8") {
        getGrade = 9;
      } else if (state.grade === "9") {
        getGrade = 10;
      } else if (state.grade === "10") {
        getGrade = 11;
      } else if (state.grade === "11") {
        getGrade = 12;
      } else if (state.grade === "12") {
        getGrade = 13;
      } else {
        getGrade = 14;
      }
      const clientDateTime = moment().format();
      const jsonObj = {
        LoginName: state.userName.trim(),
        Password: state.password,
        FirstName: state.firstName.trim(),
        LastName: state.lastName.trim(),
        GradeLevelId: getGrade,
        NWEAId: state.nweid,
        renaissanceId: state.renaissanceId,
        IEP: getIEP,
        ELL: getELL,
        ClientDateTime: clientDateTime,
        DistrictAssignedId: state.districtAssignedId,
        ExternalID: getIsOrgHasRegistrationOwned ? "" : state.districtAssignedId,
        StudentSettingsRequest: [
          {
            SettingID: 1,
            SubjectID: 1,
            IsOn: textToSpeechMath === "on" ? true : false
          },
          {
            SettingID: 1,
            SubjectID: 2,
            IsOn: textToSpeechReading === "on" ? true : false
          },
          {
            SettingID: 1,
            SubjectID: 5,
            IsOn: textToSpeechSel === "on" ? true : false
          },
          {
            SettingID: 1,
            SubjectID: 6,
            IsOn: false
          },
          {
            SettingID: 2,
            SubjectID: 1,
            IsOn: translationToSpeechMath === "on" ? true : false
          },
          {
            SettingID: 2,
            SubjectID: 2,
            IsOn: translationToSpeechReading === "on" ? true : false
          },
          {
            SettingID: 2,
            SubjectID: 5,
            IsOn: translationToSpeechSel === "on" ? true : false
          },
          {
            SettingID: 2,
            SubjectID: 6,
            IsOn: false
          },
          {
            SettingID: 3,
            SubjectID: 1,
            IsOn: spanish === "on" ? true : false
          },
          {
            SettingID: 3,
            SubjectID: 6,
            IsOn: false
          },
          {
            SettingID: 4,
            SubjectID: 1,
            IsOn: studentMyPathMathEnabled === "on" ? true : false
          },
          {
            SettingID: 4,
            SubjectID: 2,
            IsOn: studentMyPathReadingEnabled === "on" ? true : false
          },
          {
            SettingID: 5,
            SubjectID: 1,
            IsOn: false
          }
        ],
        firstLanguageId: firstLanguageList?.find((ele) => ele.languageName === state.firstLanguage)?.languageID,
        loginCode: loginCode
      };
      const newjsonObj = {
        LoginName: state.userName,
        Password: state.password,
        FirstName: state.firstName.trim(),
        LastName: state.lastName.trim(),
        GradeLevelId: getGrade,
        NWEAId: state.nweid,
        renaissanceId: state.renaissanceId,
        IEP: iepsubject,
        ELL: ellsubject,
        ClientDateTime: clientDateTime,
        DistrictAssignedId: state.districtAssignedId,
        ExternalID: getIsOrgHasRegistrationOwned ? "" : state.districtAssignedId,
        StudentSettingsRequest: newStudentSettingsRequest,
        loginCode: loginCode,
        firstLanguageId: firstLanguageList?.find((ele) => ele.languageName === state.firstLanguage)?.languageID
      };
      let sid = id;
      if (getUserRoleId === 5 && getDaDetails.type === 2) {
        sid = selectedSchool.orgGUID;
      }
      UserService.addStudentWithGuid(getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? newjsonObj : jsonObj, sid).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            setShowModalIndicator(false);
            if (getFlagrKeys["S54-3369-NewCreateStudentDesign"]) {
              dispatch(updateCreateStudent(true));
              exit();
            } else {
              handleSuccess();
              closeStudentModal();
            }
          } else {
            addStudentErrorResponse(data as ky.HTTPError);
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  const validationErrorMessage = (errorMessage: string) => {
    if (errorMessage.includes("RenaissanceId must be a unique value")) {
      validateRenaissanceID(errorMessage);
    } else if (errorMessage.includes("Loginname already exists.")) {
      validateUserNameBackend(errorMessage);
    } else if (errorMessage.includes("DuplicateKey")) {
      if (errorMessage.includes("Username")) {
        validateUserNameBackend(errorMessage);
      }
      if (errorMessage.includes("StudentNumber") || errorMessage.includes("Tag")) {
        validateExternalIDBackend(errorMessage);
      }
    } else if (errorMessage.includes("Please enter a unique External ID.")) {
      validateExternalIDBackend(errorMessage);
    } else if (errorMessage.includes("Please enter a valid username with allowed special characters.")) {
      validateUserNameBackend(errorMessage);
    }
  };

  async function addStudentErrorResponse(responsedata: ky.HTTPError) {
    let responseStudents = JSON.parse(await responsedata.response.text());
    setShowModalIndicator(false);
    if (responseStudents?.status === 400) {
      setShowToast({
        val: true,
        msg: responseStudents?.errors?.Password[0],
        type: "warning"
      });
    }
    try {
      const errMsg = responseStudents.message.split(", ");
      for (let errorMessage of errMsg) {
        if (errorMessage.includes("NWEAId must be a unique value")) {
          setNWEAIDBorder(borderColorError);
          setNWEAIDIssueText(configMessages.NWEAIdValidation);
          setShowToast({
            val: true,
            msg: configMessages.studentCreationValidation,
            type: "warning"
          });
          if (userNameIssueText === "") {
            setTabKey(getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "profile" : "details");
          }
        } else {
          validationErrorMessage(errorMessage);
        }
      }
      if (errMsg === "Unauthorized") {
        alert("Unauthorized to create users");
      } else {
        // do nothing
      }
    } catch (err) {}
  }
  function validateSpanishMath() {
    state.grade === "K" ||
    state.grade === "5" ||
    state.grade === "1" ||
    state.grade === "2" ||
    state.grade === "3" ||
    (state.grade === "4" && spanishMathEnable)
      ? setSpanish(spanishRef)
      : setSpanish("off");
  }

  const setSelectedSubjectGradeCheck = (tab: string) => {
    if (
      state.grade === "K" ||
      state.grade === "1" ||
      state.grade === "2" ||
      state.grade === "3" ||
      state.grade === "4" ||
      state.grade === "5"
    ) {
      setGradeCheck(true);
    } else {
      let filtersubjectTab = textSelectedSubjects.filter((word) => word !== "Science");
      setTextSelectedSubjects(filtersubjectTab);
      let filtersubjectTabLang = languageSelectedSubjects.filter((word) => word !== "Science");
      setLanguageSelectedSubjects(filtersubjectTabLang);
      let filtersubjectTabSMath = spanishSelectedSubjects.filter((word) => word !== "Math");
      setSpanishSelectedSubjects(filtersubjectTabSMath);
      setGradeCheck(false);
      setSpanishScience(false);
    }
    if (getUserRoleId === 5 && getDaDetails.type === 2) {
      if (state.school !== "Select") {
        setTabKey(tab);
        localStorage.setItem("page", tab);
      } else {
        //do nothing
      }
    } else {
      setTabKey(tab);
      localStorage.setItem("page", tab);
    }
  };
  function validateFirstTab(tab: string) {
    const externalIdValidate = getIsOrgHasRegistrationOwned ? state.districtAssignedId !== "" : true;
    validateFirstName();
    validateLastName();
    validateUserName();
    validatePasswordName();
    validateConfirmPasswordName();
    validateGradeName();
    validateSpanishMath();
    validateExternalId("FirstTab");
    if (getUserRoleId === 5 && getDaDetails.type === 2) {
      validateSchoolSelection(tab);
      fetchMyPathAssessmentSettings(selectedSchool.orgGUID);
      getLicensedSubjects(selectedSchool.orgGUID);
    }
    if (tab === "details" || tab === "settings") {
      if (
        state.password.length >= 4 &&
        state.password === state.confirmPassword &&
        schoolSelectionIssueText === "" &&
        firstNameIssueText === "" &&
        lastNameIssueText === "" &&
        userNameIssueText === "" &&
        gradeSelectionIssueText === "" &&
        state.firstName !== "" &&
        state.lastName !== "" &&
        state.userName !== "" &&
        state.userName.trim().length > 3 &&
        state.grade !== "Select" &&
        !/\\/g.test(state.password) &&
        externalIdValidate
      ) {
        setSelectedSubjectGradeCheck(tab);
      } else {
        //do nothing
      }
    } else {
      setTabKey(tab);
      localStorage.setItem("page", tab);
    }
  }

  function validateAdmin() {
    validateFirstName();
    validateLastName();
    validateUserName();
    validatePasswordName();
    validateConfirmPasswordName();
    validateGradeName();
    validateExternalId("Admin");
    setEnable(true);

    if (
      getUserRoleId !== 5 &&
      firstNameIssueText === "" &&
      lastNameIssueText === "" &&
      userNameIssueText === "" &&
      passwordIssueText === "" &&
      confirmPasswordIssueText === "" &&
      gradeSelectionIssueText === "" &&
      NWEAIDIssueText === ""
    ) {
      CreateStudentUser();
    } else {
      if (
        getUserRoleId === 5 &&
        getDaDetails.type === 1 &&
        firstNameIssueText === "" &&
        lastNameIssueText === "" &&
        userNameIssueText === "" &&
        passwordIssueText === "" &&
        confirmPasswordIssueText === "" &&
        gradeSelectionIssueText === "" &&
        NWEAIDIssueText === ""
      ) {
        CreateStudentUser();
      } else {
        validateSchoolSelection("save");
      }
    }
  }
  function handleTexttoSpeechReading(value: string) {
    setTextToSpeechReading(value);
    setShowToast({
      val: true,
      msg: configMessages.enableReading,
      type: "reading"
    });
  }

  function handleLanguageReading(value: string) {
    setTranslationToSpeechReading(value);
    setShowToastLang({
      val: true,
      msg: configMessages.enableReadingLanguage,
      type: "reading"
    });
  }

  function assessmentValidation(ids: number) {
    schoolData.forEach((data: SchoolDataProps) => {
      if (data.organizationID === ids) {
        setRNEIDStatus(data.renaissanceEnabled);
        setNWEAStatus(data.nweaEnabled);
      } else {
        //do nothing
      }
      return 0;
    });
  }
  function selectSchools(row: IList) {
    setSelectedSchool(row);
    setState({ ...state, school: row.name });
    assessmentValidation(row.id);
    fetchspanishids(row.orgGUID);
    if (getFlagrKeys["S54-3369-NewCreateStudentDesign"]) {
      localStorage.setItem("ala:createStudent", "true");
    }
  }

  function renderSchoolSelectionList() {
    return schoolList.map((row: IList) => {
      return (
        <NavDropdown.Item
          onClick={() => {
            selectSchools(row);
          }}
          className="supportTextForm"
          eventKey="active"
        >
          {row.name}
        </NavDropdown.Item>
      );
    });
  }

  const userProfileDetailsHolderFunc = () => {
    return (
      <div className="userProfileDetailsHolderCS">
        <div className="labelInputHolder">
          <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
            Username *
          </label>
          <div
            style={{
              border: `1.3px solid ${userNameBorder}`,
              borderRadius: "3px"
            }}
          >
            <input
              className="eTextInput"
              maxLength={128}
              minLength={4}
              placeholder="Username"
              value={state.userName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "userName")}
              autoComplete="new-password"
            />
          </div>
          <label className="labelForIssue">{userNameIssueText}</label>
        </div>
        {getUserRoleId === 5 && getDaDetails.type === 2 ? (
          <div className="labelInputHolder">
            <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
              School*
            </label>
            <div
              style={{
                border: `1.3px solid ${schoolSelectionBorder}`,
                borderRadius: "3px"
              }}
              onClick={() => {
                setSchoolSelectionBorder(borderColorFocus);
                setSchoolSelectionIssueText("");
              }}
              onBlur={() => {
                setGradeTypeBorder(borderColorToReset);
              }}
            >
              <NavDropdown id="eselectUserType" title={state.school} style={{ paddingTop: "7px" }}>
                {renderSchoolSelectionList()}
              </NavDropdown>
            </div>
            <label className="labelForIssue">{schoolSelectionIssueText}</label>
          </div>
        ) : (
          <div style={{ height: "82px" }}></div>
        )}
      </div>
    );
  };

  function renderStudentProfileTab() {
    return (
      <>
        <div className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newdesignscroll" : ""}>
          {showModalIndicator ? (
            <div className="fullScreenIndicator">
              <UxActivityIndicator position={"fixed !important"} opacity={0.5} />
            </div>
          ) : null}
          <div className="userProfileDetailsHolderCS">
            <div className="labelInputHolder">
              <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
                First Name *
              </label>
              <div
                style={{
                  border: `1.3px solid ${firstNameBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="eTextInput"
                  maxLength={64}
                  minLength={1}
                  placeholder="First Name"
                  value={state.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "firstName")}
                />
              </div>
              <label className="labelForIssue">{firstNameIssueText}</label>
            </div>
            <div className="labelInputHolder">
              <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
                Last Name *
              </label>
              <div
                style={{
                  border: `1.3px solid ${lastNameBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  className="eTextInput"
                  maxLength={64}
                  minLength={1}
                  placeholder="Last Name"
                  value={state.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "lastName")}
                />
              </div>
              <label className="labelForIssue">{lastNameIssueText}</label>
            </div>
          </div>
          {userProfileDetailsHolderFunc()}
          <div className="userProfileDetailsHolderCS">
            <div className="labelInputHolder">
              <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
                Grade Level*
              </label>
              <div
                style={{
                  width: "100px",
                  border: `1.3px solid ${gradeTypeBorder}`,
                  borderRadius: "3px",
                  paddingTop: "5px"
                }}
                onClick={() => {
                  setGradeTypeBorder(borderColorFocus);
                }}
                onBlur={() => {
                  setGradeTypeBorder(borderColorToReset);
                }}
              >
                <NavDropdown id="eselectGradeLevelType" title={state.grade} className="supportTextForm">
                  {Object.keys(gradeLevels)
                    .filter((grades) =>
                      getFlagrKeys["ReadinessFeature_Users_Groups_Events"] && getIsOrgHasRegistrationOwned
                        ? grades
                        : grades !== "PK" && grades !== "Other"
                    )
                    .map((grade) => (
                      <NavDropdown.Item
                        className="supportTextForm"
                        eventKey="active"
                        onClick={() => {
                          handleTextChange(gradeLevels[grade], "gradeType");
                          setGradeSelectionIssueText(issueTextToReset.gradeSelection);
                        }}
                        key={grade}
                      >
                        {gradeLevels[grade]}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              </div>
              <label className="labelForIssue">{gradeSelectionIssueText}</label>
            </div>
          </div>
          <div className="userProfileDetailsHolderCS">
            <div className="labelInputHolder">
              <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
                New Password *
              </label>
              <div
                style={{
                  border: `1.3px solid ${passwordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  type="password"
                  value={state.password}
                  minLength={4}
                  className="eTextInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "password")}
                  autoComplete="new-password"
                />
              </div>
              <label className="labelForPasswordIssue">{passwordIssueText}</label>
            </div>
            <div className="labelInputHolder">
              <label className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}>
                Confirm Password *
              </label>
              <div
                style={{
                  border: `1.3px solid ${confirmPasswordBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  type="password"
                  value={state.confirmPassword}
                  className="eTextInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "confirmPassword")}
                />
              </div>
              <label className="labelForIssue">{confirmPasswordIssueText}</label>
            </div>
          </div>
          {getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? (
            <>
              <div className="userProfileDetailsHolderCS">
                <div className="labelInputHolder">
                  <label className="newlabelForInput">Renaissance ID</label>
                  <div
                    style={{
                      border: `1.3px solid ${RNEIDBorder}`,
                      borderRadius: "3px"
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Renaissance ID"
                      value={state.renaissanceId}
                      className="eTextInput"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "renaissanceId")}
                    />
                  </div>
                  <label className="labelForIssue">{RNEIDIssueText}</label>
                </div>
                <div className="labelInputHolder">
                  <label className="newlabelForInput">NWEA ID</label>
                  <div
                    style={{
                      border: `1.3px solid ${NWEAIDBorder}`,
                      borderRadius: "3px"
                    }}
                  >
                    <input
                      type="text"
                      placeholder="NWEA ID"
                      value={state.nweid}
                      className="eTextInput"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "nweid")}
                    />
                  </div>
                  <label className="labelForIssue">{NWEAIDIssueText}</label>
                </div>
                {getUserRoleId !== 5 || getIsOrgHasRegistrationOwned ? (
                  <div className="labelInputHolder" style={{ marginBottom: "10px" }}>
                    <label
                      className={getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? "newlabelForInput" : "labelForInput"}
                    >
                      {getIsOrgHasRegistrationOwned ? "Student ID *" : "External ID"}
                    </label>
                    <div
                      style={{
                        border: `1.3px solid ${districtAssignedIDBorder}`,
                        borderRadius: "3px"
                      }}
                    >
                      <input
                        type="text"
                        placeholder={getIsOrgHasRegistrationOwned ? "STUDENT ID" : "EXTERNAL ID"}
                        value={state.districtAssignedId}
                        className="eTextInput"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "districtAssignedId")}
                        maxLength={64}
                      />
                    </div>
                    <label className="labelForIssue">{districtAssignedIDIssueText}</label>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {getFlagrKeys["S54-3369-NewCreateStudentDesign"] ? (
          <div className="newdesignprofilefooter">
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              className="px-2 py-1 me-2 cursor secondaryLightButton"
              priority={UxShadesEnum.LIGHT}
              callback={() => {
                cancelCreate();
              }}
            />
            <UxAction
              key={0}
              size="sm"
              text={"Next"}
              className="cursor turkishBlue"
              callback={() => {
                validateFirstTab("settings");
              }}
            />
          </div>
        ) : null}
      </>
    );
  }

  function RadioButtonsGroup(data: IepProps, type: string, checkedValue: string) {
    return data.map((row) => {
      return (
        <FormControlLabel
          value="Unknown"
          control={
            <UxRadioButton
              checked={row.value === checkedValue}
              value={row.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onRadioSelected(e, type);
              }}
              name={type}
            />
          }
          label={row.value}
        />
      );
    });
  }

  function onRadioSelected(e: React.ChangeEvent<HTMLInputElement>, type: string) {
    const value = e.target.value;
    if (type === "IEP") {
      setIepSelected(value);
    } else if (type === "ELL") {
      setEllSelected(value);
    } else {
      //do nothing
    }
  }

  function renderLangCheckBoxes(type: string) {
    return (
      <FormGroup>
        {mathEnabled ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  type === "TestToSpeech"
                    ? textSelectedSubjects.includes("Math")
                    : languageSelectedSubjects.includes("Math")
                }
                style={{ marginRight: "-12px" }}
                //@ts-ignore
                onChange={() => {
                  selectedSubject("Math", type);
                }}
                name="subjects"
                size="small"
              />
            }
            //@ts-ignore
            label={"Math"}
          />
        ) : null}
        {readingEnabled ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  type === "TestToSpeech"
                    ? textSelectedSubjects.includes("Reading")
                    : languageSelectedSubjects.includes("Reading")
                }
                style={{ marginRight: "-12px" }}
                //@ts-ignore
                onChange={() => {
                  selectedSubject("Reading", type);
                }}
                name="subjects"
                size="small"
              />
            }
            label={"Reading"}
          />
        ) : null}
        {scienceEnabled && gradeCheck ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  type === "TestToSpeech"
                    ? textSelectedSubjects.includes("Science")
                    : languageSelectedSubjects.includes("Science")
                }
                style={{ marginRight: "-12px" }}
                //@ts-ignore
                onChange={() => {
                  selectedSubject("Science", type);
                }}
                name="subjects"
                size="small"
              />
            }
            //@ts-ignore
            label={"Science"}
          />
        ) : null}
        {selEnabled ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  type === "TestToSpeech"
                    ? textSelectedSubjects.includes("Purpose Prep SEL")
                    : languageSelectedSubjects.includes("Purpose Prep SEL")
                }
                style={{ marginRight: "-12px" }}
                //@ts-ignore
                onChange={() => {
                  selectedSubject("Purpose Prep SEL", type);
                }}
                name="subjects"
                size="small"
              />
            }
            label="Purpose"
          />
        ) : null}
      </FormGroup>
    );
  }

  function renderSpanishMathCheckBoxes() {
    return (
      <FormGroup>
        {spanishMathEnable && mathEnabled ? (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={spanishSelectedSubjects.includes("Math")}
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    selectedSubject("Math", "Spanish");
                  }}
                  name="subjects"
                  size="small"
                />
              }
              //@ts-ignore
              label={"Math Lessons"}
            />
            <LightTooltip
              placement="right-start"
              style={{ backgroundColor: "white" }}
              title={renderSpanishMathToolTip()}
            >
              <i
                className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                style={{ marginTop: "10px", color: "#6E7075" }}
              ></i>
            </LightTooltip>
          </div>
        ) : null}
        {orgMyPathMathEnabled && mypathMath ? (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={spanishMypath}
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    selectedSubject("MyPath", "Spanish");
                    setSpanishMypath(!spanishMypath);
                  }}
                  name="subjects"
                  size="small"
                />
              }
              //@ts-ignore
              label={"MyPath Math Assessment"}
            />
            <LightTooltip
              placement="right-start"
              style={{ backgroundColor: "white" }}
              title={renderSpanishMyPathToolTip()}
            >
              <i
                className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                style={{ marginTop: "10px", color: "#6E7075" }}
              ></i>
            </LightTooltip>
          </div>
        ) : null}
        {getFlagrKeys["SpanishLanguageScience"] && spanishScienceEnable && scienceEnabled && gradeCheck ? (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={spanishScience}
                  style={{ marginRight: "-12px" }}
                  //@ts-ignore
                  onChange={() => {
                    selectedSubject("Science", "Spanish");
                    setSpanishScience(!spanishScience);
                  }}
                  name="subjects"
                  size="small"
                />
              }
              //@ts-ignore
              label={"Science Lessons"}
            />
            <LightTooltip
              placement="right-start"
              style={{ backgroundColor: "white" }}
              title={renderSpanishScienceToolTip()}
            >
              <i
                className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                style={{ marginTop: "10px", color: "#6E7075" }}
              ></i>
            </LightTooltip>
          </div>
        ) : null}
      </FormGroup>
    );
  }

  const setSelectedSubjectsFunc = (getSubject: string, type: string) => {
    if (type === "Mypath") {
      if (mypathMath === false && spanishSelectedSubjects.includes("MyPath")) {
        setSpanishMypath(false);
        const filters = spanishSelectedSubjects.filter(function (item) {
          return item !== "MyPath";
        });
        setSpanishSelectedSubjects(filters);
      }
      if (MypathSelectedSubjects.includes(getSubject)) {
        const filters = MypathSelectedSubjects.filter(function (item) {
          return item !== getSubject;
        });
        setMypathSelectedSubjects(filters);
      } else {
        setMypathSelectedSubjects([...MypathSelectedSubjects, getSubject]);
      }
    } else if (type === "Spanish") {
      if (spanishSelectedSubjects.includes(getSubject)) {
        const filters = spanishSelectedSubjects.filter(function (item) {
          return item !== getSubject;
        });
        setSpanishSelectedSubjects(filters);
      } else {
        setSpanishSelectedSubjects([...spanishSelectedSubjects, getSubject]);
      }
    }
  };

  function selectedSubject(getSubject: string, type: string) {
    //@ts-ignore
    if (type === "TestToSpeech") {
      if (textSelectedSubjects.includes(getSubject)) {
        const filters = textSelectedSubjects.filter(function (item) {
          return item !== getSubject;
        });
        setTextSelectedSubjects(filters);
      } else {
        if (getSubject === "Reading") {
          handleTexttoSpeechReading("on");
        }
        setTextSelectedSubjects([...textSelectedSubjects, getSubject]);
      }
    } else if (type === "language") {
      if (languageSelectedSubjects.includes(getSubject)) {
        const filters = languageSelectedSubjects.filter(function (item) {
          return item !== getSubject;
        });
        setLanguageSelectedSubjects(filters);
      } else {
        if (getSubject === "Reading") {
          handleLanguageReading("on");
        }
        setLanguageSelectedSubjects([...languageSelectedSubjects, getSubject]);
      }
    } else {
      setSelectedSubjectsFunc(getSubject, type);
    }
  }
  const gradeCheckFormControl = () => {
    if (getFlagrKeys["MPAMathSpanishLanguage"]) {
      return renderSpanishMathCheckBoxes();
    } else {
      return (
        <FormControlLabel
          control={
            <Checkbox
              style={{ marginRight: "-12px" }}
              //@ts-ignore
              onChange={() => {
                selectedSubject("Math", "Spanish");
              }}
              name="subjects"
              size="small"
            />
          }
          //@ts-ignore
          label={"Math"}
        />
      );
    }
  };

  function renderStudentSettingsTab1() {
    return (
      <div className="responsiveSettings">
        <div>
          <div className="settingholder">
            {showNoSubject ? (
              <div className="labelInputHolder">
                <div className="settingsFirstRow" style={{ display: showNoSubject ? "block" : "none" }}>
                  <label className="newlabelForInput">Text-to-Speech</label>
                  <LightTooltip
                    placement="left-start"
                    style={{ backgroundColor: "white" }}
                    title={renderTextToSpeechToolTip()}
                  >
                    <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                  </LightTooltip>
                </div>
                {renderLangCheckBoxes("TestToSpeech")}
              </div>
            ) : null}
            {showNoSubject ? (
              <div className="labelInputHolder ">
                <div className="settingsFirstRow" style={{ display: showNoSubject ? "block" : "none" }}>
                  <label className="newlabelForInput">Language Translation Tools</label>
                  <LightTooltip
                    placement="left-start"
                    style={{ backgroundColor: "white" }}
                    title={renderTranslationToolTip()}
                  >
                    <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                  </LightTooltip>
                </div>
                {renderLangCheckBoxes("language")}
              </div>
            ) : null}
            <div>
              {getUserRoleId === 1 ||
              getUserRoleId === 2 ||
              getUserRoleId === 3 ||
              getUserRoleId === 4 ||
              getUserRoleId === 7 ? (
                <div className="labelInputHolder">
                  <div className="settingsFirstRow">
                    <label style={{ marginLeft: "30px" }} className="newlabelForInput">
                      IEP
                    </label>
                    <LightTooltip placement="left-start" title={renderIepToolTip()}>
                      <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                    </LightTooltip>
                  </div>
                  <div className="RadioButtons">{RadioButtonsGroup(niep, "IEP", iepSelected)}</div>
                </div>
              ) : null}
              <div style={{ marginTop: "25px" }} className="labelInputHolder">
                <div className="settingsFirstRow">
                  <label style={{ marginLeft: "30px" }} className="newlabelForInput">
                    ELL
                  </label>
                  <LightTooltip placement="left-start" title={renderEllToolTip()}>
                    <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                  </LightTooltip>
                </div>
                <div className="RadioButtons">{RadioButtonsGroup(ELL, "ELL", ellSelected)}</div>
              </div>
            </div>
          </div>
          <div className="settingholder" style={{ marginTop: "45px" }}>
            {(spanishMathEnable && mathEnabled) ||
            (getFlagrKeys["SpanishLanguageScience"] && spanishScienceEnable && gradeCheck && scienceEnabled) ||
            (getFlagrKeys["MPAMathSpanishLanguage"] && orgMyPathMathEnabled && mypathMath) ? (
              <div className="labelInputHolder">
                <div>
                  <div className="settingsFirstRow">
                    <label className="newlabelForInput">Spanish Language</label>
                    {!getFlagrKeys["MPAMathSpanishLanguage"] ? (
                      <LightTooltip
                        placement="right-start"
                        style={{ backgroundColor: "white" }}
                        title={renderSpanishMathToolTip()}
                      >
                        <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                      </LightTooltip>
                    ) : null}
                  </div>
                  {gradeCheck ? (
                    gradeCheckFormControl()
                  ) : (
                    <div>
                      {getFlagrKeys["MPAMathSpanishLanguage"] ? (
                        <FormGroup>
                          {spanishMathEnable && mathEnabled ? (
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={!gradeCheck}
                                    checked={false}
                                    style={{ marginRight: "-12px" }}
                                    //@ts-ignore
                                    name="subjects"
                                    size="small"
                                  />
                                }
                                //@ts-ignore
                                label={"Math Lessons"}
                              />
                              <LightTooltip
                                placement="right-start"
                                style={{ backgroundColor: "white" }}
                                title={renderSpanishMathToolTip()}
                              >
                                <i
                                  className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                                  style={{
                                    marginTop: "10px",
                                    color: "#6E7075"
                                  }}
                                ></i>
                              </LightTooltip>
                            </div>
                          ) : null}
                          {orgMyPathMathEnabled && mypathMath ? (
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={spanishMypath}
                                    style={{ marginRight: "-12px" }}
                                    //@ts-ignore
                                    onChange={() => {
                                      selectedSubject("MyPath", "Spanish");
                                      setSpanishMypath(!spanishMypath);
                                    }}
                                    name="subjects"
                                    size="small"
                                  />
                                }
                                //@ts-ignore
                                label={"MyPath Math Assessment"}
                              />
                              <LightTooltip
                                placement="right-start"
                                style={{ backgroundColor: "white" }}
                                title={renderSpanishMyPathToolTip()}
                              >
                                <i
                                  className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                                  style={{
                                    marginTop: "10px",
                                    color: "#6E7075"
                                  }}
                                ></i>
                              </LightTooltip>
                            </div>
                          ) : null}
                          {getFlagrKeys["SpanishLanguageScience"] &&
                          spanishScienceEnable &&
                          scienceEnabled &&
                          gradeCheck ? (
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={spanishScience}
                                    style={{ marginRight: "-12px" }}
                                    //@ts-ignore
                                    onChange={() => {
                                      selectedSubject("Science", "Spanish");
                                      setSpanishScience(!spanishScience);
                                    }}
                                    name="subjects"
                                    size="small"
                                  />
                                }
                                //@ts-ignore
                                label={"Science Lessons"}
                              />
                              <LightTooltip
                                placement="right-start"
                                style={{ backgroundColor: "white" }}
                                title={renderSpanishScienceToolTip()}
                              >
                                <i
                                  className="fa fa-info-circle newfainfoCircleicon1 newinfoImportsIconStudent1"
                                  style={{
                                    marginTop: "10px",
                                    color: "#6E7075"
                                  }}
                                ></i>
                              </LightTooltip>
                            </div>
                          ) : null}
                        </FormGroup>
                      ) : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!gradeCheck}
                              checked={false}
                              style={{ marginRight: "-12px" }}
                              //@ts-ignore
                              name="subjects"
                              size="small"
                            />
                          }
                          //@ts-ignore
                          label={"Math"}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {orgMyPathMathEnabled || orgMyPathReadingEnabled ? (
              <div
                className="labelInputHolder "
                style={{
                  display: orgMyPathMathEnabled || orgMyPathReadingEnabled ? "block" : "none"
                }}
              >
                <div className="settingsFirstRow">
                  <label className="newlabelForInput">MyPath Assessment</label>
                  <LightTooltip
                    placement="left-start"
                    style={{ backgroundColor: "white" }}
                    title={renderMyPathAssessmentToolTip()}
                  >
                    <i className="fa fa-info-circle newfainfoCircleicon newinfoImportsIconStudent"></i>
                  </LightTooltip>
                </div>
                {
                  <FormGroup>
                    {orgMyPathMathEnabled && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mypathMath}
                            style={{ marginRight: "-12px" }}
                            //@ts-ignore
                            onChange={() => {
                              setMypathMath(!mypathMath);
                              selectedSubject("Math", "Mypath");
                            }}
                            name="subjects"
                            size="small"
                          />
                        }
                        //@ts-ignore
                        label={"Math"}
                      />
                    )}
                    {orgMyPathReadingEnabled && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={mypathReading}
                            style={{ marginRight: "-12px" }}
                            //@ts-ignore
                            onChange={() => {
                              setMypathReading(!mypathReading);
                              selectedSubject("Reading", "Mypath");
                            }}
                            name="subjects"
                            size="small"
                          />
                        }
                        //@ts-ignore
                        label={"Reading"}
                      />
                    )}
                  </FormGroup>
                }
              </div>
            ) : null}
            {getFlagrKeys["ReadinessFeature_Users_Groups_Events"] && (
              <div className="firstLanguageFieldWrapper">
                <div className="settingsFirstRow">
                  <label className="newlabelForInput">First Language</label>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: `1.3px solid ${borderColorToReset}`,
                    borderRadius: "3px",
                    paddingTop: "5px"
                  }}
                >
                  <NavDropdown
                    id="firstLanguageSelect"
                    title={state.firstLanguage}
                    className="supportTextFormFirstLang"
                  >
                    {firstLanguageList?.map((language: { languageID: number; languageName: string }) => (
                      <NavDropdown.Item
                        className="supportTextFormFirstLang"
                        eventKey="active"
                        onClick={() => {
                          handleTextChange(language.languageName, "firstLanguage");
                        }}
                        key={language.languageID}
                      >
                        {language.languageName}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="settingsFooter">
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Cancel"}
            className="px-2 py-1 me-2 cursor secondaryLightButton"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              cancelCreate();
            }}
          />
          <UxAction
            key={0}
            size="sm"
            enabled={true}
            text={"Previous"}
            className="px-2 py-1 me-2 cursor secondaryLightButton"
            priority={UxShadesEnum.LIGHT}
            callback={() => {
              setTabKey("profile");
              localStorage.setItem("page", "Details");
            }}
          />
          <UxAction
            key={1}
            size="sm"
            enabled={true}
            text={"Save"}
            className="px-2 cursor turkishBlue"
            callback={() => {
              validateAdmin();
              setTabKey("profile");
              localStorage.setItem("page", "Profile");
            }}
          />
        </div>
      </div>
    );
  }

  function cancelCreate() {
    if (localStorage.getItem("ala:createStudent") === "false") {
      exit();
    }
    if (localStorage.getItem("ala:createStudent") === "true") {
      setShowCancelModal(true);
    } else {
      setShowCancelModal(false);
      localStorage.setItem("page", "studentsRoster");
    }
  }
  function renderStudentDetailsTab() {
    return (
      <div className="userProfileDetailsHolderCS" style={{ minHeight: "384px" }}>
        <div className="userProfileHolder" style={{ padding: "0px" }}>
          <div className="labelInputHolder">
            <label className="labelForInput">Renaissance ID</label>
            <div
              style={{
                border: `1.3px solid ${RNEIDBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                type="text"
                placeholder="Renaissance ID"
                value={state.renaissanceId}
                className="eTextInput"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "renaissanceId")}
              />
            </div>
            <label className="labelForIssue">{RNEIDIssueText}</label>
          </div>
          <div className="labelInputHolder">
            <label className="labelForInput">NWEA ID</label>
            <div
              style={{
                border: `1.3px solid ${NWEAIDBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                type="text"
                placeholder="NWEA ID"
                value={state.nweid}
                className="eTextInput"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "nweid")}
              />
            </div>
            <label className="labelForIssue">{NWEAIDIssueText}</label>
          </div>

          {getUserRoleId !== 5 ? (
            <div className="labelInputHolder">
              <label className="labelForInput">External ID</label>
              <div
                style={{
                  border: `1.3px solid ${districtAssignedIDBorder}`,
                  borderRadius: "3px"
                }}
              >
                <input
                  type="text"
                  placeholder="EXTERNAL ID"
                  value={state.districtAssignedId}
                  className="eTextInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "districtAssignedId")}
                />
              </div>
              <label className="labelForIssue">{districtAssignedIDIssueText}</label>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function renderSpanishMathToolTip() {
    return (
      <text>
        Students in grades K-5 will receive
        <br />
        Math lessons in Spanish.
      </text>
    );
  }

  function renderSpanishMyPathToolTip() {
    return (
      <text>
        If enabled, this student will receive
        <br />
        MyPath Math Assessment in Spanish.
      </text>
    );
  }

  function renderSpanishScienceToolTip() {
    return (
      <text>
        Students in grades K-5 will receive
        <br />
        Science lessons in Spanish.
      </text>
    );
  }

  function renderIepToolTip() {
    return (
      <text>
        Set Individual Education Plan if <br />
        desired for reporting.
      </text>
    );
  }

  function renderEllToolTip() {
    return (
      <text>
        {" "}
        Set English Language Learner if <br />
        desired for reporting.
      </text>
    );
  }
  function renderTextToSpeechToolTip() {
    return (
      <text>
        When On, student can access a Text-to-Speech tool
        <br />
        that reads aloud instructional and assessment
        <br />
        content for the subject.
      </text>
    );
  }
  function renderTranslationToolTip() {
    return (
      <text>
        When On, student can access a Language Translation
        <br />
        tool to translate written instructional and assessment
        <br />
        content for the subject. Available languages may vary.
      </text>
    );
  }

  function renderMyPathAssessmentToolTip() {
    return (
      <text>
        Turn the MyPath Assessment off for students who are
        <br />
        exempt from testing. You will need to manually place
        <br />
        these students.
      </text>
    );
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242",
        zIndex: 9999
      }
    }
  }));
  const alertclasses = alertStyles();

  function renderStudentSettingsTab() {
    return (
      <div className="userProfileDetailsHolderCS" style={{ minHeight: "384px" }}>
        <div className="userProfileHolder" style={{ padding: "0px" }}>
          {getUserRoleId === 1 ||
          getUserRoleId === 2 ||
          getUserRoleId === 3 ||
          getUserRoleId === 4 ||
          getUserRoleId === 7 ? (
            <div style={{ paddingBottom: "7px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <label className="labelForInput">IEP</label>
                <LightTooltip placement="right-start" title={renderIepToolTip()}>
                  <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
                </LightTooltip>
              </div>
              <div className="customSwitchButtonHolderUnknown">
                <div
                  className="customSwitchButtonYes"
                  style={{
                    background: iep === "yes" ? "#4c9c8d" : "#ffffff",
                    color: iep === "yes" ? "#ffffff" : "#6E7075"
                  }}
                  onClick={() => {
                    setIep("yes");
                  }}
                >
                  Yes
                </div>
                <div
                  className="customSwitchButtonNo"
                  style={{
                    background: iep === "no" ? "#e0e0e0" : "#ffffff",
                    color: iep === "no" ? "#5B5B5B" : "#6E7075"
                  }}
                  onClick={() => {
                    setIep("no");
                  }}
                >
                  No
                </div>
              </div>
            </div>
          ) : (
            <div style={{ height: "104px" }}></div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <label className="labelForInput">ELL</label>
            <LightTooltip placement="right-start" title={renderEllToolTip()}>
              <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
            </LightTooltip>
          </div>
          <div className="customSwitchButtonHolderUnknown">
            <div
              className="customSwitchButtonYes"
              style={{
                background: ell === "yes" ? "#4c9c8d" : "#ffffff",
                color: ell === "yes" ? "#ffffff" : "#6E7075"
              }}
              onClick={() => {
                setEll("yes");
              }}
            >
              Yes
            </div>
            <div
              className="customSwitchButtonNo"
              style={{
                background: ell === "no" ? "#e0e0e0" : "#ffffff",
                color: ell === "no" ? "#5B5B5B" : "#6E7075"
              }}
              onClick={() => {
                setEll("no");
              }}
            >
              No
            </div>
          </div>
          <div className={spanishMathEnable ? "" : "SettingsHide"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label className="labelForInput">SPANISH LANGUAGE MATH</label>
              <LightTooltip placement="right-start" title={renderSpanishMathToolTip()}>
                <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
              </LightTooltip>
            </div>
            <div
              className={
                state.grade === "K" ||
                state.grade === "5" ||
                state.grade === "1" ||
                state.grade === "2" ||
                state.grade === "3" ||
                state.grade === "4"
                  ? "customSwitchButtonHolder"
                  : "customSwitchButtonHolderDisabled"
              }
            >
              <div
                className="customSwitchButtonLeft"
                style={{
                  background: spanish === "on" ? "#4c9c8d" : "#ffffff",
                  color: spanish === "on" ? "#ffffff" : "#6E7075"
                }}
                onClick={() => {
                  setSpanish("on");
                  setSpanishRef("on");
                }}
              >
                On
              </div>
              <div
                className="customSwitchButtonRight"
                style={{
                  background: spanish === "off" ? "#e0e0e0" : "#ffffff",
                  color: spanish === "off" ? "#5B5B5B" : "#6E7075"
                }}
                onClick={() => {
                  setSpanish("off");
                  setSpanishRef("off");
                }}
              >
                Off
              </div>
            </div>
          </div>
        </div>

        <div className="userProfileHolder" style={{ padding: "0px" }}>
          <div className={mathEnabled || readingEnabled || selEnabled ? "CardFlexHolder1" : "hidden"}>
            <div className="userProfileHolder1" style={{ justifyContent: "center" }}>
              <label
                className="labelForInput"
                style={{
                  backgroundColor: "white",
                  paddingLeft: "10px",
                  color: "#6E7075",
                  textTransform: "none"
                }}
              >
                Text-To-Speech{" "}
              </label>
              <LightTooltip
                placement="left-start"
                style={{ backgroundColor: "white" }}
                title={renderTextToSpeechToolTip()}
              >
                <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
              </LightTooltip>
            </div>
            <div className="importButHolderforStudent">
              <div
                className="CardItems"
                style={{
                  display: mathEnabled ? "block" : "none",
                  padding: "10px"
                }}
              >
                <label className="labelForInput">Math</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: textToSpeechMath === "on" ? "#4c9c8d" : "#ffffff",
                      color: textToSpeechMath === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setTextToSpeechMath("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: textToSpeechMath === "off" ? "#e0e0e0" : "#ffffff",
                      color: textToSpeechMath === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTextToSpeechMath("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: readingEnabled ? "block" : "none",
                  padding: "10px"
                }}
              >
                <label className="labelForInput">Reading</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: textToSpeechReading === "on" ? "#4c9c8d" : "#ffffff",
                      color: textToSpeechReading === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      handleTexttoSpeechReading("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: textToSpeechReading === "off" ? "#e0e0e0" : "#ffffff",
                      color: textToSpeechReading === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTextToSpeechReading("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: selEnabled ? "block" : "none",
                  padding: "10px"
                }}
              >
                <label className="labelForInput">Purpose</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: textToSpeechSel === "on" ? "#4c9c8d" : "#ffffff",
                      color: textToSpeechSel === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setTextToSpeechSel("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: textToSpeechSel === "off" ? "#e0e0e0" : "#ffffff",
                      color: textToSpeechSel === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTextToSpeechSel("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={mathEnabled || readingEnabled || selEnabled ? "CardFlexHolder1" : "hidden"}>
            <div className="userProfileHolder1" style={{ justifyContent: "center" }}>
              <label
                className="labelForInput"
                style={{
                  backgroundColor: "white",
                  paddingLeft: "10px",
                  color: "#6E7075",
                  textTransform: "none"
                }}
              >
                Language Translation Tools{" "}
              </label>
              <LightTooltip
                placement="left-start"
                style={{ backgroundColor: "white" }}
                title={renderTranslationToolTip()}
              >
                <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
              </LightTooltip>
            </div>
            <div className="importButHolderforStudent">
              <div
                className="CardItems"
                style={{
                  padding: "10px",
                  display: mathEnabled ? "block" : "none"
                }}
              >
                <label className="labelForInput">Math</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: translationToSpeechMath === "on" ? "#4c9c8d" : "#ffffff",
                      color: translationToSpeechMath === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setTranslationToSpeechMath("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: translationToSpeechMath === "off" ? "#e0e0e0" : "#ffffff",
                      color: translationToSpeechMath === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTranslationToSpeechMath("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "10px",
                  display: readingEnabled ? "block" : "none"
                }}
              >
                <label className="labelForInput">Reading</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: translationToSpeechReading === "on" ? "#4c9c8d" : "#ffffff",
                      color: translationToSpeechReading === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      handleLanguageReading("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: translationToSpeechReading === "off" ? "#e0e0e0" : "#ffffff",
                      color: translationToSpeechReading === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTranslationToSpeechReading("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "10px",
                  display: selEnabled ? "block" : "none"
                }}
              >
                <label className="labelForInput">Purpose</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: translationToSpeechSel === "on" ? "#4c9c8d" : "#ffffff",
                      color: translationToSpeechSel === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setTranslationToSpeechSel("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: translationToSpeechSel === "off" ? "#e0e0e0" : "#ffffff",
                      color: translationToSpeechSel === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setTranslationToSpeechSel("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={orgMyPathMathEnabled || orgMyPathReadingEnabled ? "CardFlexHolder1" : "hidden"}>
            <div className="userProfileHolder1" style={{ justifyContent: "center" }}>
              <label
                className="labelForInput"
                style={{
                  backgroundColor: "white",
                  paddingLeft: "10px",
                  color: "#6E7075",
                  textTransform: "none"
                }}
              >
                MyPath Assessment{" "}
              </label>
              <LightTooltip
                placement="left-start"
                style={{ backgroundColor: "white" }}
                title={renderMyPathAssessmentToolTip()}
              >
                <i className="fa fa-info-circle fainfoCircleicon infoImportsIconStudent"></i>
              </LightTooltip>
            </div>
            <div className="importButHolderforStudent">
              <div
                className="CardItems"
                style={{
                  display: orgMyPathMathEnabled ? "block" : "none",
                  padding: "10px"
                }}
              >
                <label className="labelForInput">Math</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: studentMyPathMathEnabled === "on" ? "#4c9c8d" : "#ffffff",
                      color: studentMyPathMathEnabled === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setStudentMyPathMathEnabled("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: studentMyPathMathEnabled === "off" ? "#e0e0e0" : "#ffffff",
                      color: studentMyPathReadingEnabled === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setStudentMyPathMathEnabled("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: orgMyPathReadingEnabled ? "block" : "none",
                  padding: "10px"
                }}
              >
                <label className="labelForInput">Reading</label>
                <div className="customSwitchButtonHolder">
                  <div
                    className="customSwitchButtonLeft"
                    style={{
                      background: studentMyPathReadingEnabled === "on" ? "#4c9c8d" : "#ffffff",
                      color: studentMyPathReadingEnabled === "on" ? "#ffffff" : "#6E7075"
                    }}
                    onClick={() => {
                      setStudentMyPathReadingEnabled("on");
                    }}
                  >
                    On
                  </div>
                  <div
                    className="customSwitchButtonRight"
                    style={{
                      background: studentMyPathReadingEnabled === "off" ? "#e0e0e0" : "#ffffff",
                      color: studentMyPathReadingEnabled === "off" ? "#5B5B5B" : "#6E7075"
                    }}
                    onClick={() => {
                      setStudentMyPathReadingEnabled("off");
                    }}
                  >
                    Off
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function enableButton() {
    if (getUserRoleId === 5 && getDaDetails.type === 2) {
      if (
        state.firstName &&
        state.lastName &&
        state.userName &&
        state.password &&
        state.confirmPassword &&
        state.grade !== "Select" &&
        state.school !== "Select" &&
        userNameIssueText === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      state.firstName &&
      state.lastName &&
      state.userName &&
      state.password &&
      state.confirmPassword &&
      state.grade !== "Select" &&
      userNameIssueText === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  const checkHoldersFunc = () => {
    if (enableButton() || enable === true) {
      return (
        <div className="checkHolders">
          <i className="fa fa-check checkers"></i>
        </div>
      );
    } else {
      return <div className="checkHolderEmpty" />;
    }
  };

  function renderProfileFooter() {
    return (
      <div
        style={{
          justifyContent: "flex-end",
          width: "100%",
          display: "flex"
        }}
      >
        <div className="processHolderforStudent">
          {userNameIssueText ||
          firstNameIssueText ||
          lastNameIssueText ||
          gradeSelectionIssueText ||
          passwordIssueText ||
          confirmPasswordIssueText ||
          schoolSelectionIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            checkHoldersFunc()
          )}
          <div className="processBar" />
          {NWEAIDIssueText || RNEIDIssueText || districtAssignedIDIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            checkHoldersFunc()
          )}
          <div className="processBar" />
          {enable ? (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          ) : (
            <div className="checkHolderEmpty" />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%"
          }}
        >
          <UxAction
            key={0}
            size="sm"
            enabled={enableButton()}
            text={"Next"}
            className="cursor turkishBlue"
            callback={() => {
              validateFirstTab("details");
            }}
          />
        </div>
      </div>
    );
  }

  function renderDetailsFooter() {
    return (
      <div
        style={{
          justifyContent: "flex-end",
          width: "100%",
          display: "flex"
        }}
      >
        <div className="processHolderforStudent">
          {userNameIssueText ||
          firstNameIssueText ||
          lastNameIssueText ||
          gradeSelectionIssueText ||
          passwordIssueText ||
          confirmPasswordIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          )}
          {<div className="processBar" />}
          {NWEAIDIssueText || RNEIDIssueText || districtAssignedIDIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          )}
          <div className="processBar" />
          {enable ? (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          ) : (
            <div className="checkHolderEmpty" />
          )}
        </div>

        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Previous"}
          className="px-2 py-1 me-2 cursor secondaryLightButton"
          priority={UxShadesEnum.LIGHT}
          callback={() => {
            setTabKey("profile");
            localStorage.setItem("page", "Profile");
          }}
        />
        <UxAction
          key={1}
          size="sm"
          enabled={true}
          text={"Next"}
          className="px-2 cursor turkishBlue"
          callback={() => {
            setTabKey("settings");
            localStorage.setItem("page", "Settings");
          }}
        />
      </div>
    );
  }

  function renderSettingsFooter() {
    return (
      <div
        style={{
          justifyContent: "flex-end",
          width: "100%",
          display: "flex"
        }}
      >
        <div className="processHolderforStudent">
          {userNameIssueText ||
          firstNameIssueText ||
          lastNameIssueText ||
          gradeSelectionIssueText ||
          passwordIssueText ||
          confirmPasswordIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          )}
          {<div className="processBar" />}
          {NWEAIDIssueText || RNEIDIssueText || districtAssignedIDIssueText ? (
            <div className="checkHolders1">
              <i className="fa fa-exclamation checkers"></i>
            </div>
          ) : (
            <div className="checkHolders">
              <i className="fa fa-check checkers"></i>
            </div>
          )}
          <div className="processBar" />
          <div className="checkHolders">
            <i className="fa fa-check checkers"></i>
          </div>
        </div>

        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Previous"}
          className="px-2 py-1 me-2 cursor secondaryLightButton"
          priority={UxShadesEnum.LIGHT}
          callback={() => {
            setTabKey("details");
            localStorage.setItem("page", "Details");
          }}
        />
        <UxAction
          key={1}
          size="sm"
          enabled={true}
          text={"Save"}
          className="px-2 cursor turkishBlue"
          callback={() => {
            validateAdmin();
            setTabKey("profile");
            localStorage.setItem("page", "Profile");
          }}
        />
      </div>
    );
  }

  const tabs = [
    {
      eventKey: "profile",
      title: "Student Profile",
      content: renderStudentProfileTab()
    },
    {
      eventKey: "details",
      title: "Student Details",
      content: renderStudentDetailsTab()
    },
    {
      eventKey: "settings",
      title: "Student Settings",
      content: renderStudentSettingsTab()
    }
  ];
  const tabsWithOutDetails = [
    {
      eventKey: "profile",
      title: "Student Profile",
      content: renderStudentProfileTab()
    },
    {
      eventKey: "settings",
      title: "Student Settings",
      content: renderStudentSettingsTab1()
    }
  ];
  function checkTabEnabled(tab: string) {
    validateFirstTab(tab);
  }

  function renderStudentTabs() {
    return (
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            checkTabEnabled(tab);
          }}
          children={tabs}
          className="removeModalmargin"
          currentKey={tabKey}
        />
      </div>
    );
  }
  function newrenderStudentTabs() {
    localStorage.setItem("page", "createStudent");
    return (
      <div className="class-nav-tabs" style={{ marginTop: "-10px" }}>
        <UxTabs
          tabsId="student-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            checkTabEnabled(tab);
          }}
          children={tabsWithOutDetails}
          className="newremoveModalmargin"
          currentKey={tabKey}
        />
      </div>
    );
  }

  function renderCancelModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Exit Screen
            </text>
            <img
              className="confirmCancelImage"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowCancelModal(false);
              }}
            />
          </div>
          <hr></hr>
          <div>
            <text>You’ve started creating a student. If you exit this screen, the student won’t be created.</text>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-20px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowCancelModal(false);
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Exit"}
              className="px-2 py-1 closeBut"
              callback={() => {
                exit();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function exit() {
    setShowCancelModal(false);
    dispatch(updateStudentsSubtab("roster"));
    dispatch(updateTabName("students"));
    let data = getBreadCrumbsData;
    data.crumbs.pop();
    dispatch(updateBreadCrumbs(data));
    navigate(data.crumbs[data.crumbs.length - 1].path);
    localStorage.setItem("ala:createStudent", "false");
    localStorage.setItem("page", "studentsRoster");
  }
  const renderFooterDetails = () => {
    if (tabKey === "details") {
      return renderDetailsFooter();
    } else {
      return renderSettingsFooter();
    }
  };
  return (
    <>
      <div className={alertclasses.root + " create-student-snackbar"}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={5000}
          classes={{
            root: "create-student-snackbar_root"
          }}
          onClose={() => {
            setShowToast({ val: false, msg: "", type: "" });
          }}
        >
          {showToast.type === "warning" ? (
            <Alert
              severity="warning"
              style={{ backgroundColor: "#f4473c", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          ) : (
            <Alert
              severity="info"
              style={{ backgroundColor: "#69a1cf", color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          )}
        </Snackbar>
      </div>

      <div className={alertclasses.root + " create-student-snackbar"}>
        <Snackbar
          open={showToastLang.val}
          classes={{
            root: "create-student-snackbar_root"
          }}
          autoHideDuration={5000}
          onClose={() => {
            setShowToastLang({ val: false, msg: "", type: "" });
          }}
        >
          <Alert
            severity="info"
            style={{ backgroundColor: "#69a1cf", color: "#fff" }}
            onClose={() => {
              setShowToastLang({ val: false, msg: "", type: "" });
            }}
          >
            {showToastLang.msg}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <WNECreateModal
          showModal={showModal}
          children={renderStudentTabs()}
          create={() => {
            validateAdmin();
          }}
          close={() => {
            closeStudentModal();
          }}
          title="Create Student"
          footer={tabKey === "profile" ? renderProfileFooter() : renderFooterDetails()}
        />
        {getFlagrKeys["S54-3369-NewCreateStudentDesign"] && <div>{newrenderStudentTabs()}</div>}
      </div>
      <BulkImportsModal showBulkModal={showCancelModal} children={renderCancelModal()} width="670px" height="200px" />
    </>
  );
};

export default connect(mapStateToProps)(CreateStudent);
