export default function GetBreakpoint(setSize: (args: string) => void) {
  const size = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
  };

  window.addEventListener("load", function () {
    const width = window.innerWidth;
    switch (true) {
      case width < size.sm:
        setSize("xs");
        break;
      case width < size.md:
        setSize("sm");
        break;
      case width < size.lg:
        setSize("md");
        break;
      case width < size.xl:
        setSize("lg");
        break;
      case width < size.xxl:
        setSize("xl");
        break;
      case width >= size.xxl:
        setSize("xxl");
        break;
    }
  });

  window.addEventListener("resize", function () {
    const width = window.innerWidth;
    switch (true) {
      case width < size.sm:
        setSize("xs");
        break;
      case width < size.md:
        setSize("sm");
        break;
      case width < size.lg:
        setSize("md");
        break;
      case width < size.xl:
        setSize("lg");
        break;
      case width < size.xxl:
        setSize("xl");
        break;
      case width >= size.xxl:
        setSize("xxl");
        break;
    }
  });
}
