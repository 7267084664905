import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import ClassSettings from "./ClassSettings";
import Roster from "./Roster";
import StudentPlacement from "./StudentPlacement";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updateSchoolID,
  updatePageTitle,
  updateTabChange,
  updateTabName,
  updatePreviousURLForClassProgress,
  updateReportCard,
  updateClassDetails,
  updateLevel,
  updateClassExternalId,
  updateStudentIds,
  updateSchoolGUID,
  updateClassViewTab,
  updateClassSummaryReport,
  updateClassGUID
} from "../../Redux/Action";
import { LMSService } from "../../services/helpers/lms.services";
import { AdminService } from "../../services/helpers/api.services";
import ReportsTab from "../reports/ReportsTab";
import GetToken from "../../authService/GetToken";
import ReduxProps from "../../Redux/Redux.props";
import { ClassViewProps } from "./ClassView.props";
import { RootComponentProps } from "../../root-component.props";
import { getIsOrgGuidPresent } from "../../helpers/orgGuidList";

interface Props extends ReduxProps, ClassViewProps, RootComponentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getClassViewTab: state.getClassViewTab,
    getTabChange: state.getTabChange,
    getFlagrKeys: state.getFlagrKeys,
    getTabName: state.getTabName,
    getReportCard: state.getReportCard,
    getClassDetails: state.getClassDetails,
    getSubjectName: state.getSubjectName
  };
}

function ClassView(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();

  const {
    getUserRoleId,
    getDaDetails,
    dispatch,
    getClassViewTab,
    getBreadCrumbsData,
    getTabChange,
    getTabName,
    ilpsubject,
    MyPathLevelSubject,
    BenchmarkGrowthReportBreadcrumbSubject,
    ActivityIndicatorSubject,
    mpngClassSummarySubject,
    mpngStudentIlpSubject,
    dsdsReportSubject,
    getSubjectName
  } = props;
  const [tabKey, setTabKey] = useState(getClassViewTab);
  const [previousTab, setPreviousTab] = useState("");
  const [currentOrgId, setCurrentOrgId] = useState("");
  const navigate = useNavigate();
  const [classIsActive, setClassIsActive] = useState(false);

  useEffect(() => {
    dispatch(updateTabChange(!getTabChange));
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    dispatch(updateReportCard("home"));
    setPreviousTab(getTabName);

    setTabKey(getTabName ? getTabName : tabKey);
    if (getTabName === "reports") {
      isAssessmentTabClicked(getTabName);
    }
    if (getTabName != "reports") {
      dispatch(updateTabName(null));
    }
    if (getTabName === null || getTabName === "") {
      localStorage.setItem("page", tabKey);
    } else {
      localStorage.setItem("page", getTabName);
    }
    dispatch(updateStudentIds([]));
    return () => {
      dispatch(updateTabName(previousTab));
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await LMSService.getClassDetailsWithGuid(id).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotClassDetails(data);
          }
        });
      } catch (err) {
        //do nothing
      }
    };

    dispatch(updateActivityIndicator(true));
    fetchData();
    dispatch(updateLevel("class"));
    // eslint-disable-next-line
  }, [dispatch, id]);

  async function gotClassDetails(detail: Response) {
    let details = JSON.parse(await detail.text());
    ilpsubject.next({ token: GetToken(), classId: details.classGUID });
    dispatch(
      updateClassDetails({
        classGUID: details.classGUID,
        classId: id,
        className: details.classTitle
      })
    );
    dispatch(updateClassGUID(details.classGUID));
    dispatch(
      updateClassSummaryReport({
        classId: details.classGUID,
        className: details.classTitle,
        subject: details.subjects[0]?.subject,
        academicSessionId: details.academicSessionGuid,
        orgTimezone: details.organizationTimeZone,
        schoolYear: details.academicSessionSchoolYear,
        schoolGeoState: details.schoolGeoState,
        organizationId: details.organizationGuid
      })
    );
    const subject = mpngClassSummarySubject.getValue().subject;
    const checkAndReturnString = (str) => {
      if (str && str.length > 1) {
        return str;
      } else {
        return undefined;
      }
    };
    let actualSubject =
      checkAndReturnString(subject) || checkAndReturnString(getSubjectName) || details.subjects[0]?.subject;
    mpngClassSummarySubject.next({
      classId: details.classGUID,
      className: details.classTitle,
      subject: actualSubject,
      academicSessionId: details.academicSessionGuid,
      orgTimezone: details.organizationTimeZone,
      schoolYear: details.academicSessionSchoolYear,
      schoolGeoState: details.schoolGeoState,
      organizationId: details.organizationGuid
    });
    mpngStudentIlpSubject.next({
      studentId: "",
      subject: actualSubject,
      ilpId: "",
      academicSessionId: "",
      orgTimezone: "",
      firstname: "",
      lastname: "",
      schoolYear: "",
      returnRoute: `/classsummary/${id}`
    });

    //@ts-ignore
    dispatch(updateSchoolID(details.organizationID));
    dispatch(updateSchoolGUID(details.organizationGuid));
    setCurrentOrgId(details.organizationGuid);
    setClassIsActive(details?.isActive);
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      //@ts-ignore
      dispatch(updatePageTitle(`- Customers - ${details.classTitle}`));
      //@ts-ignore
      fetchAdminBreadCrumbs(details.organizationGuid, details.classTitle);
    } else if (getUserRoleId === 3) {
      //@ts-ignore
      fetchDABreadCrumbs(details.organizationGuid, details.classTitle);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      //@ts-ignore
      dispatch(updatePageTitle(`- Classes - ${details.classTitle}`));
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          //@ts-ignore
          { path: `/saclasses/${details.organizationGuid}`, name: "Classes" },
          //@ts-ignore
          { path: `/classsummary/${id}`, name: details.classTitle }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      // dispatch(updateActivityIndicator(false))
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      //@ts-ignore
      dispatch(updatePageTitle(`- Schools - ${details.classTitle}`));
      //@ts-ignore
      fetchSABreadCrumbs(details.organizationGuid, details.classTitle);
    } else if (getUserRoleId === 5 && getDaDetails.type === 1) {
      //@ts-ignore
      dispatch(updatePageTitle(`- Classes - ${details.classTitle}`));
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          //@ts-ignore
          {
            path: `/teacherclasses/${details.organizationGuid}`,
            name: "Classes"
          },
          //@ts-ignore
          { path: `/classsummary/${id}`, name: details.classTitle }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      // dispatch(updateActivityIndicator(false))
    } else if (getUserRoleId === 5 && getDaDetails.type === 2) {
      //@ts-ignore
      dispatch(updatePageTitle(`- Schools - ${details.classTitle}`));
      //@ts-ignore
      fetchTeacherBreadCrumbs(details.organizationGuid, details.classGUID, details.classTitle);
      // dispatch(updateActivityIndicator(false))
    }

    if (details.districtAssignedId !== null) {
      dispatch(updateClassExternalId(details.districtAssignedId));
    } else {
      dispatch(updateClassExternalId(""));
    }
    if (getClassViewTab === "classsummary") {
      navigate(`/classsummary/${id}`);
    }
    if (localStorage.getItem("page") !== "placement") {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    if ((getDaDetails.type === 1 || getDaDetails.type === 2) && getUserRoleId === 3) {
      if (getBreadCrumbsData.crumbs[0].name !== "") {
        if (getDaDetails.type === 1) {
          dispatch(
            updatePageTitle(`- Schools - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        } else {
          dispatch(
            updatePageTitle(`- Districts - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [getDaDetails, getBreadCrumbsData]);

  const fetchTeacherBreadCrumbs = async (oid: number, cid: number, cname: string) => {
    try {
      let apiObj = { rid: oid, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            updateTeacherBreadCrumbs(data, cid, cname, oid);
          } else {
            const breadcrumb = {
              crumbs: [
                { path: `/teacherschools/${getDaDetails.id}`, name: "Schools" },
                { path: `/teacherclasses/${oid}`, name: "Classes" },
                { path: `/classsummary/${cid}`, name: cname }
              ]
            };
            dispatch(updateBreadCrumbs(breadcrumb));
          }
          // // dispatch(updateActivityIndicator(false));
        }
      );
    } catch (err) {
      //do nothing
    }
  };

  async function updateTeacherBreadCrumbs(detail: Response, cid: number, cname: string, oid: number) {
    //@ts-ignore
    let details = JSON.parse(await detail.text());
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/teacherschools/${getDaDetails.id}`, name: "Schools" },
        //@ts-ignore
        { path: `/teacherclasses/${oid}`, name: details.name },
        { path: `/classsummary/${cid}`, name: cname }
      ]
    };

    dispatch(updateBreadCrumbs(breadcrumb));
  }

  const fetchSABreadCrumbs = async (oid: number, cname: string) => {
    try {
      let apiObj = { rid: oid, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            updateSABreadCrumbs(oid, cname, data);
          } else {
            const breadcrumb = {
              crumbs: [
                { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
                { path: `/saclasslist/${oid}`, name: "Classes" },
                { path: `/classview/${oid}`, name: cname }
              ]
            };
            dispatch(updateBreadCrumbs(breadcrumb));
          }
          // // dispatch(updateActivityIndicator(false));
        }
      );
    } catch (err) {
      //do nothing
    }
  };

  async function updateSABreadCrumbs(oid: number, cname: string, detail: Response) {
    //@ts-ignore
    let details = JSON.parse(await detail.text());
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
        //@ts-ignore
        { path: `/saclasslist/${oid}`, name: details.name },
        { path: `/classsummary/${id}`, name: cname }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
  }

  const fetchDABreadCrumbs = async (oid: number, cname: string) => {
    try {
      let apiObj = { rid: getDaDetails.id, cid: oid };

      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            updateDABreadCrumbs(oid, cname, data);
          }
          // // dispatch(updateActivityIndicator(false));
        }
      );
    } catch (err) {
      //do nothing
    }
  };

  async function updateDABreadCrumbs(_oid: number, cname: string, detail: Response) {
    //@ts-ignore
    let details = JSON.parse(await detail.text());
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [{ path: `/dainstitutions/${details.orgGUID}`, name: details.name }]
      };
      breadcrumb.crumbs.push({ path: `/classsummary/${id}`, name: cname });
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${x.orgGUID}`,
          //@ts-ignore
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/daschool/${details.orgGUID}`,
        //@ts-ignore
        name: details.name
      });
      //@ts-ignore
      breadcrumb.crumbs.push({ path: `/classsummary/${id}`, name: cname });
      dispatch(updateBreadCrumbs(breadcrumb));
      //@ts-ignore
    }
  }

  const fetchAdminBreadCrumbs = async (oid: string, cname: string) => {
    try {
      let apiObj = { guid: oid };
      await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          updateAdminBreadCrumbs(oid, cname, data);
        }
        // // dispatch(updateActivityIndicator(false));
      });
    } catch (err) {
      //do nothing
    }
  };

  const isAssessmentTabClicked = (tab: string) => {
    if (tab === "reports") {
      dispatch(updateReportCard("home"));
    } 
    if (tab === "classprogress") {
      dispatch(updatePreviousURLForClassProgress(`/classview/${id}`));
      navigate("/classprogress");
    } else {
      if (tab === "classsummary") {
        navigate(`/classsummary/${id}`);
      } else {
        dispatch(updateTabName(tab));
        dispatch(updateClassViewTab(tab));
        setTabKey(tab);
      }
    }
  };

  async function updateAdminBreadCrumbs(_oid: string, cname: string, detail: Response) {
    //@ts-ignore
    const details = JSON.parse(await detail.text());
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      let breadcrumb = {
        crumbs: [
          { path: "/customers", name: "Customers" },
          //@ts-ignore
          { path: `/school/${details.orgGUID}`, name: details.name }
        ]
      };
      breadcrumb.crumbs.push({ path: `/classsummary/${id}`, name: cname });
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      let breadcrumb = { crumbs: [{ path: "/customers", name: "Customers" }] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/institutions/${x.orgGUID}`,
          name: x.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/school/${details.orgGUID}`,
        name: details.name
      });
      breadcrumb.crumbs.push({ path: `/classsummary/${id}`, name: cname });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
  }
  let tabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement",
      content: <StudentPlacement orgLevel="class"/>
    },
    {
      eventKey: "roster",
      title: "Student Roster",
      content: <Roster orgId={currentOrgId} isClassActive={classIsActive} />
    },

    {
      eventKey: "reports",
      title: "Reports",
      content: (
        <ReportsTab
          isfrom={"class"}
          id={id}
          MyPathLevelSubject={MyPathLevelSubject}
          BenchmarkGrowthReportBreadcrumbSubject={BenchmarkGrowthReportBreadcrumbSubject}
          ActivityIndicatorSubject={ActivityIndicatorSubject}
          dsdsReportSubject={dsdsReportSubject}
        />
      )
    },

    {
      eventKey: "settings",
      title: "Settings",
      content: <ClassSettings />
    }
  ];

  tabs = getIsOrgGuidPresent(id) ? tabs.filter((tab) => tab.eventKey !== "reports") : tabs;

  return (
    <>
      {tabKey !== "classsummary" && (
        <div className="class-nav-tabs">
          {
            <UxTabs
              tabsId="class-tabs"
              activeKey={tabKey}
              onTabSelect={(tab: string) => {
                isAssessmentTabClicked(tab);
                localStorage.setItem("page", tab);
                //
              }}
              children={tabs}
              className="pt-2"
              currentKey={tabKey}
            />
          }
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(ClassView);
