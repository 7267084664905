//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import "./HeaderMenu.scss";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import PendoInitialize from "../PendoIntegration/PendoInitialize";
import { logout } from "../../authService/AuthService";
import {
  updateActivityIndicator,
  updateUserRole,
  updateUserEmail,
  updateUserName,
  updateUserId,
  updateUserRoleId,
  updateUserFirstName,
  updateUserLastName,
  updateDaDetails,
  updatePaginationControllerState,
  updateActiveURL,
  updateDoLittleToken,
  updateTopLevelDA,
  updateIsWneUser,
  updateUserGuId,
  updateBreadCrumbs,
  updateChildDepth,
  updateOrgLoginCodeAvailable,
  updateIsInherited
} from "../../Redux/Action";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import SessionHandler from "../../authService/SessionHandler";
import { persistor } from "../../Redux/Store";
import moment from "moment";
import CHATICON from "../../images/chat.png";
import { LogoutIcon } from "@wne-spa/ux-component-library";
import GetLoginUrl from "../modals/LoginUrl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import configMessages from "../../helpers/configMessages";
import ReduxProps from "../../Redux/Redux.props";
import { HeaderMenuProps } from "./HeaderMenu.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getUserRole: state.getUserRole,
    getUserFirstName: state.getUserFirstName,
    getUserLastName: state.getUserLastName,
    getDaDetails: state.getDaDetails,
    userRoleDetails: state.userRoleDetails,
    getUserId: state.getUserId,
    getUserGuId: state.getUserGuId,
    getPageTitle: state.getPageTitle,
    getFlagrKeysFromState: state.getFlagrKeys,
    getFlagrKeys: state.getFlagrKeys,
    getUserName: state.getUserName,
    getPendoEducatorDetails: state.getPendoEducatorDetails,
    getOrgFlagrKeys: state.getOrgFlagrKeys
  };
}

const HeaderMenu = (props: ReduxProps) => {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const {
    getDaDetails,
    getBreadCrumbsData,
    dispatch,
    getUserRoleId,
    getUserRole,
    getUserId,
    getUserFirstName,
    getUserLastName,
    userRoleDetails,
    getPendoEducatorDetails,
    getUserGuId,
    getFlagrKeys
  } = props;

  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const [showLoginUrlModal, setShowLoginUrlModal] = useState(false);
  const [showExitAddStudentRosterModal, setShowExitAddStudentRosterModal] = useState(false);
  const [profileExit, setProfileExit] = useState(false);
  const [loginCode, setLoginCode] = useState("");
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });

  const [singleSignOnStatus, setSingleSignOnStatus] = useState(false);
  useEffect(() => {
    // if (
    //   getFlagrKeysFromState["S27-G4176-ZenDeskChat"] &&
    //   getPendoEducatorDetails.firstName != ""
    // ) {
    //   if (
    //     window.location.pathname !== "/" &&
    //     window.location.pathname !== "/signin" &&
    //     window.location.pathname !== "/forgotpassword" &&
    //     [3, 4, 5].includes(getUserRoleId)
    //   ) {
    //     const linkTag = document.createElement("script");
    //     linkTag.src =
    //       "https://static.zdassets.com/ekr/snippet.js?key=2ea001f3-c80c-4d99-b307-09dfb5f7b78c";
    //     linkTag.id = "ze-snippet";
    //     document.head.appendChild(linkTag);
    //     // if(!getFlagrKeys["S58-5299-ShowLogoutIcon"]){
    //     window.zESettings = {
    //       webWidget: {
    //         zIndex: 5,
    //         color: { theme: "#2e7f9e" },
    //         position: { horizontal: "left", vertical: "bottom" },
    //         offset: {
    //           horizontal: "90px",
    //         },
    //         launcher: {
    //           label: {
    //             "*": "Support Chat",
    //           },
    //         },
    //         helpCenter: {
    //           title: {
    //             "*": "Edgenuity Chat",
    //           },
    //         },
    //         chat: {
    //           departments: {
    //             select: "NextGenSupport",
    //           },
    //         },
    //         answerBot: {
    //           avatar: {
    //             url:
    //               "https://app.edgenuity.com/ux-components-library/3dd4a2560cde1d33f9b0aea5b0533084.svg",
    //           },
    //         },
    //       },
    //     };
    //     const zopimInterval = setInterval(function () {
    //       if (
    //         window.$zopim === undefined ||
    //         window.$zopim.livechat === undefined
    //       ) {
    //         return;
    //       }

    //       let educatorDetails = getPendoEducatorDetails;
    //       educatorDetails.customerId = localStorage.getItem("ala:loginCode");
    //       let fullName =
    //         educatorDetails.firstName + " " + educatorDetails.lastName;
    //       zE(function () {
    //         $zopim(function () {
    //           zE.identify({
    //             name: fullName,
    //             email: educatorDetails.email,
    //             organization: educatorDetails.userParentInstitutionName,
    //           });
    //           $zopim.livechat.setName(fullName);
    //           $zopim.livechat.setEmail(educatorDetails.email);
    //           $zopim.livechat.addTags(
    //             "Type-" + educatorDetails.roleName,
    //             "FullName-" + fullName,
    //             "CustomerID-" + educatorDetails.customerId,
    //             "SchoolUUID-" + educatorDetails.userInstitutionGUID,
    //             "SchoolName-" + educatorDetails.userinstitutionname,
    //             "AppUserID-" + educatorDetails.userID,
    //             "AppUserUUID-" + educatorDetails.userGuid,
    //             "UserName-" + getUserName,
    //             "SchoolID-" + educatorDetails.userParentInstitutionId,
    //             "ProductType-" + "MPNG"
    //           );
    //         });
    //       });
    //       clearInterval(zopimInterval);
    //     }, 100);
    //     // }
    //   }
    // }
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      dispatch(updateIsWneUser(true));
    }
  }, [getPendoEducatorDetails]);

  useEffect(() => {
    if (getUserRoleId === 3 || getUserRoleId === 4 || getUserRoleId === 5) {
      fetchUserLoginUrl();
    }
    setLoginCode(localStorage.getItem("ala:loginCode"));
    localStorage.setItem("isLogoutClicked", "false");
  }, []);

  useEffect(() => {
    // const { pathname } = window.location;
    // let path = "";
    // if (pathname.lastIndexOf("/") === 0) {
    //   path = pathname.substring(1, pathname.length);
    // } else {
    //   path = pathname.substring(1, pathname.lastIndexOf("/"));
    // }

    // if (
    //   !(
    //     path.includes("reports/usage/class") ||
    //     path.includes("reports/usage/student")
    //   )
    // ) {
    fetchLoginCodeDetails();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchUserLoginUrl = async () => {
    dispatch(updateActivityIndicator(true));
    if (id) {
      let guid = { orgGuid: id.split("_")[0] };
      await AdminService.getSettingsSsoWithGuid(guid).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotSettingsSsoResponse(data);
        } else {
          // do nothing
        }
      });
    }
  };

  async function gotSettingsSsoResponse(responsedata: Response) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    setSingleSignOnStatus(details.ssoEnabled);
  }

  const fetchLoginCodeDetails = async () => {
    if (id) {
      let orgids = { guid: id };

      await AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotLogincodeResponse(data);
        } else {
          // do nothing
        }
      });
    }
  };

  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    setLoginCode(details[0].loginCode);
    dispatch(updateIsInherited(details[0]?.inherited));
    if (details[0].loginCode === "") {
      dispatch(updateOrgLoginCodeAvailable(false));
    } else {
      dispatch(updateOrgLoginCodeAvailable(true));
    }
  }

  useEffect(() => {
    if (getUserRoleId === 1 || getUserRoleId === 3 || getUserRoleId === 2 || getUserRoleId === 7) {
      fetchspanishids();
    }
  }, [id]);

  function handleOutsideDropdownClick(ref: React.MutableRefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowProfileDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  handleOutsideDropdownClick(wrapperRef);

  const fetchspanishids = async () => {
    if (id != null) {
      let apiObj = { guid: id.split("_")[0] };
      await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          getspanishidsResponse(data);
        } else {
          // do nothing
        }
      });
    }
  };

  async function getspanishidsResponse(responsedata: Response) {
    let details = await responsedata.text();
    let responseDetails = JSON.parse(details);
    dispatch(updateChildDepth(responseDetails.childDepth));
    if (responseDetails.parents === null) {
      dispatch(updateTopLevelDA(true));
    }
  }

  async function getPaginationController(details: HeaderMenuProps) {
    try {
      const detail = userRoleDetails === "" ? JSON.parse(await details.text()) : JSON.parse(details);
      UserService.getPaginationCountWithGUID(detail.userGuid).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            updatePagination(data, detail);
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function updatePagination(data: Response, details: HeaderMenuProps) {
    const count = JSON.parse(await data.text());
    dispatch(updatePaginationControllerState(count));
    getDetails(details);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uname = await SessionHandler().then((data) => {
          //@ts-ignore
          return data.username;
        });
        const token = await SessionHandler().then((data) => {
          //@ts-ignore
          return data.token;
        });
        const useragent = navigator.userAgent;
        const eventURL = window.location.origin;
        const clientDateTime = moment().format();
        if (userRoleDetails === "") {
          //@ts-ignore
          const apiObj = {
            Username: uname,
            ClientDateTime: clientDateTime,
            JWTToken: token,
            UserAgent: useragent,
            EventSourceURL: eventURL
          };
          UserService.Authentication(apiObj).then(({ status, data }: { status: number; data: Response }) => {
            if (status === 200) {
              getPaginationController(data);
            }
          });
        } else {
          getPaginationController(userRoleDetails);
        }
      } catch (err) {
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    dispatch(updateActiveURL(`${window.location.pathname}`));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDetails(details: HeaderMenuProps) {
    const roleId = details.roleID;
    if (roleId === 2) {
      dispatch(updateUserRole("Customer Support"));
    } else if (roleId === 1) {
      dispatch(updateUserRole("WNE Admin"));
    } else if (roleId === 7) {
      dispatch(updateUserRole("WNE HelpDesk"));
    } else if (roleId === 3) {
      //@ts-ignore
      getContextName(details.parentOrgGuid, details.parentOrgGuid, 0, 3);
      //@ts-ignore
      dispatch(updateUserRole("District Admin"));
    } else if (roleId === 4) {
      dispatch(updateUserRole("School Admin"));
      //@ts-ignore
      if (details.orgGuidList.length > 1) {
        //@ts-ignore
        getContextName(details.parentOrgGuid, details.orgGuidList.join("_"), 2, 4);
      } else {
        //@ts-ignore
        getContextName(details.orgGuidList[0], details.orgGuidList[0], 1, 4);
      }
    } else if (roleId === 5) {
      dispatch(updateUserRole("Teacher"));
      //@ts-ignore
      if (details.orgGuidList.length > 1) {
        //@ts-ignore
        getContextName(details.parentOrgGuid, details.orgGuidList.join("_"), 2, 5);
        //@ts-ignore
      } else {
        //@ts-ignore
        getContextName(details.orgGuidList[0], details.orgGuidList[0], 1, 5);
      }
    }
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      //@ts-ignore
      getDoLittleToken();
    }
    //@ts-ignore
    dispatch(updateUserName(details.loginName));
    //@ts-ignore
    dispatch(updateUserEmail(details.email));
    //@ts-ignore
    dispatch(updateUserFirstName(details.firstName));
    //@ts-ignore
    dispatch(updateUserLastName(details.lastName));
    //@ts-ignore
    dispatch(updateUserId(details.userID));
    dispatch(updateUserGuId(details.userGuid));
    //@ts-ignore
    dispatch(updateUserRoleId(details.roleID));
  }

  async function getDoLittleToken() {
    try {
      await UserService.getDoLittleToken().then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setDoLittleToken(data);
        } else {
          // do nothing
        }
      });
    } catch (err) {
      //do nothing
    }
  }

  async function setDoLittleToken(responsedata: Response) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    dispatch(updateDoLittleToken(details.token));
  }

  async function updateContextName(details: Response, id: string[] | string, type: number, role: number) {
    const detail = JSON.parse(await details.text());
    if (role === 3) {
      let rtype = 1;
      //@ts-ignore
      if (detail.childDepth > 1) {
        rtype = 2;
      }
      //@ts-ignore
      dispatch(updateDaDetails({ name: detail.name, id: id, type: rtype }));
    } else {
      //@ts-ignore
      dispatch(updateDaDetails({ name: detail.name, id: id, type: type }));
    }
  }

  async function getContextName(pid: string | string[], id: string | string[], type: number, role: number) {
    try {
      let apiObj = { rid: pid, cid: pid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            updateContextName(data, id, type, role);
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  function renderDot() {
    return (
      <div
        style={{
          width: "4px",
          height: "4px",
          background: "#CECFD0",
          borderRadius: "70px",
          margin: "0 10px 0 10px"
        }}
      />
    );
  }

  const handleSideNavClick = (menus: string) => {
    navigate(menus, { replace: false });
    setShowProfileDropDown(false);
  };

  function navigateProfile() {
    if (localStorage.getItem("ala:addRosterStudent") === "true") {
      setProfileExit(true);
      setShowExitAddStudentRosterModal(true);
    } else {
      localStorage.setItem("ala:createStudent", "false");
      dispatch(updateBreadCrumbs({ crumbs: [{ name: "Profile" }] }));
      setShowProfileDropDown(!showProfileDropDown);
      //@ts-ignore
      handleSideNavClick(`/profile/${getDaDetails.id}_${getUserGuId}`);
    }
  }

  function handleProfileClick() {
    setShowProfileDropDown(!showProfileDropDown);
    setTimeout(() => {
      setShowProfileDropDown(false);
    }, 5000);
  }

  function renderIcon() {
    return (
      <div className="logoutIcon">
        <img src={LogoutIcon} alt="" width="12px" height="16px" fill="#46a5c9" onClick={() => handleProfileClick()} />
        <div
          className="profileDropDownHeader"
          style={{
            display: showProfileDropDown ? "block" : "none"
          }}
        >
          <div className="profileItemHeader" onClick={() => navigateProfile()}>
            Profile
          </div>
          <div
            className="profileItemHeader"
            onClick={async () => {
              handleLogoutClick();
            }}
          >
            Logout
          </div>
        </div>
      </div>
    );
  }

  async function sendLogoutEvent() {
    try {
      const clientDateTime = moment().format();
      const userAgent = navigator.userAgent;
      const jsonObj = {
        userId: getUserId,
        clientDateTime: clientDateTime,
        userAgent: userAgent,
        userRole: getUserRole
      };
      //added to avoid multiple api calls, when we click on  logout multiple times.
      if (localStorage.getItem("isLogoutClicked") === "false") {
        localStorage.setItem("isLogoutClicked", "true");

        AdminService.saveLogoutevent(jsonObj).then((_response: Response) => {
          //do nothing
        });

        await persistor.purge();
        localStorage.removeItem("lms:isRenew");
        localStorage.removeItem("isLogoutClicked");
        localStorage.removeItem("clever:sso");
        localStorage.removeItem("oidc:session");
        localStorage.removeItem("page");
        localStorage.removeItem("oidc:state");
        localStorage.removeItem("persist:mypath-reports");
        for (let i = 0; i < localStorage.length; i++) {
          if (localStorage.key(i).includes("oidc.")) {
            localStorage.removeItem(localStorage.key(i));
          }
        }
        logout();
      }
    } catch (err) {
      //do nothing
    }
  }

  const handleLogoutClick = async () => {
    // we shouldn't clear  the session because the OIDC-IMLP needs id token to logout api and then it will clear all the sessionstorage.
    //sessionStorage.clear();;

    if (localStorage.getItem("ala:addRosterStudent") === "true") {
      setProfileExit(false);
      setShowExitAddStudentRosterModal(true);
    } else {
      sendLogoutEvent();
    }
  };

  function renderUrl() {
    return (
      <div>
        <label className="loginUrl" onClick={showUrlModal}>
          View Site Code
        </label>
      </div>
    );
  }

  function showUrlModal() {
    setShowLoginUrlModal(true);
  }

  function exitModalProfileButtonClick() {
    setShowExitAddStudentRosterModal(false);
    localStorage.setItem("ala:addRosterStudent", "false");
    localStorage.setItem("ala:createStudent", "false");

    dispatch(updateBreadCrumbs({ crumbs: [{ name: "Profile" }] }));
    setShowProfileDropDown(!showProfileDropDown);
    //@ts-ignore
    handleSideNavClick(`/profile/${getDaDetails.id}_${getUserGuId}`);
  }

  function exitModalLogoutButtonClick() {
    setShowExitAddStudentRosterModal(false);
    localStorage.setItem("ala:addRosterStudent", "false");
    sendLogoutEvent();
  }

  function copyLoginUrl() {
    navigator.clipboard.writeText(loginCode);
    setShowLoginUrlModal(false);
    setShowToast({
      val: true,
      msg: "Site Code copied successfully.",
      color: "#36BBA4",
      type: "success"
    });
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  function closeUrlModal() {
    setShowLoginUrlModal(false);
    setShowExitAddStudentRosterModal(false);
  }

  const userOnClick = () => {
    return (
      <div onClick={() => handleProfileClick()} className="profileClick">
        <label className="menuWelcome" style={{ cursor: "pointer" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span>{getUserFirstName}</span> <span style={{ marginLeft: "8px" }}>{getUserLastName}</span> {renderIcon()}
          </span>
        </label>
      </div>
    );
  };

  let pageTitle = getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name;
  let pageBadge = getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].badge;
  // Ensure pageBadge has a valid Component else set it to null
  if (!React.isValidElement(pageBadge)) {
    pageBadge = null;
  }

  return (
    <>
      <div ref={wrapperRef}>
        <div className="menuHolder">
          <img style={{ display: "none" }} src={CHATICON} />
          <div className="menuTitle">
            <span>{pageTitle}</span> {pageBadge}
          </div>
          <div className="menuWelcome">
            {getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                WNE {renderDot()} {getUserRole} {renderDot()} {userOnClick()}
              </span>
            ) : null}

            {(getUserRoleId === 3 || getUserRoleId === 4 || getUserRoleId === 5) && getDaDetails.name ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {" "}
                {!singleSignOnStatus ? (
                  <>
                    {renderUrl()} {renderDot()}
                  </>
                ) : null}{" "}
                <span>{getDaDetails.name}</span> {renderDot()} {userOnClick()}
              </span>
            ) : null}
          </div>
        </div>
        {showExitAddStudentRosterModal ? (
          <GetLoginUrl
            show={showExitAddStudentRosterModal}
            close={closeUrlModal}
            callback={profileExit ? exitModalProfileButtonClick : exitModalLogoutButtonClick}
            message={configMessages.studentRosterExitScreenMessage}
            isAddStudent={true}
          />
        ) : null}

        {showLoginUrlModal ? (
          <GetLoginUrl
            show={showLoginUrlModal}
            close={closeUrlModal}
            callback={copyLoginUrl}
            message={`${loginCode}`}
          />
        ) : null}
        <Snackbar
          open={showToast.val}
          autoHideDuration={2000}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: showToast.color, color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
        <PendoInitialize />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(HeaderMenu);
