import { TTSSTProps } from "./StudentHomePage.props";
import TransitionK2 from "../TransitionScreens/TransitionK2/TransitionK2";
import Transition68 from "../TransitionScreens/Transition68/Transition68";
import Transition912 from "../TransitionScreens/Transition912/Transition912";
import React from "react";

const isEnabled = (value?: string) => value === "On";

export function checkURL(subjectName: string, speechSettings: TTSSTProps) {
  let subPartURL = "";

  if (subjectName === "math") {
    if (isEnabled(speechSettings.tts?.math)) {
      subPartURL += `&tts=${speechSettings.tts?.math}`;
    }
    if (isEnabled(speechSettings.st?.math)) {
      subPartURL += `&st=${speechSettings.st?.math}`;
    }
    if (speechSettings.lang !== null || subPartURL) {
      if (speechSettings.lang !== null && isEnabled(speechSettings.lang?.math)) {
        subPartURL += `&language=Spanish`;
      } else {
        subPartURL += `&language=English`;
      }
    }
  } else if (subjectName === "ela") {
    if (isEnabled(speechSettings.tts?.reading)) {
      subPartURL += `&tts=${speechSettings.tts?.reading}`;
    }

    if (isEnabled(speechSettings.st?.reading)) {
      subPartURL += `&st=${speechSettings.st?.reading}`;
    }
  }
  return subPartURL;
}

//launching  CAT
export function transitionToCatPlayer(chronologicalGrade: string, speechSettings: TTSSTProps, subjectName: string) {
  let productId = 13705;
  if (subjectName === "Math") {
    productId = 13704;
  }
  let updatedGrade = chronologicalGrade === "K" || chronologicalGrade === "k" ? 0 : chronologicalGrade;
  let catSubject = subjectName === "Math" ? "math" : "ela";
  let subPartURL = checkURL(catSubject, speechSettings);
  window.location.assign(
    `${window.APP_ENV.cat_launch_url}/${catSubject}?gradeLevel=${updatedGrade}&productId=${productId}&returnUrl=https://${window.location.host}/home${subPartURL}`
  );
}

export const showTransitionScreensForGrade = (
  isKto2: boolean,
  is3to5: boolean,
  is6to8: boolean,
  isTestTimeAgain: boolean,
  grade: string,
  getTTSST: TTSSTProps,
  goBackToMyPathAssessment: () => void
) => {
  const subjectName = localStorage.getItem("stud:subName");
  if (isKto2 || is3to5) {
    return (
      <TransitionK2
        goBackToMyPathAssessment={() => {
          goBackToMyPathAssessment();
        }}
        transitionGo={() => {
          transitionToCatPlayer(grade, getTTSST, subjectName);
        }}
        isTestTimeAgain={isTestTimeAgain}
      />
    );
  } else if (is6to8) {
    return (
      <Transition68
        goBackToMyPathAssessment={() => {
          goBackToMyPathAssessment();
        }}
        transitionGo={() => {
          transitionToCatPlayer(grade, getTTSST, subjectName);
        }}
        isTestTimeAgain={isTestTimeAgain}
      />
    );
  } else {
    return (
      <Transition912
        goBackToMyPathAssessment={() => {
          goBackToMyPathAssessment();
        }}
        transitionGo={() => {
          transitionToCatPlayer(grade, getTTSST, subjectName);
        }}
        isTestTimeAgain={isTestTimeAgain}
      />
    );
  }
};
