import React from "react";
import LogoProps from "./logo.props";
import logoIcon from "../../assets/student68/logoIcon.png";

const Logo = (props: LogoProps) => {
  return (
    <div className="edge-ux-logo" data-testid="edge-ux-logo">
      <img src={logoIcon} alt="imagine-learning-logo" style={{ maxWidth: "167px" }} />
    </div>
  );
};

export default Logo;
