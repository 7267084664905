enum IconSizeEnum {
  "sm" = 12,
  "md" = 20,
  "lg" = 30,
  "1x" = 40,
  "2x" = 60,
  "3x" = 90,
  "4x" = 150,
  "5x" = 200
}
export default IconSizeEnum;
