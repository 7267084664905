import React from "react";
import { UxModal, UxAction, UxShadesEnum, UxPositionEnum } from "@wne-spa/ux-component-library";
import configMessages from "../../helpers/configMessages";
import { ICustomer } from "./customer.interface";

interface IUserListProps {
  status: () => void;
  close: () => void;
  custstate: boolean;
  customerdata: ICustomer;
}

export default function OrgStatus({ close, status, custstate, customerdata }: IUserListProps) {
  const customerStatusData = () => {
    if (custstate && customerdata.parent === 0 && customerdata.childDepth >= 1) {
      return configMessages.districtLevelDeactivate;
    } else if (!custstate && customerdata.parent === 0 && customerdata.childDepth >= 1) {
      return configMessages.districtLevelActivate;
    } else if (custstate && customerdata.parent !== 0 && customerdata.childDepth >= 1) {
      return configMessages.institutionLevelDeactivate;
    } else if (!custstate && customerdata.parent !== 0 && customerdata.childDepth >= 1) {
      return configMessages.institutionLevelActivate;
    } else if (custstate && customerdata.childDepth === 0) {
      return configMessages.schoolLevelDeactivate;
    } else if (!custstate && customerdata.childDepth === 0) {
      return configMessages.schoolLevelActivate;
    } else {
      return null;
    }
  };

  return (
    <UxModal
      showModal={true}
      handleClose={() => {
        close();
      }}
      title={"Confirm"}
      size="lg"
      scrollable={true}
      body={
        <>
          <div className="mb-5">
            <span className="name-text"> {customerStatusData()}</span>
          </div>
        </>
      }
      footer={[
        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Cancel"}
          className="px-2 py-1"
          priority={UxShadesEnum.LIGHT}
          callback={() => {
            close();
          }}
        />,
        <UxAction
          key={1}
          size="sm"
          enabled={true}
          text={"Yes"}
          className="px-4 py-1"
          priority={UxShadesEnum.DARK}
          callback={() => {
            status();
          }}
        />
      ]}
      vPosition={UxPositionEnum.CENTER}
      hPosition={UxPositionEnum.CENTER}
      backdrop="static"
    />
  );
}
