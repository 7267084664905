import React, { useEffect } from "react";
import { updateActivityIndicator, updatePageTitle } from "../../Redux/Action";
import { noLicenseProduct } from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import "./Nolicenseproducts.scss";
import AssignmentBuilderProps from "./AssignmentBuilder.props";

function NoLicenseProducts(props: AssignmentBuilderProps) {
  const { dispatch } = props;

  useEffect(() => {
    dispatch(updatePageTitle(`- Assignment Builder`));
    dispatch(updateActivityIndicator(false));
  }, []);

  return (
    <div>
      <div className="noLicenseIconHoler">
        <img
          src={noLicenseProduct}
          alt=""
          width="180px"
          height="110px"
          style={{ marginLeft: "15px", marginBottom: "30px" }}
        />
        <label className="sorrytext">Sorry! No product license</label>
      </div>
      <p className="remaingtext">
        There is no product license for this school.<br></br>
        Please contact your administrator.
      </p>
    </div>
  );
}
export default connect()(NoLicenseProducts);
