import React from "react";
import "./index.scss";

function Banner({ leftIcon, text, rightIcon, color, borderColor, backgroundColor }) {
  return (
    <div className="banner-outer-holder" data-testid="banners-wrapper">
      <div
        className="banner-holder"
        style={{
          color: color,
          backgroundColor: backgroundColor,
          border: `1px solid ${borderColor}`
        }}
      >
        <div className="d_flex">
          {leftIcon}
          {text}
        </div>
        {rightIcon}
      </div>
    </div>
  );
}

export default Banner;
