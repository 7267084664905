import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  Select,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./mui-table.scss";
import KebabIcon from "../../assets/icons/icon_kebab.svg";

interface LogTableRowDataProps {
  institutionID?: number;
  institutionName?: string;
  jobID?: number;
  type?: string;
  createdDate?: string;
  records?: number;
  error?: number;
  status?: string;
  name?: string;
}
interface IMUILogTable {
  columnNames: string[];
  rowMapper: Array<string | Array<string>>;
  rowData: LogTableRowDataProps[];
  MUITableClassName: string;
  emptyTableText?: string;
  checkboxRef: string;
  showSortList?: boolean[];
  sortListMapper?: Object;
  mtableHeight?: number;
  customErrorOptionCallback?: (args: { type: string; data: LogTableRowDataProps }) => void;
}

export default function UxMUILogTable(props: IMUILogTable) {
  const {
    columnNames,
    rowMapper,
    rowData,
    MUITableClassName,
    emptyTableText,
    mtableHeight,
    showSortList,
    sortListMapper,
    customErrorOptionCallback
  } = props;

  const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    container: {
      overflowX: "initial",
      maxHeight: `${mtableHeight}px !important`,
      marginTop: "0px !important"
    }
  });
  const classes = useStyles();
  const showSortListMapperFunc = (data: string, index: number) => {
    if (showSortList[index]) {
      if (sortListMapper && sortListMapper[data][0] === null) {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](true);
              sortListMapper[data][2](data);
            }}
            className="fa fa-sort sortIconDefault"
          ></i>
        );
      } else if (sortListMapper && sortListMapper[data][0]) {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](!sortListMapper[data][0]);
              sortListMapper[data][2](data);
            }}
            className="fa  fa-sort-asc sortIconUp"
          ></i>
        );
      } else {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](!sortListMapper[data][0]);
              sortListMapper[data][2](data);
            }}
            className="fa fa-sort-desc sortIconDown"
          />
        );
      }
    } else {
      return null;
    }
  };
  const displayTableBodyStatusMessage = (row: LogTableRowDataProps, index: number, getIndex: number) => {
    switch (row[rowMapper[index] as string]) {
      case "Completed":
        return (
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
            data-testid={`completed-tablebodystatusmessage-${getIndex}`}
          >
            <div className="status">Completed</div>
            <div className="dropper">
              <Select
                value={""}
                onChange={() => {
                  //do nothing
                }}
                inputProps={{
                  "data-testid": `select-input-completed-${getIndex}`
                }}
                onMouseOver={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                }}
                onMouseOut={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                onBlur={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                IconComponent={() => (
                  <img
                    className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                    src={KebabIcon}
                    alt="kebab icon"
                    width="30px"
                    height="30px"
                  />
                )}
                className="noUnderLine"
                data-testid={`select-completed-${getIndex}`}
              >
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Imported File"
                    })
                  }
                >
                  Download Imported File
                </MenuItem>
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Job Details"
                    })
                  }
                >
                  Job Details
                </MenuItem>
              </Select>
            </div>
          </div>
        );
      case "CompletedWithError":
        return (
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
            data-testid={`completed-witherror-tablebodystatusmessage-${getIndex}`}
          >
            <div className="status">Completed w/ Errors</div>
            <div className="dropper">
              <Select
                value={""}
                onChange={() => {
                  //do nothing
                }}
                inputProps={{
                  "data-testid": `select-input-completedwitherror-${getIndex}`
                }}
                onMouseOver={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                }}
                onMouseOut={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                onBlur={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                IconComponent={() => (
                  <img
                    className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                    src={KebabIcon}
                    alt="Kebab icon"
                    width="30px"
                    height="30px"
                  />
                )}
                className="noUnderLine"
                data-testid={`select-completedwitherror-${getIndex}`}
              >
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Errors File"
                    })
                  }
                >
                  Download Errors File
                </MenuItem>
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Imported File"
                    })
                  }
                >
                  Download Imported File
                </MenuItem>
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Job Details"
                    })
                  }
                >
                  Job Details
                </MenuItem>
              </Select>
            </div>
          </div>
        );
      case "Terminated":
        return (
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
            data-testid={`terminated-tablebodystatusmessage-${getIndex}`}
          >
            <div className="status">Terminated</div>
            <div className="dropper">
              <Select
                value={""}
                onChange={() => {
                  //do nothing
                }}
                inputProps={{
                  "data-testid": `select-input-terminated-${getIndex}`
                }}
                onMouseOver={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                }}
                onMouseOut={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                onBlur={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                IconComponent={() => (
                  <img
                    className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                    src={KebabIcon}
                    alt="kebab icon"
                    width="30px"
                    height="30px"
                  />
                )}
                className="noUnderLine"
                data-testid={`select-terminated-${getIndex}`}
              >
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Imported File"
                    })
                  }
                >
                  Download Imported File
                </MenuItem>
              </Select>
            </div>
          </div>
        );
      case "ImportDisabled":
        return (
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
            data-testid={`importdisabled-tablebodystatusmessage-${getIndex}`}
          >
            <div className="status">Import Disabled</div>
            <div className="dropper">
              <Select
                value={""}
                onChange={() => {
                  //do nothing
                }}
                inputProps={{
                  "data-testid": `select-input-importdisabled-${getIndex}`
                }}
                onMouseOver={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                }}
                onMouseOut={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                onBlur={() => {
                  document
                    .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                    .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                }}
                IconComponent={() => (
                  <img
                    className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                    src={KebabIcon}
                    alt="kebab icon"
                    width="30px"
                    height="30px"
                  />
                )}
                className="noUnderLine"
                data-testid={`select-importdisabled-${getIndex}`}
              >
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Imported File"
                    })
                  }
                >
                  Download Imported File
                </MenuItem>
              </Select>
            </div>
          </div>
        );
      case "Failed":
        return "Failed-Retrying";
      case "WaitingForUserIngestion":
        return "Waiting-User Ingestion";
      case "SystemError":
        return "System Error";
      default:
        return row[rowMapper[index] as string];
    }
  };
  const displayRowText = (row: LogTableRowDataProps, index: number) => {
    if (row[rowMapper[index] as string] === "Educator") {
      return "Educators";
    } else if (row[rowMapper[index] as string] === "Student") {
      return "Students";
    } else if (row[rowMapper[index] as string] === "Class") {
      return "Classes";
    } else {
      return "--";
    }
  };
  const tableCellOnKeys = (row: LogTableRowDataProps, index: number, keys: string, labelId: string) => {
    if (keys === "type") {
      return (
        <TableCell className="rowText" component="th" scope="row">
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            <label className="rowText ellipsis " style={{ marginTop: "10px" }} id={labelId}>
              {displayRowText(row, index)}
            </label>
          </div>
        </TableCell>
      );
    } else {
      return (
        <TableCell className="rowText" component="th" scope="row">
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            <label className="rowText ellipsis " style={{ marginTop: "10px" }} id={labelId}>
              {row[rowMapper[index] as string]}
            </label>
          </div>
        </TableCell>
      );
    }
  };

  return (
    <div className={`bg-white ${MUITableClassName} bg-white`} data-testid="log-table-wrapper">
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow role="checkbox">
              {columnNames.map((data: string, index: number) => {
                return index === 0 ? (
                  <TableCell className="textBold" align="left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        alignItems: "left"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "left",
                          alignItems: "center"
                        }}
                      >
                        <label style={{ marginTop: "10px" }}>{data}</label>
                        {rowData.length > 0 && showSortList ? showSortListMapperFunc(data, index) : null}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell className="textBold" align="left">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        alignItems: "center"
                      }}
                    >
                      <label style={{ marginTop: "10px" }}>{data}</label>
                      {rowData.length > 0 && showSortList ? showSortListMapperFunc(data, index) : null}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length > 0
              ? rowData.map((row: LogTableRowDataProps, getIndex: number) => {
                  const labelId = `enhanced-table-checkbox-${getIndex}`;
                  return (
                    <TableRow key={row.name}>
                      {rowMapper.map((keys: string, index: number) => {
                        return keys === "status" ? (
                          <TableCell className="rowText" component="th" scope="row">
                            <div className="rowText">
                              <span className=" ellipsis " style={{ marginTop: "10px" }} id={labelId}>
                                {displayTableBodyStatusMessage(row, index, getIndex)}
                              </span>
                            </div>
                          </TableCell>
                        ) : (
                          tableCellOnKeys(row, index, keys, labelId)
                        );
                      })}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        {emptyTableText && rowData.length === 0 ? (
          <label className="emptyTableFullText" data-testid="empty-table-text">
            <i>{emptyTableText}</i>
          </label>
        ) : null}
      </TableContainer>
    </div>
  );
}
