import {
  HEADERDATA,
  BREADCRUMBS,
  USERROLE,
  USERROLEID,
  USERID,
  USERGUID,
  USERLASTNAME,
  USERFIRSTNAME,
  CURRENTUSERDETAILS,
  CURRENTORGANIZATIONDETAILS,
  DADETAILS,
  ACTIVITYINDICATOR,
  CLASSVIEWTAB,
  SCHOOLID,
  SCHOOLGUID,
  REFRESHSP,
  USEREMAIL,
  USERNAME,
  SIDEBARACTIVECLASS,
  PAGETITLE,
  ROSTERFILTER,
  EDUCATORSFILTER,
  STUDENTSFILTER,
  WNEUSERFILTER,
  CLASSFILTER,
  STUDENTLISTSORT,
  SEARCHSTUDENTLISTSORT,
  WNEUSERLISTSORT,
  ROSTERLISTSORT,
  CLASSLISTSORT,
  CUSTOMERLISTSORT,
  TABCHANGE,
  SEARCHEDUCATORLISTSORT,
  EDUCATORLISTSORT,
  STUDENTPLACEMENTLISTSORT,
  STUDENTPLACEMENTFILTER,
  PAGINATIONCONTROLLERSTATE,
  FLAGRKEYS,
  BULKLISTSORT,
  USERROLEDETAILS,
  EXTERNALID,
  CLASSEXTERNALID,
  ACTIVEURL,
  ONPROCESS,
  EDUCATORDETAILS,
  SITECODEDETAILS,
  ASSESSMENTDETAILS,
  USEID,
  CATEGORY,
  ANNOTATEURL,
  TABNAME,
  CLASSGUID,
  LSBREPORT,
  DISTRICTLEVELFIRSTTAB,
  DOLITTLETOKEN,
  ACADEMICGRADE,
  SHOWSCREEN,
  ORGLOGINCODEAVAILABLE,
  LICENSINGLISTSORT,
  TRANSITIONSCREEN,
  TESTTIMEAGAIN,
  LICENSINGORGIDS,
  CURRENTSCHOOLID,
  CURRENTSCHOOLGUID,
  STUDENTSUBTAB,
  TOPLEVELDA,
  CICENABLE,
  ASSESSMENTTAB,
  FIRSTTAB,
  PREVIOUSURL,
  FROMSIDEBARASSESSMENT,
  PREVIOUSURLCLASSPROGRESS,
  LOGLISTSORT,
  SHOWLOGTABLE,
  REPORTCARD,
  CLASSDETAILS,
  IMPORTLOGFILTER,
  IMPORTLOGCALENDAR,
  NETSUITECALENDAR,
  SUBJECTNAME,
  ADMINNAME,
  LEVEL,
  PREVIOUSMYPATHURL,
  SCHOOLGUIDS,
  BREAKPOINT,
  DLLSBReportsData,
  ISWNEUSER,
  STUDENTIDS,
  SPANISHIDS,
  PREVIOUSBENCHMARKGROWTHREPORTURL,
  ISSESSIONMODAL,
  GETSTUDENTANDCLASSFILTERURL,
  CREATESTUDENT,
  ASSIGNMENTBUILDERORGID,
  ACADEMICCALENDAR,
  CLASSSUMMARYREPORT,
  SELECTEDSTUDENTIDS,
  UPDATESHOWCOMPONENT,
  ADDSTUDENTROSTER,
  UPDATEROLELEVEL,
  GETDSDSREPORTTOKEN,
  GETORGFLAGRKEYS,
  GETCHILDDEPTH,
  ISORGANIZATIONACTIVE,
  ISMPPAPP,
  GETMPPSUBJECT,
  ISINHERITED,
  ISORGHASREGISTRATIONOWNED
} from "../Action";
import { AnyAction } from "redux";

let flagrvalues = {};

const initialState = {
  getOrgFlagrKeys: {},
  getFlagrKeys: flagrvalues,
  getDaDetails: { name: "", id: 0, type: 0 },
  getExternalId: "",
  getClassExternalId: "",
  getHeaderData: "WNE",
  getBreadCrumbsData: { crumbs: [{ name: "", path: "/" }] },
  getUserRole: "",
  getUserRoleId: 0,
  getUserId: 0,
  getUserGuId: 0,
  getUserEmail: "",
  getUserName: "",
  getUserLastName: "",
  getUserFirstName: "",
  getActivityIndicator: false,
  getOnProcess: false,
  getClassViewTab: "classsummary",
  getSchoolID: 0,
  getSchoolGUID: "",
  getRefreshSP: false,
  getSidebarActiveClass: "",
  getPageTitle: "",
  getRosterFilter: ["Active"],
  getStudentsFilter: ["Active"],
  getEducatorsFilter: ["Active"],
  getWNEUserFilter: ["Active"],
  getClassFilter: ["Active"],
  getStudentListSort: { name: "lastname", val: "asc" },
  getSearchStudentListSort: { name: "lastname", val: "asc" },
  getWNEUserListSort: { name: "lastname", val: "asc" },
  getClassListSort: { name: "classname", val: "asc" },
  getRosterListSort: { name: "lastname", val: "asc" },
  getCustomerListSort: { name: "organizationtitle", val: "ASC" },
  getTabChange: false,
  getEducatorListSort: { name: "lastname", val: "asc" },
  getSearchEducatorListSort: { name: "lastname", val: "asc" },
  getStudentPlacementListSort: { name: "lastName", val: "asc" },
  getStudentPlacementFilter: ["gl-conf-All", "All Subjects"],
  getImportLogFilter: [""],
  getBulkListSort: { name: "submitteddate", val: "ASC" },
  userRoleDetails: "",
  getActiveURL: "",
  getPreviousURL: "",
  getLogListSort: { name: "createdDate", val: "desc" },
  getShowLogTable: false,
  getPendoEducatorDetails: {
    userID: "",
    userGuid: "",
    roleName: "",
    firstName: "",
    lastName: "",
    email: "",
    activeClasses: "",
    userinstitutionname: "",
    userInstitutionid: "",
    userInstitutionTimeZone: "",
    userInstitutionSiteCode: "",
    numberofclasses: "",
    userInstitutionState: "",
    userInstitutionSiteId: "",
    userInstitutionSiteName: "",
    userPathFromSiteToUsersInstitution: "",
    userParentInstitutionName: "",
    userInstituionALAType: "",
    userParentInstitutionId: "",
    userCategory: "",
    ScheduledSISImports: "",
    SingleSignOnSettings: "",
    SpanishLanguageMathAvailability: "",
    SpanishLanguageScienceAvailability: ""
  },
  getSiteCodeDetails: {
    siteCode: "",
    siteInstitutionName: "",
    siteInstitutionId: "",
    siteInstitutionState: "",
    siteInstitutionAlaType: "",
    siteInstitutionCategory: ""
  },
  getUseId: "",
  getAssessmentDetails: "",
  getCategoryDetails: "",
  getPendoUrls: { currentUrl: "" },
  getPaginationControllerState: [
    { paginationPageName: "Students", paginationCount: 50 },
    { paginationPageName: "Educators", paginationCount: 50 },
    { paginationPageName: "WNEUsers", paginationCount: 50 },
    { paginationPageName: "Classes", paginationCount: 50 },
    { paginationPageName: "Roaster", paginationCount: 50 },
    { paginationPageName: "StudentPlacement", paginationCount: 50 },
    { paginationPageName: "Institutions", paginationCount: 50 },
    { paginationPageName: "ProductLicense", paginationCount: 50 },
    { paginationPageName: "ImportLog", paginationCount: 50 }
  ],

  getReportsLevel: "",

  getDoLittleToken: "",
  getOrgLoginCodeAvailable: false,
  getLicensingListSort: { name: "organizationtitle", val: "ASC" },

  getTabName: "",
  getStudentSubtab: "home",
  getClassGUID: "classGUID",
  getLSBReport: false,
  getDistrictLevelFirstTab: "",
  getChronologicalGrade: "",
  getShowScreen: false,
  isTransitionScreen: false,
  testTimeAgain: true,
  getLicensingOrgIds: [""],
  getCurrentSchoolID: 0,
  getCurrentSchoolGuid: "",
  topLevelDA: false,
  cicEnable: false,
  getAssessmentTab: "home",
  getFirstTab: "",
  updatePreviousURL: "",
  updateFromSideBarAssessment: false,
  updatePreviousURLForClassProgress: "",
  getReportCard: "",
  getClassDetails: { classId: 0, className: "" },
  getImportLogCalendar: {
    from: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    },
    to: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    }
  },
  getNetSuiteCalendar: {
    from: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    },
    to: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    }
  },

  getAcademicCalendar: {
    from: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    },
    to: {
      date: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    }
  },
  getSubjectName: "",

  getAdminName: "adminhome",

  getUpdateLevel: "",
  getPreviousMyPathURL: "",
  getSchoolGuids: [],

  getBreakpoint: "xxl",
  getDistrictLevelLSBReportsData: {},
  getWNEUser: false,
  studentIds: [],
  getSpanishIds: [],
  getPreviousBenchmarkGrowthReportURL: "",
  isSessionModal: false,
  getStudentAndClassFilterURL: "",
  getCreateStudent: false,
  getAssignmentBuilderOrgId: {},
  classSummaryReport: {
    classId: "",
    className: "",
    subject: "",
    academicSessionId: "",
    orgTimezone: "",
    schoolYear: "",
    schoolGeoState: ""
  },
  selectedStudentIds: [],
  updateShowComponent: false,
  addStudentToRosterSuccess: false,
  updateRoleLevel: "",
  getDSDSReportToken: "",
  getChildDepth: 0,
  isOrganizationActive: null,
  globalSubjectPurpose: "Purpose",
  getMPPAPP: false,
  getMPPSubject: "",
  getIsInherited: false,
  getIsOrgHasRegistrationOwned: false
};

export default function GlobalReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case HEADERDATA:
      return { ...state, getHeaderData: action.payload };
    case FLAGRKEYS:
      return { ...state, getFlagrKeys: action.payload };
    case TABCHANGE:
      return { ...state, getTabChange: action.payload };
    case PAGETITLE:
      return { ...state, getPageTitle: action.payload };
    case STUDENTPLACEMENTFILTER:
      return { ...state, getStudentPlacementFilter: action.payload };
    case SEARCHEDUCATORLISTSORT:
      return { ...state, getSearchEducatorListSort: action.payload };
    case STUDENTPLACEMENTLISTSORT:
      return { ...state, getStudentPlacementListSort: action.payload };
    case EDUCATORLISTSORT:
      return { ...state, getEducatorListSort: action.payload };
    case CUSTOMERLISTSORT:
      return { ...state, getCustomerListSort: action.payload };
    case ROSTERLISTSORT:
      return { ...state, getRosterListSort: action.payload };
    case CLASSLISTSORT:
      return { ...state, getClassListSort: action.payload };
    case WNEUSERLISTSORT:
      return { ...state, getWNEUserListSort: action.payload };
    case SEARCHSTUDENTLISTSORT:
      return { ...state, getSearchStudentListSort: action.payload };
    case STUDENTLISTSORT:
      return { ...state, getStudentListSort: action.payload };
    case ROSTERFILTER:
      return { ...state, getRosterFilter: action.payload };
    case WNEUSERFILTER:
      return { ...state, getWNEUserFilter: action.payload };
    case CLASSFILTER:
      return { ...state, getClassFilter: action.payload };
    case BREADCRUMBS:
      return { ...state, getBreadCrumbsData: action.payload };
    case USERNAME:
      return { ...state, getUserName: action.payload };
    case USERROLE:
      return { ...state, getUserRole: action.payload };
    case USERROLEID:
      return { ...state, getUserRoleId: action.payload };
    case USERID:
      return { ...state, getUserId: action.payload };
    case USERGUID:
      return { ...state, getUserGuId: action.payload };
    case USEREMAIL:
      return { ...state, getUserEmail: action.payload };
    case USERLASTNAME:
      return { ...state, getUserLastName: action.payload };
    case USERFIRSTNAME:
      return { ...state, getUserFirstName: action.payload };
    case CURRENTUSERDETAILS:
      return { ...state, getCurrentUserDetails: action.payload };
    case CURRENTORGANIZATIONDETAILS:
      return { ... state, getCurrentOrganizationDetails: action.payload };
    case DADETAILS:
      return { ...state, getDaDetails: action.payload };
    case EXTERNALID:
      return { ...state, getExternalId: action.payload };
    case CLASSEXTERNALID:
      return { ...state, getClassExternalId: action.payload };
    case ACTIVITYINDICATOR:
      return { ...state, getActivityIndicator: action.payload };
    case CLASSVIEWTAB:
      return { ...state, getClassViewTab: action.payload };
    case SCHOOLID:
      return { ...state, getSchoolID: action.payload };
    case SCHOOLGUID:
      return { ...state, getSchoolGUID: action.payload };
    case REFRESHSP:
      return { ...state, getRefreshSP: action.payload };
    case SIDEBARACTIVECLASS:
      return { ...state, getSidebarActiveClass: action.payload };
    case STUDENTSFILTER:
      return { ...state, getStudentsFilter: action.payload };
    case EDUCATORSFILTER:
      return { ...state, getEducatorsFilter: action.payload };
    case PAGINATIONCONTROLLERSTATE:
      return { ...state, getPaginationControllerState: action.payload };
    case BULKLISTSORT:
      return { ...state, getBulkListSort: action.payload };
    case USERROLEDETAILS:
      return { ...state, userRoleDetails: action.payload };
    case ONPROCESS:
      return { ...state, getOnProcess: action.payload };
    case ACTIVEURL:
      return { ...state, getActiveURL: action.payload };
    case EDUCATORDETAILS:
      return { ...state, getPendoEducatorDetails: action.payload };
    case SITECODEDETAILS:
      return { ...state, getSiteCodeDetails: action.payload };
    case ASSESSMENTDETAILS:
      return { ...state, getAssessmentDetails: action.payload };
    case USEID:
      return { ...state, getUseId: action.payload };
    case CATEGORY:
      return { ...state, getCategoryDetails: action.payload };
    case ANNOTATEURL:
      return { ...state, getPendoUrls: action.payload };

    case TABNAME:
      return { ...state, getTabName: action.payload };

    case CLASSGUID:
      return { ...state, getClassGUID: action.payload };
    case LSBREPORT:
      return { ...state, getLSBReport: action.payload };
    case DISTRICTLEVELFIRSTTAB:
      return { ...state, getDistrictLevelFirstTab: action.payload };
    case ACADEMICGRADE:
      return { ...state, getChronologicalGrade: action.payload };
    case SHOWSCREEN:
      return { ...state, getShowScreen: action.payload };
    case ORGLOGINCODEAVAILABLE:
      return { ...state, getOrgLoginCodeAvailable: action.payload };
    case DOLITTLETOKEN:
      return { ...state, getDoLittleToken: action.payload };
    case LICENSINGLISTSORT:
      return { ...state, getLicensingListSort: action.payload };
    case TRANSITIONSCREEN:
      return { ...state, isTransitionScreen: action.payload };
    case TESTTIMEAGAIN:
      return { ...state, testTimeAgain: action.payload };
    case LICENSINGORGIDS:
      return { ...state, getLicensingOrgIds: action.payload };
    case CURRENTSCHOOLID:
      return { ...state, getCurrentSchoolID: action.payload };
    case CURRENTSCHOOLGUID:
      return { ...state, getCurrentSchoolGuid: action.payload };

    case STUDENTSUBTAB:
      return { ...state, getStudentSubtab: action.payload };

    case TOPLEVELDA:
      return { ...state, topLevelDA: action.payload };

    case ASSESSMENTTAB:
      return { ...state, getAssessmentTab: action.payload };

    case FIRSTTAB:
      return { ...state, getFirstTab: action.payload };
    case PREVIOUSURL:
      return { ...state, updatePreviousURL: action.payload };
    case FROMSIDEBARASSESSMENT:
      return { ...state, updateFromSideBarAssessment: action.payload };
    case PREVIOUSURLCLASSPROGRESS:
      return { ...state, updatePreviousURLForClassProgress: action.payload };
    case CICENABLE:
      return { ...state, cicEnable: action.payload };
    case LOGLISTSORT:
      return { ...state, getlogListSort: action.payload };
    case SHOWLOGTABLE:
      return { ...state, getShowLogTable: action.payload };
    case REPORTCARD:
      return { ...state, getReportCard: action.payload };
    case SUBJECTNAME:
      return { ...state, getSubjectName: action.payload };
    case CLASSDETAILS:
      return { ...state, getClassDetails: action.payload };

    case IMPORTLOGFILTER:
      return { ...state, getImportLogFilter: action.payload };
    case IMPORTLOGCALENDAR:
      return { ...state, getImportLogCalendar: action.payload };
    case NETSUITECALENDAR:
      return { ...state, getNetSuiteCalendar: action.payload };

    case ADMINNAME:
      return { ...state, getAdminName: action.payload };
    case LEVEL:
      return { ...state, getUpdateLevel: action.payload };
    case PREVIOUSMYPATHURL:
      return { ...state, getPreviousMyPathURL: action.payload };
    case SCHOOLGUIDS:
      return { ...state, getSchoolGuids: action.payload };
    case BREAKPOINT:
      return { ...state, getBreakpoint: action.payload };
    case DLLSBReportsData:
      return { ...state, getDistrictLevelLSBReportsData: action.payload };
    case ISWNEUSER:
      return { ...state, getWneUser: action.payload };
    case STUDENTIDS:
      return { ...state, studentIds: action.payload };
    case SPANISHIDS:
      return { ...state, getSpanishIds: action.payload };
    case PREVIOUSBENCHMARKGROWTHREPORTURL:
      return { ...state, getPreviousBenchmarkGrowthReportURL: action.payload };
    case ISSESSIONMODAL:
      return { ...state, isSessionModal: action.payload };
    case GETSTUDENTANDCLASSFILTERURL:
      return { ...state, getStudentAndClassFilterURL: action.payload };
    case CREATESTUDENT:
      return { ...state, getCreateStudent: action.payload };
    case ASSIGNMENTBUILDERORGID:
      return { ...state, getAssignmentBuilderOrgId: action.payload };

    case ACADEMICCALENDAR:
      return { ...state, getAcademicCalendar: action.payload };
    case CLASSSUMMARYREPORT:
      return { ...state, classSummaryReport: action.payload };
    case SELECTEDSTUDENTIDS:
      return { ...state, selectedStudentIds: action.payload };

    case UPDATESHOWCOMPONENT:
      return { ...state, updateShowComponent: action.payload };
    case ADDSTUDENTROSTER:
      return { ...state, addStudentToRosterSuccess: action.payload };

    case UPDATEROLELEVEL:
      return { ...state, updateRoleLevel: action.payload };

    case GETDSDSREPORTTOKEN:
      return { ...state, getDSDSReportToken: action.payload };

    case GETORGFLAGRKEYS:
      return { ...state, getOrgFlagrKeys: action.payload };
    case GETCHILDDEPTH:
      return { ...state, getChildDepth: action.payload };

    case ISORGANIZATIONACTIVE:
      return { ...state, isOrganizationActive: action.payload };
    case ISMPPAPP:
      return { ...state, getMPPAPP: action.payload };
    case GETMPPSUBJECT:
      return { ...state, getMPPSubject: action.payload };
    case ISINHERITED:
      return { ...state, getIsInherited: action.payload };
    case ISORGHASREGISTRATIONOWNED:
      return { ...state, getIsOrgHasRegistrationOwned: action.payload };
    default:
      return state;
  }
}
