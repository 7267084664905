import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateAssessmentTab,
  updateFromSideBarAssessment,
  updateIsOrganizationActive
} from "../../Redux/Action";
import AssessmentCards from "./AssessmentCards";
import { AdminService } from "../../services/helpers/api.services";
import GetToken from "../../authService/GetToken";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { BehaviorSubject } from "rxjs";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId
  };
}

type AssessmentProps = {
  dispatch: AppDispatch;
  schedulerAssessmentSubject?: BehaviorSubject<object>;
};

interface Props extends AssessmentProps, ReduxProps {}

function Assessment(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, schedulerAssessmentSubject, getDaDetails, getUserRoleId } = props;

  useEffect(() => {
    dispatch(updatePageTitle("- Assessments"));
    dispatch(updateAssessmentTab("home"));
    dispatch(updateFromSideBarAssessment(true));
    dispatch(updateActivityIndicator(false));
    let apiObj = { guid: id.toString() };
    AdminService.getGUIDSWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        responseGUIDS(data);
      } else {
        // do nothing
      }
    });
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (getDaDetails.type === 1) {
        const breadcrumb = {
          crumbs: [{ path: "/assessments", name: "Assessments" }]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updateActivityIndicator(false));
      } else {
        try {
          // await AdminService.getParentsWithRoot(id, id).then(
          let apiObj = { rid: id, cid: id };
          await AdminService.getParentsWithRootWithGuid(apiObj).then(
            ({ status, data }: { status: number; data: Response }) => {
              if (status === 200) {
                handleSuccessResponse(data);
              } else {
                //do nothing
              }
            }
          );
        } catch (err) {
          //do nothing
        }
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDaDetails]);

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    if (getUserRoleId === 4) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          {
            path: `/saassessmentschools/${getDaDetails.id}`,
            name: "Assessments"
          },
          { path: "/assessments", name: details.name }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    } else if (getUserRoleId === 5) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          {
            path: `/teacherassessmentschools/${getDaDetails.id}`,
            name: "Assessments"
          },
          { path: "/assessments", name: details.name }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    //@ts-ignore
    dispatch(updateActivityIndicator(false));
  }

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    schedulerAssessmentSubject.next({
      token: GetToken(),
      guid: allData[0].orgGUID
    });
    dispatch(updateIsOrganizationActive(allData[0].isActive));
  }

  return (
    <>
      <AssessmentCards id={id} isFromSideBarAssessment={true} />
    </>
  );
}

export default connect(mapStateToProps)(Assessment);
