import UxStyleEnum from "../enums/style.enum";

const VisualActionDefaults = {
  educator: {
    // state: StateEnum,
    // status: StatusEnum,
    // content: VisualActionContentProps,
    // size: "sm" | "md" | "lg" | "xl" | "1:1" | undefined,
    style: UxStyleEnum.ROUNDED
    // priority: UxPriorityEnum | UxShadesEnum,
    // className: string,
    // bgImage: string,
  },
  student_3_5: {
    style: UxStyleEnum.CIRCLE
  },
  student_k_2: {
    style: UxStyleEnum.CIRCLE
  }
};

export default VisualActionDefaults;
