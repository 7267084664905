import { updateBreadCrumbs, updatePageTitle, updateActivityIndicator } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { connect } from "react-redux";
import React, { useEffect } from "react";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId
  };
}

type DomainMappingProps = {
  dispatch: AppDispatch;
};
interface Props extends DomainMappingProps, ReduxProps {}

function DomainMapping(props: Props) {
  const { dispatch } = props;

  useEffect(() => {
    dispatch(updateActivityIndicator(false));
    dispatch(updatePageTitle("- Domain Mapping"));
    dispatch(
      updateBreadCrumbs({
        crumbs: [{ path: "/domainmapping", name: "Domain Mapping" }]
      })
    );
  }, [dispatch]);

  return <div id="domainmapping"></div>;
}

export default connect(mapStateToProps)(DomainMapping);
