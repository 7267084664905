import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import "./SASettings.scss";
import { AdminService } from "../../services/helpers/api.services";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getExternalId: state.getExternalId,
    getActivityIndicator: state.getActivityIndicator
  };
}

interface SASettingProps {
  dispatch: AppDispatch;
}

interface Props extends SASettingProps, ReduxProps {}

function SASettings(props: Props) {
  const { dispatch, getDaDetails, getExternalId } = props;
  const [loginCode, setLoginCode] = useState("");
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });

  const [timezoneObject, setTimeZoneObject] = useState({});
  const [orgId, setOrgId] = useState("");
  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  useEffect(() => {
    dispatch(updatePageTitle(`- Settings`));
    const breadcrumb = { crumbs: [{ path: "/sasettings", name: "Settings" }] };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDaDetails]);

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    let orgids = { guid: id };
    AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotLogincodeResponse(data);
      } else {
        // do nothing
        dispatch(updateActivityIndicator(false));
      }
    });
    // AdminService.getOrgTimezone(id)
    let apiObj1 = { orgGuid: id };
    AdminService.getOrgTimezoneWithGuid(apiObj1).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotTimezoneResponse(data);
      }
    });
  }, []);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.json();

    if (details.length > 0) {
      setLoginCode(details[0].loginCode);
      setOrgId(details[0].organizationID);
    } else {
      //do nothing
    }
    dispatch(updateActivityIndicator(false));
  }

  async function gotTimezoneResponse(responseData: Response) {
    const timezoneObject = await responseData.json();
    setTimeZoneObject(timezoneObject);
  }

  const loginCodeValue = loginCode !== "" ? loginCode : "- -";

  return (
    <>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: showToast.color, color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div className="ps-4">
        <div className="nweasettings">
          <p className="settingsheadings"> INSTITUTION ID </p>
          <p className="settingstagvalue"> {orgId} </p>
        </div>
        <div className="nweasettings">
          <p className="settingsheadings"> External Institution ID </p>
          <p className="settingstagvalue"> {getExternalId}</p>
        </div>

        <div>
          <span className="settingsheadings"> Site Code </span>
          <p className="settingstagname "> SITE CODE </p>

          <p className="settingstagvalue">
            {loginCodeValue}
            <CopyToClipboard text={loginCodeValue}>
              <button
                style={{ border: "none" }}
                className="copyButton"
                onClick={() => {
                  setShowToast({
                    val: true,
                    msg: "Site Code copied successfully",
                    color: "#36BBA4",
                    type: "success"
                  });
                }}
              >
                <i className="fal fa fa-clone"></i>
              </button>
            </CopyToClipboard>
          </p>
        </div>
        <div className="loginCodesettings">
          <span className="settingsheadings"> Time Zone </span>
          <div>
            <p className="settingstagname"> TIME ZONE </p>
            <p className="settingstagvalue">
              {/* @ts-ignore */}
              {timezoneObject.timeZoneName ? `(UTC ${timezoneObject.utcTime}) ${timezoneObject.timeZoneName}` : "- -"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(SASettings);
