import React from "react";
import { myPathAssessmentImg } from "@wne-spa/ux-component-library";

export default function MyPathAssessment612({ launchCAT }: Readonly<MPAProps>) {
  const subjectName = localStorage.getItem("stud:subName");
  return (
    <div
      className="assessmentMathPage68 scrollbarhidden"
      tabIndex={0}
      role="button"
      aria-label={`Go to MyPath  ${subjectName} Assessment page`}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          launchCAT(subjectName);
        } else {
          //do nothing
        }
      }}
    >
      <div className="iconCircleMath68">
        <img src={`${myPathAssessmentImg}`} alt="" style={{ width: "30px", height: "30px" }}></img>
      </div>
      <div className="myPathMathLabel">{`MyPath ` + subjectName + ` Assessment`}</div>
      <div
        className="myPathAssessmentGoMath68"
        onClick={() => {
          launchCAT(subjectName);
        }}
      >
        GO
      </div>
    </div>
  );
}
