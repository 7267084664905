import { get, post } from "../config/ky.lmsconfig";
import {
  CreateClassWithGuidProps,
  ClassUpdateWithGuidProps,
  EnrollStudentsWithGuidProps,
  GetClassListForTeacherWithGuidProps,
  GetClassListWithGuidProps,
  StudentsEnrolledWithGuidProps
} from "../helpers/lms.services.props";

export const LMSService = {
  createClass: (args: CreateClassWithGuidProps) =>
    post(`class/createclass`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassListForTeacher: (args: GetClassListForTeacherWithGuidProps) =>
    post(`class/forteacher`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  activateClasses: (id: string[]) =>
    post(`class/activate?ClassIds=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  deactivateClasses: (id: string[]) =>
    post(`class/deactivate?ClassIds=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkExportClasses: (id: number, oid: string, flag: boolean) =>
    get(`bulkexport/classes?UserID=${id}&ExpectedOrgID=${oid}&flag=${flag}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentsEnrolled: (args: StudentsEnrolledWithGuidProps) =>
    post(`class/studentsenrolled`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  classUpdate: (args: ClassUpdateWithGuidProps, id: string) =>
    post(`class/update?ClassId=${id}`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getSubjects: (id: number | string) =>
    get(`class/getsubjects?organizationId=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  enrollStudents: (enrollJson: EnrollStudentsWithGuidProps) =>
    post(`class/EnrollStudentsProps`, {
      json: { ...enrollJson }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassDetails: (id: string) =>
    get(`class/details?classid=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassGUIDDetails: (guid: string) =>
    get(`class/id?classGuid=${guid}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),

  getSubjectsWithGiud: (id: string | number) =>
    get(`class/getsubjectswithguid?organizationGuid=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassListWithGuid: (args: GetClassListWithGuidProps) =>
    post(`class/getclasslistwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassDetailsWithGuid: (id: string) =>
    get(`class/detailswithguid?classGuid=${id}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  studentsEnrolledWithGuid: (args: StudentsEnrolledWithGuidProps) =>
    post(`class/studentsenrolledwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  createClassWithGuid: (args: CreateClassWithGuidProps) =>
    post(`class/createclasswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassListForTeacherWithGuid: (args: GetClassListForTeacherWithGuidProps) =>
    post(`class/forteacherwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  enrollStudentsWithGuid: (enrollJson: EnrollStudentsWithGuidProps) =>
    post(`class/enrollstudentswithguid`, {
      json: { ...enrollJson }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkExportClassesWithGuid: (id: string | number, oid: string, flag: boolean) =>
    get(`bulkexport/classeswithguid?UserGuid=${id}&ExpectedOrgGuid=${oid}&flag=${flag}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassCSVData: (args: GetClassListWithGuidProps) =>
    post(`school/classes/csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getStudentRosterclasslevelCSVData: (args: StudentsEnrolledWithGuidProps) =>
    post(`class/students/csv  `, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getClassCSVDataforTeacher: (args: GetClassListForTeacherWithGuidProps) =>
    post(`teacher/classes/csv`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      })
};
