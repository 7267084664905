/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./addStudents.scss";
import "./roster.scss";
import {
  UxAction,
  UxShadesEnum,
  UxPaginate,
  UxMUITable,
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxSearchInput,
  TimesIcon,
  UxPaginationController,
  classrosterimg
} from "@wne-spa/ux-component-library";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateRosterListSort,
  updatePaginationControllerState,
  updateClassViewTab,
  updateBreadCrumbs,
  updateSelectedStudentIds,
  updateAddStudentRoaster
} from "../../Redux/Action";
import BulkImportsModal from "../modals/bulk-imports";
import { paginationControllers } from "../../helpers/configs";
import { UserService } from "../../services/helpers/user.services";
import { LMSService } from "../../services/helpers/lms.services";
import Snackbar from "@material-ui/core/Snackbar";
import HelpIcon from "../../images/help_search_icon.png";
import ReduxProps, { SelectedStudentProps } from "../../Redux/Redux.props";
import { AddStudentRosterProps, RosterSearchRefProps } from "./AddStudentRoster.props";
import ky from "ky";
import { classLimit } from "../../helpers/configs";

interface Props extends ReduxProps, AddStudentRosterProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getRefreshSP: state.getRefreshSP,
    getRosterFilter: state.getRosterFilter,
    getRosterListSort: state.getRosterListSort,
    getUserId: state.getUserId,
    getTabChange: state.getTabChange,
    getPaginationControllerState: state.getPaginationControllerState,
    getSchoolID: state.getSchoolID,
    getSchoolGUID: state.getSchoolGUID,
    getClassExternalId: state.getClassExternalId,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    selectedStudentIds: state.selectedStudentIds,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}

function AddStudentRoster(props: Props) {
  const {
    dispatch,
    getRosterListSort,
    getUserId,
    getTabChange,
    getPaginationControllerState,
    getSchoolGUID,
    getClassExternalId,
    getBreadCrumbsData,
    selectedStudentIds,
    getIsOrgHasRegistrationOwned
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [spinner, setSpinner] = useState(true);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState({
    page: 0,
    size: 0,
    pageSize: 0,
    values: []
  });
  const [enrolledstudentCount, setEnrolledstudentCount] = useState(0);
  const elementRef = useRef(null);
  const [tableHgt, setTableHgt] = useState(0);
  let columnNames = ["Student Name", "UserName", "EXTERNAL ID", "Student ID", "Grade Level"];
  columnNames = columnNames.filter((column) =>
    getIsOrgHasRegistrationOwned ? column !== "EXTERNAL ID" : column !== "Student ID"
  );

  const rowMapper = [["lastName", "firstName"], "loginName", "districtAssignedId", "gradeLevelName"];
  const grades = ["K", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const sortBy = useRef(getRosterListSort.name);
  const sortOrder = useRef(getRosterListSort.val);
  const [studentNameBool, setStudentNameBool] = useState<boolean | null>(null);
  const [gradeLevelBool, setGradeLevelBool] = useState<boolean | null>(null);
  const searchResultRef = useRef(false);
  const [emptyTableText, setEmptyTableText] = useState<JSX.Element>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [userNameBool, setuserNameBool] = useState<boolean | null>(null);
  const textMessage = React.useRef("none");
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });
  const [checkBoxEnable, setCheckBoxEnable] = useState(true);
  interface IEnrolledStudentsList {
    firstName: string;
    lastName: string;
    loginName: string;
    eid: number;
    glevel: number;
    isEnabled: boolean;
  }

  interface IPage {
    size: number;
    page: number;
    pageSize: number;
    values: IEnrolledStudentsList[];
  }

  const rosterSearchRef = useRef<RosterSearchRefProps>({ value: "" });

  const Styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          marginTop: theme.spacing(2)
        }
      }
    })
  );
  let classes = Styles();

  async function callback(args: number) {
    let updateState = getPaginationControllerState;
    updateState[4] = { paginationPageName: "Roaster", paginationCount: args };
    setPageNo(1);
    dispatch(updatePaginationControllerState(updateState));
    try {
      dispatch(updateActivityIndicator(true));
      UserService.updatePaginationCount(getUserId, 5, args).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          getEnrolledStudents(1, rosterSearchRef.current.value, false);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 500) {
        setTableHgt(
          //@ts-ignore
          parseInt(window.innerHeight - (elementRef.current.offsetTop + 180))
        );
      } else {
        //@ts-ignore
        setTableHgt(parseInt(500 - (elementRef.current.offsetTop + 0)));
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (window.innerHeight > 500) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 180))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(500 - (elementRef.current.offsetTop + 0)));
    }
  }, [getTabChange]);

  useEffect(() => {
    if (getRosterListSort.val === "desc" || getRosterListSort.val === "DESC") {
      if (getRosterListSort.name === "lastname") {
        setStudentNameBool(true);
      } else {
        setGradeLevelBool(true);
      }
    } else if (getRosterListSort.val === "asc" || getRosterListSort.val === "ASC") {
      if (getRosterListSort.name === "lastname") {
        setStudentNameBool(false);
      } else {
        setGradeLevelBool(false);
      }
    }

    dispatch(updateClassViewTab("classsummary"));
    setSpinner(false);
    dispatch(updateActivityIndicator(true));
    fetchData();
  }, []);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(updateActivityIndicator(false));
    setPageNo(value);
    getEnrolledStudents(value, rosterSearchRef.current.value, false);
  };

  function sortChange(type: string) {
    dispatch(updateSelectedStudentIds([]));
    setSelected([]);
    localStorage.setItem("ala:addRosterStudent", "false");
    if (type === "Student Name") {
      if (!studentNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "lastname";
    } else if (type === "UserName") {
      if (!userNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "UserName";
    } else {
      if (!gradeLevelBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "gradelevelname";
    }
    getEnrolledStudents(1, rosterSearchRef.current.value, true);
    setPageNo(1);
  }

  const getEnrolledStudents = async (page: number, sText: string, sort: boolean) => {
    try {
      dispatch(updateActivityIndicator(true));

      dispatch(updateRosterListSort({ name: sortBy.current, val: sortOrder.current }));
      const studentsObj = {
        ClassGuid: id,
        Page: page,
        PageSize: getPaginationControllerState[4].paginationCount,
        SortName: sortBy.current,
        SortOrder: sortOrder.current,
        SearchText: sText,
        FilterGradeLevels: "",
        FilterStatus: "",
        SortBy: sortBy.current,
        organizationGuid: getSchoolGUID
      };
      UserService.getstudentdetailslistwithguid(studentsObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data, sort);
          } else {
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
            setEmptyTableText(helpSearch);
            dispatch(updateActivityIndicator(false));
            setSpinner(false);
          }
        }
      );
    } catch (err) {
      //do nothing
      dispatch(updateActivityIndicator(false));
      setSpinner(false);
    }
  };

  const sortByFunc = () => {
    if (sortBy.current === "lastname") {
      setGradeLevelBool(null);
      setuserNameBool(null);
    } else if (sortBy.current === "UserName") {
      setStudentNameBool(null);
      setGradeLevelBool(null);
    } else {
      setStudentNameBool(null);
      setuserNameBool(null);
    }
  };
  const setCheckBoxData = (allData) => {
    let count = 0;
    allData.values.map((values) => {
      if (values.isAdded) {
        count++;
      }
    });
    if (count === allData.values.length) {
      setCheckBoxEnable(false);
    } else {
      setCheckBoxEnable(true);
    }
  };

  async function gotResponse(responsedata: Response, sort: boolean) {
    let allData = JSON.parse(await responsedata.text());
    sortByFunc();
    setSpinner(false);
    setSelected([]);
    dispatch(updateActivityIndicator(false));
    if (allData.size === 0) {
      setEmptyTableText(helpSearch);
      dispatch(updateActivityIndicator(false));
      setSpinner(false);
      setData({
        page: 0,
        size: 0,
        pageSize: 0,
        values: []
      });
    } else {
      setEnrolledstudentCount(allData.values[0].enrolledstudentCount);
      let students = {
        page: allData.page,
        size: allData.size,
        pageSize: allData.pageSize,
        values: []
      };
      let checkBoxIds: number[] = [];

      for (let x in allData.values) {
        let val = allData.values[x];
        val.gradeLevelId = grades[allData.values[x].gradeLevelId - 1];
        //@ts-ignore
        students.values.push(val);
      }
      selectedStudentIds.forEach((student: SelectedStudentProps) => {
        const selecteddata = allData.values.filter((user) => user.userId === student.userId && user.isAdded === false);
        if (selecteddata[0] !== undefined) {
          checkBoxIds = [...checkBoxIds, selecteddata[0].userId];
        }
      });

      if (checkBoxIds.length > 0 && !sort) {
        setSelected(checkBoxIds);
      }
      setCheckBoxData(allData);
      setData(allData);
    }
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === "Enter" && rosterSearchRef.current.value !== "") {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getEnrolledStudents(1, rosterSearchRef.current.value, false);
    }
  }

  function renderSearchButton() {
    return <UxIcon icon={UxIconEnum.SEARCH} size={UxSizeEnum.MEDIUM} className="rostersearchIcon" />;
  }

  function handleSearchButtonClick(): void {
    if (rosterSearchRef.current.value !== "" && rosterSearchRef.current.value !== undefined) {
      searchResultRef.current = true;
      textMessage.current = "Search";
      setPageNo(1);
      getEnrolledStudents(1, rosterSearchRef.current.value, false);
    }
  }

  function renderCancelButton() {
    setTimeout(() => {
      if (
        rosterSearchRef.current.value !== "" &&
        rosterSearchRef.current.value !== undefined &&
        !searchResultRef.current
      ) {
        document.getElementById("rostercloseIcon").style.display = "block";
        document.getElementById("rosterclearIcon").style.display = "none";
      } else if (
        rosterSearchRef.current.value !== "" &&
        rosterSearchRef.current.value !== undefined &&
        searchResultRef.current
      ) {
        document.getElementById("rostercloseIcon").style.display = "none";
        document.getElementById("rosterclearIcon").style.display = "block";
      } else {
        document.getElementById("rostercloseIcon").style.display = "none";
        document.getElementById("rosterclearIcon").style.display = "none";
      }
    });
    return (
      <>
        <div id="rostercloseIcon">
          <img src={TimesIcon} alt="" width="20px" height="20px" />
        </div>
        <div id="rosterclearIcon" className="rostersearchIconText">
          <span> Clear Results </span>
        </div>
      </>
    );
  }

  function clearButtonClick() {
    if (rosterSearchRef.current.value !== "" && !searchResultRef.current) {
      rosterSearchRef.current.value = "";
    } else if (rosterSearchRef.current.value !== "" && searchResultRef.current) {
      rosterSearchRef.current.value = "";
      searchResultRef.current = false;
      setPageNo(1);
      getEnrolledStudents(1, "", false);
    }
  }

  async function save() {
    if (selectedStudentIds.length + enrolledstudentCount <= classLimit) {
      setPageNo(1);
      let selectedlist: string[] = [];
      selectedStudentIds.forEach((i: SelectedStudentProps) => {
        const selecteddata = i.userGuid;
        selectedlist = [...selectedlist, selecteddata];
      });
      // dispatch(updateSelectedStudentIds(selectedlist))
      try {
        const enrollJson = {
          ClassGuid: id,
          StudentUserGuids: selectedlist.join()
        };
        if (selectedlist.length > 0) {
          dispatch(updateActivityIndicator(true));
          await LMSService.enrollStudentsWithGuid(enrollJson).then(
            ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
              if (status === 200) {
                setShowToast({
                  val: true,
                  msg: "Student(s) successfully added to class",
                  color: "#f4473c",
                  type: "success"
                });
                localStorage.setItem("ala:addRosterStudent", "false");
                dispatch(updateSelectedStudentIds([]));
                setSelected([]);
                // fetchData()
                dispatch(updateAddStudentRoaster(true));
                closeaddstudent();
              } else {
                handleError(data as ky.HTTPError);
              }
            }
          );
        } else {
          setShowToast({
            val: true,
            msg: "Please select a student first.",
            color: "#fdba16",
            type: "warning"
          });
        }
      } catch (err) {
        //do nothing
      }
    } else {
      dispatch(updateActivityIndicator(false));
      setShowToast({
        val: true,
        msg: `A single class is limited to ${classLimit} students. Please consider creating two classes.`,
        color: "#fabb1f",
        type: "error"
      });
    }
  }

  async function handleError(error: ky.HTTPError) {
    let errorMsg = JSON.parse(await error.response.text());
    const errMsg = errorMsg.message;
    if (
      errMsg === `Unable to add more than ${classLimit} students to a class. Please remove some students and try again.`
    ) {
      setShowToast({
        val: true,
        msg: `A single class is limited to ${classLimit} students. Please consider creating two classes.`,
        color: "#fabb1f",
        type: "error"
      });
    } else {
      alert("Unauthorized to add students");
    }
    dispatch(updateActivityIndicator(false));
  }
  function renderCancelModal() {
    return (
      <>
        <div className="manipContainer">
          <div className="bulkHeader">
            <text className="bulkImportText" style={{ marginLeft: "0px" }}>
              Exit Screen
            </text>
            <img
              className="confirmCancelImageAddStudent"
              src={TimesIcon}
              alt=""
              onClick={() => {
                setShowCancelModal(false);
              }}
            />
          </div>
          <div className="popuptext">
            <text>
              You’ve selected student(s) to add to your class. If you exit this screen, your selections won’t be saved.
            </text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-10px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBut"
              callback={() => {
                setShowCancelModal(false);
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Exit"}
              className="px-2 py-1 closeBut"
              callback={() => {
                closeaddstudent();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function getselectedid(selectedstudentid: number[], checkedvalue: boolean) {
    let checkstudentlist: SelectedStudentProps[] = selectedStudentIds;
    if (checkedvalue) {
      localStorage.setItem("ala:addRosterStudent", "true");
      selectedstudentid.forEach((i: number) => {
        const checkstudent = selectedStudentIds.filter((elem: SelectedStudentProps) => elem.userId === i);
        if (checkstudent[0] === undefined) {
          const selecteddata = data.values.filter((elem) => elem.userId === i);
          checkstudentlist = [...checkstudentlist, selecteddata[0]];
        }
      });
    } else {
      selectedstudentid.forEach((i: number) => {
        const checkstudent = selectedStudentIds.filter((elem) => elem.userId === i);
        if (checkstudent[0] !== undefined) {
          const filteredcheckstudentlist = checkstudentlist.filter((elem) => elem.userId !== i);
          checkstudentlist = [...filteredcheckstudentlist];
        }
      });
      if (checkstudentlist.length === 0) {
        localStorage.setItem("ala:addRosterStudent", "false");
      }
    }
    dispatch(updateSelectedStudentIds(checkstudentlist));
  }

  function closeaddstudent() {
    localStorage.setItem("ala:addRosterStudent", "false");
    setSelected([]);
    dispatch(updateSelectedStudentIds([]));
    setShowCancelModal(false);
    dispatch(updateClassViewTab("roster"));
    let breadCrumbsData = getBreadCrumbsData;
    breadCrumbsData.crumbs.pop();
    dispatch(updateBreadCrumbs(breadCrumbsData));
    if (breadCrumbsData.crumbs[breadCrumbsData.crumbs.length - 1].path.includes("classsummary")) {
      navigate(`/classview/${id}`);
    } else {
      navigate(breadCrumbsData.crumbs[breadCrumbsData.crumbs.length - 1].path);
    }
  }

  function closecall() {
    if (selectedStudentIds.length > 0) {
      setShowCancelModal(true);
    } else {
      closeaddstudent();
    }
  }
  const fetchData = async () => {
    try {
      let jsonObj = {
        Page: 1,
        PageSize: getPaginationControllerState[4].paginationCount,
        OrgId: 0,
        FilterGradeLevels: "",
        FilterStatus: "",
        SearchText: "",
        SortBy: sortBy.current,
        SortOrder: sortOrder.current,
        organizationGuid: getSchoolGUID,
        ClassGuid: id
      };

      await UserService.getstudentdetailslistwithguid(jsonObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data, false);
          } else {
            setData({
              page: 0,
              size: 0,
              pageSize: 0,
              values: []
            });
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  };

  function removestudent(removedId: number) {
    const selecteddata = selectedStudentIds.filter((elem: SelectedStudentProps) => elem.userId !== removedId);
    if (selecteddata.length === 0) {
      localStorage.setItem("ala:addRosterStudent", "false");
    }
    dispatch(updateSelectedStudentIds([...selecteddata]));
    let getremovedid: number[] = [];
    selecteddata.forEach((obj: SelectedStudentProps) => {
      getremovedid = [...getremovedid, obj.userId];
    });
    const checkid = selected.filter((elem: number) => elem === removedId);
    if (checkid[0] !== null) {
      setSelected(getremovedid);
    }
  }

  const alertclasses = alertStyles();

  const helpSearch = (
    <div className="emptyTextContent">
      <img src={HelpIcon} className="resultIcon" />
      <div className="helpMessage">No Results Found </div>
      <div className="helpcenter">
        Try modifying the search terms or use the{" "}
        <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
          tips
        </a>{" "}
        in this Help Center article.
      </div>
    </div>
  );

  function showToastValue() {
    if (showToast.type === "success") {
      return (
        <Alert
          severity="success"
          style={{ backgroundColor: "#36BBA4", color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    } else if (showToast.type === "error") {
      return (
        <Alert
          severity="error"
          style={{ backgroundColor: showToast.color, color: "#3d4450" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    } else {
      return (
        <Alert
          severity="warning"
          style={{ backgroundColor: showToast.color, color: "#fff" }}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToast.msg}
        </Alert>
      );
    }
  }

  function restoreDefaultCheckState() {
    setSelected([]);
  }

  return (
    <div>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "", color: "", type: "" });
          }}
        >
          {showToastValue()}
        </Snackbar>
      </div>
      <div className="rostermainlayout">
        <div className="rostertable">
          <div className="searchrosterstudent">
            <div>
              <div className="selectStudents">Select Students</div>
              <div className="currentStudentInClass">Current Students in Class: {enrolledstudentCount}</div>
            </div>
            <UxSearchInput
              flexClassName={"addStudentrosterFlexHolder"}
              inputClassName="srInputText"
              placeHolder="Search Student Name, Username, External ID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                rosterSearchRef.current.value = e.target.value;

                if (
                  rosterSearchRef.current.value !== "" &&
                  rosterSearchRef.current.value !== undefined &&
                  !searchResultRef.current
                ) {
                  document.getElementById("rostercloseIcon").style.display = "block";
                  document.getElementById("rosterclearIcon").style.display = "none";
                } else if (
                  rosterSearchRef.current.value !== "" &&
                  rosterSearchRef.current.value !== undefined &&
                  searchResultRef.current
                ) {
                  document.getElementById("rostercloseIcon").style.display = "none";
                  document.getElementById("rosterclearIcon").style.display = "block";
                } else {
                  document.getElementById("rostercloseIcon").style.display = "none";
                  document.getElementById("rosterclearIcon").style.display = "none";
                }
              }}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyPress(e);
              }}
              searchButtonClassName="rosterSearchButton"
              searchButtonContent={renderSearchButton()}
              onSearchClick={() => {
                handleSearchButtonClick();
              }}
              clearButtonClassName="rosterclearButton"
              clearButtonContent={renderCancelButton()}
              onClearClick={() => {
                clearButtonClick();
              }}
              restoreCheckBoxState={() => {
                restoreDefaultCheckState();
              }}
            />
          </div>
          <div ref={elementRef} className="classrostertable">
            {data.size > 0 ? (
              <UxMUITable
                MUITableClassName={"classRosterContentForStudent"}
                columnNames={columnNames}
                rowMapper={rowMapper}
                rowData={(data as IPage).values}
                showColumnCheckbox={checkBoxEnable}
                kebabAction={""}
                showNameKebabMenu={false}
                emptyTableText={emptyTableText}
                checkboxRef={"userId"}
                selected={selected}
                setSelected={setSelected}
                getselectedid={getselectedid}
                isaddstudent={true}
                showStatusText={false}
                mtableHeight={tableHgt}
                showSortList={[true, true, false, true]}
                sortListMapper={{
                  "Student Name": [
                    studentNameBool,
                    setStudentNameBool,
                    (args: string) => {
                      sortChange(args);
                    }
                  ],
                  UserName: [
                    userNameBool,
                    setuserNameBool,
                    (args: string) => {
                      sortChange(args);
                    }
                  ],
                  "Grade Level": [
                    gradeLevelBool,
                    setGradeLevelBool,
                    (args: string) => {
                      sortChange(args);
                    }
                  ]
                }}
              />
            ) : (
              <UxMUITable
                MUITableClassName={"contentForEnrolledStudent"}
                columnNames={columnNames}
                rowMapper={rowMapper}
                rowData={(data as IPage).values}
                showColumnCheckbox={getClassExternalId === "" ? true : false}
                showKebabMenu={!getClassExternalId}
                emptyTableText={emptyTableText}
                checkboxRef={"userGuid"}
                selected={selected}
                setSelected={setSelected}
                mtableHeight={tableHgt + 5}
              />
            )}
            {spinner ? null : (
              <div className="fixedpagination">
                <UxPaginate
                  data={data}
                  page={pageNo}
                  paginationSize={getPaginationControllerState[4].paginationCount}
                  className={classes.root}
                  handleChange={handleChange}
                />
                {data.size > 50 && (
                  <UxPaginationController
                    rowsList={paginationControllers}
                    setRowsCount={getPaginationControllerState[4].paginationCount}
                    callback={callback}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="rosterselectedlist">
          <div className="rightPanel">
            Selected Students
            {selectedStudentIds.length > 0 ? " (" + selectedStudentIds.length + ")" : ""}
          </div>
          {true ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "80%",
                justifyContent: "center"
              }}
            >
              {selectedStudentIds.length > 0 ? (
                <>
                  <div>
                    <label className="selectedlistlable">student name</label>
                    <div className="selectedstudentlistlayout">
                      {selectedStudentIds.map((row) => (
                        <>
                          <div className="selectedstudentlist" style={{ display: "flex" }}>
                            <div className="studentname">
                              {row.lastName}, {row.firstName}
                            </div>
                            <div className="studentremovebutton" onClick={() => removestudent(row.userId)}>
                              Remove
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <div className="noStudentimage">
                      <img src={classrosterimg} />
                    </div>
                    <div className="noStudentText">
                      <label>You haven't selected any students yet.</label>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>table</div>
          )}
        </div>
      </div>
      <div className="classrosterfooter">
        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={"Cancel"}
          priority={UxShadesEnum.LIGHT}
          className="px-2 py-1 footercancel"
          callback={() => {
            closecall();
          }}
        />
        <UxAction
          key={0}
          size="sm"
          enabled={true}
          text={t("Add to Class")}
          className="px-2 py-1 footeraddstudent"
          callback={() => {
            selectedStudentIds.length === 0
              ? setShowToast({
                  val: true,
                  msg: "Please select a student first.",
                  color: "#f4473c",
                  type: "warning"
                })
              : save();
          }}
        />
      </div>
      <div>
        <BulkImportsModal showBulkModal={showCancelModal} children={renderCancelModal()} width="780px" height="225px" />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(AddStudentRoster);
