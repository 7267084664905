// @ts-nocheck
import Settings from "../helpers/constants";

export default function HasSession() {
  if (
    sessionStorage.getItem(
      `oidc.user:${Settings().authority}:${
        localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
      }`
    )
  ) {
    return true;
  } else {
    return false;
  }
}
