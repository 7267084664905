import React, { useEffect } from "react";
import "./StudentSubjects.scss";
import { math68png, reading68png } from "@wne-spa/ux-component-library";
import { updatePageTitle } from "../../../Redux/Action";
import { connect } from "react-redux";
import ReduxProps from "../../../Redux/Redux.props";
import { StudentSubjectsProps } from "./StudentSubjects.props";

function mapStateToProps(state: ReduxProps) {
  return {
    isSessionModal: state.isSessionModal
  };
}
interface Props extends ReduxProps, StudentSubjectsProps {}

function StudentSubjectsFor912(props: Props) {
  const { subjectName, goClick, dispatch, isSessionModal } = props;
  useEffect(() => {
    dispatch(updatePageTitle("Student Dashboard | Imagine MyPath "));
    return () => {
      //do nothing
    };
  });
  let arialabel = `Go to mypath ${subjectName} page`;
  return (
    <>
      <div className="box">
        <div
          className={`subjectMath912 ${subjectName === "Math" ? "mathbackground912" : "readingbackground912"}`}
          onClick={() => {
            goClick(subjectName);
          }}
          tabIndex={isSessionModal ? -1 : 0}
          role="button"
          aria-label={arialabel}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              goClick(subjectName);
            } else {
              //do nothing
            }
          }}
        >
          <div className={`subjectborder912 ${subjectName === "Math" ? "mathborder912" : "readingborder912"}`}>
            <img
              className="subjectsize"
              style={{ width: "30px", height: "30px" }}
              src={subjectName === "Math" ? math68png : reading68png}
              alt={"subjectgrade912"}
            ></img>
          </div>
          {props.showIMPathway && subjectName === "Math" ? (
            <div className="math912Text" aria-hidden="true">
              BUILDER
            </div>
          ) : (
            <div className="math912Text" aria-hidden="true">
              {subjectName}{" "}
            </div>
          )}
          <div className={`go912button ${subjectName === "Math" ? "math912btn" : "reading912btn"}`}>
            <span className="letsGoText" aria-hidden="true">
              Let's Go!
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(StudentSubjectsFor912);
