import React from "react";
import { UxAction, UxShadesEnum, TimesIcon } from "@wne-spa/ux-component-library";
import { Modal } from "react-bootstrap";
import "./index.scss";

interface ILoginUrlProps {
  callback: () => void;
  close: () => void;
  message: string;
  show: boolean;
  isAddStudent?: boolean;
}

export default function GetLoginUrl({ close, callback, message, show, isAddStudent }: ILoginUrlProps): JSX.Element {
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        contentClassName="modal-content"
        style={isAddStudent ? { marginTop: "189px" } : { marginTop: "50px", marginLeft: "60px" }}
      >
        <div>
          <div className="loginUrlHeader">
            {isAddStudent ? (
              <text className="loginUrlText" style={{ marginLeft: "0px" }}>
                Exit Screen
              </text>
            ) : (
              <text className="loginUrlText" style={{ marginLeft: "0px" }}>
                Site Code
              </text>
            )}
            <img
              className="cancelImage"
              src={TimesIcon}
              alt=""
              onClick={() => {
                close();
              }}
            />
          </div>

          <div>
            {isAddStudent ? (
              <div className="popuptext addStudentText">
                <label>{message}</label>
              </div>
            ) : (
              <>
                <hr></hr>
                <label className="message w-100 p-2">{message}</label>
                <hr></hr>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: " flex-end",
              paddingBottom: "10px",
              marginRight: "-10px"
            }}
          >
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={"Cancel"}
              priority={UxShadesEnum.LIGHT}
              className="px-2 py-1 closeBtn"
              callback={() => {
                close();
              }}
            />
            <UxAction
              key={0}
              size="sm"
              enabled={true}
              text={isAddStudent ? "Exit" : "Copy Site Code"}
              className="px-2 py-1 closeBtn w-auto"
              callback={() => {
                callback();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
