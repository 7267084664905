import GetToken from "../../../authService/GetToken";
import { useEffect, useState } from "react";

export default function useCatTestStatus() {
  const [subStatusAndOrder, setSubStatusAndOrder] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchWithRetries = async (retryCount = 0) => {
      const maxRetries = 2;
      try {
        const rawResponse = await fetch(`${window.APP_ENV.catTestStatusUrl}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${GetToken()}`
          }
        });
        const content = await rawResponse.json();
        if (isMounted) {
          setSubStatusAndOrder(content.results);
        }
      } catch (error) {
        if (retryCount < maxRetries && isMounted) {
          return fetchWithRetries(retryCount + 1);
        }
        if (isMounted) {
          setSubStatusAndOrder([]);
        }
      }
    };

    fetchWithRetries();

    return () => {
      isMounted = false;
    };
  }, []);

  return { subStatusAndOrder };
}
