const orgGuidList = [
  "00000000-0000-0000-0000-000000000003",
  "59ca317d-4f40-4fcc-8565-a3ab00f2928c",
  "00000000-0000-0000-0000-000000000004",
  "cb12bcf3-2063-41c4-b9bc-a3ff015d1381",
  "126ec584-b5de-4cfd-9713-afa2010612af",
  "d82eff81-7881-4cdc-a3f2-afa3000e7c8a",
  "1e7860ad-2ea4-4f12-be24-a3ff015ddf59",
  "a1beaecb-d623-44f0-ae91-afa300077268",
  "faf737f8-d15c-48cf-8da5-a3ff015de7f2",
  "4fcba17b-f2ce-44d2-9c29-afa3000799ad",
  "504f6384-0bed-498c-b1f2-a3ff015df4df",
  "31c00685-fe2e-4e4e-b69b-afa30007bde0",
  "077f72d5-bd61-4f9f-abd3-aa5d0132c050",
  "6cb64dad-23c7-438b-b287-a3ff015e029a",
  "4983dd19-1fdd-4cb3-a372-afa30007e07d",
  "b5e239d1-f21c-44be-9d97-a3ff015e0c2e",
  "11fe3125-2ac8-4cc3-9ac8-afa300080232",
  "ca6c9743-7f33-43cd-9f3b-a3ff015ed10b",
  "28be8f46-ab2d-48f0-a18e-afa300085a92",
  "de4a459f-8c5e-4550-9327-a3ff015ef595",
  "e4e8d65d-5548-4617-bee9-afa3000879e4",
  "8bc460f7-756a-4c88-a563-a3ff015f49d1",
  "b0bd695a-8800-43e5-a451-afa3000a0bb1",
  "8a162593-c390-4ece-8186-a3ff015f54c9",
  "e3b6d17d-8f10-42aa-be65-afa3000a2d76",
  "e1f80d40-fd0c-4411-8384-a3ff015f5edb",
  "74372a2e-cd40-4de8-b9ac-afa3000a5217",
  "1a7da2ba-7bc1-49e6-91b4-a3ff015f707a",
  "2744dd13-cc4c-47e3-93d5-afa3000a6fc9",
  "ca0fb815-6f6c-43fd-985a-a3ff015f7849",
  "881c6d62-bba5-407e-be73-afa3000aa55b",
  "5c4b7c0a-3fa8-4ebf-b906-a3ff015f94b4",
  "f294564b-259c-4e14-95db-afa3000ac480",
  "781fbba9-a2dd-4387-9946-a3ff015fa0bb",
  "83c75cfe-e215-41e3-abd6-afa3000ae918",
  "9c99d726-c0cb-4bee-827f-a3ff015faaf1",
  "6c696252-163c-4565-beeb-afa3000b0953",
  "0f9585f5-9041-46c9-8f7a-a3ff015fb4d3",
  "ff9e7855-f33e-4410-a349-afa3000b2929",
  "471aee75-264a-4cee-b070-a3ff015fc1b3",
  "eceb0c18-29bd-4437-8611-afa3000b6126",
  "b97dffb8-d20c-4a2a-9128-a3ff015fcafd",
  "6e9335f6-3381-4f5b-a9f1-afa3000b8507",
  "f01a040f-b63a-4dec-a556-a3ff015fd636",
  "394040a1-5fdd-48ae-aa4f-afa3000ba8ac",
  "e72010d9-491c-47d1-b813-a3ff015fdff3",
  "df8188f2-9887-443a-8745-afa3000bc707",
  "79b77d74-fa5c-4fdb-af25-a3ff015fe89a",
  "3089ad80-1192-4a6a-9290-afa3000be83d",
  "43de82d7-c37c-4b4f-94d3-a3ff015ff42f",
  "fef102d1-a0b2-4438-b1db-afa3000c1a58",
  "6c9f9477-6920-4ce3-96e4-a3ff015ffcee",
  "9f8049d3-cab8-4b0a-aa20-afa3000c3722",
  "4c033215-9109-412f-b38e-a5360167e0e3",
  "d33b30a4-977b-493d-a4a9-afa201067646",
  "411d9791-572a-4736-b4c6-a3ff01600b7a",
  "e620b8c6-7738-4ef8-9870-afa3000c5710",
  "62efc5f3-c212-41e7-b77e-a5cf01675d56",
  "f6ca2f67-fcf2-4756-96e4-a3ff01601bf8",
  "e1400e98-41ac-4593-94e2-afa3000c7acd",
  "a11f2008-4f80-4b6a-8817-a3ff01602a4f",
  "d3110fa9-24d2-4b23-9089-afa3000c9c52",
  "b541067e-fa93-4a6e-8170-a3ff01603632",
  "41f8f2ab-1b4d-46ac-840c-afa3000cc5c1",
  "dd2e6b2b-ef92-45ef-bfc5-a3ff016046ef",
  "7e67aedf-c304-49f6-956a-afa3000ce821",
  "4c6c3e94-319f-4dcd-ad80-a3ff01604fc6",
  "48ce5ec1-04b8-4d82-8610-afa3000d07be",
  "67f45a32-cce1-49d1-8c28-afb0017bd3fe",
  "d7589721-6c07-483b-8da7-afb1011af212",
  "7516483a-f373-47df-87fb-a3ff01605733",
  "8ff83d64-fd1a-42b3-953c-afa3000d270f",
  "70c3b230-a75f-4e83-9849-afb1011af9d0",
  "c5974950-28a1-4608-898b-a3ff01605fd2",
  "f4a5eab0-9cd9-45c0-a221-afa3000d67d4",
  "9332eead-a3ec-4b9e-94d5-afb1011b02eb",
  "60bbb338-9779-4193-9c8d-a3ff01606e8c",
  "48f0dc81-1e1c-44bb-8707-afa3000da03d",
  "096aaf2d-cfd1-4cdd-9222-afb1011b0e11",
  "abdcaf64-4e26-45c2-bb53-a3ff01607aaa",
  "80eaa499-e06a-4763-934a-afa3000de354",
  "a78e4e3e-cee9-482b-8c51-afb1011b22df",
  "fd2b250d-01be-4245-a87c-a3ff0160862d",
  "4bfc33c6-7284-416c-bfa2-afa3000e090d",
  "45e27327-0944-4261-a42a-afb1011b30bb",
  "55ce463b-cef9-40a4-abdc-a3ff016090f4",
  "129ac4d0-de1f-4a2f-8eac-afa3000e2c09",
  "2e62d1b5-c9d6-48ed-9ba0-afb1011b3c62",
  "a81c7289-8c9c-4447-84fd-a3ff01609b50",
  "31fa39cd-3afe-4d68-bf53-afa3000e5756",
  "cf0ef347-9353-4e4e-bb3d-a3ff015c5f75",
  "ffcfe020-32fe-481e-923b-afa201080ac5",
  "b5a84b96-298a-4b60-b69a-afb1010c9e58",
  "b2fc079e-a5a6-4808-a320-a3ff015c6ccb",
  "2c58f3ac-960b-4361-8268-afa300046f52",
  "c1f6635b-e76c-47e3-af86-afb101166237",
  "4103a687-2dbe-47a1-8665-a3ff015c772c",
  "11ec4c74-7f6e-46c8-8850-afa30004b50f",
  "1681f796-87d1-416d-a8ef-afb1011673c8",
  "c4197114-6fa7-47d1-b4f9-a3ff015c82a3",
  "7543fbc9-d443-43d5-b57e-afa30004d8e8",
  "4e5ed20e-d8b0-468e-97c4-afb101169000",
  "7efd7e00-d467-431c-a3b8-a3ff015c8db2",
  "7ed74dfa-9acb-40c4-9ee7-afb100032a47",
  "7b5cb5b1-523c-4e06-b0de-afa3000503d6",
  "86b058d2-4ad8-47d5-9de2-a3ff015c9955",
  "84ab6eaa-e369-4635-b257-afa300058339",
  "857bd996-f29a-4221-a457-afb10116f79d",
  "db4df5b6-2d2b-4925-a488-a3ff015cb130",
  "bc3b16db-cdca-45c3-bff2-afa30005aeff",
  "3aacba2b-b9b3-4a0b-b911-afb10117714f",
  "e89adcae-6ab1-4925-a817-a3ff015cbd5f",
  "3633998b-2f22-42e4-ae1f-afa30005d64d",
  "f73eaf5d-a12e-4ee6-8bd8-afb1011789ec",
  "ca89d863-db4a-4b80-88ab-a3ff0162dc88",
  "1aaecd83-5c9d-47c6-afe9-afa300064d4e",
  "0deb04b9-f0f6-4db1-8919-afb101179b31",
  "101d7052-2b1d-4656-bba4-a3ff015cc878",
  "58f9d6af-f9d7-449e-8b8b-afa300067d2b",
  "c1888ba6-7dc4-446d-825a-afb10117adac",
  "be7e8d6b-a4ec-4ca5-ab5f-a3ff015cd4fd",
  "a3cb26ee-2566-4bc6-83b6-afa30006a619",
  "7794a01d-5464-4ef0-a824-afb10117bcc4",
  "e49ce577-fff7-431f-b708-a3ff015cde0b",
  "631f774a-bafd-491d-ac2d-afa30006cbe9",
  "a281d536-caf7-45de-b81c-afb101188bd3",
  "5f7b0660-8f8c-43ec-b857-a4fe010dd045",
  "e6c8aed7-8925-44d9-ac1f-a3ff015ce558",
  "0c428d15-a260-415f-9d13-afa30006edce",
  "846fa898-5aaa-43ec-8989-afb101189c84",
  "76acfde9-76e9-4c7b-9547-a3ff015cee36",
  "e3feb4bd-a685-418e-8467-afa300070fe6",
  "618f4085-3efc-402e-a175-afb10118d080",
  "96e6f917-5ae3-4911-a940-afa3000746dc",
  "07039a88-c06b-403c-ae2d-afb101195ad7",
  "03f11d73-e16a-40f2-9daa-afb101199812",
  "489162b2-57e5-4a89-9c01-afb101196987",
  "60633c48-f208-42e0-ba34-afb1011978fa",
  "84d006a4-b66b-4679-8b25-afb101198bd1",
  "6a7f25ea-5b8a-42c7-9d5f-ab99012ee0b8",
  "91831e36-1424-4555-8779-a592013a0363",
  "524ad33a-4d73-4d82-a93e-afb10119abd4",
  "74703690-82fb-4598-bee6-afb10119bdad",
  "857353c1-8138-40ac-9de5-afb10119cc39",
  "1a1fbc26-dcd2-44f9-a9a8-afb10119db14",
  "15341c18-452c-484f-9e4d-a509011fe9b4",
  "28d80116-b5d9-4695-94f3-afb10119e5fb",
  "ed5ce06f-9e58-436f-a66b-afb10119f334",
  "c297dcef-0648-402e-befd-afb10119febf",
  "22b19b0d-bf86-47d6-a394-afb1011a0a36",
  "5547abdc-ac1b-4ff7-981f-afb1011a14db",
  "15d2f086-3a9a-4960-99b5-afb1011a2420",
  "8468e99f-23b5-43a0-9961-afb1011a2de0",
  "5080dbd6-ac7f-4864-9a78-afb1011a38b6",
  "e143c4c0-ec25-4be6-9e0e-afb1011a4244",
  "20ad00ca-0e60-4359-b041-afb1011a4da4",
  "c85afb7e-e3fd-4b18-a833-afb1011a55de",
  "280f6e11-f62f-4fa0-958a-aa0700ef2326",
  "ec0d3b4c-822f-4e77-a019-afb1011a6528",
  "82406221-384f-41ba-b2cc-afb1011a708e",
  "45fc0822-b8d7-41e4-aac5-afb1011a7a27",
  "e687d3c9-5df3-4bdd-bd48-afb1011a8396",
  "0b5d6d4f-caa6-41c7-a52b-afb1011a9591",
  "328e79de-5630-4ab4-9148-afb1011aa9f7",
  "68531610-a73a-42a8-b4b2-ac3f010fb0b2",
  "b4d510a3-3314-4c64-820d-afb1011ac4b8",
  "361feefc-6819-42ec-9867-afb1011acf65",
  "7844c427-bd08-4807-bb71-afb1011ad8bd",
  "5e412b72-3b5f-4fdb-853b-afb1011ae958",
  "d37d0524-4e95-4a19-ad98-a59200f0d8f0",
  "7fdba4ac-6e74-4ffd-af9b-ac01014f01d3",
  "6232b29c-0b57-4f4b-a6b6-a6430147d9aa",
  "c4e922f9-7324-4875-8b2b-a72200fa717e",
  "00000000-0000-0000-0000-000000000000"
];

export const hiddenTabsForOrgGuidList = ["settings", "reports", "Assessments"];

export const getIsOrgGuidPresent = (orgGuid: string): boolean => {
  return orgGuidList.includes(orgGuid);
};
