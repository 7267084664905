import { useEffect, useState } from "react";
import { AdminService } from "../../services/helpers/api.services";

const useOrganizations = (ids: string) => {
  const [allSchools, setAllSchools] = useState<any[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const request = { guid: ids.split("_").toString() };
      const { status, data } = await AdminService.getOrganizationDetailsWithGuid(request);

      if (status === 200) {
        const dataString = await data.text();
        const details: any = JSON.parse(dataString);
        const schools = details.map((x: any) => x);
        setAllSchools(schools);
      }
    };

    fetchData();
  }, [ids]);

  return allSchools;
};

export default useOrganizations;
