// eslint-disable-next-line prettier/prettier
import {
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import WarningIcon from "../../assets/icons/icon-warning-light.svg";
import KebabIcon from "../../assets/icons/icon_kebab.svg";
import Icon from "../../components/icon/index";
import LibraryEnum from "../../shared/enums/icon-library.enum";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
import { CustomKebabMenuDataProps, TableRowDataProps } from "./mui-table.props";
import "./mui-table.scss";

interface IMUITable {
  columnNames: string[];
  rowMapper: Array<string | Array<string>>;
  rowData: Array<TableRowDataProps>;
  showColumnCheckbox: boolean;
  showKebabMenu?: boolean;
  MUITableClassName: string;
  emptyTableText?: string;
  checkboxRef: string;
  id?: number;
  status?: any;
  kebabAction?: (x, y) => void;
  handleStatus?: (x: number, y: boolean, z: number) => void;
  viewChild?: (x) => void;
  selected: Array<string | number>;
  setSelected: React.Dispatch<React.SetStateAction<Array<string | number>>>;
  showNWEAFlag?: boolean;
  showNameKebabMenu?: boolean;
  showStatusText?: boolean;
  childDepth?: string;
  customKebabMenu?: boolean;
  showCustomKebabMenu?: boolean;
  showCustomKebabMenuText?: boolean;
  customKebabMenuData?: CustomKebabMenuDataProps[];
  KebabMenuOption?: Array<string>;
  showSortList?: boolean[];
  sortListMapper?: Object;
  showToolTip?: boolean;
  mtableHeight?: number;
  showCustomStatusText?: boolean;
  customStatusMapper?: string;
  errorsCallback?: (args) => void;
  customErrorOptionCallback?: (args) => void;
  LicenseTableFlag?: boolean;
  customImpersonationKebabOption?: (args) => void;
  showImpersonationKebabMenu?: boolean;
  showHelpIconFlag?: boolean;
  isaddstudent?: boolean;
  getselectedid?: (arg1, arg2) => void;
  isStudentClasses?: boolean;
  isTeacherRole?: boolean;
  hideCheckboxOverValue?: string;
  disableKebabMenuOverValue?: string;
  removeEditabilityFlag?: boolean;
}

export default function UxMUITable(props: IMUITable) {
  const {
    columnNames,
    rowMapper,
    rowData,
    showColumnCheckbox,
    showKebabMenu,
    MUITableClassName,
    emptyTableText,
    checkboxRef,
    id,
    status,
    handleStatus,
    viewChild,
    selected,
    setSelected,
    showNameKebabMenu,
    showStatusText,
    mtableHeight,
    customKebabMenu,
    showCustomKebabMenu,
    showCustomKebabMenuText,
    showToolTip,
    customKebabMenuData,
    KebabMenuOption,
    kebabAction,
    showNWEAFlag,
    showSortList,
    sortListMapper,
    showCustomStatusText,
    customStatusMapper,
    errorsCallback,
    customErrorOptionCallback,
    customImpersonationKebabOption,
    LicenseTableFlag,
    showImpersonationKebabMenu,
    showHelpIconFlag,
    isaddstudent,
    getselectedid,
    isStudentClasses,
    isTeacherRole,
    hideCheckboxOverValue
  } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))(Tooltip);

  const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    container: {
      maxHeight: `${mtableHeight}px !important`,
      marginTop: "0px !important"
    },
    tableCell: {
      minWidth: "20px !important",
      maxWidth: "350px !important"
    }
  });
  const classes = useStyles();
  function isSelected(name: string) {
    return selected.indexOf(name) !== -1;
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = showNWEAFlag
        ? rowData
            .filter(
              (item: TableRowDataProps) =>
                item["subject"].toLowerCase() != "sel" &&
                item["subject"].toLowerCase() != "science" &&
                item["subject"].toLowerCase() != "purpose"
            )
            .map((n: TableRowDataProps) => n[checkboxRef])
        : rowData
            .filter((item) => (hideCheckboxOverValue ? item.isSynced === false : item))
            .map((n: TableRowDataProps) => n[checkboxRef]);
      if (isaddstudent) {
        const newSelecteds = rowData
          .filter(
            (item: TableRowDataProps) =>
              item["isAdded"] === false && item["gradeLevelName"] !== "OTHER" && item["gradeLevelName"] !== "PK"
          )
          .map((n: TableRowDataProps) => n[checkboxRef]);
        getselectedid(newSelecteds, true);
      }
      setSelected(newSelecteds);
      return;
    }
    uncheckedAllData();
    setSelected([]);
    if (!event.target.checked) {
      if (isaddstudent) {
        const newSelecteds = rowData
          .filter((item: TableRowDataProps) => item["isAdded"] === false)
          .map((n: TableRowDataProps) => n[checkboxRef]);
        getselectedid(newSelecteds, false);
      }
      // getselectedid([],false)
    }
  }

  const uncheckedAllData = () => {
    if (LicenseTableFlag || isaddstudent) {
      const newIds = rowData.map((n: TableRowDataProps) => n[checkboxRef]);
      localStorage.setItem("ala:unCheckAll", `${newIds}`);
    } else {
      localStorage.setItem("ala:unCheckAll", `${""}`);
    }

    if (showNWEAFlag) {
      const newIds = rowData
        .filter(
          (item: TableRowDataProps) =>
            item["subject"].toLowerCase() != "sel" && item["subject"].toLowerCase() != "purpose"
        )
        .map((n: TableRowDataProps) => n[checkboxRef]);
      localStorage.setItem("stu:unCheckAll", `${newIds}`);
    } else {
      localStorage.setItem("stu:unCheckAll", `${""}`);
    }
  };

  function renderStudentPlacementRetestToolTip() {
    return (
      <text>
        This student is currently in the <br />
        process of retesting.
      </text>
    );
  }

  const handleClick = (name: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected as string[], name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat((selected as string[]).slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat((selected as string[]).slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        (selected as string[]).slice(0, selectedIndex),
        (selected as string[]).slice(selectedIndex + 1)
      );
    }

    uncheckedData(selectedIndex, name);
    if (isaddstudent) {
      if ((e.target as HTMLInputElement).checked || (e.target as HTMLInputElement).checked === undefined) {
        getselectedid(newSelected, true);
      } else {
        getselectedid([name], false);
      }
    }
    setSelected(newSelected);
  };

  const uncheckedData = (selectedIndex: number, name: string) => {
    if (LicenseTableFlag && selectedIndex !== -1) {
      localStorage.setItem("ala:unchecked", `${name}`);
    } else {
      localStorage.setItem("ala:unchecked", `${""}`);
    }

    if (showNWEAFlag && selectedIndex !== -1) {
      localStorage.setItem("stu:unchecked", `${name}`);
    } else {
      localStorage.setItem("stu:unchecked", `${""}`);
    }
  };

  const columnCheckBoxStyle = () => {
    if (showNWEAFlag && !showColumnCheckbox) {
      return { marginTop: "10px", paddingLeft: "28px" };
    } else {
      return { marginTop: "10px" };
    }
  };
  const showSortListMapperFunc = (data: string, index: number) => {
    if (showSortList[index]) {
      if (sortListMapper && sortListMapper[data][0] === null) {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](true);
              sortListMapper[data][2](data);
            }}
            className="fa fa-sort sortIconDefault"
          ></i>
        );
      } else if (sortListMapper && sortListMapper[data][0]) {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](!sortListMapper[data][0]);
              sortListMapper[data][2](data);
            }}
            className="fa  fa-sort-asc sortIconUp"
          ></i>
        );
      } else {
        return (
          <i
            onClick={() => {
              sortListMapper[data][1](!sortListMapper[data][0]);
              sortListMapper[data][2](data);
            }}
            className="fa fa-sort-desc sortIconDown"
          />
        );
      }
    } else {
      return null;
    }
  };
  const customDataFunc = (customData: CustomKebabMenuDataProps, row: TableRowDataProps) => {
    if (customData.key === "placement") {
      if (row.placementGrades.studentPlacementId === 0) {
        return customData.data.undefined;
      } else {
        return customData.data.hasValue;
      }
    } else if (customData.key === "deactivate") {
      if (row.placementGrades.isILPActivated === true) {
        return customData.data.hasValue;
      } else {
        return customData.data.undefined;
      }
    } else {
      return null;
    }
  };
  const displayWarningToolTip = (row: TableRowDataProps) => {
    if (
      (row[rowMapper[0] as string] === "" ||
        row[rowMapper[0] as string] === null ||
        row[rowMapper[0] as string] === undefined) &&
      (row.renaissanceID === "" || row.renaissanceID === null || row.renaissanceID === undefined) &&
      (row.nweaid === "" || row.nweaid === null || row.nweaid === undefined)
    ) {
      return (
        <LightTooltip
          placement="bottom-start"
          title="No assessment ID is saved for this student. Click the ellipsis menu and select Add Assessment ID to enter the student’s assessment ID."
          enterDelay={500}
          leaveDelay={200}
        >
          <img alt="warning  icon" src={WarningIcon} className="warningImg" />
        </LightTooltip>
      );
    } else {
      return <label className="retestIconhidden"></label>;
    }
  };
  const displayCheckBox = (row: TableRowDataProps, labelId: string, isItemSelected: boolean, excluded: boolean) => {
    if (!excluded) {
      return (
        <Checkbox
          disabled={excluded}
          checked={excluded ? false : isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
          onClick={(e) => {
            handleClick(row[checkboxRef], e);
          }}
          size="small"
        />
      );
    } else {
      return <div style={{ marginLeft: "40px" }} data-testid="sss"></div>;
    }
  };
  const customStatusRowMapper = (row: TableRowDataProps, index: number, labelId: string) => {
    if (rowMapper[index] === customStatusMapper) {
      if (row[rowMapper[index] as string] > 0) {
        return (
          <label
            onClick={() => {
              errorsCallback(row);
            }}
            className="rowText customTableCell"
            style={{ marginTop: "15px" }}
            id={labelId}
          >
            {row[rowMapper[index] as string]}
          </label>
        );
      } else {
        return (
          <label className="rowText ellipsis noCursor" style={{ marginTop: "15px" }} id={labelId}>
            {row[rowMapper[index] as string]}
          </label>
        );
      }
    } else {
      return (
        <label
          className={
            // eslint-disable-next-line no-constant-condition
            typeof row.placementGrades !== "undefined" &&
            row.placementGrades.isReset === true &&
            row.placementGrades.source === "MyPath" &&
            (rowMapper[index] === "placementgrade" ||
              rowMapper[index] === "source" ||
              rowMapper[index] === "placementDate")
              ? "greyRowText ellipsis noCursor"
              : "rowText ellipsis noCursor"
          }
          style={{ marginTop: "15px" }}
          id={labelId}
        >
          {row[rowMapper[index] as string]}
        </label>
      );
    }
  };
  const displayMenuItemsOnRowSubject = (customData: CustomKebabMenuDataProps, row: TableRowDataProps) => {
    if (
      ((row.subject === "SEL" || row.subject === "Purpose") && customData.key !== "placement") ||
      (row.subject !== "SEL" && row.subject !== "Purpose" && row.subject !== "Science")
    ) {
      return (
        <MenuItem
          className="tableStatusMenu"
          onClick={() => {
            customData.callback(row);
          }}
        >
          {customDataFunc(customData, row)}
        </MenuItem>
      );
    } else if (row.subject === "Science" && customData.key === "deactivate") {
      return (
        <MenuItem
          className="tableStatusMenu"
          onClick={(_event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            customData.callback(row);
          }}
        >
          {row.placementGrades.isILPActivated === true ? customData.data.hasValue : customData.data.undefined}
        </MenuItem>
      );
    } else {
      return null;
    }
  };
  const customKebabMenuDataFunc = (row: TableRowDataProps) => {
    if (row.placementGrades.studentPlacementId > 0 && row.placementGrades.isILPActivated === false) {
      return customKebabMenuData.map((customData: CustomKebabMenuDataProps) => {
        return (
          customData.key === "deactivate" && (
            <MenuItem
              className="tableStatusMenu"
              onClick={() => {
                customData.callback(row);
              }}
            >
              {row.placementGrades.isILPActivated === true ? customData.data.hasValue : customData.data.undefined}
            </MenuItem>
          )
        );
      });
    } else {
      return customKebabMenuData.map((customData: CustomKebabMenuDataProps) => {
        return (
          customData.key === "placement" &&
          row.subject !== "Science" && (
            <MenuItem
              className="tableStatusMenu"
              onClick={() => {
                customData.callback(row);
              }}
            >
              {row.placementGrades.studentPlacementId === 0 ? customData.data.undefined : customData.data.hasValue}
            </MenuItem>
          )
        );
      });
    }
  };
  const customStatusMapperFunc = (row: TableRowDataProps) => {
    if (row[customStatusMapper] === 0) {
      if (row.statusId === 5) {
        return "Failed";
      } else {
        return "Completed";
      }
    } else {
      return "Completed w/Errors";
    }
  };

  const showImpersonationKebabMenuFunc = (row: TableRowDataProps, index: number, getIndex: number) => {
    if (showImpersonationKebabMenu) {
      return (
        <div className="dropper">
          <Select
            value={""}
            onMouseOver={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
            }}
            onMouseOut={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0)");
            }}
            onBlur={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0)");
            }}
            IconComponent={() => (
              <img
                className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                src={KebabIcon}
                alt="kebab icon"
                width="30px"
                height="30px"
              />
            )}
            className="noUnderLine"
            data-testid={`table-select-showimpersonate-${getIndex}`}
          >
            {KebabMenuOption.length > 0 && (
              <MenuItem
                className="tableStatusMenu"
                onClick={() =>
                  customImpersonationKebabOption({
                    data: row,
                    type: "Impersonation"
                  })
                }
              >
                {KebabMenuOption[0]}
              </MenuItem>
            )}
          </Select>
        </div>
      );
    } else {
      return (
        <div className="dropper">
          <Select
            value={""}
            onChange={
              kebabAction
                ? () => {
                    kebabAction(row, getIndex);
                  }
                : () => {
                    //do nothing
                  }
            }
            onMouseOver={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
            }}
            onMouseOut={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0)");
            }}
            onBlur={() => {
              document
                .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                .setAttribute("style", "background: rgba(71, 165, 201, 0)");
            }}
            IconComponent={() => (
              <img
                className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                src={KebabIcon}
                alt="kebab icon"
                width="30px"
                height="30px"
              />
            )}
            className="noUnderLine"
          >
            {KebabMenuOption.length > 0 && (
              <MenuItem
                className="tableStatusMenu"
                onClick={() =>
                  customErrorOptionCallback({
                    data: row,
                    type: "Impersonation"
                  })
                }
              >
                {KebabMenuOption[0]}
              </MenuItem>
            )}
          </Select>
        </div>
      );
    }
  };
  const showCustomStatusTextFunc = (row: TableRowDataProps, getIndex: number) => {
    if (showCustomStatusText) {
      if (
        row[customStatusMapper] === null ? null : row[customStatusMapper] === 0 //@ts-ignore
      ) {
        return (
          <div className="dropper">
            <Select
              value={""}
              onChange={() => {
                //do nothing
              }}
              onMouseOver={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
              }}
              onMouseOut={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0)");
              }}
              onBlur={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0)");
              }}
              IconComponent={() => (
                <img
                  className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                  src={KebabIcon}
                  alt="kebab icon"
                  width="30px"
                  height="30px"
                />
              )}
              className="noUnderLine"
            >
              {row.successCount !== 0 ? (
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Completed File"
                    })
                  }
                >
                  Download Completed File
                </MenuItem>
              ) : null}

              <MenuItem
                className="tableStatusMenu"
                onClick={() =>
                  customErrorOptionCallback({
                    data: row,
                    type: "File Details"
                  })
                }
              >
                File Details
              </MenuItem>
            </Select>
          </div>
        );
      } else {
        return (
          <div className="dropper">
            <Select
              value={""}
              onChange={() => {
                //do nothing
              }}
              onMouseOver={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
              }}
              onMouseOut={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0)");
              }}
              onBlur={() => {
                document
                  .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                  .setAttribute("style", "background: rgba(71, 165, 201, 0)");
              }}
              IconComponent={() => (
                <img
                  className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                  src={KebabIcon}
                  alt="kebab icon"
                  width="30px"
                  height="30px"
                />
              )}
              className="noUnderLine"
            >
              <MenuItem
                className="tableStatusMenu"
                onClick={() =>
                  customErrorOptionCallback({
                    data: row,
                    type: "Download & Fix Errors"
                  })
                }
              >
                Download & Fix Errors
              </MenuItem>
              {row.successCount !== 0 ? (
                <MenuItem
                  className="tableStatusMenu"
                  onClick={() =>
                    customErrorOptionCallback({
                      data: row,
                      type: "Download Completed File"
                    })
                  }
                >
                  Download Completed File
                </MenuItem>
              ) : null}

              <MenuItem
                className="tableStatusMenu"
                onClick={() =>
                  customErrorOptionCallback({
                    data: row,
                    type: "File Details"
                  })
                }
              >
                File Details
              </MenuItem>
            </Select>
          </div>
        );
      }
    } else {
      return null;
    }
  };
  const muiTableCellData = (
    row: TableRowDataProps,
    index: number,
    getIndex: number,
    labelId: string,
    isItemSelected: boolean
  ) => {
    if (index === 0 && showToolTip) {
      return (
        <TableCell className="rowText" component="th" scope="row">
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            {showColumnCheckbox ? (
              <Checkbox
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
                onClick={(e) => {
                  handleClick(row[checkboxRef], e);
                }}
                data-testid={`tablecell-showTooltip-${getIndex}`}
                size="small"
              />
            ) : null}
            <LightTooltip placement="bottom-start" title={row[rowMapper[index][1]]} enterDelay={10} leaveDelay={10}>
              <label
                data-testid={`label-showTooltip-${getIndex}`}
                onClick={
                  viewChild
                    ? () => {
                        viewChild(row);
                      }
                    : () => {
                        //do nothing
                      }
                }
                className="rowText ellipsis tBlueHover"
                style={{ marginTop: "10px" }}
                id={labelId}
              >
                {row[rowMapper[index][0]]}
              </label>
            </LightTooltip>
          </div>
        </TableCell>
      );
    } else if (index === 0 && LicenseTableFlag) {
      return (
        <TableCell width="400px" component="th" scope="row" data-testid={`tablecell-licensetableflag-${getIndex}`}>
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            <label
              className="rowText ellipsis "
              style={{
                marginTop: "10px",
                fontWeight: "bold",
                marginBottom: "-15px",
                marginLeft: showColumnCheckbox ? "44px" : "0px"
              }}
              id={labelId}
            >
              {row[rowMapper[index][0]].split(",")[0]}
            </label>
          </div>
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            {showColumnCheckbox ? (
              <Checkbox
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
                onClick={(e) => {
                  handleClick(row[checkboxRef], e);
                }}
                data-testid={`checkbox-licenseTableFlag-${getIndex}`}
                size="small"
              />
            ) : null}
            <label
              className="rowText ellipsis"
              style={{
                marginTop: "12px",
                marginBottom: "-2px"
              }}
              id={labelId}
            >
              {row[rowMapper[index][0]].split(",")[1]}
            </label>
          </div>
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            <label
              className="rowText ellipsis"
              style={{
                marginLeft: showColumnCheckbox ? "45px" : "0px",
                marginTop: "-2px"
              }}
              id={labelId}
            >
              {row[rowMapper[index][0]].split(",")[2]}
            </label>
          </div>
        </TableCell>
      );
    } else if (index === 0 && isaddstudent) {
      return (
        <TableCell className="rowText studentplacementfirstcolumn" component="th" scope="row">
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            {showColumnCheckbox
              ? displayCheckBox(
                  row,
                  labelId,
                  isItemSelected,
                  row.isAdded || row.gradeLevelName === "OTHER" || row.gradeLevelName === "PK"
                )
              : null}
            <label
              data-testid={`table-cell-addStudent-${getIndex}`}
              onClick={
                viewChild
                  ? () => {
                      viewChild(row);
                    }
                  : () => {
                      //do nothing
                    }
              }
              className={isaddstudent ? "rowText classRosterEllipsis" : "tBlueHover"}
              style={{
                marginTop: isaddstudent ? "15px" : "10px"
              }}
              id={labelId}
            >
              {rowMapper[index].length > 1
                ? (rowMapper[index] as string[]).map((_data: string, i: number) => {
                    if (i === 0) return `${row[rowMapper[index][i]]}, `;
                    else if (row.isAdded) {
                      return (
                        <>
                          {row[rowMapper[index][i]]}
                          <label className="classrosteraddedlabel">
                            {" "}
                            {index === 0 && row.isAdded ? "In Class" : ""}
                          </label>
                        </>
                      );
                    } else if (row.gradeLevelName === "OTHER" || row.gradeLevelName === "PK") {
                      return (
                        <>
                          {row[rowMapper[index][i]]}
                          <label className="classrosteraddedlabel"> {"Invalid Grade"}</label>
                        </>
                      );
                    } else return `${row[rowMapper[index][i]]}`;
                  })
                : row[rowMapper[index][0]]}
            </label>
          </div>
        </TableCell>
      );
    } else if (index === 0 && !showNameKebabMenu) {
      return (
        <TableCell className="rowText" component="th" scope="row">
          <div
            className="rowText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "left"
            }}
          >
            {showColumnCheckbox ? (
              hideCheckboxOverValue ? (
                !row[hideCheckboxOverValue] ? (
                  <Checkbox
                    disabled={row.isAdded}
                    checked={row.isAdded ? false : isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    onClick={(e) => {
                      handleClick(row[checkboxRef], e);
                    }}
                    size="small"
                  />
                ) : (
                  <div style={{ padding: "18px 22px" }}></div>
                )
              ) : (
                <Checkbox
                  disabled={row.isAdded}
                  checked={row.isAdded ? false : isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(e) => {
                    handleClick(row[checkboxRef], e);
                  }}
                  size="small"
                />
              )
            ) : null}
            <label
              onClick={
                viewChild
                  ? () => {
                      viewChild(row);
                    }
                  : () => {
                      //do nothing
                    }
              }
              className={isaddstudent || isTeacherRole ? "rowText ellipsis noCursor" : "tBlueHover rowText ellipsis"}
              style={{
                marginTop: isaddstudent ? "15px" : "10px"
              }}
              id={labelId}
            >
              {rowMapper[index].length > 1
                ? (rowMapper[index] as string[]).map((_data: string, i: number) => {
                    if (i === 0) return `${row[rowMapper[index][i]]}, `;
                    else if (row.isAdded) {
                      return <>{row[rowMapper[index][i]]}</>;
                    } else return `${row[rowMapper[index][i]]}`;
                  })
                : row[rowMapper[index][0]]}
            </label>
          </div>
        </TableCell>
      );
    } else if (
      !showNameKebabMenu &&
      index > 0 &&
      (index < rowMapper.length - 1 || LicenseTableFlag || isaddstudent || isStudentClasses)
    ) {
      return (
        <TableCell align="left" data-testid={`tablecell-licenseTableflag-${getIndex}`}>
          {rowMapper[index] !== "___" &&
          row[rowMapper[index] as string] !== "" &&
          row[rowMapper[index] as string] !== " " &&
          row[rowMapper[index] as string] !== undefined &&
          row[rowMapper[index] as string] !== null ? (
            <>
              <label className="rowText rowtext1" style={{ display: "flex" }}>
                {typeof row[rowMapper[index] as string] === "object" && row[rowMapper[index] as string].length > 0 ? (
                  <>
                    <label className="rowTextNoEllipsis" style={{ marginTop: "10px" }} id={labelId}>
                      {row[rowMapper[index] as string][0]}
                    </label>
                    {row[rowMapper[index] as string].length - 1 > 0 ? (
                      <label
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px"
                        }}
                      >
                        {` +${row[rowMapper[index] as string].length - 1}`}
                      </label>
                    ) : null}
                  </>
                ) : (
                  //@ts-ignore
                  customStatusRowMapper(row, index, labelId)
                )}
              </label>
            </>
          ) : (
            "__"
          )}
        </TableCell>
      );
    } else if (showNameKebabMenu && index === 0) {
      return (
        <TableCell className="rowText noCursor" align="left" data-testid={`table-cell-with-kebebmenu-${getIndex}`}>
          <div className="d-flex">
            <div className="status">
              <span
                className="name-text"
                data-testid={`tablecell-span-status-${getIndex}`}
                onClick={
                  viewChild
                    ? () => {
                        viewChild(row);
                      }
                    : () => {
                        //do nothing
                      }
                }
              >
                {row[rowMapper[index] as string]}
              </span>
            </div>
            {showNameKebabMenu ? (
              <div className="dropper">
                <Select
                  value={""}
                  inputProps={{
                    "data-testid": `select-shownamekebebmenu-input-${getIndex}`
                  }}
                  onChange={
                    handleStatus
                      ? () => {
                          handleStatus(row[id], row[status], getIndex);
                        }
                      : () => {
                          //do nothing
                        }
                  }
                  onMouseOver={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                  }}
                  onMouseOut={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                  }}
                  onBlur={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                  }}
                  IconComponent={() => (
                    <img
                      className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                      src={KebabIcon}
                      alt="kebab  icon"
                      width="30px"
                      height="30px"
                    />
                  )}
                  className="noUnderLine"
                  data-testid={`select-shownamekebebmenu-${getIndex}`}
                >
                  <MenuItem className="tableStatusMenu">
                    {row[rowMapper[index] as string] ? "Deactivate" : "Activate"}
                  </MenuItem>
                </Select>
              </div>
            ) : null}
          </div>
        </TableCell>
      );
    } else if (customKebabMenu) {
      return (
        <TableCell
          className="rowText noCursor"
          align="left"
          style={
            showNWEAFlag && index === rowMapper.length - 1
              ? {
                  position: "sticky",
                  right: "0",
                  backgroundColor: "white"
                }
              : null
          }
          data-testid={`table-cell-with-customkebebmenu-${getIndex}`}
        >
          <div className="d-flex">
            <div className="status">
              {showCustomKebabMenuText ? <span className="name-text">{row[rowMapper[index] as string]}</span> : null}
            </div>
            {showCustomKebabMenu ? (
              <div className="dropper">
                <Select
                  value={""}
                  onMouseOver={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0.2)");
                  }}
                  onMouseOut={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                  }}
                  onBlur={() => {
                    document
                      .getElementsByClassName(`icon${MUITableClassName}${getIndex}`)[0]
                      .setAttribute("style", "background: rgba(71, 165, 201, 0)");
                  }}
                  IconComponent={() => (
                    <img
                      className={`MuiSvgIcon-root MuiSelect-icon icon${MUITableClassName}${getIndex}`}
                      src={KebabIcon}
                      alt="kebab icon"
                      width="30px"
                      height="30px"
                    />
                  )}
                  className="noUnderLine"
                  data-testid={`select-with-showcustom-kebabmenu-${getIndex}`}
                >
                  {row.placementGrades.studentPlacementId > 0 && row.placementGrades.isILPActivated === true
                    ? customKebabMenuData.map((customData: CustomKebabMenuDataProps) => {
                        return (
                          //@ts-ignore
                          customData.key !== "addnweaid" ? displayMenuItemsOnRowSubject(customData, row) : null
                        );
                      })
                    : customKebabMenuDataFunc(row)}
                  {(row[rowMapper[0] as string] === "" ||
                    row[rowMapper[0] as string] === null ||
                    row[rowMapper[0] as string] === undefined) &&
                  (row.renaissanceID === "" || row.renaissanceID === null || row.renaissanceID === undefined) &&
                  (row.nweaid === "" || row.nweaid === null || row.nweaid === undefined) &&
                  row.subject !== "SEL" &&
                  row.subject !== "Purpose" &&
                  row.subject !== "Science" ? (
                    <MenuItem
                      className="tableStatusMenu"
                      onClick={() => {
                        customKebabMenuData[0].callback(row);
                      }}
                    >
                      {customKebabMenuData[0].data.hasValue}
                    </MenuItem>
                  ) : null}
                </Select>
              </div>
            ) : null}
          </div>
        </TableCell>
      );
    } else {
      return (
        <TableCell className="rowText rowText2" align="left">
          <div className="d-flex">
            <div className="status">
              {showStatusText === false ? null : (
                <span className="name-text noCursor">
                  {row[rowMapper[index] as string] === true ? "Active" : "Inactive"}
                </span>
              )}
              {showStatusText === false && showToolTip === true ? (
                <span className="name-text noCursor" data-testid={`status-text-with-tooltip-${getIndex}`}>
                  {row[rowMapper[index] as string]}
                </span>
              ) : null}
              {showCustomStatusText ? (
                <span className="name-text noCursor" data-testid="custom-status-text">
                  {
                    //@ts-ignore
                    row[customStatusMapper] === null
                      ? "Processing"
                      : //@ts-ignore
                        customStatusMapperFunc(row)
                  }
                </span>
              ) : null}
            </div>
            {showKebabMenu
              ? showImpersonationKebabMenuFunc(row, index, getIndex)
              : showCustomStatusTextFunc(row, getIndex)}
          </div>
        </TableCell>
      );
    }
  };

  return (
    <div className={`bg-white ${MUITableClassName} p-3 bg-white`} data-testid="table-wrapper">
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table" style={{ position: "relative" }}>
          <TableHead>
            <TableRow role="checkbox">
              {columnNames.map((data: string, index: number) => {
                return index === 0 ? (
                  <TableCell
                    className="textBold"
                    align="left"
                    style={showNWEAFlag || isaddstudent ? { zIndex: 5 } : null}
                    data-testid="table-cell-wrapper"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        alignItems: "left"
                      }}
                    >
                      {showColumnCheckbox ? (
                        <Checkbox
                          indeterminate={
                            hideCheckboxOverValue
                              ? selected.length > 0 &&
                                selected.length < rowData.filter((item) => item[hideCheckboxOverValue] !== true).length
                                ? true
                                : false
                              : selected.length > 0 &&
                                selected.length < rowData.length &&
                                (showNWEAFlag
                                  ? selected.length !==
                                    rowData.filter(
                                      (item: TableRowDataProps) =>
                                        item["subject"].toLowerCase() != "sel" &&
                                        item["subject"].toLowerCase() != "purpose" &&
                                        item.subject.toLowerCase() != "science"
                                    ).length
                                  : true)
                          }
                          checked={
                            rowData.length > 0 &&
                            (showNWEAFlag
                              ? selected.length ===
                                  rowData.filter(
                                    (item: TableRowDataProps) =>
                                      item["subject"].toLowerCase() != "sel" &&
                                      item["subject"].toLowerCase() != "purpose" &&
                                      item.subject.toLowerCase() != "science"
                                  ).length &&
                                rowData.filter(
                                  (item: TableRowDataProps) =>
                                    item.subject.toLowerCase() == "math" || item.subject.toLowerCase() == "reading"
                                ).length > 0
                              : hideCheckboxOverValue
                              ? rowData.filter((item) => item[hideCheckboxOverValue] !== true).length > 0 &&
                                selected.length ===
                                  rowData.filter((item) => item[hideCheckboxOverValue] !== true).length
                              : selected.length === rowData.length)
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all names" }}
                          size="small"
                          data-testid={`checkbox-table-${index}`}
                        />
                      ) : null}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "left",
                          alignItems: "center"
                        }}
                      >
                        <label
                          style={
                            showNWEAFlag && showColumnCheckbox
                              ? { marginTop: "10px", paddingLeft: "24px" }
                              : columnCheckBoxStyle()
                          }
                        >
                          {data}
                        </label>
                        {rowData.length > 0 && showSortList ? showSortListMapperFunc(data, index) : null}
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    className="textBold"
                    align="left"
                    style={showNWEAFlag && index === columnNames.length - 1 ? { position: "sticky", right: 0 } : null}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        alignItems: "center"
                      }}
                    >
                      <label style={{ marginTop: "10px" }}>{data}</label>

                      {rowData.length > 0 && showSortList && sortListMapper[data] != null
                        ? showSortListMapperFunc(data, index)
                        : null}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length > 0
              ? rowData.map((row: TableRowDataProps, getIndex: number) => {
                  const isItemSelected = isSelected(row[checkboxRef]);
                  const labelId = `enhanced-table-checkbox-${getIndex}`;
                  return (
                    <TableRow key={row.name}>
                      {rowMapper.map((_keys: string, index: number) => {
                        return index === 0 && showNWEAFlag ? (
                          <TableCell
                            width="20px"
                            component="th"
                            scope="row"
                            className={`studentplacementfirstcolumn ${classes.tableCell}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                alignItems: "center"
                              }}
                            >
                              {showColumnCheckbox && (
                                <label
                                  style={
                                    row.subject.toLowerCase() === "sel" ||
                                    row.subject.toLowerCase() === "purpose" ||
                                    row.subject.toLowerCase() === "science"
                                      ? { visibility: "hidden" }
                                      : {}
                                  }
                                >
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    onClick={(e) => {
                                      handleClick(row[checkboxRef], e);
                                    }}
                                    size="small"
                                    data-testid={`checkbox-column-${getIndex}-${index}`}
                                  />
                                </label>
                              )}

                              {showNWEAFlag &&
                              row.placementGrades.isReset === true &&
                              row.placementGrades.source === "MyPath" ? (
                                <LightTooltip
                                  placement="bottom-start"
                                  title={renderStudentPlacementRetestToolTip()}
                                  enterDelay={500}
                                  leaveDelay={200}
                                  id="retestMsg"
                                >
                                  <div>
                                    <Icon
                                      icon={IconEnum.SYNC_ALT}
                                      size={SizeEnum.MEDIUM}
                                      library={LibraryEnum.REGULAR}
                                      className="retestIcon"
                                    />
                                  </div>
                                </LightTooltip>
                              ) : (
                                displayWarningToolTip(row)
                              )}

                              {
                                <label
                                  data-testid={`label-row-text-${getIndex}`}
                                  onClick={
                                    viewChild
                                      ? () => {
                                          viewChild(row);
                                        }
                                      : () => {
                                          //do nothing
                                        }
                                  }
                                  className="rowText   ellipsis tBlueHover"
                                  style={{
                                    marginTop: "10px"
                                  }}
                                  id={labelId}
                                >
                                  {rowMapper[index].length > 1
                                    ? (rowMapper[index] as string[]).map((_data: string, i: number) => {
                                        if (i === 0) return `${row[rowMapper[index][i]]}, `;
                                        else return `${row[rowMapper[index][i]]}`;
                                      })
                                    : row[rowMapper[index][0]]}
                                </label>
                              }
                            </div>
                          </TableCell>
                        ) : (
                          muiTableCellData(row, index, getIndex, labelId, isItemSelected)
                        );
                      })}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        {emptyTableText && rowData.length === 0 ? (
          <label className="emptyTableFullText" data-testid="empty-table-text">
            {showHelpIconFlag ? [emptyTableText] : <i>{emptyTableText}</i>}
          </label>
        ) : null}
      </TableContainer>
    </div>
  );
}
