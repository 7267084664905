import { get, post } from "../config/ky.apiconfig";
import {
  AcademicSessionsProps, ActivateCustomerProps, AddOrgTimezoneProps, AssessmentSettingsdetailProps, BulkimportlistProps, BulkImportUploadFixedErrordProps, BulkImportUploadProps, BulkUpdateUploadFixedErrordProps, BulkUpdateUploadProps, CicSettingsProps, DeactivateCustomerProps, DownloadProps, GetAcademicSessionsProps, GetchildrenProps, GetExternalInstitutionIdProps, GetFlagerStatusProps, GetGuids,
  GetIds, GetLicensedProductsProps, GetLogincodeProps, GetOrganizationDetailsProps, GetOrgLicensedSubjectsProps, GetOrgTimezoneProps, GetParentsProps,
  GetParentsWithRootProps, GetProductLicensedataProps, GetSchoolsToMapProps,
  GetSettingsSsoProps, GetSisenseReportTokenProps, LicensebyguidProps, LogouteventProps, MypathAssessmentSettingsProps, NetSuiteProps, NweaSaveSettingProps, NweaSettingsProps, NweaSyncServiceProps, OrganizationInformationProps, RenaissanceSaveServiceProps, RenaissanceSettingsProps, RenaissanceSyncServiceProps, SaveLoginCodeProps, SaveProductLicensingProps, SaveSettingsSsoProps, SavesettingswithguidProps, SchoolSearchProps, SearchChildOrganizationServiceProps, SearchProps, UpdateOrgTimezoneProps
} from "./api.services.props";

export const AdminService = {
  getSchools: (args: SchoolSearchProps) =>
    post(`api/admin/getschools`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  downloadFiles: (args: DownloadProps) =>
    get(`api/aws/downloadfilefroms3?BulkImportID=${args.id}&FileType=${args.type}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkUpdateValidateClasses: (args: FormData) =>
    post(`bulkupdate/validateclass`, {
      body: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkUpdateValidateEducators: (args: FormData) =>
    post(`bulkupdate/validateeducators`, {
      body: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkUpdateValidateStudents: (args: FormData) =>
    post(`bulkupdate/validatestudents`, {
      body: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  validateClasses: (args: FormData) =>
    post(`bulkimport/validateclasses`, {
      body: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  validateEducators: (args: FormData) =>
    post(`bulkimport/validateeducators`, {
      body: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  validateStudents: (args: FormData) =>
    post(`bulkimport/validatestudents`, {
      body: args,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  searchCustomers: (args: SearchProps) =>
    post(`admin/searchorganizations`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  saveLogoutevent: (args: LogouteventProps) =>
    post(`event/logoutevent`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getLastSyncDate: () =>
    get(`organizations/lastsynccompleteddate`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  netSuiteSync: (args: NetSuiteProps) =>
    post(`organizations/netsuitesync?lastModifiedDate=${args.date}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  downloadSyncContent: () =>
    get(`organizations/downloadlastsyncdata`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  nweaSyncService: (args: NweaSyncServiceProps) =>
    post(`sync/nweaondemandjobwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  renaissanceSyncService: (args: RenaissanceSyncServiceProps) =>
    post(`sync/renaissanceondemandjobwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  netSuiteSyncStatus: () =>
    get(`organizations/syncstatus`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  saveProductLicensing: (args: SaveProductLicensingProps) =>
    post(`productlicensing`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getGUIDSWithGuid: (args: GetGuids) =>
    get(`admin/organizationguiddetails?OrganizationGuids=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getTimezoneList: () =>
    get(`settings/viewtimezones?timeZoneDst=0`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getIds: (args: GetIds) =>
    post(`api/admin/organizationidbyguid`, {
      json: args.guids
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getCurrentOrganizationDetails: (orgGuid: string) => 
    get(`v2/organization/${orgGuid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error};
      }),
  getOrganizationDetailsWithGuid: (args: GetOrganizationDetailsProps) =>
    get(`api/admin/getorganizationdetailswithguid?organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  mypathAssessmentSettingsWithGuid: (args: MypathAssessmentSettingsProps) =>
    get(`mypath/settingswithguid?organizationGuid=${args.orgGuid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getLogincodeWithGuid: (args: GetLogincodeProps) =>
    get(`organizations/settings/logincodewithguid?organizationGuid=${args.guid.split("_")[0]}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getParentsWithGuid: (args: GetParentsProps) =>
    get(`api/admin/getparentswithguid?guid=${args.guid}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getParentsWithRootWithGuid: (args: GetParentsWithRootProps) =>
    get(`api/admin/getparentswithrootguid?RootOrganizationGuid=${args.rid}&CurrentOrganizationGuid=${args.cid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  nweaSettingsWithGuid: (args: NweaSettingsProps) =>
    get(`nwea/settingswithguid?organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  renaissanceSettingsWithGuid: (args: RenaissanceSettingsProps) =>
    get(`renaissance/settingswithguid?organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getSchoolsToMapWithGuid: (args: GetSchoolsToMapProps) =>
    get(`api/admin/getschoolstomapwithguid?userGuid=${args.uid}&organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getSettingsSsoWithGuid: (args: GetSettingsSsoProps) =>
    get(`organizations/settings/ssowithguid?organizationGuid=${args.orgGuid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  saveSettingsSsoWithGuid: (args: SaveSettingsSsoProps) =>
    post(`organizations/settingsbyguid/sso`, {
      json: { ...args },
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getOrgLicensedSubjectsWithGuid: (args: GetOrgLicensedSubjectsProps) =>
    post(`organizations/licensedsubjectswithguid`, {
      json: args.subjectGuidObj
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  organizationInformationGuid: (args: OrganizationInformationProps) =>
    get(`api/admin/organizationinformationwithguid?organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  nweaSaveSettingsWithGuid: (args: NweaSaveSettingProps) =>
    post(`nwea/savesettingswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  searchChildOrganizationsWithGuid: (args: SearchChildOrganizationServiceProps) =>
    post(`admin/searchchildorganizationswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  activateCustomerWithGuid: (args: ActivateCustomerProps) =>
    post(`organization/activatewithguid?guid=${args.guid}`, { timeout: 120000 })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  deactivateCustomerWithGuid: (args: DeactivateCustomerProps) =>
    post(`organization/deactivatewithguid?guid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  renaissanceSaveSettingsWithGuid: (args: RenaissanceSaveServiceProps) =>
    post(`renaissance/savesettingswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  assessmentSettingsdetailWithGuid: (args: AssessmentSettingsdetailProps) =>
    post(`assessment/settingsdetailwithguid`, {
      json: args.assessmentSettingsObj,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  saveMyPathAssessmentSettingsWithGuid: (args: SavesettingswithguidProps) =>
    post(`mypath/savesettingswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  saveLogincodeWithGuid: (args: SaveLoginCodeProps) =>
    post(`organizations/settings/logincodewithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getExternalInstitutionIdByGuid: (args: GetExternalInstitutionIdProps) =>
    get(`organization/getexternalinstitutionwithguid?organizationGuids=${args.guid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getLicensingDataWithGuid: (args: LicensebyguidProps) =>
    post(`organizations/${args.orgID}/licensebyguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getProductLicenseDataWithGuid: (args: GetProductLicensedataProps) =>
    get(`productlicensedatawithguid?OrganizationGuid=${args.orgGuid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getOrgTimezoneWithGuid: (args: GetOrgTimezoneProps) =>
    get(`${args.orgGuid}/settings/viewtimezonewithguid`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  addOrgTimezoneWithGuid: (args: AddOrgTimezoneProps) =>
    post(`timezones/${args.orgGuid}/add/${args.timezoneId}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updateOrgTimezoneWithGuid: (args: UpdateOrgTimezoneProps) =>
    post(`timezones/${args.orgGuid}/edit/${args.timezoneId}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getChildrenWithGuid: (args: GetchildrenProps) =>
    post(`api/admin/getchildrenwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getCicSettingsWithGuid: (args: CicSettingsProps) =>
    post(`admin/organizations/cicsettingswithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkUpdateUploadWithGuid: (args: BulkUpdateUploadProps) =>
    post(`bulkupdate/updatewithguid?UpdateType=${args.type}&orgGuid=${args.orgGuid}`, {
      body: args.bulkUpdateUploadjsonObj
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkImportUploadWithGuid: (args: BulkImportUploadProps) =>
    post(`bulkimport/importwithguid?ImportType=${args.type}&orgGuid=${args.orgGuid}`, {
      body: args.bulkImportUploadjsonObj,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updateAcademicSessions: (args: AcademicSessionsProps) =>
    post(`academicsessions`, {
      json: args
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkImportUploadFixedErrordWithGuid: (args: BulkImportUploadFixedErrordProps) =>
    post(`bulkerror/importwithguid?ImportType=${args.type}&orgGuid=${args.orgGuid}&ImportId=${args.bid}`, {
      body: args.bulkImportUploadFixedErrordjsonObj
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkUpdateUploadFixedErrordWithGuid: (args: BulkUpdateUploadFixedErrordProps) =>
    post(`bulkerror/updatewithguid?UpdateType=${args.type}&orgGuid=${args.guid}&UpdateId=${args.bid}`, {
      body: args.bulkUpdateUploadFixedErrordjsonObj
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  bulkImportListWithGuid: (args: BulkimportlistProps) =>
    post(`bulkimport/getuploadlistwithguid`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getAcademicSessionsWithGUID: (args: GetAcademicSessionsProps) =>
    get(`academicsessionswithguid?organizationGuid=${args.guid}`, {
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getSisenseReportToken: (args: GetSisenseReportTokenProps) =>
    post(`sisensereport/token`, {
      json: { ...args }
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getDSDSReportToken: async (args: { districtGuid: string | null; schoolGuid: string | null }) => {
    try {
      const response = await post("dsdsreport/token", {
        json: args
      });
      return { status: response.status, data: response };
    } catch (error) {
      return { status: false, data: error };
    }
  },
  getPacificDateTime: () =>
    get(`organization/currentdatetime`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getLicensedProducts: (args: GetLicensedProductsProps) =>
    get(`organization/licensedproducts?organizationGuid=${args.orgGuid}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getFlagerStatus: (args: GetFlagerStatusProps) =>
    post(`flags/status`, {
      json: args,
      timeout: 120000
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  updateSettingsAppSettings: (args: string) =>
    get(`admin/redis/deletecache?organizationGuid=${args}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getAcademicsessionsImportValue: (args: string) =>
    get(`academicsessions/cicimport?organizationGuid=${args}`)
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      }),
  getOrganizationGuidByRegistrationGuid: (args: string) =>
    get(`organization/getorganizationguidbyregistrationguid?registrationGuid=${args}`, {
      timeout: 120000 // It's generally a good practice to always set a timeout for HTTP requests. This ensures that the application can recover and continue executing even if some requests do not receive a response in a timely manner.
    })
      .then((response) => response)
      .then((response) => {
        return { status: response.status, data: response };
      })
      .catch((error) => {
        return { status: false, data: error };
      })
};
