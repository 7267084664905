import React, { useState, useEffect } from "react";
import { UxAction, UxActivityIndicator } from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import configMessages from "../../helpers/configMessages";
import "./ResetPassword.scss";
import "../Login/login.scss";
import logo from "../../images/edgenuity-logo-large.png";
import Ilogo from "../../images/LOGO-IL.png";

export default function ResetPassword() {
  const { t } = useTranslation();
  //@ts-ignore
  const { token } = useParams<{ token: string | undefined }>();
  const navigate = useNavigate();
  const [state, setState] = useState({
    password: "",
    cnfpassword: "",
    eid: "",
    aud: ""
  });
  const [activityIndicator, setActivityIndicator] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [showToast, setShowToast] = useState({ val: false, msg: "", type: "" });
  const [showLoginLink, setShowLoginLink] = useState(false);

  useEffect(() => {
    try {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const jtoken = JSON.parse(jsonPayload);
      const time = Math.round(new Date().getTime() / 1000);
      if (time > jtoken.exp) {
        setActivityIndicator(false);
        setIsValid(false);
      } else {
        setState({ ...state, eid: jtoken.iss, aud: jtoken.aud });
        validateLink(jtoken.iss, jtoken.aud);
      }
    } catch (err) {
      setIsValid(false);
      setActivityIndicator(false);
    }

    // eslint-disable-next-line
  }, []);

  async function validateLink(eid: string, aud: string) {
    try {
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };
      const response = await fetch(
        `${window.APP_ENV.userApiUrl}/api/validateResetlink?key=${aud}&email=${eid}`,
        options
      );

      if (response.status === 200) {
        let allData = JSON.parse(await response.text());
        if (!allData) {
          setIsValid(false);
        }
        setActivityIndicator(false);
      } else {
        setIsValid(false);
        setActivityIndicator(false);
      }
    } catch (err) {
      setIsValid(false);
      setActivityIndicator(false);
    }
  }

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>, inputType: string): void {
    if (!event.target.value.includes(" ")) {
      setState({ ...state, [inputType]: event.target.value });
    }
  }

  function validatePasswordBackend() {
    setShowToast({
      val: true,
      msg: configMessages.CreateEducatorPasswordValidationMin,
      type: ""
    });
  }

  async function updatePassword() {
    setActivityIndicator(true);
    try {
      const apiObj = {
        key: state.aud,
        password: state.password,
        emailId: state.eid
      };
      const response = await fetch(`${window.APP_ENV.userApiUrl}/api/changepassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiObj)
      });
      if (response.status === 200) {
        setShowLoginLink(true);
        setIsValid(false);
        setActivityIndicator(false);
      } else if (response.status === 400) {
        setActivityIndicator(false);
        validatePasswordBackend();
      } else {
        setIsValid(false);
        setActivityIndicator(false);
      }
    } catch (err) {
      setIsValid(false);
      setActivityIndicator(false);
    }
  }

  function savePassword() {
    if (state.password !== "" && state.password === state.cnfpassword && state.password.length <= 256) {
      updatePassword();
    } else {
      if (state.password === "" || state.password.length < 8) {
        setShowToast({
          val: true,
          msg: configMessages.CreateEducatorPasswordValidationMin,
          type: ""
        });
      } else if (state.password.length > 256) {
        setShowToast({
          val: true,
          msg: configMessages.CreateEducatorpasswordValdiationMax,
          type: "warning"
        });
      } else {
        setShowToast({
          val: true,
          msg: configMessages.confirmPasswordValidation,
          type: ""
        });
      }
    }
  }

  function loginPage() {
    navigate("/");
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();
  const user = localStorage.getItem("ala:redirectedFrom") === "wneuser" ? "wneuser" : "educator";

  const showLoginLinkHolder = () => {
    if (showLoginLink) {
      return (
        <p
          onClick={() => {
            loginPage();
          }}
          className="returntologinpage"
        >
          Return to Login Screen
        </p>
      );
    } else {
      return (
        <>
          <p className="rHeading"> Reset Password </p>
          <p className="returnmessage"> This link is no longer valid. Please contact your Administrator. </p>
        </>
      );
    }
  };

  return (
    <>
      {activityIndicator ? <UxActivityIndicator type="nouser" /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        {user === "wneuser" ? (
          <title>Edgenuity - Reset Password</title>
        ) : (
          <title>Imagine MyPath - Reset Password</title>
        )}
      </Helmet>
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={10000}
          onClose={() => {
            setShowToast({ val: false, msg: "", type: "" });
          }}
        >
          <Alert
            severity="warning"
            style={{ backgroundColor: "#f4473c", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "", type: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div className="background"></div>
      <div className="resetpwdlayout">
        <div className="resetpwdcontent">
          <div className="resetpwdlogoimg">
            <img
              src={user === "wneuser" ? logo : Ilogo}
              alt=""
              style={user === "wneuser" ? { width: "60%", height: "auto" } : { width: "34%", height: "auto" }}
            />
          </div>

          <div className="resetpwdinput">
            {isValid ? (
              <>
                <div>
                  <p className="rHeading"> Reset Password </p>
                </div>

                <div className="textinputholderwne">
                  <div>
                    <input
                      style={{ border: "1px solid #cccccc" }}
                      className={"logintextinputresponsive urpass tabfocus loginellipsis"}
                      placeholder="Password"
                      type="password"
                      value={state.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "password")}
                      tabIndex={0}
                    />
                  </div>
                </div>
                <div className="textinputholderwne">
                  <div>
                    <input
                      style={{ border: "1px solid #cccccc" }}
                      className={"logintextinputresponsive urpass tabfocus loginellipsis"}
                      type="password"
                      placeholder="Confirm Password"
                      value={state.cnfpassword}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "cnfpassword")}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          savePassword();
                        }
                      }}
                      tabIndex={0}
                    />
                  </div>
                </div>

                <div className="resetbuttonbox">
                  <UxAction
                    callback={() => {
                      savePassword();
                    }}
                    text={t("Reset")}
                    className="continuebuttonresponsive"
                  />
                </div>
              </>
            ) : (
              showLoginLinkHolder()
            )}
          </div>
        </div>
        <div className="resetpwdfooter">
          {user === "wneuser" ? (
            <div className="leftfooter">© {new Date().getFullYear()} Edgenuity Inc. All Rights Reserved.</div>
          ) : (
            <div className="leftfooter">© {new Date().getFullYear()} Imagine Learning LLC. All Rights Reserved.</div>
          )}

          <div className="rightfooter" style={{ fontWeight: 500 }} role="contentinfo" aria-label="Footer">
            <a
              className="tagRightFooter"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/en/us/products/mypath"
              id="about"
              tabIndex={0}
              aria-label="About link opens in a new tab"
              role="link"
              onKeyDown={(event) => {
                if (event.key === "Spacebar" || event.key === " ") {
                  window.open("https://www.imaginelearning.com/en/us/products/mypath");
                }
              }}
            >
              <u> About </u>
            </a>
            <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
            <a
              className="tagRightFooter  "
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/support/privacy/policy/"
              tabIndex={0}
              aria-label="Privacy Policy link opens in a new tab"
              id="policy"
              role="link"
            >
              <u> Privacy Policy</u>
            </a>
            <span style={{ color: "#fff", fontSize: "12px" }}>•</span>
            <a
              className="tagRightFooter  "
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.imaginelearning.com/media/pdf/End%20User%20TOS%202022%20IL%20LLC.pdf"
              tabIndex={0}
              aria-label="Terms of Use link opens in a new tab"
              id="terms"
              role="link"
            >
              <u> Terms of Use </u>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
