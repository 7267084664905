import React, { useEffect } from "react";
import MyAssignmentCard from "../StudentSubjects/MyAssignmentCard";
import StudentSubjectsFor68 from "../StudentSubjects/StudentSubjectsFor68";
import { Result, TestWindow, UpdatePlacementProps } from "./StudentHomePage.props";
import GetToken, { GetRefreshToken } from "../../../authService/GetToken";
import StudentSubjectsFor912 from "../StudentSubjects/StudentSubjectsFor912";
import StudentSubjectsFork5 from "../StudentSubjects/StudentSubjectsFork5";
import PracticeCard from "../StudentSubjects/PracticeCard";
import useGradeBand from "../hooks/useGradeBand";
import usePageTitle from "../hooks/usePageTitle";
import useMPPSubject from "../hooks/useMPPSubject";
import { ApiPathwayTypeEnum } from "../../ClassView/StudentPlacement.props";

export interface AssignmentsAndSubjectsProps {
  allPlacementData: UpdatePlacementProps[];
  showMyAssignmentBadge: boolean;
  getMPPAPP: boolean;
  showIMPathway: boolean;
  grade: string;
  studentMypathAssessment: any;
  mppSubjects: UpdatePlacementProps[];
  subStatusAndOrder: any[];
}

export default function AssignmentsAndSubjects({
  allPlacementData,
  showMyAssignmentBadge,
  getMPPAPP,
  showIMPathway,
  grade,
  studentMypathAssessment,
  mppSubjects,
  subStatusAndOrder
}: Readonly<AssignmentsAndSubjectsProps>) {
  const { mppSubject, updateMppSubject } = useMPPSubject();
  const { updateTitle } = usePageTitle();
  const { isKto2, is3to5, is6to8 } = useGradeBand(grade);

  useEffect(() => {
    updateTitle("Student Dashboard | Imagine MyPath");
  }, []);

  useEffect(() => {
    let mppSubjectHolder = mppSubject || localStorage.getItem("ala:MppLaunchSubject");
    if (getMPPAPP && !mppSubject && mppSubjectHolder) {
      updateMppSubject(mppSubjectHolder);
    }
  }, [mppSubject, allPlacementData, getMPPAPP, showMyAssignmentBadge]);

  const getHighestOrder = (data: TestWindow) => {
    let highestOrder = 1;
    for (let record in data) {
      if (data[record].order > highestOrder) {
        highestOrder = data[record].order;
      }
    }
    return highestOrder;
  };

  //click on go button  from homepage,,, for grade k-5
  function handleClick(subject: string, pathwayType?: ApiPathwayTypeEnum) {
    let subjectData = allPlacementData.filter((item: UpdatePlacementProps) => {
      return pathwayType ? item.pathwayType === pathwayType : item.subject === subject;
    });
    //@ts-ignore
    localStorage.setItem(
      "stud:myPathSubjectStatus",
      //@ts-ignore
      `${subjectData[0].myPathSubjectStatus}`
    );

    const storageSubject = subject === "Purpose" ? "SEL" : subject;
    localStorage.setItem("stud:subName", storageSubject);
    localStorage.setItem("stud:isGradek12", `${isKto2}`);
    let catSubject = storageSubject === "Math" ? "math" : "ela";
    let subWindow = subStatusAndOrder.filter((item: Result) => item.subject === catSubject);
    if (storageSubject === "SEL" || subWindow.length === 0 || storageSubject === "Science") {
      goToMPNG(pathwayType ?? "");
      return;
    }

    let windowOrder = 1;
    if (subWindow[0].status !== "NoWindowAvailable") {
      windowOrder = getHighestOrder(subWindow[0].testWindows);
    }
    goToBubblePageFunc(subject, subWindow, windowOrder, pathwayType);
  }

  const goToBubblePageFunc = (subject: string, subWindow, windowOrder: number, pathwayType?: ApiPathwayTypeEnum) => {
    let studentLevelSubject =
      subject.toLowerCase() === "math" ? studentMypathAssessment.math : studentMypathAssessment.reading;
    if (localStorage.getItem("stud:myPathSubjectStatus") === "true") {
      if (studentLevelSubject === "On" && subWindow[0].status === "NotStarted" && windowOrder === 1) {
        goToBubblePage();
        localStorage.setItem("stud:TransitionScreen", "false");
      } else if (studentLevelSubject === "On" && subWindow[0].status === "NotStarted" && windowOrder !== 1) {
        goToBubblePage();
        localStorage.setItem("stud:testTimeAgain", "true");
        localStorage.setItem("stud:TransitionScreen", "true");
      } else if (studentLevelSubject === "On" && subWindow[0].status === "InProgress") {
        goToBubblePage();
        localStorage.setItem("stud:testTimeAgain", "false");
        localStorage.setItem("stud:TransitionScreen", "true");
      } else if (
        subWindow[0].status === "Completed" ||
        subWindow[0].status === "Waiting" ||
        subWindow[0].status === "Restricted"
      ) {
        localStorage.setItem("stud:TransitionScreen", "false");
        goToMPNG(pathwayType ?? "");
      } else {
        goToMPNG(pathwayType ?? "");
      }
    } else {
      goToMPNG(pathwayType ?? "");
    }
  };

  function goToMPNG(subName: string = "") {
    const token = GetToken();
    const refreshToken = GetRefreshToken();
    localStorage.setItem("ImlpToken", token);
    window.location.assign(
      `${window.APP_ENV.lmsApiUrl}/lti/launch?token=${token}&subjectId=${
        subName !== "" ? subName : localStorage.getItem("stud:subName")
      }&refreshToken=${refreshToken}&returnURL=${window.location.host}/home`
    );
  }

  function goToBubblePage() {
    window.location.assign(isKto2 || is3to5 ? "/mypathassessmentk5" : "/mypathassessment612");
  }

  const getStudentSubjectCard = (mppSubjects: UpdatePlacementProps[]) => {
    if (mppSubjects.length !== 0 && mppSubjects[0].subject !== "") {
      if (isKto2 || is3to5) {
        return (
          <>
            {mppSubjects.map((subjectObj, index) => subjectObj.pathwayType === ApiPathwayTypeEnum.PRACTICE && showIMPathway ? (
              <PracticeCard key={`${subjectObj.subject}${index}`} handleClick={handleClick}/>
            ) : (
              <StudentSubjectsFork5
                key={`${subjectObj.subject}${index}`}
                showIMPathway={showIMPathway}
                subject={subjectObj.subject}
                subjectClick={handleClick}
              />
            ))}
          </>
        );
      } else if (is6to8) {
        return (
          <>
            {mppSubjects.map((subjectObj, index) => subjectObj.pathwayType === ApiPathwayTypeEnum.PRACTICE && showIMPathway ? (
              <PracticeCard key={`${subjectObj.subject}${index}`} handleClick={handleClick}/>
            ) : (
              <StudentSubjectsFor68
                key={`${subjectObj.subject}${index}`}
                showIMPathway={showIMPathway}
                subject={subjectObj.subject}
                goClick68={handleClick}
              />
            ))}
          </>
        );
      } else {
        return (
          <>
            {mppSubjects.map((subjectObj, index) => subjectObj.pathwayType === ApiPathwayTypeEnum.PRACTICE && showIMPathway ? (
              <PracticeCard key={`${subjectObj.subject}${index}`} handleClick={handleClick}/>
            ) : (
              <StudentSubjectsFor912
                key={`${subjectObj.subject}${index}`}
                showIMPathway={showIMPathway}
                subjectName={subjectObj.subject}
                goClick={handleClick}
              />
            ))}
          </>
        );
      }
    }
  };

  return (
    <>
      {showMyAssignmentBadge && <MyAssignmentCard handleClick={goToMPNG} />}
      {getStudentSubjectCard(mppSubjects)}
    </>
  );
}
