import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UxTabs, UxMUITable, UxIconEnum, UxIcon } from "@wne-spa/ux-component-library";
import studentTabs from "./studentTabs";
import { UserService } from "../../services/helpers/user.services";
import { updateActivityIndicator, updateClassViewTab } from "../../Redux/Action";
import { connect } from "react-redux";
import "../StudentProfile/StudentClasses.scss";

function mapStateToProps(state: any) {
  return {
    getUserRoleId: state.getUserRoleId,
    getFlagrKeys: state.getFlagrKeys,
    getSchoolGUID: state.getSchoolGUID,
    getClassListSort: state.getClassListSort,
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}

function StudentClasses(props: any) {
  const { dispatch, getFlagrKeys, getSchoolGUID, getClassListSort, getBreadCrumbsData, getUserRoleId } = props;
  const [tabKey, setTabKey] = useState("classes");
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [data, setData] = useState([]);
  const [tableHgt, setTableHgt] = useState(0);
  const [studentEnrollIcon, setStudentEnrollIcon] = useState(false);
  const [classNameBool, setClassNameBool] = useState<boolean | null>(null);
  const [educatorBool, setEducatorBool] = useState<boolean | null>(null);
  const [subjectBool, setSubjectBool] = useState<boolean | null>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const columnNames = ["Class Name", "External Id", "Teacher", "Subject"];
  const rowMapper = [["classTitle"], "districtAssignedId", "educatorList", "subjectList"];
  const sortBy = useRef(getClassListSort.name);
  const sortOrder = useRef(getClassListSort.val);

  useEffect(() => {
    getStudentClasses();
    //@ts-ignore
    window.addEventListener("resize", handleResize);
    setSortColumn();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleResize = () => {
    if (window.innerHeight > 650) {
      //@ts-ignore
      setTableHgt(
        //@ts-ignore
        parseInt(window.innerHeight - (elementRef.current.offsetTop + 75))
      );
    } else {
      //@ts-ignore
      setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
    }
  };

  function getStudentClasses() {
    let args = {
      studentGuid: window.location.pathname.split("_").reverse()[0],
      organizationGuid: getSchoolGUID,
      sortName: sortBy.current,
      sortOrder: sortOrder.current
    };
    dispatch(updateActivityIndicator(true));
    (async () => {
      await UserService.getStudentClasses(args).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          //@ts-ignore
          setTableHgt(parseInt(650 - (elementRef.current.offsetTop + 0)));
          getStudentData(data);
          setStudentEnrollIcon(false);
        } else {
          setStudentEnrollIcon(true);
          dispatch(updateActivityIndicator(false));
          //added because suppose if we get status other than 200 in any case,,,for the user perspective we are displaying this,,,
        }
      });
    })();
  }

  const noStudentsEnrolled = () => {
    let studentName = [...getBreadCrumbsData.crumbs].reverse()[0].name;
    return (
      <div className="enroll">
        <div className="enrollIcon">
          <UxIcon icon={UxIconEnum.NO_CLASSES} className="noClassesIcon" />
        </div>
        <div className="enrollMsg">{studentName} is not enrolled in any classes</div>
      </div>
    );
  };
  const studentClassesIcon = () => {
    setStudentEnrollIcon(true);
    dispatch(updateActivityIndicator(false));
  };
  const getStudentData = async (data) => {
    let result = await data.json();
    //@ts-ignore
    result.length > 0 ? setData(result) : studentClassesIcon();
    if (sortBy.current === "classname") {
      setEducatorBool(null);
      setSubjectBool(null);
    } else if (sortBy.current === "teacher") {
      setSubjectBool(null);
      setClassNameBool(null);
    } else {
      setClassNameBool(null);
      setEducatorBool(null);
    }
    dispatch(updateActivityIndicator(false));
  };

  function onTabChange(tab: string) {
    if (tab === "settings") {
      navigate(`/viewstudent/${window.location.pathname.split("/").reverse()[0]}`);
    } else if (tab === "studentprogress") {
      navigate(`/studentprogress/${window.location.pathname.split("/").reverse()[0]}`);
    }
  }

  function paginationcomp() {
    return (
      <div style={{ display: "inline" }}>
        {data.length > 0 ? (
          <span
            style={{
              float: "right",
              marginTop: 20,
              marginRight: 20,
              fontSize: "15px"
            }}
          >
            {" "}
            Viewing Records 1 - {data.length} of {data.length}
          </span>
        ) : null}
      </div>
    );
  }
  function setSortColumn() {
    if (getClassListSort.val === "desc" || getClassListSort.val === "DESC") {
      if (getClassListSort.name === "classname") {
        setClassNameBool(true);
      } else if (getClassListSort.name === "teacher") {
        setEducatorBool(true);
      } else {
        setSubjectBool(true);
      }
    } else if (getClassListSort.val === "asc" || getClassListSort.val === "ASC") {
      if (getClassListSort.name === "classname") {
        setClassNameBool(false);
      } else if (getClassListSort.name === "teacher") {
        setEducatorBool(false);
      } else {
        setSubjectBool(false);
      }
    }
  }
  function sortChange(type: string) {
    if (type === "Class Name") {
      if (!classNameBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "classname";
    } else if (type === "Teacher") {
      if (!educatorBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "teacher";
    } else {
      if (!subjectBool) {
        sortOrder.current = "desc";
      } else {
        sortOrder.current = "asc";
      }
      sortBy.current = "subject";
    }
    getStudentClasses();
  }
  let updatedStudentTabs = !getFlagrKeys["ShowStudentProgressTab"]
    ? studentTabs.filter((x) => x.eventKey !== "studentprogress")
    : studentTabs;

  const viewClass = (args: any) => {
    dispatch(updateClassViewTab("classsummary"));
    navigate(`/classview/${args.classGUID}`);
  };
  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
            onTabChange(tab);
          }}
          children={updatedStudentTabs}
          className="pt-2"
        />
      </div>
      {!studentEnrollIcon && (
        <>
          <div ref={elementRef}>
            <UxMUITable
              MUITableClassName={"contentForClasses"}
              columnNames={columnNames}
              rowMapper={rowMapper}
              rowData={data}
              showColumnCheckbox={false}
              showKebabMenu={false}
              showNameKebabMenu={false}
              viewChild={getUserRoleId === 5 ? () => {} : viewClass}
              emptyTableText={""}
              checkboxRef="classGUID"
              selected={selected}
              setSelected={setSelected}
              isStudentClasses={true}
              isTeacherRole={getUserRoleId === 5 ? true : false}
              mtableHeight={tableHgt}
              showSortList={[true, false, true, true]}
              sortListMapper={{
                "Class Name": [
                  classNameBool,
                  setClassNameBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Teacher: [
                  educatorBool,
                  setEducatorBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ],
                Subject: [
                  subjectBool,
                  setSubjectBool,
                  (args: string) => {
                    sortChange(args);
                  }
                ]
              }}
            />
          </div>
          <div>{paginationcomp()}</div>
        </>
      )}

      {studentEnrollIcon && noStudentsEnrolled()}
    </>
  );
}

export default connect(mapStateToProps)(StudentClasses);
