import { useEffect, useState } from "react";

import { GetFlagrKeysProps, ReportFlagMetadata, SchoolGuidsResponseProps } from "../../Redux/Redux.props";
import { AcademicSessionsAPIResponse } from "../AcademicSessions/AcademicSessions.props";
import { AdminService } from "../../services/helpers/api.services";

const useHasStandardsProficiencyReportAccess = (id: string, schoolData: SchoolGuidsResponseProps[], getFlagrKeys: GetFlagrKeysProps, userGuid: number, level: string) => {
  const [hasStandardsReportAccess, setHasStandardsReportAccess] = useState(false);

  useEffect(() => {
    const loadAcademicSessions = async () => {
      try {
        const { on: hasAccess, flagValues } = getFlagrKeys['StandardsProficiencyReport-Access'] as ReportFlagMetadata;

        if (!hasAccess || flagValues.organization_level_to_hide?.includes(level)) {
          setHasStandardsReportAccess(false);
          return;
        }

        if (flagValues.user_ids?.includes(`${userGuid}`)) {
          setHasStandardsReportAccess(true);
          return;
        }

        if (!flagValues.sessionYear) {
          setHasStandardsReportAccess(false);
          return;
        }
        const orgGUID = schoolData.length > 0 ? schoolData[0].organizationGuid || schoolData[0].orgGUID : "";
        if (!orgGUID) {
          return;
        }
        const { status, data } = await AdminService.getAcademicSessionsWithGUID({ guid: orgGUID });
        if (status !== 200) {
          setHasStandardsReportAccess(false);
          return;
        }

        const academicSessions: AcademicSessionsAPIResponse[] = await data.json();
        const isValid = isSessionYearValid(academicSessions, flagValues.sessionYear);
        setHasStandardsReportAccess(isValid);
        return;
      } catch (error) {
        // do nothing.
      }
    };

    loadAcademicSessions();
  }, [id, schoolData, getFlagrKeys, userGuid, level]);

  return hasStandardsReportAccess;
};

const isSessionYearValid = (academicSessions: AcademicSessionsAPIResponse[], sessionYear: number) => {
  try {
    const sortedSessions = [...academicSessions].sort((a, b) => Number(a.schoolYear) < Number(b.schoolYear) ? 1 : -1);
    return sortedSessions.length > 0 && Number(sortedSessions[0].schoolYear) >= sessionYear;
  } catch (error) {
    // do nothing.
  }
}

export default useHasStandardsProficiencyReportAccess;