import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { AcademicClockIcon } from "@wne-spa/ux-component-library";
import "./AcademicSessions.scss";
import {
  updateActivityIndicator,
} from "../../Redux/Action";
import moment from "moment";
import { AdminService } from "../../services/helpers/api.services";
import configMessages from "../../helpers/configMessages";
import {
  AcademicSessionsProps,
  AcademicSessionsAPIResponse
} from "./AcademicSessions.props";
import ReduxProps from "../../Redux/Redux.props";

import Table from 'react-bootstrap/Table';

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getAcademicCalendar: state.getAcademicCalendar,
    getTabChange: state.getTabChange,
    getFlagrKeys: state.getFlagrKeys
  };
}
interface Props extends AcademicSessionsProps, ReduxProps { }

function FreshSessions(props: Props) {
  const { isFrom, getAcademicCalendar, getUserRoleId, getTabChange, dispatch, getFlagrKeys } = props;
  const { id } = useParams<{ id: string | undefined }>();
  const [sessions, setSessions] = useState<AcademicSessionsAPIResponse[]>([]);

  const academic_clock = (
    <div className="academic-clock-bar">
      <img src={AcademicClockIcon} style={{ marginLeft: "5px" }} />
      <label className="academic-clock-bar-text-schedule">{configMessages.academicClockBarNote}</label>
    </div>
  );

  useEffect(() => {
    getAcademicSessions(id);
  }, [id]);

  //functions
  function getAcademicSessions(OrgId: string) {
    try {
      dispatch(updateActivityIndicator(true));
      AdminService.getAcademicSessionsWithGUID({ guid: OrgId }).then(
        async ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            const jsonData = await data.json();
            setSessions(jsonData);
            dispatch(updateActivityIndicator(false));
          } else {
            // do nothing
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="as-page">
      <div className={"academic-note"}>
        <b style={{ fontStyle: "italic" }}>Note:</b>{" "}
        {configMessages.AcademicSessionReadOnlyMessage}
      </div>
      <div>{academic_clock}</div>
      <div >
        <Table className="as-table">
          <thead>
            <tr className="as-table-header">
              <th>ACADEMIC SESSION</th>
              <th>SCHOOL YEAR</th>
              <th>START DATE</th>
              <th>END DATE</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((session, index) => (
              <tr className="as-table-row" key={index}>
                <td >{session.title}</td>
                <td >{session.schoolYear}</td>
                <td >{moment(session.startDate).format("LL")}</td>
                <td >{moment(session.endDate).format("LL")}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(FreshSessions);
