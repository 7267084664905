import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import {
  updateClassViewTab,
  updateTabName,
  updateClassGUID,
  updateClassDetails,
  updatePreviousURLForClassProgress,
  updateActivityIndicator
} from "../../Redux/Action";
import { useParams, useNavigate } from "react-router-dom";
import "./classSettings.scss";
import { LMSService } from "../../services/helpers/lms.services";
import ReduxProps from "../../Redux/Redux.props";
import { ClassSummaryReportProps } from "./ClassSummaryReport.props";
import { RootComponentProps } from "../../root-component.props";

interface Props extends ReduxProps, ClassSummaryReportProps, RootComponentProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    classSummaryReport: state.classSummaryReport,
    getSubjectName: state.getSubjectName,
    getClassDetails: state.getClassDetails,
    getUserRoleDetails: state.userRoleDetails,
    organizationId: state.getSchoolGUID
  };
}

function ClassSummaryReport(props: Props) {
  const { dispatch, classSummaryReport, mpngClassSummarySubject, getSubjectName, getClassDetails, organizationId } = props;
  const { id } = useParams<{ id: string | undefined }>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        LMSService.getClassDetailsWithGuid(
          window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]
        ).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            classDetails(data);
          }
        });
      } catch (err) {
        //do nothing
      }
    };
    fetchData();
    dispatch(updateTabName(""));
    localStorage.setItem("page", "summary");
    // eslint-disable-next-line
  }, []);
  async function classDetails(detail: Response) {
    //@ts-ignore
    let details = JSON.parse(await detail.text());
    dispatch(updateClassGUID(details.classGUID));
    dispatch(
      updateClassDetails({
        classGUID: details.classGUID,
        classId: id,
        className: details.classTitle
      })
    );
  }

  let tabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      title: "Student Roster"
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  useEffect(() => {
    mpngClassSummarySubject.next({
      classId: classSummaryReport.classId,
      className: classSummaryReport.className,
      subject: getSubjectName,
      academicSessionId: classSummaryReport.academicSessionId,
      orgTimezone: classSummaryReport.orgTimezone,
      schoolYear: classSummaryReport.schoolYear,
      schoolGeoState: classSummaryReport.schoolGeoState,
      organizationId
    });
    dispatch(updateActivityIndicator(false));
  }, [window.location.pathname]);

  function isAssessmentTabClicked(tab: string) {
    if (tab !== "classsummary") {
      dispatch(updatePreviousURLForClassProgress(`/classview/${getClassDetails.classGUID}`));
      if (tab === "classprogress") {
        navigate("/classprogress");
      } else {
        dispatch(updateClassViewTab(tab));
        dispatch(updateTabName(tab));
        navigate(`/classview/${getClassDetails.classGUID}`);
      }
    }
  }

  return (
    <>
      <div className="class-nav-tabs">
        {
          <UxTabs
            tabsId="class-tabs"
            activeKey={"classsummary"}
            onTabSelect={(tab: string) => {
              isAssessmentTabClicked(tab);
              localStorage.setItem("page", tab);
              //
            }}
            children={tabs}
            className="pt-2"
            currentKey={"classsummary"}
          />
        }
      </div>
      {window.location.pathname.includes("classsummary") ? (
        <div>
          <div id="classsummary"></div>
        </div>
      ) : (
        <div id="studentprogress"></div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(ClassSummaryReport);
