import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Reducer from "../Reducer";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const persistConfig = {
  key: "ala-admin",
  storage
};

//@ts-ignore
const persistedReducer = persistReducer(persistConfig, Reducer);
let store = createStore(persistedReducer, composeWithDevTools());
let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export { store, persistor };
