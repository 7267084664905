/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { UxTabs } from "@wne-spa/ux-component-library";
import { connect } from "react-redux";
import StudentPlacement from "../ClassView/StudentPlacement";
import { updateBreadCrumbs } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { StudentViewProps } from "./StudentView.props";

interface Props extends ReduxProps, StudentViewProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getDaDetails: state.getDaDetails
  };
}

function StudentView(props: Props): JSX.Element {
  const [tabKey, setTabKey] = useState("studentPlacement");
  const { dispatch, getDaDetails } = props;
  const tabs = [
    {
      eventKey: "studentPlacement",
      title: "Student Placement",
      content: <StudentPlacement orgLevel="district"/> // Unable to tell where this is actually called from, appears to be unused
    }
  ];

  React.useEffect(() => {
    dispatch(
      updateBreadCrumbs({
        crumbs: [
          { path: "/eadmin", name: "Admin" },
          { path: "/studentView", name: "Assessments" }
        ]
      })
    );
    if (getDaDetails.name !== "") {
      // do nothing
    }
  }, [getDaDetails]);

  useEffect(() => {
    localStorage.setItem("page", "studentPlacement");
  }, []);

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
            //
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(StudentView);
