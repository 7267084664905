import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { updateTabName, updateAssessmentTab, updateActivityIndicator, updateStudentsSubtab } from "../../Redux/Action";
import "./Assessment.scss";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import StudentDropdownTab from "../HeaderTabs/StudentDropdownTab";
import AssessmentDropdownTab from "../HeaderTabs/AssessmentDropdownTab";

function mapStateToProps(state: ReduxProps) {
  return {
    getAssessmentTab: state.getAssessmentTab,
    getDistrictLevelFirstTab: state.getDistrictLevelFirstTab,
    getFlagrKeys: state.getFlagrKeys,
    getFirstTab: state.getFirstTab,
    getDaDetails: state.getDaDetails,
    updatePreviousURL: state.updatePreviousURL,
    updateFromSideBarAssessment: state.updateFromSideBarAssessment,
    getStudentSubtab: state.getStudentSubtab,
    getUserRoleId: state.getUserRoleId,
    getOrgLoginCodeAvailable: state.getOrgLoginCodeAvailable,
    getIsInherited: state.getIsInherited,
    getChildDepth: state.getChildDepth
  };
}

interface TabProps {
  title: JSX.Element | string;
  tabClassName?: string;
  eventKey: string;
  content?: JSX.Element | string;
  className?: string;
}
type AssessmentScheduleProps = {
  isFromSideBarAssessment?: boolean;
  dispatch: AppDispatch;
};

interface Props extends AssessmentScheduleProps, ReduxProps {}

function AssessmentSchedule(props: Props) {
  const {
    getAssessmentTab,
    getDistrictLevelFirstTab,
    dispatch,
    updatePreviousURL,
    updateFromSideBarAssessment,
    getStudentSubtab,
    getUserRoleId,
    getOrgLoginCodeAvailable,
    getIsInherited,
    getChildDepth
  } = props;

  const [tabKey, setTabKey] = useState("Assessments");
  const [assessmentTabKey, setAssessmentTabKey] = useState("Schedule");
  const navigate = useNavigate();
  const [showtabs, setShowtabs] = useState(false);
  useEffect(() => {
    if (updateFromSideBarAssessment) {
      setAssessmentTabKey("schedule");
    }

    if (getAssessmentTab === "schedule") {
      navigate("/assessmentschedule");
      setShowtabs(true);
    }
    dispatch(updateActivityIndicator(false));
    return () => {
      //do nothing
    };
  }, [getAssessmentTab, updateFromSideBarAssessment]);

  function setPage(pageName: string) {
    dispatch(updateStudentsSubtab(pageName));
    dispatch(updateTabName("students"));
    setTabKey("students");
    navigate(updatePreviousURL);
  }

  function setAssessmentTab(tabName: string) {
    dispatch(updateAssessmentTab(tabName.toString()));
    dispatch(updateTabName("Assessments"));
    setTabKey("Assessments");
    if (tabName !== "schedule") {
      navigate(updatePreviousURL);
    }
  }

  function isAssessmentTabClicked(tab: string) {
    if (tab === "students" || tab === "Assessments") {
      return;
    }

    navigate(updatePreviousURL);
    dispatch(updateTabName(tab));
  }

  //@ts-ignore
  let institutionsTab: TabProps[] = [
    {
      eventKey: "schools",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={getStudentSubtab} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "licensing",
      title: "Licensing"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  //@ts-ignore
  let schoolTabs: TabProps[] = [
    {
      eventKey: "classes",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={getStudentSubtab} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  let daInstitutionsTabs: TabProps[] = [
    {
      eventKey: "schools",
      title: getDistrictLevelFirstTab
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={getStudentSubtab} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  let daSchoolTabs: TabProps[] = [
    {
      eventKey: "classes",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={getStudentSubtab} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  let saSchoolTabs: TabProps[] = [
    {
      eventKey: "classes",
      title: "Classes"
    },
    {
      eventKey: "students",
      tabClassName: "student-tab",
      title: <StudentDropdownTab onNavClick={setPage} currentView={getStudentSubtab} />
    },
    {
      eventKey: "educators",
      title: "Educators"
    },

    {
      eventKey: "Assessments",
      tabClassName: "assessments-tab",
      title: <AssessmentDropdownTab onNavClick={setAssessmentTab} />
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "AcademicSessions",
      title: "Academic Sessions"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  let sideBarAssessmentTabs = [
    {
      eventKey: "settings",
      title: "Settings"
    },
    {
      eventKey: "schedule",
      title: "Scheduling"
    }
  ];

  let urlPart = updatePreviousURL.split("/")[1];
  const displayTabs = () => {
    if (urlPart === "school") {
      return schoolTabs;
    } else if (urlPart === "dainstitutions") {
      return daInstitutionsTabs;
    } else if (urlPart === "daschool") {
      return daSchoolTabs;
    } else if (urlPart === "saclasslist") {
      return saSchoolTabs;
    } else {
      return institutionsTab;
    }
  };
  let tabs = urlPart === "Institutions" ? institutionsTab : displayTabs();

  if (urlPart === "Institutions") {
    tabs =
      getUserRoleId === 1
        ? tabs
        : //@ts-ignore
          tabs.filter((item) => item.eventKey !== "licensing");
  }

  function isAssessmentScheduleTabClicked(tab: string) {
    if (tab === "settings") {
      navigate(updatePreviousURL);
    }
  }

  return (
    <>
      {!updateFromSideBarAssessment && showtabs && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            isAssessmentTabClicked(tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      )}
      {updateFromSideBarAssessment && (
        <UxTabs
          tabsId="class-tabs"
          activeKey={assessmentTabKey}
          onTabSelect={(tab: string) => {
            isAssessmentScheduleTabClicked(tab);
          }}
          children={sideBarAssessmentTabs}
          className="pt-2"
          currentKey={assessmentTabKey}
        />
      )}
      <div className="class-nav-tabs" style={{ marginTop: "-1.5rem" }}>
        <div className="bar">
          <div className="settingsBar">
            <label className="settingsBarTextSchedule">Assessment Scheduling</label>
          </div>
          <div style={{ marginTop: "10px" }}>
            <div id="assessmentschedule"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(AssessmentSchedule);
