enum AvatarEnum {
  BLUE_ONE_EYED_MONSTER = "blue_one_eyed_monster",
  GOLD_ONE_EYED_MONSTER = "gold_one_eyed_monster",
  GREEN_THREE_EYED_MONSTER = "green_three_eyed_monster",
  LIME_THREE_EYED_MONSTER = "lime_three_eyed_monster",
  MAGENTA_TWO_EYED_MONSTER = "magenta_two_eyed_monster",
  PURPLE_THREE_EYED_MONSTER = "purple_three_eyed_monster",
  TURQUOISE_THREE_EYED_MONSTER = "turquoise_three_eyed_monster",
  VIOLET_TWO_EYED_MONSTER = "violet_two_eyed_monster",
  // K-5
  CHUCK = "svg:Chuck",
  CLYDE = "svg:Clyde",
  CRABBY = "svg:Crabby",
  DAISY = "svg:Daisy",
  FUZZY = "svg:Fuzzy",
  HANK = "svg:Hank",
  HAPPY = "svg:Happy",
  HARRY = "svg:Harry",
  JG = "svg:JG",
  JOE = "svg:Joe",
  JOSEPH = "svg:Joseph",
  JUNE = "svg:June",
  LEGS = "svg:Legs",
  LILY = "svg:Lily",
  MEL = "svg:Mel",
  POPPY = "svg:Poppy",
  ROCKY = "svg:Rocky",
  ROSE = "svg:Rose",
  SID = "svg:Sid",
  SPARKLE = "svg:Sparkle",
  SUNNY = "svg:Sunny",
  TULIP = "svg:Tulip",

  // 6-8
  GADGET = "svg:Gadget",
  HARRIS = "svg:Harris",
  MEG = "svg:Meg",
  MO = "svg:Mo",
  NANO = "svg:Nano",
  OTTO = "svg:Otto",
  RED = "svg:Red",
  SKY = "svg:Sky",
  SMILEY = "svg:Smiley",
  SMOKY = "svg:Smoky",
  TANGO = "svg:Tango",

  // 9-12
  EDGE = "png:Edge",
  GRIZZLY = "png:Grizzly",
  LION = "png:Lion",
  SHARK = "png:Shark",
  MONKEY = "png:Monkey",
  HORSE = "png:Horse",
  PANDA = "png:Panda",
  ELK = "png:Elk",
  FOX = "png:Fox",
  RACCOON = "png:Raccoon",
  GIRAFFE = "png:Giraffe"
}
export default AvatarEnum;
