/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { UxAction } from "@wne-spa/ux-component-library";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import NavDropdown from "react-bootstrap/NavDropdown";
import { makeStyles, Theme } from "@material-ui/core/styles";
import "./WneUserProfile.scss";
import configMessages from "../../helpers/configMessages";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import ViewEducatorUpdateModal from "../EducatorProfile/ViewEducatorUpdateModal";
import { UserService } from "../../services/helpers/user.services";
import ConfirmActions from "../ClassView/ConfirmActions";
import moment from "moment";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import ky from "ky";
import { validEmailAddress } from "../../helpers/regularExpressions";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getActivityIndicator: state.getActivityIndicator,
    getUserId: state.getUserId,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getFlagrKeys: state.getFlagrKeys
  };
}
type WNEUserProfileProps = {
  dispatch: AppDispatch;
};

interface Props extends WNEUserProfileProps, ReduxProps {}

function WNEUserProfile(props: Props) {
  const { t } = useTranslation();
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getUserRoleId, getBreadCrumbsData, getUserId } = props;
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const [showProfile, setShowProfile] = useState(true);
  const [editProfile, setEditProfile] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [wneUserId, setWneUserId] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const roles = ["", "WNE Admin", "WNE Support", "WNE HelpDesk"];
  const [roleId, setRoleId] = useState();
  const [state, setState] = useState({
    firstName: firstName,
    lastName: lastName,
    userName: username,
    email: email
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const borderColorToReset = "#DDDDDD";
  const borderColorFocus = "#4DA7C9";
  const borderColorSuccess = "#3CBCA6";
  const borderColorError = "#F24E45";
  const [firstNameBorder, setFirstNameBorder] = useState(borderColorToReset);
  const [lastNameBorder, setLastNameBorder] = useState(borderColorToReset);
  const [emailBorder, setEmailBorder] = useState(borderColorToReset);
  const [firstNameIssueText, setFirstNameIssueText] = useState("");
  const [lastNameIssueText, setLastNameIssueText] = useState("");
  const [emailIssueText, setEmailIssueText] = useState("");
  const [userNameBorder, setUserNameBorder] = useState(borderColorToReset);
  const [userNameIssueText, setUserNameIssueText] = useState("");
  const dropdownList = ["WNE Admin", "WNE Support", "WNE HelpDesk"];
  const [dropdownTitle, setDropdownTitle] = useState(role);
  const [saveModalMessage, setSaveModalMessage] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  useEffect(() => {
    getWNEUserDetails();
    localStorage.setItem("page", "settings");

    // eslint-disable-next-line
  }, []);

  function getWNEUserDetails() {
    UserService.getWNEUsersProfileWithGuid(id).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        gotResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
      }
    });
  }

  async function gotResponse(responsedata: Response) {
    let allData = await responsedata.json();
    dispatch(updatePageTitle(`- Users - ${allData.firstName} ${allData.lastName}`));
    dispatch(updateActivityIndicator(false));
    dispatch(
      updateBreadCrumbs({
        crumbs: [
          { path: `/users/`, name: "Users" },
          {
            path: `/wneuser/${2}`,
            name: `${allData.firstName} ${allData.lastName}`
          }
        ]
      })
    );
    setFirstName(allData.firstName);
    setLastName(allData.lastName);
    setUsername(allData.loginName);
    setEmail(allData.email);
    setWneUserId(allData.userID);
    //@ts-ignore
    setRoleId(allData.roleID);
    //@ts-ignore

    if (allData.roleID === 7) {
      setRole(roles[3]);
      setDropdownTitle(roles[3]);
    } else {
      setRole(roles[allData.roleID]);
      setDropdownTitle(roles[allData.roleID]);
    }
    //@ts-ignore
    setState({
      firstName: allData.firstName,
      lastName: allData.lastName,
      userName: allData.loginName,
      email: allData.email
    });
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>, inputType: string) {
    if (event.target.value.charAt(0) !== " ") {
      setState({ ...state, [inputType]: event.target.value });
    }
  }

  function handleUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value.includes(" ")) {
      setState({ ...state, userName: event.target.value });
    }
  }

  function handleEmailTextChange(event: React.ChangeEvent<HTMLInputElement>, inputType: string) {
    if (event.target.value.charAt(0) !== " ") {
      event.target.value = event.target.value.replace(" ", "").trim();
      setState({ ...state, [inputType]: event.target.value });
    }
  }

  function resetPasswordConfirmation() {
    setShowConfirmationModal(true);
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  function resetPassword() {
    sendResetPasswordLink();
  }

  async function sendResetPasswordLink() {
    dispatch(updateActivityIndicator(true));
    setShowConfirmationModal(false);
    try {
      await UserService.resetPasswordWithGuid(id).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setShowToast({
            val: true,
            msg: configMessages.educatorPasswordReset
          });
          dispatch(updateActivityIndicator(false));
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  function cancel() {
    setState({
      firstName: firstName,
      lastName: lastName,
      userName: username,
      email: email
    });
    setFirstNameIssueText("");
    setLastNameIssueText("");
    setEmailIssueText("");
    setUserNameIssueText("");
    setFirstNameBorder(borderColorToReset);
    setLastNameBorder(borderColorToReset);
    setUserNameBorder(borderColorToReset);
    setEmailBorder(borderColorToReset);
    setEditProfile(false);
    setEditDetails(false);
    setShowProfile(true);
    setDropdownTitle(role);
    localStorage.setItem("page", "settings");
  }

  function saveWNEUser() {
    validateFirstName();
    validateLastName();
    validateEmail();
    validateUserName();
    save();
  }

  function validateUserName() {
    if (state.userName !== "") {
      setUserNameBorder(borderColorSuccess);
      setUserNameIssueText("");
    } else {
      setUserNameBorder(borderColorError);
      setUserNameIssueText(configMessages.uniqueNameValidation);
    }
  }

  function validateFirstName() {
    if (state.firstName !== "") {
      setFirstNameBorder(borderColorSuccess);
      setFirstNameIssueText("");
    } else {
      setFirstNameBorder(borderColorError);
      setFirstNameIssueText(configMessages.WNEfirstNameValidation);
    }
  }

  function validateLastName() {
    if (state.lastName !== "") {
      setLastNameBorder(borderColorSuccess);
      setLastNameIssueText("");
    } else {
      setLastNameBorder(borderColorError);
      setLastNameIssueText(configMessages.WNElastNameValidation);
    }
  }

  function validateEmail() {
    if (
      validEmailAddress.test(state.email) &&
      (state.email.toLowerCase().includes("@edgenuity.com") ||
        state.email.toLowerCase().includes("@weldnorth.com") ||
        state.email.toLowerCase().includes("@weldnorthed.com") ||
        state.email.toLowerCase().includes("@imaginelearning.com"))
    ) {
      setEmailIssueText("");
      setEmailBorder(borderColorSuccess);
    } else {
      setEmailIssueText(configMessages.WNEemailValidation);
      setEmailBorder(borderColorError);
    }
  }

  function save() {
    if (
      state.firstName !== "" &&
      state.lastName !== "" &&
      state.userName !== "" &&
      validEmailAddress.test(state.email) &&
      (state.email.toLowerCase().includes("@edgenuity.com") ||
        state.email.toLowerCase().includes("@weldnorth.com") ||
        state.email.toLowerCase().includes("@weldnorthed.com") ||
        state.email.toLowerCase().includes("@imaginelearning.com"))
    ) {
      dispatch(updateActivityIndicator(true));
      saveEducatorDetais();
    }
  }

  async function saveEducatorDetais() {
    const clientDateTime = moment().format();
    try {
      const objJson = {
        UserGuid: id,
        loginName: state.userName,
        FirstName: state.firstName,
        LastName: state.lastName,
        Email: state.email,
        RoleID: roleId,
        ClientDateTime: clientDateTime
      };
      UserService.updateWNEUserProfile(objJson).then(
        ({ status, data }: { status: number | boolean; data: Response | ky.HTTPError }) => {
          if (status === 200) {
            let breadcrumb = getBreadCrumbsData.crumbs;
            breadcrumb[breadcrumb.length - 1].name = `${state.firstName} ${state.lastName}`;
            dispatch(updateBreadCrumbs({ crumbs: breadcrumb }));
            setShowToast({ val: true, msg: configMessages.wneuserupdatemsg });

            setFirstName(state.firstName);
            setLastName(state.lastName);
            setUsername(state.userName);
            setEmail(state.email);
            setEditProfile(false);
            setShowProfile(true);
            dispatch(updateActivityIndicator(false));
            setFirstNameBorder(borderColorToReset);
            setLastNameBorder(borderColorToReset);
            setUserNameBorder(borderColorToReset);
            setEmailBorder(borderColorToReset);
            localStorage.setItem("page", "settings");
          } else {
            updateWneUserErrorResponse(data as ky.HTTPError);
          }
        }
      );
    } catch (err) {
      setState({
        firstName: firstName,
        lastName: lastName,
        userName: username,
        email: email
      });
      setEditProfile(false);
      setShowProfile(true);
      dispatch(updateActivityIndicator(false));
    }
  }

  async function updateWneUserErrorResponse(responsedata: ky.HTTPError) {
    let responseStudents = await responsedata.response.json();
    dispatch(updateActivityIndicator(false));
    const errMsg = responseStudents.message;
    if (errMsg !== undefined) {
      if (errMsg.includes("Loginname already exists.")) {
        setUserNameBorder(borderColorError);
        setUserNameIssueText(configMessages.uniqueNameValidation);
      }
      if (errMsg.includes("Please enter a valid username with allowed special characters.")) {
        setUserNameBorder(borderColorError);
        setUserNameIssueText(configMessages.AllowSpacialChar);
      }
      if (errMsg.includes("Email address already exists.")) {
        setEmailIssueText(configMessages.uniqueEmailValidation);
        setEmailBorder(borderColorError);
        dispatch(updateActivityIndicator(false));
      }
    }
    if (responseStudents.errors.Email[0] === "Please enter a valid email address.") {
      setEmailIssueText(configMessages.WNEemailValidation);
      setEmailBorder(borderColorError);
      dispatch(updateActivityIndicator(false));
    }
  }

  const saveModalMessageFunc = () => {
    if (dropdownTitle === "WNE Admin") {
      setSaveModalMessage(configMessages.wneHelpDesktowneAdmin);
    } else {
      setSaveModalMessage(configMessages.wneHelpDesktowneSupport);
    }
  };

  function saveDetails() {
    if (dropdownTitle !== role) {
      if (role === "WNE Admin") {
        if (dropdownTitle === "WNE Support") {
          setSaveModalMessage(configMessages.wneAdmintowneSupport);
        } else {
          setSaveModalMessage(configMessages.wneAdmintowneHelpDesk);
        }
      } else if (role === "WNE Support") {
        if (dropdownTitle === "WNE Admin") {
          setSaveModalMessage(configMessages.wneSupporttowneAdmin);
        } else {
          setSaveModalMessage(configMessages.wneSupporttowneHelpDesk);
        }
      } else {
        saveModalMessageFunc();
      }
      setSaveModal(true);
    }
  }

  function closeModal() {
    setSaveModal(false);
    setSaveModalMessage("");
  }

  async function updateType() {
    setSaveModal(false);
    dispatch(updateActivityIndicator(true));
    const ids = {
      "WNE Admin": 1,
      "WNE Support": 2,
      "WNE HelpDesk": 7
    };
    const clientDateTime = moment().format();
    try {
      //@ts-ignore
      const jsonObj = {
        UserGuid: id,
        //@ts-ignore
        RoleID: ids[dropdownTitle],
        ClientDateTime: clientDateTime
      };
      await UserService.updateWNEUserType(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          getWNEUserDetails();
          setShowToast({ val: true, msg: configMessages.wneuserupdatemsg });
          setEditProfile(false);
          setEditDetails(false);
          setShowProfile(true);
          localStorage.setItem("page", "settings");
        } else {
          alert("Error while updating WNE User detials");
          cancel();
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      alert("Error while updating Educator detials");
      cancel();
      dispatch(updateActivityIndicator(false));
    }
  }

  return (
    <div className="px-4 viewwneprofilescrollable">
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      {showProfile ? (
        <div>
          <div className="profile">
            <div>
              <span className="headings"> WNE User Profile </span>
              <UxAction
                callback={() => {
                  setEditProfile(true);
                  setShowProfile(false);
                  localStorage.setItem("page", "wneUserProfileEditForm");
                }}
                text={t("Edit")}
                className="editButton"
                size={"lg"}
              />
            </div>
            <div>
              <p className="tagname"> Name </p>
              {state.lastName ? (
                <p className="tagvalue">
                  {" "}
                  {state.firstName} {state.lastName}{" "}
                </p>
              ) : null}
            </div>
            <div>
              <p className="tagname"> Username </p>
              <p className="tagvalue"> {username} </p>
            </div>
            <div>
              <p className="tagname"> Password </p>
              <div className="disinline" style={{ display: "flex" }}>
                <p className="passwordvalue"> *********** </p>
              </div>
            </div>
            <div>
              <p className="tagname"> Email </p>
              <p className="tagvalue">{state.email} </p>
            </div>
          </div>
          <div className="profile mt-3">
            <div>
              <span className="headings">WNE User Details</span>
              {getUserRoleId === 1 || getUserRoleId === 7 ? (
                <UxAction
                  callback={() => {
                    setEditDetails(true);
                    setShowProfile(false);
                    localStorage.setItem("page", "wneUserDetailsEditForm");
                  }}
                  text={t("Edit")}
                  className="editButton"
                  size={"lg"}
                />
              ) : null}
            </div>
            <div>
              <p className="tagname"> Type </p>
              <p className="tagvalue"> {role} </p>
            </div>
          </div>
        </div>
      ) : null}
      {editProfile ? (
        <div>
          <div>
            <span className="headings">Edit WNE User Profile</span>
          </div>
          <div className="labelInputHolder" style={{ marginTop: "20px" }}>
            <label className="labelForInput">First Name *</label>
            <div
              style={{
                border: `1.3px solid ${firstNameBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                className="eTextInput"
                maxLength={255}
                onClick={() => {
                  setFirstNameIssueText("");
                  setFirstNameBorder(borderColorFocus);
                }}
                value={state.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "firstName")}
                onBlur={() => {
                  setFirstNameBorder(borderColorToReset);
                }}
              />
            </div>
            <label className="labelForIssue">{firstNameIssueText}</label>
          </div>
          <div className="labelInputHolder">
            <label className="labelForInput">Last Name *</label>
            <div
              style={{
                border: `1.3px solid ${lastNameBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                className="eTextInput"
                maxLength={255}
                onClick={() => {
                  setLastNameIssueText("");
                  setLastNameBorder(borderColorFocus);
                }}
                value={state.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e, "lastName")}
                onBlur={() => {
                  setLastNameBorder(borderColorToReset);
                }}
              />
            </div>
            <label className="labelForIssue">{lastNameIssueText}</label>
          </div>
          <div className="labelInputHolder">
            <label className="labelForInput">Username *</label>
            <div
              style={{
                border: `1.3px solid ${userNameBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                className="eTextInput"
                maxLength={255}
                onClick={() => {
                  setUserNameIssueText("");
                  setUserNameBorder(borderColorFocus);
                }}
                value={state.userName}
                onChange={handleUserNameChange}
                onBlur={() => {
                  setUserNameBorder(borderColorToReset);
                }}
                disabled={getUserId === wneUserId}
              />
            </div>
            <label className="labelForIssue">{userNameIssueText}</label>
          </div>
          <div className="labelInputHolder">
            <label className="labelForInput">Password</label>
            <div>
              <p
                onClick={() => {
                  resetPasswordConfirmation();
                }}
                className="passwordedit"
              >
                Reset Password via Email{" "}
              </p>
            </div>
          </div>
          <div className="labelInputHolder">
            <label className="labelForInput">Email *</label>
            <div
              style={{
                border: `1.3px solid ${emailBorder}`,
                borderRadius: "3px"
              }}
            >
              <input
                maxLength={100}
                onClick={() => {
                  setEmailIssueText("");
                  setEmailBorder(borderColorFocus);
                }}
                value={state.email}
                className="eTextInput"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailTextChange(e, "email")}
                onBlur={() => {
                  setEmailBorder(borderColorToReset);
                }}
              />
            </div>
            <label className="labelForIssue">{emailIssueText}</label>
          </div>
          <div style={{ marginTop: "30px" }}>
            <UxAction callback={() => cancel()} text={t("Cancel")} className="cancelButton" size={"lg"} />
            {firstName !== state.firstName ||
            lastName !== state.lastName ||
            username !== state.userName ||
            email !== state.email ? (
              <UxAction callback={() => saveWNEUser()} text={t("Save")} className="saveButton" size={"lg"} />
            ) : (
              <UxAction
                callback={() => {
                  //do nothing
                }}
                text={t("Save")}
                className="saveButtonDisabled"
                size={"lg"}
              />
            )}
          </div>
        </div>
      ) : null}

      {editDetails ? (
        <div>
          <div>
            <span className="headings"> Edit WNE User Details </span>
          </div>
          <div>
            <div className="eViewLabelInputHolder">
              <label className="labelForInput typeMargin">Type *</label>
              <div style={{ border: "1.3px solid #dee2e6", borderRadius: "3px" }}>
                <NavDropdown id="eViewSelectUserType" title={dropdownTitle}>
                  {dropdownList.map((row) => {
                    return (
                      <NavDropdown.Item
                        className="supportText labelForInput"
                        eventKey="active"
                        onClick={() => {
                          setDropdownTitle(row);
                        }}
                      >
                        {row}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "70px" }}>
            <UxAction callback={() => cancel()} text={t("Cancel")} className="cancelButton" size={"lg"} />
            {dropdownTitle !== role ? (
              <UxAction callback={() => saveDetails()} text={t("Save")} className="saveButton" size={"lg"} />
            ) : (
              <UxAction
                callback={() => {
                  //do nothing
                }}
                text={t("Save")}
                className="saveButtonDisabled"
                size={"lg"}
              />
            )}
          </div>
        </div>
      ) : null}

      {showConfirmationModal ? (
        <ConfirmActions
          title="Confirm Reset?"
          message={configMessages.educatorResetPassword}
          close={closeConfirmationModal}
          callback={resetPassword}
        />
      ) : null}
      {saveModal ? (
        <ViewEducatorUpdateModal
          show={saveModal}
          close={closeModal}
          message={saveModalMessage}
          save={() => {
            updateType();
          }}
        />
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps)(WNEUserProfile);
