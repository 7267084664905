import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { useParams, useNavigate } from "react-router-dom";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import StudentSetting from "./StudentSetting";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { StudentProfileProps } from "./StudentProfile.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getBreadCrumbsData: state.getBreadCrumbsData,
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails,
    getFlagrKeys: state.getFlagrKeys,
    userRoleDetails: state.userRoleDetails
  };
}
interface Props extends ReduxProps, StudentProfileProps {}

function StudentsProfile(props: Props): JSX.Element {
  const { dispatch, getUserRoleId, getDaDetails, getBreadCrumbsData, getFlagrKeys } = props;
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [tabKey, setTabKey] = useState("settings");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.studentDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setEducatorDetailsData(data, uid);
          } else {
            // do nothing
          }
        });
      } catch (err) {
        //do nothing
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBreadCrumbForSAWithSingleSchool = (details, uid: string) => {
    let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
    if (index > -1) {
      const breadcrumb = {
        crumbs: [
          { path: `/saclasses/${getDaDetails.id}`, name: "Classes" },
          {
            path: getBreadCrumbsData.crumbs[index].path,
            name: getBreadCrumbsData.crumbs[index].name
          },
          {
            path: `/viewstudent/${uid}`,
            name: `${details.firstName} ${details.lastName}`
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      //@ts-ignore
      dispatch(updatePageTitle(`- Classes - ${details.firstName} ${details.lastName}`));
    } else {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/sausers/${getDaDetails.id}`, name: "Users" },
          {
            path: `/viewstudent/${uid}`,
            name: `${details.firstName} ${details.lastName}`
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      //@ts-ignore
      dispatch(updatePageTitle(`- Users - ${details.firstName} ${details.lastName}`));
    }
  };
  const updateBreadCrumbForTeacherWithSingleSchool = (details, uid: string) => {
    let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
    if (index > -1) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          //@ts-ignore
          { path: `/teacherclasses/${details.orgGuid}`, name: "Classes" },
          {
            path: getBreadCrumbsData.crumbs[index].path,
            name: getBreadCrumbsData.crumbs[index].name
          },
          {
            path: `/viewstudent/${id}`,
            name: `${details.firstName} ${details.lastName}`
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      dispatch(updatePageTitle(`- Classes - ${details.firstName} ${details.lastName}`));
    } else {
      const breadcrumb = {
        crumbs: [
          { path: `/tstudents/${getDaDetails.id}`, name: "Students" },
          {
            path: `/viewstudent/${uid}`,
            name: `${details.firstName} ${details.lastName}`
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      //@ts-ignore
      dispatch(updatePageTitle(`- Students - ${details.firstName} ${details.lastName}`));
    }
  };

  async function setEducatorDetailsData(responsedata: Response, uid: string) {
    let details = await responsedata.json();
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      //@ts-ignore
      setAdminBreadCrumb(details.firstName, details.lastName, uid);
    } else if (getUserRoleId === 3) {
      //@ts-ignore
      setDABreadCrumb(details.firstName, details.lastName, uid);
    } else if (getUserRoleId === 4 && getDaDetails.type === 2) {
      //@ts-ignore
      setMSABreadCrumb(details.firstName, details.lastName, uid);
    } else if (getUserRoleId === 4 && getDaDetails.type === 1) {
      updateBreadCrumbForSAWithSingleSchool(details, uid);
    } else if (getUserRoleId === 5 && getDaDetails.type === 1) {
      updateBreadCrumbForTeacherWithSingleSchool(details, uid);
    } else if (getUserRoleId === 5 && getDaDetails.type === 2) {
      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
      if (index > -1) {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            { path: `/teacherschools/${getDaDetails.id}`, name: "Schools" },
            {
              path: `/teacherclasses/${details.orgGuid}`,
              name: details.schoolName
            },
            {
              path: getBreadCrumbsData.crumbs[index].path,
              name: getBreadCrumbsData.crumbs[index].name
            },
            {
              path: `/viewstudent/${id}`,
              name: `${details.firstName} ${details.lastName}`
            }
          ]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updatePageTitle(`- Schools - ${details.firstName} ${details.lastName}`));
      } else {
        const breadcrumb = {
          crumbs: [
            { path: `/tstudents/${getDaDetails.id}`, name: "Students" },
            {
              path: `/viewstudent/${id}`,
              name: `${details.firstName} ${details.lastName}`
            }
          ]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        //@ts-ignore
        dispatch(updatePageTitle(`- Students - ${details.firstName} ${details.lastName}`));
      }
    }
  }

  const updatePageTitleFunc = () => {
    if (getBreadCrumbsData.crumbs[0].name !== "") {
      if (getDaDetails.type === 1) {
        dispatch(
          updatePageTitle(`- Schools - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
        );
      } else {
        dispatch(
          updatePageTitle(`- Districts - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
        );
      }
    }
  };

  useEffect(() => {
    if ((getDaDetails.type === 1 || getDaDetails.type === 2) && getUserRoleId === 3) {
      updatePageTitleFunc();
    }
    if (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) {
      if (getBreadCrumbsData.crumbs.length > 1) {
        if (getBreadCrumbsData.crumbs[0].name !== "") {
          dispatch(
            updatePageTitle(`- Customers - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [getDaDetails, getBreadCrumbsData]);

  async function setMSABreadCrumb(fname: string, lname: string, uid: number) {
    try {
      const oid = id.substring(0, id.lastIndexOf("_"));
      let apiObj = { rid: oid, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setMSABreadCrumbData(data, fname, lname, oid, uid);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function setMSABreadCrumbData(responsedata: Response, fname: string, lname: string, oid: string, uid: number) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    //@ts-ignore
    dispatch(updatePageTitle(`- Schools - ${fname} ${lname}`));
    //@ts-ignore
    const breadcrumb = {
      crumbs: [
        { path: `/saschools/${getDaDetails.id}`, name: "Schools" },

        //@ts-ignore
        { path: `/saclasslist/${oid}`, name: `${details.name}` }
      ]
    };
    let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
    if (index > -1) {
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: getBreadCrumbsData.crumbs[index].path,
        name: getBreadCrumbsData.crumbs[index].name
      });
    }
    //@ts-ignore
    breadcrumb.crumbs.push({
      path: `/svieweducator/${uid}`,
      name: `${fname} ${lname}`
    });
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
  }

  async function setDABreadCrumb(fname: string, lname: string, uid: number) {
    try {
      const oid = id.substring(0, id.lastIndexOf("_"));
      let apiObj = { rid: getDaDetails.id, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setDABreadCrumbData(data, fname, lname, uid);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  const updateDABreadCrumbsFunc = (fname: string, lname: string, uid: number, details) => {
    if (details.childDepth === 0) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          //@ts-ignore
          { path: `/daschool/${details.orgGUID}`, name: details.name }
        ]
      };
      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
      if (index > -1) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/cvieweducator/${uid}`,
        name: `${fname} ${lname}`
      });
      //@ts-ignore
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          //@ts-ignore
          { path: `/dainstitutions/${details.orgGUID}`, name: details.name }
        ]
      };
      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
      if (index > -1) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/cvieweducator/${uid}`,
        name: `${fname} ${lname}`
      });
      //@ts-ignore
      dispatch(updateBreadCrumbs(breadcrumb));
    }
  };

  async function setDABreadCrumbData(responsedata: Response, fname: string, lname: string, uid: number) {
    let details = await responsedata.text();
    details = JSON.parse(details);
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      updateDABreadCrumbsFunc();
    } else {
      const breadcrumb = { crumbs: [] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${x.orgGUID}`,
          //@ts-ignore
          name: x.name
        });
      }
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/daschool/${details.orgGUID}`,
          //@ts-ignore
          name: details.name
        });
      } else {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${details.orgGUID}`,
          //@ts-ignore
          name: details.name
        });
      }

      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
      if (index > -1) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/dvieweducator/${uid}`,
        //@ts-ignore
        name: `${fname} ${lname}`
      });
      dispatch(updateActivityIndicator(false));
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    dispatch(updateActivityIndicator(false));
  }

  async function setAdminBreadCrumb(fname: string, lname: string, uid: number) {
    try {
      const oid = id.substring(0, id.lastIndexOf("_"));
      let apiObj = { guid: oid };
      AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          setAdminBreadCrumbData(data, fname, lname, uid);
        } else {
          const breadcrumb = {
            crumbs: [{ path: "/customers", name: "Customers" }]
          };
          dispatch(updateActivityIndicator(false));
          dispatch(updateBreadCrumbs(breadcrumb));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  const updateAdminBreadCrumbFunc = (fname: string, lname: string, uid: number, details) => {
    if (details.childDepth === 0) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/customers/`, name: "Customers" },
          //@ts-ignore
          { path: `/school/${details.orgGUID}`, name: details.name }
        ]
      };
      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classview"));
      if (index > -1) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/cvieweducator/${uid}`,
        name: `${fname} ${lname}`
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    } else {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/customers/`, name: "Customers" },
          //@ts-ignore
          { path: `/institutions/${details.orgGUID}`, name: details.name }
        ]
      };
      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));
      if (index > -1) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        path: `/cvieweducator/${uid}`,
        name: `${fname} ${lname}`
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
  };

  async function setAdminBreadCrumbData(responsedata: Response, fname: string, lname: string, uid: number) {
    let details = await responsedata.json();
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      updateAdminBreadCrumbFunc();
    } else {
      const breadcrumb = {
        crumbs: [{ path: `/customers/`, name: "Customers" }]
      };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/institutions/${x.orgGUID}`,
          name: x.name
        });
      }
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/school/${details.orgGUID}`,
          name: details.name
        });
      } else {
        //@ts-ignore
        breadcrumb.crumbs.push({
          path: `/institutions/${details.orgGUID}`,
          name: details.name
        });
      }

      let index = getBreadCrumbsData.crumbs.findIndex((data) => data.path.includes("classsummary"));

      if (index >= 0) {
        breadcrumb.crumbs.push({
          path: getBreadCrumbsData.crumbs[index].path,
          name: getBreadCrumbsData.crumbs[index].name
        });
      }
      // @ts-ignore
      breadcrumb.crumbs.push({
        path: `/cvieweducator/${uid}`,
        name: `${fname} ${lname}`
      });

      dispatch(updateBreadCrumbs(breadcrumb));
    }
    dispatch(updateActivityIndicator(false));
  }

  let children = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <StudentSetting />
    },
    {
      eventKey: "classes",
      title: "Classes"
    },
    {
      eventKey: "studentprogress",
      title: "Student Progress"
    }
  ];

  children = !getFlagrKeys["ShowStudentProgressTab"]
    ? children.filter((x) => x.eventKey !== "studentprogress")
    : children;

  children = !getFlagrKeys["ShowStudentClassesTab"] ? children.filter((data) => data.eventKey !== "classes") : children;

  function onTabClick(tab: string) {
    if (tab === "studentprogress") {
      navigate(`/studentprogress/${window.location.pathname.split("/").reverse()[0]}`);
    } else if (tab === "classes") {
      navigate(`/studentclasses/${window.location.pathname.split("/").reverse()[0]}`);
    }
  }

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
            onTabClick(tab);
          }}
          children={children}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(StudentsProfile);
