// eslint-disable-next-line prettier/prettier
import {
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import {
  UxIcon,
  UxIconEnum,
  UxSizeEnum,
  UxIconLibraryEnum
} from "@wne-spa/ux-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { HoverToolTip } from "@wne/mpng-components";
import { CustomKebabMenuDataProps, ColumnDetailsProps } from "./student-placement-table.props";
import { IStudentPlacementDetails } from "../StudentPlacement.props";
import "./student-placement-table.scss";

interface IStudentPlacementTable {
  columnDetails: ColumnDetailsProps[];
  rowData: Array<IStudentPlacementDetails>;
  showColumnCheckbox: boolean;
  emptyTableText?: string | JSX.Element;
  checkboxRef: string;
  viewChild?: (x) => void;
  selected: Array<string | number>;
  setSelected: React.Dispatch<React.SetStateAction<Array<string | number>>>;
  customKebabMenuData?: CustomKebabMenuDataProps[];
  sortStates?: { [key: string]: boolean | null };
  updateSortStates: (key: string, value: boolean) => void;
  mtableHeight?: number;
  showHelpIconFlag?: boolean;
  hasRetestIcon?: boolean;
}

export default function StudentPlacementTable(props: IStudentPlacementTable) {
  const {
    columnDetails,
    rowData,
    showColumnCheckbox,
    emptyTableText,
    checkboxRef,
    viewChild,
    selected,
    setSelected,
    mtableHeight,
    customKebabMenuData,
    sortStates,
    updateSortStates,
    showHelpIconFlag,
    hasRetestIcon
  } = props;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))(Tooltip);

  const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    container: {
      maxHeight: `${mtableHeight}px !important`,
      marginTop: "0px !important"
    },
    tableCell: {
      minWidth: "20px !important",
      maxWidth: "350px !important"
    }
  });
  const classes = useStyles();
  function isSelected(name: string) {
    return selected.indexOf(name) !== -1;
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = rowData.filter(
                            (item: IStudentPlacementDetails) =>
                              item["subject"].toLowerCase() != "sel" &&
                              item["subject"].toLowerCase() != "science" &&
                              item["subject"].toLowerCase() != "purpose"
                          )
                          .map((n: IStudentPlacementDetails) => n[checkboxRef]);
      setSelected(newSelecteds);
      return;
    }
    uncheckedAllData();
    setSelected([]);
  }

  const uncheckedAllData = () => {
    localStorage.setItem("ala:unCheckAll", `${""}`);
    const newIds = rowData
    .filter(
      (item: IStudentPlacementDetails) =>
        item["subject"].toLowerCase() != "sel" && item["subject"].toLowerCase() != "purpose"
    )
    .map((n: IStudentPlacementDetails) => n[checkboxRef]);
    localStorage.setItem("stu:unCheckAll", `${newIds}`);
  };

  function renderStudentPlacementRetestToolTip() {
    return (
      <text>
        This student is currently in the <br />
        process of retesting.
      </text>
    );
  }

  const handleClick = (name: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected as string[], name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat((selected as string[]).slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat((selected as string[]).slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        (selected as string[]).slice(0, selectedIndex),
        (selected as string[]).slice(selectedIndex + 1)
      );
    }

    uncheckedData(selectedIndex, name);
    setSelected(newSelected);
  };

  const uncheckedData = (selectedIndex: number, name: string) => {
    localStorage.setItem("ala:unchecked", `${""}`);

    if (selectedIndex !== -1) {
      localStorage.setItem("stu:unchecked", `${name}`);
    } else {
      localStorage.setItem("stu:unchecked", `${""}`);
    }
  };

  const showSortFunc = (data: string) => {
    if (Object.keys(sortStates).includes(data)) {
      
      if (sortStates[data] === null) {
        return (
          <i
            onClick={() => updateSortStates(data, true)}
            className="fa fa-sort sortIconDefault"
          ></i>
        );
      } else {
        return (
          <i
            onClick={() => updateSortStates(data, !sortStates[data])}
            className={`fa ${sortStates[data] ? "fa-sort-asc sortIconUp" : "fa-sort-desc sortIconDown"}`}
          />
        );
      }
    } else {
      return null;
    }
  };

  const renderCellData = (row: IStudentPlacementDetails, index: number) => {
    const dataWrapper = columnDetails[index].displayColumnData;
    if (dataWrapper) {
      return dataWrapper(row);
    } else {
      return row[columnDetails[index].dataName[0]] || "-";
    }
  };

  const muiTableCellData = (
    row: IStudentPlacementDetails,
    index: number,
    getIndex: number,
    labelId: string,
    isItemSelected: boolean
  ) => {
    if (index === 0) {
      return (
        <TableCell
          width="20px"
          component="th"
          scope="row"
          key={index}
          className={`student-placement-first-column ${classes.tableCell} ${showColumnCheckbox ? "check-box-cell" : ""}`}
        >
          <div className="flexCellContent" style={{ justifyContent: columnDetails[index].alignment }}>
            {showColumnCheckbox && (
              <label
                style={
                  row.subject.toLowerCase() === "sel" ||
                  row.subject.toLowerCase() === "purpose" ||
                  row.subject.toLowerCase() === "science"
                    ? { visibility: "hidden" }
                    : {}
                }
              >
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(e) => {
                    handleClick(row[checkboxRef], e);
                  }}
                  size="small"
                  data-testid={`checkbox-column-${getIndex}-${index}`}
                />
              </label>
            )}

            {(row.isReset && row.source === "MyPath") && (
              <LightTooltip
                placement="bottom-start"
                title={renderStudentPlacementRetestToolTip()}
                enterDelay={500}
                leaveDelay={200}
                id="retestMsg"
              >
                <div>
                  <UxIcon
                    icon={UxIconEnum.SYNC_ALT}
                    size={UxSizeEnum.MEDIUM}
                    library={UxIconLibraryEnum.REGULAR}
                    className="retestIcon"
                  />
                </div>
              </LightTooltip>
            )}

            {
              <label
                data-testid={`label-row-text-${getIndex}`}
                onClick={
                  viewChild
                    ? () => {
                        viewChild(row);
                      }
                    : () => {
                        //do nothing
                      }
                }
                className={`ellipsis tBlueHover ${hasRetestIcon && (!row.isReset || row.source !== "MyPath") ? "retestIconPadding" : ""}`}
                id={labelId}
              >
                {renderCellData(row, index)}
              </label>
            }
          </div>
        </TableCell>
      );
    } else if (index > 0 && index < columnDetails.length - 1) {
      return (
        <TableCell align="left" data-testid={`tablecell-licenseTableflag-${getIndex}`} key={index}>
          <div className="flexCellContent" style={{ justifyContent: columnDetails[index].alignment }}>
            <label
              className={`ellipsis noCursor 
                ${(row.isReset && row.source === "MyPath") &&
                (columnDetails[index].dataName[0] === "placementGrade" ||
                  columnDetails[index].dataName[0] === "source" ||
                  columnDetails[index].dataName[0] === "placementDate")
                  ? " greyRowText"
                  : " rowText"} 
                ${columnDetails[index].alignment === "right" && columnDetails[index].isSorted ? " sortIconPadding" : ""} 
              `}
              id={labelId}
            >
              {renderCellData(row, index)}
            </label>
          </div>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          id="kebabCell"
          className={`rowText noCursor ${index === columnDetails.length - 1 ? "student-placement-last-column" : ""}`}
          align="left"
          key={index}
          data-testid={`table-cell-with-customkebebmenu-${getIndex}`}
        >
          <div className="kebab-icon-holder">
            <Select
              value={""}
              IconComponent={() => (
                <FontAwesomeIcon icon={faEllipsisH as any} rotation={90} className="kebab-icon"/>
              )}
              data-testid={`select-with-showcustom-kebabmenu-${getIndex}`}
            >
              {customKebabMenuData.map((customData: CustomKebabMenuDataProps, index: number) => {
                if (customData.showMenuItem(row)) {
                  return (
                    <MenuItem
                      className="tableStatusMenu kebab-menu-item"
                      onClick={() => {
                        customData.callback(row);
                      }}
                      key={index}
                    >
                      {customData.getDisplayValue(row)}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </div>
        </TableCell>
      );
    }
  };

  return (
    <div className={`bg-white student-placement-table-wrapper p-3 bg-white`} data-testid="table-wrapper">
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader aria-label="sticky table" style={{ position: "relative" }}>
          <TableHead>
            <TableRow role="checkbox">
              {columnDetails.map((data: ColumnDetailsProps, index: number) => {
                return index === 0 ? (
                  <TableCell
                    className={`column-header-text ${showColumnCheckbox ? "check-box-cell" : ""}`}
                    align="left"
                    style={{ zIndex: 5 }}
                    data-testid="table-cell-wrapper"
                    key={index}
                  >
                    <div className="flexCellContent" style={{ justifyContent: data.alignment }}>
                      {showColumnCheckbox ? (
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < rowData.length &&
                            (selected.length !==
                                rowData.filter(
                                  (item: IStudentPlacementDetails) =>
                                    item["subject"].toLowerCase() != "sel" &&
                                    item["subject"].toLowerCase() != "purpose" &&
                                    item.subject.toLowerCase() != "science"
                                ).length)
                          }
                          checked={
                            rowData.length > 0 &&
                            (selected.length ===
                                  rowData.filter(
                                    (item: IStudentPlacementDetails) =>
                                      item["subject"].toLowerCase() != "sel" &&
                                      item["subject"].toLowerCase() != "purpose" &&
                                      item.subject.toLowerCase() != "science"
                                  ).length &&
                                rowData.filter(
                                  (item: IStudentPlacementDetails) =>
                                    item.subject.toLowerCase() == "math" || item.subject.toLowerCase() == "reading"
                                ).length > 0)
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{ "aria-label": "select all names" }}
                          size="small"
                          data-testid={`checkbox-table-${index}`}
                        />
                      ) : null}
                      <label className={`${hasRetestIcon ? "retestIconPadding" : ""}`}>
                        {data.columnName}
                      </label>
                      {rowData.length > 0 && sortStates && updateSortStates ? showSortFunc(data.columnName) : null}
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    className={`column-header-text ${index === columnDetails.length - 1 ? "student-placement-last-column" : ""}`}
                    align="left"
                    key={index}
                  >
                    <div className="flexCellContent" style={{ justifyContent: data.alignment }}>
                      <label>{data.columnName}</label>

                      {data.toolTipContent && (
                        <HoverToolTip help={data.toolTipContent} className={data.toolTipClass || ""}>
                          <UxIcon icon={UxIconEnum.INFO_CIRCLE} className="column-info-icon" />
                        </HoverToolTip>
                      )}

                      {rowData.length > 0 && sortStates && updateSortStates ? showSortFunc(data.columnName) : null}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length > 0
              ? rowData.map((row: IStudentPlacementDetails, getIndex: number) => {
                  const isItemSelected = isSelected(row[checkboxRef]);
                  const labelId = `enhanced-table-checkbox-${getIndex}`;
                  return (
                    <TableRow key={row.userSubId}>
                      {columnDetails.map((_keys: ColumnDetailsProps, index: number) => {
                        return muiTableCellData(row, index, getIndex, labelId, isItemSelected)
                      })}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
        {emptyTableText && rowData.length === 0 ? (
          <label className="emptyTableFullText" data-testid="empty-table-text">
            {showHelpIconFlag ? [emptyTableText] : <i>{emptyTableText}</i>}
          </label>
        ) : null}
      </TableContainer>
    </div>
  );
}
