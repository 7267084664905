import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { useParams } from "react-router-dom";
import ViewEducatorProfile from "./ViewEducatorProfile";
import { updateActivityIndicator, updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import { UserService } from "../../services/helpers/user.services";
import { AdminService } from "../../services/helpers/api.services";
import { EducatorProfileProps } from "./EducatorProfile.props";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId
  };
}

interface Props extends EducatorProfileProps, ReduxProps {}

function DistrictEducatorProfile(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getDaDetails, getUserRoleId, getBreadCrumbsData } = props;
  const [tabKey, setTabKey] = useState("settings");

  useEffect(() => {
    localStorage.setItem("page", "settings");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.getEducatorDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setEducatorBreadcrumb(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [dispatch]);

  async function setEducatorBreadcrumb(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setBreadCrumb(details.firstName, details.lastName, details.userID, details.organizations[0].orgGUID);
  }

  useEffect(() => {
    if ((getDaDetails.type === 1 || getDaDetails.type === 2) && getUserRoleId === 3) {
      if (getBreadCrumbsData.crumbs[0].name !== "") {
        if (getDaDetails.type === 1) {
          dispatch(
            updatePageTitle(`- Schools - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        } else {
          dispatch(
            updatePageTitle(`- Districts - ${getBreadCrumbsData.crumbs[getBreadCrumbsData.crumbs.length - 1].name}`)
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [getDaDetails, getBreadCrumbsData]);

  async function setBreadCrumb(fname: string, lname: string, uid: number, oid: number) {
    try {
      let apiObj = { rid: getDaDetails.id, cid: oid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            handleSuccessResponse(data, fname, lname, uid);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }

  async function handleSuccessResponse(responsedata: Response, fname: string, lname: string, uid: number) {
    let details = await responsedata.json();
    //@ts-ignore
    if (details.parents === null) {
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            //@ts-ignore
            { path: `/daschool/${details.orgGUID}`, name: details.name },
            { path: `/cvieweducator/${uid}`, name: `${fname} ${lname}` }
          ]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
      } else {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [
            //@ts-ignore
            { path: `/dainstitutions/${details.orgGUID}`, name: details.name },
            { path: `/cvieweducator/${uid}`, name: `${fname} ${lname}` }
          ]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
      }
    } else {
      const breadcrumb = { crumbs: [] };
      //@ts-ignore
      const crumbs = details.parents.reverse();
      for (let x of crumbs) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${x.orgGUID}`,
          //@ts-ignore
          name: x.name
        });
      }
      //@ts-ignore
      if (details.childDepth === 0) {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/daschool/${details.orgGUID}`,
          //@ts-ignore
          name: details.name
        });
      } else {
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${details.orgGUID}`,
          //@ts-ignore
          name: details.name
        });
      }
      //@ts-ignore
      breadcrumb.crumbs.push({
        //@ts-ignore
        path: `/dvieweducator/${uid}`,
        //@ts-ignore
        name: `${fname} ${lname}`
      });
      dispatch(updateBreadCrumbs(breadcrumb));
    }
    dispatch(updateActivityIndicator(false));
  }
  const tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <ViewEducatorProfile />
    }
  ];
  return (
    <>
      <div>
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(DistrictEducatorProfile);
