import React from "react";
import "../StudentSubjects/MyAssignmentCard.scss";
import { math35png, math68png, mathk2png } from "@wne-spa/ux-component-library";
import useGrade from "../hooks/useGrade";
import { ApiPathwayTypeEnum } from "../../ClassView/StudentPlacement.props";

interface CardProps {
  handleClick: (subject: string, pathwayType?: ApiPathwayTypeEnum) => void;
}

function PracticeCard(props: CardProps) {
  const { handleClick } = props;

  const { isKto2, is3to5, is6to8, is9to12 } = useGrade();

  const mathIcon = () => {
    if (isKto2) {
      return mathk2png;
    } else if (is3to5) {
      return math35png;
    } else if (is6to8) {
      return math68png;
    } else if (is9to12) {
      return math68png;
    } else {
      return mathk2png;
    }
  };

  const backgroundClass = () => {
    if (is9to12) {
      return "subjectMath912 practicebackground912";
    } else if (is6to8) {
      return "squareis68 practice68background";
    } else {
      return "diamond";
    }
  };

  const contentClass = () => {
    if (is9to12) {
      return "square-content no-gap";
    } else if (is6to8) {
      return "square-content";
    } else {
      return "diamond-content";
    }
  };

  return (
    <div
      className={backgroundClass()}
      onClick={() => {
        handleClick("Math", ApiPathwayTypeEnum.PRACTICE);
      }}
    >
      <div className={contentClass()}>
        <div className={is9to12 ? "subjectborder912 practice68background" : ""}>
          <img src={mathIcon()} alt="Math Practice" className={is9to12 ? "practice-img-912" : "practice-img"} />
        </div>
        <div className={is9to12 ? "math912Text" : "diamond-content-text"}>MASTERY</div>
        {isKto2 || is3to5 ? (
          <div className={`practice-go ${isKto2 ? "practice-go-k2" : "practice-go-35"}`}>
            <div className="go-text-k2" aria-hidden="true">
              GO
            </div>
            <div className="arrowmargin">
              <i className="fas fa-arrow-right arrowright"></i>
            </div>
          </div>
        ) : (
          <div className={is6to8 ? "practicebackground68" : "go912button practice68background"}>
            <div className="letsgoassessment" aria-hidden="true">
              Let's Go!
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PracticeCard;
