interface Tabs {
  eventKey: string;
  title: string;
}

let studentTabs: Tabs[] = [
  {
    eventKey: "settings",
    title: "Settings"
  },
  {
    eventKey: "classes",
    title: "Classes"
  },
  {
    eventKey: "studentprogress",
    title: "Student Progress"
  }
];
export default studentTabs;
