import React from "react";
import Props from "./radio.props";
import "./radio.scss";
export default function RadioButtons(props: Props) {
  const { value, onChange, name, checked } = props;

  return (
    <div className="RadioButton">
      <input
        data-testid="radio"
        style={{ width: "20px", height: "20px", marginLeft: "30px" }}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        id={value}
        onChange={(event) => {
          onChange(event);
        }}
      />
    </div>
  );
}
