import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReduxProps from "Redux/Redux.props";
import { updateActivityIndicator, updateBreadCrumbs } from "../../Redux/Action";
import ClassView from "../EducatorView/ClassView";
import "./ClassCards.scss";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails
  };
}

interface SAClassviewprops {
  dispatch: AppDispatch;
}

interface Props extends SAClassviewprops, ReduxProps {}

function SAClassView(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getDaDetails } = props;
  const classTeacherList = [
    {
      class: "Class 01",
      teacher: "Teacher 01",
      id: 1
    },
    {
      class: "Class 02",
      teacher: "Teacher 02",
      id: 2
    },
    {
      class: "Class 03",
      teacher: "Teacher 03",
      id: 3
    },
    {
      class: "Class 04",
      teacher: "Teacher 04",
      id: 4
    },
    {
      class: "Class 05",
      teacher: "Teacher 05",
      id: 5
    }
  ];
  useEffect(() => {
    if (getDaDetails.type === 1) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/saclasses/${getDaDetails.id}`, name: "Classes" },
          {
            path: `/classview/${id}`,
            name: classTeacherList[parseInt(id) - 1].class
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      dispatch(updateActivityIndicator(false));
    } else {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/saclasslist/${getDaDetails.id}`, name: "Classes" },
          {
            path: `/classview/${id}`,
            name: classTeacherList[parseInt(id) - 1].class
          }
        ]
      };
      dispatch(updateBreadCrumbs(breadcrumb));
      dispatch(updateActivityIndicator(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  return (
    <>
      <ClassView />
    </>
  );
}

export default connect(mapStateToProps)(SAClassView);
