import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateBreadCrumbs,
  updateDistrictLevelFirstTab,
  updateDSDSReportToken,
  updateActivityIndicator
} from "../../Redux/Action";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "Redux/Store";
import ErrorMsg from "../ErrorMsg/ErrorMsg";
import errorMessages from "../../helpers/errorMessages";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getDaDetails: state.getDaDetails
  };
}

interface DsdsReportLaunchProps extends ReduxProps {
  dispatch?: AppDispatch;
}

const DsdsReportLaunch = (props: DsdsReportLaunchProps) => {
  const { dispatch, getUserRoleId, getDaDetails } = props;
  const { id } = useParams<{ id: string | undefined }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    state: false,
    msg: ""
  });
  const [organizationGuid, setOrganizationGuid] = useState();

  //Get organization guid by registration guid
  const getOrganizationGuidByRegistrationGuid = async () => {
    setError({
      state: false,
      msg: ""
    });

    const response = await AdminService.getOrganizationGuidByRegistrationGuid(id);

    if (response.status === 200) {
      const organizationGuid = JSON.parse(await response.data.text());
      setOrganizationGuid(organizationGuid);
    } else {
      setError({
        state: true,
        msg: errorMessages.serviceFailureError
      });
    }
  };

  const getAndUpdateDsdsReportToken = async () => {
    const config = { districtGuid: null, schoolGuid: null };
    if (getUserRoleId === 3) {
      config.districtGuid = getDaDetails.type === 1 ? `${getDaDetails.id}` : organizationGuid;
      config.schoolGuid = null;
    } else if (getUserRoleId === 4) {
      config.districtGuid = null;
      config.schoolGuid = getDaDetails.type === 1 ? `${getDaDetails.id}` : organizationGuid;
    }

    setError({
      state: false,
      msg: ""
    });

    const response = await AdminService.getDSDSReportToken(config);

    if (response.status === 200) {
      const extractedData = await response.data.text();
      dispatch(updateDSDSReportToken(extractedData));
    } else {
      setError({
        state: true,
        msg: errorMessages.serviceFailureError
      });
    }
  };

  //BreadCrumbs for DA and SA
  const fetchBreadCrumbs = async (rid: string) => {
    try {
      let apiObj = { rid: rid, cid: organizationGuid };
      await AdminService.getParentsWithRootWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            handleSuccessResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
            setError({
              state: true,
              msg: errorMessages.serviceFailureError
            });
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      setError({
        state: true,
        msg: errorMessages.serviceFailureError
      });
    }
  };

  async function handleSuccessResponse(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    // Preparing breadcrumbs for district admmin
    if (getUserRoleId === 3) {
      if (details.parents === null) {
        //@ts-ignore
        const breadcrumb = {
          crumbs: [{ path: `/dainstitutions/${organizationGuid}`, name: details.name }]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updateDistrictLevelFirstTab("Schools"));
      } else {
        let breadcrumb = { crumbs: [] };
        //@ts-ignore
        const crumbs = details.parents.reverse();
        for (let x of crumbs) {
          //@ts-ignore
          breadcrumb.crumbs.push({
            //@ts-ignore
            path: `/dainstitutions/${x.orgGUID}`,
            //@ts-ignore
            name: x.name
          });
        }
        //@ts-ignore
        breadcrumb.crumbs.push({
          //@ts-ignore
          path: `/dainstitutions/${details.orgGUID}`,
          //@ts-ignore
          name: details.name
        });
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updateDistrictLevelFirstTab("Institutions"));
      }
    }

    //Preparing breadcrumbs for school admin
    if (getUserRoleId === 4) {
      //@ts-ignore
      const breadcrumb = {
        crumbs: [
          { path: `/saschools/${getDaDetails.id}`, name: "Schools" },
          //@ts-ignore
          { path: `/saclasslist/${id}`, name: details.name }
        ]
      };
      //@ts-ignore
      dispatch(updateBreadCrumbs(breadcrumb));
    }

    dispatch(updateActivityIndicator(false));
  }

  const validateUserWithRole = async () => {
    //Preparing breadcrumbs for district/school admin
    if (getUserRoleId === 3 || getUserRoleId === 4) {
      dispatch(updateActivityIndicator(true));
      setLoading(true);
      await getOrganizationGuidByRegistrationGuid(); // getting organization guid by registration guid
      if (organizationGuid !== undefined) {
        await getAndUpdateDsdsReportToken(); // getting DSDS report token and updating in redux
        let rid: string = getUserRoleId === 3 ? getDaDetails.id : organizationGuid;
        await fetchBreadCrumbs(rid); // update breadcrumbs in header
      }
      setLoading(false);
    } else {
      dispatch(updateActivityIndicator(false));
      setError({
        state: true,
        msg: errorMessages.unauthorizedAccessError
      });
    }
  };

  useEffect(() => {
    if (!error.state && !loading) {
      navigate(`/dsdsreport/${organizationGuid}`);
    }
  }, [loading, error]);

  useEffect(() => {
    validateUserWithRole();
    return () => {
      dispatch(updateActivityIndicator(false)); // cleanup for activity indicator
    };
  }, [organizationGuid]);

  return error.state ? (
    <div>
      <ErrorMsg error={error.msg} />
    </div>
  ) : (
    <div style={{ height: "100vh" }}>loading...</div>
  );
};

export default connect(mapStateToProps)(DsdsReportLaunch);
