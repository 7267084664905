import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateExternalId,
  updateSchoolGuids,
  updateStudentAndClassFilterURL,
  updateIsOrgRegistrationOwned
} from "../../Redux/Action";
import Classes from "../ViewCustomer/Classes";
import "./SAClassList.scss";
import { AdminService } from "../../services/helpers/api.services";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { useParams } from "react-router-dom";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails
  };
}

interface SAClassesprops {
  dispatch: AppDispatch;
}

interface Props extends SAClassesprops, ReduxProps {}

function SAClasses(props: Props) {
  const { dispatch, getDaDetails } = props;
  const { id } = useParams<{ id: string | undefined }>();
  useEffect(() => {
    dispatch(updatePageTitle(`- Classes`));
    const fetchData = async () => {
      if (getDaDetails.type === 1) {
        const breadcrumb = {
          crumbs: [{ path: `/saclasses/${getDaDetails.id}`, name: "Classes" }]
        };
        dispatch(updateBreadCrumbs(breadcrumb));
        dispatch(updateActivityIndicator(false));
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();
    dispatch(updateStudentAndClassFilterURL(window.location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDaDetails]);

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    fetchExternalInstitutionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let apiObj = { guid: id };
    (async () => {
      await AdminService.getGUIDSWithGuid(apiObj).then(async ({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          await responseGUIDS(data);
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function responseGUIDS(responsedata: Response) {
    let allData = await responsedata.json();
    dispatch(updateIsOrgRegistrationOwned(allData[0]?.isRegistrationOwned));
  }

  async function fetchExternalInstitutionId() {
    try {
      let eids = { guid: getDaDetails.id };
      await AdminService.getExternalInstitutionIdByGuid(eids).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            setInstitutionId(data);
          } else {
            // do nothing
          }
        }
      );
    } catch (err) {
      //do nothing
    }
  }
  async function setInstitutionId(data: Response) {
    let details = await data.json();
    let ExternalId = details[0].districtAssignedId;
    dispatch(updateSchoolGuids(details));
    if (ExternalId === "") {
      dispatch(updateExternalId("-"));
    } else {
      dispatch(updateExternalId(ExternalId));
    }
  }

  return (
    <>
      <div className="class-nav-tabs">
        <Classes isfrom="sa" />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(SAClasses);
