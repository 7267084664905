import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import { updateTabName, updateActivityIndicator, updateClassViewTab } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { ClassProgressProps } from "./ClassProgress.props";

interface Props extends ReduxProps, ClassProgressProps {}

function mapStateToProps(state: ReduxProps) {
  return {
    updatePreviousURLForClassProgress: state.updatePreviousURLForClassProgress,
  };
}

function ClassProgress(props: Props) {
  const navigate = useNavigate();
  const { updatePreviousURLForClassProgress, dispatch } = props;

  useEffect(() => {
    dispatch(updateActivityIndicator(false));
  }, []);
  let tabs = [
    {
      eventKey: "classsummary",
      title: "Class Summary"
    },
    {
      eventKey: "classprogress",
      title: "Class Progress"
    },
    {
      eventKey: "placement",
      title: "Student Placement"
    },
    {
      eventKey: "roster",
      title: "Student Roster"
    },
    {
      eventKey: "reports",
      title: "Reports"
    },
    {
      eventKey: "settings",
      title: "Settings"
    }
  ];

  function isAssessmentTabClicked(tab: string) {
    if (tab === "classsummary") {
      let urlParts = updatePreviousURLForClassProgress.split("/");
      navigate(`/classsummary/${urlParts[urlParts.length - 1]}`);
    } else {
      dispatch(updateClassViewTab(tab));
      dispatch(updateTabName(tab));
      navigate(updatePreviousURLForClassProgress);
    }
  }

  return (
    <>
      <UxTabs
        tabsId="class-tabs"
        activeKey="classprogress"
        onTabSelect={(tab: string) => {
          isAssessmentTabClicked(tab);
          localStorage.setItem("page", tab);
        }}
        children={tabs}
        className="pt-2"
        currentKey="classprogress"
      />
      <div id="classprogress"></div>
    </>
  );
}

export default connect(mapStateToProps)(ClassProgress);
