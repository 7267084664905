// @ts-ignore
import { User, UserManager } from "oidc-client";
import Settings from "../helpers/constants";

const settings = {
  authority: Settings().authority,
  client_id: localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id,
  redirect_uri: `${Settings().redirect_uri}`,
  silent_redirect_uri: `${Settings().redirect_uri}`,
  post_logout_redirect_uri:
    localStorage.getItem("ala:MppLaunch") === "true" ? null : `${Settings().post_logout_redirect_uri}`,
  response_type: `${Settings().response_type}`,
  scope: Settings().scope,
  revokeAccessTokenOnSignout: true,
  staleStateAge: 43200,
  extraQueryParams: {
    login_hint: JSON.stringify({
      siteId:
        localStorage.getItem("ala:redirectedFrom") === "student" ||
        localStorage.getItem("ala:redirectedFrom") === "educator"
          ? localStorage.getItem("ala:loginCode")
          : "0",
      tenant: window.APP_ENV.tenant || "Tenant.ALA",
      clientId: localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
    })
  }
};

let userManager = new UserManager(settings);
userManager
  .clearStaleState()
  .then(() => {
    //do nothing
  })
  .catch((e) => {
    //do nothing
  });

export function getUser(): Promise<User | null> {
  return userManager.getUser();
}

export function login(): Promise<void> {
  return userManager.signinRedirect();
}

export function renewToken(): Promise<User> {
  return userManager.signinSilent();
}

export function logout(): Promise<void> {
  return userManager.signoutRedirect();
}

export function signinCallback(): Promise<User> {
  return userManager.signinRedirectCallback();
}
