import React, { useEffect } from "react";
import "./Transition912.scss";
import { updatePageTitle } from "../../../../Redux/Action";
import { connect } from "react-redux";
import ReduxProps from "../../../../Redux/Redux.props";
import { TransitionProps } from "../Transition.props";
function mapStateToProps(state: ReduxProps) {
  return {
    getBreakpoint: state.getBreakpoint
  };
}
function Transition912(props: TransitionProps) {
  const { transitionGo, goBackToMyPathAssessment, isTestTimeAgain, dispatch } = props;
  useEffect(() => {
    dispatch(
      updatePageTitle(
        isTestTimeAgain ? "Test time again | Imagine MyPath" : "Time to finish your test | Imagine MyPath "
      )
    );
  }, []);
  useEffect(() => {
    document.getElementById("click912").focus();
    document.getElementById("back912").addEventListener("keydown", (event) => {
      if (event.key === "Tab" && !event.shiftKey) {
        event.preventDefault();
        let elem = document.getElementById("close912");
        elem.focus();
      }
    });
    document.getElementById("close912").addEventListener("keydown", (event) => {
      if (event.shiftKey && event.key === "tab") {
        event.preventDefault();
        let ele = document.getElementById("back912");
        ele.focus();
      }
    });
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        goBackToMyPathAssessment();
      }
    });
  }, []);
  return (
    <>
      <div className="flexFor912 scrollbarhidden" style={{ backgroundColor: "rgba(6, 10, 139, 0.44)" }}>
        <div className="messageBox912" role="dialog" aria-label="test time">
          <div id="click912" tabIndex={-1} aria-hidden="true"></div>

          <div
            className="closeicon  "
            style={{ width: "30px" }}
            onClick={() => {
              goBackToMyPathAssessment();
            }}
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.key === "Enter") {
                goBackToMyPathAssessment();
              }
            }}
          >
            <i
              className="fa fa-close tabfocustransition"
              tabIndex={0}
              aria-label="close"
              role="button"
              id="close912"
            ></i>
          </div>

          <div>
            {isTestTimeAgain ? (
              <div className="modalmessage912">
                <h2 className=" modalh2message tabfocustransition" tabIndex={0}>
                  {" "}
                  Test Time Again!{" "}
                </h2>
                Don't worry, your hard work will be saved.
              </div>
            ) : (
              <div className="modalmessage912">
                <h2 className=" modalh2message  tabfocustransition" tabIndex={0}>
                  {" "}
                  Time to Finish Your Test.
                </h2>
              </div>
            )}
          </div>

          <div className="buttons">
            <div
              className="gotransitionButton912 tabfocustransition"
              onClick={() => {
                transitionGo();
              }}
              tabIndex={0}
              onKeyPress={(event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                  transitionGo();
                }
              }}
              role="button"
            >
              Go
            </div>
            <div
              id="back912"
              className="backtransitionButton912 tabfocustransition"
              onClick={() => {
                goBackToMyPathAssessment();
              }}
              tabIndex={0}
              onKeyPress={(event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                  goBackToMyPathAssessment();
                }
              }}
              role="button"
            >
              Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Transition912);
