import React, { useEffect } from "react";
import { connect } from "react-redux";
import ViewEducatorProfile from "./ViewEducatorProfile";
import { updateBreadCrumbs, updatePageTitle } from "../../Redux/Action";
import { EducatorProfileProps } from "./EducatorProfile.props";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}

function EditOwnProfile(props: EducatorProfileProps): JSX.Element {
  const { dispatch, isFrom } = props;
  useEffect(() => {
    if (isFrom === "profile") {
      dispatch(updateBreadCrumbs({ crumbs: [{ name: "Profile" }] }));
      dispatch(updatePageTitle(`- Profile`));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <div style={{ marginTop: "30px" }}>
          <ViewEducatorProfile isFrom="profile" />
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(EditOwnProfile);
