/* eslint-disable react-hooks/exhaustive-deps */
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import configMessages from "../../helpers/configMessages";
import { updateActivityIndicator, updatePageTitle } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AdminService } from "../../services/helpers/api.services";
import { UserService } from "../../services/helpers/user.services";
import { CicsettingsRequestProps, EducatorProfileProps } from "./EducatorProfile.props";
import "./ViewProfile.scss";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#454545",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: 10
  }
}))(Tooltip);

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getUserRoleId: state.getUserRoleId,
    getUserGuId: state.getUserGuId,
    getFlagrKeys: state.getFlagrKeys
  };
}
interface Props extends EducatorProfileProps, ReduxProps {}

function ViewEducatorProfile(props: Props): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch, getUserRoleId, isFrom, getActivityIndicator, getUserGuId, getFlagrKeys } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [districtAssignedId, setDistrictAssignedId] = useState("");
  const [username, setUsername] = useState("");
  const [schools, setSchools] = useState([]);
  const [district, setDistrict] = useState([]);
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState();
  const [showToast, setShowToast] = useState({ val: false, msg: "" });
  const roles = [
    "",
    "WNE Admin",
    "WNE Support",
    "District Administrator",
    "School Administrator",
    "Teacher",
    "WNE HelpDesk"
  ];
  const [allSchoolList, setAllSchoolList] = useState([]);
  const [state, setState] = useState({
    firstName: firstName,
    lastName: lastName,
    userName: username,
    email: email
  });
  const [dropdownTitle, setDropdownTitle] = useState(role);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = id.substring(id.lastIndexOf("_") + 1, id.length);
        UserService.getEducatorDetailsWithGuid(uid).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        });
      } catch (err) {
        dispatch(updateActivityIndicator(false));
      }
    };
    dispatch(updateActivityIndicator(true));
    fetchData();
    fetchspanishids(id.split("_")[0]);
    isFrom === "profile" ? localStorage.setItem("page", "profile") : localStorage.setItem("page", "settings");
    // eslint-disable-next-line
  }, []);

  const setSchoolIdValue = (allData) => {
    let school1 = [];
    let schoolids1 = [];
    for (let i in allData.organizations) {
      school1.push(allData.organizations[i].name);
      schoolids1.push({
        name: allData.organizations[i].name,
        id: allData.organizations[i].id,
        orgGuid: allData.organizations[i].orgGUID
      });
    }
    let school = [];
    for (let i in allData.organizations) {
      school.push(allData.organizations[i].name);
    }

    if (allData.roleID === 4 || allData.roleID === 5) {
      //@ts-ignore
      setSchools(school1);
    } else {
      //@ts-ignore
      setDistrict(school1);
    }
  };

  async function gotResponse(responsedata: Response) {
    let allData = await responsedata.json();
    if (isFrom === "profile") {
      dispatch(updatePageTitle(`- Profile - ${allData.firstName} ${allData.lastName}`));
    }
    setFirstName(allData.firstName);
    setLastName(allData.lastName);
    setUsername(allData.loginName);
    setEmail(allData.email);
    if (allData.districtAssignedId === null) {
      setDistrictAssignedId("");
    } else {
      setDistrictAssignedId(allData.districtAssignedId);
    }
    //@ts-ignore
    setSchoolIdValue(allData);
    //@ts-ignore
    setRoleId(allData.roleID);
    //@ts-ignore
    if (allData.roleID === 7) {
      setRole(roles[6]);
    } else {
      setRole(roles[allData.roleID]);
    }
    //@ts-ignore
    setDropdownTitle(roles[allData.roleID]);
    setState({
      firstName: allData.firstName,
      lastName: allData.lastName,
      userName: allData.loginName,
      email: allData.email
    });
    if (allData.roleID === 4 || allData.roleID === 5) {
      getSchools(allData.organizations[0].orgGUID);
    }
    if (allData.roleID === 3) {
      getInstitutes(allData.organizations[0].orgGUID);
    }
    //@ts-ignore
    if (
      getUserRoleId === 4 ||
      (allData.organizations.length === 1 &&
        allData.organizations[0].parentId === 0 &&
        dropdownTitle !== "District Admintrator")
    ) {
    }
    if (isFrom === "profile") {
      dispatch(updateActivityIndicator(false));
    }
  }

  async function getInstitutes(sid: string) {
    try {
      const jsonObj = { Page: 1, PageSize: 5000, Guid: sid };
      await AdminService.getChildrenWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          gotInstitutesResponse(data);
        } else {
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  async function gotInstitutesResponse(responsedata: Response) {
    dispatch(updateActivityIndicator(false));
    let allData = await responsedata.json();
    //@ts-ignore
    let allSchoolDetails = [];
    //@ts-ignore
    let allSchools = [];
    //@ts-ignore
    for (let x in allData.values) {
      if (allData.values[x].childDepth === 0) {
        let obj = {
          customerName: null,
          id: allData.values[x].id,
          isActive: allData.values[x].isActive,
          name: allData.values[x].name,
          netsuiteId: allData.values[x].netsuiteId,
          parentId: allData.values[x].parentId,
          parentName: null,
          orgGuid: allData.values[x].orgGUID
        };
        allSchoolDetails.push(obj);
        //@ts-ignore
        allSchools.push(allData.values[x].name);
      }
    }
    //@ts-ignore
    setAllSchoolList(allSchools);
  }

  async function getSchools(sid: number) {
    try {
      let apiObj = { guid: sid, uid: getUserGuId };
      await AdminService.getSchoolsToMapWithGuid(apiObj).then(
        ({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            getSchoolsResponse(data);
          } else {
            dispatch(updateActivityIndicator(false));
          }
        }
      );
    } catch (err) {
      dispatch(updateActivityIndicator(false));
      //do nothing
    }
  }

  async function getSchoolsResponse(responsedata: Response) {
    dispatch(updateActivityIndicator(false));
    let allData = await responsedata.json();
    let allSchools = [];
    for (let x in allData) {
      //@ts-ignore
      allSchools.push(allData[x].name);
    }
    //@ts-ignore
    setAllSchoolList(allSchools);
  }

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  const fetchspanishids = async (Ids: string) => {
    let apiObj = { guid: Ids };
    await AdminService.getParentsWithGuid(apiObj).then(({ status, data }: { status: number; data: Response }) => {
      if (status === 200) {
        getspanishidsResponse(data);
      } else {
        dispatch(updateActivityIndicator(false));
        // do nothing
      }
    });
  };

  async function getspanishidsResponse(responsedata: Response) {
    let spanishparents = [];
    let json: CicsettingsRequestProps = {
      currentLevelGuid: { type: "", guid: "" },
      parentsGuid: []
    };
    let details = await responsedata.json();
    let i = 0;
    let levelId = { type: "Custom", guid: details.orgGUID.toString() };

    if (details.parents != null) {
      let parentid = details.parents;

      for (i; i < parentid.length; i++) {
        spanishparents[i] = {
          type: "Custom",
          guid: parentid[i].orgGUID.toString()
        };
      }
    }

    json = {
      ...json,
      currentLevelGuid: levelId,
      //@ts-ignore
      parentsGuid: spanishparents
    };
  }

  return (
    <div className="px-4 viewprofilescrollable">
      <div className={alertclasses.root}>
        <Snackbar
          open={showToast.val}
          autoHideDuration={4000}
          onClose={() => {
            setShowToast({ val: false, msg: "" });
          }}
        >
          <Alert
            style={{ backgroundColor: "#36BBA4", color: "#fff" }}
            onClose={() => {
              setShowToast({ val: false, msg: "" });
            }}
          >
            {showToast.msg}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <div className="profile">
          <div>
            <span className="headings">
              {isFrom === "profile" && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7)
                ? "Details"
                : "Educator Profile"}
            </span>
          </div>
          <div>
            <p className="tagname"> Name </p>
            {state.lastName ? (
              <p className="tagvalue">
                {" "}
                {state.firstName} {state.lastName}{" "}
              </p>
            ) : null}
          </div>
          <div>
            <p className="tagname">
              {isFrom === "profile" && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7)
                ? "Username"
                : getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"]
                ? "Username/Email"
                : "Username"}
            </p>
            <p className="tagvalue"> {username} </p>
          </div>
          <div>
            <p className="tagname"> Password</p>
            <div className="disinline" style={{ display: "flex" }}>
              <p className="passwordvalue"> *********** </p>
            </div>
          </div>
          {isFrom === "profile" && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) ? (
            <div>
              <p className="tagname"> Email </p>
              <p className="tagvalue">{state.email} </p>
            </div>
          ) : getFlagrKeys["ReadinessFeature_Educator_Email_Loginname"] ? null : (
            <div>
              <p className="tagname"> Email </p>
              <p className="tagvalue">{state.email} </p>
            </div>
          )}
        </div>
        <div className="profile mt-3">
          <div>
            <span className="headings">
              {isFrom === "profile" && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7)
                ? "Other"
                : "Educator Details"}
            </span>
          </div>
          {roleId === 4 || roleId === 5 ? (
            <div>
              <p className="tagname"> Associated Schools </p>
              {schools.map((i) => (
                <p className="tagvalue"> {i} </p>
              ))}
            </div>
          ) : null}
          {roleId === 3 ? (
            <div>
              <p className="tagname"> Associated District </p>
              {district.map((i) => (
                <p className="tagvalue"> {i} </p>
              ))}
            </div>
          ) : null}

          <div>
            <p className="tagname"> Type </p>
            <p className="tagvalue"> {role} </p>
            {isFrom !== "profile" &&
            role === "District Administrator" &&
            allSchoolList.length <= 1 &&
            !getActivityIndicator ? (
              <LightTooltip placement="top-start" title={configMessages.districtAdminNoChange}>
                <p className="infoTag">
                  <i className="fa fa-info-circle infoiconforEducator" aria-hidden="true"></i>
                </p>
              </LightTooltip>
            ) : null}
          </div>
          {isFrom === "profile" && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) ? null : (
            <div>
              <p className="tagname"> External ID</p>
              <p className="tagvalue">
                {" "}
                {districtAssignedId === " " || districtAssignedId === null ? "-" : districtAssignedId}{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ViewEducatorProfile);
