import React, { useState, useEffect } from "react";
import { UxMUITable } from "@wne-spa/ux-component-library";
import "./DataList.scss";
import { SchoolListProps } from "./EducatorView.props";

function SchoolList(props: SchoolListProps): JSX.Element {
  const { data, handleClick } = props;
  const [schools, setSchools] = useState({
    values: []
  });
  const [emptyTableText, setEmptyTableText] = useState("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const columnNames = ["School Name"];
  const rowMapper = [["name"]];
  useEffect(() => {
    setSchools({ values: data });
    const school = JSON.stringify(data);
    if (school.length === 0) {
      setEmptyTableText("No Records found");
    }
  }, [data]);

  interface IList {
    id: number;
    name: string;
    isActive: boolean;
    netsuiteId: string;
    parentId: number;
    parentName: string | null;
    customerName: string;
    childDepth: number;
  }

  interface ISchoolList {
    values: IList[];
  }

  function paginationcomp() {
    return (
      <div className="fixedpagination">
        {data.length > 0 ? (
          <span
            style={{
              float: "right",
              marginTop: 20,
              marginRight: 20,
              fontSize: "15px"
            }}
          >
            {" "}
            Viewing Records 1 - {data.length} of {data.length}
          </span>
        ) : null}
      </div>
    );
  }
  return (
    <>
      <div className="class-nav-tabs">
        <UxMUITable
          MUITableClassName="bg-white dTable p-3 bg-white"
          columnNames={columnNames}
          rowMapper={rowMapper}
          rowData={(schools as ISchoolList).values}
          showColumnCheckbox={false}
          showKebabMenu={false}
          showNameKebabMenu={false}
          viewChild={handleClick}
          emptyTableText={emptyTableText}
          checkboxRef="loginName"
          selected={selected}
          setSelected={setSelected}
        />
        {paginationcomp()}
      </div>
    </>
  );
}

export default SchoolList;
