import React, { useEffect } from "react";
import { connect } from "react-redux";
import { math68png, reading68png } from "@wne-spa/ux-component-library";
import { updatePageTitle } from "../../../Redux/Action";
import ReduxProps from "../../../Redux/Redux.props";
import { StudentSubjectsProps } from "./StudentSubjects.props";
import usePageTitle from "../hooks/usePageTitle";

function mapStateToProps(state: ReduxProps) {
  return {
    isSessionModal: state.isSessionModal
  };
}
interface Props extends ReduxProps, StudentSubjectsProps {}

function StudentSubjectFor68(props: Props) {
  const { subject, goClick68, dispatch, isSessionModal } = props;
  const { updateTitle } = usePageTitle();

  useEffect(() => {
    updateTitle("Student Dashboard | Imagine MyPath");
  }, []);

  let arialabel = `Go to mypath ${subject} page`;
  return (
    <div className="box">
      <div
        className={
          subject === "Math" ? "squareGrade68 backgroundcolorformath" : "squareGrade68 backgroundcolorforreading"
        }
        onClick={() => {
          goClick68(subject);
        }}
        tabIndex={isSessionModal ? -1 : 0}
        role="button"
        aria-label={arialabel}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            goClick68(subject);
          } else {
            //do nothing
          }
        }}
      >
        <div>
          <img
            style={subject === "Math" ? { width: "78px", height: "70px" } : { width: "70px", height: "70px" }}
            src={subject === "Math" ? math68png : reading68png}
            alt={"subject"}
          ></img>
        </div>
        {props.showIMPathway && subject === "Math" ? (
          <div className="text68" aria-hidden="true">
            BUILDER
          </div>
        ) : (
          <div className="text68" aria-hidden="true">
            {subject === "Math" ? "MATH" : "READING"}
          </div>
        )}
        <div className="gotextbutton">
          <div
            className={subject === "Math" ? "go68 backgroundandborderformath" : "go68 backgroundandborderforreading"}
          ></div>
          <div className="textgocolor" aria-hidden="true">
            Let's Go!
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(StudentSubjectFor68);
