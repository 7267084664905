import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch, persistor } from "../../Redux/Store";
export interface MppLaunchProps {
  dispatch: AppDispatch;
}
interface Props extends ReduxProps, MppLaunchProps { }
function mapStateToProps(state: any) {
  return {};
}
function MppLaunch(props: Props) {
  const url = new URL(`${window.location}`);

  useEffect(() => {
    sessionStorage.clear();
    persistor.pause();
    persistor.purge();
    localStorage.clear();

    if (url.searchParams.get("appfrom") === "mpp") {
      localStorage.setItem("ala:MppLaunch", "true");
      localStorage.setItem("ala:MppLaunchSubject", url.searchParams.get("Subject"));

      //Additional processing in case of landing page being sent as part of the launch request
      if (url.searchParams.get("landingpage") === "cpr") {
        sessionStorage.setItem("ala:MPPLandingPage", url.searchParams.get("landingpage"));
        sessionStorage.setItem("ala:MPPorgId", url.searchParams.get("orgId"));
      }

      window.open(window.location.origin, "_self");
    }
  }, []);
  return <></>;
}
export default connect(mapStateToProps)(MppLaunch);
