import { AppDispatch } from "../../Redux/Store";

export type StudentPlacementProps = {
  orgLevel: string;
  dispatch: AppDispatch;
};

export type StudentPlacementSearchRefProps = { value: string };

export enum ApiPathwayTypeEnum {
  INSTRUCTION = "Instruction",
  PRACTICE = "Practice"
}

export type IStudentPlacementDetails = {
  organizationId?: number;
  organizationName?: string;
  organizationGuid?: string;
  userId: number;
  userGuid: string;
  userSubId: string;
  userName: string;
  firstName: string;
  lastName: string;
  subject: string;
  subjectId: number;
  grade: string;
  nweaId: string;
  renaissanceId: string;
  studentPlacementId?: number;
  placementGrade: string;
  placementDate: string;
  source: string;
  pathwayType: ApiPathwayTypeEnum | null;
  imPlusPlacements?: ApiPathwayTypeEnum[];
  isEnabled: boolean;
  isReset: boolean;
  isPaused: boolean;
  isLocked: boolean;
  isBridged: boolean;
  hasIPlusMlicense: boolean;
};

export type SubjectGradesProps = {
  subject: string;
  grade: string[];
};