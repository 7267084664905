import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateMPPSubject } from "../../../Redux/Action";

export default function useMPPSubject() {
  const mppSubject = useSelector((state: RootStateOrAny) => state.getMPPSubject);
  const dispatch = useDispatch();

  const updateMppSubject = (subject) => {
    dispatch(updateMPPSubject(subject));
  };

  return { mppSubject, updateMppSubject };
}
