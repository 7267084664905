import { blue, grey } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { UxAction, UxShadesEnum } from "@wne-spa/ux-component-library";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReduxProps from "Redux/Redux.props";
import configMessages from "../../helpers/configMessages";
import { updateActivityIndicator, updateOrgLoginCodeAvailable, updatePendoCategoryDetails } from "../../Redux/Action";
import { AppDispatch } from "../../Redux/Store";
import { AdminService } from "../../services/helpers/api.services";
import "./Settings.scss";

interface SettingsProps {
  dispatch: AppDispatch;
  isfrom?: string;
}

interface TimeZoneObjectProps {
  organizationID: number;
  timeZoneId: number;
  timeZoneName: string;
  utcTime: string;
  isTimeZoneExist: boolean;
  countryCode: string | null;
}

interface Props extends SettingsProps, ReduxProps {}

const borderColorToReset = "#DDDDDD";
const borderColorFocus = "#4DA7C9";
const borderColorError = "#F24E45";

function mapStateToProps(state: ReduxProps) {
  return {
    getUserRoleId: state.getUserRoleId,
    getActivityIndicator: state.getActivityIndicator,
    getDoLittleToken: state.getDoLittleToken,
    getSpanishIds: state.getSpanishIds,
    getIsOrgHasRegistrationOwned: state.getIsOrgHasRegistrationOwned
  };
}

function Settings(props: Props) {
  const { getUserRoleId, dispatch } = props;
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [showToast, setShowToast] = useState({
    val: false,
    msg: "",
    color: "",
    type: ""
  });
  const [state, setState] = useState({
    primarySubsidiary: "",
    netsuiteId: "",
    name: "",
    productionSchoolName: "",
    altName: "",
    orgType: "",
    district: "",
    parentId: "",
    parent: "",
    topLevelParentId: "",
    topLevelParent: "",
    mchPin: "",
    state: "",
    lmsDisplayName: "",
    ncesId: "",
    accountManager: "",
    professionalDevelopmentSpecialist: "",
    strategicProjectManager: "",
    externalSalesRep: "",
    insideOutsideSales: "",
    insideSalesRep: "",
    private: false,
    territory: "",
    middlewareId: "",
    customerId: "",
    customerInternalId: "",
    historicalCustomerInternalId: "",
    crmId: "",
    parentMiddlewareID: "",
    toplevelParentMiddlewareID: "",
    organizationID: "",
    crmName: "",
    category: ""
  });
  const [loginCode, setLoginCode] = React.useState("");
  const [InstitutionId, setInstitutionId] = useState("-");
  const [singleSignOnStatusVal, setSingleSignOnStatusVal] = useState<boolean>(false);
  const [editSingleSignonSettings, setEditSingleSignonSettings] = useState(false);
  const [ClientIdVal, setClientIdVal] = useState("");
  const [ClientSecretVal, setClientSecretVal] = useState("");
  const [ClientId, setClientId] = useState("");
  const [ClientSecret, setClientSecret] = useState("");
  const [ClientIdIssueText, setClientIdIssueText] = useState("");
  const [ClientSecretIssueText, setClientSecretIssueText] = useState("");
  const [singleSignOnStatus, setSingleSignOnStatus] = React.useState({
    checkedA: false
  });
  const [ClientIdBorder, setClientIdBorder] = useState(borderColorToReset);
  const [ClientSecretBorder, setClientSecretBorder] = useState(borderColorToReset);
  const [timezoneObject, setTimeZoneObject] = useState<TimeZoneObjectProps>({
    organizationID: 0,
    timeZoneId: 0,
    timeZoneName: "",
    utcTime: "",
    isTimeZoneExist: false,
    countryCode: ""
  });

  useEffect(() => {
    dispatch(updateActivityIndicator(true));
    const fetchData = async () => {
      try {
        const requestGuid = { guid: id };
        let requestOrgGuid = { orgGuid: id };
        const [orgResponse, loginCodeResponse, ssoResponse,  timezoneResponse, externalIdResponse] = await Promise.all([
          AdminService.organizationInformationGuid(requestGuid),
          AdminService.getLogincodeWithGuid(requestGuid),
          AdminService.getSettingsSsoWithGuid(requestOrgGuid),
          AdminService.getOrgTimezoneWithGuid(requestOrgGuid),
          AdminService.getExternalInstitutionIdByGuid(requestGuid)
        ]);

        if (orgResponse.status === 200) {
          setOrganizationData(orgResponse.data);
        }

        if (loginCodeResponse.status === 200) {
          gotLogincodeResponse(loginCodeResponse.data);
        }

        if (ssoResponse.status === 200) {
          gotSettingsSsoResponse(ssoResponse.data);
        }

        if (timezoneResponse.status === 200) {
          gotTimezoneResponse(timezoneResponse.data);
        }
        
        if (externalIdResponse.status === 200) {
          setExternalInstitutionId(externalIdResponse.data);
        }
      } catch (err) {}
    };

    if (id && (getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7)) {
      fetchData();
    }
  }, [id]);

  async function gotTimezoneResponse(responseData: Response) {
    const timezoneObjects = JSON.parse(await responseData.text());
    setTimeZoneObject(timezoneObjects);
  }

  async function gotSettingsSsoResponse(responsedata: Response) {
    let details = await responsedata.json();
    setClientIdVal(details.clientID);
    setClientSecretVal(details.clientSecret);
    setSingleSignOnStatusVal(details.ssoEnabled);
  }
  
  async function gotLogincodeResponse(responsedata: Response) {
    let details = await responsedata.json();
    if (details.length > 0) {
      if (details[0].updateStatus !== 2) {
        if (details[0].oldLoginCode === null) {
          setLoginCode(details[0].loginCode);
          if (details[0].loginCode === "") {
            dispatch(updateOrgLoginCodeAvailable(false));
          } else {
            dispatch(updateOrgLoginCodeAvailable(true));
          }
        } else {
          setLoginCode(details[0].oldLoginCode);
          dispatch(updateOrgLoginCodeAvailable(true));
        }
      } else {
        setLoginCode(details[0].loginCode);
        if (details[0].loginCode === "") {
          dispatch(updateOrgLoginCodeAvailable(false));
        } else {
          dispatch(updateOrgLoginCodeAvailable(true));
        }
      }
    }
    dispatch(updateActivityIndicator(false));
  }

  async function setOrganizationData(responsedata: Response) {
    let details = await responsedata.json();
    //@ts-ignore
    setState(details);
    localStorage.setItem("ala:orgid", details.organizationID);
    dispatch(updatePendoCategoryDetails(details.category));
  }

  async function setExternalInstitutionId(responsedata: Response) {
    let details = await responsedata.json();
    let ExternalId = details[0].districtAssignedId;
    if (ExternalId === "") {
      setInstitutionId("");
    } else {
      setInstitutionId(ExternalId);
    }
  }

  function editSingleSignOnSettings() {
    setClientId(ClientIdVal);
    setClientSecret(ClientSecretVal);
    setSingleSignOnStatus({
      ...singleSignOnStatus,
      checkedA: singleSignOnStatusVal
    });
    setEditSingleSignonSettings(true);
  }

  const handleChangeSingleSignOnStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSingleSignOnStatus({
      ...singleSignOnStatus,
      [event.target.name]: event.target.checked
    });
  };

  function handleClientIdTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setClientId(event.target.value);
    }
  }

  function handleClientSecretTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.charAt(0) !== " ") {
      setClientSecret(event.target.value);
    }
  }

  function saveSingleSignOn() {
    if (!ClientId) {
      setClientIdIssueText(configMessages.clientIdRequired);
      setClientIdBorder(borderColorError);
    }
    if (!ClientSecret) {
      setClientSecretIssueText(configMessages.clientSecretRequired);
      setClientSecretBorder(borderColorError);
    }
    if (ClientId && ClientSecret && singleSignOnStatus.checkedA) {
      saveSsoSettings();
    }
    if (ClientId && ClientSecret && !singleSignOnStatus.checkedA) {
      saveSsoSettings();
    }
  }

  function singleSignOnCancleEdit() {
    setClientSecretIssueText("");
    setClientSecretBorder(borderColorToReset);
    setClientIdIssueText("");
    setClientIdBorder(borderColorToReset);
    setEditSingleSignonSettings(false);
    setSingleSignOnStatus({
      ...singleSignOnStatus,
      checkedA: singleSignOnStatusVal
    });
    setClientId(ClientIdVal);
    setClientSecret(ClientSecretVal);
  }

  async function saveSsoSettings() {
    try {
      dispatch(updateActivityIndicator(true));
      const jsonObj = {
        OrganizationGuid: id,
        ssoEnabled: singleSignOnStatus.checkedA,
        clientID: ClientId,
        clientSecret: ClientSecret
      };
      AdminService.saveSettingsSsoWithGuid(jsonObj).then(({ status, data }: { status: number; data: Response }) => {
        if (status === 200) {
          saveSettingsSsoResponse(data);
        } else {
          alert("Error while updating Single Sign On");
          setSingleSignOnStatus({
            ...singleSignOnStatus,
            checkedA: singleSignOnStatusVal
          });
          dispatch(updateActivityIndicator(false));
        }
      });
    } catch (err) {}
  }

  async function saveSettingsSsoResponse(response: Response) {
    let details = await response.json();
    if (
      details.message === "Organization setting update was successful." ||
      details.message === "Organization setting saved was successful."
    ) {
      setEditSingleSignonSettings(false);
      setClientIdVal(ClientId);
      setClientSecretVal(ClientSecret);
      setSingleSignOnStatusVal(singleSignOnStatus.checkedA);
      dispatch(updateActivityIndicator(false));
      setShowToast({
        val: true,
        msg: configMessages.SingleSignOnSuccess,
        color: "#36BBA4",
        type: "success"
      });
    } else if (details.message === "Please enter a unique value.") {
      setClientIdIssueText(configMessages.clientIdUnique);
      setClientIdBorder(borderColorError);
      dispatch(updateActivityIndicator(false));
    }
  }

  const BlueSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: blue[500]
      },
      "&$checked + $track": {
        backgroundColor: blue[500]
      }
    },
    checked: {},
    track: {}
  })(Switch);

  function Alert(alertprops: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...alertprops} />;
  }

  const alertStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
        backgroundColor: "#424242"
      }
    }
  }));
  const alertclasses = alertStyles();

  return (
    <>
      <div>
        <div className={alertclasses.root}>
          <Snackbar
            open={showToast.val}
            autoHideDuration={4000}
            onClose={() => {
              setShowToast({ val: false, msg: "", color: "", type: "" });
            }}
          >
            <Alert
              style={{ backgroundColor: showToast.color, color: "#fff" }}
              onClose={() => {
                setShowToast({ val: false, msg: "", color: "", type: "" });
              }}
            >
              {showToast.msg}
            </Alert>
          </Snackbar>
        </div>

        {(getUserRoleId === 1 || getUserRoleId === 2 || getUserRoleId === 7) && !editSingleSignonSettings ? (
          <div className="settingsscrollable px-4 ms-2">
            <div className="nweasettings">
              <p className="settingsheading"> Institution ID </p>
              <p className="settingstagvalue">{state.organizationID}</p>
            </div>

            <div className="nweasettings">
              <div style={{ marginBottom: "20px" }}>
                <span className="settingsheading">External Institution ID</span>
              </div>
              <p className="settingstagvalue"> {InstitutionId === "" ? "-" : InstitutionId} </p>
            </div>

            <div className="loginCodesettings">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="settingsheading"> Site Code </span>
              </div>
              <div>
                <p className="settingstagname"> SITE CODE </p>
                <p className="settingstagvalue">
                  {loginCode}
                  <CopyToClipboard text={loginCode}>
                    <button
                      style={{ border: "none" }}
                      className="copyButton"
                      onClick={() => {
                        setShowToast({
                          val: true,
                          msg: "Site Code copied successfully",
                          color: "#36BBA4",
                          type: "success"
                        });
                      }}
                    >
                      <i className="fal fa fa-clone"></i>
                    </button>
                  </CopyToClipboard>
                </p>
              </div>
            </div>

            {getUserRoleId !== 7 && (
              <div className="loginCodesettings">
                <span className="settingsheading"> Time Zone </span>
                <div>
                  <p className="settingstagname"> TIME ZONE </p>
                  <p className="settingstagvalue">
                    {/* @ts-ignore */}
                    {timezoneObject.timeZoneName
                      ? `(UTC ${timezoneObject.utcTime}) ${timezoneObject.timeZoneName}`
                      : "- -"}
                  </p>
                </div>
              </div>
            )}

            {loginCode !== "" ? (
              <div className="singleSignOnMainSettings">
                <span className="singleSignOnSettings">Single Sign-On Settings</span>
                {(
                  <UxAction
                    enabled={getUserRoleId === 1}
                    callback={() => {
                      editSingleSignOnSettings();
                    }}
                    text={"Edit"}
                    className="editButton"
                    size={"lg"}
                  />
                )}
                <div>
                  <p className="settingstagname"> SINGLE SIGN-ON </p>
                  <p className="settingstagvalue">{singleSignOnStatusVal ? "Enabled" : "Disabled"}</p>
                  <p className="settingstagname">CLIENT ID</p>
                  <p className="settingstagvalue">{ClientIdVal ? ClientIdVal : "-"}</p>
                  <p className="settingstagname">CLIENT SECRET</p>
                  <p className="settingstagvalue">{ClientSecretVal ? ClientSecretVal : "-"}</p>
                </div>
              </div>
            ) : null}

            <div>
              <p className="settingstagname"> Name </p>
              <p className="settingstagvalue"> {state.name ? state.name : "-"} </p>
            </div>
            <div>
              <p className="settingstagname"> CRM Name</p>
              <p className="settingstagvalue"> {state.crmName ? state.crmName : "-"} </p>
            </div>
            <div>
              <p className="settingstagname"> State </p>
              <p className="settingstagvalue"> {state.state ? state.state : "-"} </p>
            </div>
            <div>
              <p className="settingstagname"> Type </p>
              <p className="settingstagvalue"> {state.category ? state.category : "-"} </p>
            </div>
          </div>
        ) : null}
        
        {editSingleSignonSettings ? (
          <div className="settingsscrollable px-4 ms-2">
            <div>
              <span className="singleSignOnSettings">Single Sign-On Settings</span>
              <div>
                <p className="settingstagname"> SINGLE SIGN-ON </p>

                <p className="settingstagvalue">
                  {singleSignOnStatus.checkedA ? "Enable" : "Disable"}
                  <span>
                    <BlueSwitch
                      checked={singleSignOnStatus.checkedA}
                      onChange={handleChangeSingleSignOnStatus}
                      name="checkedA"
                    />
                  </span>
                </p>
                <div className="singleSignOnlabelInputHolder" style={{ marginTop: "20px" }}>
                  <label className="labelForInput1">CLIENT ID*</label>
                  <div
                    style={{
                      border: `1.3px solid ${ClientIdBorder}`,
                      borderRadius: "3px"
                    }}
                  >
                    <input
                      className="singleSignOneTextInput"
                      maxLength={100}
                      onClick={() => {
                        setClientIdIssueText("");
                        setClientIdBorder(borderColorFocus);
                      }}
                      placeholder="Enter Client ID"
                      value={ClientId}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleClientIdTextChange(e)}
                      onBlur={() => {
                        setClientIdBorder(borderColorToReset);
                      }}
                    />
                  </div>
                  <label className="labelForIssue">{ClientIdIssueText}</label>
                </div>
                <div className="singleSignOnlabelInputHolder" style={{ marginTop: "20px" }}>
                  <label className="labelForInput1">CLIENT SECRET*</label>
                  <div
                    style={{
                      border: `1.3px solid ${ClientSecretBorder}`,
                      borderRadius: "3px"
                    }}
                  >
                    <input
                      maxLength={150}
                      className="singleSignOneTextInput"
                      onClick={() => {
                        setClientSecretIssueText("");
                        setClientSecretBorder(borderColorFocus);
                      }}
                      placeholder="Enter Client Secret"
                      value={ClientSecret}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleClientSecretTextChange(e)}
                      onBlur={() => {
                        setClientSecretBorder(borderColorToReset);
                      }}
                    />
                  </div>
                  <label className="labelForIssue">{ClientSecretIssueText}</label>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <UxAction
                    callback={() => singleSignOnCancleEdit()}
                    text={"Cancel"}
                    priority={UxShadesEnum.LIGHT}
                    className="cancelButtonSingleSignon"
                    size={"lg"}
                  />
                  <UxAction
                    callback={() => {
                      saveSingleSignOn();
                    }}
                    text={"Save"}
                    className="saveButtonSso"
                    size={"lg"}
                    enabled={
                      ClientSecret !== ClientSecretVal ||
                      ClientId !== ClientIdVal ||
                      singleSignOnStatusVal !== singleSignOnStatus.checkedA
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(Settings);
