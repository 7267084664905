import { UxTabs } from "@wne-spa/ux-component-library";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateActivityIndicator } from "../../Redux/Action";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";
import { RootComponentProps } from "../../root-component.props";
import { AdminService } from "../../services/helpers/api.services";
import { AcademicSessionsAPIResponse } from "../AcademicSessions/AcademicSessions.props";
import studentTabs from "./studentTabs";

function mapStateToProps(state: ReduxProps) {
  return {
    getActivityIndicator: state.getActivityIndicator,
    getUserRoleId: state.getUserRoleId,
    getUserRoleDetails: state.userRoleDetails,
    getFlagrKeys: state.getFlagrKeys,
    organizationId: state.getSchoolGUID
  };
}

interface StudentProgressProps {
  dispatch: AppDispatch;
  organizationId: string;
}

interface Props extends StudentProgressProps, ReduxProps, RootComponentProps {}

function StudentProgress(props: Props) {
  const { dispatch, studentProgressSubject, getFlagrKeys, organizationId } = props;
  const [tabKey, setTabKey] = useState("studentprogress");

  useEffect(() => {
    const fetchData = async () => {
      let schoolYear: number|string = "";
      try {
        const { status, data } : {status: number|boolean, data: Response} = await AdminService.getAcademicSessionsWithGUID({ guid: organizationId });
        if (status === 200) {
          const academicSessions: AcademicSessionsAPIResponse[] = await data.json();
          schoolYear = academicSessions.sort((a,b) => parseInt(`${b.schoolYear}`) - parseInt(`${a.schoolYear}`))[0].schoolYear;
        }
      } catch (err) {
        //do nothing
      } finally {
        studentProgressSubject.next({
          studentid: window.location.pathname.split("_")[1],
          organizationId,
          schoolYear
        });

        dispatch(updateActivityIndicator(false));
      }
    };

    fetchData(); 
  }, [window.location.pathname]);

  const navigate = useNavigate();

  function onTabChange(tab: string) {
    if (tab === "settings") {
      navigate(`/viewstudent/${window.location.pathname.split("/").reverse()[0]}`);
    } else if (tab === "classes") {
      navigate(`/studentclasses/${window.location.pathname.split("/").reverse()[0]}`);
    }
  }

  let updatedStudentTabs = !getFlagrKeys["ShowStudentClassesTab"]
    ? studentTabs.filter((data) => data.eventKey !== "classes")
    : studentTabs;

  return (
    <>
      <div className="class-nav-tabs">
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
            onTabChange(tab);
          }}
          children={updatedStudentTabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
      <div id="studentprogress"></div>
    </>
  );
}

export default connect(mapStateToProps)(StudentProgress);
