import { UserService } from "../services/helpers/user.services";
import urls from "./urls";

export async function openHelpCenter(targetRedirectUrl?: string) {
  const redirectUrl = targetRedirectUrl ?? urls.helpcenter;
  const { status, data } = await UserService.getZendeskToken();

  if (status === 200) {
    const { token } = await data.json();

    // Create a form and append it to the body
    const form = document.createElement("form");
    form.method = "post";
    form.action = `${urls.zendesk}?return_to=${redirectUrl}`;
    form.target = "_blank";

    // Create an input for the JWT token
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "jwt";
    hiddenField.value = token;

    // Add the input to the form and submit it
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();

    // Clean up the form
    document.body.removeChild(form);
  } else {
    //token could not be obtained with SSO information. Fallback to opening help center without providing SSO info.
    console.error("Failed to get token for Zendesk. Redirecting without token.");
    window.open(redirectUrl, "_blank");
  }
}
