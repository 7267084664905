import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getPageTitle: state.getPageTitle,
    getUserRoleId: state.getUserRoleId
  };
}

const PageTitle = (props: ReduxProps) => {
  const { getPageTitle, getUserRoleId } = props;
  const titleText = localStorage.getItem("ala:redirectedFrom") === "wneuser" ? "Edgenuity" : "Imagine MyPath";
  return (
    <Helmet>
      <title> {getUserRoleId === 6 ? getPageTitle : titleText + getPageTitle}</title>
    </Helmet>
  );
};

export default connect(mapStateToProps)(PageTitle);
