import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UxTabs } from "@wne-spa/ux-component-library";
import WNEUserProfile from "./WNEUserProfile";
import ReduxProps from "../../Redux/Redux.props";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}
interface Props extends ReduxProps {}

function WNEProfile(props: Props) {
  const [tabKey, setTabKey] = useState("settings");

  useEffect(() => {
    localStorage.setItem("page", "settings");
  }, []);

  const tabs = [
    {
      eventKey: "settings",
      title: "Settings",
      content: <WNEUserProfile />
    }
  ];
  return (
    <>
      <div>
        <UxTabs
          tabsId="class-tabs"
          activeKey={tabKey}
          onTabSelect={(tab: string) => {
            setTabKey(tab);
            localStorage.setItem("page", tab);
          }}
          children={tabs}
          className="pt-2"
          currentKey={tabKey}
        />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(WNEProfile);
