import React from "react";

import Icon from "../icon";
import EnhancedIconProps from "./enhanced-icon.props";
import UxPositionEnum from "../../shared/enums/position.enum";
import "./enhanced-icon.scss";
import classMap from "../../shared/utilities/classMap";

const propsTextPosition = (textPosition?: UxPositionEnum) => {
  if (textPosition === UxPositionEnum.LEFT) {
    return "left";
  } else if (textPosition === UxPositionEnum.RIGHT) {
    return "right";
  } else {
    return "";
  }
};

const EnhancedIcon = ({ textPosition, textClassName, ...props }: EnhancedIconProps) => {
  const textClass = !props.title ? "" : propsTextPosition(textPosition);
  const position: UxPositionEnum = textPosition ? textPosition : UxPositionEnum.LEFT;
  const icon = (
    <div className={classMap("enhanced-icon-icon", props.enhancedClassName)}>
      <Icon {...props} />
    </div>
  );
  const text = (
    <div className={classMap("enhanced-icon-text", textClassName, textClass)}>
      <span>{props.title}</span>
    </div>
  );
  const before: boolean = position === UxPositionEnum.TOP || position === UxPositionEnum.LEFT;
  const enhancedIcon = before ? (
    <>
      {text}
      {icon}
    </>
  ) : (
    <>
      {icon}
      {text}
    </>
  );
  const vertical: boolean = position === UxPositionEnum.TOP || position === UxPositionEnum.BOTTOM;
  const classes: string = `d-flex ${vertical ? "flex-column centerContent" : "align-items-center"}`;

  return <div className={`edge-ux-enhanced-icon ${classes}`}>{enhancedIcon}</div>;
};

export default EnhancedIcon;
