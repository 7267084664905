import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Students from "../ViewCustomer/Students";
import { AdminService } from "../../services/helpers/api.services";
import {
  updateActivityIndicator,
  updateBreadCrumbs,
  updatePageTitle,
  updateOrgLoginCodeAvailable
} from "../../Redux/Action";
import "./DataList.scss";
import "../Students/students.scss";
import ReduxProps from "../../Redux/Redux.props";
import { AppDispatch } from "../../Redux/Store";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData
  };
}
interface TSStudentsProps {
  dispatch: AppDispatch;
}

interface Props extends TSStudentsProps, ReduxProps {}

function TStudents(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [loading] = useState(true);
  const { dispatch } = props;
  const [data] = useState([]);
  useEffect(() => {
    dispatch(updatePageTitle("- Students"));
    const breadcrumb = {
      crumbs: [{ path: `/tstudents/${id}`, name: "Students" }]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(true));
  }, [dispatch, id]);

  useEffect(() => {
    try {
      const schoolIds = id.split("_");
      //Teachers with 1 school
      if (schoolIds.length === 1) {
        let orgids = { guid: id.split("_")[0] };

        AdminService.getLogincodeWithGuid(orgids).then(({ status, data }: { status: number; data: Response }) => {
          if (status === 200) {
            gotLogincodeResponse(data);
          } else {
            // do nothing
          }
        });
      } else {
        dispatch(updateOrgLoginCodeAvailable(true));
      }
    } catch {}
  }, []);

  async function gotLogincodeResponse(responsedata: Response) {
    try {
      let details = JSON.parse(await responsedata.text());
      if (details.length > 0) {
        if (details[0].loginCode === "") {
          dispatch(updateOrgLoginCodeAvailable(false));
        } else {
          dispatch(updateOrgLoginCodeAvailable(true));
        }
      } else {
        //do nothing
      }
    } catch {}
  }

  function paginationcomp() {
    return (
      <div style={{ display: "inline" }}>
        {data.length > 0 ? (
          <span style={{ float: "right", marginTop: 20, marginRight: 20 }}> Viewing Records 1 - {data.length}</span>
        ) : null}
      </div>
    );
  }
  return (
    <>
      <div className="class-nav-tabs mt-4">
        <Students isfrom="school" isteacher={true} />
        {loading ? null : paginationcomp()}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(TStudents);
