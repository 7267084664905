import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UxTable } from "@wne-spa/ux-component-library";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IList } from "./IList";
import { updateActivityIndicator, updateBreadCrumbs } from "../../Redux/Action";
import "./DataList.scss";
import ReduxProps from "Redux/Redux.props";
import { AppDispatch } from "Redux/Store";
import useOrganizations from "../hooks/useOrganizations";

function mapStateToProps(state: ReduxProps) {
  return {
    getBreadCrumbsData: state.getBreadCrumbsData,
    getDaDetails: state.getDaDetails,
    getUserRoleId: state.getUserRoleId,
    getActivityIndicator: state.getActivityIndicator
  };
}
interface DAListProps {
  dispatch: AppDispatch;
}
interface Props extends ReduxProps, DAListProps {}
function DAList(props: Props) {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const [loading, setLoading] = useState(true);
  const { dispatch, getUserRoleId } = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const allSchools = useOrganizations(id);

  useEffect(() => {
    const breadcrumb = { crumbs: [{ path: "/dalist", name: "Schools" }] };
    dispatch(updateBreadCrumbs(breadcrumb));

    if (allSchools !== null) {
      setData(allSchools);
      setLoading(false);
    }
    dispatch(updateActivityIndicator(false));
  }, [dispatch, allSchools]);

  function handleClick(sid: number) {
    if (getUserRoleId === 5) {
      navigate(`/teacherclasses/${sid}`);
    }
  }

  function isUnchecked(event: React.MouseEvent<HTMLInputElement, MouseEvent>, index: number) {
    if ((event.target as HTMLInputElement).checked) {
      document.getElementsByClassName("checkLabel")[index].setAttribute("style", "background: black");
    } else {
      document.getElementsByClassName("checkLabel")[index].setAttribute("style", "background: white");
    }
  }

  const headings = ["School Name", "Customer Name", "NetSuite ID", "School ID", "Status"];
  const children = data.map((school: IList, index: number) => ({
    name: (
      <div className="d-flex" onClick={() => handleClick(school.id)}>
          <div className="checker">
            <input
              type="checkbox"
              className="childCheckBox"
              id={`hiddenCheck${index}`}
              onClick={(e) => {
                isUnchecked(e, index);
              }}
            />
            <label htmlFor={`hiddenCheck${index}`} className="checkLabel"></label>
          </div>
          <span
            onClick={() => {
              //do nothing
            }}
            className="name-text cName"
          >
            {school.name}
          </span>
        </div>
    ),
    cname: (
      <div className="d-flex">
          <span
            onClick={() => {
              //do nothing
            }}
            className="name-text cName"
          >
            {school.customerName}
          </span>
        </div>
    ),
    nid: (
      <div className="d-flex">
        <span
          onClick={() => {
            //do nothing
          }}
          className="name-text cName"
        >
          {school.netsuiteId}
        </span>
      </div>
    ),
    sid: (
      <div className="d-flex">
        <span
          onClick={() => {
            //do nothing
          }}
          className="name-text cName"
        >
          {school.id}
        </span>
      </div>
    ),
    isActive: (
      <div className="d-flex">
        <div className="status">
          <span className="name-text  noCursor">{school.isActive === true ? "Active" : "Inactive"}</span>
        </div>
        <div className="dropper">
          <NavDropdown
            id="basic-nav-dropdown"
            title={<i className="fas fa-ellipsis-v"></i>}
            onSelect={() => {
              //do nothing
            }}
          >
            <NavDropdown.Item eventKey="student">{school.isActive ? "Deactivate" : "Activate"}</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
    )
  }));

  function paginationcomp() {
    return (
      <div style={{ display: "inline" }}>
        {data.length > 0 ? (
          <span
            style={{
              float: "right",
              marginTop: 20,
              marginRight: 20,
              fontSize: "15px"
            }}
          >
            {" "}
            Viewing Records 1 - {data.length} of {data.length}
          </span>
        ) : null}
      </div>
    );
  }
  return (
    <div className="class-nav-tabs">
      <UxTable
        headings={headings}
        children={children}
        separated={true}
        hover={true}
        className="bg-white dTable p-3 bg-white"
        headingsClassName="textBold"
      />
      {loading ? null : paginationcomp()}
    </div>
  );
}

export default connect(mapStateToProps)(DAList);

//NOTE: possible unused page. Check whether this page is even being used and remove it if not.
