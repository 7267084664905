import React from "react";
import "./page-not-found.scss";
import { useNavigate } from "react-router-dom";
import { logout } from "../../authService/AuthService";
import { persistor } from "../../Redux/Store";

function PageNotFound() {
  const navigate = useNavigate();

  function Logout() {
    persistor.purge();
    localStorage.clear();
    sessionStorage.clear();
    //@ts-ignore
    logout();
    navigate("/");
  }
  return (
    <>
      <div className="showPageNotFound">
        <div className="fNfDec">
          There seems to be some issue while impersonating.
          <br />
          Please try again
        </div>
        <div
          onClick={() => {
            Logout();
          }}
          className="toHome"
        >
          <i className="fa fa-arrow-left"></i>Go Back to Login
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
