import { UxTabDropDown } from "@wne-spa/ux-component-library";
import React, { useState } from "react";

type StudentDropdownTabProps = {
  currentView: string;
  onNavClick: (pageName: string) => void;
};

export default function StudentDropdownTab(props: Readonly<StudentDropdownTabProps>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onNavClick = (pageName: string) => {
    setIsDropdownOpen(false);
    props.onNavClick(pageName);
  };

  return (
    <UxTabDropDown
      title="Students"
      onNavClick={onNavClick}
      onDropDownClick={() => {
        setIsDropdownOpen((val) => !val);
      }}
      isOpen={isDropdownOpen}
      options={[
        { text: "Student Roster", value: "roster" },
        { text: "Student Placement", value: "placement" }
      ]}
      activeOption={props.currentView}
    />
  );
}
