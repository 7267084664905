import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateActivityIndicator,
  updateSchoolGuids,
  updateBreadCrumbs,
  updateAssignmentBuilderOrgId,
  updatePageTitle
} from "../../Redux/Action";
import SchoolList from "../EducatorView/SchoolList";
import { LMSService } from "../../services/helpers/lms.services";
import { SchoolProps } from "./AssignmentBuilder.props";
import { AppDispatch } from "../../Redux/Store";
import useOrganizations from "../hooks/useOrganizations";

type AssignmentBuilderStaticProps = {
  dispatch: AppDispatch;
};

function AssignmentBuilderSchools(props: Readonly<AssignmentBuilderStaticProps>): JSX.Element {
  //@ts-ignore
  const { id } = useParams<{ id: string | undefined }>();
  const { dispatch } = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const allSchools = useOrganizations(id);

  useEffect(() => {
    dispatch(updatePageTitle(`- Assignment Builder`));
    const breadcrumb = {
      crumbs: [
        {
          path: `/assignmentbuilder/schools/${id}`,
          name: "Assignment Builder"
        }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    dispatch(updateActivityIndicator(false));
  }, [dispatch, updateBreadCrumbs]);

  useEffect(() => {
    if (allSchools !== null) {
      dispatch(updateSchoolGuids(allSchools));
      setData(allSchools);
    }
    dispatch(updateActivityIndicator(false));
  }, [dispatch, allSchools]);

  async function getLicensedSubjects(schoolId: number) {
    try {
      await LMSService.getSubjects(schoolId).then(({ status }: { status: number; data: Response }) => {
        if (status === 200) {
          navigate(`/assignmentbuilder/dashboard`);
        } else {
          navigate(`/noLicenseProducts`);
          dispatch(updateActivityIndicator(true));
        }
      });
    } catch (error) {
      //do nothing
    }
  }

  function handleClick(row: SchoolProps) {
    let ids = {
      schoolId: row.id,
      schoolGuid: row.organizationGuid,
      schoolName: row.name
    };
    dispatch(updateAssignmentBuilderOrgId(ids));
    dispatch(updateActivityIndicator(true));
    const breadcrumb = {
      crumbs: [
        {
          path: `/assignmentbuilder/schools/${id}`,
          name: "Assignment Builder"
        },
        { path: `/assignmentbuilder/dashboard`, name: row.name }
      ]
    };
    dispatch(updateBreadCrumbs(breadcrumb));
    getLicensedSubjects(row.id);
  }

  return (
    <div className="class-nav-tabs">
      <SchoolList handleClick={handleClick} data={data} />
    </div>
  );
}

export default connect()(AssignmentBuilderSchools);
